import { createSelector } from '@reduxjs/toolkit';

const getTShapeGraphicData = (state) => state.t.graphicData;
const getTShapeSelectedSkillInfo = (state) => state.t.selectedSkillInfo;
export const selectTShapeData = createSelector([getTShapeGraphicData], (tData) => {
  return tData;
});
export const selectTShapeSelectedSkill = createSelector(
  [getTShapeSelectedSkillInfo],
  (skillInfo) => {
    if (skillInfo) {
      return {
        id: skillInfo.competenceId,
        value: skillInfo.label,
        ...skillInfo,
      };
    }

    return skillInfo;
  },
);
