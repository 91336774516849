const context = process.env.REACT_APP_CONTEXT;

export const ROUTES = {
  ONBOARDING_ROOT: `${context}/onboarding`,
  ONBOARDING_WELCOME: `${context}/onboarding/welcome`,
  ONBOARDING_RESUME: `${context}/onboarding/resume`,
  ONBOARDING_RESUME_COMPLETE: `${context}/onboarding/resume/complete`,
  ONBOARDING_SKILLS: `${context}/onboarding/skills`,
  ONBOARDING_ASSESSMENT: `${context}/onboarding/assessment`,
  ONBOARDING_PERSONAL: `${context}/onboarding/personal`,
  ONBOARDING_ADD_SKILLS: `${context}/onboarding/add-skills`,
  ONBOARDING_EDUCATION: `${context}/onboarding/education`,
  ONBOARDING_EXPERIENCE: `${context}/onboarding/experience`,
  ONBOARDING_PORTFOLIO: `${context}/onboarding/portfolio`,
  PROFILE_ROOT: `${context}/profile`,
  PROFILE_SKILLS: `${context}/profile/skills`,
  PROFILE_EDUCATION: `${context}/profile/education`,
  PROFILE_EXPERIENCE: `${context}/profile/experience`,
  PROFILE_PORTFOLIO: `${context}/profile/portfolio`,
  CAREER_PATHWAYS: `${context}/career-pathways`,
  ADMIN_DASHBOARD: `${context}/admin/dashboard`,
  ADMIN_SKILLS_PARSING: '${context}/admin/skills-parse',
  ADMIN_SKILLS_PARSING_FILE: `${context}/admin/skills-parsing-file`,
  ADMIN_SKILLS_PARSING_TEXT: `${context}/admin/skills-parsing-text`,
  ADMIN_TAXONOMIES: `${context}/admin/taxonomies`,
  MANAGE_SKILLS: `${context}/manage-skills`,
  DISCOVERY_DASHBOARD: `${context}/discovery`,
  MY_RECORDS: `${context}/records`,
  MY_RECORDS_CONNECT: `${context}/records/connect`,
  MY_RECORDS_MANAGE_ROOT: `${context}/records/manage`,
  MY_RECORDS_MANAGE_COMPETENCIES: `${context}/records/manage/competencies`,
  MY_RECORDS_MANAGE_COURSES: `${context}/records/manage/courses`,
  MY_RECORDS_MANAGE_MICROCREDENTIALS: `${context}/records/manage/microcredentials`,
  MY_RECORDS_MANAGE_DEGREES: `${context}/records/manage/degrees`,
  MY_RECORDS_ACCEPT_ROOT: `${context}/records/accept`,
  MY_RECORDS_ACCEPT_COMPETENCIES: `${context}/records/accept/competencies`,
  MY_RECORDS_ACCEPT_COURSES: `${context}/records/accept/courses`,
  MY_RECORDS_ACCEPT_MICROCREDENTIALS: `${context}/records/accept/microcredentials`,
  MY_RECORDS_ACCEPT_DEGREES: `${context}/records/accept/degrees`,
  MY_RECORDS_SHARE_ROOT: '${context}/records/share',
  MY_RECORDS_SHARE_MY_RECORDS: `${context}/records/share/my-records`,
  MY_RECORDS_SHARE_REQUESTS: `${context}/records/share/requests`,
  MY_RECORDS_SHARE_SHARED: `${context}/records/share/shared`,
  CONNECTIONS: `${context}/connections`,
  TERMS_AND_CONDITIONS: `${context}/terms-conditions`,
  PRIVACY_POLICY: `${context}/privacy-policy`,
  HOME: `${context}/home`,
  T_SHAPE: `${context}/t`,
  CREDENTIALS: `${context}/credentials`,
  FCRA: `${context}/fcra`,
  PUBLIC_WALLET: `${context}`,
  REGISTER_USERS: `${context}/register`,
  REGISTER_USERS_VERIFY: `${context}/register/verify`,
  RESET_PASSWORD: `${context}/reset-password`,
  NOT_AUTHORIZED_REGISTRATION: `${context}/register/not-authorized`,
  SITEMAP: `${context}/sitemap`,
  SETTINGS: `${context}/settings`,
  ACTIVATE_CODE: `${context}/activate`,
};
