import React from 'react';
import { Expand, CircleNumber, Counter, AtAGlanceHelp } from 'common/components';
import './AtAGlance.scss';
import { CATEGORY_LABELS } from '../../utils';

/**
 * At A Glance panel
 * @param {number} skillsCount - Total skills count
 * @param {number} technologyCount - Technology skills count
 * @param {number} businessCount - Business skills count
 * @param {number} professionalCount - Professional skills count
 * @param {number} renderCirclesAsRow -
 * @param {number} expandByDefault - Allow expanding panel by default
 */
export const AtAGlance = ({
  skillsCount,
  technicalCount,
  operationalCount,
  professionalCount,
  renderCirclesAsRow = true,
  renderContainerAsRow = true,
  expandByDefault = false,
  expandable = true,
  ...props
}) => {
  const CIRCLES_DATA = [
    {
      value: technicalCount,
      label: CATEGORY_LABELS['it_skills'],
    },
    {
      value: professionalCount,
      label: CATEGORY_LABELS['professional_skills'],
    },
    {
      value: operationalCount,
      label: CATEGORY_LABELS['soft_skills'],
    },
  ];

  return (
    <Expand
      className={`iq4-at-a-glance-panel ${
        !expandable ? 'iq4-at-a-glance-panel--not-expandable' : ''
      } ${!renderContainerAsRow ? 'iq4-at-a-glance-panel--not-row' : ''} 
      `}
      title="AT A GLANCE"
      uniqueId="at-a-glance"
      label="At A Glance"
      expandByDefault={!expandable ? true : expandByDefault}
      expandable={expandable}
      titleHelp={() => <AtAGlanceHelp />}
      {...props}
    >
      <Counter label="Total number of skills" count={skillsCount} />

      <div
        className={`iq4-at-a-glance-panel__circle-container ${
          renderCirclesAsRow ? 'iq4-at-a-glance-panel__circle-container--row' : ''
        }`}
      >
        <div className="iq4-at-a-glance-panel__circles">
          {CIRCLES_DATA.map((cData, i) => {
            if (renderCirclesAsRow) {
              return (
                <div
                  className={`iq4-at-a-glance-panel__circles-col iq4-glance-${i}`}
                  key={cData.label}
                >
                  <CircleNumber
                    value={cData.value}
                    circleClass={`iq4-at-a-glance-panel__circles--${i + 1}`}
                  />
                  <span className="iq4-profile-skills__label iq4-at-a-glance-panel__label-tech">
                    {cData.label}
                  </span>
                </div>
              );
            }
            return (
              <>
                <CircleNumber
                  value={cData.value}
                  circleClass={`iq4-at-a-glance-panel__circles--${i + 1}`}
                />
                <span className="iq4-profile-skills__label iq4-at-a-glance-panel__label-tech">
                  {cData.label}
                </span>
              </>
            );
          })}
        </div>
      </div>
    </Expand>
  );
};
