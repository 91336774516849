import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextArea, Checkbox, AccessibleSelect } from 'common/components/Form/fieldTypes';
import { Uploader } from 'common/components/Uploader';
import { ReactComponent as UploaderIcon } from 'common/assets/svg/uploader.svg';
import { Button } from 'common/components';
import { validations } from 'common/components/Form/validations';
import { submitUserFeedback } from 'common/store/features/profile/profileSlice';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';

export const useUserFeedbackFormDefinition = ({ values, onComplete }) => {
  const [parsedImage, setParsedImage] = useState(null);
  const [announcement, setAnnouncement] = useState('');
  const dispatch = useDispatch();

  const handleImageUpload = (data) => {
    if (data && data.length) {
      data[0].preview = URL.createObjectURL(data[0]);
      setParsedImage(data[0]);
      setAnnouncement('You screenshot has been successfully uploaded');
    }
  };

  const feedbackTypeOptions = [
    {
      key: 'issue',
      value: 'Technical',
    },
    {
      key: 'enhancement',
      value: 'Help / Feedback',
    },
  ];

  const fieldsDefinition = [
    {
      label: 'Please describe your feedback, comments, or suggestions below:',
      name: 'description',
      component: TextArea,
      validators: {
        required: validations.isRequired(),
        maxLength: validations.hasMaxLength(1000),
        minLength: validations.hasMinLength(20),
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <AccessibleSelect
          maxWidth
          label="Category"
          name="feedbackType"
          options={feedbackTypeOptions}
          {...formProps}
          validators={{
            required: validations.isRequired(),
            validate: (currentValue) => {
              if (!!!currentValue) {
                return 'Please select a Category';
              }
            },
          }}
        />
      ),
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => {
        formProps.register('attachment');

        return (
          <div className="iq4-user-feedback-form__attachment">
            <p className="iq4-user-feedback-form__attachment-label">
              Please attach any relevant screenshots here:
            </p>

            {!parsedImage && (
              <div className="iq4-user-feedback-form__attachment-uploader">
                <Uploader
                  ariaLabel="Please upload your screenshot"
                  handleUpload={(data) => {
                    formProps.setValue('attachment', data[0]);
                    handleImageUpload(data);
                  }}
                  fileTypes={THUMBNAIL_MIME_TYPES}
                >
                  <div className="iq4-30-onboarding-uploader iq4-30-onboarding-uploader__hover-container">
                    <UploaderIcon role="presentation" aria-hidden="true" />
                    <p className="iq4-30-onboarding-uploader__info-text">
                      <span className="iq4-30-onboarding-uploader__info-text--strong">
                        Upload screenshot <br />
                      </span>
                    </p>
                  </div>
                </Uploader>
              </div>
            )}
            <div aria-live="polite" aria-atomic="true" className="sr-only">
              {announcement}
            </div>

            {parsedImage && (
              <div className="iq4-user-feedback-form__attachment-preview">
                <img
                  className="iq4-credentials__parsed-image"
                  src={parsedImage.preview}
                  alt="thumbnail"
                />
                <p>Successful upload!</p>

                <Button
                  variation="clear"
                  onClick={() => {
                    setParsedImage(null);
                    formProps.setValue('attachment', null);
                  }}
                >
                  Delete image
                </Button>
              </div>
            )}
          </div>
        );
      },
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => (
        <div className="iq4-user-feedback__approved-contact">
          <Checkbox
            labelAfter
            name="approvedForContact"
            label="I would be interested in the team contacting me about other improvements to the platform"
            checkboxClass=""
            {...formProps}
          />
        </div>
      ),
    },
    {
      isCustom: true,
      component: () => (
        <div className="iq4-user-feedback__disclaimer">
          Please note that the WGU Achievement Wallet is provided and maintained by iQ4, and that by
          using this feedback feature, you will be sharing feedback directly with iQ4. It’s
          important to be aware that during troubleshooting and enhancement processes, certain
          information within your wallet may be accessible to iQ4. iQ4 is a trusted partner
          responsible for refining and optimizing the wallet based on your valuable input.
        </div>
      ),
    },
    {
      isCustom: true,
      component: () => (
        <div style={{ marginTop: 40, textAlign: 'center' }}>Ready to submit your feedback?</div>
      ),
    },
  ];

  const onCreate = async ({ approvedForContact, description, attachment, feedbackType }) => {
    // save user feedback
    try {
      const result = await dispatch(
        submitUserFeedback({ feedbackType, approvedForContact, description, image: attachment }),
      );

      if (result?.meta?.requestStatus === 'fulfilled') {
        return {
          data: 'success',
          error: null,
        };
      } else {
        return {
          error: 'There was an error saving your feedback. Please try again later.',
        };
      }
    } catch (e) {
      return {
        error: 'There was an error saving your feedback. Please try again later.',
      };
    }
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onCreate,
      onComplete,
    },
  };
};
