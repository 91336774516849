import React from 'react';
import { ExternalLinkInterstitial, SimpleIcon } from 'common/components';
import { INTERSTITIAL_COPY } from 'common/constants/interstitialCopy';
import './OpenPositionsInnerCard.scss';

/**
 * Open position inner card
 * @param {string} jobTitle - The job title
 * @param {string} company - The company name
 * @param {string} location - The city name
 * @param {string} url - The url
 * @param {string} [className] - Custom className
 * @param {function} [onClick] - Handle btn click
 */
export const OpenPositionsInnerCard = ({ position, className }) => {
  const { JobTitle: jobTitle, Company: company, Location: location, URL: url } = position;

  return (
    <div className={`iq4-open-positions-inner-card ${className ? className : ''}`}>
      <div className="iq4-open-positions-inner-card__row-1">
        <div className="iq4-open-positions-inner-card__icon">
          <SimpleIcon name="experience" />
        </div>
        <div className="iq4-open-positions-inner-card_position-container">
          <p className="iq4-open-positions-inner-card_position-title">{jobTitle}</p>
          <p className="iq4-open-positions-inner-card_position-company">{company}</p>
        </div>
      </div>

      <div className="iq4-open-positions-inner-card__row-2">
        <div className="iq4-open-positions-inner-card__location-container">
          <div className="iq4-open-positions-inner-card__icon">
            <SimpleIcon name="source" />
          </div>
          <div className="iq4-role-course-inner-card__location">{location}</div>
        </div>
        <ExternalLinkInterstitial
          className="iq4-open-positions-inner-card__btn"
          linkLabel="Learn More"
          labelName={`${jobTitle} at ${company}`}
          link={url}
          introText={INTERSTITIAL_COPY.JOB_BOARD_INTRO_TEXT}
          mainText={INTERSTITIAL_COPY.JOB_BOARD_MAIN_TEXT}
        />
      </div>
    </div>
  );
};
