import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Topbar, Modal, Button } from 'common/components';
import { FormWrapper } from 'common/components/Form';
import OnboardingInfoPanel from 'common/components/Onboarding/OnboardingInfoPanel/OnboardingInfoPanel';

import { useNewUserRegistrationFormDefinition } from 'common/hooks/forms';
import { sendRequest } from 'common/utils';

import {
  SEND_VERIFY_EMAIL_NEW_USER_URL,
  SCHOOLS_AVAILABLE_FOR_REGISTERING_USERS,
} from 'common/constants/endpoints';
import { ROUTES } from 'routes/routeNames';

import profileImage from '@assets/png/profile-illustrated-for-onboarding.png';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';

import './RegisterUserPage.scss';

export const RegisterUserPage = () => {
  const [showSentEmailModal, setShowSentEmailModal] = useState(false);
  const [regStatus, setRegStatus] = useState({ error: null, errorCode: null });
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const school = urlParams.get('school');
  const activationCode = urlParams.get('activationCode');

  const [values, setValues] = useState({
    id: null,
    firstname: '',
    lastname: '',
    school: !!school ? school : null,
    email: '',
    personalEmail: '',
    major: '',
    password: '',
    confirmPassword: '',
    activationCode: !!activationCode ? activationCode : null,
  });

  const handleRegister = (status) => {
    // update with new values
    setValues((prevState) => ({ ...prevState, ...status.data }));

    if (status.error) {
      // user not authroized to register
      if (status.errorCode === 3) {
        history.push(ROUTES.NOT_AUTHORIZED_REGISTRATION);
      }

      // set error status
      setRegStatus(status);
      return;
    }

    // reset
    resetDataAndErrors();

    // navigate to verify screen
    if (activationCode)
      history.push(ROUTES.HOME);
    else
      setShowSentEmailModal(true);
  };

  const handleResendEmail = async () => {
    try {
      await sendRequest({
        verb: 'POST',
        url: SEND_VERIFY_EMAIL_NEW_USER_URL,
        data: [
          {
            email: values.email,
          },
        ],
      });
    } catch (e) {
      console.log('error resending email', e);
    }

    resetDataAndErrors();
    setShowSentEmailModal(true);
  };

  //  reset data & errors
  function resetDataAndErrors() {
    setRegStatus({ error: null, errorCode: null });
  }

  return (
    <div className="iq4-register-page">
      <Topbar logoOnly Logo={Logo} className="iq4-onboarding-resume-path__topbar" />

      <section className="iq4-onboarding-resume-path">
        <OnboardingInfoPanel>
          <div className="iq4-resume-info-panel">
            <div className="iq4-resume-info-panel__content">
              <h1 className="iq4-resume-info-panel__header">Welcome</h1>
              <p className="iq4-resume-info-panel__intro">
                <h2>Register today!</h2>{' '}
              </p>
              <p>
                To help you build out your profile upload your resume. We can then pre populate it.
              </p>
              <p>
                You can edit it at any time in the process, during this onboarding journey or after
                you’ve uploaded your resume.
              </p>
              <p>
                The details we capture will fill out your Personal, Education, Experience, Skills
                and Project cards.
              </p>
            </div>
            <img
              className="iq4-resume-info-panel__sample-profile"
              src={profileImage}
              alt="Sample profile image, representing the main profile page, where we can see the 5 main sections within the Wallet: Profile, Skills, Credentials, Career Pathways and Connections"
            />
          </div>
        </OnboardingInfoPanel>

        <div className="iq4-onboarding-resume-path__content-container">
          <h2 className="iq4-register-page__form-title">Register to get started</h2>
          <FormWrapper
            submitLabel="Register"
            showButtons
            values={values}
            useFormDefinition={useNewUserRegistrationFormDefinition}
            onComplete={handleRegister}
          />

          {/* handle errors */}
          {regStatus.error && (
            <div className="iq4-register-page__register-error">
              {regStatus.error}
              {regStatus.errorCode == 1 && (
                <button onClick={handleResendEmail}>Re-send verification email</button>
              )}
              {regStatus.errorCode == 2 && <Link to={ROUTES.HOME}>Login</Link>}
            </div>
          )}
        </div>
      </section>

      <Modal
        className="iq4-register-page__modal"
        BackdropProps={{
          classes: {
            root: 'iq4-register-page__modal-backdrop',
          },
        }}
        variation="1"
        isOpen={showSentEmailModal}
        handleClose={() => void setShowSentEmailModal(false)}
        onClose={() => void setShowSentEmailModal(false)}
      >
        <div className="iq4-register-page__verify-email">
          <div className="iq4-onboarding__step-container">
            <div className="iq4-verify-card">
              <h2 className="iq4-verify-card__title">Verification</h2>

              <div className="iq4-verify-card__main">
                <p>A confirmation email has been sent to: </p>
                <p>{values.email}</p>
              </div>

              <div className="iq4-verify-card__footer">
                <p>
                  Confirm the email we sent to the above address and you can log into your new iQ4
                  Wallet
                </p>

                <Button onClick={() => void history.push(ROUTES.HOME)}>Got it</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
