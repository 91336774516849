import React from 'react';
import { Switch } from '@material-ui/core';
import { FixedTooltip } from 'common/components';
import './PrivatePublicToggle.scss';

export const PrivatePublicToggle = ({
  isIncludeContext = false,
  record,
  onSelecting,
  ariaLabel,
}) => {
  const labelId = `private-public-toggle-${record?.id}`;

  function keySelectItem(event) {
    if (event.key === ' ' || event.key === 'Enter') {
      event.preventDefault();
      onSelecting({ selected: !record?.isPublic });
    }
  }

  return (
    <div className="iq4-private-public-toggle" key={record?.id}>
      <span className="iq4-private-public-toggle__private">
        {isIncludeContext ? "Don't Include" : 'Private'}
      </span>
      <label
        className="form-switch"
        tabIndex={0}
        role="checkbox"
        aria-checked={!!record?.isPublic}
        aria-label={ariaLabel || 'Toggle record to be public or private'}
        onKeyDown={(event) => keySelectItem(event)}
      >
        <input
          aria-checked={!!record?.isPublic}
          tabIndex="0"
          type="checkbox"
          aria-labelledby={labelId}
          checked={!!record?.isPublic}
          onChange={(e) => onSelecting({ selected: e.target.checked })}
        />
        <i />
      </label>
      <span
        className={
          !!record?.isPublic
            ? 'iq4-private-public-toggle__public'
            : 'iq4-private-public-toggle__private'
        }
      >
        {isIncludeContext ? 'Include' : 'Public'}
      </span>
    </div>
  );
};
