import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {
  selectHasAcceptedFCRA,
  selectHasAcceptedTerms,
  selectHasFetchedProfile,
  selectIsAcceptingFCRA,
  selectProfile,
} from 'common/store/features/profile/selectors';
import {
  acceptFCRATerms,
  acceptUserTerms,
  getShareProfileSettings,
} from 'common/store/features/profile/profileSlice';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { ROUTES } from 'routes';
import { Button } from 'common/components/Button';
import { selectIsLoggedIn } from 'common/store/features/session/selectors';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './AcceptTerms.scss';

export const AcceptTerms = () => {
  const [isCheckingFCRA, setIsCheckingFCRA] = useState(false);

  const hasAcceptedTerms = useSelector(selectHasAcceptedTerms);
  const hasAcceptedFCRA = useSelector(selectHasAcceptedFCRA);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const hasFetchedProfile = useSelector(selectHasFetchedProfile);
  const isAcceptingFCRA = useSelector(selectIsAcceptingFCRA);
  const { personal } = useSelector(selectProfile);

  const location = useLocation();
  const dispatch = useDispatch();
  const breakpoint = useBreakpoints();

  const rejectUrl = process.env.REACT_APP_REJECT_TERMS_URL;

  const rejectTerms = () => {
    window.location.replace(rejectUrl);
  };

  const excludeTerms = ['home', 'terms-conditions', 'privacy-policy'];

  const acceptTerms = () => {
    dispatch(acceptUserTerms({ id: personal?.userProfile?.id }));
    dispatch(acceptFCRATerms());
  };
  const acceptFCRA = async () => {
    const result = await dispatch(acceptFCRATerms());
    if (result?.type === 'profile/acceptFCRATerms/fulfilled') {
      setIsCheckingFCRA(false);
    }
  };

  useEffect(() => {
    dispatch(getShareProfileSettings());
  }, []);

  function renderNormalContent() {
    return (
      <>
        <h1 className="iq4-terms-modal__container--title">USER AGREEMENT</h1>
        <div className="iq4-terms-modal__container--content">
          By using our platform, you are agreeing to accept our
          <br />
          <div className="iq4-terms-modal__container--parent">
            <a
              href={ROUTES.TERMS_AND_CONDITIONS}
              target="_blank"
              className="iq4-terms-modal__container--content--link"
            >
              Terms and conditions
            </a>{' '}
            and{' '}
            <a
              href={ROUTES.PRIVACY_POLICY}
              target="_blank"
              className="iq4-terms-modal__container--content--link"
            >
              Privacy policy
            </a>
          </div>
          {/*<div className="iq4-terms-modal__container--parent">
            <a
              href={ROUTES.PRIVACY_POLICY}
              target="_blank"
              className="iq4-terms-modal__container--content--link"
            >
              Privacy policy
            </a>
          </div>*/}
          {/*          <div className="iq4-terms-modal__container--parent">and</div>
          <div className="iq4-terms-modal__container--parent">
            <div
              onClick={() => setIsCheckingFCRA(true)}
              className="iq4-terms-modal__container--content--link"
            >
              Fair Credit Reporting Act
            </div>
          </div>*/}
        </div>
        <div className="iq4-terms-modal__container--buttons">
          <Button onClick={rejectTerms}>Go back</Button>
          <Button disabled={isAcceptingFCRA} onClick={acceptTerms}>
            {!isAcceptingFCRA && <span>Accept</span>}
            {isAcceptingFCRA && <CircularProgress size={20} />}
          </Button>
        </div>
      </>
    );
  }

  function renderMonth() {
    let returnValue = 1 + moment(new Date()).month();
    if (returnValue.toString().length === 1) {
      returnValue = `0${returnValue}`;
    }
    return returnValue;
  }

  function renderDay() {
    let returnValue = moment(new Date()).date();
    if (returnValue.toString().length === 1) {
      returnValue = `0${returnValue}`;
    }
    return returnValue;
  }

  function renderFCRAContent() {
    return (
      <>
        <div className="iq4-terms-modal__container--title">FAIR CREDIT REPORTING ACT</div>
        {hasAcceptedFCRA && <div className="iq4-terms-modal__accepted">ACCEPTED</div>}
        <div className="iq4-terms-modal__container--content"></div>
        <strong>DISCLOSURE FORM TO OBTAIN CONSUMER REPORTS FOR EMPLOYMENT PURPOSES</strong>
        <br />
        <br />
        <strong>
          Please read carefully before clicking “I Agree” in the Consumer Authorization Form
        </strong>
        <br />
        <br />
        <strong>DISCLOSURE</strong>
        <br />
        <br /> As a member associated with IQ4 Corporation (hereinafter, “IQ4”), you are aware that
        in connection with those services being provided by IQ4, that you may be considered for
        employment opportunities with third parties (hereinafter, “Employers”) by virtue of your
        member profile and wallet (“Wallet”) with IQ4. This disclosure, pursuant to the Fair Credit
        Reporting Act (“FCRA”), codified as 15 U.S.C. §1681, is to inform you that in considering
        you for employment opportunities, and if you are employed, in considering you for promotion,
        assignment, reassignment, retention, or discipline with any third-party Employers, both IQ4
        and those Employers may request and rely upon one or more consumer reports about you that
        are obtained from a consumer reporting agency. Under the FCRA, before any third party
        Employer or any other company, including, but not limited to, IQ4, can obtain a consumer
        report about you for employment purposes, the company must obtain your written consent.
        Additionally, before any third Party Employer or any other company, including, but not
        limited to, IQ4, can take adverse action on the basis of the review of your consumer report,
        in whole or in part, you will be provided a copy of that report, the name, address, and
        telephone number of the consumer reporting agency, and a summary of your rights under the
        FCRA. <br />
        <br />
        For purposes of this disclosure, “consumer report” shall have the following definition:
        “Consumer Report” shall mean any written, oral or other communication of any information by
        a consumer reporting agency bearing on your credit worthiness, credit standing, credit
        capacity, character, general reputation, personal characteristics, or mode of living which
        is used or expected to be used or collected, in whole or in part, for the purpose of serving
        as a factor in making an employment-related decision concerning you.
        <br />
        <br /> Examples of such information include, but are not limited to, credit information,
        criminal history reports, and/or driving records.
        <br />
        <br />
        <strong>AUTHORIZATION</strong>
        <br />
        <br /> By clicking the box below titled “I Agree,” I hereby acknowledge that I have read and
        understand the terms set forth in the Disclosure Document regarding my rights under the Fair
        Credit Reporting Act, codified as 15 U.S.C. §1681. Following my review of the Disclosure
        Document, I authorize IQ4 Corporation (hereinafter “IQ4”) to obtain and rely upon any
        consumer reports concerning me and properly obtained from any consumer reporting agency or
        other source. Furthermore, following my review of the Disclosure Document, I also authorize
        any third party Employers to access my Member Wallit with IQ4 and to obtain and rely upon
        any consumer reports concerning me and contained therein and/or obtain and rely upon any
        consumer reports concerning me and properly obtained from any consumer reporting agency or
        other source. <br />
        <br />
        Furthermore, by clicking the box below titled “I Agree,” I further consent to have any
        legally required notices sent to me electronically.
        <br />
        <br />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <div>Full Name*</div>
        </div>
        <input
          disabled={true}
          type="text"
          className="iq4-terms-modal__input"
          value={`${personal?.userProfile?.user?.firstName} ${personal?.userProfile?.user?.lastName}`}
        />
        <br />
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
          <div>Date*</div>
        </div>
        <div style={{ width: '100%', display: 'flex' }}>
          <input
            disabled={true}
            type="text"
            className="iq4-terms-modal__input"
            style={{ width: '100px', marginRight: 20, textAlign: 'center' }}
            value={`${renderMonth()}`}
          />
          <input
            disabled={true}
            type="text"
            className="iq4-terms-modal__input"
            style={{ width: '100px', marginRight: 20, textAlign: 'center' }}
            value={`${renderDay()}`}
          />
          <input
            disabled={true}
            type="text"
            className="iq4-terms-modal__input"
            style={{ width: '100px', textAlign: 'center' }}
            value={`${moment(new Date()).year()}`}
          />
        </div>
        *I acknowledge and agree that the fields above may be executed and captured confirming my
        ascent electronically.{' '}
        {!hasAcceptedFCRA && (
          <div className="iq4-terms-modal__container--buttons">
            <Button disabled={isAcceptingFCRA} onClick={() => setIsCheckingFCRA(false)}>
              Reject
            </Button>
            <Button disabled={isAcceptingFCRA} onClick={acceptFCRA}>
              {!isAcceptingFCRA && <span>I agree</span>}
              {isAcceptingFCRA && <CircularProgress size={20} />}
            </Button>
          </div>
        )}
      </>
    );
  }

  if (
    !hasAcceptedTerms &&
    isLoggedIn &&
    hasFetchedProfile &&
    !excludeTerms.some((x) => location.pathname.indexOf(x) !== -1)
  ) {
    return (
      <Dialog
        fullScreen={breakpoint === 'xs'}
        disableBackdropClick
        open={true}
        onClose={() => {}}
        classes={{
          root: 'iq4-modal iq4-modal--2',
          paper: 'iq4-modal__onboarding',
        }}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="iq4-modal-title"
        BackdropProps={{
          classes: {
            root: 'iq4-modal__backdrop',
          },
        }}
      >
        <div className="iq4-terms-modal__container">
          {isCheckingFCRA && (
            <SimpleIcon
              onClick={() => setIsCheckingFCRA(false)}
              className="iq4-terms-modal__back"
              name="onboardingArrow"
            />
          )}
          <Logo />
          {isCheckingFCRA && renderFCRAContent()}
          {!isCheckingFCRA && renderNormalContent()}
        </div>
      </Dialog>
    );
  }

  return null;
};
