import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { Button, SimpleIcon } from 'common/components';
import { ROUTES } from 'routes';
import {
  submitPasswordResetReq,
  validateResetToken,
} from 'common/store/features/session/sessionSlice';
import { selectIsResettingPassword } from 'common/store/features/session/selectors';
import './ResetPasswordPage.scss';

export const ResetPasswordPage = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const [hasEightCharacters, setHasEightCharacters] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumbers, setHasNumbers] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [token, setToken] = useState(null);

  const history = useHistory();
  const dispatch = useDispatch();

  const isResettingPassword = useSelector(selectIsResettingPassword);

  const checkPassword = (password) => {
    // Check password against the conditions
    setHasEightCharacters(password.length >= 8);
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumbers(/\d/.test(password));
    setHasSpecialChar(/[!@#?\]]/.test(password));

    return hasEightCharacters && hasUppercase && hasLowercase && hasNumbers && hasSpecialChar;
  };

  useEffect(() => {
    // Enable the button if both passwords match and satisfy the conditions
    if (checkPassword(newPassword) && newPassword === confirmPassword) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tokenParam = params.get('token');
    setToken(tokenParam);
  }, [window.location]);

  useEffect(() => {
    const validateToken = async () => {
      if (token) {
        // Check if token is valid
        // If valid, allow user to reset password
        // If not valid, redirect to home page
        const result = await dispatch(validateResetToken(token));
        if (result.payload.status !== 200) {
          // Redirect to home page
          redirectToHome();
        }
      }
    };
    validateToken();
  }, [token, dispatch]);

  useEffect(() => {}, [token]);

  const hasLoginError = false;

  async function submitResetPassword() {
    const result = await dispatch(
      submitPasswordResetReq({ password1: newPassword, password2: confirmPassword, token }),
    );

    if (result?.payload?.status === 200) {
      redirectToHome();
    }
  }

  function redirectToHome() {
    history.push(ROUTES.HOME);
  }

  return (
    <main id="main">
      <div className="iq4-reset-password-form__container">
        <div className="iq4-reset-password-form__box">
          <div className="iq4-reset-password-form__box--logo">
            <Logo />
          </div>
          <>
            <h1 className="iq4-reset-password-form__box--title">Reset Password</h1>
            <div className="iq4-reset-password-form__forgot-info-text">
              <div className="iq4-reset-password-form__disclaimer">
                <div>When changing your password please follow these rules:</div>
                <br />
                <div className="iq4-reset-password-form__disclaimer--row">
                  At least 8 characters{' '}
                  {hasEightCharacters ? (
                    <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                  ) : null}
                </div>
                <div className="iq4-reset-password-form__disclaimer--row">
                  A mixture of both uppercase and lowercase letters{' '}
                  {hasUppercase && hasLowercase ? (
                    <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                  ) : null}
                </div>
                <div className="iq4-reset-password-form__disclaimer--row">
                  A mixture of letters and numbers.{' '}
                  {hasNumbers ? (
                    <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                  ) : null}
                </div>
                <div className="iq4-reset-password-form__disclaimer--row">
                  Inclusion of at least one special character, e.g., ! @ # ? ]{' '}
                  {hasSpecialChar ? (
                    <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                  ) : null}
                </div>
                <div className="iq4-reset-password-form__disclaimer--row">
                  Passwords match{' '}
                  {newPassword !== '' && newPassword === confirmPassword ? (
                    <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                  ) : null}
                </div>
              </div>
            </div>
            <div role="form" className="iq4-reset-password-form__box--login-container">
              <div className="iq4-reset-password-form__box--field-container">
                <label htmlFor="username">Your new Password:</label>
                <TextField
                  id="username"
                  type="password"
                  classes={{
                    root: hasLoginError
                      ? 'iq4-reset-password-form__box--field-input-root-error'
                      : 'iq4-reset-password-form__box--field-input-root',
                  }}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: hasLoginError
                        ? 'iq4-reset-password-form__box--field-input-error'
                        : 'iq4-reset-password-form__box--field-input',
                      notchedOutline: hasLoginError
                        ? 'iq4-reset-password-form__box--no-border'
                        : '',
                    },
                  }}
                  variant="outlined"
                />
              </div>
              <div className="iq4-reset-password-form__box--field-container">
                <label htmlFor="password" className="iq4-reset-password-form__box--field-label">
                  Confirm new Password:
                </label>
                <TextField
                  id="password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  clas
                  classes={{
                    root: hasLoginError
                      ? 'iq4-reset-password-form__box--field-input-root-error'
                      : 'iq4-reset-password-form__box--field-input-root',
                  }}
                  InputProps={{
                    classes: {
                      input: hasLoginError
                        ? 'iq4-reset-password-form__box--field-input-error'
                        : 'iq4-reset-password-form__box--field-input',
                      notchedOutline: hasLoginError
                        ? 'iq4-reset-password-form__box--no-border'
                        : '',
                    },
                  }}
                  variant="outlined"
                />
              </div>
              {hasLoginError && (
                <div className="iq4-reset-password-form__box--error-container">
                  <SimpleIcon name="error" /> Incorrect Username or Password
                </div>
              )}
              <div className="iq4-reset-password-form__box--button-container">
                <Button
                  onClick={redirectToHome}
                  className={`iq4-reset-password-form__box--cancel-button ${
                    hasLoginError ? 'iq4-reset-password-form__box--error-button' : ''
                  }`}
                >
                  Cancel
                </Button>
                <Button
                  loading={isResettingPassword}
                  disabled={!isButtonEnabled}
                  onClick={submitResetPassword}
                  className={`iq4-reset-password-form__box--button ${
                    hasLoginError ? 'iq4-reset-password-form__box--error-button' : ''
                  }`}
                  variation="ghost"
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        </div>
      </div>
    </main>
  );
};
