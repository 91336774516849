import React from 'react';
import PropTypes from 'prop-types';
import MUIDrawer from '@material-ui/core/Drawer';
import './Drawer.scss';

/**
 * MUI Drawer Wrapper Component
 * @param isOpen - isDrawerOpen
 * @param anchor - position drawer e.g. left, right, top, bottom
 * @param className - className
 * @param children - Drawer Body
 * @constructer
 */

export const Drawer = ({ isOpen, anchor, className, children, ...props }) => (
  <MUIDrawer
    classes={{ root: 'iq4-drawer', paper: `iq4-drawer-paper ${className}` }}
    anchor={anchor}
    open={isOpen}
    {...props}
  >
    {children}
  </MUIDrawer>
);

Drawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchor: PropTypes.string,
  className: PropTypes.string,
};

Drawer.defaultProps = {
  anchor: 'left',
  className: '',
};
