import React from 'react';
import './Container.scss';

/**
 * Keep contents within a max-width container
 */
export const Container = ({ children, className, ...props }) => {
  return (
    <div className={`iq4-max-width-container ${className ? className : ''}`} {...props}>
      {children}
    </div>
  );
};
