import React, { useEffect, useState } from 'react';
import { UserFeedbackForm } from './UserFeedbackForm';
import { Button, Drawer, SimpleIcon } from 'common/components';
import './UserFeedback.scss';
import FocusLock from 'react-focus-lock';
import { useHistory } from 'react-router-dom';

export const UserFeedback = () => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [submittedFeedback, setSubmittedFeedback] = useState(false);
  const [marginTop, setMarginTop] = useState(false);

  useEffect(() => {
    // This code will run whenever the user navigates to a different screen
    if (window.location.href.includes('career-pathways')) {
      setMarginTop(true);
    } else {
      setMarginTop(false);
    }
  }, [history.location]);

  const handleOnSuccess = ({ data, error }) => {
    setSubmittedFeedback(true);
  };

  const handleOnError = ({ data, error }) => {};

  const handleDrawerToggle = (value) => {
    setIsOpen(value);

    // reset when drawer is closed
    if (!value) {
      setSubmittedFeedback(false);
    }
  };

  return (
    <div className="iq4-user-feedback">
      <Button
        variation="ghost"
        className="iq4-user-feedback__trigger"
        onClick={() => void handleDrawerToggle(!isOpen)}
      >
        Send us feedback
      </Button>

      {isOpen && (
        <Drawer
          anchor="right"
          variant="persistent"
          isOpen={isOpen}
          classes={{
            root: 'iq4-user-feedback__drawer-root',
            paper: `iq4-user-feedback__drawer-paper ${
              marginTop ? 'iq4-user-feedback__drawer-paper--margin-top' : ''
            }`,
          }}
        >
          <FocusLock returnFocus={true}>
            <div className="iq4-user-feedback__drawer-header">
              <h3 className="iq4-user-feedback__drawer-header-title">We Want Your Feedback</h3>
              <button
                className="iq4-user-feedback__drawer-header-close"
                onClick={() => void handleDrawerToggle(false)}
                aria-label="Close Feedback Drawer"
              >
                <SimpleIcon name="close" />
              </button>
              <hr />
            </div>

            {!submittedFeedback && (
              <p className="iq4-user-feedback__drawer-desc">
                We are committed to providing the best possible user experience. We value your
                feedback and are always looking for ways to improve our platform. Please take a
                moment to share your thoughts and suggestions with us.
              </p>
            )}

            {!submittedFeedback && (
              <UserFeedbackForm onSuccess={handleOnSuccess} onError={handleOnError} />
            )}

            {submittedFeedback && (
              <div className="iq4-user-feedback__success">
                <div className="iq4-user-feedback__success_title-container">
                  <SimpleIcon className="iq4-user-feedback__success_tick" name="tick" />
                  <p className="iq4-user-feedback__success_title">
                    Success, your feedback has been submitted
                  </p>
                </div>

                <p className="iq4-user-feedback__success_p">
                  Thank you for taking the time to share your feedback with us. We appreciate your
                  input and will review your feedback carefully. If we need any additional
                  information, we will contact you at the email address you provided.
                </p>
                <p className="iq4-user-feedback__success_p">Please keep the feedback coming!</p>

                <Button
                  className="iq4-user-feedback__success_btn"
                  variation="ghost"
                  onClick={() => void setSubmittedFeedback(false)}
                >
                  I have more feedback
                </Button>
              </div>
            )}
          </FocusLock>
        </Drawer>
      )}
    </div>
  );
};
