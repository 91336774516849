import React from 'react';
import { Modal } from 'common/components';
import './MyRecordsModal.scss';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { useScrollToTop } from 'common/hooks/useScrollToTop';

export const MyRecordsModal = ({
  isOpen,
  handleClose,
  title,
  children,
  className,
  onDeactivation,
  ...props
}) => {
  const isMobile = useIsMobile();

  useScrollToTop('.iq4-modal__paper', [children.key], () => isMobile && isOpen);

  return (
    <Modal
      className={`iq4-my-records__modal ${className ? className : ''}`}
      isOpen={isOpen}
      handleClose={() => handleClose(false)}
      title={title}
      onDeactivation={onDeactivation}
      {...props}
    >
      {children}
    </Modal>
  );
};
