import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './ActionIcon.scss';

export const ActionIcon = ({
  className,
  isDark,
  buttonClick,
  buttonLabel,
  iconName,
  isAriaHidden,
  ...actionIconProps
}) => (
  <button
    className={`iq4-action-icon ${isDark ? 'iq4-action-icon--dark' : ''} ${className}`}
    onClick={buttonClick}
    onKeyPress={buttonClick}
    aria-label={buttonLabel}
    {...actionIconProps}
  >
    <SimpleIcon
      className="iq4-action-icon__icon"
      name={iconName}
      role="presentation"
      aria-hidden={isAriaHidden}
    />
  </button>
);

ActionIcon.propTypes = {
  buttonClick: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  isDark: PropTypes.bool,
};

ActionIcon.defaultProps = {
  className: '',
  isAriaHidden: true,
  isDark: false,
};
