import React from 'react';
import { Handle, Position } from 'reactflow';
import Avatar from '@material-ui/core/Avatar';
import { useSelector } from 'react-redux';
import { selectProfile } from 'common/store/features/profile/selectors';

export function AvatarNode({ data, isConnectable = true }) {
  const { personal } = useSelector(selectProfile);

  const avatarUploaded = !!(
    personal &&
    personal.userProfilePhoto &&
    personal.userProfilePhoto.thumbnail
  );

  return (
    <div>
      {avatarUploaded && (
        <img
          style={{ width: 110, height: 110, borderRadius: '50%', objectFit: 'cover' }}
          src={personal.userProfilePhoto?.thumbnail}
          alt="Avatar"
        />
      )}
      {!avatarUploaded && (
        <Avatar
          style={{ width: '110px', height: '110px' }}
          className="iq4-pathway__empty-avatar"
          alt="Avatar"
          title={`${personal?.userProfile?.user?.firstName} ${personal?.userProfile?.user?.lastName} avatar`}
          src={null}
        />
      )}
      <Handle type="source" position={Position.Bottom} id="a" isConnectable={isConnectable} />
    </div>
  );
}
