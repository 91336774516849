import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  fetchWorkrolesByJobFamily,
  setIsFetchingWorkRoles,
} from 'common/store/features/framework/frameworkSlice';
import {
  selectJobFamilyWorkRoles,
  selectIsFetchingWorkRoles,
  selectJobFamilyByRoleJobFamilyId,
} from 'common/store/features/framework/selectors';
import {
  setShowJobFamilyExplanation,
  setShowJobRoleExplanation,
} from '../../store/features/ui/uiSlice';
import {
  selectShowJobFamilyExplanation,
  selectShowJobRoleExplanation,
} from '../../store/features/ui/selectors';
import { JobRoleExplanation } from '../../../pages/CareerPathwaysPage/components/JobRoleExplanation';
import { JobFamilyExplanation } from '../../../pages/CareerPathwaysPage/components/JobFamilyExplanation';

export const SelectWorkRoles = ({
  displayBackButton = true,
  jobFamily,
  onSelectWorkRole,
  onClose,
  selectedWorkRole,
}) => {
  const workRoles = useSelector(
    selectJobFamilyWorkRoles(selectedWorkRole ? selectedWorkRole.jobFamilyId : jobFamily?.id),
  );

  const selectedWorkRoleJobFamily = useSelector(
    selectJobFamilyByRoleJobFamilyId(selectedWorkRole?.jobFamilyId),
  );

  const isFetchingWorkRoles = useSelector(selectIsFetchingWorkRoles);
  const showJobFamilyExplanation = useSelector(selectShowJobFamilyExplanation);
  const showJobRoleExplanation = useSelector(selectShowJobRoleExplanation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !isFetchingWorkRoles &&
      selectedWorkRoleJobFamily &&
      !selectedWorkRoleJobFamily.hasFetchedAllRoles
    ) {
      dispatch(setIsFetchingWorkRoles(true));
      dispatch(fetchWorkrolesByJobFamily(selectedWorkRole.jobFamilyId));
    }
  }, [selectedWorkRole]);

  useEffect(() => {
    if (
      !isFetchingWorkRoles &&
      jobFamily &&
      Object.keys(jobFamily).length &&
      !jobFamily.hasFetchedAllRoles
    ) {
      dispatch(setIsFetchingWorkRoles(true));
      dispatch(fetchWorkrolesByJobFamily(jobFamily.id));
    }
  }, [jobFamily]);

  function displayLoadingState() {
    return (
      <>
        <div>
          <Skeleton variant="rect" style={{ width: '100%', marginBottom: 2 }} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" style={{ width: '33%', marginBottom: 2 }} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" style={{ width: '76%', marginBottom: 2 }} height={25} />
        </div>
        <div>
          <Skeleton variant="rect" style={{ width: '90%', marginBottom: 2 }} height={25} />
        </div>
      </>
    );
  }

  function showJobFamilyExplanationFunc() {
    dispatch(setShowJobFamilyExplanation(!showJobFamilyExplanation));
    dispatch(setShowJobRoleExplanation(false));
  }

  function showJobRoleExplanationFunc() {
    dispatch(setShowJobRoleExplanation(!showJobRoleExplanation));
    dispatch(setShowJobFamilyExplanation(false));
  }

  return (
    <div className="iq4-pathway-detail__selection-container">
      {!!showJobRoleExplanation && (
        <JobRoleExplanation
          hideJobRoleExplanation={() => dispatch(setShowJobRoleExplanation(false))}
        />
      )}
      {!!showJobFamilyExplanation && (
        <JobFamilyExplanation
          hideJobFamilyExplanation={() => dispatch(setShowJobFamilyExplanation(false))}
        />
      )}
      {displayBackButton && (
        <button className="iq4-pathway-detail__selection-container--cancel" onClick={onClose}>
          {selectedWorkRole ? 'Cancel' : 'Back'}
        </button>
      )}
      <div className="iq4-pathway-detail__selection-container--title">
        {selectedWorkRole ? 'ROLE' : 'JOB FAMILY'}
        <div
          className="iq4-categories__helper"
          role="button"
          tabIndex={0}
          onClick={selectedWorkRole ? showJobRoleExplanationFunc : showJobFamilyExplanationFunc}
          onKeyPress={selectedWorkRole ? showJobRoleExplanationFunc : showJobFamilyExplanationFunc}
        >
          What is a {`${selectedWorkRole ? 'Role' : 'Job Family'}?`}
        </div>
      </div>
      <TextField
        value={selectedWorkRole ? selectedWorkRole?.label : jobFamily?.label}
        classes={{
          root: 'iq4-pathway-detail__location-input--field-input-root',
        }}
        InputProps={{
          readOnly: true,
          classes: {
            input: 'iq4-pathway-detail__location-input--field-input',
          },
        }}
        variant="outlined"
      />
      <div className="iq4-pathway-detail__selection-container--root">
        {(!workRoles || isFetchingWorkRoles) && displayLoadingState()}
        {(workRoles || []).map((workRole) => {
          const isSelectedWorkRole = selectedWorkRole?.id === workRole.id;
          return (
            <button
              className={`iq4-pathway-detail__selection-container--item ${
                isSelectedWorkRole ? 'iq4-pathway-detail__selection-container--selected' : ''
              }`}
              key={workRole.id}
              onClick={isSelectedWorkRole ? undefined : () => onSelectWorkRole(workRole)}
              aria-label={`Select ${workRole.label} work role`}
            >
              <span className="iq4-pathway-detail__selection-container--label">
                {workRole.label}
              </span>
              {isSelectedWorkRole && <CheckCircleIcon />}
            </button>
          );
        })}
      </div>
    </div>
  );
};
