import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { selectSkillExpandingId, selectSkillExpandedId } from 'common/store/features/ui/selectors';
import { setSkillExpandingId, setSkillExpandedId } from 'common/store/features/ui/uiSlice';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { orderBy } from 'lodash';
import { SORT_DIRECTION } from 'common/utils/constants';
import './ManageSkillsCard.scss';
import {
  SimpleIcon,
  SkillCard,
  LoadingSkeleton,
  Button,
  Toast,
  Paginator,
} from 'common/components';
import { ManageSkillsCardFilter } from './ManageSkillsCardFilter';
import { ManageSkillsCardSort } from './ManageSkillsCardSort';
import { FilterBanner } from './FilterBanner';
import EmptyStateImage from '@assets/svg/empty-states_skills.svg';
import {
  DEFAULT_SKILLS_FILTER,
  DEFAULT_SKILLS_SORT,
  DEFAULT_TABLE_PAGE_SIZE,
  SKILLS_SORT_FUNCS,
} from 'common/constants/miscellaneous';
import { runGenericFilter } from 'common/utils';
import { removeNewlyAddedFlag, fetchSkillDetails } from 'common/store/features/skills/skillsSlice';
import { selectFeatures } from 'store/features/config/selectors';
import { SelectPageSize } from '../DataTable/SelectPageSize';
import _orderBy from 'lodash/orderBy';
import { selectIsPublicProfile } from '../../store/features/profile/selectors';
import { useParams } from 'react-router-dom';

const dummySkill = {
  competenceId: 12345,
  source: 0,
  competenceGroup: 'Example Skills',
  competenceGroupId: 'CG00',
  outcomeDescription: null,
  outcomeUrl: null,
  outcomeType: null,
  tier1Id: 1,
  tier1Label: 'EX',
  tier2Id: 2,
  tier2Label: 'EX',
  tier3Id: 3,
  tier3Label: 'Example Skill',
  tier3Description:
    'This is a placeholder skill used to demonstrate how skills will be displayed on your profile. A real skill description would go here, detailing what the skill entails, its importance, and potential applications in various contexts.',
  dateAdded: 1690758000000,
  isNewSkill: false,
  isPublic: null,
  isHidden: false,
  rank: null,
  isVerified: null,
  assessment: 0,
  achievements: [],
  assessments: [
    {
      assesseeId: 1,
      assesseeName: 'self',
      assessment: 0,
      dateCreated: 1690758000000,
    },
  ],
  ksas: [
    {
      competencies: null,
      id: 111111,
      isSelected: true,
      isVerified: null,
      label: 'Example Key Skill Attribute 1.',
    },
    {
      competencies: null,
      id: 111112,
      isSelected: true,
      isVerified: null,
      label: 'Example Key Skill Attribute 2.',
    },
    {
      competencies: [
        {
          achievements: null,
          assessment: null,
          class: 'iq4.dto.CompetenceDTO',
          classifyResult: null,
          competenceGroup: null,
          competenceGroupId: null,
          competenceId: 22222,
          description: null,
          foundIn: null,
          id: null,
          label: 'Example Sub-skill 1',
          outcomeDescriptionUrl: null,
          outcomeId: null,
          outcomeType: null,
          score: null,
          source: null,
          tier1: null,
          tier2: null,
          tier3: null,
          tier4: null,
        },
        {
          achievements: null,
          assessment: null,
          class: 'iq4.dto.CompetenceDTO',
          classifyResult: null,
          competenceGroup: null,
          competenceGroupId: null,
          competenceId: 33333,
          description: null,
          foundIn: null,
          id: 333333,
          label: 'Example Sub-skill 2',
          outcomeDescriptionUrl: null,
          outcomeId: null,
          outcomeType: null,
          score: null,
          source: null,
          tier1: null,
          tier2: null,
          tier3: null,
          tier4: null,
        },
      ],
      id: 111113,
      isSelected: false,
      isVerified: null,
      label: 'Example Key Skill Attribute 3.',
    },
    {
      competencies: null,
      id: 111114,
      isSelected: true,
      isVerified: null,
      label: 'Example Key Skill Attribute 4.',
    },
    {
      competencies: null,
      id: 111115,
      isSelected: true,
      isVerified: null,
      label: 'Example Key Skill Attribute 5.',
    },
    {
      competencies: null,
      id: 111116,
      isSelected: true,
      isVerified: null,
      label: 'Example Key Skill Attribute 6.',
    },
  ],
  sources: [
    {
      id: 9999,
      isVerified: false,
      title: 'Example Educational Background',
      type: 'education',
    },
    {
      id: 8888,
      isVerified: false,
      title: 'Example Work Experience',
      type: 'portfolio',
    },
    {
      id: null,
      isVerified: false,
      title: null,
      type: 'resume',
    },
  ],
  tier1: {
    id: 1,
    label: 'EX',
  },
  tier2: {
    id: 2,
    label: 'EX',
  },
  tier3: {
    description:
      'This is a placeholder skill used to demonstrate how skills will be displayed on your profile. A real skill description would go here, detailing what the skill entails, its importance, and potential applications in various contexts.',
    id: 3,
    label: 'Example Skill',
  },
  educationalItems: [
    {
      id: 9999,
      isVerified: false,
      title: 'Example Educational Background',
      type: 'education',
    },
  ],
};

/**
 * Manage Skills Card - holds control of skills filtering & sorting
 * @param skills
 * @param showDummySkill
 * @returns {*}
 * @constructor
 */
export const ManageSkillsCard = ({ skills, showDummySkill = false }) => {
  const { selfAttestedEnabled } = useSelector(selectFeatures);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_TABLE_PAGE_SIZE);
  const [currentSort, setCurrentSort] = useState(() => getCurrentSort(selfAttestedEnabled));
  const [currentFilter, setCurrentFilter] = useState(DEFAULT_SKILLS_FILTER);
  const [skillsRefs, setSkillsRefs] = useState([]);
  const [scrollToElement, setScrollToElement] = useState(null);
  const [paginationData, setPaginationData] = useState({ currentPage: 1, pageLimit: rowsPerPage });
  const [currentSkills, setCurrentSkills] = useState([]);
  const [filteredSkills, setFilteredSkills] = useState(
    orderBy(skills, (s) => s.tier3Label, SORT_DIRECTION.ASC),
  );
  const [clearPagination, setClearPagination] = useState(false);

  const dispatch = useDispatch();

  const expandedId = useSelector(selectSkillExpandedId);
  const expandingId = useSelector(selectSkillExpandingId);
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const hasLoaded = !isBootstrapping;
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const { id } = useParams();

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    const hasAnyFilter = Object.keys(currentFilter).some((k) => currentFilter[k]);

    if (hasAnyFilter) {
      applyFilters(currentFilter, true);
    } else {
      const activeSort = getActiveSort(currentSort);
      const newFilteredSkills = SKILLS_SORT_FUNCS[activeSort || 'aToZ'](skills);
      setFilteredSkills(newFilteredSkills);

      const refs = newFilteredSkills.reduce((acc, value) => {
        acc[value.competenceId] = React.createRef();
        return acc;
      }, {});

      setSkillsRefs(refs);
    }
  }, [skills, dispatch, currentSort]);

  useEffect(() => {
    if (scrollToElement && skillsRefs[scrollToElement].current) {
      skillsRefs[scrollToElement].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScrollToElement(null);
    }
  }, [scrollToElement, skillsRefs]);

  const expandSkill = (skill) => {
    // check if it's currently expanded, and collapse
    if (skill.competenceId === expandedId) {
      dispatch(setSkillExpandedId(-1));
    } else if (skill.tier1 && !!skill.sources) {
      dispatch(setSkillExpandedId(skill.competenceId));
    } else {
      dispatch(setSkillExpandingId(skill.competenceId));
      dispatch(
        fetchSkillDetails({
          competenceId: skill.competenceId,
          isPublic: !!isPublicProfile,
          userId: id,
        }),
      );
    }
  };

  const updateSkillsData = (data) => {
    const { currentPage, pageLimit } = data;
    const activeSort = getActiveSort(currentSort);
    const newOrderedRows = _orderBy(filteredSkills, [activeSort], [currentSort.direction]);
    const offset = (currentPage - 1) * pageLimit;

    const newCurrentSkills =
      pageLimit === skills.length
        ? newOrderedRows
        : newOrderedRows.slice(offset, offset + pageLimit);

    setPaginationData({
      ...paginationData,
      ...data,
    });

    setCurrentSkills(newCurrentSkills);
  };

  useEffect(() => {
    if (filteredSkills.length <= rowsPerPage || clearPagination) {
      updateSkillsData({ currentPage: 1, pageLimit: rowsPerPage });
      setClearPagination(false);
    }
  }, [filteredSkills, rowsPerPage, clearPagination]);

  useEffect(() => {
    if (paginationData && !clearPagination) {
      updateSkillsData(paginationData);
    }
  }, [currentSort, filteredSkills, skills, clearPagination]);

  const getActiveSort = (sort) => Object.keys(sort).find((key) => sort[key]);

  const manageChangeFilter = (currentFilter) => {
    setCurrentFilter(currentFilter);
    const clearPagination = Object.values(currentFilter).every((val) => val === false);
    if (clearPagination) {
      setClearPagination(true);
    }
    applyFilters(currentFilter);
  };

  const manageChangeSort = (newCurrentSort) => {
    setCurrentSort(newCurrentSort);
    const newSort = getActiveSort(newCurrentSort);
    applySort(newSort);
  };

  const clearAllFilters = () => {
    setCurrentFilter(DEFAULT_SKILLS_FILTER);
    const newFilteredSkills = orderBy(skills, (s) => s.tier3Label, SORT_DIRECTION.ASC);
    setFilteredSkills(newFilteredSkills);
  };

  const handlePageSizeChange = (value) => setRowsPerPage(value);

  const applySort = (newSort, target = filteredSkills) =>
    setFilteredSkills(SKILLS_SORT_FUNCS[newSort](target));

  function filterCategory(inputSkills, inputFilter) {
    const categoryProps = [
      'technologyCategory',
      'professionalSkillsCategory',
      'businessDevelopmentCategory',
    ];
    const hasSomeFilter =
      inputFilter.technologyCategory ||
      inputFilter.professionalSkillsCategory ||
      inputFilter.businessDevelopmentCategory;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;
    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function filterOutcomeType(inputSkills, inputFilter) {
    const categoryProps = ['outcomeTypeCommon', 'outcomeTypeHard'];
    const hasSomeFilter = inputFilter.outcomeTypeCommon || inputFilter.outcomeTypeHard;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;

    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function filterProficiencies(inputSkills, inputFilter) {
    const categoryProps = [
      'proficiencyOne',
      'proficiencyTwo',
      'proficiencyThree',
      'proficiencyFour',
    ];
    const hasSomeFilter =
      inputFilter.proficiencyOne ||
      inputFilter.proficiencyTwo ||
      inputFilter.proficiencyThree ||
      inputFilter.proficiencyFour;

    // if no filter is applied just return input
    if (!hasSomeFilter) return inputSkills;
    return runGenericFilter({ categoryProps, inputFilter, inputSkills });
  }

  function filterIsVerifiedStatus(inputSkills, inputFilter) {
    if (inputFilter.isVerified) {
      return inputSkills.filter((skill) => skill.isVerified === true);
    }

    return inputSkills;
  }

  function filterIsSelfAttestedStatus(inputSkills, inputFilter, prevSkillsList) {
    if (inputFilter.isSelfAttested && !inputFilter.isVerified) {
      return inputSkills.filter((skill) => !skill.isVerified);
    } else if (inputFilter.isSelfAttested && inputFilter.isVerified) {
      return prevSkillsList;
    }
    return inputSkills;
  }

  const applyFilters = (currentFilter, refreshRefs) => {
    const filteredByCategory = filterCategory(skills, currentFilter);
    const filteredByOutcomeTypes = filterOutcomeType(filteredByCategory, currentFilter);
    const filteredByProficiencies = filterProficiencies(filteredByOutcomeTypes, currentFilter);
    const filteredByIsVerifiedStatus = filterIsVerifiedStatus(
      filteredByProficiencies,
      currentFilter,
    );

    const filteredByIsSelfAttestedStatus = filterIsSelfAttestedStatus(
      filteredByIsVerifiedStatus,
      currentFilter,
      filteredByProficiencies,
    );

    if (refreshRefs) {
      const refs = filteredByIsSelfAttestedStatus.reduce((acc, value) => {
        acc[value.competenceId] = React.createRef();
        return acc;
      }, {});

      setSkillsRefs(refs);
    }

    const activeSort = getActiveSort(currentSort);
    applySort(activeSort, filteredByIsSelfAttestedStatus);
  };

  const onRemoveFilter = (prop) => {
    const newFilter = {
      ...currentFilter,
      [prop]: false,
    };
    setCurrentFilter(newFilter);
    applyFilters(newFilter);
  };

  const goToPageAndScroll = (page, skill) => {
    updateSkillsData({ currentPage: page, pageLimit: rowsPerPage });

    if (skill.tier1 && !!skill.sources) {
      dispatch(setSkillExpandedId(skill.competenceId));
    } else {
      dispatch(setSkillExpandingId(skill.competenceId));
      dispatch(fetchSkillDetails({ competenceId: skill.competenceId }));
    }

    dispatch(removeNewlyAddedFlag(skill.competenceId));
    setScrollToElement(skill.competenceId);
  };

  const newlyAddedSkills = filteredSkills.filter((skill) => skill.newlyAdded);

  const getToastContent = () => {
    return newlyAddedSkills.map((skill) => {
      const skillIndex = filteredSkills.findIndex((s) => s.competenceId === skill.competenceId);
      const whichPageIsSkillIn = Math.floor(skillIndex / paginationData.pageLimit) + 1;

      return (
        <div className="iq4-manage-skills__toast-content" key={skill.competenceId}>
          You have added <strong>{skill.tier3Label}</strong> to your Manage Skills list <br />
          <span
            role="button"
            aria-label={`Go to ${skill.tier3Label} role details`}
            tabIndex={0}
            onKeyPress={() => goToPageAndScroll(whichPageIsSkillIn, skill)}
            className="iq4-manage-skills__toast-view"
            onClick={() => goToPageAndScroll(whichPageIsSkillIn, skill)}
          >
            View it now
          </span>{' '}
          or edit later
        </div>
      );
    });
  };

  const dismissAllNewlyAddedSkills = () => {
    newlyAddedSkills.forEach((skill) => {
      dispatch(removeNewlyAddedFlag(skill.competenceId));
    });
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  function getCurrentSort(selfAttestedEnabled) {
    if (selfAttestedEnabled) return DEFAULT_SKILLS_SORT;

    const { lowToHigh: _, highToLow: __, ...allExProf } = DEFAULT_SKILLS_SORT;

    return allExProf;
  }

  return (
    <div
      className={`iq4-manage-skills-card ${
        hasLoaded && skills.length === 0 && 'iq4-manage-skills-card--empty'
      }`}
    >
      {!!newlyAddedSkills.length && (
        <Toast
          title="new skill added"
          onClose={dismissAllNewlyAddedSkills}
          ariaLabel="Close the new skill added notification"
        >
          {getToastContent()}
        </Toast>
      )}
      <div className="iq4-manage-skills-card__header">
        <div className="iq4-manage-skills-card__header--left">
          <div className="iq4-manage-skills-card__header-container">
            {!isMobile && (
              <>
                <SimpleIcon
                  className="iq4-manage-skills-card__header-icon"
                  name="skillsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-manage-skills-card__header-title">Skills</h2>
                {hasLoaded ? (
                  <>
                    <span className="iq4-manage-skills-card__length">{skills.length}</span>
                  </>
                ) : (
                  <LoadingSkeleton
                    variant="circle"
                    width={20}
                    height={20}
                    style={{ display: 'inline-flex', justifyContent: 'center' }}
                  />
                )}
              </>
            )}
            {skills.length > 0 && (
              <div className="iq4-manage-skills__pagination">
                <ManageSkillsCardSort
                  currentSort={currentSort}
                  onChangeSort={manageChangeSort}
                  hasProfFilter={false}
                />
                {!isMobile && (
                  <div className="iq4-datatable__paginator-container">
                    <SelectPageSize
                      handlePageSizeChange={handlePageSizeChange}
                      rows={filteredSkills}
                      rowsPerPage={rowsPerPage}
                    />
                    <span
                      className="iq4-datatable__view-all"
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => handlePageSizeChange(filteredSkills.length)}
                      onClick={() => handlePageSizeChange(filteredSkills.length)}
                    >
                      View All
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {filteredSkills.length > rowsPerPage && !isMobile && (
          <div className="iq4-manage-skills__pagination">
            <Paginator
              totalRecords={filteredSkills.length}
              onPageChanged={updateSkillsData}
              pageLimit={rowsPerPage}
              outCurrentPage={paginationData ? paginationData.currentPage : null}
            />
          </div>
        )}
        {skills.length > 0 && !isPublicProfile && (
          <ManageSkillsCardFilter
            currentFilter={currentFilter}
            onChangeFilter={manageChangeFilter}
            hasProfFilter={false}
          />
        )}
      </div>
      <ul className="iq4-manage-skills-card__content">
        <FilterBanner currentFilter={currentFilter} onRemoveFilter={onRemoveFilter} />
        {hasLoaded ? (
          <>
            {(isMobile
              ? filteredSkills
              : !!currentSkills?.length
              ? currentSkills
              : filteredSkills
            ).map((skill, index) => (
              <li
                className="iq4-manage-skills-card__skill-card"
                key={skill.competenceId}
                ref={skillsRefs[skill.competenceId]}
              >
                {index === 0 && showDummySkill && (
                  <SkillCard
                    skill={dummySkill}
                    handleClick={() => expandSkill(dummySkill)}
                    isExpanding={dummySkill.competenceId === expandingId}
                    isExpanded={dummySkill.competenceId === expandedId}
                    key={dummySkill.competenceId}
                    index={index}
                    readOnly={!selfAttestedEnabled}
                  />
                )}
                <SkillCard
                  skill={skill}
                  handleClick={() => expandSkill(skill)}
                  isExpanding={skill.competenceId === expandingId}
                  isExpanded={skill.competenceId === expandedId}
                  key={skill.competenceId}
                  index={index}
                  readOnly={!selfAttestedEnabled}
                />
              </li>
            ))}
            {skills.length === 0 && (
              <div className="iq4-manage-skills-card__empty-state">
                <img
                  className="iq4-manage-skills-card__empty-state-image"
                  src={EmptyStateImage}
                  alt=""
                />
                <p>You currently have no skills</p>
              </div>
            )}
            {!!(!filteredSkills.length && skills.length) && (
              <div className="iq4-manage-skills-card__no-results-container">
                <div className="iq4-manage-skills-card__no-results">No results returned!</div>
                <div className="iq4-manage-skills-card__no-results">
                  Try dismissing some of the filter terms above
                </div>
                <div className="iq4-manage-skills-card__no-results-normal">or</div>
                <div className="iq4-manage-skills-card__no-results-normal">Clear filters</div>
                <div className="iq4-manage-skills-card__no-results-normal">
                  <Button variation="ghost" onClick={clearAllFilters}>
                    Clear
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
            <LoadingSkeleton
              variant="rect"
              width="100%"
              height={80}
              style={{ marginTop: '10px' }}
            />
          </>
        )}
      </ul>
    </div>
  );
};
