import React, { useState } from 'react';
import { Modal } from 'common/components/Modal';
import { ProfileAvatar } from 'components/Profile/ProfileAvatar';
import { Button, ActionIcon, SimpleIcon } from 'common/components';
import { useSelector } from 'react-redux';
import {
  selectIsPublicProfile,
  selectProfile,
  selectUserProfile,
} from 'common/store/features/profile/selectors';
import { FormWrapper } from 'common/components/Form';
import { OnboardingHeader } from 'common/components/Onboarding';
import { useProfilePersonalFormDefinition } from 'common/hooks/forms';
import { ensureHTTPSProtocol } from 'common/utils';
import './ProfilePersonal.scss';

export const ProfilePersonal = () => {
  const userProfile = useSelector(selectUserProfile);
  const [isOpen, setIsOpen] = useState(false);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const { personal } = useSelector(selectProfile);
  const isPopulated = userProfile;

  const userProfilePhotoData = personal?.userProfilePhoto || {
    id: null,
    image: null,
    imageName: null,
    thumbnail: null,
    type: null,
  };

  const onAdd = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const goToLinkedIn = () => {
    window.open(ensureHTTPSProtocol(userProfile.linkedIn), '_blank');
  };

  return (
    <>
      <div className="iq4-personal-card">
        <div className="iq4-personal-card__heading">
          <div className="iq4-personal-card__heading-link">
            <SimpleIcon
              className="iq4-personal-card__heading-icon"
              name="personCircle"
              role="presentation"
              hidden
            />
            <h2 className="iq4-personal-card__heading-title">Personal</h2>
          </div>
          {!isPublicProfile && (
            <ActionIcon isDark iconName="edit" buttonClick={onAdd} buttonLabel="Edit Personal" />
          )}
        </div>

        <ProfileAvatar onAvatarClick={onAdd} />

        <div className="iq4-personal-card__body">
          {isPopulated && (
            <>
              <p className="iq4-personal-card__item iq4-personal-card__item--name">
                {userProfile?.user?.firstName} {userProfile?.user?.lastName}
              </p>
              {userProfile.currentPosition && (
                <p className="iq4-personal-card__item">{userProfile.currentPosition}</p>
              )}
              {userProfile.description && (
                <p className="iq4-personal-card__item">{userProfile.description}</p>
              )}
              {userProfile.linkedIn && (
                <p className="iq4-personal-card__item">
                  <Button
                    variation="ghost"
                    className="iq4-personal-card__linkedin"
                    onClick={() => goToLinkedIn()}
                  >
                    <SimpleIcon name="linkedIn" />
                    View LinkedIn Page
                  </Button>
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        title={<OnboardingHeader icon="personCircle" title="Personal" />}
        className="iq4-personal-card__modal_edit"
        onDeactivation={() => {
          setTimeout(() => document.querySelector('.iq4-action-icon').focus(), 100);
        }}
      >
        <FormWrapper
          values={{
            ...userProfile,
            firstName:
              userProfile &&
              userProfile.user &&
              userProfile.user.firstName &&
              userProfile.user.firstName,
            lastName:
              userProfile &&
              userProfile.user &&
              userProfile.user.lastName &&
              userProfile.user.lastName,
            email: userProfile && userProfile.user && userProfile.user.email,
            image: userProfilePhotoData.image,
          }}
          onComplete={handleClose}
          useFormDefinition={useProfilePersonalFormDefinition}
        />
      </Modal>
    </>
  );
};
