import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { selectFeatures } from 'store/features/config/selectors';
import { selectConsentForPlanning } from 'common/store/features/records/selectors';
import {
  fetchWalletProfile,
  updateWalletProfileConsentReq,
} from 'common/store/features/records/recordsSlice';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { ProfileResumeParse } from 'components/Profile';
import { ConsentBanner, Topbar, Footer } from 'common/components';
import { MobileProfileExpand } from '../MobileProfileExpand';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import './ProfilePageMobile.scss';

export const ProfilePageMobile = () => {
  const dispatch = useDispatch();
  const [isConsentDisplayed, setIsConsentDisplayed] = useState(false);
  const [showResumeParser, setShowResumeParser] = useState(false);

  const { applicationTitle, personalProfileSource, consentForPlanningEnabled } = useSelector(
    selectFeatures,
  );

  const consentForPlanning = useSelector(selectConsentForPlanning);

  useEffect(() => {
    setIsConsentDisplayed(consentForPlanningEnabled && consentForPlanning?.status === false);
  }, [consentForPlanningEnabled, consentForPlanning]);

  useEffect(() => {
    consentForPlanningEnabled && dispatch(fetchWalletProfile());
  }, [consentForPlanningEnabled]);

  useEffect(() => {
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
  }, [dispatch, personalProfileSource]);

  const handleOnAccept = (type) => {
    dispatch(
      updateWalletProfileConsentReq({
        [type]: true,
      }),
    );
  };

  return (
    <>
      <Helmet title={applicationTitle} />
      <section className="iq4-profile-page-mobile">
        <div className="iq4-profile-page-mobile__main">
          <Topbar Logo={Logo} onUploadResume={() => setShowResumeParser(true)} />

          {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}

          {isConsentDisplayed && (
            <ConsentBanner
              title="Planning Consent"
              message={consentForPlanning?.message}
              acceptLabel="Accept"
              onAccept={() => handleOnAccept('consentForPlanning')}
            />
          )}
          <MobileProfileExpand className="iq4-profile-page-mobile__tabs" />
        </div>

        <Footer className="iq4-profile-page-mobile__footer" />
      </section>
    </>
  );
};
