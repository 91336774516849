import React from 'react';
import { FormattedMessage } from 'react-intl';

export const validations = {
  isRequired: (message) => ({
    value: true,
    ...(message ? { message } : { message: <FormattedMessage id="form.validation.required" /> }),
  }),
  hasMinLength: (value, message) => ({
    value,
    ...(message
      ? { message }
      : { message: <FormattedMessage id="form.validation.minLength" values={{ value }} /> }),
  }),
  hasMaxLength: (value, message) => ({
    value,
    ...(message
      ? { message }
      : { message: <FormattedMessage id="form.validation.maxLength" values={{ value }} /> }),
  }),
};
