import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './Alert.scss';

export const Alert = (props) => {
  const { variation, title, description, alertButton, alertButtonAction } = props;
  return (
    <div role="alert" className={`iq4-alert iq4-alert--${variation}`}>
      <SimpleIcon
        className="iq4-alert__icon"
        name={variation}
        role="presentation"
        aria-hidden="true"
      />
      <div className="iq4-alert__content">
        <p className="iq4-alert__title">{title}</p>
        <p className="iq4-alert__description">{description}</p>
        {alertButton && (
          <button className="iq4-alert__button" onClick={alertButtonAction}>
            {alertButton}
          </button>
        )}
      </div>
    </div>
  );
};

Alert.propTypes = {
  variation: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  alertButton: PropTypes.string,
  alertButtonAction: PropTypes.func,
};

Alert.defaultProps = {
  variation: 'error',
  alertButton: null,
  alertButtonAction: null,
};
