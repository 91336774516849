import React from 'react';
import OnboardingInfoPanel from './OnboardingInfoPanel';
import profileImage from '@assets/png/profile-illustrated-for-onboarding.png';
import './ResumeInfoPanel.scss';

export const ResumeInfoPanel = ({ userProfile }) => (
  <OnboardingInfoPanel>
    <div className="iq4-resume-info-panel">
      <div className="iq4-resume-info-panel__content">
        <h1 className="iq4-resume-info-panel__header">Welcome</h1>
        <p className="iq4-resume-info-panel__intro">
          Hi{' '}
          <span className="iq4-resume-info-panel__firstname">{userProfile?.user?.firstName}</span>,
        </p>
        <p>
          Uploading your resume to your Wallet can significantly enhance your job search experience
          and career prospects. Here are some key benefits:
        </p>
        <p>
          <b>Skills Highlight:</b> Your resume is assessed based on skills, ensuring your strengths
          are highlighted effectively.
        </p>
        <p>
          <b>Targeted Job Matches:</b> Analyze your skills and match with relevant job
          opportunities.
        </p>
        <p>Take advantage and jumpstart your career and land the job you deserve!</p>
      </div>
      <img
        className="iq4-resume-info-panel__sample-profile"
        src={profileImage}
        alt="Sample profile"
      />
    </div>
  </OnboardingInfoPanel>
);
