import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleIcon } from 'common/components';
import { selectErrors } from 'common/store/features/errors/selectors';
import { dismissError, setError } from 'common/store/features/errors/errorsSlice';
import './ErrorNotification.scss';
import { useIsMobile } from 'common/hooks/useIsMobile';

export const ErrorNotification = () => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const location = useLocation();
  const errorData = useSelector(selectErrors);
  const initialRenderRef = useRef(null);
  const mobileNodeRef = useRef(null);

  useEffect(() => {
    if (!initialRenderRef.current) {
      initialRenderRef.current = true;
      return;
    }

    if (initialRenderRef.current && errorData.hasError) {
      handleDismissError();
    }
  }, [location.pathname]);

  // mobile notification component positioning
  useEffect(() => {
    if (isMobile) {
      const rootNode = document.querySelector('#root');

      if (errorData.hasError) {
        rootNode.style.transform = `translateY(${mobileNodeRef.current.offsetHeight}px)`;
      } else {
        rootNode.removeAttribute('style');
      }
    } else {
      if (!!errorData.message) {
        setTimeout(() => {
          dispatch(dismissError());
        }, 4000);
      }
    }
  }, [isMobile, errorData]);

  const handleDismissError = () => {
    dispatch(dismissError());
  };

  const renderMobile = () => {
    return (
      <div ref={mobileNodeRef} className="iq4-error-notification-mobile">
        <div className="iq4-error-notification-mobile--flex">
          <SimpleIcon name="warning2" />
          <span className="iq4-error-notification__text">{errorData.message}</span>
        </div>
        {errorData.canDismiss && (
          <SimpleIcon
            onClick={handleDismissError}
            className="iq4-error-notification-mobile--close"
            name="close"
          />
        )}
      </div>
    );
  };

  const renderDefault = () => {
    return (
      <div className="iq4-error-notification">
        <SimpleIcon name="warning2" />
        {errorData.message}
        {errorData.canDismiss && (
          <SimpleIcon
            onClick={handleDismissError}
            className="iq4-error-notification--close"
            name="close"
          />
        )}
      </div>
    );
  };

  if (!errorData.hasError) return null;

  return createPortal(
    <>
      {isMobile && renderMobile()}
      {!isMobile && renderDefault()}
    </>,
    document.body,
  );
};
