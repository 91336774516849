import React, { useState } from 'react';
import moment from 'moment';
import { SimpleIcon, PromptModal } from 'common/components';
import './OnboardingList.scss';

export const OnboardingList = ({
  records,
  onEdit = () => {},
  onDelete = () => {},
  renderFields = () => {},
  listType,
}) => {
  const [deleteItem, setDeleteItem] = useState(null);

  const renderDates = ({ startMonth, startYear, endMonth, endYear, isCurrentRole }) => {
    const formatMonth = (month) => (month ? moment(month, 'MM').format('MMM') : '');

    const startDates = `${formatMonth(startMonth)}${startMonth && startYear ? ' ' : ''}${
      startYear || ''
    }`;

    const endDates = isCurrentRole
      ? 'Current'
      : `${formatMonth(endMonth)}${endMonth && endYear ? ' ' : ''}${endYear || ''}`;

    return `${startDates}${startDates && endDates ? ' - ' : ''}${endDates}`;
  };

  if (records?.length === 0) {
    return null;
  }

  return (
    <div className="iq4-onboarding-list">
      {records?.map((item, index) => (
        <div className="iq4-onboarding-list__item">
          <PromptModal
            hideDismiss
            isOpen={deleteItem && deleteItem === item}
            handleClose={() => setDeleteItem(null)}
            title="Are you sure you want to delete this?"
            buttonLabel="Delete"
            buttonAction={() => onDelete(deleteItem, setDeleteItem)}
          />

          {renderFields(item, renderDates)}
          {!item.verifiedBy && (
            <div className="iq4-onboarding-list__item__icon-container">
              <button
                className={`iq4-trigger-modal__${listType || 'onboarding'}__${index}`}
                aria-label={`Edit ${item.title} ${listType || ''} record`}
                onClick={() =>
                  onEdit(item, `.iq4-trigger-modal__${listType || 'onboarding'}__${index}`)
                }
              >
                <SimpleIcon name="edit" />
              </button>

              <button
                aria-label={`Delete ${item.title} ${listType} record`}
                onClick={() => setDeleteItem(item)}
              >
                <SimpleIcon name="bin" />
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
