import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ROUTES } from 'routes';
import {
  ProfilePage,
  ProfileExperiencePage,
  ProfileEducationPage,
  ProfilePortfolioPage,
  ProfilePageMobile,
} from 'pages/Profile';
import { useSelector } from 'react-redux';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { selectProfile } from 'common/store/features/profile/selectors';
import { useBreakpoints } from 'common/hooks/useBreakpoints';

export const ProfileRoutes = () => {
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(checkIsMobile(breakpoint));

  useEffect(() => {
    setIsMobile(checkIsMobile(breakpoint));
  }, [breakpoint]);

  function checkIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  const state = useSelector(selectProfile);
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const hasLoaded = !isBootstrapping;

  return (
    <Switch>
      {isMobile && (
        <Route exact path={ROUTES.PROFILE_ROOT}>
          <ProfilePageMobile />
        </Route>
      )}

      {!isMobile && (
        <Route exact path={ROUTES.PROFILE_ROOT}>
          <ProfilePage />
        </Route>
      )}

      {!isMobile && (
        <Route exact path={`${ROUTES.PUBLIC_WALLET}/:id`}>
          <ProfilePage />
        </Route>
      )}

      {state.experience.length > 0 && (
        <Route exact path={ROUTES.PROFILE_EXPERIENCE}>
          <ProfileExperiencePage />
        </Route>
      )}
      {state.education.length > 0 && (
        <Route exact path={ROUTES.PROFILE_EDUCATION}>
          <ProfileEducationPage />
        </Route>
      )}
      {state.portfolio.length > 0 && (
        <Route exact path={ROUTES.PROFILE_PORTFOLIO}>
          <ProfilePortfolioPage />
        </Route>
      )}
      {hasLoaded && <Redirect exact from={`${ROUTES.PROFILE_ROOT}/*`} to={ROUTES.PROFILE_ROOT} />}
    </Switch>
  );
};
