import React, { useState } from 'react';
import { default as MUITooltip } from '@material-ui/core/Tooltip';
import { default as MUIClickAwayListener } from '@material-ui/core/ClickAwayListener';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './Tooltip.scss';

/**
 * Tooltip that leverages MUI's tooltip
 * @param [children] - apply tooltip to child
 * @param variation - dark/light and any future color variations
 * @param className
 * @param message - message for the tooltip can take a node
 * @returns {*}
 * @constructor
 */

export const Tooltip = ({ children, variation, className, message, ...props }) => {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    if (!clicked) {
      setOpen(false);
    }
  };

  const handleTooltipClickedOutside = () => {
    setOpen(false);
    setClicked(false);
  };

  const handleTooltipClicked = () => {
    setOpen(!clicked);
    setClicked(!clicked);
  };

  return (
    <MUIClickAwayListener onClickAway={handleTooltipClickedOutside}>
      <MUITooltip
        classes={{
          tooltip: `iq4-tooltip ${variation ? 'iq4-tooltip--' + variation : ''}`,
          arrow: 'iq4-tooltip__arrow',
          popper: 'iq4-tooltip__popper',
        }}
        PopperProps={{
          disablePortal: true,
        }}
        onMouseEnter={() => handleTooltipOpen()}
        onMouseLeave={() => handleTooltipClose()}
        onClose={handleTooltipClose}
        open={open}
        disableTouchListener
        disableFocusListener
        disableHoverListener
        title={message}
        arrow
      >
        <span>
          {/* with custom child  */}
          {!!children && (
            <button
              className="iq4-tooltip__button-with-child"
              onClick={() => handleTooltipClicked()}
              aria-haspopup={clicked}
              aria-label="More Info"
              type="button"
            >
              {children}
            </button>
          )}

          {/* default ? icon */}
          {!children && (
            <button
              className={`iq4-tooltip__button ${clicked ? 'iq4-tooltip__button--clicked' : ''}`}
              onClick={(e) => handleTooltipClicked(e)}
              aria-haspopup={clicked}
              aria-label="More Info"
              type="button"
            >
              <SimpleIcon name="tooltipCircle" />
            </button>
          )}
        </span>
      </MUITooltip>
    </MUIClickAwayListener>
  );
};
