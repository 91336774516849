import React, { useState } from 'react';
import { Modal } from 'common/components';
import './ExternalLinkInterstitial.scss';

export const ExternalLinkInterstitial = ({
  className,
  linkLabel,
  link,
  introText,
  mainText,
  name,
  id,
  labelName,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="iq4-external-link-interstitial">
        <button
          className={`iq4-external-link-interstitial__link ${className}`}
          onClick={() => setIsOpen(true)}
          aria-label={`Learn more about ${labelName || name}`}
        >
          {linkLabel}
        </button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        className="iq4-external-link-interstitial__modal"
        onDeactivation={() => {
          setTimeout(() => {
            const button = document.querySelector(`.iq4-refocus-${id}`);
            if (button) {
              button.focus();
            }
          }, 1);
        }}
      >
        <h1 className="iq4-external-link-interstitial__modal-heading">{introText}</h1>
        <p className="iq4-external-link-interstitial__modal-content">{mainText}</p>

        <div className="iq4-external-link-interstitial__modal-actions">
          <a
            className="iq4-external-link-interstitial__modal-continue"
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            onClick={() => setIsOpen(false)}
          >
            Continue
          </a>
          <button
            className="iq4-external-link-interstitial__modal-cancel"
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
