import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { NavLink, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Toolbar, Popover, IconButton, Badge, Drawer } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { OnboardingHeader } from 'common/components/Onboarding';
import {
  Notifications as NotificationsIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { selectBadgrAccountEnabled } from 'common/store/features/records/selectors';
import {
  TopbarAvatar,
  Container,
  Notifications,
  SimpleIcon,
  Button,
  Modal,
  ShareWalletModal,
  UserFeedback,
} from 'common/components';
import { FormWrapper } from 'common/components/Form';
import { selectCounts } from 'common/store/features/records/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import { selectIsSessionExpired } from 'common/store/features/session/selectors';
import {
  selectIsPublicProfile,
  selectProfile,
  selectUserProfile,
} from 'common/store/features/profile/selectors';
import {
  selectConnectionsCount,
  selectNotifyInvites,
} from 'common/store/features/connections/selectors';
import { selectedDisplayedCredentialsNotification } from 'common/store/features/credentials/selectors';
import {
  fetchCredentialsReq,
  fetchCredentialsStatusReq,
  fetchBadgrCredentials,
  setDisplayedCredentialsNotification,
} from 'common/store/features/credentials/credentialsSlice';
import { ROUTES } from 'routes';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { useProfilePersonalFormDefinition } from 'common/hooks/forms';
import './Topbar.scss';
import FocusLock from 'react-focus-lock';
import IQ4Tour from '../IQ4Tour/IQ4Tour';
import { selectDisplayTour, selectIsResumeParserEnabled } from '../../store/features/ui/selectors';
import {
  setCameFromProfile,
  setDisplayTour,
  setPathwayDetailTour,
  setSkillExpandedId,
} from '../../store/features/ui/uiSlice';
import { getCredentialTourSteps } from './tourSteps';

const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
const context = process.env.REACT_APP_CONTEXT;

export const Topbar = ({
  logoOnly,
  Logo,
  isPublicProfile,
  className = '',
  onUploadResume = () => {},
}) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isAccountSettingsOpen, setIsAccountSettingsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [showCredentialsNotification, setShowCredentialsNotification] = useState(false);
  const [isShareWalletModalVisible, setIsShareWalletModalVisible] = useState(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState(false);
  const [announcement, setAnnouncement] = useState('');
  const [currentTourSteps, setCurrentTourSteps] = useState([]);
  const [showTourTrigger, setShowTourTrigger] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpoints();
  const isMobile = useIsMobile((b) => b === 'xs' || b === 'sm');
  const anchorElCredentials = useRef(null);
  const location = useLocation();

  const notificationCounts = useSelector(selectCounts);
  const userProfile = useSelector(selectUserProfile);
  const { personal } = useSelector(selectProfile);
  const { modules, lcnEnabled, burgerMenu } = useSelector(selectFeatures);
  const isBadgrAccountEnabled = useSelector(selectBadgrAccountEnabled);
  const connectionsCount = useSelector(selectConnectionsCount);
  const isSessionExpired = useSelector(selectIsSessionExpired);
  const alreadyDisplayedCredentialsNotification = useSelector(
    selectedDisplayedCredentialsNotification,
  );
  const isResumeParserEnabled = useSelector(selectIsResumeParserEnabled);

  const notifyInvites = useSelector(selectNotifyInvites);
  const showTour = useSelector(selectDisplayTour);

  const youtubeURL = process.env.REACT_APP_TUTORIAL_URL;
  const appName = process.env.REACT_APP_NAME;
  const match = useRouteMatch();

  // check if current url contains career-pathways
  const isCareerPathways = useRouteMatch(ROUTES.CAREER_PATHWAYS);

  useEffect(() => {
    setAnnouncement(`Current route is: ${match.path}`);
  }, [match]);

  useEffect(() => {
    if (isSessionExpired) {
      setShowCredentialsNotification(false);
    }
  }, [isSessionExpired]);

  useEffect(() => {
    dispatch(setDisplayTour(false));
  }, [location, dispatch]);

  useEffect(() => {
    let steps;
    switch (location.pathname) {
      case `${context}/profile`:
        steps = getCredentialTourSteps();
        break;
      case `${context}/credentials`:
        steps = getCredentialTourSteps();
        break;
      case `${context}/manage-skills`:
        //steps = tourStepsForRoute2;
        break;
      case `${context}/career-pathways`:
        //steps = tourStepsForRoute2;
        break;
      default:
        steps = [];
    }

    const validLocations = [
      `${context}/profile`,
      `${context}/credentials`,
      `${context}/manage-skills`,
      `${context}/career-pathways`,
    ];

    setCurrentTourSteps(steps);
    setShowTourTrigger(validLocations.includes(location.pathname));
  }, [location]);

  const userProfilePhotoData = personal?.userProfilePhoto || {
    id: null,
    image: null,
    imageName: null,
    thumbnail: null,
    type: null,
  };

  const handleAccountSettingsButton = (e) => {
    setIsAccountSettingsOpen(!isAccountSettingsOpen);
    setAnchorEl(e.currentTarget);
  };

  const handleNotificationsButton = (e) => {
    setIsNotificationsOpen(true);
    setAnchorElNotifications(e.currentTarget);
  };

  const viewCredentials = () => {
    setShowCredentialsNotification(false);
    // go to credentials page
    history.push(ROUTES.CREDENTIALS);
  };

  const inCareerPathwaysPage = ['career-pathways'].some(
    (x) => window.location.href.indexOf(x) !== -1,
  );

  const notificationsCollection = [
    ...(notificationCounts.credentailsCountNew > 0
      ? [
          {
            message: `You have ${notificationCounts.credentailsCountNew} new achievements to manage`,
            actionLabel: 'Manage',
            actionRoute: ROUTES.MY_RECORDS_ACCEPT_ROOT,
          },
        ]
      : []),
    ...(notificationCounts.connectionsCountNew > 0
      ? [
          {
            message: `You have ${notificationCounts.connectionsCountNew} new requests to respond to`,
            actionLabel: 'Respond',
            actionRoute: ROUTES.MY_RECORDS_SHARE_REQUESTS,
          },
        ]
      : []),
    ...(!!modules?.connections && notifyInvites?.length > 0
      ? [
          {
            message: `You have ${notifyInvites?.length} new invitation${
              notifyInvites?.length > 0 ? 's' : ''
            } to manage`,
            actionLabel: 'Manage',
            actionRoute: ROUTES.CONNECTIONS,
          },
        ]
      : []),
    ...(notificationCounts.credentailsCountNew === 0 && !inCareerPathwaysPage
      ? [
          {
            message: `Go to "Career Pathways" to explore career and learning opportunities.`,
            actionLabel: 'Explore',
            actionRoute: ROUTES.CAREER_PATHWAYS,
          },
        ]
      : []),
  ];

  const displayMobileNotificationsPanel = () => {
    return (
      <div className="iq4-topbar__notifications-mobile-container">
        <Notifications
          onClose={() => setIsNotificationsOpen(false)}
          notifications={notificationsCollection}
        />
      </div>
    );
  };

  function startTutorial() {
    setIsTutorialOpen(true);
  }

  function triggerTour() {
    if (location.pathname === `${context}/profile` && !isResumeParserEnabled) {
      history.push(`${context}/onboarding/personal`);
      dispatch(setDisplayTour(true));
      dispatch(setCameFromProfile(true));
    } else if (location.pathname === `${context}/profile` && isResumeParserEnabled) {
      dispatch(setDisplayTour(true));
    } else if (
      location.pathname === `${context}/career-pathways` &&
      window.location.href.includes('?r=')
    ) {
      dispatch(setPathwayDetailTour(true));
    } else {
      dispatch(setDisplayTour(true));
    }
  }

  const excludeTerms = ['home', 'terms-conditions', 'privacy-policy'];
  const inTermsPage = excludeTerms.some((x) => window.location.href.indexOf(x) !== -1);

  const showHeaderHamburguer = () =>
    isMobile
      ? burgerMenu.bottomRight.activeMobile && !inTermsPage
      : burgerMenu.navbar.activeDesktop;

  return (
    <>
      {!logoOnly && (
        <a
          tabIndex="0"
          className="skip-main"
          href="#main"
          onClick={() =>
            setTimeout(() => {
              window.scrollTo(0, 0);
            }, 1)
          }
        >
          Skip to main content
        </a>
      )}
      <AppBar
        position="fixed"
        classes={{
          root: `iq4-topbar__container ${
            breakpoint === 'xs' || breakpoint === 'sm' || isCareerPathways
              ? 'iq4-topbar__container--no-shadow'
              : ''
          }`,
        }}
      >
        <Container className="iq4-max-width-container--padding">
          <div aria-live="polite" aria-atomic="true" className="sr-only">
            {announcement}
          </div>
          <Toolbar
            classes={{
              root: isPublicProfile ? 'iq4-topbar__toolbar-center' : 'iq4-topbar__toolbar',
            }}
            className={className}
          >
            <div className="iq4-topbar__logo-links__container">
              {showHeaderHamburguer() && <SidePanelNav Logo={Logo} />}
              <Logo />
            </div>
            {!logoOnly && !isMobile && !isPublicProfile && (
              <div className="iq4-topbar__middle-container">
                <ul className="iq4-topbar__links">
                  {modules.profile && (
                    <li className="iq4-topbar__links-item">
                      <NavLink className="iq4-topbar__links-item-link" to={ROUTES.PROFILE_ROOT}>
                        Profile
                      </NavLink>
                    </li>
                  )}
                  {modules.credentials && (
                    <li className="iq4-topbar__links-item">
                      <NavLink className="iq4-topbar__links-item-link" to={ROUTES.CREDENTIALS}>
                        Credentials
                      </NavLink>
                    </li>
                  )}
                  {modules.skills && (
                    <li className="iq4-topbar__links-item">
                      <NavLink className="iq4-topbar__links-item-link" to={ROUTES.MANAGE_SKILLS}>
                        Skills
                      </NavLink>
                    </li>
                  )}
                  {modules.records && (
                    <li className="iq4-topbar__links-item">
                      <NavLink className="iq4-topbar__links-item-link" to={ROUTES.MY_RECORDS}>
                        My Records
                      </NavLink>
                    </li>
                  )}
                  {modules.pathways && (
                    <li className="iq4-topbar__links-item">
                      <NavLink className="iq4-topbar__links-item-link" to={ROUTES.CAREER_PATHWAYS}>
                        Career Pathways
                      </NavLink>
                    </li>
                  )}
                  {modules.connections && (
                    <li className="iq4-topbar__links-item">
                      <NavLink className="iq4-topbar__links-item-link" to={ROUTES.CONNECTIONS}>
                        Connections
                      </NavLink>
                    </li>
                  )}
                </ul>
                <UserFeedback />
              </div>
            )}
            {!logoOnly && !isPublicProfile && (
              <div className="iq4-topbar__links-actions">
                {/*                <div className="iq4-topbar__links-actions-item">
                  <IconButton
                    aria-label={`You have ${notificationsCollection?.length} notifications`}
                    aria-expanded={isNotificationsOpen}
                    color="inherit"
                    ref={anchorElCredentials}
                    onClick={(e) => handleNotificationsButton(e)}
                  >
                    {notificationCounts && (
                      <Badge
                        max={999}
                        badgeContent={notificationsCollection?.length}
                        color="secondary"
                      >
                        <NotificationsIcon
                          className={`iq4-topbar__notifications-icon ${
                            isNotificationsOpen ? 'iq4-topbar__notifications-icon--active' : ''
                          }`}
                        />
                      </Badge>
                    )}
                  </IconButton>
                </div>*/}
                <Popover
                  id="notifications-popover"
                  classes={{ paper: 'iq4-notification-menu__paper' }}
                  open={isNotificationsOpen}
                  anchorEl={anchorElNotifications}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => setIsNotificationsOpen(false)}
                >
                  <div className="iq4-avatar-menu">
                    {isMobile && (
                      <div
                        className="iq4-avatar-menu__mobile-container"
                        role="button"
                        tabIndex={0}
                        onClick={() => setIsNotificationsOpen(false)}
                        onKeyPress={() => setIsNotificationsOpen(false)}
                      >
                        <SimpleIcon name="arrow" />
                        <span className="iq4-avatar-menu__mobile-container--text">Back</span>
                      </div>
                    )}
                    <div className="iq4-avatar-menu__triangle-with-shadow"></div>
                    <Notifications
                      onClose={() => setIsNotificationsOpen(false)}
                      notifications={notificationsCollection}
                    />
                  </div>
                </Popover>

                {showTourTrigger && !isResumeParserEnabled && (
                  <div
                    className="iq4-header-tour-container"
                    role="button"
                    tabIndex={0}
                    onClick={triggerTour}
                  >
                    {!isMobile && <span style={{ marginRight: 10 }}>Page Tour</span>}
                    <SimpleIcon style={{ marginRight: isMobile ? 2 : 40 }} name="tour" />
                  </div>
                )}
                <button
                  className="iq4-topbar__links-actions-item"
                  aria-expanded={isAccountSettingsOpen}
                  onClick={(e) => handleAccountSettingsButton(e)}
                  aria-label="Click to open account setting."
                >
                  <TopbarAvatar />
                  <Popover
                    id="avatar-popover"
                    classes={{ paper: 'iq4-avatar-menu__paper' }}
                    open={isAccountSettingsOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    onClose={() => setIsAccountSettingsOpen(false)}
                  >
                    <div className="iq4-avatar-menu">
                      <div className="iq4-avatar-menu__triangle-with-shadow"></div>
                      {isMobile && (
                        <div
                          className="iq4-avatar-menu__mobile-container"
                          role="button"
                          tabIndex={0}
                          onClick={() => setIsNotificationsOpen(false)}
                          onKeyPress={() => setIsNotificationsOpen(false)}
                        >
                          <SimpleIcon name="arrow" />
                          <span className="iq4-avatar-menu__mobile-container--text">Back</span>
                        </div>
                      )}
                      <ul className="iq4-avatar-menu__options">
                        <li>
                          <div className="iq4-avatar-menu__profile">
                            <TopbarAvatar alt="Profile Avatar" />
                            <div className="iq4-avatar-menu__profile__details">
                              <span className="iq4-avatar-menu__profile__details__name">
                                {userProfile?.user?.firstName} {userProfile?.user?.lastName}
                              </span>
                              {userProfile?.currentPosition && (
                                <span className="iq4-avatar-menu__profile__details__position">
                                  {userProfile.currentPosition}
                                </span>
                              )}
                            </div>
                            <button
                              aria-label="Edit profile"
                              className="iq4-avatar-menu__profile__edit"
                              onClick={() => setIsOpen(true)}
                            >
                              <SimpleIcon name="edit" alt="Edit profile icon" />
                            </button>
                          </div>
                        </li>
                        {userProfile?.description && (
                          <li>
                            <span className="iq4-avatar-menu__description">
                              {userProfile?.description}
                            </span>
                          </li>
                        )}
                        {!isBadgrAccountEnabled && lcnEnabled && (
                          <li>
                            <a href={ROUTES.MY_RECORDS_CONNECT} aria-label="Connect an account">
                              Connect an account
                            </a>
                          </li>
                        )}
                        <li className="iq4-avatar-menu__upload-resume">
                          <Button
                            className="iq4-avatar-menu__share-btn"
                            onClick={() => setIsShareWalletModalVisible(true)}
                            variation="primary"
                          >
                            <ShareIcon alt="Share icon" /> Share my Wallet
                          </Button>
                        </li>
                        <li className="iq4-avatar-menu__upload-resume">
                          <Button
                            variation="ghost"
                            onClick={() => onUploadResume()}
                            aria-label="Upload resume"
                          >
                            Upload Resume
                          </Button>
                        </li>
                        <li className="iq4-avatar-menu__logout">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={ROUTES.TERMS_AND_CONDITIONS}
                            aria-label="Terms and Conditions (opens in a new tab)"
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        <li className="iq4-avatar-menu__logout">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={ROUTES.PRIVACY_POLICY}
                            aria-label="Privacy Policy (opens in a new tab)"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        {/*                        <li className="iq4-avatar-menu__logout">
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={ROUTES.FCRA}
                            aria-label="FCRA"
                          >
                            FCRA
                          </a>
                        </li>*/}
                        {!!youtubeURL && (
                          <li className="iq4-avatar-menu__logout">
                            <div
                              role="button"
                              tabIndex={0}
                              style={{ cursor: 'pointer' }}
                              onClick={startTutorial}
                              onKeyPress={startTutorial}
                              aria-label="Start tutorial"
                            >
                              Tutorial
                            </div>
                          </li>
                        )}
                        <li className="iq4-avatar-menu__logout">
                          <NavLink to={ROUTES.SETTINGS}>Settings</NavLink>
                        </li>
                        <li className="iq4-avatar-menu__logout">
                          <NavLink to={ROUTES.SITEMAP}>Site Map</NavLink>
                        </li>
                        <li className="iq4-avatar-menu__logout">
                          <a href={logoutUrl} aria-label="Log out">
                            Log out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </Popover>
                </button>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <div className="iq4-toolbar__placeholder" />
      {isShareWalletModalVisible && (
        <ShareWalletModal
          isOpen={true}
          onClose={() => setIsShareWalletModalVisible(false)}
          onShare={() => {}}
        />
      )}
      <Modal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        title={<OnboardingHeader icon="personCircle" title="Personal" />}
        className="iq4-personal-card__modal_edit"
      >
        <FormWrapper
          values={{
            ...userProfile,
            firstName: userProfile?.user?.firstName,
            lastName: userProfile?.user?.lastName,
            email: userProfile && userProfile?.user && userProfile?.user?.email,
            image: userProfilePhotoData?.thumbnail,
          }}
          onComplete={() => setIsOpen(false)}
          useFormDefinition={useProfilePersonalFormDefinition}
        />
      </Modal>
      <Modal
        isOpen={isTutorialOpen}
        handleClose={() => setIsTutorialOpen(false)}
        title="Tutorial"
        className="iq4__tutorial-modal"
      >
        <iframe
          src={youtubeURL}
          title="Tutorial"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
    </>
  );
};

const SIDEPANEL_FOOTER_LINKS = [
  {
    url: process.env.REACT_APP_FOOTER_ITEM_1_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_1_LABEL,
  },
  {
    url: process.env.REACT_APP_FOOTER_ITEM_2_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_2_LABEL,
  },
  {
    url: process.env.REACT_APP_FOOTER_ITEM_3_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_3_LABEL,
  },
];

export const SidePanelNav = ({ Logo }) => {
  const [showSidePanel, setShowSidePanel] = useState(false);
  const { modules, burgerMenu } = useSelector(selectFeatures);
  const isSessionExpired = useSelector(selectIsSessionExpired);
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const history = useHistory();

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setShowSidePanel(open);
  };

  if (
    !burgerMenu.navbar.activeMobile &&
    !burgerMenu.navbar.activeDesktop &&
    !burgerMenu.bottomRight.activeMobile &&
    !burgerMenu.bottomRight.activeDesktop
  )
    return null;

  function getIsMobile(breakpoint) {
    const zoomLevel = ((window.outerWidth - 10) / window.innerWidth) * 100;
    return breakpoint === 'xs' || breakpoint === 'sm' || zoomLevel > 250;
  }

  return (
    <>
      {((burgerMenu.navbar.activeDesktop && !isMobile) ||
        (burgerMenu.navbar.activeMobile && isMobile)) &&
        !isSessionExpired && (
          <button
            className="iq4-sidepanel-toggle-btn-navbar"
            onClick={toggleDrawer(!showSidePanel)}
            onKeyPress={toggleDrawer(!showSidePanel)}
          >
            {!showSidePanel && <MenuIcon className="iq4-sidenav-burger" />}
            {showSidePanel && <CloseIcon className="iq4-sidenav-burger" />}
          </button>
        )}

      {((burgerMenu.bottomRight.activeDesktop && !isMobile) ||
        (burgerMenu.bottomRight.activeMobile && isMobile) ||
        showSidePanel) &&
        !isSessionExpired &&
        ReactDOM.createPortal(
          <button
            aria-label={showSidePanel ? 'Close side panel' : 'Open side panel'}
            className="iq4-sidepanel-toggle-btn"
            onClick={toggleDrawer(!showSidePanel)}
            onKeyPress={toggleDrawer(!showSidePanel)}
          >
            {!showSidePanel && <MenuIcon className="iq4-sidenav-burger" />}
            {showSidePanel && <CloseIcon className="iq4-sidenav-burger" />}
          </button>,
          window.document.body,
        )}

      <Drawer
        classes={{
          root: 'iq4-sidepanel',
        }}
        anchor={'left'}
        open={showSidePanel}
        onClose={toggleDrawer(false)}
      >
        <FocusLock>
          <div className="iq4-sidepanel__header">
            {Logo && <Logo className="iq4-sidepanel__logo" />}
          </div>
          <ul className="iq4-sidepanel__routes-list">
            {modules.profile && (
              <li className="iq4-sidepanel__route">
                <NavLink
                  className="iq4-sidepanel__route-link"
                  to={ROUTES.PROFILE_ROOT}
                  aria-current={history.location.pathname === ROUTES.PROFILE_ROOT ? 'page' : null}
                >
                  Profile
                </NavLink>
              </li>
            )}
            {modules.skills && (
              <li className="iq4-sidepanel__route">
                <NavLink
                  className="iq4-sidepanel__route-link"
                  to={ROUTES.MANAGE_SKILLS}
                  aria-current={history.location.pathname === ROUTES.MANAGE_SKILLS ? 'page' : null}
                >
                  Skills
                </NavLink>
              </li>
            )}
            {modules.records && (
              <li className="iq4-sidepanel__route">
                <NavLink className="iq4-sidepanel__route-link" to={ROUTES.MY_RECORDS}>
                  My Records
                </NavLink>
              </li>
            )}
            {modules.credentials && (
              <li className="iq4-sidepanel__route">
                <NavLink
                  className="iq4-sidepanel__route-link"
                  to={ROUTES.CREDENTIALS}
                  aria-current={history.location.pathname === ROUTES.CREDENTIALS ? 'page' : null}
                >
                  Credentials
                </NavLink>
              </li>
            )}
            {modules.pathways && (
              <li className="iq4-sidepanel__route">
                <NavLink
                  className="iq4-sidepanel__route-link"
                  to={ROUTES.CAREER_PATHWAYS}
                  aria-current={
                    history.location.pathname === ROUTES.CAREER_PATHWAYS ? 'page' : null
                  }
                >
                  Career Pathways
                </NavLink>
              </li>
            )}
            {modules.connections && (
              <li className="iq4-sidepanel__route">
                <NavLink
                  className="iq4-sidepanel__route-link"
                  to={ROUTES.CONNECTIONS}
                  aria-current={history.location.pathname === ROUTES.CONNECTIONS ? 'page' : null}
                >
                  Connections
                </NavLink>
              </li>
            )}

            <li className="iq4-sidepanel__feedback">
              <UserFeedback />
            </li>
          </ul>
          {SIDEPANEL_FOOTER_LINKS.map((item, index) =>
            item.url ? (
              <a
                className="iq4-sidepanel__footer-link"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`See more details (opens in a new tab).`}
              >
                {item.label}
                <span className="sr-only">This will open in a new tab.</span>
              </a>
            ) : null,
          )}
        </FocusLock>
      </Drawer>
    </>
  );
};
