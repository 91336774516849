import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './Button.scss';

export const Button = (props) => {
  const { children, loading, disabled = false, variation, className, icon, ...buttonProps } = props;
  return (
    <button
      className={`iq4-button iq4-button-${variation} ${className} ${
        disabled ? 'iq4-button__disabled' : ''
      }`}
      type="button"
      {...buttonProps}
      disabled={loading || disabled}
    >
      {loading ? (
        <CircularProgress size={20} classes={{ root: 'iq4-button__progress' }} />
      ) : (
        children
      )}

      {icon && <SimpleIcon className="iq4-button__icon" name={icon} />}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variation: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Button.defaultProps = {
  variation: 'primary',
  className: '',
  icon: null,
};
