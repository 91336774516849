import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { ROUTES } from 'routes';
import { Topbar, SimpleIcon, Button } from 'common/components';
import { ProfileResumeParse } from 'components/Profile';
import { ProfilePersonal } from 'components/Profile/ProfilePersonal';
import { ProfileEducationDetail } from 'components/Profile/ProfileEducationDetail';
import { FormWrapper } from 'common/components/Form';
import { TriggerModal } from 'common/components/TriggerModal';
import { useProfileEducationFormDefinition } from 'common/hooks/forms';
import { ProfilePageLayout } from 'components/Profile/ProfilePageLayout';
import { DEFAULT_COUNTRY } from 'common/constants/countries';
import { selectFeatures } from 'store/features/config/selectors';

export const ProfileEducationPage = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsMobile((b) => ['xs', 'sm', 'md'].includes(b));
  const [showResumeParser, setShowResumeParser] = useState(false);

  const defaultValues = {
    country: DEFAULT_COUNTRY,
    isCurrentStudent: true,
  };

  const { applicationTitle } = useSelector(selectFeatures);

  return (
    <>
      {!isMobile && (
        <ProfilePageLayout
          singleRow={isTablet}
          helmetTitle={`${applicationTitle} - Profile - Education`}
        >
          <div>
            {!isMobile && (
              <Button variation="ghost" onClick={() => setShowResumeParser(true)}>
                Upload your updated resume
              </Button>
            )}
            {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}
          </div>
          {isTablet ? null : <ProfilePersonal />}
          <ProfileEducationDetail />
        </ProfilePageLayout>
      )}

      {isMobile && (
        <section className="iq4-profile-page-mobile-detail">
          <Topbar Logo={Logo} />

          <main className="iq4-profile-page-mobile-detail__main">
            <Link to={ROUTES.PROFILE_ROOT} className="iq4-profile-page-mobile-detail__back-link">
              <SimpleIcon name="arrow" /> Education
            </Link>

            <ProfileEducationDetail />

            <TriggerModal
              title="Education"
              form={
                <FormWrapper
                  useFormDefinition={useProfileEducationFormDefinition}
                  values={defaultValues}
                />
              }
              label={<FormattedMessage id="profile.addAnother.label" />}
              buttonVariation="primary"
            />
          </main>
        </section>
      )}
    </>
  );
};
