import React, { useEffect, useRef } from 'react';
import { Handle, Position } from 'reactflow';
import { PathwayDonut } from 'common/components/PathwaysGraph';

export function RoleNode({ data }) {
  const { spoke, positionLabel, nodeType, onSelectPath, onAddPath, onRemovePath } = data;
  const nodeRef = useRef();

  useEffect(() => {
    if (nodeRef.current) {
      let parent = nodeRef.current.parentNode;
      while (parent) {
        if (parent.getAttribute('role') === 'button') {
          parent.removeAttribute('role');
          break;
        }
        parent = parent.parentNode;
      }
    }
  }, []);

  if (!data.spoke) {
    return null;
  }

  return (
    <div ref={nodeRef}>
      <PathwayDonut
        readOnly={false}
        spoke={spoke}
        positionLabel={positionLabel}
        nodeType={nodeType}
        onDelete={() => {}}
        onRemove={onRemovePath}
        onSelect={(spoke) => onSelectPath(spoke, nodeRef.current)}
        onAdd={onAddPath}
      />
      <Handle type="target" position={Position.Bottom} id="b" isConnectable={true} />
      <Handle type="source" position={Position.Bottom} id="t" isConnectable={true} />
    </div>
  );
}
