import React, { useEffect, useState } from 'react';
import { useHistory, useParams, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProfilePersonal,
  ProfileEducationSummary,
  ProfileLCNEducationSummary,
  ProfileExperienceSummary,
  ProfilePortfolioSummary,
  ProfileSkillsSummary,
  ProfilePageLayout,
  ProfileResumeParse,
  ProfileEducationDetail,
  ProfileExperienceDetail,
} from 'components/Profile';
import {
  Button,
  ConsentBanner,
  Container,
  Footer,
  ManageSkillsCard,
  Topbar,
} from 'common/components';
import { ROUTES } from 'routes/routeNames';
import { selectFeatures } from 'store/features/config/selectors';
import {
  selectProfile,
  selectPublicCredentials,
  selectPublicPersonalProfile,
  selectPublicSkills,
  selectUserProfile,
} from 'common/store/features/profile/selectors';
import {
  fetchProfileReq,
  fetchPublicData,
  setIsPublicProfile,
} from 'common/store/features/profile/profileSlice';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { Helmet } from 'react-helmet';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import Grid from '@material-ui/core/Grid';
import './PublicProfilePage.scss';
import {
  selectSkills,
  selectSkillsWithLearningResourceByState,
} from 'common/store/features/skills/selectors';
import { ProfilePortfolioDetail } from '../../../components/Profile/ProfilePortfolioDetail';
import { CareerPathwaysPage } from '../../CareerPathwaysPage';
import { setSkillExpandedId } from '../../../common/store/features/ui/uiSlice';
import { CredentialsPage } from '../../CredentialsPage';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CredentialRow } from '../../CredentialsPage/components/CredentialRow';
import { VerifiedCredentialRow } from '../../CredentialsPage/components/VerifiedCredentialRow';
import { selectInnerCurrentCredentials } from '../../../common/store/features/credentials/selectors';
import { fetchAllSkillsReq } from '../../../common/store/features/skills/skillsSlice';

export const PublicProfilePage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { applicationTitle, lcnEnabled, personalProfileSource } = useSelector(selectFeatures);
  const [showResumeParser, setShowResumeParser] = useState(false);
  const isMobile = useIsMobile();
  const isTablet = useIsMobile((b) => ['xs', 'sm', 'md'].includes(b));
  const personalPublicProfile = useSelector(selectPublicPersonalProfile);
  const { skills: publicSkills } = useSelector(selectSkills);
  const credentialsList = useSelector(selectPublicCredentials);
  const context = process.env.REACT_APP_CONTEXT;

  const [hasRequestedPersonal, setHasRequestedPersonal] = useState(false);
  const [hasRequestedSkills, setHasRequestedSkills] = useState(false);
  const [hasRequestedPathways, setHasRequestedPathways] = useState(false);
  const [hasRequestedCredentials, setHasRequestedCredentials] = useState(false);

  const personal = personalPublicProfile?.personal;

  const { skills } = useSelector((state) =>
    selectSkillsWithLearningResourceByState(state, personal?.school),
  );

  const { id } = useParams();
  const { url, path, params } = useRouteMatch();

  useEffect(() => {
    console.log('personalPublicProfile', personalPublicProfile);
  }, [personalPublicProfile]);

  useEffect(() => {
    dispatch(setIsPublicProfile(true));
  }, []);

  useEffect(() => {
    switch (path) {
      case `${context}/user/:id`:
      case `${context}/user/:id/education`:
      case `${context}/user/:id/experience`:
      case `${context}/user/:id/portfolio`:
        if (!hasRequestedSkills) dispatch(fetchPublicData({ id, section: 'skills' }));
        if (!hasRequestedPersonal) dispatch(fetchPublicData({ id, section: 'personal' }));
        if (!hasRequestedPathways) dispatch(fetchPublicData({ id, section: 'pathways' }));
        setHasRequestedPersonal(true);
        setHasRequestedSkills(true);
        setHasRequestedPathways(true);
        break;
      case `${context}/user/:id/skills`:
        if (!hasRequestedSkills) dispatch(fetchPublicData({ id, section: 'skills' }));
        if (!hasRequestedPersonal) dispatch(fetchPublicData({ id, section: 'personal' }));

        setHasRequestedPersonal(true);
        setHasRequestedSkills(true);
        // dispatch(fetchAllSkillsReq());
        break;
      case `${context}/user/:id/credentials`:
        if (!hasRequestedPersonal) dispatch(fetchPublicData({ id, section: 'personal' }));
        if (!hasRequestedCredentials) dispatch(fetchPublicData({ id, section: 'credentials' }));
        setHasRequestedPersonal(true);
        setHasRequestedCredentials(true);
        break;
      case `${context}/user/:id/career-pathways`:
        if (!hasRequestedSkills) dispatch(fetchPublicData({ id, section: 'skills' }));
        if (!hasRequestedPersonal) dispatch(fetchPublicData({ id, section: 'personal' }));
        if (!hasRequestedPathways) dispatch(fetchPublicData({ id, section: 'pathways' }));
        setHasRequestedPersonal(true);
        setHasRequestedSkills(true);
        setHasRequestedPathways(true);
        break;
    }

    dispatch(setSkillExpandedId(-1));
    window.scrollTo(0, 0);
  }, [path]);

  function renderLeftComponents() {
    switch (path) {
      case `${context}/user/:id/education`:
      case `${context}/user/:id/experience`:
      case `${context}/user/:id/skills`:
      case `${context}/user/:id/credentials`:
      case `${context}/user/:id/career-pathways`:
        return (
          <>
            <ProfilePersonal />
          </>
        );
      default:
        return (
          <>
            <ProfilePersonal />
            <ProfileEducationSummary />
            <ProfileExperienceSummary />
          </>
        );
    }
  }

  function renderRightComponents() {
    switch (path) {
      case `${context}/user/:id/education`:
        return (
          <>
            <ProfileEducationDetail />
          </>
        );
      case `${context}/user/:id/experience`:
        return (
          <>
            <ProfileExperienceDetail />
          </>
        );
      case `${context}/user/:id/portfolio`:
        return (
          <>
            <ProfilePortfolioDetail />
          </>
        );
      case `${context}/user/:id/skills`:
        return (
          <>
            <ManageSkillsCard skills={publicSkills} />
          </>
        );
      case `${context}/user/:id/credentials`:
        return (
          <>
            <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
              <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                <h2 className="iq4-credentials__sub-header">Manage</h2>
                <div className="iq4-credentials__card-container-header">
                  <div className="iq4-credentials__card-container--header">Name</div>
                  <div
                    className="iq4-credentials__card-container--header"
                    style={{ textAlign: 'center' }}
                  >
                    Status
                  </div>
                  <div className="iq4-credentials__card-container--header">Award Date</div>
                  <div className="iq4-credentials__card-container--header">Issuer</div>
                </div>
                {credentialsList?.map((c) => (
                  <CredentialRow
                    row={c}
                    key={c?.id}
                    setCredentialToBeDeleted={() => {}}
                    setIsDeletingModal={() => {}}
                    setIsEdit={() => {}}
                    isReadOnly={true}
                  />
                ))}
              </CardContent>
            </Card>
          </>
        );
      case `${context}/user/:id/career-pathways`:
        return (
          <>
            <CareerPathwaysPage />
          </>
        );
      default:
        return (
          <>
            <ProfileSkillsSummary />
            <ProfilePortfolioSummary />
          </>
        );
    }
  }

  return (
    <>
      {/*<Helmet title={helmetTitle} />*/}
      <Topbar isPublicProfile={true} Logo={Logo} />
      <main className="iq4-profile-page" id="main">
        <Container>
          {!isTablet && (
            <Grid container classes={{ root: 'iq4-profile__grid-container' }}>
              <Grid
                item
                md={3}
                classes={{
                  root:
                    'iq4-profile-page-item iq4-profile-page-item--left iq4-profile-page-item--top',
                }}
              >
                <h1 className="iq4-profile-header__text">
                  <span className="iq4-profile-header__label">Profile</span>
                </h1>
              </Grid>
              <Grid
                item
                md={9}
                classes={{
                  root:
                    'iq4-public-profile-page-item iq4-public-profile-page-item--right iq4-public-profile-page-item--top',
                }}
              >
                <div className="iq4-public-profile__link-container">
                  <div
                    className={`iq4-public-profile__link-item ${
                      [
                        `${context}/user/${id}`,
                        `${context}/user/${id}/education`,
                        `${context}/user/${id}/experience`,
                        `${context}/user/${id}/portfolio`,
                      ].includes(url)
                        ? 'iq4-public-profile__link-item--active'
                        : ''
                    }`}
                    onClick={() => history.push(`${context}/user/${id}`)}
                  >
                    Profile
                  </div>
                  <div
                    className={`iq4-public-profile__link-item ${
                      url === `${context}/user/${id}/skills`
                        ? 'iq4-public-profile__link-item--active'
                        : ''
                    }`}
                    onClick={() => history.push(`${context}/user/${id}/skills`)}
                  >
                    Skills
                  </div>
                  <div
                    className={`iq4-public-profile__link-item ${
                      url === `${context}/user/${id}/credentials`
                        ? 'iq4-public-profile__link-item--active'
                        : ''
                    }`}
                    onClick={() => history.push(`${context}/user/${id}/credentials`)}
                  >
                    Credentials
                  </div>
                  <div
                    className={`iq4-public-profile__link-item ${
                      url === `${context}/user/${id}/career-pathways`
                        ? 'iq4-public-profile__link-item--active'
                        : ''
                    }`}
                    onClick={() => history.push(`${context}/user/${id}/career-pathways`)}
                  >
                    Career Pathways
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Grid container>
            {!isTablet && (
              <Grid
                item
                md={3}
                sm={6}
                classes={{ root: 'iq4-profile-page-item iq4-profile-page-item--left' }}
              >
                <div>{renderLeftComponents()}</div>
              </Grid>
            )}
            <Grid
              item
              md={isTablet ? 12 : 9}
              sm={isTablet ? 12 : 6}
              classes={{
                root: `iq4-profile-page-item iq4-profile-page-item--right ${
                  isTablet ? 'iq4-profile-page-item--mobile' : ''
                }`,
              }}
            >
              <div>{renderRightComponents()}</div>
            </Grid>
          </Grid>
        </Container>

        <div className="iq4-profile-page__footer-container">
          <Footer />
        </div>
      </main>
    </>
  );
};
