import React, { useState } from 'react';
import FocusLock, { AutoFocusInside } from 'react-focus-lock';
import { SimpleIcon, Drawer } from 'common/components';

export function TopSkillsHelp({ additionalClass }) {
  const [showTopSkillsHelp, setShowTopSkillsHelp] = useState(false);

  function toggleHelp(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowTopSkillsHelp(!showTopSkillsHelp);
  }

  return (
    <>
      <div
        className={`iq4-t-shape__info ${additionalClass || ''}`}
        role="button"
        tabIndex={0}
        onClick={toggleHelp}
        onKeyPress={toggleHelp}
        aria-label="Get more info about Top 10 Skills"
      >
        <SimpleIcon name="infoDefault" />
        <div className="iq4-t-shape__info--text">What's this?</div>
      </div>
      {showTopSkillsHelp && (
        <Drawer
          anchor="right"
          variant="persistent"
          isOpen={true}
          classes={{
            root: 'iq4-tshape-page__drawer',
            paper: 'iq4-tshape-help-page__drawer-paper',
          }}
        >
          <FocusLock returnFocus={true}>
            <div style={{ paddingTop: '40px' }}>
              <span
                aria-live="polite"
                role="alert"
                className="sr-only"
                aria-label="Top 10 Skills help drawer opened"
              ></span>
              <AutoFocusInside>
                <button
                  aria-label="Close the top 10 skills drawer"
                  className="iq4-tshape-page__drawer__dismiss-button"
                  onClick={() => setShowTopSkillsHelp(false)}
                >
                  <SimpleIcon name="close" />
                </button>
              </AutoFocusInside>
              <div className="iq4-tshape-page__drawer__role-container">
                <div className="iq4-tshape-page__drawer__role-container--title-2">
                  Top 10 Skills
                </div>
                <div className="iq4-tshape-page__drawer__role-container--divider" />
                <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
                <p style={{ fontSize: '0.875rem' }}>
                  Explore your Top 10 Skills: Our platform identifies and ranks your key strengths
                  based on various criteria. Discover your standout abilities at a glance to enhance
                  your profile and stay competitive in today’s dynamic landscape.
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: 40 }}
                >
                  Customize Your Skills Page
                </div>
                <p style={{ fontSize: '0.875rem' }}>
                  Tailor your profile to highlight what matters most to you. Easily rearrange the
                  order of your skills on the Skills Page to showcase your priorities and strengths
                  in the way that best suits your profile. This is easily done from the Top 10
                  skills card on the left hand side of the skills page. Just expand the card and
                  change your skills or re order them to your liking. This will then be saved and
                  appear in the profile skills section. You can also search for other skills you
                  have from this card and bring in new ones that you prefer to showcase.
                </p>
              </div>
            </div>
          </FocusLock>
        </Drawer>
      )}
    </>
  );
}
