import React from 'react';
import { SimpleIcon } from 'common/components';
import './ImageUploadPlaceholder.scss';

export const ImageUploadPlaceholder = ({ label, isLarge }) => (
  <div
    className={`iq4-image-upload-placeholder ${isLarge && 'iq4-image-upload-placeholder--large'}`}
  >
    <SimpleIcon name="camera" className="iq4-image-upload-placeholder__camera" />
    {label && <span className="iq4-image-upload-placeholder__label">{label}</span>}
  </div>
);
