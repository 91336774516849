import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import Avatar from '@material-ui/core/Avatar';
import { PathwayDonut } from 'common/components/PathwaysGraph';
import { SimpleIcon } from '../../SimpleIcon';
import { MiddleStepModal } from '../../PathwaysGraph/MiddleStepModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import FocusLock, { AutoFocusInside } from 'react-focus-lock';

export function IntermediateRoleNode({ data }) {
  const middleStepRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [focusOnButton, setFocusOnButton] = useState(false);
  const focusOnButtonRef = useRef(focusOnButton);

  const { spoke, positionLabel } = data;

  const buttonClass = `${spoke.name.replace(/[^a-zA-Z0-9_]/g, '')}-info`;

  useEffect(() => {
    focusOnButtonRef.current = focusOnButton;
  }, [focusOnButton]);

  useEffect(() => {
    const handler = (e) => {
      const isOutside = !(middleStepRef.current && middleStepRef.current.contains(e.target));
      if (isOutside && showPopover) {
        setShowPopover(false);
        const button = document.querySelector(`.${buttonClass}`);
        if (button && focusOnButtonRef.current) {
          button.focus();
          setFocusOnButton(false);
        }
      }
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [middleStepRef, showPopover]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setShowPopover(false);
        const button = document.querySelector(`.${buttonClass}`);
        if (button && focusOnButtonRef.current) {
          button.focus();
          setFocusOnButton(false);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  let backgroundStyle = {
    background: `conic-gradient(
      #0070F0 0deg 0deg,
      #292929 0deg 360deg)`,
  };

  if (spoke.type !== 'achievement') {
    backgroundStyle = {
      background: '#0070F0',
    };
  }

  return (
    <>
      <div className="iq4-pathways-donut__container" style={{ marginBottom: '1rem' }}>
        <div
          className={`iq4-pathway__circle-container__label iq4-pathway__circle-container--label-${positionLabel}`}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="iq4-pathway__label-text">{spoke?.name?.replace(/,/, '\u200E,')}</div>
          </div>
        </div>
        <div
          className={buttonClass}
          ref={middleStepRef}
          aria-label={`See more information about ${spoke.name}`}
          role="button"
          tabIndex={0}
          onClick={() => {
            setShowPopover(!showPopover);
            setFocusOnButton(true);
          }}
          onKeyPress={() => {
            setShowPopover(!showPopover);
            setFocusOnButton(true);
          }}
        >
          <SimpleIcon
            name={`${spoke?.type === 'achievement' ? 'educationCircle' : 'roleIcon'}`}
            className="iq4-pathway__role-btn--icon"
          />
          <div className="donut" style={backgroundStyle} />
        </div>
      </div>
      {!!showPopover && (
        <FocusLock>
          <div className="iq4-pathways__mid-step-popover">
            <div className="iq4-pathways__mid-step-popover--title">
              <SimpleIcon
                name={
                  spoke?.type === 'achievement' ? 'educationCircle' : 'experienceCirclePathways'
                }
                className="iq4-pathways__mid-step-popover--title-icon"
              />
              <div className="iq4-pathways__mid-step-popover--title-label">{spoke.name}</div>
            </div>
            <div className="iq4-pathways__mid-step-popover--footer">
              <div className="iq4-pathways__mid-step-popover--footer-left">
                <div className="iq4-pathways__mid-step-popover--footer-count">
                  {spoke.competencies?.length}
                </div>
                <div className="iq4-pathways__mid-step-popover--title-label">Skills</div>
              </div>
              <AutoFocusInside>
                <div
                  className="iq4-pathways__mid-step-popover--footer-label-right"
                  onClick={() => setShowModal(true)}
                  onKeyPress={() => setShowModal(true)}
                  role="button"
                  tabIndex={0}
                >
                  View more details
                </div>
              </AutoFocusInside>
            </div>
          </div>
        </FocusLock>
      )}
      <MiddleStepModal
        step={spoke}
        showModal={showModal}
        setShowModal={setShowModal}
        onDeactivation={() => {
          setTimeout(() => {
            const button = document.querySelector(`.${buttonClass}`);
            if (button) {
              button.focus();
              setFocusOnButton(false);
            }
          }, 1);
        }}
      />
      <Handle type="target" position={Position.Bottom} id="b" isConnectable={true} />
      <Handle type="source" position={Position.Bottom} id="t" isConnectable={true} />
    </>
  );
}
