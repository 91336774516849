import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingFooter, OnboardingHeader } from 'common/components/Onboarding';
import { Button, FormWrapper, SimpleIcon } from 'common/components';
import {
  usePortfolioAudioFormDefinition,
  usePortfolioDocumentFormDefinition,
  usePortfolioImageFormDefinition,
  usePortfolioOtherFormDefinition,
  usePortfolioVideoFormDefinition,
} from 'common/hooks/forms';
import { PORTFOLIO_TYPE, PROFILE_TYPE, sendFormDataRequest } from 'common/utils';
import { PROFILE_PORTFOLIO_URL } from 'common/constants/endpoints';
import {
  setProfileSection,
  updateHasOnboardedReq,
} from 'common/store/features/profile/profileSlice';
import { selectProfile } from 'common/store/features/profile/selectors';
import './OnboardingPortfolioPage.scss';
import IQ4Tour from '../../../common/components/IQ4Tour/IQ4Tour';
import { ROUTES } from '../../../routes';
import { selectFeatures } from '../../../store/features/config/selectors';
import { useHistory } from 'react-router-dom';

const showTutorial = process.env.REACT_APP_SHOW_TUTORIAL === 'true';

export const OnboardingPortfolioPage = ({ onComplete = () => {}, onPrevious = () => {} }) => {
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [selectedPortfolioType, setSelectedPortfolioType] = useState('document');
  const [values, setValues] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [submitForm, setSubmitForm] = useState(null);

  const { defaultRoute } = useSelector(selectFeatures);
  const { portfolio } = useSelector(selectProfile);
  const { personal } = useSelector(selectProfile);
  const history = useHistory();
  const dispatch = useDispatch();

  const steps = [
    {
      target: '.iq4-onboarding-portfolio-list',
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Portfolio Item</h2>
          <p style={{ fontSize: '0.875rem' }}>
            Elevate your profile by adding a portfolio item (eg: papers you've written, art you've
            created, code you've worked on, etc.) These items should highlight previous work
            experience or personal achievments that support your career aspirations.
          </p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.iq4-type-project',
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Type of Project</h2>
          <p style={{ fontSize: '0.875rem' }}>Select the type of project you want to add.</p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.iq4-form',
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Portfolio Item Details</h2>
          <p style={{ fontSize: '0.875rem' }}>
            Upload an image for your portfolio item and add the rest of details related to this
            item.
          </p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
  ];

  function finishOnboarding() {
    if (isValid && isDirty) {
      setIsSubmittingForm(true);
      sendFormDataRequest({
        url: PROFILE_PORTFOLIO_URL,
        verb: 'POST',
        data: {
          ...values,
          type: selectedPortfolioType,
          featured: portfolio.length === 0,
        },
      })
        .then((resp) => {
          dispatch(setProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: resp }));
          onComplete();
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    } else if (!isDirty) {
      onComplete();
    }
  }

  function exposeFormContext(props) {
    setValues(props.getValues());
    setIsValid(props.isValid);
    setIsDirty(props.dirty);

    if (!submitForm && props?.formDefinition?.onCreate) {
      setSubmitForm(props?.formDefinition?.onCreate);
    }
  }

  function onFormComplete(props) {
    console.log(props);
  }

  const options = [
    {
      type: 'document',
      icon: 'document',
      mainLabel: 'Document',
      subLabel: 'PDF, Word, txt file',
    },
    {
      type: 'image',
      icon: 'image',
      mainLabel: 'Image',
      subLabel: 'JPEG, PNG, or GIF',
    },
    {
      type: 'video',
      icon: 'video',
      mainLabel: 'Video',
      subLabel: 'MP4 or link',
    },
    {
      type: 'audio',
      icon: 'audio',
      mainLabel: 'Audio',
      subLabel: 'MP3',
    },
    {
      type: 'other',
      icon: 'link',
      mainLabel: 'Link',
      subLabel: '',
    },
  ];

  const PORTFOLIO_FORMS = {
    other: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioOtherFormDefinition}
        showButtons={false}
        exposeFormContext={exposeFormContext}
        onFieldChange={(values) => setValues(values)}
      />
    ),
    document: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioDocumentFormDefinition}
        showButtons={false}
        exposeFormContext={exposeFormContext}
        onFieldChange={(values) => setValues(values)}
      />
    ),
    video: (
      <FormWrapper
        values={values}
        onChange={(data) => console.log(data)}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioVideoFormDefinition}
        showButtons={false}
        exposeFormContext={exposeFormContext}
        onFieldChange={(values) => setValues(values)}
      />
    ),
    audio: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioAudioFormDefinition}
        showButtons={false}
        exposeFormContext={exposeFormContext}
        onFieldChange={(values) => setValues(values)}
      />
    ),
    image: (
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={usePortfolioImageFormDefinition}
        showButtons={false}
        exposeFormContext={exposeFormContext}
        onFieldChange={(values) => setValues(values)}
      />
    ),
  };

  async function skipOnboardingPortfolio() {
    await dispatch(updateHasOnboardedReq({ id: personal?.userProfile?.id, hasOnboarded: true }));
    history.push(ROUTES[defaultRoute]);
  }

  return (
    <>
      <div className="iq4-onboarding-portfolio-list" style={{ position: 'relative' }}>
        <OnboardingHeader icon="portfolioCircle" title="Add a Portfolio project" isMainHeading />
        {showTutorial && <IQ4Tour steps={steps} />}

        <div className="iq4-onboarding__card">
          <div className="iq4-type-project">
            <p>Select from the following; This project is a:</p>
            {options.map((option) => (
              <div
                className={`portfolio-option ${
                  selectedPortfolioType === option.type ? 'portfolio-option__active' : ''
                }`}
                onClick={() => setSelectedPortfolioType(option.type)}
                role="button"
              >
                <div className="icon-container">
                  <SimpleIcon name={option.icon} className="portfolio-option__icon" />
                </div>
                <span className="portfolio-option__labels-main">{option.mainLabel}</span>
                <span className="portfolio-option__labels-sub">{option.subLabel}</span>
              </div>
            ))}
          </div>
          <div className="portfolio-form__container">{PORTFOLIO_FORMS[selectedPortfolioType]}</div>
        </div>
      </div>
      <OnboardingFooter
        direction="You're done here, great!"
        skipStep={async () => {
          await dispatch(
            updateHasOnboardedReq({ id: personal?.userProfile?.id, hasOnboarded: true }),
          );
          history.push(ROUTES[defaultRoute]);
        }}
        isSubmittingForm={isSubmittingForm}
        nextButtonLabel="Next"
        handleNext={() => finishOnboarding()}
        handlePrev={() => onPrevious()}
      />
    </>
  );
};
