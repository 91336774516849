import _orderBy from 'lodash/orderBy';

export const selectJobFamilies = (state) => _orderBy(state.framework.jobFamilies, 'label');

export const selectJobFamilyWorkRoles = (jobFamilyId) => (state) => {
  const specificJobFamily = state.framework.jobFamilies.find((x) => x.id === jobFamilyId);
  return _orderBy(specificJobFamily?.workRoles, ['label'], ['asc']);
};

export const selectJobFamilyByRoleJobFamilyId = (jobFamilyId) => (state) => {
  return state.framework.jobFamilies.find((x) => x.id === jobFamilyId);
};

export const selectWorkRole = ({ id, isPublicProfile = false }) => (state) => {
  let workRoleDetails = null;

  state.framework.jobFamilies.forEach((jobFamily) => {
    jobFamily.workRoles &&
      jobFamily.workRoles.forEach((workRole) => {
        if (parseInt(workRole.id) === parseInt(id)) {
          workRoleDetails = workRole;
        }
      });
  });

  return workRoleDetails;
};

export const selectIsFetchingJobs = (state) => state.framework.isFetchingJobs;

export const selectIsFetchingDemographicData = (state) => state.framework.isFetchingDemographicData;

export const selectIsFetchingWorkRoles = (state) => state.framework.isFetchingWorkRoles;

export const selectSelectedWorkRoles = (state) => state.framework.selectedWorkRoles;

export const selectIsFetchingRolePaths = (state) => state.framework.isFetchingRolePaths;

export const selectHasFetchedJobFamilies = (state) => state.framework.hasFetchedJobFamilies;
