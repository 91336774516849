import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SimpleIcon } from 'common/components';
import { useHover } from 'common/hooks/useHover';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import { selectIsPublicProfile } from 'common/store/features/profile/selectors';
import { addAddedSuggestion, addPathwayReq } from 'common/store/features/pathways/pathwaysSlice';
import {
  selectIsAddingRoleToPathway,
  selectRoleBeingAdded,
} from '../../store/features/pathways/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';

export const PathwayDonut = ({ spoke, positionLabel, onRemove, onAdd, onSelect, nodeType }) => {
  const [hoverRef, isHovered] = useHover();
  const dispatch = useDispatch();
  const [showPopover, setShowPopover] = useState(false);
  const isMobile = useIsMobile((b) => b === 'xs' || b === 'sm');
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const isAddingRoleToPathway = useSelector(selectIsAddingRoleToPathway);
  const roleBeingAdded = useSelector(selectRoleBeingAdded);

  const { label, name } = spoke;
  const displayName = label || name;

  const roleSkillsLength = spoke.skills?.roleSkills?.length || 0;
  const overlapSkillsLength = spoke.skills?.overlapSkills?.length || 0;

  // Calculate the total length
  const totalLength = roleSkillsLength + overlapSkillsLength;

  // Calculate the degrees
  const roleSkillsDegrees = totalLength ? (roleSkillsLength / totalLength) * 360 : 0;
  const overlapSkillsDegrees = totalLength ? (overlapSkillsLength / totalLength) * 360 : 0;

  // Construct the background style
  let backgroundStyle = {
    background: `conic-gradient(
      #0070F0 0deg ${roleSkillsDegrees}deg,
      #292929 ${roleSkillsDegrees + 1}deg 360deg)`,
  };

  if (spoke.type !== 'achievement' && !roleSkillsDegrees && !overlapSkillsDegrees) {
    backgroundStyle = {
      background: '#0070F0',
    };
  }

  useEffect(() => {
    const handler = (e) => {
      const isOutside = !(hoverRef.current && hoverRef.current.contains(e.target));
      if (isOutside && showPopover) {
        setShowPopover(false);
      }
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [hoverRef, showPopover]);

  const addSuggestion = () => {
    dispatch(addAddedSuggestion(spoke));
    dispatch(addPathwayReq(spoke));
  };

  if (nodeType !== 'suggested') {
    const renderDelete = () => {
      return (
        <SimpleIcon
          className="iq4-pathway-detail__header__add-to-pathways__icon delete-role"
          name="bin"
          aria-label={`Remove ${displayName} from your pathway`}
          hidden={false}
          role="button"
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation();
            onRemove(spoke);
          }}
          onKeyPress={() => {
            onRemove(spoke);
          }}
        />
      );
    };
    const renderNodeIcon = () => {
      return (
        <div
          className={`iq4-pathway__circle-container__label iq4-pathway__circle-container--label-${positionLabel}`}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!isPublicProfile &&
              !(roleBeingAdded === spoke?.id) &&
              positionLabel === 'left' &&
              nodeType !== 'intermediate' &&
              renderDelete()}
            {isAddingRoleToPathway && roleBeingAdded === spoke?.id && positionLabel === 'left' && (
              <div style={{ height: 24, width: 24, overflow: 'hidden' }}>
                <CircularProgress size={24} className="iq4-30__loading__spinner" />
              </div>
            )}
            <div className="iq4-pathway__label-text">{displayName?.replace(/,/, '\u200E,')}</div>
            {!isPublicProfile &&
              !(roleBeingAdded === spoke?.id) &&
              positionLabel === 'right' &&
              nodeType !== 'intermediate' &&
              renderDelete()}
            {isAddingRoleToPathway && roleBeingAdded === spoke?.id && positionLabel === 'right' && (
              <div style={{ height: 24, width: 24, overflow: 'hidden' }}>
                <CircularProgress size={24} className="iq4-30__loading__spinner" />
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div className="iq4-pathways-donut__container" style={{ marginBottom: '1rem' }}>
        {renderNodeIcon()}

        <div
          role="button"
          className="role-node-button"
          aria-label={`${displayName} node. Click to See more information about `}
          tabIndex={0}
          onClick={() => onSelect(spoke)}
          onKeyPress={() => onSelect(spoke)}
        >
          <SimpleIcon
            name={`${spoke?.type === 'achievement' ? 'educationCircle' : 'roleIcon'}`}
            className={`iq4-pathway__role-btn--icon ${
              isHovered ? 'iq4-pathway__role-btn--hovered' : ''
            }`}
          />
          <div className="donut" style={backgroundStyle} />
        </div>
      </div>
    );
  }

  return (
    <div className="iq4-pathways-donut__container">
      <div
        className={`iq4-pathway__circle-container__label iq4-pathway__circle-container--label-${positionLabel}`}
      >
        {displayName?.replace(/,/, '\u200E,')}
        {!isPublicProfile && !(roleBeingAdded === spoke?.id) && (
          <span className="iq4-pathway__suggestion-label-container--actions">
            <span
              role="button"
              tabIndex="0"
              aria-label={`Add ${displayName} to your pathway`}
              onClick={onAdd}
              onKeyPress={onAdd}
            >
              <SimpleIcon name="add" />
              <span className="iq4-pathway__suggestion-label-container--actions-label">Add</span>
            </span>
          </span>
        )}
        {isAddingRoleToPathway && roleBeingAdded === spoke?.id && (
          <div style={{ height: 24, width: 24, overflow: 'hidden' }}>
            <CircularProgress size={24} className="iq4-30__loading__spinner" />
          </div>
        )}
      </div>
      <div
        className="suggested-role"
        role="button"
        tabIndex={0}
        onClick={() => onSelect(spoke)}
        onKeyPress={() => onSelect(spoke)}
        aria-label={`See more information about ${displayName}`}
      >
        <SimpleIcon className="iq4-pathways__ghost-node" name="roleGhostIcon" />
      </div>
    </div>
  );
};
