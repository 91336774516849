import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'common/components';
import { ResumeParserSkillList } from '../ResumeParserSkillList';
import { ResumeParserUpload } from '../ResumeParserUpload';
import { ResumeParserAssessmentList } from '../ResumeParserAssessmentList';
import { selectResumeParsedSkills } from 'common/store/features/skills/selectors';
import { parseResumeReq } from 'common/store/features/parsedResume/parsedResumeSlice';
import {
  updateSelectedResumeParserSkills,
  updateAssessmentResumeParserSkill,
  saveAllSkillsReq,
  setResumeParserSkills,
} from 'common/store/features/skills/skillsSlice';

import './ResumeParserWizard.scss';

const WIZARD_SECTIONS = ['RESUME_UPLOAD', 'SKILLS_CHECK', 'ASSESSMENTS'];

export const ResumeParserWizard = ({
  className,
  onSectionChange = () => {},
  onSave = () => {},
  ...props
}) => {
  const dispatch = useDispatch();
  const parsedResumeSkills = useSelector(selectResumeParsedSkills); // get from selector
  const derivedSkillsForSkillList = useMemo(() => getSkillsForSkillsList(parsedResumeSkills), [
    parsedResumeSkills,
  ]);
  const checkedSkillsOnly = useMemo(() => getCheckedSkillsOnly(derivedSkillsForSkillList), [
    derivedSkillsForSkillList,
  ]);
  const [activeSection, setActiveSection] = useState(() => setInitialSection(parsedResumeSkills));
  const [isUploadingResume, setIsUploadingResume] = useState(false);

  // handle sections change subscription
  useEffect(() => {
    onSectionChange(activeSection);
  }, [activeSection]); // eslint-disable-line react-hooks/exhaustive-deps

  // handle parsing the user's resume
  const handleResumeUpload = async (attachment) => {
    // extract data
    let formData = new FormData();
    formData.append('resume', attachment[0]);

    // set the loader active while uploading the resume
    setIsUploadingResume(true);

    // parse resume and get relevant skills
    await dispatch(parseResumeReq(formData));

    // hide the loader
    setIsUploadingResume(false);
    // go to the next section
    setActiveSection(WIZARD_SECTIONS[1]);
  };

  // handle checking/unchecking skills derived from resume
  const handleSkillCheckChange = (skill, checked) => {
    dispatch(updateSelectedResumeParserSkills({ skill, checked }));
  };

  const handleToggleSection = (isNext) => {
    const currentSectionIndex = WIZARD_SECTIONS.findIndex((section) => activeSection === section);
    const nextSection = WIZARD_SECTIONS[currentSectionIndex + (isNext ? 1 : -1)];
    setActiveSection(nextSection);
  };

  //
  const handleAssessmentChange = (skill, assessment) => {
    dispatch(updateAssessmentResumeParserSkill({ skill, assessment }));
  };

  // save skills
  const handleDone = async () => {
    const payload = checkedSkillsOnly.map((skill) => ({
      competenceId: skill.id,
      assessment: skill.assessment,
    }));

    await dispatch(saveAllSkillsReq(payload));

    dispatch(setResumeParserSkills([]));
    setActiveSection(WIZARD_SECTIONS[0]);
    onSave();
  };

  // figures out the sections to show based on the parsed skills.
  function setInitialSection(parsedSkills) {
    // if parsedSkills isn't available return the initial screen
    if (!parsedSkills || !parsedSkills.length) return WIZARD_SECTIONS[0];

    // TODO: see if any of the skills were assessed, if so, return the assessments section
    const hasAssessment = !!parsedSkills.find(
      (skill) => skill.assessment !== null && skill.checked,
    );
    if (hasAssessment) return WIZARD_SECTIONS[2];

    // if there are skills but none have been assessed yet then return the skills check section
    return WIZARD_SECTIONS[1];
  }

  function getSkillsForSkillsList(skills = []) {
    return skills.map((skill) => ({
      id: skill.competenceId,
      value: skill.label,
      tier1: skill.tier1.label,
      tier2: skill.tier2.label,
      selected: skill.checked,
      assessment: skill.assessment,
    }));
  }

  function getCheckedSkillsOnly(skills = []) {
    return skills.filter((skill) => skill.selected);
  }

  return (
    <div className={`iq4-resume-parser-wizard ${className ? className : ''}`} {...props}>
      <div className="iq4-resume-parser-wizard__main">
        {activeSection === WIZARD_SECTIONS[0] && (
          <ResumeParserUpload onUpload={handleResumeUpload} isUploading={isUploadingResume} />
        )}
        {activeSection === WIZARD_SECTIONS[1] && (
          <ResumeParserSkillList
            skills={derivedSkillsForSkillList}
            onChange={handleSkillCheckChange}
          />
        )}
        {activeSection === WIZARD_SECTIONS[2] && (
          <ResumeParserAssessmentList
            skills={checkedSkillsOnly}
            onChange={handleAssessmentChange}
          />
        )}
      </div>

      <div className="iq4-resume-parser-wizard__footer">
        <div className="iq4-resume-parser-wizard__footer-info">
          {activeSection === WIZARD_SECTIONS[1] && !!parsedResumeSkills.length && (
            <p>Now let's score your skills!</p>
          )}

          {activeSection === WIZARD_SECTIONS[2] && (
            <>
              <p>
                <strong>You're done here, great!</strong>
              </p>
              <p>Go to profile</p>
            </>
          )}
        </div>

        <div className="iq4-resume-parser-wizard__footer-btns">
          {(activeSection === WIZARD_SECTIONS[1] || activeSection === WIZARD_SECTIONS[2]) && (
            <Button
              className="iq4-resume-parser-wizard__btn"
              onClick={() => void handleToggleSection()}
            >
              Back
            </Button>
          )}
          {((activeSection === WIZARD_SECTIONS[0] && parsedResumeSkills.length) ||
            (activeSection === WIZARD_SECTIONS[1] && !!checkedSkillsOnly.length)) && (
            <Button
              className="iq4-resume-parser-wizard__btn"
              onClick={() => void handleToggleSection(true)}
            >
              Next
            </Button>
          )}

          {activeSection === WIZARD_SECTIONS[2] && (
            <Button className="iq4-resume-parser-wizard__btn" onClick={() => void handleDone()}>
              Done
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
