import React from 'react';
import { OpenPositionsInnerCard } from './OpenPositionsInnerCard';
import poweredByCOS from 'assets/jpg/powered_by_cos.jpg';
import './OpenPositions.scss';

export const OpenPositions = ({ role }) => {
  const { liveRoles } = role;

  return (
    <div className="iq4-open-positions">
      {liveRoles && liveRoles.map((position) => <OpenPositionsInnerCard position={position} />)}
      <div className="iq4-open-positions__acknowledgement">
        <p>
          Source: United States Department of Labor Employment and Training Administration
          ("DOLETA") and the Minnesota Department of Employment &amp; Economic Development ("DEED")
        </p>
        <div className="iq4-open-positions__powered-by">
          <img src={poweredByCOS} alt="Powered by Career One Stop" />
        </div>
      </div>
    </div>
  );
};
