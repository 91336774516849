export const LCN_RECORD_STATES = {
  ACTIVE: 'ACTIVE',
  NEW: 'NEW',
  HISTORY: 'HISTORY',
};

export const LCN_RECORD_STATUSES = {
  SHARED: 'shared',
  REQUESTED: 'requested',
};

export const LCN_CREDENTIAL_PROVIDERS = {
  BADGR: 'BADGR',
  NSC: 'NSC',
};

export const LCN_SHARE_STATUSES = {
  ACTIVE: 'ACTIVE',
  VIEWED: 'VIEWED',
  EXPIRED: 'EXPIRED',
  REJECT: 'REJECT',
};

export const LCN_SHARE_HISTORY_ALL_STATUSES = ['ACTIVE', 'VIEWED', 'EXPIRED', 'REJECT'];
