import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { ROUTES } from 'routes';
import { Topbar, SimpleIcon, Button } from 'common/components';
import { ProfileResumeParse } from 'components/Profile';
import { ProfilePersonal } from 'components/Profile/ProfilePersonal';
import { ProfilePortfolioDetail } from 'components/Profile/ProfilePortfolioDetail';
import { TriggerModal } from 'common/components/TriggerModal';
import { ProfilePortfolioForm } from 'common/components/Forms';
import { ProfilePageLayout } from 'components/Profile/ProfilePageLayout';
import { selectFeatures } from 'store/features/config/selectors';

export const ProfilePortfolioPage = () => {
  const isMobile = useIsMobile();
  const { applicationTitle, profilePortfolioLabel } = useSelector(selectFeatures);
  const [showResumeParser, setShowResumeParser] = useState(false);

  return (
    <>
      {!isMobile && (
        <ProfilePageLayout helmetTitle={`${applicationTitle} - Profile - Education`}>
          <div>
            {!isMobile && (
              <Button variation="ghost" onClick={() => setShowResumeParser(true)}>
                Upload your updated resume
              </Button>
            )}
            {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}
          </div>
          <ProfilePersonal />
          <ProfilePortfolioDetail />
        </ProfilePageLayout>
      )}

      {isMobile && (
        <section className="iq4-profile-page-mobile-detail">
          <Topbar Logo={Logo} />

          <main className="iq4-profile-page-mobile-detail__main">
            <Link to={ROUTES.PROFILE_ROOT} className="iq4-profile-page-mobile-detail__back-link">
              <SimpleIcon name="arrow" /> {profilePortfolioLabel}
            </Link>

            <ProfilePortfolioDetail />

            <TriggerModal
              title={profilePortfolioLabel}
              form={<ProfilePortfolioForm />}
              label={<FormattedMessage id="profile.addAnother.label" />}
              buttonVariation="primary"
            />
          </main>
        </section>
      )}
    </>
  );
};
