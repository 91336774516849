import { orderBy } from 'lodash';

export const SKILL_MAX_RATING = 4;

export const RATING_STARS_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const DEFAULT_SKILLS_FILTER = {
  technologyCategory: false,
  professionalSkillsCategory: false,
  businessDevelopmentCategory: false,
  educationSource: false,
  experienceSource: false,
  portfolioSource: false,
  publicPermission: false,
  privatePermission: false,
  withPermission: false,
  proficiencyOne: false,
  proficiencyTwo: false,
  proficiencyThree: false,
  proficiencyFour: false,
  outcomeTypeCommon: false,
  outcomeTypeHard: false,
  isVerified: false,
  isSelfAttested: false,
};

export const DEFAULT_SKILLS_SORT = {
  aToZ: true,
  zToA: false,
  newToOld: false,
  oldToNew: false,
  lowToHigh: false,
  highToLow: false,
};

export const SKILLS_SORT_FUNCS = {
  aToZ: (skills) => orderBy(skills, (skill) => skill.tier3Label, ['asc']),
  zToA: (skills) => orderBy(skills, (skill) => skill.tier3Label, ['desc']),
  newToOld: (skills) => orderBy(skills, (skill) => new Date(skill.dateAdded), ['desc']),
  oldToNew: (skills) => orderBy(skills, (skill) => new Date(skill.dateAdded), ['asc']),
  lowToHigh: (skills) => orderBy(skills, (skill) => skill.proficiency || 0, ['asc']),
  highToLow: (skills) => orderBy(skills, (skill) => skill.proficiency || 0, ['desc']),
};

export const SKILLS_FILTER_PROP_MAPPING = {
  technologyCategory: (skill) => skill.competenceGroupId === 'CG01',
  professionalSkillsCategory: (skill) =>
    skill.competenceGroupId === 'CG03' || skill.competenceGroupId === 'CG04',
  businessDevelopmentCategory: (skill) => skill.competenceGroupId === 'CG02',
  educationSource: (skill) => {
    const educationSources = skill.sources.filter((source) => source.type === 'education');
    return educationSources && educationSources.length;
  },
  experienceSource: (skill) => {
    const educationSources = skill.sources.filter((source) => source.type === 'experience');
    return educationSources && educationSources.length;
  },
  portfolioSource: (skill) => {
    const educationSources = skill.sources.filter((source) => source.type === 'portfolio');
    return educationSources && educationSources.length;
  },
  publicPermission: (skill) => skill.isPublic,
  privatePermission: (skill) => !skill.isPublic,
  proficiencyOne: (skill) => skill.proficiency === 1,
  proficiencyTwo: (skill) => skill.proficiency === 2,
  proficiencyThree: (skill) => skill.proficiency === 3,
  proficiencyFour: (skill) => skill.proficiency === 4,
  outcomeTypeCommon: (skill) => skill.outcomeType === 'Emsi Common Skill',
  outcomeTypeHard: (skill) => skill.outcomeType === 'Emsi Hard Skill',
};

export const GENDER_CHART_MALE_COLOR = '#B275B2';
export const GENDER_CHART_FEMALE_COLOR = '#000000';

export const SKILLS_OVERLAP_COLOR_SCHEME = {
  UNMATCHED_SKILL: '#000000',
  MATCHED_BACKGROUND: '#B275B2',
  MATCHED_LINES: '#000000',
  MISSING_SKILLS: '#B275B2',
};

export const SALARY_CHART_COLOR = '#B275B2';

export const SPOKE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const TABLE_SORT_TYPE = {
  SIMPLE: 'SIMPLE',
  DOUBLE_ARROW: 'DOUBLE_ARROW',
};

export const COLUMN_TYPE = {
  RAW: 'raw',
  LINK: 'link',
  DATE: 'date',
  DATE_LINK: 'date-link',
  EXPAND: 'expand',
  CUSTOM: 'custom',
};

export const OPTION_TYPE = {
  SELECTED: 'selected',
  UNSELECTED: 'unselected',
  DIVIDER: 'divider',
};

export const SKILL_TYPE = {
  MATCHED: 'matched',
  UNMATCHED: 'unmatched',
  MISSING: 'missing',
};

export const DASHBOARD_APP_CHART_BACKGROUND_1 = '#b275b1';
export const DASHBOARD_APP_CHART_BACKGROUND_2 = '#2d283d';

export const DEFAULT_TABLE_PAGE_SIZE = 10;

export const SOURCE_TYPES = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  ALL: 'ALL',
};
