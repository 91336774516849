import React from 'react';

export const defaultContent = () => (
  <div className="iq4-terms-page--container">
    <div className="iq4-terms-page--content">
      <strong>1. INTRODUCTION.</strong>
      <br />
      <br />
      A. Purpose. iQ4’s purpose and goal is to provide a platform where people can share thoughts,
      ideas, research and questions and work collaboratively to achieve a desired result. To achieve
      this mission, iQ4 makes features and functionality available through our website, including
      our mobile and other applications, and other information available as part of the iQ4 services
      to communities and groups and for projects (“Services”).
      <br />
      <br />
      B. Registration and/or Use Represents Your Agreement to iQ4 Agreement and Privacy Policy. You
      agree that by registering on iQ4, or by using the our website, including our mobile
      applications, developer platform (if applicable), services, iQ4 Platforms (as defined below)
      or other information provided as part of the iQ4 services (individually a “Service” or
      collectively, if applicable, the “Services”), you are entering into a legally binding
      agreement with iQ4 Corporation, 300 Tice Boulevard, Suite 315, Woodcliff Lake, New Jersey
      07677 (“we,” “us,” “our,” and “iQ4”) based on the terms of this iQ4 User Agreement and the iQ4
      Privacy Policy, which is hereby incorporated by reference (collectively referred to as the
      “Agreement” or “Terms of Service”). You cannot become an iQ4 user unless you agree to the
      terms of the Agreement.
      <br />
      <br />
      C. If you are using iQ4 through or on behalf of a company or other legal entity, you are
      nevertheless individually bound by this Agreement even if your company or other legal entity
      has a separate agreement with us. If you do not want to register and become an iQ4 User, do
      not conclude the Agreement, do NOT click “Join iQ4” and do not access, view, download, post or
      otherwise use any iQ4 webpage, information or services. By clicking “Join iQ4,” you
      acknowledge that you have read and understood the terms and conditions of this Agreement and
      that you agree to be bound by all of its provisions. By clicking “Accept” you also consent to
      use electronic signatures and acknowledge your click of the “Accept” button as one.
      <br />
      <br />
      THIS AGREEMENT CONTAINS DISCLAIMERS OF WARRANTIES AND REPRESENTATIONS (SECTION 5), A
      LIMITATION OF LIABILITY (SECTION 6), AND A BINDING DISPUTE RESOLUTION CLAUSE (SECTION 8).
      PLEASE READ IT CAREFULLY.
      <br />
      <br />
      <strong>2. YOUR OBLIGATIONS.</strong>
      <br />
      <br />
      A. Compliance with Applicable laws and this Agreement. You must comply with all applicable
      laws, the Agreement, as may be amended from time to time with or without advance notice, and
      the policies and processes explained in the Agreement.
      <br />
      <br />
      B. License and warranty for your submissions to iQ4. You own the information and content
      (including written, audio, visual, text, artistic, or other content in any medium or format
      now known or hereafter discovered (collectively, “content”) you provide iQ4 under this
      Agreement, and may request its deletion at any time, unless you have shared such information
      or content with others who are registered with iQ4 and/or using the Services (individually, a
      “User” and, collectively, “Users”) and they have not deleted it, or it was copied or stored by
      other Users. Additionally, you grant iQ4 a nonexclusive, irrevocable, worldwide, perpetual,
      unlimited, assignable, sublicensable, fully paid up and royalty-free right and license,
      without any further consent, notice, and/or compensation to you or to any third parties, to
      copy, prepare derivative works of, improve, distribute, publish, publicly display, perform,
      remove, retain, add, process, analyze, use and commercialize, in any way now known or in the
      future discovered, any information or content you provide, directly or indirectly to iQ4,
      including, but not limited to, any user generated content, ideas, concepts, techniques, or
      data you submit to iQ4. Any information you submit to us is at your own risk of loss as noted
      in Sections 2 and 3 of this Agreement. By providing information and content to us, you
      represent and warrant that you are entitled to submit the information and content and that the
      information and content is accurate, not confidential, and not in violation of any contractual
      restrictions or other third party rights. It is your responsibility to keep your iQ4 profile
      information and other content accurate and updated.
      <br />
      <br />
      C. iQ4 Service Eligibility. To be eligible to use the Service, you must meet the following
      criteria and represent and warrant that you: (1) are 13 years of age or older and, if you are
      a minor over 13, have obtained your parent or legal guardian’s consent and are participating
      under such parent or legal guardian’s supervision; (2) are not currently restricted from the
      Services, or not otherwise prohibited from registering with iQ4 and maintaining a membership
      profile, (3) are not a competitor of iQ4 or are not using the Services for reasons that are in
      competition with iQ4; (4) will maintain only one iQ4 registration and membership profile at
      any given time; (5) have full power and authority to enter into this Agreement and doing so
      will not violate any other agreement to which you are a party; (6) will not violate any rights
      of iQ4, including intellectual property rights such as copyright or trademark rights; and (7)
      agree to provide at your cost all equipment, software, and internet access necessary to use
      the Services.
      <br />
      <br />
      D. Login Security; Use of iQ4 Logins. You agree to:
      <br />
      (1) keep your password secure and confidential;
      <br />
      (2) not permit others to use your iQ4 profile or membership;
      <br />
      (3) refrain from using other Users’ iQ4 profile or membership;
      <br />
      (4) refrain from selling, trading, or otherwise transferring your iQ4 membership to another
      party; and
      <br />
      (5) refrain from charging anyone for access to any portion of iQ4, or any information therein.
      Further, you are responsible for anything that happens through your profile or membership
      until you terminate your membership or prove that your membership security was compromised due
      to no fault of your own. To terminate your membership, please contact iQ4 in accordance with
      Section 9(C).
      <br />
      <br />
      E. Indemnification. You shall indemnify us and hold us harmless for all damages, losses and
      costs (including, but not limited to, reasonable attorneys’ fees and costs) related to all
      third party claims, charges, and investigations, caused by (1) your failure to comply with
      this Agreement, including, without limitation, your submission of information or content that
      violates third party rights or applicable laws, (2) any information or content you submit to
      the Services, and (3) any activity in which you engage on or through iQ4.
      <br />
      <br />
      F. Payment. If you purchase any Services that we offer for a fee, either on a one-time or
      subscription basis, you agree to iQ4 storing your credit or debit card information. You also
      agree to pay the applicable fees for such Services as they become due plus all related taxes,
      and to reimburse us for all collection costs and interest for any overdue amounts. Your
      obligation to pay fees continues through the end of the subscription period during which you
      cancel your subscription. You may cancel your Services by contacting us in accordance with
      Section 9(C). iQ4 does not refund any fees for any reason.
      <br />
      <br />
      G. Prior Notification to iQ4 Required for Acts Contrary to the Agreement. If you believe that
      you are entitled or obligated to act contrary to this Agreement under any mandatory law, you
      agree to provide us with detailed and substantiated explanation of your reasons in writing at
      least 30 days before you act contrary to this Agreement, to allow us to assess whether we may,
      at our sole discretion, provide an alternative remedy for the situation, though we are under
      no obligation to do so.
      <br />
      <br />
      H. Notifications and Service Messages. For purposes of service messages and notices about the
      Services to you, iQ4 may place a notice on its pages to alert you to certain changes such as
      modifications to this Agreement. Alternatively, notice may consist of an email from iQ4 to an
      email address associated with your membership, even if we have other contact information. You
      also agree that iQ4 may communicate with you through the iQ4 platform or through other means
      including email, mobile number, telephone, or delivery services including the postal service
      about your iQ4 membership or Services associated with iQ4. You acknowledge and agree that we
      shall have no liability associated with or arising from your failure to maintain accurate
      contact or other information, including, but not limited to, your failure to receive critical
      information about the Service.
      <br />
      <br />
      I. iQ4 Applications. iQ4 may offer the Services through applications such as a smart phone
      application (iQ4 for Blackberry or iQ4 for iPhone) and other interactive plugins distributed
      on websites across the Internet. iQ4 applications are distinct from third party platform
      applications addressed in Section 4(B). If you use an iQ4 application or interact with a
      website that has deployed a plugin, you agree that information about you and your use of the
      Services, including, but not limited to, your device, your mobile carrier, your internet
      access provider, your physical location, and/or web pages containing iQ4 plugins that load in
      your browser may be communicated to us. Further, by importing any of your iQ4 data through the
      iQ4 application, you represent that you have authority to share the transferred data with your
      mobile carrier or other access provider. In the event you change or deactivate your mobile
      account, you must promptly update your iQ4 account information to ensure that your messages
      are not sent to the person that acquires your old number and failure to do so is your
      responsibility. You acknowledge you are responsible for all charges and necessary permissions
      related to accessing iQ4 through your mobile access provider. Therefore, you should check with
      your provider to find out if the Services are available and the terms for these services for
      your specific mobile devices. Finally, by using any downloadable application to enable your
      use of the Services, you are explicitly confirming your acceptance of the terms of the end
      user license agreement associated with the application provided at download or installation,
      or as may be updated from time to time.
      <br />
      <br />
      J. User-to-User Communication and Sharing (iQ4 Groups, Communities and Projects). iQ4 offers
      various forums such as iQ4 Groups, iQ4 Communities and iQ4 Projects (collectively, “iQ4
      Platforms”), where, with the permission of the applicable iQ4 Platform manager, you can post
      your observations, comments, content and information on designated topics and questions and
      share such items with Users of the applicable iQ4 Platform. Please note that ideas you post
      and content and information you share may be seen and used by other Users, and iQ4 cannot
      guarantee that other Users will not use the ideas, information and content that you share on
      iQ4. Therefore, if you have an idea, content or information that you would like to keep
      confidential and/or don’t want others to use, or that is subject to third party rights that
      may be infringed by your sharing it, do not post it to any iQ4 Platform, or elsewhere on iQ4.
      iQ4 IS NOT RESPONSIBLE FOR A USER’S MISUSE OR MISAPPROPRIATION OF ANY CONTENT OR INFORMATION
      YOU POST IN ANY iQ4 PLATFORM .
      <br />
      <br />
      K. Privacy. You should carefully read our full Privacy Policy before deciding to become a User
      as it is hereby incorporated into this User Agreement by reference, and governs our treatment
      of any information, including personally identifiable information you submit to us. Please
      note that certain information, statements, data, and content which you may submit to iQ4, or
      groups you choose to join might, or are likely to, reveal your gender, ethnic origin,
      nationality, age, and/or other personal information about you. You acknowledge that your
      submission of any information, statements, data, and content to us is voluntary on your part.
      <br />
      <br />
      L. Export Control. Your use of iQ4 and the Services is subject to export and re-export control
      laws and regulations, including the Export Administration Regulations maintained by the United
      States Department of Commerce and sanctions programs maintained by the United States Treasury
      Department’s Office of Foreign Assets Control. You shall not, directly or indirectly, sell,
      export, re-export, transfer, divert, or otherwise dispose of any software or service to any
      end-user without obtaining the required authorizations from the appropriate government
      authorities. You also warrant that you are not prohibited from receiving U.S. origin products,
      including services or software.
      <br />
      <br />
      M. Contributions to iQ4. By submitting ideas, suggestions, documents, content and/or proposals
      (“Contributions”) to iQ4 through its suggestion or feedback mechanisms or in accordance with
      Section 9(C), you acknowledge and agree that: (a) your Contributions do not contain
      confidential or proprietary information; (b) iQ4 is not under any obligation of
      confidentiality, express or implied, with respect to the Contributions; (c) iQ4 shall be
      entitled to use or disclose (or choose not to use or disclose) such Contributions for any
      purpose, in any way, in any media worldwide; (d) iQ4 may have something similar to the
      Contributions already under consideration or in development; (e) you irrevocably assign to iQ4
      all rights to your Contributions; and (f) you are not entitled to any compensation or
      reimbursement of any kind from iQ4 under any circumstances.
      <br />
      <br />
      N. Certain Agreements of Users. As a condition to access iQ4, you agree to this User Agreement
      and to strictly observe the covenants set forth below. a. You agree to undertake the
      following:
      <br />
      1. Comply with all applicable laws, including, without limitation, privacy laws, intellectual
      property laws, export control laws, tax laws, and regulatory requirements;
      <br />
      2. Provide accurate information to us and update it as necessary;
      <br />
      3. Protect sensitive personal information such as your email address, phone number, street
      address, or other information that is confidential in nature
      <br />
      4. Review and comply with our Privacy Policy;
      <br />
      5. Review and comply with notices sent by iQ4 concerning the Services; and
      <br />
      6. Use the Services in a professional manner.
      <br />
      <br />
      b. You agree to not:
      <br />
      1. Act dishonestly or unprofessionally by engaging in unprofessional behavior by posting
      inappropriate, inaccurate, or objectionable content to iQ4;
      <br />
      2. Provide inaccurate information about yourself in your membership application, your member
      profile, or to any other member;
      <br />
      3. Create a user profile or membership for anyone other than a natural person;
      <br />
      4. Harass, abuse or harm another person, including sending unwelcomed communications to others
      using iQ4;
      <br />
      5. Upload a profile image that is not a head-shot photo of your likeness;
      <br />
      6. Use or attempt to use another User’s membership or iQ4 access, or create a false identity
      on iQ4;
      <br />
      7. Upload, post, email, display, distribute, transmit or otherwise make available or initiate
      any content that:
      <br />
      i. Falsely states, impersonates or otherwise misrepresents your identity, including but not
      limited to the use of a pseudonym, or misrepresenting your current or previous positions and
      qualifications, or your affiliations with a person or entity, past or present;
      <br />
      ii. Is unlawful, libelous, slanderous, defamatory, abusive, obscene, discriminatory, or
      otherwise objectionable;
      <br />
      iii. You know or have reason to believe is false or misleading;
      <br />
      iv. Adds to a content field content that is not intended for such field;
      <br />
      v. Includes information that you do not have the right to disclose or make available under any
      law or under contractual or fiduciary relationships (such as insider information, or
      proprietary and confidential information learned or disclosed as part of employment
      relationships or under nondisclosure agreements);
      <br />
      vi. Infringes upon patents, trademarks, trade secrets, copyrights, or other proprietary
      rights;
      <br />
      vii. Includes any unsolicited or unauthorized advertising, promotional materials, “junk mail,”
      “spam,” “chain letters,” “pyramid schemes,” or any other form of solicitation;
      <br />
      viii. Contains software viruses, worms, or any other computer code, files, or programs that
      interrupt, destroy or limit the functionality of any computer software or hardware or
      telecommunications equipment of iQ4 or any User of iQ4;
      <br />
      ix. Forges headers or otherwise manipulates identifiers in order to disguise the origin of any
      communication transmitted through the Services;
      <br />
      <br />
      8. Duplicate, license, sublicense, publish, broadcast, transmit, distribute, perform, display,
      sell, rebrand, or otherwise transfer information or content found on iQ4 (excluding
      information and content posted by you) except as permitted in this Agreement, or as expressly
      authorized by iQ4;
      <br />
      9. Reverse engineer, decompile, disassemble, decipher, or otherwise attempt to derive the
      source code for any underlying intellectual property used to provide the Services, or any part
      thereof;
      <br />
      10. Utilize or copy information, content, or any data you view on and/or obtain from iQ4 to
      provide any service that is competitive, in iQ4’s sole discretion, with iQ4;
      <br />
      11. Imply or state, directly or indirectly, that you are affiliated with or endorsed by iQ4
      unless you have entered into a written agreement with iQ4;
      <br />
      12. Adapt, modify, or create derivative works based on iQ4 or technology underlying the
      Services, or other Users’ content, in whole or part, except as permitted by iQ4;
      <br />
      13. Rent, lease, loan, trade, sell/re-sell access to iQ4 or any information therein, or the
      equivalent, in whole or part;
      <br />
      14. Sell, sponsor, or otherwise monetize an iQ4 Platform or any other service or functionality
      of iQ4, without the express written permission of iQ4.
      <br />
      15. Deep-link to the iQ4 website or any iQ4 Platform for any purpose (i.e., including a link
      to an iQ4 web page that is not iQ4’s home page), unless expressly authorized in writing by
      iQ4;
      <br />
      16. Remove any copyright, trademark, or other proprietary rights notices contained in or on
      iQ4, including those of both iQ4 and any of its licensors;
      <br />
      17. Remove, cover, or otherwise obscure any form of advertisement included on iQ4;
      <br />
      18. Collect, use, copy, distribute, display, or transfer any information, including, but not
      limited to, personally identifiable information obtained from iQ4 except as expressly
      permitted in this Agreement or as the owner of such information may expressly permit;
      <br />
      19. Share information or content of non-Users without their express consent;
      <br />
      20. Infringe or use iQ4’s brand, logos, and/or trademarks, including, without limitation,
      using the word “iQ4” in any business name, email, or URL, or including iQ4’s trademarks and
      logos except as expressly permitted by iQ4;
      <br />
      21. Use manual or automated software, devices, scripts robots, other means or processes to
      access, “scrape,” “crawl,” or “spider” any web pages or other services contained in the site;
      <br />
      22. Use bots or other automated methods to access iQ4, add or download information from the
      iQ4 Platform, send or redirect messages, or perform other activities through iQ4, unless
      explicitly permitted by iQ4;
      <br />
      23. Access, via automated or manual means or processes, iQ4 for purposes of monitoring iQ4’s
      availability, performance, or functionality for any competitive purpose;
      <br />
      24. Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of
      iQ4’s website or any iQ4 Platform;
      <br />
      25. Attempt to or actually access iQ4 by any means other than through the interfaces provided
      by iQ4, including, without limitation, mobile application or by navigating to
      http://www.iq4.com using a web browser (for the avoidance of doubt, such prohibition includes
      accessing or attempting to access iQ4 using any third-party service, including
      software-as-a-service platforms that aggregate access to multiple services, including iQ4);
      <br />
      26. Attempt to or actually override any security component included in or underlying iQ4;
      <br />
      27. Engage in any action that directly or indirectly interferes with the proper working of or
      places an unreasonable load on iQ4’s infrastructure, including, but not limited to, sending
      unsolicited communications to other Users or iQ4 personnel, attempting to gain unauthorized
      access to iQ4, or transmitting or activating computer viruses through or on iQ4; and
      <br />
      28. Interfere with or disrupt iQ4 or the Services, including, but not limited to, any servers
      or networks connected to iQ4, in particular, iQ4’s search algorithms.
      <br />
      <br />
      <strong>3. YOUR RIGHTS.</strong>
      <br />
      <br />
      A. On the condition that you comply with all your obligations under this Agreement, we grant
      you a limited, revocable, nonexclusive, nonassignable, nonsublicensable license and right to
      access the Services, through a generally available web browser, mobile device, or application
      (but not through scraping, spidering, crawling, or other technology or software used to access
      data without the express written consent of iQ4 or its Users), view information, and use the
      Services that we provide on iQ4 websites and/or an iQ4 Platform and in accordance with this
      Agreement. Any other use of iQ4 contrary to our mission and purpose (such as seeking to
      connect to someone you do not know or trust, or to use information gathered from iQ4
      commercially unless expressly authorized by iQ4) is strictly prohibited and a violation of
      this Agreement. We reserve all rights not expressly granted in this Agreement, including,
      without limitation, title, ownership, intellectual property rights, and all other rights and
      interest in iQ4 and all related items, including any and all copies made of the iQ4 website.
      <br />
      <br />
      <strong>4. OUR RIGHTS AND OBLIGATIONS.</strong>
      <br />
      <br />
      A. Services Availability. For as long as iQ4 continues to offer the Services, iQ4 shall
      provide and seek to update, improve, and expand the Services. As a result, we allow you to
      access iQ4 as it may exist and be available on any given day and have no other obligations,
      except as expressly stated in this Agreement. We may modify, replace, refuse access to,
      suspend, or discontinue iQ4, partially or entirely, or change and modify prices for all or
      part of the Services for you or for all our users in our sole discretion. All of these changes
      shall be effective upon their posting on our site or by direct communication to you unless
      otherwise noted. iQ4 further reserves the right to withhold, remove and/or discard any
      information or content available as part of your membership, with or without notice if deemed
      by iQ4 to be contrary to this Agreement. For avoidance of doubt, iQ4 has no obligation to
      store, maintain, or provide you a copy of any information or content that you or other Users
      provide when using the Services.
      <br />
      <br />
      B. Third Party Sites and Developers. iQ4 may include links to third party web sites on
      www.iq4.com and elsewhere. iQ4 also enables third party developers to create applications that
      provide features and functionality using data and developer tools made available by iQ4
      through a developer platform. You are responsible for evaluating whether you want to access or
      use a third party site or platform application. You should review any applicable terms and/or
      privacy policy of a third party site or platform application before using it or sharing any
      information with it, because you may give the operator permission to use your information in
      ways we would not. iQ4 is not responsible for and does not endorse any features, content,
      advertising, products or other materials on or available from third party sites or platform
      applications. iQ4 also does not screen, audit, or endorse platform applications. Accordingly,
      if you decide to access third party sites or use platform applications, you do so at your own
      risk and agree that your use of any platform application is on an “as-is” basis without any
      warranty as to the platform developer’s actions, and that this Agreement does not apply to
      your use of any third party site or developer application. Please note: If you allow a
      platform application or third party site to authenticate to or connect with your iQ4
      membership, that application or website can access information on iQ4 related to you and the
      content and information you have and continue to provide to iQ4.
      <br />
      <br />
      C. Disclosure of User Information. You acknowledge, consent and agree that we may access,
      preserve, and disclose your registration and any other information you provide if required to
      do so by law or in a good faith belief that such access, preservation or disclosure is
      reasonably necessary in our opinion to:
      <br />
      (1) comply with legal process, including, but not limited to, civil and criminal subpoenas,
      court orders or other compulsory disclosures;
      <br />
      (2) enforce this Agreement;
      <br />
      (3) respond to claims of a violation of the rights of third parties, whether or not the third
      party is a User, individual, or government agency;
      <br />
      (4) respond to customer service inquiries; or
      <br />
      (5) protect the rights, property, or personal safety of iQ4, our Users or the public.
      Disclosures of User information to third parties other than those required to provide customer
      support, administer this agreement, or comply with legal requirements are addressed in the
      Privacy Policy.
      <br />
      <br />
      D. Connections and Interactions with other Users. You are solely responsible for your
      interactions with other Users. iQ4 or an iQ4 Platform manager may limit, restrict, or prohibit
      your ability to interact with other Users on a particular platform and may, in certain
      circumstances, prohibit you from contacting other Users through use of the Services or
      otherwise limit your use of the Services. iQ4 reserves the right, but has no obligation, to
      monitor disputes between you and iQ4 Platform managers, or you and other Users and to
      restrict, suspend, or terminate your membership if iQ4 determines, in our sole discretion,
      that doing so is necessary to enforce this Agreement.
      <br />
      <br />
      <strong>5. DISCLAIMER OF REPRESENTATIONS AND WARRANTIES</strong>
      <br />
      <br />
      SOME COUNTRIES AND JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED TERMS IN CONTRACTS
      WITH CONSUMERS AND AS A RESULT THE CONTENTS OF THIS SECTION MAY NOT APPLY TO YOU. DO NOT RELY
      ON iQ4, ANY INFORMATION THEREIN, OR ITS CONTINUATION. WE PROVIDE THE iQ4 PLATFORM AND ALL
      INFORMATION AND SERVICES ON AN “AS IS” AND “AS AVAILABLE” BASIS. iQ4 DOES NOT CONTROL OR
      PROVIDE QUALITY CONTROL REVIEW OF USER GENERATED CONTENT FOR ACCURACY. WE DO NOT PROVIDE ANY
      EXPRESS WARRANTIES OR REPRESENTATIONS. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW,
      WE DISCLAIM ANY AND ALL IMPLIED WARRANTIES AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION,
      ANY AND ALL WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY
      OF DATA, AND NONINFRINGEMENT. IF YOU ARE DISSATISFIED OR HARMED BY iQ4 OR ANYTHING RELATED TO
      iQ4, YOU MAY TERMINATE YOUR iQ4 MEMBERSHIP AND TERMINATE THIS AGREEMENT IN ACCORDANCE WITH
      SECTION 7 (“TERMINATION”) AND SUCH TERMINATION SHALL BE YOUR SOLE AND EXCLUSIVE REMEDY. iQ4 IS
      NOT RESPONSIBLE, AND MAKES NO REPRESENTATIONS OR WARRANTIES FOR THE DELIVERY OF ANY MESSAGES
      (SUCH AS E-MAILS, INMAILS, POSTING OF INFORMATION OR CONTENT OR TRANSMISSION OF ANY OTHER USER
      GENERATED INFORMATION OR CONTENT) SENT THROUGH iQ4 TO ANYONE. IN ADDITION, WE NEITHER WARRANT
      NOR REPRESENT THAT YOUR USE OF THE SERVICE WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES. ANY
      MATERIAL, SERVICE, OR TECHNOLOGY DESCRIBED OR USED ON THE WEBSITE MAY BE SUBJECT TO
      INTELLECTUAL PROPERTY RIGHTS OWNED BY THIRD PARTIES WHO HAVE LICENSED SUCH MATERIAL, SERVICE,
      OR TECHNOLOGY TO US. iQ4 DOES NOT HAVE ANY OBLIGATION TO VERIFY THE IDENTITY OF THE PERSONS
      REGISTERING OR SUBSCRIBING TO ITS SERVICES, NOR DOES IT HAVE ANY OBLIGATION TO MONITOR THE USE
      OF ITS SERVICES BY OTHER USERS OF AN iQ4 PLATFORM; THEREFORE, iQ4 DISCLAIMS ALL LIABILITY FOR
      IDENTITY THEFT OR ANY OTHER MISUSE OF YOUR IDENTITY OR INFORMATION. iQ4 DOES NOT GUARANTEE
      THAT THE SERVICES IT PROVIDES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS IN FUNCTIONING. IN
      PARTICULAR, THE OPERATION OF THE iQ4 PLATFORM OR SERVICES MAY BE INTERRUPTED DUE TO
      MAINTENANCE, UPDATES, OR SYSTEM OR NETWORK FAILURES. iQ4 DISCLAIMS ALL LIABILITY FOR DAMAGES
      CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN FUNCTIONING. FURTHERMORE, iQ4 DISCLAIMS ALL
      LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE iQ4
      SITE DUE TO INAPPROPRIATE EQUIPMENT, DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO
      THE SATURATION OF THE INTERNET NETWORK, AND FOR ANY OTHER REASON.
      <strong>6. LIMITATION OF LIABILITY.</strong>
      <br />
      <br />
      SOME COUNTRIES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY IN CONTRACTS WITH
      CONSUMERS AND AS A RESULT THE CONTENTS OF THIS SECTION MAY NOT APPLY TO YOU. NEITHER IQ4 NOR
      ANY OF OUR SUBSIDIARIES, AFFILIATED COMPANIES, EMPLOYEES, SHAREHOLDERS, DIRECTORS, OR OFFICERS
      (“IQ4 AFFILIATES”) SHALL BE INDIVIDUALLY OR CUMULATIVELY LIABLE FOR (A) ANY DAMAGES IN EXCESS
      OF THE MOST RECENT FEE THAT YOU PAID FOR A SERVICE, IF ANY, OR US $100, WHICHEVER AMOUNT IS
      LESS, OR (B) ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, OR CONSEQUENTIAL DAMAGES OR LOSS OF
      USE, PROFIT, REVENUE, OR DATA TO YOU OR ANY THIRD PERSON ARISING FROM YOUR USE OF THE SERVICE,
      ANY PLATFORM APPLICATIONS OR ANY OF THE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR
      DOWNLOADED FROM IQ4. THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN BETWEEN
      THE PARTIES AND WITHOUT IT THE TERMS AND PRICES CHARGED WOULD BE DIFFERENT. THIS LIMITATION OF
      LIABILITY SHALL APPLY REGARDLESS OF WHETHER (1) YOU BASE YOUR CLAIM ON CONTRACT, TORT, STRICT
      LIABILITY, STATUTE, OR ANY OTHER LEGAL THEORY, (2) WE KNEW OR SHOULD HAVE KNOWN ABOUT THE
      POSSIBILITY OF SUCH DAMAGES, OR (3) THE LIMITED REMEDIES PROVIDED IN THIS SECTION FAIL IN
      THEIR ESSENTIAL PURPOSE.
      <strong>7. TERMINATION.</strong>
      <br />
      <br />
      A. Mutual rights of termination. You may terminate this Agreement, for any or no reason, at
      any time, with notice to iQ4 pursuant to Section 9(C). This notice will be effective upon iQ4
      processing your notice. iQ4 may terminate the Agreement and your membership for any reason or
      no reason, at any time, with or without notice. This cancellation shall be effective
      immediately or as may be specified in the notice. For avoidance of doubt, only iQ4 or the
      party paying for the Services may terminate your access to any Services. Termination of your
      iQ4 membership includes disabling your access to iQ4 and may also bar you from any future use
      of iQ4.
      <br />
      B. Misuse of the Services. iQ4 may restrict, suspend or terminate the membership of any User
      who abuses or misuses the Services. Misuse of the Services includes: inviting other Users with
      whom you do not know to connect, abusing the iQ4 messaging services, creating multiple or
      false profiles, using the Services commercially without iQ4’s authorization, infringing any
      intellectual property rights, violating any of the covenants in this Agreement, or any other
      behavior that iQ4, in its sole discretion, deems contrary to its purpose. In addition, and
      without limiting the foregoing, iQ4 has adopted a policy of terminating memberships of Users
      who, in iQ4’s sole discretion, are deemed to be repeat infringers under the United States
      Copyright Act, Lanham Act, and other federal or state intellectual property laws.
      <br />
      C. Effect of Termination. Upon the termination of your iQ4 membership, you lose access to the
      Services and all iQ4 Platforms. The terms of this Agreement shall survive any termination,
      except Sections 3 (“Your Rights”) and 4(A), 4(B) and 4(D) (“Our Rights and Obligations”)
      hereof.
      <br />
      <br />
      <strong>8. DISPUTE RESOLUTION.</strong>
      <br />
      <br />
      This Agreement or any claim, cause of action or dispute (“claim”) arising out of or related to
      this Agreement shall be governed by the laws of the State of New Jersey regardless of your
      state or country of origin or where you access iQ4, and notwithstanding of any conflicts of
      law principles and the United
      <br />
      <br />
      Nations Convention for the International Sale of Goods. You and iQ4 agree that all claims
      arising out of or related to this Agreement must be resolved exclusively by a state or federal
      court located in Bergen County, New Jersey. You and iQ4 agree to submit to the personal
      jurisdiction of the courts located within Bergen County, New Jersey for the purpose of
      litigating all such claims. Notwithstanding the above, you agree that iQ4 shall be allowed to
      apply for injunctive remedies (or an equivalent type of urgent legal relief) in any
      jurisdiction.
      <br />
      <br />
      <strong>9. GENERAL TERMS.</strong>
      <br />
      <br />
      A. Severability. If any provision of this Agreement is found by a court of competent
      jurisdiction to be illegal, void, or unenforceable, the unenforceable provision will be
      modified so as to render it enforceable and effective to the maximum extent possible in order
      to effect the intention of the provision; and if a court finds the modified provision invalid,
      illegal, void, or unenforceable, the validity, legality, and enforceability of the remaining
      provisions of this Agreement will not be affected in any way.
      <br />
      B. Language. Where iQ4 has provided you with a translation of the English language version of
      this Agreement, the Privacy Policy, and/or any other documentation, you agree that the
      translation is provided for your convenience only and that the English language versions of
      this Agreement, the Privacy Policy, and any other documentation, will govern your relationship
      with iQ4.
      <br />
      C. Notices and Service of Process. In addition to Section 2(h) (“Notices and Service
      Messages”), we may notify you via postings on www.iq4.com. You may contact us at frank@iq4.com
      <br />
      D. or via mail or courier at: iQ4 Corporation c/o Dilworth Paxson LLP Attn: John A. Squires 99
      Park Avenue | Suite 320 | New York, NY, 10016
      <br />
      <br />
      Additionally, iQ4 accepts service of process at this address. Any notices that you provide
      without compliance with this section on Notices shall have no legal effect.
      <br />
      <br />
      E. Entire Agreement. You agree that this Agreement constitutes the entire, complete and
      exclusive agreement between you and us regarding the Services and supersedes all prior
      agreements and understandings, whether written or oral, or whether established by custom,
      practice, policy or precedent, with respect to the subject matter of this Agreement. You or
      your employer (including, without limitation, a business or governmental entity) and/or an iQ4
      Platform manager also may be subject to additional terms and conditions that may apply when
      using certain iQ4 Services, third-party content, or third party software. In the event of a
      conflict between this Agreement and such additional terms and conditions, the terms of such
      additional terms and conditions shall control.
      <br />
      <br />
      F. Amendments to this Agreement. We reserve the right to modify, supplement, or replace the
      terms of the Agreement, effective upon posting at www.iQ4.com or notifying you otherwise. For
      example, iQ4 presents a banner on the site when we have amended this Agreement or the Privacy
      Policy so that you may access and review the changes prior to your continued use of the site.
      If you do not want to agree to changes to the Agreement, you can terminate this Agreement at
      any time per Section 7 (Termination).
      <br />
      <br />
      G. No informal waivers, agreements, or representations. Our failure to act with respect to a
      breach of this Agreement by you or others does not waive our right to act with respect to that
      breach or subsequent similar or other breaches. Except as expressly and specifically
      contemplated by the Agreement, no representations, statements, consents, waivers, or other
      acts or omissions by any iQ4 Affiliate shall be deemed legally binding on any iQ4 Affiliate,
      unless documented in a physical writing and signed by a duly appointed officer of iQ4.
      <br />
      <br />
      H. No Injunctive Relief. In no event shall you seek or be entitled to rescission, injunctive,
      or other equitable relief, or to enjoin or restrain the operation of the Service, exploitation
      of any advertising or other materials issued in connection therewith, or exploitation of the
      Services or any content or other material used or displayed through the Services.
      <br />
      <br />
      I. Assignment and Delegation. You may not assign or delegate any rights or obligations under
      the Agreement. Any purported assignment and delegation shall be ineffective. We may freely
      assign or delegate all rights and obligations under the Agreement, fully or partially without
      notice to you. We may also substitute, by way of unilateral novation, effective upon notice to
      you, iQ4 Corporation for any third party that assumes our rights and obligations under this
      Agreement.
      <br />
      <br />
      J. Potential Other Rights and Obligations. You may have rights or obligations under local law
      other than those enumerated here if you are located outside the United States.
      <strong>10. iQ4 TRADEMARKS.</strong>
      <br />
      <br />
      The trademarks, logos and service marks (“Marks”) displayed on the iQ4 website and iQ4
      Platform are the property of iQ4 Corporation or other third parties. Users are not permitted
      to use these Marks without the prior written consent of iQ4 or such third party which may own
      the Mark. “iQ4”, the “iQ4” logo, “CONNECTING STUDENTS WITH REAL WORLD PROBLEMS” and
      “COLLABORATORY EDU” are each trademarks/servicemarks of iQ4 Corporation.
      <strong>11. COMPLAINTS REGARDING CONTENT POSTED ON THE iQ4 WEBSITE.</strong>
      <br />
      <br />
      We respect the intellectual property rights of others. Accordingly, this Agreement requires
      that information posted by Users be accurate and not in violation of the intellectual property
      rights or other rights of third parties. To promote these objectives, iQ4 provides a process
      for submission of complaints concerning content and materials posted by our Users.
      <br />
      A. Copyright Policy/DMCA. You may not post, modify, distribute, or reproduce in any way
      copyrighted or other proprietary materials without obtaining the prior written consent of the
      copyright owner of such materials. We may terminate a membership, deny access to a site or
      service, or terminate any user who is alleged to have infringed the copyright or proprietary
      rights of another. Without limiting the foregoing, if you believe that your copyright or other
      rights have been infringed, please provide our Designated Agent (as defined below) written
      notice with the following information:
      <br />
      - An electronic or physical signature of the person authorized to act on behalf of the owner
      of the copyright or other interest;
      <br />
      - A description of the copyrighted work or other work that you claim has been infringed;
      <br />
      - A description of where the material that you claim is infringing is located on our sites,
      networks or other repositories;
      <br />
      - Your address, telephone number, and email address;
      <br />
      - A written statement by you that you have a good faith belief that the disputed use is not
      authorized by the copyright owner, its agent, or the law; and
      <br />
      - A statement by you, made under penalty of perjury, that the above information in your notice
      is accurate and that you are the copyright owner or authorized to act on the copyright owner’s
      behalf .
      <br />
      <br />
      If the disputed materials were posted by a third party identifiable through reasonable
      efforts, we will provide reasonable notice to the third party of the charge. If the third
      party responds with a valid counter-notification pursuant to Sections 512(g)(2) and (3) of the
      Digital Millennium Copyright Act, we will provide you with a copy so that you may take any
      other steps you may consider appropriate. A counter-notification shall be a written
      communication which contains:
      <br />
      1. Your physical or electronic signature;
      <br />
      2. Identification of the material removed or to which access has been disabled;
      <br />
      3. A statement under penalty of perjury that you have a good faith belief that removal or
      disablement of the material was a mistake or that the material was misidentified; and
      <br />
      4. Your name and telephone number, and a statement that you consent to the jurisdiction of the
      Federal District court in the judicial district where your address is located if the address
      is in the United States, and that you will accept service of process from the complainant
      submitting the notice or his/her authorized agent. Our Designated Agent for notice of claims
      of copyright infringement and counter-notifications can be reached as follows:
      <br />
      <br />
      John A. Squires
      <br />
      Attorney at Law
      <br />
      iQ4 Corporation c/o Dilworth Paxson LLP
      <br />
      Attn: John A. Squires
      <br />
      99 Park Avenue | Suite 320 | New York, NY 10016
      <br />
      | Fax: (215) 575-7200
      <br />
      jsquires@dilworthlaw.com
      <br />
      <br />
      B. Claims Regarding Content And Intellectual Property Violations (Other Than Copyright
      Infringement). To notify us that you in good faith believe that content posted by a User on
      our website infringes your intellectual property rights (other than copyright—in which case
      please see Copyright Policy/DMCA) or is inaccurate or unlawful, you may provide our Designated
      Agent (as defined above) a written notice with the following information:
      <br />
      - An electronic or physical signature of the person authorized to act on behalf of the owner
      of the copyright or other interest;
      <br />
      - A description of the (i) trademark or other intellectual property right that you claim has
      been infringed, or (ii) right which has been violated;
      <br />
      - A description of where the material that you claim is infringing is located on our sites,
      networks or other repositories;
      <br />
      - Your address, telephone number, and email address;
      <br />
      - A written statement by you that you have a good faith belief that the disputed use is not
      authorized by the copyright owner, its agent, or the law; and
      <br />
      - A statement by you, made under penalty of perjury, that the above information in your notice
      is accurate and that you are the copyright owner or authorized to act on the copyright owner’s
      behalf. If you believe such notice was submitted in error, or if you contest the removal of
      the allegedly infringing, inaccurate or unlawful content, you may complete a written
      counter-notification, and submit it to the Designated Agent, containing the following
      information:
      <br />
      1. Your physical or electronic signature;
      <br />
      2. Identification of the material removed or to which access has been disabled;
      <br />
      3. A statement under penalty of perjury that you have a good faith belief that removal or
      disablement of the material was a mistake or that the material was misidentified; and
      <br />
      4. Your name and telephone number, and a statement that you consent to the jurisdiction of the
      Federal District court in the judicial district where your address is located if the address
      is in the United States, and that you will accept service of process from the complainant
      submitting the notice or his/her authorized agent. Any assertions made by you in submitting
      this form are under penalty of perjury. iQ4 will process the counter notification in
      accordance with its policies and procedures which remain in our sole discretion.
    </div>
  </div>
);
