import React from 'react';
import { ReactComponent as Add } from './svg/add.svg';
import { ReactComponent as Alert } from './svg/alert.svg';
import { ReactComponent as Arrow } from './svg/arrow.svg';
import { ReactComponent as Audio } from './svg/audio.svg';
import { ReactComponent as Bin } from './svg/bin.svg';
import { ReactComponent as Camera } from './svg/camera.svg';
import { ReactComponent as CaretDown } from './svg/caret-down.svg';
import { ReactComponent as CaretLeft } from './svg/caret-left.svg';
import { ReactComponent as CaretRight } from './svg/caret-right.svg';
import { ReactComponent as CaretUp } from './svg/caret-up.svg';
import { ReactComponent as CertifiedAssessments } from './svg/certified-assessments.svg';
import { ReactComponent as Close } from './svg/close.svg';
import { ReactComponent as CloseX } from './svg/close-x.svg';
import { ReactComponent as Credential } from './svg/credential.svg';
import { ReactComponent as CredentialCircle } from './svg/credential-circle.svg';
import { ReactComponent as DisclosureDocument } from './svg/disclosure-document.svg';
import { ReactComponent as Document } from './svg/document.svg';
import { ReactComponent as Drag } from './svg/drag.svg';
import { ReactComponent as DragSort } from './svg/drag-sort.svg';
import { ReactComponent as Edit } from './svg/edit.svg';
import { ReactComponent as Education } from './svg/education.svg';
import { ReactComponent as EducationCircle } from './svg/education-circle.svg';
import { ReactComponent as Error } from './svg/error.svg';
import { ReactComponent as Exclamation } from './svg/icon-error.svg';
import { ReactComponent as Experience } from './svg/experience.svg';
import { ReactComponent as ExperienceCircle } from './svg/experience-circle.svg';
import { ReactComponent as ExperienceCirclePathways } from './svg/experience-circle-pathways.svg';
import { ReactComponent as Filter } from './svg/filter.svg';
import { ReactComponent as GhostNode } from './svg/ghost-node.svg';
import { ReactComponent as Hide } from './svg/hide.svg';
import { ReactComponent as Info } from './svg/info.svg';
import { ReactComponent as InfoDefault } from './svg/info-default.svg';
import { ReactComponent as Link } from './svg/link.svg';
import { ReactComponent as LinkedIn } from './svg/linkedin-icon.svg';
import { ReactComponent as Menu } from './svg/menu.svg';
import { ReactComponent as Notifications } from './svg/notifications.svg';
import { ReactComponent as OpenLink } from './svg/open-link.svg';
import { ReactComponent as Opportunity } from './svg/opportunity.svg';
import { ReactComponent as Options } from './svg/options.svg';
import { ReactComponent as PathwaysGraph } from './svg/pathways-graph.svg';
import { ReactComponent as PathwaysGraphCircle } from './svg/pathway-icon.svg';
import { ReactComponent as PeerAssessments } from './svg/peer-assessments.svg';
import { ReactComponent as Permissions } from './svg/permissions.svg';
import { ReactComponent as Person } from './svg/personal.svg';
import { ReactComponent as PersonCircle } from './svg/personal-circle.svg';
import { ReactComponent as Play } from './svg/play.svg';
import { ReactComponent as Private } from './svg/private.svg';
import { ReactComponent as Public } from './svg/public.svg';
import { ReactComponent as Portfolio } from './svg/portfolio.svg';
import { ReactComponent as PortfolioCircle } from './svg/portfolio-circle.svg';
import { ReactComponent as RoleIcon } from './svg/role-icon.svg';
import { ReactComponent as RoleGhostIcon } from './svg/role-ghost-icon.svg';
import { ReactComponent as Search } from './svg/search.svg';
import { ReactComponent as Share } from './svg/share.svg';
import { ReactComponent as Skills } from './svg/skills.svg';
import { ReactComponent as SkillsCircle } from './svg/skills-circle.svg';
import { ReactComponent as Sort } from './svg/sort.svg';
import { ReactComponent as SortAscending } from './svg/sort-ascending.svg';
import { ReactComponent as SortDescending } from './svg/sort-descending.svg';
import { ReactComponent as Source } from './svg/source.svg';
import { ReactComponent as Star } from './svg/star-fill.svg';
import { ReactComponent as Student } from './svg/icon_student.svg';
import { ReactComponent as StarOutline } from './svg/star-outline.svg';
import { ReactComponent as Success } from './svg/success.svg';
import { ReactComponent as TableSortUp } from './svg/table_sort_up.svg';
import { ReactComponent as TableSortDown } from './svg/table_sort_down.svg';
import { ReactComponent as TestedAssessments } from './svg/tested-assessments.svg';
import { ReactComponent as Tick } from './svg/tick.svg';
import { ReactComponent as Tip } from './svg/tip.svg';
import { ReactComponent as ToggleOff } from './svg/toggle-off.svg';
import { ReactComponent as ToggleOn } from './svg/toggle-on.svg';
import { ReactComponent as Tools } from './svg/tools.svg';
import { ReactComponent as Tooltip } from './svg/tooltip.svg';
import { ReactComponent as TooltipCircle } from './svg/tooltip-circle.svg';
import { ReactComponent as Tour } from './svg/tour.svg';
import { ReactComponent as Video } from './svg/video.svg';
import { ReactComponent as Warning } from './svg/warning.svg';
import { ReactComponent as Warning2 } from './svg/icon_warning.svg';
import { ReactComponent as Warning3 } from './svg/warning3.svg';
import { ReactComponent as Man } from './svg/man.svg';
import { ReactComponent as Woman } from './svg/woman.svg';
import { ReactComponent as HexagonAvatar } from './svg/avatar- empty state.svg';
import { ReactComponent as Stripes } from './svg/stripes.svg';
import { ReactComponent as Print } from './svg/print.svg';
import { ReactComponent as FileExport } from './svg/file-export.svg';
import { ReactComponent as SortCaretUp } from './svg/sort-caret-up.svg';
import { ReactComponent as SortCaretDown } from './svg/sort-caret-down.svg';
import { ReactComponent as LongArrow } from './svg/long-arrow.svg';
import { ReactComponent as Image } from './svg/image.svg';
import { ReactComponent as OnboardingArrow } from './svg/onboarding_arrow.svg';
import { ReactComponent as Verified } from './svg/verified.svg';
import { ReactComponent as Verified2 } from './svg/verified2.svg';
import { ReactComponent as VisibilityOn } from './svg/visibility-on.svg';
import { ReactComponent as VisibilityOff } from './svg/visibility-off.svg';

import './SimpleIcon.scss';

const iconTypes = {
  alert: Alert,
  add: Add,
  arrow: Arrow,
  audio: Audio,
  bin: Bin,
  camera: Camera,
  caretDown: CaretDown,
  caretLeft: CaretLeft,
  caretRight: CaretRight,
  caretUp: CaretUp,
  certifiedAssessments: CertifiedAssessments,
  credential: Credential,
  credentialCircle: CredentialCircle,
  credentialsCircle: CredentialCircle,
  close: Close,
  closeX: CloseX,
  disclosureDocument: DisclosureDocument,
  document: Document,
  drag: Drag,
  dragSort: DragSort,
  edit: Edit,
  education: Education,
  educationCircle: EducationCircle,
  error: Error,
  exclamation: Exclamation,
  experience: Experience,
  experienceCircle: ExperienceCircle,
  experienceCirclePathways: ExperienceCirclePathways,
  fileExport: FileExport,
  filter: Filter,
  ghostNode: GhostNode,
  hexagonAvatar: HexagonAvatar,
  hide: Hide,
  info: Info,
  infoDefault: InfoDefault,
  man: Man,
  menu: Menu,
  notifications: Notifications,
  link: Link,
  linkedIn: LinkedIn,
  openLink: OpenLink,
  opportunity: Opportunity,
  options: Options,
  other: Link,
  peerAssessments: PeerAssessments,
  pathwaysGraph: PathwaysGraph,
  pathwaysGraphCircle: PathwaysGraphCircle,
  permissions: Permissions,
  person: Person,
  personCircle: PersonCircle,
  play: Play,
  portfolio: Portfolio,
  portfolioCircle: PortfolioCircle,
  print: Print,
  private: Private,
  public: Public,
  roleIcon: RoleIcon,
  roleGhostIcon: RoleGhostIcon,
  search: Search,
  share: Share,
  skills: Skills,
  skillsCircle: SkillsCircle,
  sort: Sort,
  sortAscending: SortAscending,
  sortCaretUp: SortCaretUp,
  sortCaretDown: SortCaretDown,
  sortDescending: SortDescending,
  source: Source,
  star: Star,
  starOutline: StarOutline,
  student: Student,
  success: Success,
  tableSortUp: TableSortUp,
  tableSortDown: TableSortDown,
  testedAssessments: TestedAssessments,
  tick: Tick,
  tip: Tip,
  toggleOff: ToggleOff,
  toggleOn: ToggleOn,
  tools: Tools,
  tooltip: Tooltip,
  tooltipCircle: TooltipCircle,
  tour: Tour,
  video: Video,
  warning: Warning,
  warning2: Warning2,
  warning3: Warning3,
  woman: Woman,
  stripes: Stripes,
  longArrow: LongArrow,
  image: Image,
  onboardingArrow: OnboardingArrow,
  verified: Verified,
  verified2: Verified2,
  visibilityOn: VisibilityOn,
  visibilityOff: VisibilityOff,
};

export const SimpleIcon = ({ name, hidden = true, className = '', ...props }) => {
  const Icon = iconTypes[name];

  if (!Icon) {
    return null;
  }

  const ariaHidden = hidden === true ? 'true' : 'false';

  return (
    <Icon
      className={`iq4-simple-icon ${className}`}
      role="presentation"
      aria-hidden={ariaHidden}
      {...props}
    />
  );
};
