import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { InnerTabs, ShareWalletEmployerModal, SimpleIcon, Topbar } from 'common/components';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { selectFeatures } from 'store/features/config/selectors';
import {
  selectHistoryInvites,
  selectIsTriggeringDecision,
  selectNewInvites,
} from 'common/store/features/connections/selectors';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { updateInviteStudentStatus } from 'common/store/features/connections/connectionsSlice';
import { selectSessionUser } from 'common/store/features/session/selectors';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { ReactComponent as ThumbsUp } from 'common/assets/svg/thumbs-up.svg';
import { ConnectionRow } from './ConnectionRow';
import { HistoryRow } from './HistoryRow';
import './ConnectionsPage.scss';
import { MobileRow } from './MobileRow';

export const ConnectionsPage = () => {
  const dispatch = useDispatch();
  const { applicationTitle, personalProfileSource } = useSelector(selectFeatures);
  const userProfile = useSelector(selectSessionUser);
  const historyData = useSelector(selectHistoryInvites);
  const connectionsData = useSelector(selectNewInvites);
  const isTriggeringDecision = useSelector(selectIsTriggeringDecision);

  const [selectedTab, setSelectedTab] = useState('new');
  const [orderedConnectionsData, setOrderedConnectionsData] = useState(connectionsData);
  const [orderedHistoryData, setOrderedHistoryData] = useState(historyData);
  const [showEmployerSharingSettings, setShowEmployerSharingSettings] = useState(false);
  const [activeCounsellorId, setActiveCounsellorId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const isMobile = useIsMobile((breakpoint) => breakpoint === 'sm' || breakpoint === 'xs');

  useEffect(() => {
    setOrderedConnectionsData(connectionsData);
  }, [connectionsData]);

  useEffect(() => {
    setOrderedHistoryData(historyData);
  }, [historyData]);

  useEffect(() => {
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
  }, [dispatch, personalProfileSource]);

  const onAccept = (item) => {
    dispatch(
      updateInviteStudentStatus({
        type: 'student',
        status: 2,
        userId: item?.counsellorId,
      }),
    );
  };

  const onDecline = (item) => {
    dispatch(
      updateInviteStudentStatus({
        type: 'student',
        status: 3,
        userId: item?.counsellorId,
      }),
    );
  };

  const onRevoke = async (item) => {
    await dispatch(
      updateInviteStudentStatus({
        type: 'student',
        status: 4,
        userId: item?.counsellorId,
      }),
    );

    setShowAlert(true);
    setAlertMessage('Connection revoked successfully');

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const tabContent = [
    {
      title: 'New',
      content: (
        <>
          {!orderedConnectionsData?.length && <ConnectionsEmptyState />}
          {!!orderedConnectionsData?.length &&
            orderedConnectionsData.map((x) => (
              <MobileRow item={x} onAccept={onAccept} onDecline={onDecline} />
            ))}
        </>
      ),
    },
    {
      title: 'History',
      disabled: orderedHistoryData?.length === 0,
      content: (
        <>
          {orderedHistoryData.map((x) => (
            <MobileRow item={x} onRevoke={onRevoke} />
          ))}
        </>
      ),
    },
  ];

  function ConnectionsEmptyState() {
    return (
      <div className="iq4-connections-page__empty-container">
        <ThumbsUp role="presentation" aria-hidden="true" />
        <div className="iq4-connections-page__empty-container--label">
          All your connections are up to date
        </div>
        {!!orderedHistoryData?.length && (
          <div className="iq4-connections-page__empty-container--label">
            To view all previous activity, select the{' '}
            <span
              tabIndex={0}
              role="button"
              onKeyPress={() => setSelectedTab('history')}
              onClick={() => setSelectedTab('history')}
              className="iq4-connections-page__empty-container--link"
            >
              History Tab
            </span>
          </div>
        )}
      </div>
    );
  }

  function onManage({ counsellorId }) {
    setActiveCounsellorId(counsellorId);
    setShowEmployerSharingSettings(true);
  }

  function onEmployerModalClose() {
    setShowEmployerSharingSettings(false);
    setActiveCounsellorId(null);
  }

  function onEmployerModalShare() {
    setShowEmployerSharingSettings(false);
    setShowAlert(true);
    setAlertMessage('Your settings have been saved');
    setActiveCounsellorId(null);

    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }

  const renderTabContent = () => {
    if (selectedTab === 'new') {
      if (!orderedConnectionsData?.length) {
        return (
          <div id="new-tabpanel" role="tabpanel" aria-labelledby="new-tab">
            <ConnectionsEmptyState />
          </div>
        );
      }

      return (
        <div id="new-tabpanel" role="tabpanel" aria-labelledby="new-tab">
          <div className="iq4-connections-page--pre-expand">
            <div className="iq4-connections-page--pre-expand--flex-3">Name</div>
            <div className="iq4-connections-page--pre-expand--flex-2 iq4-connection-row--margin-left">
              <div>Date Received</div>
            </div>
            <div className="iq4-connections-page--pre-expand--flex-2">
              <div>Status</div>
            </div>
            <div className="iq4-connections-page--pre-expand--flex-2">
              <div>Organization</div>
            </div>
            <div className="iq4-connections-page--pre-expand--flex-3-center">Action</div>
          </div>
          {orderedConnectionsData.map((x) => (
            <ConnectionRow
              item={x}
              onAccept={() => onAccept(x)}
              onDecline={() => onDecline(x)}
              isLoading={isTriggeringDecision}
            />
          ))}
        </div>
      );
    }

    return (
      <div id="history-tabpanel" role="tabpanel" aria-labelledby="history-tab">
        {showEmployerSharingSettings && (
          <ShareWalletEmployerModal
            isOpen={true}
            onClose={onEmployerModalClose}
            onShare={onEmployerModalShare}
            counsellorId={activeCounsellorId}
            counsellorFullName={
              orderedHistoryData.find((x) => x.counsellorId === activeCounsellorId)
                ?.counsellorFullName
            }
          />
        )}
        <div className="iq4-connections-page--pre-expand">
          <div className="iq4-connections-page--pre-expand--flex-2-no-center">
            <div>Name</div>
          </div>
          <div className="iq4-connections-page--pre-expand--flex-1">
            <div>Date</div>
          </div>
          <div className="iq4-connections-page--pre-expand--flex-1">
            <div>Organization</div>
          </div>
          <div className="iq4-connections-page--pre-expand--flex-1">
            <div>Status</div>
          </div>
          <div className="iq4-connections-page--pre-expand--flex-1">
            <div />
          </div>
        </div>
        {orderedHistoryData.map((x) => (
          <HistoryRow item={x} onRevoke={onRevoke} onManage={onManage} />
        ))}
      </div>
    );
  };

  return (
    <>
      <Helmet title={`${applicationTitle} - Connections`} />
      <Topbar Logo={Logo} />
      <div className="iq4-connections-page">
        <div
          className={`iq4-credentials__alert ${showAlert ? 'iq4-credentials__alert--visible' : ''}`}
        >
          <SimpleIcon className="iq4-credentials__alert--close" name="close" />
          {alertMessage}
        </div>
        <div className="iq4-max-width-container">
          <h1 className="iq4-manage-skills__title">Connections</h1>
          {!isMobile && (
            <div className="iq4-connections-page--container">
              <div role="tablist" className="iq4-connections-page--inner-tabs">
                <div
                  className={
                    selectedTab === 'new' ? 'iq4-connections-page--inner-tabs--selected' : ''
                  }
                  onClick={() => setSelectedTab('new')}
                  onKeyPress={() => setSelectedTab('new')}
                  id="new-tab"
                  role="tab"
                  aria-selected={selectedTab === 'new'}
                  aria-controls="new-tabpanel"
                  tabIndex={0}
                >
                  Pending
                </div>
                <div
                  className={
                    selectedTab === 'history'
                      ? 'iq4-connections-page--inner-tabs--selected'
                      : !orderedHistoryData?.length
                      ? 'iq4-connections-page--inner-tabs--disabled'
                      : ''
                  }
                  onClick={() => (!orderedHistoryData?.length ? {} : setSelectedTab('history'))}
                  onKeyPress={() => (!orderedHistoryData?.length ? {} : setSelectedTab('history'))}
                  id="history-tab"
                  role="tab"
                  aria-selected={selectedTab === 'history'}
                  aria-controls="history-tabpanel"
                  tabIndex={0}
                >
                  History
                </div>
              </div>
              {renderTabContent()}
            </div>
          )}
          {isMobile && (
            <div className="iq4-connections-page--container">
              <div className="iq4-connections-page--upper">
                This is a Connections page for your Profile. Here you can share your profile with
                counselors and, soon, you'll be able to accept credentials from Colleges and
                organizations
              </div>
              <InnerTabs
                id="connections"
                data={tabContent}
                activeTab={selectedTab === 'new' ? 0 : 1}
                onChangeTab={(newValue) => setSelectedTab(newValue === 0 ? 'new' : 'history')}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
