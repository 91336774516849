import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RESUME_PARSER_URL } from 'common/constants/endpoints';

export const parseResumeReq = createAsyncThunk(
  '/skills/parseResumeReq',
  async (attachment, { getState }) => {
    const res = await fetch(RESUME_PARSER_URL, {
      method: 'POST',
      body: attachment,
    });

    const data = await res.json();

    const skills = data.skills;

    const state = getState();

    // filter out any skills already added
    const filteredSkills = skills.filter((skillFromParser) => {
      return !state.skills.skills.find(
        (skill) => skillFromParser.competenceId === skill.competenceId,
      );
    });

    return {
      ...data,
      skills: filteredSkills.map((skill) => ({
        ...skill,
        assessment: null,
        checked: true,
      })),
    };
  },
);

export const parsedResumeSlice = createSlice({
  name: 'parsedResume',
  initialState: {
    data: null,
  },
  reducers: {
    removeEducation: (state, action) => {
      state.data = {
        ...state.data,
        education: {
          ...state.data.education,
          EducationDetails: state.data.education.EducationDetails.filter(
            (item) => item.Id !== action.payload.reference,
          ),
        },
      };
    },
    removeExperience: (state, action) => {
      state.data = {
        ...state.data,
        employmentHistory: {
          ...state.data.employmentHistory,
          Positions: state.data.employmentHistory.Positions.filter(
            (item) => item.Id !== action.payload.reference,
          ),
        },
      };
    },

    updateAssessedResumeParserSkills: (state, action) => {
      const index = state.data.skills.findIndex(
        (skill) => skill.competenceId === action.payload.skill.id,
      );
      if (index > -1) {
        state.data.skills[index].assessment = action.payload.assessment;
      }
    },

    updateSelectedResumeParserSkills: (state, action) => {
      console.log('state.resumeParser: ', state.resumeParser);
      const index = state.data.skills.findIndex(
        (skill) => skill.competenceId === action.payload.skill.id,
      );

      if (index > -1) {
        state.data.skills[index].checked = action.payload.checked;
      }
    },
  },
  extraReducers: {
    [parseResumeReq.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

// action creators
export const {
  removeEducation,
  removeExperience,
  updateSelectedResumeParserSkills,
  updateAssessedResumeParserSkills,
} = parsedResumeSlice.actions;

export default parsedResumeSlice.reducer;
