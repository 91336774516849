import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { addSkillReq } from 'common/store/features/skills/skillsSlice';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { ProfileResumeParse } from 'components/Profile';
import {
  selectSkillsWithLearningResourceByState,
  selectSkillsBreakUp,
  selectSelectedSkills,
  selectMySkills,
  selectHasFetchedAllSkills,
} from 'common/store/features/skills/selectors';
import {
  AtAGlance,
  ConsentBanner,
  Container,
  Topbar,
  Top10,
  SkillsSearchBar,
  ManageSkillsCard,
  RecommendedSkillsPanel,
  ResumeParserModal,
  Button,
  Footer,
  InnerTabs,
  Expand,
  SimpleIcon,
} from 'common/components';
import { selectConsentForPlanning } from 'common/store/features/records/selectors';
import { selectSelectedPathwayRoles } from 'common/store/features/pathways/selectors';
import { fetchTShapeGraphicData, setSelectedSkillInfo } from 'common/store/features/t/slice';
import { selectTShapeData } from 'common/store/features/t/selectors';
import {
  fetchWalletProfile,
  updateWalletProfileConsentReq,
} from 'common/store/features/records/recordsSlice';
import {
  fetchCredentialsReq,
  fetchCredentialsStatusReq,
} from 'common/store/features/credentials/credentialsSlice';
import {
  fetchFrameworkReq,
  fetchUserSelectedWorkRolesReq,
} from 'common/store/features/pathways/pathwaysSlice';
import { fetchAllSkillsReq } from 'common/store/features/skills/skillsSlice';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { selectFeatures } from 'store/features/config/selectors';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { TShapeV2 } from 'common/components/TShapeV2';
import { Dropdown } from 'common/components/Dropdown';
import { TShapePage } from 'pages/TShapePage';
import { selectHasFetchedProfile, selectProfile } from 'common/store/features/profile/selectors';
import './ManageSkillsPage.scss';
import { getMySkillsTShapeData } from 'common/utils';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import {
  selectCredentialsList,
  selectHasFetchedCredentials,
  selectIsFetchingCredentials,
} from 'common/store/features/credentials/selectors';
import { useLocation } from 'react-router-dom';
import { AboutSkillsHelp } from './AboutSkillsHelp';
import Joyride from 'react-joyride';
import { selectDisplayTour } from '../../common/store/features/ui/selectors';
import {
  setDisplayTour,
  setPathwayDetailTour,
  setSkillExpandedId,
} from '../../common/store/features/ui/uiSlice';

/**
 * Main Wrapper for the Manage Skills page
 * @returns {*}
 * @constructor
 */
export const ManageSkillsPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState('');
  const [showResumeParser, setShowResumeParser] = useState(false);
  const [activeResumeParserModal, setActiveResumeParserModal] = useState(false);
  const [selectedTShapeRole, setSelectedTShapeRole] = useState(null);
  const [activeTShapeView, setActiveTShapeView] = useState(false);
  const [showAboutSkillsHelp, setShowAboutSkillsHelp] = useState(false);
  const [triggerFocus, setTriggerFocus] = useState(false);
  const [showDummySkills, setShowDummySkill] = useState(false);
  const [showSecondTour, setShowSecondTour] = useState(false);
  const [currentSecondTourStepIndex, setCurrentSecondTourStepIndex] = useState(0);

  const selectedWorkRoles = useSelector(selectSelectedPathwayRoles);
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const credentialsList = useSelector(selectCredentialsList);
  const hasFetchedCredentials = useSelector(selectHasFetchedCredentials);
  const isFetchingCredentials = useSelector(selectIsFetchingCredentials);
  const hasFetchedAllSkills = useSelector(selectHasFetchedAllSkills);
  const hasFetchedProfile = useSelector(selectHasFetchedProfile);
  const showTour = useSelector(selectDisplayTour);

  const [isConsentDisplayed, setIsConsentDisplayed] = useState(false);
  const [isShowSkillsExplanation, setShowSkillsExplanation] = useState(false);

  const skillResults = useSkillsSearch(searchTerm);
  const tShapeGraphicData = useSelector(selectTShapeData);
  const skillsBreakup = useSelector(selectSkillsBreakUp);
  const mySkills = useSelector(selectMySkills);
  const {
    applicationTitle,
    personalProfileSource,
    resumeParserEnabled,
    selfAttestedEnabled,
    displayTop10Skills,
    consentForPlanningEnabled,
  } = useSelector(selectFeatures);

  const { personal } = useSelector(selectProfile);

  const { skills } = useSelector((state) =>
    selectSkillsWithLearningResourceByState(state, personal?.school),
  );

  const hasLoaded = !isBootstrapping;
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const tabContent = [
    {
      title: 'Skills',
      content: skills && <ManageSkillsCard skills={skills} />,
    },
    ...(selfAttestedEnabled
      ? [
          {
            title: 'Recommended',
            content: <RecommendedSkillsPanel addedSkills={skills.length} />,
          },
        ]
      : []),
  ];

  const consentForPlanning = useSelector(selectConsentForPlanning);

  // set selected tshape role if available
  useEffect(() => {
    if (selectedWorkRoles.length && !selectedTShapeRole) {
      const selected = selectedWorkRoles.find((role) => role.id !== -1);
      setSelectedTShapeRole(selected);
    }
  }, [selectedWorkRoles, selectedTShapeRole]);

  // get tshape data for the selected role when the role changes
  useEffect(() => {
    if (selectedTShapeRole) {
      dispatch(fetchTShapeGraphicData({ roleId: selectedTShapeRole.id }));
    }
  }, [selectedTShapeRole]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!activeTShapeView && triggerFocus) {
      const button = document.querySelector('.iq4-manage-skills__tshape-btn');
      if (button) {
        button.focus();
      }
    } else if (activeTShapeView) {
      setTriggerFocus(true);
    }
  }, [activeTShapeView]);

  useEffect(() => {
    dispatch(fetchFrameworkReq());
    dispatch(fetchUserSelectedWorkRolesReq());
  }, []);

  useEffect(() => {
    if (!credentialsList?.length && !hasFetchedCredentials && !isFetchingCredentials) {
      dispatch(fetchCredentialsReq());
      dispatch(fetchCredentialsStatusReq());
    }
  }, [credentialsList]);

  useEffect(() => {
    consentForPlanningEnabled && dispatch(fetchWalletProfile());
  }, [consentForPlanningEnabled]);

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    if (!hasFetchedAllSkills) {
      dispatch(fetchAllSkillsReq());
    }
  }, [dispatch, hasFetchedAllSkills]);

  useEffect(() => {
    if (!hasFetchedProfile) {
      personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
    }
  }, [dispatch, personalProfileSource]);

  useEffect(() => {
    setIsConsentDisplayed(consentForPlanningEnabled && consentForPlanning?.status === false);
  }, [consentForPlanningEnabled, consentForPlanning]);

  useEffect(() => {
    dispatch(setSelectedSkillInfo(null));
  }, [location.pathname]);

  const toggleResumeParserModal = (active) => {
    setActiveResumeParserModal(active);
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  const handleOnAccept = (type) => {
    dispatch(
      updateWalletProfileConsentReq({
        [type]: true,
      }),
    );
  };

  const handleTShapeRoleChange = (e) => {
    const role = selectedWorkRoles.find((x) => x.id === e.target.value);
    setSelectedTShapeRole(role);
  };

  function triggerAboutSkillsHelp() {
    setShowAboutSkillsHelp(!showAboutSkillsHelp);
    setShowSkillsExplanation(false);
  }

  function handleSkillsTourCallback(data) {
    if (data.origin === 'button_close') {
      dispatch(setDisplayTour(false));
      return;
    }

    if (data.action === 'next' && data.index === 6 && data.lifecycle === 'complete') {
      setShowDummySkill(true);
    }
    if (data.action === 'next' && data.index === 7 && data.lifecycle === 'complete') {
      const button = document.querySelector('.iq4-skill-card__expand-button');
      if (button) {
        button.click();
        dispatch(setDisplayTour(false));
        setTimeout(() => {
          setShowSecondTour(true);
        }, 500);
      }
    }
  }

  function handleSecondTourCallback(data) {
    if (data.origin === 'button_close') {
      setShowSecondTour(false);
      setCurrentSecondTourStepIndex(0);
      dispatch(setSkillExpandedId(-1));
      setShowDummySkill(false);
    }

    if (data.action === 'next' && data.index === 0 && data.lifecycle === 'complete') {
      setCurrentSecondTourStepIndex(1);
    }

    if (data.action === 'prev' && data.index === 1 && data.lifecycle === 'complete') {
      console.log('triggering first step');
      setCurrentSecondTourStepIndex(0);
    }

    if (data.action === 'prev' && data.index === 2) {
      const nextTab = document.querySelector('#tab-skills-0-0');
      if (nextTab) {
        nextTab.click();
      }

      setTimeout(() => {
        // Trigger the next step after a delay
        setCurrentSecondTourStepIndex(1);
      }, 100);
    }

    if (data.action === 'next' && data.index === 1 && data.lifecycle === 'complete') {
      const nextTab = document.querySelector('#tab-skills-0-1');
      if (nextTab) {
        nextTab.click();
      }

      setTimeout(() => {
        // Trigger the next step after a delay
        setCurrentSecondTourStepIndex(2);
      }, 100);
    }

    if (data.action === 'prev' && data.index === 3 && data.lifecycle === 'complete') {
      const nextTab = document.querySelector('#tab-skills-0-1');
      if (nextTab) {
        nextTab.click();
      }

      setTimeout(() => {
        // Trigger the next step after a delay
        setCurrentSecondTourStepIndex(2);
      }, 100);
    }

    if (data.action === 'next' && data.index === 2 && data.lifecycle === 'complete') {
      const nextTab = document.querySelector('#tab-skills-0-2');
      if (nextTab) {
        nextTab.click();
      }

      setTimeout(() => {
        // Trigger the next step after a delay
        setCurrentSecondTourStepIndex(3);
      }, 100);
    }

    if (data.action === 'next' && data.index === 3 && data.lifecycle === 'complete') {
      setTimeout(() => {
        // Trigger the next step after a delay
        setCurrentSecondTourStepIndex(4);
      }, 100);
    }
    if (data.action === 'next' && data.index === 4 && data.lifecycle === 'complete') {
      setTimeout(() => {
        // Trigger the next step after a delay
        setCurrentSecondTourStepIndex(5);
      }, 100);
    }

    if (data.action === 'next' && data.index === 5 && data.lifecycle === 'complete') {
      setCurrentSecondTourStepIndex(6);
    }

    if (data.action === 'next' && data.index === 6 && data.lifecycle === 'complete') {
      setCurrentSecondTourStepIndex(7);
    }

    if (data.action === 'next' && data.index === 7 && data.lifecycle === 'complete') {
      setCurrentSecondTourStepIndex(8);
    }

    if (data.action === 'next' && data.index === 8 && data.lifecycle === 'complete') {
      setShowSecondTour(false);
      setShowDummySkill(false);
      setCurrentSecondTourStepIndex(0);
      dispatch(setSkillExpandedId(-1));
    }
  }

  const desktopSteps = [
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            To understand more about this skill, read the provided definition.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '.iq4-skill-card__skill-description',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            When you click "Skill Details" you will see examples of how the skill can be used in a
            job. The boxes let you show how and where you have used this skill. Look through the
            list and check the ones that match your experiences.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '#tabpanel-skills-0-0',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            You can always rate how good you are at any skill. You can update these ratings at any
            time as you learn and grow in this area.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '.iq4-skill-card__proficiency--content',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Here is where you can manage the source of any skill you have claimed yourself. Click on
            "Manage Sources" to show which past epxerience or portfolio item is the source of your
            skill.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '.iq4-skills-source__list',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Please note that not all skills will have the same amount of detail available - this can
            vary depending on the skill! Also, if you have a verified skill from a digital
            credential, you will see a checkmark next to it. This means the skill is verifiable, and
            you won't be able to edit the details since someone else confirmed it.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            You can remove any skill you don't want in your wallet at any time.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '.iq4-skill-card__delete-button',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Check here for skills that are similar or connected to others in your profile. This will
            help you create a strong picture of what you can do.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      disableScrolling: false,
      target: '.iq4-recommended-skills-panel',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Do you have a lot of skills in your wallet? You can use filters here to see only skills
            in a specific category or only skills that are verified.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      disableScrolling: false,
      target: '.iq4-manage-skills__filter-trigger',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            This section lets you showcase your top 10 skills. You can change and rearrange them to
            showcase what you think you're best at. In the future, you will be able to share this
            section with a potential employer.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      disableScrolling: false,
      target: '.iq4-top-10-panel',
    },
  ];

  const mobileSteps = [
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            To understand more about this skill, read the provided definition.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      hideCloseButton: true,
      target: '.iq4-skill-card__skill-description',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            When you click "Skill Details" you will see examples of how the skill can be used in a
            job. The boxes let you show how and where you have used this skill. Look through the
            list and check the ones that match your experiences.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      hideCloseButton: true,
      target: '#tabpanel-skills-0-0',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            You can always rate how good you are at any skill. You can update these ratings at any
            time as you learn and grow in this area.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '.iq4-skill-card__proficiency--content',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Here is where you can manage the source of any skill you have claimed yourself. Click on
            "Manage Sources" to show which past epxerience or portfolio item is the source of your
            skill.
          </p>
        </div>
      ),
      spotlightClicks: false,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      hideCloseButton: true,
      target: '.iq4-skills-source__list',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Please note that not all skills will have the same amount of detail available - this can
            vary depending on the skill! Also, if you have a verified skill from a digital
            credential, you will see a checkmark next to it. This means the skill is verifiable, and
            you won't be able to edit the details since someone else confirmed it.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      placement: 'center',
      target: 'body',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            You can remove any skill you don't want in your wallet at any time.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      target: '.iq4-skill-card__delete-button',
    },
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Do you have a lot of skills in your wallet? You can use filters here to see only skills
            in a specific category or only skills that are verified.
          </p>
        </div>
      ),
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      disableOverlay: false,
      disableScrolling: false,
      target: '.iq4-manage-skills__filter-trigger',
    },
  ];

  return (
    <>
      <Helmet title={`${applicationTitle} - Manage Skills`} />
      <Topbar Logo={Logo} onUploadResume={() => setShowResumeParser(true)} />

      {isConsentDisplayed && (
        <ConsentBanner
          title="Planning Consent"
          message={consentForPlanning?.message}
          acceptLabel="Accept"
          onAccept={() => handleOnAccept('consentForPlanning')}
        />
      )}
      {showTour && (
        <Joyride
          disableOverlay={true}
          steps={[
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Looking for help understanding how to use the Skills section of the wallet? This
                    brief tutorial will show you everything you need to know!
                  </p>
                </div>
              ),
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              placement: 'center',
              target: 'body',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    The Skills section of your wallet helps you to understand and manage your
                    skills, so that you will be able to communicate how your background and
                    knowledge aligns with the skills that employers seek as you search for jobs.
                  </p>
                </div>
              ),
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              placement: 'center',
              target: 'body',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Here is where you can see your total number of skills and understand how they
                    are categorized.
                  </p>
                </div>
              ),
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              target: '.iq4-at-a-glance-panel',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Technology Workforce Skills represent the ability to effectively use a variety
                    of software and tools on the job.
                  </p>
                </div>
              ),
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              target: '.iq4-glance-0',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Specialized Workforce Skills are unique abilities and knowledge needed for a
                    particular occupation, that includes a distinct range of skills that are crucial
                    for success on the job.
                  </p>
                </div>
              ),
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              target: '.iq4-glance-1',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Essential Workforce Skills are the personal qualities and behaviors crucial for
                    professional success. These skills can often be transferred and applied across
                    various job roles and industries.
                  </p>
                </div>
              ),
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              target: '.iq4-glance-2',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Even though we have probably picked up skills from your resume and any
                    credentials you've added, you can still add more skills. Let's add a skill now!
                  </p>
                </div>
              ),
              spotlightClicks: false,
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              placement: 'right',
              disableOverlay: false,
              target: '.iq4-skills-search-bar__input ',
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Once you've added a new skill, you'll be able to understand and manage it by
                    clicking on the arrow in the upper right corner.
                  </p>
                </div>
              ),
              spotlightClicks: false,
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
              disableOverlay: false,
              target: '.iq4-skill-card',
            },
          ]}
          showProgress={false}
          disableBeacon={true}
          continuous={true}
          run={true}
          callback={handleSkillsTourCallback}
          disableOverlayClose={true}
          scrollToFirstStep={true}
          disableScrolling={true}
          hideBackButton={true}
          styles={{
            buttonBack: {
              backgroundColor: 'transparent',
              color: '#0070F0',
            },
            buttonNext: {
              backgroundColor: '#0070F0',
              color: 'white',
            },
          }}
          locale={{
            last: 'Next',
          }}
        />
      )}
      {showSecondTour && (
        <Joyride
          hideBackButton={true}
          disableBeacon={true}
          steps={isMobile ? mobileSteps : desktopSteps}
          showProgress={false}
          continuous={true}
          scrollOffset={350}
          run={showSecondTour}
          stepIndex={currentSecondTourStepIndex}
          callback={handleSecondTourCallback}
          disableOverlayClose={true}
          scrollToFirstStep={true}
          disableScrolling={true}
          spotlightClicks={false}
          styles={{
            buttonBack: {
              backgroundColor: 'transparent',
              color: '#0070F0',
            },
            buttonNext: {
              backgroundColor: '#0070F0',
              color: 'white',
            },
          }}
          locale={{
            last: 'Finish Tour',
          }}
        />
      )}

      <main
        className={`iq4-manage-skills__page ${
          isConsentDisplayed ? 'iq4-manage-skills__page__with-consent-banner' : ''
        }`}
        id="main"
      >
        {activeTShapeView && (
          <TShapePage
            selectedRole={selectedTShapeRole}
            renderOnlySelfSkills={!selectedTShapeRole}
            onClose={() => void setActiveTShapeView(false)}
          />
        )}
        <Container style={{ display: activeTShapeView ? 'none' : 'block' }}>
          <Grid container alignItems="flex-start">
            <Grid item classes={{ root: 'iq4-manage-skills__left' }} lg={3} md={4} xs={12}>
              <div style={{ display: 'flex', alignItems: 'center', margin: '19.5px 0' }}>
                <h1 className="iq4-manage-skills__title">Manage Skills</h1>
                <div
                  className="iq4-t-shape__info"
                  role="button"
                  tabIndex={0}
                  onClick={triggerAboutSkillsHelp}
                  onKeyPress={triggerAboutSkillsHelp}
                  aria-label="Get more info about T-Shape labels"
                >
                  <SimpleIcon name="infoDefault" />
                  <div className="iq4-t-shape__info--text">About Skills</div>
                </div>
                {showAboutSkillsHelp && (
                  <AboutSkillsHelp hideAboutSkillsHelp={() => setShowAboutSkillsHelp(false)} />
                )}
              </div>
              {!isMobile && (
                <>
                  <AtAGlance
                    isOuterCard
                    skillsCount={mySkills.length}
                    technicalCount={skillsBreakup['it skills']}
                    operationalCount={skillsBreakup['soft skills']}
                    professionalCount={skillsBreakup['professional skills']}
                    expandByDefault={true}
                    expandable={false}
                  />
                  {displayTop10Skills && <Top10 expandable={false} expandByDefault={true} />}

                  <Expand
                    title="MY SKILLS T"
                    isOuterCard
                    uniqueId="my-skills-t"
                    label="MY SKILLS T"
                  >
                    <div className="iq4-manage-skills__tshape-container">
                      {selectedTShapeRole && (
                        <Dropdown
                          className="iq4-manage-skills__tshape-dropdown"
                          options={selectedWorkRoles}
                          value={selectedTShapeRole.id}
                          onChange={handleTShapeRoleChange}
                          placeholder="Role"
                          menuClass="iq4-profile-skills__tshape-dp-menu"
                        />
                      )}

                      <div className="iq4-manage-skills__tshape">
                        {((hasLoaded && !selectedTShapeRole) ||
                          (selectedTShapeRole && tShapeGraphicData)) && (
                          <TShapeV2
                            renderOnlySelfSkills={!selectedTShapeRole}
                            {...(selectedTShapeRole
                              ? { ...tShapeGraphicData }
                              : { ...getMySkillsTShapeData(mySkills) })}
                            displayOnly
                          />
                        )}
                      </div>

                      <button
                        className="iq4-manage-skills__tshape-btn"
                        onClick={() => void setActiveTShapeView(true)}
                      >
                        View larger version
                      </button>
                    </div>
                  </Expand>
                  <RecommendedSkillsPanel addedSkills={skills.length} />
                </>
              )}
            </Grid>
            <Grid item classes={{ root: 'iq4-manage-skills__main' }} lg={9} md={8} xs={12}>
              <div className="iq4-manage-skills__main-header">
                {selfAttestedEnabled && (
                  <SkillsSearchBar
                    results={skillResults}
                    onChange={(searchTerm) => {
                      setSearchTerm(searchTerm);
                    }}
                    onSkillClick={(skill) => {
                      dispatch(addSkillReq(skill));
                      setTimeout(() => {
                        const button = document.querySelector('.iq4-toast--close');
                        if (button) {
                          button.focus();
                        }
                      }, 200);
                    }}
                    isShowSkillsExplanation={isShowSkillsExplanation}
                    setShowSkillsExplanation={setShowSkillsExplanation}
                  />
                )}
                {!isMobile && (
                  <Button
                    className="iq4-manage-skills__button"
                    variation="ghost"
                    onClick={() => setShowResumeParser(true)}
                  >
                    Upload your updated resume
                  </Button>
                )}
                {showResumeParser && (
                  <ProfileResumeParse onClose={() => setShowResumeParser(false)} />
                )}
              </div>
              {isMobile && (
                <InnerTabs
                  className="iq4-manage-skills__page__mobile-tabs"
                  data={tabContent}
                  tabsProps={{ variant: 'fullWidth' }}
                  removeTabsIfOnlyOne
                />
              )}
              {!isMobile && skills && (
                <ManageSkillsCard skills={skills} showDummySkill={showDummySkills} />
              )}
            </Grid>
          </Grid>
        </Container>

        <div className="iq4-profile-page__footer-container">
          <Footer />
        </div>

        {resumeParserEnabled && (
          <ResumeParserModal
            isOpen={activeResumeParserModal}
            handleClose={() => void setActiveResumeParserModal(false)}
            onSave={() => void setActiveResumeParserModal(false)}
          />
        )}
      </main>
    </>
  );
};
