import React from 'react';
import { useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { selectProfile } from 'common/store/features/profile/selectors';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';
import './TopbarAvatar.scss';

export const TopbarAvatar = () => {
  const { personal } = useSelector(selectProfile);
  const resolvedAvatarRequest = !!personal.userProfilePhoto;
  const avatarPhoto = personal?.userProfilePhoto?.thumbnail;
  const avatarAlt = personal?.userProfile?.user
    ? `${personal.userProfile.user.firstName} ${personal.userProfile.user.lastName} profile picture`
    : 'User empty avatar';

  return (
    <>
      <div className="iq4-topbar-avatar">
        {!resolvedAvatarRequest && (
          <Skeleton variant="circle" style={{ width: '100%', height: '100%' }} />
        )}
        {resolvedAvatarRequest && (
          <Avatar
            className="iq4-topbar-avatar__image"
            alt={avatarAlt}
            src={avatarPhoto}
            role="presentation"
            aria-hidden="true"
            aria-label={avatarAlt}
          />
        )}
      </div>
    </>
  );
};
