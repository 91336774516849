import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextInput,
  AutoComplete,
  Checkbox,
  TextArea,
  Select,
  DateRange,
  FileUpload,
  AccessibleSelect,
  RadioGroup,
} from 'common/components/Form/fieldTypes';
import { CredentialPill } from 'common/components';
import { selectFeatures } from 'store/features/config/selectors';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { validations } from 'common/components/Form/validations';
import { PROFILE_EDUCATION_URL } from 'common/constants/endpoints';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import {
  setProfileSection,
  updateProfileSection,
} from 'common/store/features/profile/profileSlice';
import { PROFILE_TYPE, sendFormDataRequest, transformProfileFormsPutData } from 'common/utils';
import './useProfileEducationFormDefinition.scss';

const achievementTypeOptions = [
  {
    key: 'highSchoolDiploma',
    value: 'High School Diploma',
  },
  {
    key: 'GED',
    value: 'General Educational Development (GED)',
  },
  {
    key: 'certification',
    value: 'Certification',
  },
  {
    key: 'professionalLicense',
    value: 'Professional License',
  },
  {
    key: 'microCredential',
    value: 'Micro-credential (ie: nano-degree, certificate, etc.)',
  },
  {
    key: 'AA',
    value: 'Associate of Arts (AA)',
  },
  {
    key: 'AS',
    value: 'Associate of Science (AS)',
  },
  {
    key: 'AAS',
    value: 'Associate of Applied Science (AAS)',
  },
  {
    key: 'BA',
    value: 'Bachelor of Arts (BA)',
  },
  {
    key: 'BS',
    value: 'Bachelor of Science (BS)',
  },
  {
    key: 'MA',
    value: 'Master of Arts (MA)',
  },
  {
    key: 'MS',
    value: 'Master of Science (MS)',
  },
  {
    key: 'MBA',
    value: 'Master of Business Administration (MBA)',
  },
];

const radioButtonGroup = [
  {
    id: '1',
    value: 'true',
    label: 'I am currently studying here',
  },
  {
    id: '2',
    value: 'false',
    label: 'Graduated',
  },
];

export const useProfileEducationFormDefinition = ({ values, onComplete }) => {
  const { selfAttestedEnabled } = useSelector(selectFeatures);
  const dispatch = useDispatch();
  const [competenciesSearchTerm, setCompetenciesSearchTerm] = useState('');
  const competenciesData = useSkillsSearch(competenciesSearchTerm);

  const requiredValidator = { required: validations.isRequired() };

  const fieldsDefinition = [
    {
      component: () => <CredentialPill credential={values} />,
    },
    {
      label: 'Program Name',
      name: 'title',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <AccessibleSelect
          label="Achievement Type"
          placeholder="Select Achievement Type "
          maxWidth
          validators={{ ...requiredValidator }}
          name="achievementType"
          options={achievementTypeOptions}
          {...formProps}
        />
      ),
    },
    {
      label: 'School name',
      name: 'school',
      component: TextInput,
      isDisabled: values.verifiedBy,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => {
        return (
          <div className="iq4-education__radios">
            <RadioGroup radioItems={radioButtonGroup} name="isCurrentStudent" {...formProps} />
          </div>
        );
      },
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => {
        const { isCurrentStudent } = getValues();
        return (
          <DateRange
            isPresent={isCurrentStudent}
            key={key}
            {...formProps}
            isDisabled={values.verifiedBy}
          />
        );
      },
    },
    {
      label: 'GPA',
      name: 'gpa',
      component: TextInput,
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <AutoComplete
          aria-label="Type to search your skills"
          name="competencies"
          label="Skills"
          placeholder="Type to search your skills"
          options={competenciesData}
          optionLabel="value"
          onInputChange={(e, value) => setCompetenciesSearchTerm(value)}
          tooltip={
            <p>
              Start typing to add skills. <br />
              <br />
              Skills examples include: Teamwork, C++, Sales, etc.
            </p>
          }
          isDisabled={values.verifiedBy}
          {...formProps}
        />
      ),
    },
    {
      label: 'Concentration or Field of study',
      name: 'concentration',
      component: TextInput,
    },
    {
      label: 'Honors / Awards',
      name: 'honors',
      component: TextInput,
    },
    {
      label: 'Activities / Societies / Clubs',
      name: 'activities',
      component: TextInput,
    },
    ...(!values.verifiedBy
      ? [
          {
            isCustom: true,
            component: ({ ...formProps }) => (
              <FileUpload
                triggerLabel="Upload a cover image"
                name="image"
                type="thumbnail"
                fileTypes={THUMBNAIL_MIME_TYPES}
                {...formProps}
              />
            ),
          },
        ]
      : []),
  ];

  const onEdit = (data) => {
    return sendFormDataRequest({
      url: `${PROFILE_EDUCATION_URL}/${values.id}`,
      verb: 'PUT',
      data: transformProfileFormsPutData(data, values),
    }).then((resp) =>
      dispatch(updateProfileSection({ section: PROFILE_TYPE.EDUCATION, value: resp })),
    );
  };

  const onCreate = (data) => {
    return sendFormDataRequest({
      url: PROFILE_EDUCATION_URL,
      verb: 'POST',
      data: {
        ...data,
        isCurrentStudent: !(data.endMonth && data.endYear),
        ...(data.competencies && {
          competencies: data.competencies.map((competency) => ({ id: competency.id })),
        }),
      },
    }).then((resp) => {
      return dispatch(setProfileSection({ section: PROFILE_TYPE.EDUCATION, value: resp }));
    });
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
