export const selectIsBootstrapping = (state) => state.session.isBootstrapping;
export const selectIsLoggedIn = (state) => state.session.isLoggedIn;
export const selectIsLoggingIn = (state) => state.session.isLoggingIn;
export const selectIsChangingPassword = (state) => state.session.isChangingPassword;
export const selectHasLoginError = (state) => state.session.hasLoginError;
export const selectIsSessionExpired = (state) =>
  !!(state.session.isLoggedIn && state.session.isSessionExpired);

export const selectSessionUser = (state) => state.session?.user?.personal?.userProfile?.user;

export const selectIsResettingPassword = (state) => state.session.isResettingPassword;
export const selectHasActivatedCode = (state) => state.session.hasActivatedCode;
export const selectHasCodeActivationError = (state) => state.session.hasCodeActivationError;
export const selectIsActivatingCode = (state) => state.session.isActivatingCode;

