import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import { Button, SimpleIcon } from 'common/components';
import { ReactComponent as ModalSVG } from '@assets/svg/logo.svg';
import './PromptModal.scss';

export const PromptModal = (props) => {
  const { isOpen, handleClose, title, buttonLabel, buttonAction, hideDismiss } = props;
  return (
    <Dialog
      classes={{ root: 'iq4-prompt-modal__wrapper', paper: 'iq4-prompt-modal' }}
      open={isOpen}
      onClose={handleClose}
      disableBackdropClick
    >
      {!hideDismiss && (
        <button className="iq4-prompt-modal__dismiss-button" onClick={() => handleClose()}>
          <SimpleIcon name="close" />
        </button>
      )}
      <ModalSVG className="iq4-prompt-modal__svg" />
      <span className="iq4-prompt-modal__title">{title}</span>
      <div className="iq4-prompt-modal__buttons-wrapper">
        <Button className="iq4-prompt-modal__button" variation="ghost" onClick={buttonAction}>
          {buttonLabel}
        </Button>
        {hideDismiss && (
          <Button className="iq4-prompt-modal__button" variation="ghost" onClick={handleClose}>
            Cancel
          </Button>
        )}
      </div>
    </Dialog>
  );
};

PromptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  hideDismiss: PropTypes.bool,
};

PromptModal.defaultProps = {
  hideDismiss: false,
};
