import React, { useState } from 'react';
import moment from 'moment';
import { MultilineDisplayer, SimpleIcon } from '../../common/components';
import { STUDENT_INVITE_STATUS, STUDENT_INVITE_STATUS_LABEL } from '../../common/utils';
import CircularProgress from '@material-ui/core/CircularProgress';

export function HistoryRow({ item, onRevoke, onManage }) {
  const [isExpanded, setIsExpanded] = useState(false);

  async function triggerRevoke() {
    await onRevoke(item);
    setIsExpanded(!isExpanded);
  }

  function onManageClick() {
    onManage({ counsellorId: item.counsellorId });
  }

  return (
    <div className={`iq4-connection-row ${isExpanded ? 'iq4-connection-row-expanded' : ''}`}>
      <SimpleIcon
        className="iq4-connection-row--expander"
        onClick={() => setIsExpanded(!isExpanded)}
        name={isExpanded ? 'caretUp' : 'caretDown'}
      />
      <div className="iq4-connection-row--container">
        <div className="iq4-connection-row--avatar-container iq4-connections-page--pre-expand--flex-2-no-center">
          <span className="iq4-connection-row--avatar-container--avatar">
            <SimpleIcon name="student" />
          </span>
          <span className="iq4-connection-row--avatar-container--label">
            {item.counsellorFullName}
          </span>
        </div>
        <div className="iq4-connections-page--pre-expand--flex-1">
          {moment(new Date(item.dateCreated)).format('MM/DD/YYYY')}
        </div>
        <div className="iq4-connections-page--pre-expand--flex-1">{item.school}</div>
        <div className="iq4-connections-page--pre-expand--flex-1 iq4-connection-row--margin">
          <div className="iq4-connection-row--status">
            {STUDENT_INVITE_STATUS_LABEL[STUDENT_INVITE_STATUS[item.status]]}
          </div>
        </div>
        <div className="iq4-connections-page--pre-expand--flex-1">
          <div className="iq4-connection-row--actions">
            <div
              className="iq4-connection-row--actions--accept"
              tabIndex={0}
              role="button"
              onClick={onManageClick}
              onKeyPress={onManageClick}
            >
              MANAGE
            </div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <div className="iq4-connection-row--message-container">
            <div className="iq4-connection-row--message-container--title">Invitation Message</div>
            <div className="iq4-connection-row--message-container--content">
              <MultilineDisplayer text={item.msg} />
            </div>
          </div>
          {item.status === 2 && (
            <div
              className="iq4-connection-row--actions--revoke"
              tabIndex={0}
              role="button"
              onClick={triggerRevoke}
            >
              Revoke
            </div>
          )}
        </>
      )}
    </div>
  );
}
