import React from 'react';
import './RecommendedSkillsList.scss';

export const RecommendedSkillsList = ({ className, skills, onAdd }) => {
  const renderSkills = (skills) => {
    return skills.map((skill) => (
      <li key={skill.id} className="iq4-recommended-skill-list__item">
        <p className="iq4-recommended-skill-list__item-value">{skill.value}</p>
        <span className="iq4-recommended-skill-list__item-pill">NEW SKILL</span>
        <span
          className="iq4-recommended-skill-list__item-btn"
          tabIndex={0}
          role="button"
          onClick={() => void onAdd(skill)}
          onKeyPress={() => void onAdd(skill)}
          aria-label={`Add ${skill.value} to your skills list`}
        >
          Add
        </span>
      </li>
    ));
  };

  return (
    <ul className={`iq4-recommended-skill-list ${className ? className : ''}`}>
      {skills.length ? renderSkills(skills) : <p>You currently have no recommended skills</p>}
    </ul>
  );
};
