import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import { SimpleIcon, Button, PromptModal, TriggerModal } from 'common/components';
import { SkillSourceAdd } from '../SkillSourceAdd';
import { removeProfileSectionCompetency } from 'common/store/features/profile/profileSlice';
import {
  removeSkillSourceReq,
  fetchSkillDetails,
  updateSkill,
  fetchAllSkillsReq,
} from 'common/store/features/skills/skillsSlice';
import './SkillSources.scss';

export const SkillSources = ({ skill, readOnly = false }) => {
  const profile = useSelector(selectProfile);
  const dispatch = useDispatch();
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [sources, setSources] = useState([]);
  const isPublicProfile = useSelector(selectIsPublicProfile);

  useEffect(() => {
    setSources(skill && skill.sources ? skill.sources : []);
  }, [skill, profile]);

  const handleDeleteItem = async () => {
    await dispatch(removeSkillSourceReq({ skillId: skill.competenceId, source: itemToDelete }));
    if (itemToDelete.type !== 'credentials') {
      dispatch(
        removeProfileSectionCompetency({
          section: itemToDelete.type,
          id: itemToDelete.id,
          competency: {
            id: skill.competenceId,
            value: skill.tier3Label,
          },
        }),
      );
    }
    if (sources?.length === 1) {
      dispatch(fetchAllSkillsReq());
    }

    setIsPromptActive(false);
  };

  const confirmDelete = (source) => {
    setIsPromptActive(true);
    setItemToDelete(source);
  };

  const renderEmptyState = () => (
    <div className="iq4-skills-source__empty">
      <p>You have no sources for this skill</p>
    </div>
  );

  const renderSources = (sources) => {
    return (
      <>
        <p className="iq4-skills-source__intro">
          Below you will see the sources (or origins) for this skill and where it came from:
        </p>
        <ul className="iq4-skills-source__list">
          {sources.map((source) => {
            return (
              <li className="iq4-skills-source__list-item" key={`${source.type}-${source.id}`}>
                <div className="iq4-skills-source__heading">
                  <SimpleIcon
                    className="iq4-skills-source__heading-icon"
                    name={`${source.type}Circle`}
                    role="presentation"
                  />
                  <span className="iq4-skills-source__heading-label">
                    {source.type === 'manual' ? 'Manual' : source.type}
                  </span>
                </div>
                <div className="iq4-skills-source__content">
                  <span className="iq4-skills-source__content-title">{source.title}</span>
                  {!readOnly &&
                    !source.verifiedBy &&
                    source.type !== 'manual' &&
                    !isPublicProfile &&
                    !source.isVerified && (
                      <Button
                        variation="ghost"
                        className="iq4-skills-source__content-delete"
                        onClick={() => confirmDelete(source)}
                      >
                        Delete
                      </Button>
                    )}
                </div>
              </li>
            );
          })}
          <PromptModal
            hideDismiss
            isOpen={isPromptActive}
            handleClose={() => setIsPromptActive(false)}
            title="Are you sure you want to delete this?"
            buttonLabel="Delete"
            buttonAction={() => handleDeleteItem()}
          />
        </ul>
      </>
    );
  };

  const onSkillSourceAdd = () => {
    dispatch(fetchSkillDetails({ competenceId: skill.competenceId }));
  };

  return (
    <div className="iq4-skills-source">
      {!sources.length && renderEmptyState()}
      {!!sources.length && renderSources(sources)}
      {!readOnly && !isPublicProfile && (
        <TriggerModal
          className="iq4-skills-source__add-source-trigger"
          buttonVariation="ghost"
          title="Source List"
          form={<SkillSourceAdd skill={skill} onAddSourceComplete={onSkillSourceAdd} />}
          label="Manage sources"
          ariaLabel="Manage sources for this skill"
        />
      )}
    </div>
  );
};
