import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'common/components';
import { useIsMobile } from 'common/hooks/useIsMobile';
import './OnboardingFooter.scss';

export const OnboardingFooter = ({
  direction,
  isSubmittingForm,
  prevButtonLabel = 'Previous',
  nextButtonLabel = 'Next',
  handlePrev = () => {},
  handleNext = () => {},
  skipStep,
}) => {
  const isMobile = useIsMobile();

  return (
    <div className="iq4-onboarding-footer">
      {direction && <p className="iq4-onboarding-footer__direction">{direction}</p>}
      {skipStep && (
        <Button className="iq4-onboarding__skip iq4-onboarding__skip-2" onClick={skipStep}>
          Skip this step
        </Button>
      )}

      <div className="iq4-onboarding-footer__buttons-container">
        {!isMobile && (
          <Button className="iq4-onboarding-footer__button-prev" onClick={() => handlePrev()}>
            {prevButtonLabel}
          </Button>
        )}
        <div className="iq4-onboarding-footer__button">
          <Button
            className={`iq4-onboarding-footer__button-next ${
              isSubmittingForm ? 'iq4-onboarding-footer__button-next--loading' : ''
            }`}
            onClick={() => handleNext()}
          >
            {nextButtonLabel}
          </Button>
          {isSubmittingForm && (
            <CircularProgress size={24} className="iq4-form__progress__indicator" />
          )}
        </div>
      </div>
    </div>
  );
};
