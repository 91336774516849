import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleIcon, Button, ExternalLinkInterstitial } from 'common/components';
import { Modal } from 'common/components/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './CredentialDisputeModal.scss';
import { LCN_CREDENTIAL_PROVIDERS } from '../../../common/constants/lcnRecords';
import { selectIsDisputingCredential } from '../../../common/store/features/credentials/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';

export const CredentialDisputeModal = ({
  credential,
  onDispute = () => {},
  onClose = () => {},
  onDeactivation = () => {},
}) => {
  const isSubmittingDispute = useSelector(selectIsDisputingCredential);

  if (!credential) {
    return null;
  }

  return (
    <Modal
      isOpen={true}
      handleClose={() => onClose()}
      className="iq4-credential-dispute-modal"
      onDeactivation={onDeactivation}
    >
      <h3 className="iq4-credential-dispute-modal__heading">Credential Dispute Resolution</h3>

      <p className="iq4-credential-dispute-modal__intro">
        If you wish to dispute a credential, please send a detailed email outlining your concerns to
        WGU for prompt assistance and resolution. <br />
        <br /> You may be required to submit relevant documentation supporting your dispute, such as
        transcripts, exam scores, or other pertinent records.
        <br />
        <br /> WGU will carefully review the provided information, conducting a thorough examination
        of the credentials in question and the grounds for dispute. <br />
        <br />
        It’s important for you to follow any instructions provided by WGU and to respond promptly to
        any requests for additional information. <br />
        <br />
        {!!credential?.issuerEmail && (
          <>
            <span>Please send your dispute email to:</span> <br />
            <span className="iq4-issuer-email">{credential?.issuerEmail}</span>
          </>
        )}
      </p>

      <div className="iq4-credential-dispute-modal__buttons">
        <Button
          variation="ghost"
          className="iq4-credential-dispute-modal__buttons-cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <div style={{ minWidth: '120px' }}>
          {!isSubmittingDispute && (
            <Button
              className="iq4-credential-dispute-modal__buttons-submit"
              onClick={() => onDispute(credential)}
            >
              SET TO DISPUTE
            </Button>
          )}

          {isSubmittingDispute && (
            <CircularProgress size={24} className="iq4-credentials__dispute-loading" />
          )}
        </div>
      </div>
    </Modal>
  );
};
