import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { ValidationMessage, Tooltip } from 'common/components';
import './TextInput.scss';

/**
 * TextInput form input, connected to RHF form context
 * @param validators
 * @param id
 * @param name
 * @param label
 * @param ariaLabel
 * @param placeholder
 * @param tooltip
 * @param tooltipVariation
 * @param isDisabled
 * @param exposeFormContext
 * @param triggerExternalValidation
 * @param inputProps
 * @param type
 * @param customInfo
 * @returns {*}
 * @constructor
 */
export const TextInput = ({
  validators,
  id,
  name,
  label,
  ariaLabel,
  placeholder,
  tooltip,
  tooltipVariation,
  isDisabled,
  exposeFormContext,
  triggerExternalValidation,
  inputProps,
  type = 'text',
  customInfo,
}) => {
  const { register, triggerValidation, formState, errors } = useFormContext();
  const touched = formState.touched[name];
  const dirty = formState.dirty;
  const hasError = errors[name];
  const isSubmitted = formState.isSubmitted;
  const showError = () => {
    if (exposeFormContext && !dirty) return false;

    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  const isRequiredField = !!validators?.['required'];

  return (
    <div className="iq4-text-input">
      {label && (
        <label className="iq4-text-input__label" htmlFor={name}>
          {label}
          {isRequiredField && (
            <>
              <span className="iq4-field__required-mark" aria-hidden="true">
                *
              </span>
              <span className="sr-only">Required field</span>
            </>
          )}

          {tooltip && <Tooltip message={tooltip} variation={tooltipVariation} />}
          {customInfo && <div className="iq4-text-input__info">{customInfo}</div>}
        </label>
      )}
      <div className="iq4-text-input__wrapper">
        <input
          id={id || name}
          name={name}
          disabled={isDisabled}
          ref={register({ ...validators })}
          placeholder={placeholder}
          type={type}
          className={`iq4-text-input__field ${
            !!hasError && showError() ? 'iq4-text-input__field--error' : ''
          }`}
          onChange={() => triggerValidation(name)}
          aria-invalid={hasError ? 'true' : 'false'}
          aria-describedby={showError() ? `${name}Error` : null}
          aria-label={ariaLabel || label}
          {...inputProps}
        />
      </div>
      {showError() && <ValidationMessage id={`${name}Error`} message={hasError.message} />}
    </div>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  tooltip: PropTypes.node,
  tooltipVariation: PropTypes.string,
  exposeFormContext: PropTypes.bool,
  triggerExternalValidation: PropTypes.bool,
};
