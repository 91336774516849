import React from 'react';
import './ConsentBanner.scss';

export const ConsentBanner = ({ title, message, acceptLabel, onAccept = () => {} }) => (
  <div className="iq4-consent-banner">
    <div className="iq4-consent-banner__container">
      {title && <span className="iq4-consent-banner__title">{title}</span>}
      {message && <span className="iq4-consent-banner__message">{message}</span>}
      <span className="iq4-consent-banner__actions">
        {acceptLabel && (
          <button className="iq4-consent-banner__actions-accept" onClick={() => onAccept()}>
            {acceptLabel}
          </button>
        )}
      </span>
    </div>
  </div>
);
