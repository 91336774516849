import React, { useState } from 'react';
import { ROUTES } from 'routes/routeNames';
import { ActionCard, EmptyState, Modal, LoadingSkeleton, CredentialPill } from 'common/components';
import { useSelector } from 'react-redux';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { OnboardingHeader } from 'common/components/Onboarding';
import GraphicEducation from '@assets/svg/empty-states_education.svg';
import { FormWrapper } from 'common/components/Form';
import { useProfileEducationFormDefinition } from 'common/hooks/forms';
import { DEFAULT_COUNTRY } from 'common/constants/countries';
import { orderEducationRecords } from 'common/utils/orderRecords';
import './ProfileEducationSummary.scss';
import { useParams } from 'react-router-dom';

export const ProfileEducationSummary = () => {
  const MAX_NUM_RECORDS = 2;
  const { education } = useSelector(selectProfile);
  const [isOpen, setIsOpen] = useState(false);
  const isPopulated = education.length > 0;
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const hasLoaded = !isBootstrapping;
  const isMobile = useIsMobile();
  const { id } = useParams();

  const context = process.env.REACT_APP_CONTEXT;

  const defaultValues = {
    country: DEFAULT_COUNTRY,
    isCurrentStudent: false,
  };

  const onAdd = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ActionCard
        title="Education"
        icon="educationCircle"
        seeMore={
          isMobile
            ? null
            : isPopulated
            ? isPublicProfile
              ? `${context}/user/${id}/education`
              : ROUTES.PROFILE_EDUCATION
            : ''
        }
        isEmpty={!isPopulated}
      >
        {hasLoaded ? (
          <>
            {!isPopulated && (
              <>
                <img
                  src={GraphicEducation}
                  className="empty-state-graphic"
                  role="presentation"
                  alt="Education Graphic"
                />
                {!isPublicProfile && (
                  <EmptyState
                    text="Your education is important information to share with an employer. Add it now!"
                    action={onAdd}
                    actionLabel="Add Education"
                    maxWidth
                  />
                )}
              </>
            )}

            {isPopulated && (
              <ul className="iq4-profile-education-summary__list">
                {orderEducationRecords(education)
                  .slice(0, MAX_NUM_RECORDS)
                  .map((item) => (
                    <li className="iq4-profile-education-summary__list-item" key={item.id}>
                      <CredentialPill credential={item} />
                      <p className="iq4-profile-education-summary__degree">{item.title}</p>
                      <p className="iq4-profile-education-summary__school">{item.school}</p>
                      <p className="iq4-profile-education-summary__year">
                        {item.fromYear} {item.fromYear !== item.toYear ? `- ${item.toYear}` : ``}
                      </p>
                      <hr className="iq4-profile-education-summary__divide" />
                    </li>
                  ))}
              </ul>
            )}
          </>
        ) : (
          <div style={{ display: 'block', padding: '10px 0px' }}>
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={24} />
          </div>
        )}
      </ActionCard>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        title={<OnboardingHeader icon="educationCircle" title="Education" />}
      >
        <FormWrapper
          values={defaultValues}
          onComplete={handleClose}
          useFormDefinition={useProfileEducationFormDefinition}
        />
      </Modal>
    </>
  );
};
