import React, { useEffect, useState } from 'react';
import { Button, SimpleIcon } from 'common/components';
import { ProgressIndicator } from 'common/components/ProgressIndicator';
import {
  OnboardingResume,
  OnboardingResumeComplete,
  OnboardingPersonal,
  OnboardingEducation,
  OnboardingExperience,
} from 'common/components/Onboarding';
import { OnboardingAssessmentPage } from 'pages/Onboarding';
import { useIsMobile } from 'common/hooks/useIsMobile';
import './ProfileResumeParse.scss';
import FocusLock from 'react-focus-lock';
import { setIsResumeParserEnabled } from 'common/store/features/ui/uiSlice';
import { useDispatch } from 'react-redux';

export const ProfileResumeParse = ({ onClose = () => {} }) => {
  const [isParsingComplete, setParsingComplete] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [resumeUploadStatus, setResumeUploadStatus] = useState(
    `The Resume hasn't been uploaded yet.`,
  );
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const steps = [
    {
      id: 'OnboardingResume',
      component: isParsingComplete ? (
        <OnboardingResumeComplete onPrevious={onClose} onComplete={() => goToNextStep()} />
      ) : (
        <OnboardingResume
          title={isMobile ? 'Upload Resume' : 'Upload your updated Resume'}
          displayLinks={false}
          disableTourOnLoad
          onComplete={() => setParsingComplete(true)}
        />
      ),
      isActive: currentStep === 0,
    },
    {
      id: 'OnboardingPersonal',
      component: (
        <OnboardingPersonal
          onPrevious={() => goToPrevStep()}
          onComplete={() => goToNextStep()}
          disableTourOnLoad
        />
      ),
      isActive: currentStep === 1,
    },
    {
      id: 'OnboardingEducation',
      component: (
        <OnboardingEducation
          onPrevious={() => goToPrevStep()}
          onComplete={() => goToNextStep()}
          disableTourOnLoad
        />
      ),
      isActive: currentStep === 2,
    },
    {
      id: 'OnboardingExperience',
      component: (
        <OnboardingExperience
          onPrevious={() => goToPrevStep()}
          onComplete={() => goToNextStep()}
          disableTourOnLoad
        />
      ),
      isActive: currentStep === 3,
    },
    {
      id: 'OnboardingAssessmentPage',
      component: (
        <OnboardingAssessmentPage
          onPrevious={() => goToPrevStep()}
          onComplete={() => handleClose()}
          disableTourOnLoad
        />
      ),
      isActive: currentStep === 4,
    },
  ];

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPrevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    dispatch(setIsResumeParserEnabled(true));

    return () => {
      dispatch(setIsResumeParserEnabled(false));
    };
  }, []);

  const handleClose = () => {
    setCurrentStep(0);
    setParsingComplete(false);
    onClose();
  };

  useEffect(() => {
    if (isParsingComplete) {
      setResumeUploadStatus('The Resume has been uploaded successfully.');
    }
  }, [isParsingComplete]);

  return (
    <FocusLock returnFocus={true}>
      <div className="iq4-profile-resume-parse">
        <div className="iq4-profile-resume-parse__view">
          <div className="iq4-profile-resume-parse__view__header">
            <div className="iq4-profile-resume-parse__back">
              {currentStep > 0 && (
                <Button aria-label="Go Back" onClick={() => goToPrevStep()}>
                  <SimpleIcon name="onboardingArrow" />
                </Button>
              )}
            </div>

            <div className="iq4-profile-resume-parse__wizard-steps">
              <ProgressIndicator steps={steps} />
            </div>

            <div className="iq4-profile-resume-parse__close">
              <Button onClick={() => handleClose()}>
                {!isMobile && <span>Close</span>}
                <SimpleIcon name="close" />
              </Button>
            </div>
          </div>
          <div className="iq4-profile-resume-parse__view__container">
            <div className="iq4-profile-resume-parse__view--content">
              {steps[currentStep].component}
            </div>
            <div aria-live="polite" className="sr-only">
              {resumeUploadStatus}
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  );
};
