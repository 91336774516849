export default [
  {
    id: 1777,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32240,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1193,
      label: 'Organizational Awareness',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1,
      label:
        'Knowledge of intelligence reporting principles, policies, procedures, and vehicles, including report formats, reportable criteria (requirements and priorities), dissemination practices, and legal authorities and restrictions',
    },
    skillYears: 11,
    user: {
      id: 17,
    },
  },
  {
    id: 1778,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32241,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1193,
      label: 'Organizational Awareness',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 2,
      label:
        'Knowledge of the structure and intent of business or military operation plans, concept operation plans, orders, policies, and standing rules of engagement',
    },
    skillYears: 11,
    user: {
      id: 17,
    },
  },
  {
    id: 1779,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32242,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1193,
      label: 'Organizational Awareness',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 532,
      label: "Knowledge of the organization's core business/mission processes",
    },
    skillYears: 11,
    user: {
      id: 17,
    },
  },
  {
    id: 1780,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32243,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 512,
      label: 'Economics and Finance',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 530,
      label: 'Knowledge of Banking and the Financial Services industries',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 1781,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32244,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 512,
      label: 'Economics and Finance',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 457,
      label:
        'Uses economic indicators to evaluate the health of the economy, calculate of growth rates and use of indicators for comparison purposes',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 1782,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32245,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1190,
      label: 'Organization Overviews',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 531,
      label: "Understanding of the Firm's services and processes",
    },
    skillYears: 11,
    user: {
      id: 17,
    },
  },
  {
    id: 1783,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32246,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 26,
      label: 'Industry Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 643,
      label: 'Financial Institutions & Governments',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 533,
      label: 'Knowledge of  financial authorities and regulations',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1784,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32247,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 26,
      label: 'Industry Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 643,
      label: 'Financial Institutions & Governments',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 486,
      label:
        "Ability to tailor solutions to government entities' banking and cash management problems; ranging from planning and development to tax processing, utilities, finance, procurement, public safety and disaster relief",
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1785,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32248,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 26,
      label: 'Industry Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 808,
      label: 'Industry Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 534,
      label:
        'Understanding of the business strategy requirements to sustain a competitive advantage in the banking and financial services industry',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1786,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32249,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 26,
      label: 'Industry Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 808,
      label: 'Industry Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 491,
      label:
        'Provides US subsidiaries of both publically traded and private foreign multinational corporations with strategic opportunities to maximize revenue, reduce operating costs, manage global cash flow and  explore expansion operations',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99489,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 23,
      label: 'Credit, Lending and Insurance',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1096,
      label: 'Mortgages',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 464,
      label:
        'Knowledge of the major mortgage financing programs and guidelines, construction lending and land development financing, property appraisals review and types of mortgage fraud',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178784,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103177,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 30,
      label: 'Trade Finance',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1659,
      label: 'Traditional Trade',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 517,
      label:
        'Trade finance knowledge of the trade cycle funding gap including: the control use of funds, the control of the goods, the source of repayment and security over the goods and receivables',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178777,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103170,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 28,
      label: 'Investor Services and Financing Products',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 390,
      label: 'Custody & Fund Services',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 507,
      label:
        'Knowledge of what the custody and fund services products offer clients and the key components that make up custody and fund services products',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178776,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103169,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 27,
      label: 'Investments',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1719,
      label: 'Wealth Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 506,
      label:
        'Ability to discuss the concepts and general activities of private banking and wealth management businesses, their value propositions, fee structures and revenue models',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178775,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103168,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 27,
      label: 'Investments',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 658,
      label: 'Fixed Income',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 502,
      label: 'Knowledge of bonds as fixed income instruments',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178774,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103167,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 27,
      label: 'Investments',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 593,
      label: 'Equity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 500,
      label:
        'Knowledge of the key features of common and preferred stock, differences between the two and the different systems used to classify stocks',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178773,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103166,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 27,
      label: 'Investments',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 178,
      label: 'Brokerage',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 498,
      label:
        'Knowledge of the prime brokerage trade process, its benefits to institutional customers and the relationship between prime brokers and executing banks or broker/dealers',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178771,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103164,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 25,
      label: 'Finance',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1674,
      label: 'Treasury',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 480,
      label:
        'Knowledge of bank treasury functions, organization and management; including: structural elements of a bank’s balance sheets, sources of its profits and its assets and liabilities',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178772,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103165,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 27,
      label: 'Investments',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 119,
      label: 'Asset Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 497,
      label:
        'Identifies the key client sectors of the asset management industry, interprets the concepts of asset allocation and can explain the main types of investment vehicles used in the asset management industry',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178770,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103163,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 22,
      label: 'Account Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1051,
      label: 'Merchant Services',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 455,
      label:
        'Knowledge of how merchant services providers can help clients manage costs, drive sales and better navigate the omnichannel payment landscape',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178769,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103162,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 22,
      label: 'Account Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 479,
      label: 'Digital, Mobile & Online',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 454,
      label:
        'Knowledge of digital payment platforms for use in stores, online and in merchant apps that allow consumers to credit, debit, and use liquid cards as payment methods for use in ecommerce and mobile commerce transactions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178767,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103160,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 22,
      label: 'Account Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 282,
      label: 'Commercial Accounts',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 452,
      label:
        'Knowledge of Demand Deposit Accounts (DDAs) as cash management components; including:  characteristics, functions, features, benefits and target commercial clients of DDAs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178779,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103172,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 29,
      label: 'Markets',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 448,
      label: 'Derivatives, Options, Forwards & Futures',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 512,
      label:
        'Ability to differentiate between the basic types of derivatives:  forward-type instruments (forwards, futures, and swaps) and options, along with the basics of their pricing and risks that arise from the use of derivatives',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178780,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103173,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 29,
      label: 'Markets',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 591,
      label: 'Equities',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 513,
      label:
        'Knowledge of the equity market trading key drivers including: sources of information, equity trading models, trading strategies and the transaction costs associated with equity trading',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178788,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103181,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 31,
      label: 'Treasury Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 946,
      label: 'Liquidity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 523,
      label:
        'Matches liquidity products to client needs; meeting a client’s cash requirements while optimizing their returns',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178787,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103180,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 31,
      label: 'Treasury Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 358,
      label: 'Core Cash/ Receivables',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 520,
      label:
        'Able to define core cash receivable products and services and their role in the treasury cash management process',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178786,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103179,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 31,
      label: 'Treasury Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 258,
      label: 'Client Access',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 519,
      label:
        'Knowledge of the treasury management environment and how host-to-host platform solution helps address key client challenges',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178785,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103178,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 31,
      label: 'Treasury Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 257,
      label: 'Clearing, Payments, Cross Currency/FX',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 518,
      label:
        'Knowledge of core cash multicurrency clearing; key clients, processing platforms and correspondent banks',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178783,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103176,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 30,
      label: 'Trade Finance',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1569,
      label: 'Supply Chain Finance',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 516,
      label:
        'Matches client needs with supply chain finance solutions and discuss the emerging best practices in trade and supply chain finance',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178766,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82624,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 1,
      label: 'Business Knowledge',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1193,
      label: 'Organizational Awareness',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1471,
      label:
        "Deep knowledge of the organization's operating model, used to coach and develop others to understand how the business works",
    },
    skillYears: 11,
    user: {
      id: 17,
    },
  },
  {
    id: 178782,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103175,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 29,
      label: 'Markets',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1317,
      label: 'Products Knowledge',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1362,
      label:
        'Knowledge of financial products our business offers in terms key benefits & features for our clients',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178781,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103174,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 29,
      label: 'Markets',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 660,
      label: 'Fixed Income Products',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 514,
      label:
        'Ability to explain government and corporate bond markets, identify major players in the fixed income market and compare the types of fixed  income instruments',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178778,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103171,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 29,
      label: 'Markets',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 284,
      label: 'Commodities',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 511,
      label:
        'Ability to explain the fundamentals of commodities trading and identify the main participants in the commodities market',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178768,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103161,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 1,
      label: 'Business & Products',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 22,
      label: 'Account Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 335,
      label: 'Consumer Accounts',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 453,
      label:
        'Knowledge of transaction banking as a distinct business line, the different services provided by transaction banks and the impact of technology and regulations such as Basel III',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178790,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103182,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 3,
      label: 'Controls',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 789,
      label: 'IAS (Inter-Affiliate Services )',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 550,
      label:
        'Knowledge of the Inter-Affliliate Services (IAS) policy, standards, regulatory context and all lifecycle phases of an IAS initiative',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178791,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103183,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 3,
      label: 'Controls',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1403,
      label: 'Regulatory Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 557,
      label:
        'Able to identify and escalate policy exceptions such as SOX/SSAE16 assessment activities and On and Off boarding controls',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178792,
    isAsset: false,
    jobSkillPayGrade: {
      id: 103184,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 3,
      label: 'Controls',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1403,
      label: 'Regulatory Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 558,
      label:
        'Demonstrates the ability to comply with various Firm control initiatives, including testing internal controls over financial reporting for purposes of complying with Sarbanes Oxley requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 180067,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 3,
      label: 'Controls',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1383,
      label: 'RCSA (Risk & Control Self Assessment)',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 556,
      label: 'Able to support the operational risk assessment (RCSA) processes for the business',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178815,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 2,
      label: 'Business Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 188,
      label: 'Business Analysis',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 535,
      label:
        'Performs valid planning activities throughout the project life-cycle; utilize tools and techniques needed in order to plan, update or change the execution of business analysis tasks',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178817,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 3,
      label: 'Controls',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1126,
      label: 'NBIA  (New Business Initiative Approval)',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 553,
      label:
        'Ability to define the purpose and benefits of a New Business Initiative Approval including the roles and responsibilities of individuals involved in the NBIA program',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99476,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 2,
      label: 'Business Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 234,
      label: 'Change Readiness',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 538,
      label:
        'Skill in managing decisions regarding requirement delivery, prioritization, change control discussions, deployment and planning',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 99482,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 2,
      label: 'Business Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 201,
      label: 'Business Planning & Coordination',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 537,
      label:
        'Skill in planning and coordinating business activities; demonstrates the ability to identify and analyze a range of options or alternatives',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99486,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 3,
      label: 'Controls',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1698,
      label: 'User Tools',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 561,
      label:
        'Ability to utilize tools, reports and  feeds  to meet various controls related business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99487,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 35,
      label: 'Finance Mgmt & Reporting',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1548,
      label: 'Strategic Planning',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 569,
      label:
        'Provides technical advice, information and support to group standards team within area of discipline, adhering to group policy and control procedure',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99488,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 2,
      label: 'Business Process',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 36,
      label: 'Financial Services',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 966,
      label: 'Loan Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 577,
      label:
        'Knowledge of activity, balances, actual and or scheduled loan payments and is able to report in a controlled, timely and accurate manner within the required investor guidelines',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99752,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 3,
      label: 'Leadership & Management',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 4,
      label: 'Attracting & Developing Talent',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1608,
      label: 'Teaching Others',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 15,
      label:
        'Ability to prepare and deliver education and awareness briefings to ensure that systems, network, and data users are aware of and adhere to systems security policies and procedures',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1536,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32000,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 30,
      label: 'Conveys information that is well-organized and analytically sound',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1537,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32001,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 31,
      label: 'Demonstrates an understanding of culturally appropriate communication styles',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1538,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32002,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 32,
      label: 'Recognizes the need for additional information and asks questions to obtain it',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1539,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32003,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 33,
      label: 'Tailors communication style for diverse audiences',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1540,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32004,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 34,
      label: 'Utilizes civil discourse techniques to effectively nagivate conflict',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1542,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32006,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1355,
      label: 'Skilled in design of presentations using standard office software applications',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1543,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32007,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1356,
      label:
        'Ability to present to an audience group and articulate as well as visually present data in clear, concise and understandable terms',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1544,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32008,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1357,
      label:
        'Ability to create presentations (oral & written) across various levels     (peer, manager & stakeholders)',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1545,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32009,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1284,
      label: 'Presenting Effectively',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1358,
      label:
        'Ability to deliver in person & virtual presentations to senior management and stakeholders',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1546,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32010,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1752,
      label: 'Written Communication',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 35,
      label:
        'Adapts writing style and content to fit mode of messaging (sales, email, supervision, or short/long form reporting)',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1547,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32011,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1752,
      label: 'Written Communication',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 36,
      label:
        'Demonstrates a mastery of language structure and syntax through formal and informal writing',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1548,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32012,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1752,
      label: 'Written Communication',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 37,
      label: 'Utilizes a strong vocabulary for precise description',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1549,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32013,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1752,
      label: 'Written Communication',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1352,
      label: 'Ability to write with clarity, conciseness\n   and professionalism',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1550,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32014,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1752,
      label: 'Written Communication',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1353,
      label: 'Ability to write policies,\n   standards and/or procedures',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1551,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32015,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1752,
      label: 'Written Communication',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1354,
      label:
        'Ability to create executive summaries and\n   communications targeted towards senior\n   stakeholders',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1552,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32016,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 930,
      label: 'Builds strong partnerships by genuinely seeking out others opinions and ideas',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1553,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32017,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 39,
      label: 'Can gain the cooperation of others',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1554,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32018,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 40,
      label: 'Contributes actively in team activities, sharing experience and ideas',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1555,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32019,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 932,
      label: 'Draws upon colleagues expertise to deliver results',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1556,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32020,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 42,
      label: 'Establishes and maintains effective working relationships with others',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1557,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32021,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 43,
      label:
        'Gains agreements with peers and business partners to support ideas and uses sound rationale to explain the value of actions',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1558,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32022,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 44,
      label: 'Works co-operatively with other managers to achieve team goals',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1559,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32023,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 45,
      label: 'Works effectively with colleagues to accomplish goals and drive change',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1560,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32024,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 46,
      label:
        'Communicates appropriately with people at various levels and backgrounds, even if they have differing viewpoints',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1561,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32025,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 47,
      label:
        'Establishes relationships with and learns more about associates from other countries, cultures and backgrounds',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1562,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32026,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 48,
      label: 'Acts courteously and professionally when dealing with customers',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1563,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32027,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 49,
      label: 'Is courteous and helpful when responding to requests, even if urgent or unplanned',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1564,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32028,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 630,
      label: 'Establishes and facilitates effective working relationships with others',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1565,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32029,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 631,
      label: 'Ability to interact well with others to create positive outcomes',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1566,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32030,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 632,
      label:
        'Utilizes experience and knowledge to provide direction, support and guidance as necessary, in line with individuals’ skills and abilities',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1567,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32031,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1226,
      label: 'Personal Impact',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 50,
      label: 'Accepts responsibility for own decisions and actions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1568,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32032,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1226,
      label: 'Personal Impact',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 51,
      label: 'Maintains composure during pressured/stressful situations',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1569,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32033,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1226,
      label: 'Personal Impact',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 52,
      label: 'Takes action that goes beyond job requirements in order to achieve objectives',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1570,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32034,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1226,
      label: 'Personal Impact',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 636,
      label:
        'Demonstrates the ability to set  performance targets, and monitor progress against agreed quality and performance criteria',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1571,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32035,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1226,
      label: 'Personal Impact',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 637,
      label:
        'Skilled at leveraging expertise of employees within the group and other teams to improve overall performance',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1572,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32036,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1226,
      label: 'Personal Impact',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 638,
      label: 'Demonstrates the ability to take initiative',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1573,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32037,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 233,
      label: 'Change Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 618,
      label: 'Ability to view change and new situations as opportunities for growth',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 1574,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32038,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 233,
      label: 'Change Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 619,
      label: 'Knowledge in technological, organizational or operational change management',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 1575,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32039,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'asset',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 233,
      label: 'Change Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 620,
      label: 'Demonstrates the ability to lead through change in order to execute strategic plans',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 1576,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32040,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 326,
      label: 'Conflict Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 621,
      label: 'Ability to express your views clearly and firmly, but without aggression',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1577,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32041,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 326,
      label: 'Conflict Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 622,
      label:
        'Ability to identify personal or relational conflicts, and conflicts of interest that can occur within teams',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1578,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32042,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 326,
      label: 'Conflict Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 623,
      label:
        'Skilled in facilitating discussion between the conflicting parties and resolving conflicts using collaboration or negotiation strategies',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1579,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32043,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 547,
      label: 'Emotional Intelligence',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 624,
      label:
        'Ability to build trusting relationships and establish credibility with peers, mangers and stakeholders',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1580,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32044,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 547,
      label: 'Emotional Intelligence',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 625,
      label:
        "Capability to recognize your own and other people's emotions, and use emotional information to guide thinking and behavior",
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1581,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32045,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 809,
      label: 'Influencing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 627,
      label: 'Ability to align others with your perspective without force',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 1582,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32046,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 628,
      label:
        'Ability to think big picture and view issues/situations with a broad perspective, generate out of the box ideas and execution strategy around them',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1584,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32048,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1344,
      label: 'Knowledge of estimation techniques',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1585,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32049,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1345,
      label: 'Skill in creating and analyzing work breakdown structures',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1586,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32050,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1346,
      label: 'Ability to use network analysis to sequence tasks and creating Critical Paths',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1587,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32051,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1347,
      label: 'Skill in using precedence diagramming to schedule project work',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1588,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32052,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1348,
      label: 'Skill in creating, managing and validating realistic project schedules',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1589,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32053,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1349,
      label:
        'Organizes and requests internal resources and third party vendors in order to meet organizational expectations',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1590,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32054,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1350,
      label: 'Works through project plans to completion',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1591,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32055,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 77,
      label:
        'Works within the SLA or project plan to ensure that all projects are delivered on-time, within scope and within budget',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1592,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32056,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1351,
      label: 'Develops project plan, scope and objectives, involves all relevant stakeholders',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1593,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32057,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 74,
      label: 'Requests needed resources in order to meet organizational expectations',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1594,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32058,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 75,
      label: 'Actively works across function on broad projects to achieve broad objectives',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1595,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32059,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 76,
      label:
        'Is able to work through project plans to completion without continuous oversight or supervision',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1596,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32060,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 78,
      label: 'Effectively plans, prioritizes, and manages multiple projects',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1597,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32061,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 79,
      label: 'Organizes resources effectively to meet demands of projects',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1598,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32062,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 80,
      label: 'Maintains records as required for the project or service',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1599,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32063,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 81,
      label:
        'Knowledge of information security program management and project management principles and techniques',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1600,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32064,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 82,
      label: 'Knowledge of resource management principles and techniques',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1601,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32065,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 439,
      label: 'Decision Making',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 59,
      label:
        'Acts with highest level of integrity, generating trust and protecting client interests',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1602,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32066,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 439,
      label: 'Decision Making',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 60,
      label: 'Adheres to all Bank and FRS policies, bulletins and ethical guidelines',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1603,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32067,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 439,
      label: 'Decision Making',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 61,
      label: 'Follows set processes and methods',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1604,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32068,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 439,
      label: 'Decision Making',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 641,
      label:
        'Demonstrates the ability to make appropriate decisions on behalf of the customer/project requirement quickly and effectively',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1605,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32069,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 439,
      label: 'Decision Making',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 642,
      label: 'Demonstrates superior judgment and the ability to mitigate risk as appropriate',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1606,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32070,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 439,
      label: 'Decision Making',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 643,
      label:
        'Ability to make appropriate and fact based decisions with available information when under pressure and/or adverse conditions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1607,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32071,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 64,
      label: 'Accurately identifies and analyzes data and problems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1608,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32072,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 65,
      label: 'Demonstrates the ability to identify and evaluate problems or issues',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1609,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32073,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 66,
      label: 'Takes action to monitor and control time and costs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1610,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32074,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 67,
      label: 'Adapts effectively to changing priorities/assignments',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1611,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32075,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 68,
      label: 'Treats change and new situations as opportunities for learning or growth',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1612,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32076,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 69,
      label: 'Considers alternatives, implements decisions, and evaluates their effectiveness',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1613,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32077,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 70,
      label: 'Identifies problems and appreciates the issues required to resolve them',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1614,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32078,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 71,
      label: 'Resolves issues quickly and effectively, in a way that mitigates repeat inquiries',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1615,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32079,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 72,
      label: 'Follows-up to ensure satisfactory service and complete problem resolution',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1616,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32080,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 639,
      label: 'Ability to understand and break down complex problems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1617,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32081,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 647,
      label:
        'Approaches problems logically and with good judgment to ensure the appropriate outcome',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1618,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32082,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 648,
      label:
        'Demonstrates the ability to develop solutions by thinking outside the box to products and services    \n',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1620,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32084,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1304,
      label: 'Process Improvement',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 73,
      label: 'Identifies efficiency improvements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1621,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32085,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1304,
      label: 'Process Improvement',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 650,
      label: 'Ability to use continuous improvement to optimize, develop and deliver solutions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1623,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32087,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1304,
      label: 'Process Improvement',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 652,
      label: 'Identifies new processes to increase efficiency',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1624,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32088,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 89,
      label: 'Demonstrates urgency and timeliness when dealing with critical issues or people',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1625,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32089,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 90,
      label: 'Consistently meets expected results within deadlines',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1626,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32090,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 91,
      label: 'Gets all things done on a timely basis',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1627,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32091,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 92,
      label:
        'Places a priority on attending to the needs and inquiries from internal and external customers',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1628,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32092,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 93,
      label:
        'Remains self-disciplined and prevent irrelevant issues or distractions from interfering with the timely completion of important tasks',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1629,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32093,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 94,
      label: 'Tracks and directs own workflow efficiently',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1630,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32094,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 95,
      label: 'Uses time and energy optimally to ensure delivery of all products and services',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1631,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32095,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 662,
      label: 'Demonstrates urgency and timeliness when dealing with issues or people',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1632,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32096,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 663,
      label: 'Ability to remain focused to ensure the timely completion of tasks',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1633,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32097,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1649,
      label: 'Time Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 664,
      label:
        'Ability to manage multiple tasks simultaneously and delivery of products and services within deadlines',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1634,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32098,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 984,
      label: 'Managing Meetings',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 644,
      label:
        'Ability to successfully organize and assemble a group of people with a shared purpose, for the purpose of discussions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1635,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32099,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 984,
      label: 'Managing Meetings',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 645,
      label:
        'Capable of planning and organizing well-structured meetings where members of the team can accomplish a given objective',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1636,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32100,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 984,
      label: 'Managing Meetings',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 646,
      label:
        'Provides effective leadership and takes appropriate action where team deviates from agenda',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1637,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32101,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1431,
      label: 'Resource Management & Budgeting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 653,
      label: 'Understanding of financial control & budget management',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1638,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32102,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1431,
      label: 'Resource Management & Budgeting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 654,
      label: 'Implements and works within budget',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1639,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32103,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1431,
      label: 'Resource Management & Budgeting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 655,
      label:
        'Ability to manage and allocate resources to meet current and future needs of the business',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1640,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32104,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1431,
      label: 'Resource Management & Budgeting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 656,
      label: 'Ability to use budget and resources efficiently in order to maximize business impact',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1641,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32105,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 658,
      label: 'Develops an understanding of the firm’s strategies and where their role aligns',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1642,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32106,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 659,
      label: 'Ability to align work priorities to the appropriate corporate strategy(ies)',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1643,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32107,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 660,
      label:
        'Leverages deep understanding of the firm’s strategies to successfully drive initiatives',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1644,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32108,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'asset',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 661,
      label:
        'Drives and influences the strategic roadmap of the organization to enable business goals',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1645,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32109,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 666,
      label:
        'Develops meaningful relationships across teams and stakeholders to deliver against strategic priorities, and facilitates collaboration among disparate teams and leverages relationships',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1646,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32110,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 667,
      label:
        'Partners closely with stakeholders across the firm in order to influence priorities and establish meaningful relationships for the benefit of the team for alignment and collaboration',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1647,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32111,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 668,
      label:
        'Demonstrates the ability to establish and build long-term, strategic relationships with senior stakeholders in the largest client organizations (internal or external)',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1648,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32112,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 669,
      label:
        'Clearly determines and articulates the strategic approach to understanding stakeholder objectives and requirements to all members of the team',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1649,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32113,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1127,
      label: 'Negotiation',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 671,
      label: 'Ability to negotiate with others to develop compromises and solutions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1650,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32114,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1127,
      label: 'Negotiation',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 672,
      label: 'Ability to influence and persuade others to reach an agreement',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99491,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 289,
      label: 'Communication Overview',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 613,
      label: 'Knowledge of verbal and written communication best practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99492,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 290,
      label: 'Communication Styles',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 614,
      label:
        'Identifies different communication styles and is able to flex communication style depending on the audience',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99493,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 6,
      label: 'Communication',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 616,
      label: 'Facilitation',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 615,
      label: 'Ability to guide and direct a discussion',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178597,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82625,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 861,
      label: 'Interpersonal Skills',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1382,
      label:
        'Actively seeks ways to establish and maintain effective partnerships with colleagues/clients and consistently collaborates with others across the Firm',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178598,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82626,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 809,
      label: 'Influencing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1505,
      label:
        'Promotes partnership by fostering a shared purpose, trust and teamwork across teams, businesses, functions, regions, and levels',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 178599,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82627,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 809,
      label: 'Influencing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1504,
      label:
        'Encourages collaboration, information sharing and discussion to break down silos, create synergies and share best practices',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 178600,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82628,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 809,
      label: 'Influencing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1375,
      label: 'Ability to align others with your perspective by building a positive rapport',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 178601,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82629,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 809,
      label: 'Influencing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1376,
      label: 'Acts with authority and influences others to take appropriate action',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 178602,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82630,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 809,
      label: 'Influencing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1503,
      label:
        'Acts with authority and influences others to take appropriate action; drives decisions and manages through conflict and change',
    },
    skillYears: 7,
    user: {
      id: 17,
    },
  },
  {
    id: 178605,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82633,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 629,
      label:
        'Ability to generate new and useful ideas that impact the business and execute them, in order to deliver a solution that adds value',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178606,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82634,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1510,
      label: 'Stays abreast of trends and best practices and factors into approach',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178607,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82635,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1509,
      label: 'Drives/supports product development and efforts to grow revenue',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178608,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82636,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1379,
      label: 'Continually identifies process improvements and efficiencies',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178609,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82637,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1506,
      label: 'Challenges the status quo and drives change',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178610,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82638,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1508,
      label:
        'Creates a fail fast culture through sharing success & failure stories to encourage employees to think innovatively, take calculated risks, express their opinions and not be afraid of failure',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178612,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82640,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 7,
      label: 'Personal Effectiveness',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 826,
      label: 'Innovation & Creativity',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1507,
      label:
        'Continually identifies process improvements and efficiencies; eliminates unnecessary bureaucracy',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 178616,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82646,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1386,
      label:
        'Manages complex programs running across multiple lines of business containing multiple, projects and programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178617,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82647,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1387,
      label:
        'Works within the Service Level Agreement (SLA) or project plan to ensure that projects are delivered on-time, within scope and budget while ensuring relevant stakeholders are engaged',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178618,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82648,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1525,
      label:
        'Knowledge of organizational change management procedures such as how changes will be addressed and controlled in order to track and manage change',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178619,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82649,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1526,
      label:
        'Knowledge of organizational procurement procedures based on the project scope, budget, and schedule; in order to ensure that the required project resources will be available',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178620,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82650,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1527,
      label:
        'Knowledge of the quality management plan such as leveraging organizational quality management standards in order to prevent the occurrence of defects and control the cost of quality',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178621,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82651,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1528,
      label:
        'Skill in developing and executing the project schedule based on the approved project deliverables and milestones, scope, and resource management plans in order to manage timely completion of the project',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178622,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82652,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1529,
      label:
        'Skill in and assessing detailed project requirements, constraints, and assumptions with stakeholders based on the project charter roadmap, lessons learned, and by using requirement gathering techniques in order to establish detailed project deliverables',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178623,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82653,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1530,
      label:
        'Skill in capturing, analyzing, and managing lessons learned, using lessons learned management techniques in order to enable continuous improvement',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178624,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82654,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1531,
      label:
        'Skill in conducting kick-off meetings, communicating the start of the project key milestones, and other relevant information in order to inform and engage stakeholders and gain commitment',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178625,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82655,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1532,
      label:
        'Skill in defining the roles and responsibilities of the project team members in order to create a project organizational structure and provide guidance regarding how resources will be assigned and managed',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178626,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82656,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1533,
      label:
        'Skill in developing a scope management plan, based on the approved project scope and using scope management techniques, in order to define, maintain, and manage the scope of the project',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178627,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82657,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1534,
      label:
        'Skill in developing and executing the cost and benefits management plan based on the project scope, schedule, resources, approved project charter and other information, using appropriate project/business case data',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178628,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82658,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1535,
      label:
        "Skill in developing and executing the stakeholder management plan by analyzing needs, interests and potential impact in order to effectively manage stakeholders' expectations and manage them in project decisions",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178629,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82659,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1536,
      label:
        'Skill in developing the communications management plan based on the project organizational structure and stakeholder requirements, in order to define and manage the flow of project information',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178630,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82660,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1537,
      label:
        'Skill in identifying, analyzing, and prioritizing project risks; creating the risk register; and defining risk response strategies in order to manage uncertainty and opportunity throughout the project life cycle',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178631,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82661,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1538,
      label:
        'Skill in measuring and communicating project performance using appropriate tools and techniques in order to identify and quantify any variances and corrective actions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178632,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82662,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1539,
      label:
        'Skill in obtaining financial, legal, and administrative closure using generally accepted practices and policies in order to document and convey project closure',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178633,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82663,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1327,
      label: 'Project Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1540,
      label:
        'Skill in transforming the ownership of deliverables (including BAU activities and Benefits Realization) to the assigned stakeholders in accordance with the project plan in order to facilitate project closure',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178634,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82644,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1296,
      label: 'Problem Solving',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 649,
      label:
        'Demonstrates the ability to anticipate, break down, research and determine root cause, assess impact and resolve complex issues and problems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178635,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82645,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1304,
      label: 'Process Improvement',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 651,
      label:
        'Ability to objectively evaluate and identify new processes for recommended improvements on a continuous basis',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178636,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82664,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 657,
      label: 'Understands the fundamental business drivers and big picture view',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178637,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82665,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1543,
      label: 'Defines/drives the strategic direction of the business/function',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178638,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82666,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1541,
      label: 'Aligns group’s priorities to the broader business/function strategy',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178639,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82667,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1544,
      label: 'Drives/supports the execution of the business/function agenda',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178640,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82668,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 8,
      label: 'Productivity & Efficiency',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1550,
      label: 'Strategy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1542,
      label:
        'Considers the perspective of regulators, shareholders and our Board in planning and managing business plans; applies a global perspective in establishing direction for team',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178645,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82673,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1545,
      label:
        'Balances client / customer needs and best interests with those of the firm, while driving a strong control framework',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178646,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82674,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1546,
      label: 'Builds client relationships by promoting a client / customer centered organization',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178647,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82675,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1547,
      label:
        'Leverages experience and market knowledge to drive value-added dialogue; viewed as a trusted advisor and / or standout among competitors',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178648,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82676,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 261,
      label: 'Client Relationship Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1548,
      label: 'Proactively addresses the needs of clients / customers and the community',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178649,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82677,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 4,
      label: 'Professional Development',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 41,
      label: 'Sales & Relationship Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1127,
      label: 'Negotiation',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1551,
      label: 'Ability to negotiate with others to develop the right solutions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1787,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32250,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 0,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 10,
      label: 'Regulatory',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 916,
      label: 'Laws and Regulations',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 692,
      label:
        'Demonstrates an understanding of regulatory capital requirements from Basel Capital Accord (Basel I) in 1988 to the more sophisticated requirements of Basel II',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1788,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32251,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 0,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 10,
      label: 'Regulatory',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 916,
      label: 'Laws and Regulations',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 700,
      label:
        'Knowledge of laws and regulations governing the Banking and Financial Services industry',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1789,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32252,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 11,
      label: 'Risk',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 939,
      label: 'Line of Business Risk',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 712,
      label:
        'Maintains a sound control environment with the goal of preventing, identifying, escalating, measuring and timely remediation of control issues',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1790,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32253,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 11,
      label: 'Risk',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1415,
      label: 'Reputation Risk',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 713,
      label:
        'Ability to deliver a well controlled, resilient, compliant and secure technology environment',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1791,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32254,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 11,
      label: 'Risk',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1447,
      label: 'Risk Policy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 714,
      label:
        'Partners effectively with business and functional colleagues to guide consistent and rigorous adherence to the firm’s controls management framework and practices',
    },
    skillYears: 9,
    user: {
      id: 17,
    },
  },
  {
    id: 1792,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32255,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 11,
      label: 'Risk',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1447,
      label: 'Risk Policy',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 715,
      label:
        'Ensures procedures are end to end, up to date and align to risk policies and outline risk tolerances',
    },
    skillYears: 9,
    user: {
      id: 17,
    },
  },
  {
    id: 178789,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82678,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 5,
      label: 'Risk & Compliance',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 11,
      label: 'Risk',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1415,
      label: 'Reputation Risk',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 2048,
      label:
        'Maintains a sound control environment with the goal of preventing, identifying, escalating, measuring and timely remediation of reputational risk and control issues',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 178802,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1447,
      label:
        'Ability to coach team members in sprint planning, estimation, and use of related artifacts in the creation and maintenance of the product backlog',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178816,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 13,
      label: 'Business Intelligence & Reporting',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1090,
      label: 'Modeling and Simulation',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 124,
      label: 'Skill in developing data models',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 180050,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1446,
      label:
        'Knowledge of Product owner, Scrum master, Development team, and other roles (stakeholders, project manager, etc.) accountabilities',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 181744,
    isAsset: false,
    jobSkillPayGrade: {
      id: 32227,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 121,
      label:
        "Knowledge of the organization's enterprise information technology (IT) goals and objectives",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 180052,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1448,
      label: 'Knowledge of Agile estimation techniques',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 180065,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 771,
      label: 'Knowledge of continuous integration',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 180066,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1449,
      label: 'Knowledge of Agile Testing practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178795,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 766,
      label: 'Skill in determining when to use Agile versus Waterfall methodologies',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178799,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 770,
      label:
        'Team coaching abilities for Sprint planning, the use of their related artefacts and product owners in the creation and maintenance of product backlog',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178814,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1508,
      label: 'Software Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 854,
      label: 'Ability to describe of the key features and driving principles of DevOPS',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178812,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1508,
      label: 'Software Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 852,
      label: 'Skilled in code review techniques',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178805,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 845,
      label:
        'Skill in using Scripting to achieve desired functionality while adhering to firm standards, tools and best practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178804,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 844,
      label:
        'Ability to design, code execute, test, correct, and interpret results of Scripts and scripting programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178806,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 846,
      label:
        'Knowledge of security protocols, standards and methods within Scripts to meet programming compliance with firms IT control policies and governance',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178807,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 847,
      label: 'Ability to apply Agile development methodologies for programming Scripting solutions',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178808,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1508,
      label: 'Software Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 848,
      label: 'Skilled in Continuous Delivery practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178809,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1508,
      label: 'Software Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 849,
      label: 'Skilled in Test-Driven Development',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178810,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1508,
      label: 'Software Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 850,
      label:
        'Skill to document all program functionality and collaborate in reviews of specifications',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178801,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 772,
      label: 'Knowledge of Product Owner accountabilities',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178811,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1508,
      label: 'Software Practices',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 851,
      label:
        'Ability to collaborate and influence the best usage of technologies/trends to support business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178740,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82698,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1401,
      label: 'Ability to influence the infra design to best optimized the total system/systems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178742,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82700,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1560,
      label:
        'Knowledge of software/hardware related information technology (IT) security principles and methods (e.g., modularization, layering, abstraction, data hiding, simplicity/minimization)',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178741,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82699,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1559,
      label:
        "Ability to understand and operate within the governance processes for the organization's business architecture",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178739,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82697,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1558,
      label:
        'Ability to influence solutions to meet the business needs including build vs. buy vs. download analysis',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178737,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82695,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 401,
      label: 'Data Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1554,
      label:
        'Skill in development of logical and physical data models & translating logical to physical design',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178736,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82694,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 401,
      label: 'Data Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1553,
      label: 'Ability to troubleshoot issues with data model',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178735,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82693,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1552,
      label:
        'Knowledge of reference architecture; including business, application, data, and technology across the organization',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178733,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82691,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1403,
      label:
        'Ability to communicate application architecture and its relation to the technology roadmap',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178732,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82690,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1402,
      label:
        'Ability to recommend solutions to meet the business needs including build vs. buy vs. download (open source) analysis',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178729,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82687,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1400,
      label:
        'Skill in implementing solutions which have an ease of maintenance, etc..(modular, error logging, etc.)  Skill in building scalable, fault tolerant infrastructure software or distributed systems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178728,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82686,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1399,
      label: 'Knowledge of application usability and common use patterns across the organization',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178743,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82701,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1561,
      label:
        'Skill in implementing solutions which have an ease of maintenance, etc..  Skill in deploying scalable, fault tolerant infrastructure or distributed systems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178744,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82765,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1567,
      label:
        'Drives a robust risk and controls environment by managing and conducting new and existing business in alignment with risk/control expectations, procedures and processes including but not limited to BSA/AML/KYC and fiduciary obligations',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178745,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82766,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1565,
      label:
        'Develops and maintains LOB-specific KYC procedures in compliance with the firm’s KYC Program and addresses resource, process, system and training needs',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178755,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82776,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1563,
      label:
        'Actively partners across LOBs/functions to drive the risk/control agenda; participates in appropriate control forums',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178754,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82775,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1572,
      label:
        'Remediates regulatory issues and findings (e.g., MRA) in a timely and sustainable manner to prevent recurrence',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178753,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82774,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1568,
      label:
        'Fosters an environment where risk/control issues are escalated and trends are anticipated and identified',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178752,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82773,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1573,
      label:
        'Utilizes knowledge and lessons learned to proactively consider potential broader risks to the firm, clients/customers and shareholders',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178751,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82772,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1569,
      label:
        'Identifies, Escalates & Remediates Issues by understanding and applying knowledge of role-related risks (e.g., Market, Credit, Reputational, Operational, Fiduciary, Investment, Compliance, Conduct)',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178750,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82771,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1410,
      label: 'Actively partners across LOBs/functions to drive the risk/control agenda',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178749,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82770,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1571,
      label:
        'Pursues root-cause analysis of risk and compliance issues and client/customer complaints',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178748,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82769,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1570,
      label:
        'Operates within established risk parameters/tolerances, and meets internal/external risk and compliance obligations, including completion of required training for self and team',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178747,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82768,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1564,
      label:
        'Demonstrates expected risk and control behaviors by holding self and others to the letter and spirit of the firm’s Code of Conduct, its policies and related local/international laws/regulations',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178746,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82767,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1566,
      label:
        'Develops/supports preventative processes to strengthen the control framework and monitors for potential process failures or weaknesses',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178727,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82685,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1398,
      label:
        'Skill in selecting the appropriate resilience and performance requirements with consideration of the total cost of ownership',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1651,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32115,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 757,
      label:
        'Knowledge of Cloud Services: different providers, their products and internal vs external comparison',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 1652,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32116,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 758,
      label: 'Skill in Cloud application design',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 1653,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32117,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 759,
      label: 'Knowledge of  Cloud development infrastructure changes',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 1654,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32118,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 765,
      label: 'Knowledge of the values, principles & benefits of Agile',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1656,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32120,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 767,
      label: 'Knowledge of the key features, roles and driving principles of Scrum',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1658,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32122,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 55,
      label: 'Agile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 769,
      label: 'Knowledge of the key features and driving principles of Kanban',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1662,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32126,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 308,
      label: 'Methodologies & Practices',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1718,
      label: 'Waterfall',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 773,
      label: 'Knowledge of the Waterfall the SDLC methodology',
    },
    skillYears: 10,
    user: {
      id: 17,
    },
  },
  {
    id: 1663,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32127,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1091,
      label: 'Modelling',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 796,
      label:
        'Ability to use low-fidelity modelling as an aid to achieving shared understanding for a team',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1665,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32129,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1091,
      label: 'Modelling',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 798,
      label: 'Ability to read and create Unified Modelling Language (UML) Diagrams',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1667,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32131,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1417,
      label: 'Requirements',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 800,
      label: 'Skilled in defining acceptance criteria using examples (spec-by-example)',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1668,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32132,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1417,
      label: 'Requirements',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 801,
      label: 'Ability to understand customer requirements',
    },
    skillYears: 12,
    user: {
      id: 17,
    },
  },
  {
    id: 1670,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32134,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1697,
      label: 'User Experience',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 803,
      label: 'Knowledge of User Experience (UX) deliverables',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1671,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32135,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1697,
      label: 'User Experience',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 804,
      label: 'Knowledge of Human-Computer-Interaction Design',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1672,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32136,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1697,
      label: 'User Experience',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 805,
      label: 'Knowledge of design principles for web pages & mobile applications',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1673,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32137,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 19,
      label: 'Software Analysis',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1697,
      label: 'User Experience',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 806,
      label: 'Ability to do competitive analysis of UX options',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1674,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32138,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 466,
      label: 'Developer Tools',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 807,
      label:
        'Ability to use tools for Development (IDEs), Source Code Management, Build, Packaging, Deployment etc',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 1678,
    isAsset: true,
    jobSkillPayGrade: {
      id: 32142,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 904,
      label: 'Languages – C#/.Net',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 811,
      label: 'Knowledge of .NET framework and class library for developing .NET code',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1686,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32150,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 905,
      label: 'Languages – Java',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 819,
      label:
        "Ability to apply java-based security protocols and standards while maintaining firm's compliance requirements, control policies and governance",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1696,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32160,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 829,
      label:
        'Knowledge of application fundamentals for developing code compatible with mobile devices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1697,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32161,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 830,
      label: 'Knowledge of usability and UX fundamentals for mobile devices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1698,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32162,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 831,
      label: 'Knowledge of mobile application architecture/framework for development',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1699,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32163,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 832,
      label:
        'Skill in reviewing mobile application software to support access to enterprise systems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1700,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32164,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 833,
      label:
        'Ability to code/modify/reuse mobile application software, including the UI considerations and constraints',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1702,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32166,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 835,
      label:
        'Knowledge of mobile application editors and debugging tool for testing code compatible with mobile devices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1703,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32167,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1085,
      label: 'Mobile',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 836,
      label: 'Ability to test, interpret and correct execution results of mobile applications',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1704,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32168,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1162,
      label: 'Programming Essentials',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 837,
      label: 'Knowledge of programming fundamentals',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1708,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32172,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1200,
      label: 'Languages – Other',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1698,
      label: 'Ability to design programs to meet business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1709,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32173,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1200,
      label: 'Languages – Other',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1699,
      label: 'Ability to develop programs which follow industry and JPMC practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1710,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32174,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'asset',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1200,
      label: 'Languages – Other',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1700,
      label: 'Ability to package/release programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1723,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32187,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 856,
      label:
        'Knowledge of web/digital editors and debugging tool for developing code for web/digital applications',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1724,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32188,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 857,
      label:
        'Skill in reviewing web/digital code, programs and scripts to understand current functionality and execution results',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1725,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32189,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 858,
      label:
        'Ability to modify/reuse existing web/digital code, programs and scripts to create desired functionality and execution results',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1726,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32190,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 834,
      label:
        'Ability to test, interpret and correct execution results of Web/Digital applications, programs and scripts',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1727,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32191,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 859,
      label:
        "Ability to achieve desired functionality for web/digital applications while strictly adhering to firm's standards, tools and best practices",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1728,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32192,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 860,
      label:
        'Knowledge of web/digital programming security protocols, standards and methods to address compliance requirements, control policies and governance',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1729,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32193,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 861,
      label: 'Knowledge of responsive web design strategy for building websites',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1730,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32194,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 862,
      label:
        'Knowledge of web accessibility guidelines to test the accessibility of site or application by people with disabilities',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1731,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32195,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1728,
      label: 'Web/Digital',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 863,
      label:
        'Skill in assistive technologies & tools to build products which are easy to interact for people with disabilities',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1754,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32218,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 4,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 21,
      label: 'Software Testing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1684,
      label: 'Unit Testing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 885,
      label: 'Knowledge of unit testing fundamentals and best practices',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1755,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32219,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 4,
      skillLevel: 'required',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 21,
      label: 'Software Testing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1684,
      label: 'Unit Testing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 886,
      label: 'Ability to design, execute and interpret unit test cases',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 1758,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32237,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 4,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 21,
      label: 'Software Testing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1695,
      label: 'Functional Testing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 889,
      label: 'Ability to apply user acceptance testing fundamentals and best practices',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1759,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32238,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 4,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 21,
      label: 'Software Testing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1695,
      label: 'Functional Testing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 890,
      label: 'Ability to analyze the user acceptance test results including issues and risks',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1760,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32239,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 4,
      skillLevel: 'required',
    },
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 21,
      label: 'Software Testing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1695,
      label: 'Functional Testing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 891,
      label:
        'Ability to design, execute and interpret user acceptance test cases & create test scripts and supporting data',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 1761,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32222,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 116,
      label: 'Knowledge of enterprise messaging systems and associated software',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1762,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32223,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 117,
      label:
        'Knowledge of security architecture concepts and enterprise architecture reference models (e.g., Zackman, Federal Enterprise Architecture [FEA])',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1763,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32224,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 118,
      label:
        'Knowledge of security models (e.g., Bell-LaPadula model, Biba integrity model, Clark-Wilson integrity model)',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1764,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32225,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 119,
      label:
        "Knowledge of the methods, standards, and approaches for describing, analyzing, and documenting an organization's enterprise information technology (IT) architecture (e.g., Open Group Architecture Framework [TOGAF], Department of Defense Architecture Framework [DODAF], Federal Enterprise Architecture Framework [FEAF])",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1765,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32226,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 120,
      label: 'Knowledge of the nature and function of the relevant information structure',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1767,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32228,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 721,
      label:
        'Knowledge of distributed architecture and factors that affect distributed software designs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1768,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32229,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 722,
      label:
        'Knowledge of Enterprise architecture(EA) framework & role played by EA in strategy formulation & execution',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1769,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32230,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 98,
      label: 'Application Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 716,
      label:
        'Demonstrates strong software and business systems design, analysis, development and support skills at all levels',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1770,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32231,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 401,
      label: 'Data Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 718,
      label: 'Knowledge of Data Architecture principles',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1771,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32232,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 401,
      label: 'Data Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 719,
      label:
        'Identifies missing or incorrect data, carries out analysis to identify root cause, articulates and escalates it to the appropriate data owner(s)',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1772,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32233,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 401,
      label: 'Data Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 720,
      label:
        'Demonstrates strong software and business systems design and database, analysis, development and support skills at all levels',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1773,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32234,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 723,
      label: 'Describes the Fortress architecture approach and can compare it with the Actor Model',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1774,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32235,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 724,
      label: "Explains and follows the organization's business architecture governance processes",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1775,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32236,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'required',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 822,
      label: 'Infrastructure Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 725,
      label:
        'Understands how the Firm’s business capabilities and data entity taxonomies relate to Bank on a Page',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 1776,
    isAsset: null,
    jobSkillPayGrade: {
      id: 32256,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'required',
    },
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 43,
      label: 'Business Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1441,
      label: 'Risk & Controls',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 738,
      label: 'Knowledge and application of Rules of the Road',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 99457,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 13,
      label: 'Business Intelligence & Reporting',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 196,
      label: 'Business Intelligence & Reporting Tools',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 741,
      label:
        'Demonstrates the ability to identify prioritize, and standardize tools and solutions supporting that architecture',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99458,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 13,
      label: 'Business Intelligence & Reporting',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 399,
      label: 'Data Analysis',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 742,
      label:
        'Applies data analysis and data modelling techniques to establish, modify or maintain a data structure and its associated components (entity descriptions, relationship descriptions, attribute definitions)',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99459,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 16,
      label: 'Database & Data Warehousing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 407,
      label: 'Data Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 271,
      label: 'Knowledge of data administration and data standardization policies and standards',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99460,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 16,
      label: 'Database & Data Warehousing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 407,
      label: 'Data Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 278,
      label: 'Skill in data mining techniques',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99461,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 16,
      label: 'Database & Data Warehousing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 407,
      label: 'Data Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 279,
      label: 'Skill in developing data dictionaries',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99462,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 16,
      label: 'Database & Data Warehousing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 407,
      label: 'Data Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 280,
      label: 'Skill in developing data repositories',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99463,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1071,
      label: 'Microsoft Visio',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 783,
      label:
        'Skill in using MS Visio to create visual diagrams, including flowcharts, process maps, network diagrams, organization charts, etc',
    },
    skillYears: 10,
    user: {
      id: 17,
    },
  },
  {
    id: 99464,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1061,
      label: 'Microsoft Excel',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 775,
      label:
        'Skill in enhancing spreadsheets with formatting, sorting, filters, using functions, analyzing data using pivot tables and charts',
    },
    skillYears: 13,
    user: {
      id: 17,
    },
  },
  {
    id: 99465,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1064,
      label: 'Microsoft InfoPath',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 777,
      label:
        'Ability to design, distribute and customize a SharePoint List form using InfoPath as well as integrate InfoPath forms into custom workflows',
    },
    skillYears: 4,
    user: {
      id: 17,
    },
  },
  {
    id: 99466,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1066,
      label: 'Microsoft Outlook',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 778,
      label:
        'Skill in using Outlook features including email, calendar, tasks, contacts, email signatures, RSS feeds etc',
    },
    skillYears: 11,
    user: {
      id: 17,
    },
  },
  {
    id: 99467,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1067,
      label: 'Microsoft PowerPoint',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 779,
      label:
        'Skill in designing professional & effective presentations using features like Pictures, SmartArt, Charts, Transitions, Animations, Tables, Spreadsheets, Videos etc',
    },
    skillYears: 13,
    user: {
      id: 17,
    },
  },
  {
    id: 99468,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1068,
      label: 'Microsoft Project',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 780,
      label:
        'Skill in using MS Project to create project plan, work with tasks, set deadlines, manage resources and resource calendar as well as manage budget and timeline',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 99469,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1070,
      label: 'Microsoft SharePoint',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 782,
      label:
        'Skill in using SharePoint sites well as creating & managing lists, libraries, task list, document library, Surveys,  blogs, wikis, etc',
    },
    skillYears: 8,
    user: {
      id: 17,
    },
  },
  {
    id: 99470,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '4',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1072,
      label: 'Microsoft Word',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 784,
      label:
        'Skill in using MS Word to format documents, display data using tables and charts, use illustrations, use templates, styles, forms and perform a mail merge',
    },
    skillYears: 13,
    user: {
      id: 17,
    },
  },
  {
    id: 99471,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 45,
      label: 'Microsoft Office Applications',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1060,
      label: 'Microsoft Access',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 774,
      label:
        'Ability to build tables and forms to enter data and design queries and reports to retrieve and view data in MS Access',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 99472,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '3',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 13,
      label: 'Business Intelligence & Reporting',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1090,
      label: 'Modeling and Simulation',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 123,
      label: 'Skill in design modeling and building use cases (e.g., unified modeling language)',
    },
    skillYears: 5,
    user: {
      id: 17,
    },
  },
  {
    id: 99474,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 13,
      label: 'Business Intelligence & Reporting',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 419,
      label: 'Data Visualization',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 743,
      label: 'Ability to design and develop data visualization dashboards',
    },
    skillYears: 3,
    user: {
      id: 17,
    },
  },
  {
    id: 99475,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 21,
      label: 'Software Testing',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1369,
      label: 'Non-Functional Testing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 874,
      label: 'Knowledge of tools used for code analysis & code coverage',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99478,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 14,
      label: 'Change Configuration and Release Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 325,
      label: 'Configuration Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 125,
      label: 'Knowledge of collection management processes, capabilities, and limitations',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 99480,
    isAsset: null,
    jobSkillPayGrade: null,
    skillProficiency: '0',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 14,
      label: 'Change Configuration and Release Management',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 792,
      label: 'Identity Management',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 132,
      label:
        'Knowledge of organizational information technology (IT) user security policies (e.g., account creation, password rules, access control)',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178650,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82702,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1426,
      label:
        'Knowledge of Cloud providers such as Amazon AWS, Microsoft Azure, Google Cloud Platform services etc',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178651,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82703,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1427,
      label: 'Experience building cloud native frameworks / applications',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178652,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82704,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1428,
      label: 'Knowledge of Docker Containers',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178653,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82705,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1429,
      label: 'Knowledge of the similarities and differences between Public, Hybrid/Private Cloud',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178654,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82706,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1430,
      label:
        'Skill to develop tools, automation, and design contributions to the portfolio of cloud products',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178655,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82707,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1431,
      label:
        'Ability to development mechanisms for deployment, monitoring and maintenance for cloud native applications',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178656,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82708,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1432,
      label: 'Skill in working with and creating service-oriented architecture (SOA)/Microservices',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178657,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82709,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1611,
      label: 'Ability in using REST services/creating RESTful APIs',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178659,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82711,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1612,
      label: 'Deployment, upgrade, configuration, and maintenance of the cloud platform',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178660,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82712,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1613,
      label: 'Knowledge of Platform as a Service (PaaS)',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178661,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82713,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1614,
      label:
        'Knowledge in modern compute technologies (e.g., virtualization, cloud, object stores)',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178662,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82714,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1615,
      label:
        'Knowledge of tools, automation, and design contributions to the portfolio of cloud products',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178663,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82715,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1616,
      label: 'Knowledge of Cloud application design',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178664,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82716,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1617,
      label: 'Knowledge of the Application Platform and the Container Platform',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178665,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82717,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1618,
      label:
        'Knowledge of how to deliver cloud management software, cluster management software, or other platform level technology',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178666,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82718,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1619,
      label: 'Knowledge of integration between corporate services and external providers',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178667,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82719,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1620,
      label:
        'Knowledge of mechanisms for deployment, monitoring and maintenance for cloud native applications',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178668,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82720,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1621,
      label: 'Knowledge of orchestration tools',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178669,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82721,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1622,
      label: 'Knowledge of support tools for control, instrumentation and investigation',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178670,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82722,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1623,
      label: 'Knowledge of virtualization technologies',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178671,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82723,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 17,
      label: 'Infrastructure & Networking',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 266,
      label: 'Cloud Computing',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1624,
      label:
        'Knowledge of working with and creating service-oriented architecture (SOA)/Microservices',
    },
    skillYears: 2,
    user: {
      id: 17,
    },
  },
  {
    id: 178678,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82725,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 904,
      label: 'Languages – C#/.Net',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1676,
      label: 'Ability to design C#/.Net programs to meet business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178679,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82726,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 904,
      label: 'Languages – C#/.Net',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1677,
      label: 'Ability to develop C#/.Net programs which follow industry and JPMC practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178680,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82727,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '2',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 904,
      label: 'Languages – C#/.Net',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1678,
      label: 'Ability to package/release C#/.Net programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178683,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82730,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 905,
      label: 'Languages – Java',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1681,
      label: 'Ability to design Java programs to meet business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178684,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82731,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 905,
      label: 'Languages – Java',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1682,
      label: 'Ability to develop Java programs which follow industry and JPMC practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178685,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82732,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 905,
      label: 'Languages – Java',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1683,
      label: 'Ability to package/release Java programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178688,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82735,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 906,
      label: 'Languages – PHP',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1686,
      label: 'Ability to design PHP programs to meet business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178689,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82736,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 906,
      label: 'Languages – PHP',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1687,
      label: 'Ability to develop PHP programs which follow industry and JPMC practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178690,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82737,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 906,
      label: 'Languages – PHP',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1688,
      label: 'Ability to package/release PHP programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178693,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82740,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 907,
      label: 'Languages – Python',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1691,
      label: 'Ability to design Python programs to meet business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178694,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82741,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 907,
      label: 'Languages – Python',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1692,
      label: 'Ability to develop Python programs which follow industry and JPMC practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178695,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82742,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 907,
      label: 'Languages – Python',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1693,
      label: 'Ability to package/release Python programs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178703,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82747,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 2093,
      label: 'Ability to design scripts to meet business requirements',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178704,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82748,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 2094,
      label: 'Ability to develop scripts which follow industry and JPMC practices',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178705,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82749,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 3,
      skillLevel: 'na',
    },
    skillProficiency: '1',
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 20,
      label: 'Software Programming',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 1467,
      label: 'Languages – Scripting',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 2095,
      label: 'Ability to package/release scripts',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178721,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82679,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1010,
      label: 'Knowledge of security architecture frameworks',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178722,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82680,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 333,
      label: 'Knowledge of the enterprise information technology (IT) architecture',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178724,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82682,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1555,
      label: 'Ability to influence architecture and design across multiple systems',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178725,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82683,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1556,
      label:
        'Knowledge of distributed architecture and factors that affect hardware/software designs',
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
  {
    id: 178726,
    isAsset: false,
    jobSkillPayGrade: {
      id: 82684,
      jobPayGrade: {
        id: 169,
        payGrade: {
          class: 'iq4.model.PayGrade',
          code: '603',
          id: 6,
          label: '603',
          payGradeType: 'Hourly',
        },
      },
      proficiency: 2,
      skillLevel: 'na',
    },
    skillProficiency: null,
    skillTier1: {
      class: 'iq4.model.SkillTier1',
      id: 6,
      label: 'Technology',
    },
    skillTier2: {
      class: 'iq4.model.SkillTier2',
      id: 12,
      label: 'Architecture & Design',
    },
    skillTier3: {
      class: 'iq4.model.SkillTier3',
      id: 569,
      label: 'Enterprise Architecture',
      lmsLinks: null,
    },
    skillTier4: {
      class: 'iq4.model.SkillTier4',
      id: 1557,
      label:
        "Knowledge of the methods, standards, and approaches for describing, analyzing, and documenting an organization's enterprise information technology (IT) architecture",
    },
    skillYears: 0,
    user: {
      id: 17,
    },
  },
];
