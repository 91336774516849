import React from 'react';
import PropTypes from 'prop-types';
import './Counter.scss';

export const Counter = ({ count, label }) => (
  <div className="iq4-counter__container">
    <span className="iq4-counter__count">{count}</span>
    <span className="iq4-counter__label">{label}</span>
  </div>
);

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};
