export default {
  en: {
    'onboarding.resume.title': 'Welcome',
    'onboarding.resume.greeting': 'Hi Robert',
    'onboarding.resume.intro':
      'We are iQ4. We are transforming the learning economy and we are going to transform your career.',
    'onboarding.resume.bullet1':
      '30% of students acquire employment in cybersecurity after using this platform',
    'onboarding.resume.bullet2': 'Integrate and stay informed with emerging technologies',
    'onboarding.resume.bullet3': 'Use iQ4’s universal skills passport to bridge the skills gap.',
    'onboarding.resume.testimonal':
      'iQ4 has harnessed industry engagement and developed a scalable applied learning approach. Dilworth Paxson, LLP',
    'onboarding.resume.attestent': 'Dilworth Paxton, LLP',
    'form.validation.required': 'This field is required',
    'form.validation.minLength': 'Needs a min length of {value}',
    'form.validation.maxLength': 'The max length of this field is {value}',
    'profile.addAnother.label': 'Add Another',
  },
};
