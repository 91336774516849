export const selectIsUISubmittingForm = (state) => state.ui.isSubmittingForm;

export const selectIsLoadingFilterResults = (state) => state.ui.isLoadingFilterResults;

export const selectSkillExpandingId = (state) => state.ui.skillExpandingId;

export const selectSkillExpandedId = (state) => state.ui.skillExpandedId;

export const selectShowJobFamilyExplanation = (state) => state.ui.showJobFamilyExplanation;

export const selectShowJobRoleExplanation = (state) => state.ui.showJobRoleExplanation;

export const selectDisplayTour = (state) => state.ui.displayTour;

export const selectDisplayPathwayDetailTour = (state) => state.ui.displayPathwayDetailTour;
export const selectDisplayMissingSkillsTour = (state) => state.ui.displayMissingSkillsTour;

export const selectIsSearchingSkill = (state) => state.ui.isSearchingSkill;

export const selectCurrentTourSteps = (state) => state.ui.currentTourSteps;
export const selectDisplaySecondPathwayDetailTour = (state) =>
  state.ui.displaySecondPathwayDetailTour;

export const selectIsResumeParserEnabled = (state) => state.ui.isResumeParserEnabled;

export const selectCameFromProfile = (state) => state.ui.cameFromProfile;
