import React from 'react';
import './CountIndicator.scss';

export const CountIndicator = ({ count, variation, displayZero = true }) => {
  if (count === 0 && !displayZero) return null;

  return (
    <div className="iq4-count-indicator">
      <span className={`iq4-count-indicator__count ${variation}`}>{count}</span>
    </div>
  );
};
