import React, { useRef, useState } from 'react';
import FocusLock, { AutoFocusInside } from 'react-focus-lock';
import { SimpleIcon, Drawer, Pill } from 'common/components';
import { CATEGORY_LABELS } from '../../utils';

export function AtAGlanceHelp() {
  const [showHelp, setShowHelp] = useState(false);

  function toggleHelp(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowHelp(!showHelp);
  }

  return (
    <>
      <div
        className="iq4-t-shape__info"
        role="button"
        tabIndex={0}
        onClick={toggleHelp}
        onKeyPress={toggleHelp}
        aria-label="Get more info about the At a glance section"
      >
        <SimpleIcon name="infoDefault" />
        <div className="iq4-t-shape__info--text">What's this?</div>
      </div>
      {showHelp && (
        <Drawer
          anchor="right"
          variant="persistent"
          isOpen={true}
          classes={{
            root: 'iq4-tshape-page__drawer',
            paper: 'iq4-tshape-help-page__drawer-paper',
          }}
        >
          <FocusLock returnFocus={true}>
            <div style={{ paddingTop: '40px' }}>
              <span
                aria-live="polite"
                role="alert"
                className="sr-only"
                aria-label="At a glance help drawer opened"
              ></span>
              <AutoFocusInside>
                <button
                  aria-label="Close the at a glance help drawer"
                  className="iq4-tshape-page__drawer__dismiss-button"
                  onClick={() => setShowHelp(false)}
                >
                  <SimpleIcon name="close" />
                </button>
              </AutoFocusInside>
              <div className="iq4-tshape-page__drawer__role-container">
                <div className="iq4-tshape-page__drawer__role-container--title-2">At A Glance</div>
                <div className="iq4-tshape-page__drawer__role-container--divider" />
                <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
                <p style={{ fontSize: '0.875rem' }}>
                  At a glance offers a snapshot of your total skills view and explores your skills
                  through categories, providing a quick overview of key competencies. <br />
                  <br /> <strong>Total number of skills:</strong> This Total number of skills view
                  on our offers you a comprehensive snapshot of your skills by consolidating data
                  from education, work, projects, and extracurricular activities.
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: '2rem' }}
                >
                  WHAT ARE SKILL CATEGORIES?
                </div>
                <div className="iq4-explanation--content iq4-explanation--no-border">
                  Skills are divided into three categories:
                  <br />
                </div>
                <p style={{ fontSize: '0.875rem' }}>
                  <b>{CATEGORY_LABELS['professional_skills']}</b> are unique abilities and knowledge
                  needed for a particular occupation, that includes a distinct range of skills that
                  are crucial for success on the job.
                  <br />
                  <div className="iq4__flex-row">
                    Example: ARCHITECTURE
                    <Pill
                      label="PROFESSIONAL SKILLS"
                      type="professional skills"
                      className="iq4__margin-left"
                    />
                  </div>
                </p>
                <p style={{ fontSize: '0.875rem' }}>
                  <b>{CATEGORY_LABELS['it_skills']}</b> represent the ability to effectively use a
                  variety of software and tools on the job.
                  <br />
                  <div className="iq4__flex-row">
                    Example: SQL Databases
                    <Pill label="IT SKILLS" type="it skills" className="iq4__margin-left" />
                  </div>
                </p>
                <p style={{ fontSize: '0.875rem' }}>
                  <b>{CATEGORY_LABELS['soft_skills']}</b> are the personal qualities and behaviors
                  crucial for professional success. These skills can often be transferred and
                  applied across various job roles and industries.
                  <br />
                  <div className="iq4__flex-row">
                    Example: LEADERSHIP
                    <Pill label="SOFT SKILLS" type="soft skills" className="iq4__margin-left" />
                  </div>
                </p>
              </div>
            </div>
          </FocusLock>
        </Drawer>
      )}
    </>
  );
}
