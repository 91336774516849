import React from 'react';
import { SimpleIcon } from 'common/components';
import './OnboardingHeader.scss';

export const OnboardingHeader = ({ icon, title, isMainHeading = false }) => (
  <div className="iq4-onboarding-header">
    <SimpleIcon className="iq4-onboarding-header__icon" name={icon} role="presentation" hidden />
    {isMainHeading && <h1 className="iq4-onboarding-header__title">{title}</h1>}
    {!isMainHeading && <h2 className="iq4-onboarding-header__title">{title}</h2>}
  </div>
);
