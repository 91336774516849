import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import { ProfileResumeParse } from 'components/Profile';

import {
  Container,
  Topbar,
  ResumeParserModal,
  Button,
  Footer,
  SimpleIcon,
} from 'common/components';

import { selectFeatures } from 'store/features/config/selectors';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';

import './SettingsPage.scss';
import { TextField } from '@material-ui/core';
import { selectIsChangingPassword } from 'common/store/features/session/selectors';
import {
  changePasswordReq,
  toggleIsLoggingIn,
} from '../../common/store/features/session/sessionSlice';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';

/**
 * Main Wrapper for the Manage Skills page
 * @returns {*}
 * @constructor
 */
export const SettingsPage = () => {
  const dispatch = useDispatch();

  const [showResumeParser, setShowResumeParser] = useState(false);
  const [activeResumeParserModal, setActiveResumeParserModal] = useState(false);
  const [activeOption, setActiveOption] = useState('password');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('Your Password has been changed!');
  const [showAlert, setShowAlert] = useState(false);

  const isChangingPassword = useSelector(selectIsChangingPassword);
  const history = useHistory();

  const [hasEightCharacters, setHasEightCharacters] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasNumbers, setHasNumbers] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const checkPassword = (password) => {
    // Check password against the conditions
    setHasEightCharacters(password.length >= 8);
    setHasUppercase(/[A-Z]/.test(password));
    setHasLowercase(/[a-z]/.test(password));
    setHasNumbers(/\d/.test(password));
    setHasSpecialChar(/[!@#?\]]/.test(password));

    return hasEightCharacters && hasUppercase && hasLowercase && hasNumbers && hasSpecialChar;
  };

  const currentPasswordRef = useRef(currentPassword);
  const newPasswordRef = useRef(newPassword);
  const confirmNewPasswordRef = useRef(confirmNewPassword);

  const handleCurrentPasswordChange = (e) => {
    currentPasswordRef.current = e.target.value;
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    newPasswordRef.current = e.target.value;
    setNewPassword(e.target.value);
  };
  const handleConfirmNewPasswordChange = (e) => {
    confirmNewPasswordRef.current = e.target.value;
    setConfirmNewPassword(e.target.value);
  };

  function clearForm() {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    history.goBack();
  }

  const changePassword = async () => {
    const result = await dispatch(
      changePasswordReq({
        currentPassword,
        password1: newPassword,
        password2: confirmNewPassword,
      }),
    );

    // check if result.type contains 'rejected'
    if (result?.type?.includes('rejected')) {
    } else {
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
    clearForm();
  };

  useEffect(() => {
    // Enable the button if both passwords match and satisfy the conditions
    if (checkPassword(newPassword) && newPassword === confirmNewPassword) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [newPassword, confirmNewPassword]);

  const { applicationTitle, resumeParserEnabled } = useSelector(selectFeatures);
  return (
    <>
      <Helmet title={`${applicationTitle} - Settings`} />
      <Topbar Logo={Logo} onUploadResume={() => setShowResumeParser(true)} />

      <main className="iq4-settings__page" id="main">
        <Container style={{ display: 'block' }}>
          <h1 className="iq4-manage-skills__title">Settings</h1>
          <Grid container alignItems="flex-start">
            <Grid item classes={{ root: 'iq4-settings__card' }} lg={3} md={4} xs={12}>
              <div className="iq4-settings__left">
                <div
                  className={`iq4-settings__content ${
                    activeOption === 'password' ? 'iq4-settings__active-option' : ''
                  }`}
                  tabIndex={0}
                  role="button"
                  onClick={() => setActiveOption('password')}
                  onKeyPress={() => setActiveOption('password')}
                >
                  Change Password
                </div>
              </div>
            </Grid>
            <Grid item classes={{ root: 'iq4-settings__card' }} lg={9} md={8} xs={12}>
              <div className="iq4-settings__content">
                <div className="iq4-settings__title">Change Password</div>
                <div className="iq4-settings__main-content">
                  <div className="iq4-reset-password-form__disclaimer">
                    <div>When changing your password please follow these rules:</div>
                    <br />
                    <div
                      className={`iq4-reset-password-form__disclaimer--row ${
                        hasEightCharacters ? 'iq4-active-option' : ''
                      }`}
                    >
                      At least 8 characters{' '}
                      {hasEightCharacters ? (
                        <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                      ) : null}
                    </div>
                    <div
                      className={`iq4-reset-password-form__disclaimer--row ${
                        hasUppercase && hasLowercase ? 'iq4-active-option' : ''
                      }`}
                    >
                      A mixture of both uppercase and lowercase letters{' '}
                      {hasUppercase && hasLowercase ? (
                        <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                      ) : null}
                    </div>
                    <div
                      className={`iq4-reset-password-form__disclaimer--row ${
                        hasNumbers ? 'iq4-active-option' : ''
                      }`}
                    >
                      A mixture of letters and numbers.{' '}
                      {hasNumbers ? (
                        <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                      ) : null}
                    </div>
                    <div
                      className={`iq4-reset-password-form__disclaimer--row ${
                        hasSpecialChar ? 'iq4-active-option' : ''
                      }`}
                    >
                      Inclusion of at least one special character, e.g., ! @ # ? ]{' '}
                      {hasSpecialChar ? (
                        <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                      ) : null}
                    </div>
                    <div
                      className={`iq4-reset-password-form__disclaimer--row ${
                        newPassword !== '' && newPassword === confirmNewPassword
                          ? 'iq4-active-option'
                          : ''
                      }`}
                    >
                      Passwords match{' '}
                      {newPassword !== '' && newPassword === confirmNewPassword ? (
                        <CheckIcon className="iq4-reset-password-form__disclaimer--icon" />
                      ) : null}
                    </div>
                  </div>
                  <div role="form" className="iq4-login-form__box--login-container">
                    <div className="iq4-login-form__box--field-container">
                      <label htmlFor="currentPassword">Current Password:</label>
                      <TextField
                        id="currentPassword"
                        type="password"
                        classes={{
                          root: 'iq4-login-form__box--field-input-root',
                        }}
                        value={currentPassword}
                        onChange={handleCurrentPasswordChange}
                        InputProps={{
                          disableUnderline: true,
                          classes: {
                            input: 'iq4-login-form__box--field-input',
                          },
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className="iq4-login-form__box--field-container">
                      <label htmlFor="newPassword" className="iq4-login-form__box--field-label">
                        Your new Password:
                      </label>
                      <TextField
                        id="newPassword"
                        type="password"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        clas
                        classes={{
                          root: 'iq4-login-form__box--field-input-root',
                        }}
                        InputProps={{
                          classes: {
                            input: 'iq4-login-form__box--field-input',
                          },
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className="iq4-login-form__box--field-container">
                      <label
                        htmlFor="confirmNewPassword"
                        className="iq4-login-form__box--field-label"
                      >
                        Confirm new Password:
                      </label>
                      <TextField
                        id="confirmNewPassword"
                        type="password"
                        value={confirmNewPassword}
                        onChange={handleConfirmNewPasswordChange}
                        clas
                        classes={{
                          root: 'iq4-login-form__box--field-input-root',
                        }}
                        InputProps={{
                          classes: {
                            input: 'iq4-login-form__box--field-input',
                          },
                        }}
                        variant="outlined"
                      />
                    </div>
                    <div className="iq4-settings__button-container">
                      <Button onClick={clearForm} className={`iq4-settings-button__cancel`}>
                        Cancel
                      </Button>
                      <Button
                        loading={isChangingPassword}
                        onClick={changePassword}
                        disabled={!isButtonEnabled}
                        className={`iq4-login-form__box--button`}
                        variation="ghost"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {showResumeParser && (
                <ProfileResumeParse onClose={() => setShowResumeParser(false)} />
              )}
            </Grid>
          </Grid>
        </Container>

        <div className="iq4-profile-page__footer-container">
          <Footer />
        </div>

        {resumeParserEnabled && (
          <ResumeParserModal
            isOpen={activeResumeParserModal}
            handleClose={() => void setActiveResumeParserModal(false)}
            onSave={() => void setActiveResumeParserModal(false)}
          />
        )}
        <div
          className={`iq4-credentials__alert ${showAlert ? 'iq4-credentials__alert--visible' : ''}`}
        >
          <SimpleIcon className="iq4-credentials__alert--close" name="close" />
          {alertMessage}
        </div>
      </main>
    </>
  );
};
