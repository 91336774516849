import React from 'react';
import { Pill } from 'common/components';
import './CompetencyPill.scss';

export const CompetencyPill = ({ competency }) => {
  const { verified, value } = competency;

  return (
    <Pill
      className="iq4-competency-pill"
      label={value}
      icon={verified ? 'tick' : null}
      type={verified ? 'verifiedCompetency' : 'selfAttestedCompetency'}
    />
  );
};
