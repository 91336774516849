import React from 'react';
import { Uploader } from 'common/components/Uploader';
import { ReactComponent as UploaderIcon } from 'common/assets/svg/uploader.svg';

import './ResumeParserUpload.scss';

export const ResumeParserUpload = ({ className, onUpload, isUploading = false, ...props }) => {
  const UploadContainer = () => (
    <>
      <UploaderIcon />
      <p className="iq4-resume-parser-upload__info-text">
        <span className="iq4-resume-parser-upload__info-text--stronger">
          Upload your resume <br />
        </span>
      </p>
    </>
  );

  const LoadingContainer = () => (
    <div className="iq4-resume-parser-upload__loading-container">
      <div className="iq4-uploader__loading-wrapper progress-45">
        <span className="iq4-uploader__loading-label">
          45<span>%</span>
        </span>
        <div className="iq4-uploader__loading-pie">
          <div className="left-side iq4-uploader__loading-half-circle" />
          <div className="right-side iq4-uploader__loading-half-circle" />
        </div>
        <div className="iq4-uploader__loading-shadow"></div>
      </div>
      <div className="iq4-resume-parser-upload__loading-label">Loading</div>
    </div>
  );

  return (
    <div className={`iq4-resume-parser-upload ${className ? className : ''}`} {...props}>
      <div className="iq4-resume-parser-upload__container">
        <div className="iq4-resume-parser-upload__content">
          <Uploader handleUpload={onUpload}>
            <div
              className={`iq4-resume-parser-upload__child ${
                isUploading ? '' : 'iq4-resume-parser-upload__hover-container'
              }`}
            >
              {!isUploading && <UploadContainer />}
              {isUploading && <LoadingContainer />}
            </div>
          </Uploader>
        </div>
      </div>
    </div>
  );
};
