import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDisplayTour } from 'common/store/features/ui/selectors';

const useSetTourOverlayHeight = () => {
  const displayTour = useSelector(selectDisplayTour);

  useEffect(() => {
    const setOverlayHeight = () => {
      const overlay = document.querySelector('.react-joyride__overlay');
      if (overlay) {
        overlay.style.height = `${document.body.scrollHeight}px`;
        return true;
      }
      return false;
    };

    const intervalId = setInterval(() => {
      if (setOverlayHeight()) {
        clearInterval(intervalId);
      }
    }, 100);

    window.addEventListener('load', setOverlayHeight);
    window.addEventListener('resize', setOverlayHeight);

    // Cleanup function to remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener('load', setOverlayHeight);
      window.removeEventListener('resize', setOverlayHeight);
    };
  }, [displayTour]);
};

export default useSetTourOverlayHeight;
