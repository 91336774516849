import React from 'react';
import { SimpleIcon, ExternalLinkInterstitial } from 'common/components';
import { INTERSTITIAL_COPY } from 'common/constants/interstitialCopy';
import './RoleCourseInnerCard.scss';

/**
 * Role course inner card
 * @param {object} achievement - Achievement
 * @param {string} [className] - Custom className
 */
export const RoleCourseInnerCard = ({
  achievementName,
  achievementId,
  school,
  learnMoreUrl,
  className,
}) => {
  const isURL = !!learnMoreUrl || achievementId?.includes('http');

  return (
    <div className={`iq4-role-course-inner-card ${className ? className : ''}`}>
      <div className="iq4-role-course-inner-card__row-1">
        <div className="iq4-role-course-inner-card__icon">
          <SimpleIcon name="education" />
        </div>
        <div className="iq4-role-course-inner-card__course-container">
          <p className="iq4-role-course-inner-card__course-title">{achievementName}</p>
          <p className="iq4-role-course-inner-card__course-institution">{school}</p>
        </div>
      </div>

      {isURL && (
        <div className="iq4-role-course-inner-card__row-2">
          <div className="iq4-role-course-inner-card__location-container">
            <div className="iq4-role-course-inner-card__icon">
              <SimpleIcon name="source" />
            </div>

            <ExternalLinkInterstitial
              className="iq4-role-course-inner-card__link"
              linkLabel={<>Learn More</>}
              link={learnMoreUrl || achievementId}
              introText={INTERSTITIAL_COPY.BADGR_INTRO_TEXT}
              mainText={INTERSTITIAL_COPY.BADGR_MAIN_TEXT}
            />
          </div>
        </div>
      )}
    </div>
  );
};
