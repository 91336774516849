import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  EMPLOYER_WALLET_SHARE_SETTINGS_URL,
  INVITE_STUDENT_STATUS_URL,
  INVITE_STUDENT_URL,
  PROFILE_SHARE_URL,
  STUDENT_LOOKUP_URL,
} from 'common/constants/endpoints';

export const searchStudentByStudentId = createAsyncThunk(
  'connections/searchStudentByStudentId',
  async ({ studentId }, { rejectWithValue }) => {
    return fetch(STUDENT_LOOKUP_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        studentID: studentId,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const getEmployerShareProfileSettings = createAsyncThunk(
  'connections/getEmployerShareProfileSettings',
  async ({ counsellorId, userId }, { rejectWithValue, dispatch }) => {
    dispatch(setIsFetchingEmployerShareProfileSettings(true));
    return fetch(`${EMPLOYER_WALLET_SHARE_SETTINGS_URL}/${userId}/${counsellorId}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const shareEmployerProfile = createAsyncThunk(
  'connections/shareEmployerProfile',
  async ({ sharingFlags, counsellorId, userId }, { rejectWithValue, dispatch }) => {
    dispatch(setIsSharingEmployerProfile(true));

    const newShareObj = {
      ...sharingFlags,
      profile: {
        ...sharingFlags.profile,
        personal: {
          ...sharingFlags.profile.personal,
          link: true,
        },
      },
    };

    return fetch(PROFILE_SHARE_URL, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newShareObj),
    }).then((response) => {
      dispatch(setIsSharingEmployerProfile(false));
      dispatch(setEmployerShareProfileSettings({ newShareObj, counsellorId }));
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const getCurrentConnections = createAsyncThunk(
  'connections/getCurrentConnections',
  async ({ type }, { rejectWithValue }) => {
    return fetch(`${INVITE_STUDENT_URL}?type=${type}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const createConnection = createAsyncThunk(
  'connections/createConnection',
  async ({ message, studentId }, { rejectWithValue }) => {
    return fetch(INVITE_STUDENT_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        studentId: studentId,
        msg: message,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const updateInviteStudentStatus = createAsyncThunk(
  'connections/createConnection',
  async ({ type, status, userId }, { rejectWithValue, dispatch }) => {
    dispatch(setIsTriggeringDecision(true));
    return fetch(INVITE_STUDENT_STATUS_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        type,
        status,
        userId,
        notifyCounsellor: false,
      }),
    }).then((response) => {
      dispatch(setIsTriggeringDecision(false));
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response.json());
      }
    });
  },
);

export const connectionsSlice = createSlice({
  name: 'connections',
  initialState: {
    currentInvites: [],
    employerShareProfileSettings: {},
    isTriggeringDecision: false,
    isSharingEmployerProfile: false,
    isFetchingEmployerShareProfileSettings: false,
  },
  reducers: {
    setIsTriggeringDecision: (state, action) => {
      state.isTriggeringDecision = action.payload;
    },
    setEmployerShareProfileSettings: (state, action) => {
      state.employerShareProfileSettings[action.payload.counsellorId] = action.payload.newShareObj;
    },
    setIsSharingEmployerProfile: (state, action) => {
      state.isSharingEmployerProfile = action.payload;
    },
    setIsFetchingEmployerShareProfileSettings: (state, action) => {
      state.isFetchingEmployerShareProfileSettings = action.payload;
    },
  },
  extraReducers: {
    [createConnection.fulfilled]: (state, action) => {
      state.currentInvites = [...state.currentInvites, action.payload];
    },
    [getCurrentConnections.fulfilled]: (state, action) => {
      state.currentInvites = action.payload;
    },
    [getEmployerShareProfileSettings.fulfilled]: (state, action) => {
      const { counsellorId } = action.meta.arg;
      state.employerShareProfileSettings[counsellorId] = action.payload;
      state.isFetchingEmployerShareProfileSettings = false;
    },
    [updateInviteStudentStatus.fulfilled]: (state, action) => {
      const updatedInvite = state.currentInvites.find((x) => x.id === action.payload?.id);
      if (updatedInvite) {
        updatedInvite.status = action.payload?.status;
      }
    },
  },
});

export const {
  setIsTriggeringDecision,
  setEmployerShareProfileSettings,
  setIsSharingEmployerProfile,
  setIsFetchingEmployerShareProfileSettings,
} = connectionsSlice.actions;

export default connectionsSlice.reducer;
