import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  ProfilePersonal,
  ProfileEducationSummary,
  ProfileLCNEducationSummary,
  ProfileExperienceSummary,
  ProfilePortfolioSummary,
  ProfileSkillsSummary,
  ProfilePageLayout,
  ProfileResumeParse,
} from 'components/Profile';
import { Button } from 'common/components';
import { selectFeatures } from 'store/features/config/selectors';
import {
  selectHasFetchedProfile,
  selectUserProfile,
} from 'common/store/features/profile/selectors';
import { fetchProfileReq, setIsPublicProfile } from 'common/store/features/profile/profileSlice';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { setSelectedSkillInfo } from 'common/store/features/t/slice';
import { fetchCredentialsReq } from '../../../common/store/features/credentials/credentialsSlice';

export const ProfilePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { applicationTitle, lcnEnabled, personalProfileSource } = useSelector(selectFeatures);
  const userProfile = useSelector(selectUserProfile);
  const [showResumeParser, setShowResumeParser] = useState(false);
  const isMobile = useIsMobile();
  const location = useLocation();
  const hasFetchedProfile = useSelector(selectHasFetchedProfile);

  const { id } = useParams();

  useEffect(() => {
    if (!hasFetchedProfile && personalProfileSource) {
      dispatch(fetchProfileReq(personalProfileSource));
    }
    dispatch(fetchCredentialsReq());
  }, [dispatch, personalProfileSource, hasFetchedProfile]);

  useEffect(() => {
    dispatch(setSelectedSkillInfo(null));
  }, [location.pathname]);

  useEffect(() => {
    dispatch(setIsPublicProfile(!!id));
  }, []);

  if (!userProfile) {
    return null;
  }

  /*  if (!userProfile?.hasOnboarded) {
    history.push(ROUTES.ONBOARDING_RESUME);
  }*/

  return (
    <>
      <ProfilePageLayout helmetTitle={`${applicationTitle} - Profile`}>
        <div>
          {!isMobile && (
            <Button variation="ghost" onClick={() => setShowResumeParser(true)}>
              Upload your updated resume
            </Button>
          )}
          {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}
        </div>
        <div>
          <ProfilePersonal />
          {lcnEnabled ? <ProfileLCNEducationSummary /> : <ProfileEducationSummary />}
          <ProfileExperienceSummary />
        </div>
        <div>
          <ProfileSkillsSummary />
          <ProfilePortfolioSummary />
        </div>
      </ProfilePageLayout>
    </>
  );
};
