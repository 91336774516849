import React from 'react';
import FocusLock from 'react-focus-lock';
import { Drawer, Pill, SimpleIcon } from 'common/components';
import { CATEGORY_LABELS } from '../../common/utils';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../routes';

export const AboutSkillsHelp = ({ hideAboutSkillsHelp }) => (
  <Drawer
    anchor="right"
    variant="persistent"
    isOpen={true}
    classes={{
      root: 'iq4-tshape-page__drawer',
      paper: 'iq4-explanation__drawer-paper',
    }}
  >
    <FocusLock returnFocus={true}>
      <div style={{ paddingTop: '20px' }}>
        <button
          aria-label="Close"
          className="iq4-tshape-page__drawer__dismiss-button"
          onClick={hideAboutSkillsHelp}
        >
          <SimpleIcon name="close" />
        </button>
        <div className="iq4-tshape-page__drawer__role-container iq4-explanation__scroll">
          <h1 className="iq4-explanation--title">Manage Skills</h1>
          <div className="iq4-explanation--divider" />
          <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
          <div className="iq4-explanation--content" style={{ borderBottom: 'none' }}>
            Skills are the acquired abilities and expertise that enable individuals to perform tasks
            effectively in various areas of life and work. Skills are crucial because they empower
            individuals to navigate challenges, contribute to personal and professional success, and
            adapt to the dynamic demands of an ever- changing world.
          </div>
          <div className="iq4-tshape-page__drawer__role-container--role">ADDING SKILLS</div>
          <div className="iq4-explanation--content" style={{ borderBottom: 'none' }}>
            <div>
              Adding a skill to your profile is easy. In the “Skills” screen use the{' '}
              <strong>search field</strong> at the top of the page to look for any skill and select
              it from the dropdown list. You can also{' '}
              <strong>upload your most recent resume</strong> using the top right button ‘Upload
              your most recent resume’. This will extract any new skills from a new or recent Role
              or a new or recent Credential. Our Skills engine also recommends skills on the left
              hand side of this page, you can add these if you like them
            </div>
          </div>
          <div className="iq4-tshape-page__drawer__role-container--role">SKILLS PANEL</div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            The main page contains a list of skills panels. You can expand these to view the
            following content:
            <br />
            <br />
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Category Tag</strong>
              <br />
              <div>A skill is categorized by one of the following labels:</div>
            </div>
          </div>
          <p style={{ fontSize: '0.875rem', paddingLeft: '1rem' }}>
            <b>{CATEGORY_LABELS['professional_skills']}</b> are unique abilities and knowledge
            needed for a particular occupation, that includes a distinct range of skills that are
            crucial for success on the job.
            <br />
            <div className="iq4__flex-row">
              Example: ARCHITECTURE
              <Pill
                label="PROFESSIONAL SKILLS"
                type="professional skills"
                className="iq4__margin-left"
              />
            </div>
          </p>
          <p style={{ fontSize: '0.875rem', paddingLeft: '1rem' }}>
            <b>{CATEGORY_LABELS['it_skills']}</b> represent the ability to effectively use a variety
            of software and tools on the job.
            <br />
            <div className="iq4__flex-row">
              Example: SQL Databases
              <Pill label="IT SKILLS" type="it skills" className="iq4__margin-left" />
            </div>
          </p>
          <p style={{ fontSize: '0.875rem', paddingLeft: '1rem' }}>
            <b>{CATEGORY_LABELS['soft_skills']}</b> are the personal qualities and behaviors crucial
            for professional success. These skills can often be transferred and applied across
            various job roles and industries.
            <br />
            <div className="iq4__flex-row">
              Example: LEADERSHIP
              <Pill label="SOFT SKILLS" type="soft skills" className="iq4__margin-left" />
            </div>
          </p>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Description</strong>
              <br />
              <div>A description of the skill (where one exists)</div>
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Tabs</strong>
              <br />
              <div>
                Skills Detail - Knowledge, Skills and Abilities checkboxes. These can be selected as
                per your own personal abilities.
                <br />
                <br />
                Proficiency - This is where you can score your own ability for that skill
                <br />
                <br />
                Source - This shows you where that skill came from (ie A credential, manually added
                etc.)
                <br />
                <br />
                Learning Resources - This presents courses you can view and see how you can get a
                better proficiency for this skill
              </div>
            </div>
          </div>
          <div
            className="iq4-tshape-page__drawer__role-container--role"
            style={{ marginTop: '1rem' }}
          >
            TOP 10 SKILLS
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            The top 10 skills listed on your profile page showcase your key strengths, competencies,
            and areas of expertise. These skills can vary over time so you can change your top 10
            skills at any time from the search and drag function provided in the left hand column in
            the card titled ‘Top 10 Skills’.
            <br />
            <br />
          </div>
          <div
            className="iq4-tshape-page__drawer__role-container--role"
            style={{ marginTop: '1rem' }}
          >
            OTHER SKILLS SOURCES
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              As mentioned above, any new skills from a new or recent Role or a new or recent
              Credential can be added.
              <br />
              <br /> <strong>Credentials</strong> are a great source of new and verified skills.
              When you are awarded a new credential be sure to add it to your Credential screen in
              any number of ways. Go to the{' '}
              <NavLink style={{ color: '#0070F0', textDecoration: 'none' }} to={ROUTES.CREDENTIALS}>
                Credentials
              </NavLink>{' '}
              page to find out more.
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  </Drawer>
);
