import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import _isEqual from 'lodash/isEqual';

import { selectIsUISubmittingForm } from 'common/store/features/ui/selectors';
import { setIsSubmittingForm } from 'common/store/features/ui/uiSlice';
import { Button } from 'common/components/Button';
import './Form.scss';

/**
 * Generic Form Handler that receives
 * a field definition object and manages forms
 * @param formDefinition
 * @param values
 * @param submitLabel
 * @param {Object} [layoutConfig]
 * @returns {*}
 * @constructor
 */
export const Form = ({
  formDefinition,
  values,
  submitLabel,
  exposeFormContext,
  showButtons,
  layoutConfig = { submitButtons: { position: 'center', outline: false } },
  onFieldChange,
}) => {
  const [triggerExternalValidation, setTriggerExternalValidation] = useState(false);
  const [prevFields, setPrevFields] = useState({});
  const isSubmittingForm = useSelector(selectIsUISubmittingForm);

  const methods = useForm({
    defaultValues: values,
    mode: 'onBlur',
  });

  // Watch for changes in all fields
  const watchedFields = methods.watch();

  useEffect(() => {
    if (onFieldChange && !_isEqual(watchedFields, prevFields)) {
      onFieldChange(watchedFields);
      setPrevFields(watchedFields);
    }
  }, [watchedFields, onFieldChange, prevFields]);

  const { onEdit, onCreate, onComplete, fieldsDefinition } = formDefinition;

  const {
    reset,
    handleSubmit,
    triggerValidation,
    formState: { isValid, dirty },
  } = methods;
  const isEdit = !!values.id;
  const dispatch = useDispatch();

  const [showFormButtons, setShowFormButtons] = useState(
    exposeFormContext && showButtons ? dirty : showButtons,
  );

  const onSubmitActions = (data) => {
    dispatch(setIsSubmittingForm(true));

    return new Promise((resolve) => {
      if (isEdit) {
        onEdit(data).then((res) => {
          onComplete && onComplete(res);
          dispatch(setIsSubmittingForm(false));
          resolve();
        });
      } else {
        onCreate(data).then((res) => {
          onComplete && onComplete(res);
          dispatch(setIsSubmittingForm(false));
          resolve();

          // extend if there's an error to not reset the form values
          if (!res.error) {
            reset();
          }
        });
      }
    });
  };

  useEffect(() => {
    if (exposeFormContext) {
      const submitForm = () => onSubmitActions(methods.getValues());
      exposeFormContext({
        isValid,
        dirty,
        submitForm,
        setTriggerExternalValidation,
        formDefinition,
        getValues: methods.getValues,
      });
      if (showButtons) setShowFormButtons(dirty);
    }

    // eslint-disable-next-line
  }, [isValid, dirty]);

  useEffect(() => {
    if (!!triggerExternalValidation) {
      triggerValidation();
    }
  }, [triggerExternalValidation, triggerValidation]);

  return (
    <FormContext {...methods}>
      <form className="iq4-form" onSubmit={handleSubmit(onSubmitActions)}>
        {fieldsDefinition.map((field, index) => {
          if (field.isCustom) {
            return field.component({
              ...methods,
              key: index,
              triggerExternalValidation,
              exposeFormContext: !!exposeFormContext,
            });
          }
          const FieldComponent = field.component;
          return (
            <FieldComponent
              {...field}
              key={index}
              exposeFormContext={!!exposeFormContext}
              triggerExternalValidation={triggerExternalValidation}
            />
          );
        })}
        {!!showFormButtons && (
          <div
            className={`iq4-form__button-container iq4-form__button_container--btns-${layoutConfig.submitButtons.position}`}
          >
            <div className="iq4-form__button-submit-container">
              <Button
                variation={layoutConfig.submitButtons.outline ? 'ghost' : 'primary'}
                type="submit"
                className={`iq4-form__button-submit ${
                  isSubmittingForm ? 'iq4-form__button-submit--loading' : ''
                }`}
              >
                {submitLabel ? submitLabel : 'Submit'}
              </Button>
              {isSubmittingForm && (
                <CircularProgress size={24} className="iq4-form__progress__indicator" />
              )}
            </div>
            {exposeFormContext && (
              <Button
                variation="ghost"
                className="iq4-form__button-cancel"
                onClick={() => reset(values)}
              >
                Cancel
              </Button>
            )}
          </div>
        )}
      </form>
    </FormContext>
  );
};
