import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Router from './routes';
import content from './content';
import { PROFILE_URL } from './common/constants/endpoints';
import {
  fetchIsLoggedInReq,
  setSessionIsExpired,
} from 'common/store/features/session/sessionSlice';
import { setFeatures } from 'store/features/config/configSlice';
import {
  fetchCountsReq,
  refreshBadges,
  triggerCredentialSummary,
} from 'common/store/features/records/recordsSlice';
import { getCurrentConnections } from 'common/store/features/connections/connectionsSlice';
import { selectIsLoggedIn, selectIsBootstrapping } from 'common/store/features/session/selectors';
import { selectCredentialSummaryAPICalled } from 'common/store/features/records/selectors';
import { setCredentialSummaryAPICalled } from 'common/store/features/records/recordsSlice';
import { selectFeatures } from 'store/features/config/selectors';
import featureFlags from 'featureFlags';
import './App.scss';
import { ErrorBoundary } from './common/components';
import { useLocation } from 'react-router-dom';
import { fetchProfileAgreeTerms } from './common/store/features/profile/profileSlice';

function App() {
  const dispatch = useDispatch();
  const [isCountsRequested, setIsCountsRequested] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);

  const { lcnEnabled, nscEnabled, applicationTitle, defaultRoute } = useSelector(selectFeatures);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const credentialSummaryAPICalled = useSelector(selectCredentialSummaryAPICalled);

  const projectId = process.env.REACT_APP_AIRBRAKE_PROJECT_ID;
  const projectKey = process.env.REACT_APP_AIRBRAKE_PROJECT_KEY;
  const context = process.env.REACT_APP_CONTEXT;

  const excludeTerms = ['home', 'terms-conditions', 'privacy-policy'];

  useEffect(() => {
    dispatch(setFeatures(featureFlags));
  }, [dispatch]);

  useEffect(() => {
    if (lcnEnabled) {
      dispatch(refreshBadges());
    }
  }, [lcnEnabled]);

  useEffect(() => {
    if (!isCountsRequested && isLoggedIn && lcnEnabled) {
      dispatch(fetchCountsReq());
      setIsCountsRequested(true);
    }
  }, [dispatch, isCountsRequested, isLoggedIn, lcnEnabled]);

  useEffect(() => {
    if (isLoggedIn && excludeTerms.every((x) => window.location.href.indexOf(x) === -1)) {
      dispatch(getCurrentConnections({ type: 'student' }));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (!excludeTerms.every((x) => window.location.href.indexOf(x) === -1)) {
      dispatch(setCredentialSummaryAPICalled(true));
    }
  }, [window.location.href]);

  useEffect(async () => {
    if (!isLoggedIn) {
      const result = await dispatch(fetchIsLoggedInReq(PROFILE_URL));
      const isPublic = window.location.href.indexOf('/user/') >= 0;
      if (isPublic && result.type === 'session/fetchIsLoggedInReq/rejected') {
        window.location.href = `${window.location.protocol}//${window.location.host}${context}/home`;
      }
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (isAppReady) {
      const isPublic = window.location.href.indexOf('/user/') >= 0;
      if (!isPublic) {
        dispatch(fetchProfileAgreeTerms());
      }
    }
  }, [isAppReady]);

  useEffect(() => {
    setIsAppReady(((credentialSummaryAPICalled && nscEnabled) || !nscEnabled) && !isBootstrapping);
  }, [credentialSummaryAPICalled, isBootstrapping, nscEnabled]);

  return (
    <div className="iq4-30__root">
      <ErrorBoundary projectId={projectId} projectKey={projectKey}>
        <IntlProvider locale="en" messages={content.en}>
          <Helmet title={applicationTitle} />
          {!isAppReady && isLoggedIn && (
            <>
              <div className="iq4-30__loading">
                <span
                  className="sr-only"
                  aria-live="polite"
                  role="alert"
                  aria-label="Loading Data"
                ></span>
                <CircularProgress className="iq4-30__loading__spinner" size={100} />
              </div>
            </>
          )}
          {defaultRoute && (isAppReady || !isLoggedIn) && <Router defaultRoute={defaultRoute} />}
        </IntlProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
