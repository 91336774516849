import React, { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/config/selectors';
import { DEFAULT_SKILLS_SORT } from 'common/constants/miscellaneous';
import { SimpleIcon, Button, PromptModal, Pill, CredentialPill } from 'common/components';
import { ManageSkillsCardFilter } from 'common/components/ManageSkillsCard/ManageSkillsCardFilter';
import { ManageSkillsCardSort } from 'common/components/ManageSkillsCard/ManageSkillsCardSort';

import './PathwaysSkillList.scss';
import { CATEGORY_LABELS } from '../../../../utils';

const {
  lowToHigh: _,
  highToLow: __,
  newToOld: ___,
  oldToNew: ____,
  ...SORT_FILTERS
} = DEFAULT_SKILLS_SORT;
export const SKILLS_SORT_FUNCS = {
  aToZ: (skills) => orderBy(skills, (skill) => skill.value, ['asc']),
  zToA: (skills) => orderBy(skills, (skill) => skill.value, ['desc']),
};
/**
 * Lists out the skills, used on the pathways page atm.
 * @param {string} [title] - Title of the list
 * @param {object[]} skills - An array of { id: [number|string], value: [string] } containing the skills data
 * @param {function} [onClick] - Handle skill click
 * @param {string} [className] - Custom classname
 * @param {boolean} [addedSkills] - specifies if the skills are already added or not, true by default
 * @param {function} [onSkillToggleClick] - only required if addedSkills is defined
 */
export const PathwaysSkillList = ({
  title,
  skills,
  addedSkills = null,
  className,
  onClick = () => {},
  onSkillToggleClick = () => {},
  ...props
}) => {
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [currentSort, setCurrentSort] = useState(SORT_FILTERS);
  const [currentFilter, setCurrentFilter] = useState({
    technical: false,
    professional: false,
    operational: false,
    hard: false,
    common: false,
  });
  const [filteredSkills, setFilteredSkills] = useState(skills);
  const { displayUpperTiers, selfAttestedEnabled } = useSelector(selectFeatures);
  const isPublic = window.location.href.indexOf('/user/') >= 0;

  // filter & sort
  useEffect(() => {
    // filter out skills
    let activeFilters = { category: [], type: [] };
    Object.keys(currentFilter)
      .filter((key) => {
        return currentFilter[key];
      })
      .forEach((activeFilter) => {
        if (activeFilter === 'hard' || activeFilter === 'common') {
          activeFilters.type.push({ key: 'type', value: activeFilter });
        }

        if (
          activeFilter === 'it skills' ||
          activeFilter === 'professional skills' ||
          activeFilter === 'soft skills'
        ) {
          activeFilters.category.push({ key: 'category', value: activeFilter });
        }
      });

    const filteredSkills =
      !activeFilters.category.length && !activeFilters.type.length
        ? skills
        : skills.filter((skill) => {
            if (activeFilters.category.length) {
              let matchesCategory = false;
              activeFilters.category.forEach((activeFilter) => {
                if (
                  skill?.category?.toString()?.toLowerCase() ===
                  activeFilter.value.toString()?.toLowerCase()
                ) {
                  matchesCategory = true;
                }
              });

              if (!matchesCategory) return false;
            }

            if (activeFilters.type.length) {
              let matchesType = false;
              activeFilters.type.forEach((activeFilter) => {
                if (skill.type === activeFilter.value) {
                  matchesType = true;
                }
              });

              if (!matchesType) return false;
            }

            return true;
          });

    // sort skills
    const activeSortKey = Object.keys(currentSort).find((key) => currentSort[key]);
    const newSortedSkills = SKILLS_SORT_FUNCS[activeSortKey || 'aToZ'](filteredSkills);
    setFilteredSkills(newSortedSkills);
  }, [currentSort, currentFilter, skills]);

  return (
    <div className={`iq4-roles-skills-list ${className ? className : ''}`} {...props}>
      <div className="iq4-roles-skills-list__container">
        {skills.length > 0 && (
          <div className="iq4-roles-skills-list__filters-header">
            <ManageSkillsCardSort
              currentSort={currentSort}
              onChangeSort={(newSort) => {
                setCurrentSort(newSort);
              }}
            />
            <ManageSkillsCardFilter
              customFilters={[
                {
                  filterGroupTitle: 'category',
                  filters: [
                    { label: CATEGORY_LABELS['it_skills'], key: 'it skills' },
                    { label: CATEGORY_LABELS['professional_skills'], key: 'professional skills' },
                    { label: CATEGORY_LABELS['soft_skills'], key: 'soft skills' },
                  ],
                },
                {
                  filterGroupTitle: 'type',
                  filters: [
                    { label: 'Hard Skill', key: 'hard' },
                    { label: 'Common Skill', key: 'common' },
                  ],
                },
              ]}
              currentFilter={currentFilter}
              onChangeFilter={(newFilters) => {
                setCurrentFilter(newFilters);
              }}
            />
          </div>
        )}

        {skills.length === 0 && (
          <p className="iq4-roles-skills-list__empty-state">
            You have no <strong>{title}</strong> for this role.
          </p>
        )}
        {filteredSkills &&
          filteredSkills.length > 0 &&
          filteredSkills.map((skill) => {
            const isVerified = skill?.sources?.filter((source) => source.verifiedBy).length > 0;

            return (
              <div className="iq4-pathway-skill-list-item">
                <button
                  key={skill.id}
                  className="iq4-roles-skills-list__skill"
                  onClick={() => void onClick(skill)}
                >
                  <span class="iq4-roles-skills-list__skill--tier3">
                    {skill.value}
                    <Pill
                      className={`iq4-roles-skills-list__skill-pill iq4-roles-skills-list__skill-pill--${skill.category}`}
                      label={`${skill.category}`}
                      type={skill.category}
                    />
                    {skill.type && (
                      <Pill
                        className={`iq4-roles-skills-list__skill-pill iq4-roles-skills-list__skill-pill--${skill.type}`}
                        label={`${skill.type}`}
                        type={skill.type}
                      />
                    )}
                    {isVerified && <CredentialPill credential={{ ...skill, verifiedBy: 'NSC' }} />}
                    <SimpleIcon
                      name="arrow"
                      className="iq4-simple-icon__flip-horizontal iq4-work-roles__icon"
                    />
                  </span>
                  {displayUpperTiers && (
                    <span class="iq4-roles-skills-list__skill--tier1-tier-2">
                      {skill.tier1.label} &gt; {skill.tier2.label}
                    </span>
                  )}
                </button>

                <div className="iq4-roles-skills-list__skill-additional">
                  {!addedSkills && (
                    <Button
                      variation="ghost"
                      className="iq4-roles-skills-list__skill-additional-courses"
                      onClick={() => void onClick(skill)}
                    >
                      <span>{(skill.achievements && skill.achievements.length) || 0}</span> Training
                      Resources
                    </Button>
                  )}

                  {selfAttestedEnabled && addedSkills !== null && !isVerified && !isPublic && (
                    <Button
                      variation="ghost"
                      className="iq4-roles-skills-list__skill-toggle"
                      onClick={() => void setIsPromptActive(skill)}
                    >
                      {addedSkills ? 'Remove Skill' : 'Add Skill'}
                    </Button>
                  )}
                </div>
              </div>
            );
          })}

        {!!skills.length && !filteredSkills.length && (
          <p>No skills available for your selected filter.</p>
        )}
      </div>

      <PromptModal
        hideDismiss
        isOpen={isPromptActive}
        handleClose={() => setIsPromptActive(null)}
        title={
          addedSkills
            ? 'You are deleting this skill from your profile.'
            : 'You are adding this skill to your profile.'
        }
        buttonLabel="Proceed"
        buttonAction={() => {
          onSkillToggleClick(isPromptActive);
          setIsPromptActive(null);
        }}
      />
    </div>
  );
};
