import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn, selectIsBootstrapping } from 'common/store/features/session/selectors';
import { ROUTES } from 'routes/routeNames';
import { useScrollToTop } from 'common/hooks/useScrollToTop';
import { AcceptTerms, ErrorNotification } from 'common/components';

/**
 * Functional HOC to manage protected routes
 * @param Component
 * @param outerProps
 * @returns {*}
 * @constructor
 */
export const ProtectedRoute = ({ component: Component, ...outerProps }) => {
  const location = useLocation();
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useScrollToTop(null, [location.pathname]);

  return (
    <>
      <ErrorNotification />

      <Route
        {...outerProps}
        render={(props) => {
          // check if user is authenticated, if not set 'redirect' to true
          const redirect = !isLoggedIn;

          // don't render child component, just redirect if not authenticated
          return isBootstrapping ? (
            ''
          ) : redirect ? (
            <Redirect
              to={{
                pathname: ROUTES.HOME,
                state: { from: props.location },
              }}
            />
          ) : (
            <>
              {location.pathname !== ROUTES.ONBOARDING_WELCOME && <AcceptTerms />}
              <Component {...props} />
            </>
          );
        }}
      />
    </>
  );
};
