import { useEffect } from 'react';

/**
 *
 * @param {Object|String} nodeRefSelector — this can be a ref from react or a query string to pass to document.querySelector
 * @param {*[]} conditions - optimazation array to be passed to useEffect
 * @param {function} [check] - additional check you can pass into the use effect
 */
export const useScrollToTop = (nodeRefSelector, conditions, check = null) => {
  useEffect(() => {
    // if there's a custom check and it's not true, return
    if (check && !check()) return;

    // if the window is scrolled, scroll back to top
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }

    // if there's no node reference or query string
    if (!nodeRefSelector) return;

    let domNode = '';
    if (typeof nodeRefSelector === 'string' || nodeRefSelector instanceof String) {
      domNode = document.querySelector(nodeRefSelector);
    } else if (isElement(nodeRefSelector)) {
      domNode = nodeRefSelector;
    } else {
      domNode = nodeRefSelector.current;
    }

    // if there's no dom node, or there's a dom node but it's already scrolled at the top, return
    if (!domNode || (domNode && !domNode.scrollTop)) return;

    domNode.scrollTop = 0;
  }, [...conditions]);
};

function isElement(element) {
  return element instanceof Element || element instanceof HTMLDocument;
}
