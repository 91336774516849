import React from 'react';
import FocusLock from 'react-focus-lock';
import { Drawer, SimpleIcon } from 'common/components';

export const JobFamilyExplanation = ({ hideJobFamilyExplanation }) => (
  <Drawer
    anchor="right"
    variant="persistent"
    isOpen={true}
    classes={{
      root: 'iq4-tshape-page__drawer',
      paper: 'iq4-explanation__drawer-paper',
    }}
  >
    <FocusLock>
      <div style={{ paddingTop: '20px' }}>
        <button
          aria-label="Close"
          className="iq4-tshape-page__drawer__dismiss-button"
          onClick={hideJobFamilyExplanation}
        >
          <SimpleIcon name="close" />
        </button>
        <div className="iq4-tshape-page__drawer__role-container iq4-explanation__scroll">
          <h1 className="iq4-explanation--title">Job Families</h1>
          <div className="iq4-explanation--divider" />

          <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
          <div className="iq4-explanation--content">
            Job families refer to a grouping or classification system used within a job platform or
            organization to categorize and organize various job roles based on similarities in their
            responsibilities, skills, and qualifications. This classification helps users, such as
            job seekers and employers, navigate the platform more efficiently and understand the
            relationships between different roles.
            <br />
            <br /> Here’s a more detailed explanation: <br />
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Grouping Similar Roles:</strong> Job families group together jobs that share
              common characteristics. These characteristics can include the nature of the work,
              required skills and qualifications, job duties, and career progression paths. For
              example, all roles related to software development may be grouped into a “Software
              Development” job family.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Simplified Job Search:</strong> For job seekers, job families make it easier
              to search for relevant job opportunities. Instead of sifting through a long list of
              unrelated job titles, they can explore job families that align with their skills and
              interests. This simplifies the job search process and allows users to focus on roles
              that match their career goals.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Streamlined Recruiting:</strong> For employers and recruiters, job families
              help streamline the recruitment process. When posting job listings, they can assign
              the appropriate job family to the position, making it easier for potential candidates
              to find and apply for the job. This ensures that job postings are seen by individuals
              with the right qualifications and experience.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Career Path Visibility:</strong> Job families also provide insights into
              career progression within a specific field or industry. Users can see how different
              roles within a job family relate to one another in terms of seniority and skill
              development. This can be valuable for job seekers planning their career trajectories.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Training and Development:</strong>Organizations can use job families to
              develop training and development programs tailored to specific career paths. By
              understanding the skills and qualifications required for each job family, they can
              provide relevant training opportunities to help employees progress in their careers.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Market Analysis:</strong> For job platform operators and labor market
              analysts, job families provide a structured way to analyze and report on trends in
              different industries and sectors. They can use this information to track changes in
              demand for specific skill sets and assess the overall health of job markets.
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  </Drawer>
);
