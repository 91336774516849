import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import {
  selectAvatar,
  selectIsSharingProfile,
  selectProfile,
  selectShareProfileSettings,
  selectUserProfile,
} from 'common/store/features/profile/selectors';
import { selectOrderedSkills } from 'common/store/features/skills/selectors';
import { selectSelectedPathwaysSpokes } from 'common/store/features/pathways/selectors';
import {
  selectCredentialsForSharing,
  selectHasFetchedCredentials,
  selectInnerCurrentCredentials,
} from 'common/store/features/credentials/selectors';
import {
  ActionIcon,
  Button,
  Modal,
  PrivatePublicToggle,
  RatingStars,
  SimpleIcon,
} from 'common/components';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';
import './ShareWalletModal.scss';
import {
  acceptSharingDisclaimer,
  setIsSharingProfile,
  shareProfile,
} from '../../store/features/profile/profileSlice';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectFeatures } from 'store/features/config/selectors';
import { SkillProficiency } from '../SkillCard/Tabs';
import { SELF_ASSESSMENT_ASSESSEE_ID } from '../../constants/identifiers';
import { fetchCredentialsReq } from 'common/store/features/credentials/credentialsSlice';

export const ShareWalletModal = ({ isOpen, onClose, onShare }) => {
  const context = process.env.REACT_APP_CONTEXT;
  const APP_NAME = process.env.REACT_APP_NAME;

  const [activeTab, setActiveTab] = useState('profile');
  const [shareStep, setShareStep] = useState(1);
  const [linkCopied, setLinkCopied] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(
    `${window.location.protocol}//${window.location.host}${context}`,
  );
  const [sharingFlags, setSharingFlags] = useState({
    profile: {
      personal: {
        photo: true,
        name: true,
        role: true,
        description: true,
        linkedIn: true,
      },
      skills: {
        atAGlance: true,
        tShape: true,
      },
    },
  });
  const [hasRunInitial, setHasRunInitial] = useState(false);

  const { personal, education, experience, portfolio } = useSelector(selectProfile);
  const skills = useSelector(selectOrderedSkills);
  const selectedPathwayRoles = useSelector(selectSelectedPathwaysSpokes);
  const currentCredentials = useSelector(selectCredentialsForSharing);
  const hasPhoto = !!(personal && personal.userProfilePhoto && personal.userProfilePhoto.thumbnail);
  const userAvatar = useSelector(selectAvatar);
  const isSharingProfile = useSelector(selectIsSharingProfile);
  const shareProfileSettings = useSelector(selectShareProfileSettings);
  const { enableWalletSharing } = useSelector(selectFeatures);
  const hasFetchedCredentials = useSelector(selectHasFetchedCredentials);

  const showDisclaimer = false;
  const dispatch = useDispatch();

  const [toggleAllSkills, setToggleAllSkills] = useState(
    !!sharingFlags?.skills?.every((skill) => skill.isShareable),
  );

  useEffect(() => {
    if (personal) {
      setCurrentAddress(
        `${window.location.protocol}//${window.location.host}${context}/user/${personal.userProfile?.user?.userId}`,
      );
    }
  }, [personal]);
  useEffect(() => {
    setSharingFlags(shareProfileSettings);
  }, [shareProfileSettings]);

  useEffect(() => {
    if (!!sharingFlags?.skills) {
      const newSkills = sharingFlags?.skills?.map((skill) => ({
        ...skill,
        isShareable: toggleAllSkills,
      }));

      setSharingFlags((prevSharingFlags) => ({
        ...prevSharingFlags,
        skills: newSkills,
      }));
    }
  }, [toggleAllSkills]);

  useEffect(() => {
    if (!!sharingFlags?.skills && !hasRunInitial) {
      setHasRunInitial(true);
      setToggleAllSkills(
        !!sharingFlags?.skills?.every((skill) => skill.isShareable) &&
          !!sharingFlags?.skills?.length,
      );
    }
  }, [sharingFlags]);

  useEffect(() => {
    if (skills && hasRunInitial) {
      const newSkills = skills.map((skill) => {
        const existingSkill = sharingFlags.skills.find((s) => s.competenceId === skill.id);
        return {
          competenceId: skill.id,
          isShareable: existingSkill ? existingSkill.isShareable : false,
        };
      });

      setSharingFlags((prevSharingFlags) => ({
        ...prevSharingFlags,
        skills: newSkills,
      }));
    }
  }, [skills, hasRunInitial]);

  useEffect(() => {
    if (!hasFetchedCredentials) {
      dispatch(fetchCredentialsReq());
    }
  }, [hasFetchedCredentials]);

  function renderTabContent() {
    switch (activeTab) {
      case 'profile':
        return (
          <div id="profile-tabpanel" role="tabpanel" aria-labelledby="profile-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="personCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Personal</h2>
              </div>
            </div>
            {hasPhoto && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>Photo</strong>
                <img className="iq4-share-wallet-modal__avatar" src={userAvatar} alt="Avatar" />

                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{ isPublic: !!sharingFlags.profile.personal.photo }}
                    ariaLabel={`Toggle photo to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            photo: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Name</strong>
              <div>
                {personal?.userProfile?.user?.firstName} {personal?.userProfile?.user?.lastName}
              </div>
              <div className="iq4-share-wallet-status-toggle">
                <PrivatePublicToggle
                  isIncludeContext
                  record={{ isPublic: !!sharingFlags.profile.personal.name }}
                  ariaLabel={`Toggle name to be public or private`}
                  onSelecting={({ selected }) =>
                    setSharingFlags({
                      ...sharingFlags,
                      profile: {
                        ...sharingFlags.profile,
                        personal: {
                          ...sharingFlags.profile.personal,
                          name: selected,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            {!!personal?.userProfile?.currentPosition && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>Occupation</strong>
                <div>{personal?.userProfile?.currentPosition}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{ isPublic: !!sharingFlags.profile.personal.role }}
                    ariaLabel={`Toggle occupation to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            role: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!personal?.userProfile?.description && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>Description</strong>
                <div className="iq4-share-wallet-modal__grid-item-long">
                  {personal?.userProfile?.description}
                </div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{ isPublic: !!sharingFlags.profile.personal.description }}
                    ariaLabel={`Toggle description to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            description: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!personal?.userProfile?.linkedIn && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>LinkedIn</strong>
                <div>{personal?.userProfile?.linkedIn}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{ isPublic: !!sharingFlags.profile.personal.linkedIn }}
                    ariaLabel={`Toggle LinkedIn to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            linkedIn: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!education?.length && (
              <div className="iq4-share-wallet-modal__content__body__section">
                <div className="iq4-share-wallet__heading-section">
                  <SimpleIcon
                    className="iq4-share-wallet__heading-icon"
                    name="educationCircle"
                    role="presentation"
                    hidden
                  />
                  <h2 className="iq4-share-wallet__heading-title">Education </h2>
                </div>
              </div>
            )}
            {education.map((course) => (
              <div key={course.id} className="iq4-share-wallet-modal__grid-item">
                <div>
                  <strong>Course</strong>
                  {!!course.verifiedBy && (
                    <div className="iq4-share-wallet__verified">
                      <Verified />
                    </div>
                  )}
                </div>
                <div>{course.major || course.title}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{
                      isPublic: !!sharingFlags.profile.education?.find((x) => x.id === course.id)
                        ?.isShareable,
                    }}
                    ariaLabel={`Toggle ${course?.major} to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          education: [
                            ...sharingFlags.profile.education?.filter((x) => x.id !== course.id),
                            {
                              id: course.id,
                              isShareable: selected,
                            },
                          ],
                        },
                      })
                    }
                  />
                </div>
              </div>
            ))}
            {!!experience?.length && (
              <div className="iq4-share-wallet-modal__content__body__section">
                <div className="iq4-share-wallet__heading-section">
                  <SimpleIcon
                    className="iq4-share-wallet__heading-icon"
                    name="experienceCircle"
                    role="presentation"
                    hidden
                  />
                  <h2 className="iq4-share-wallet__heading-title">Experience</h2>
                </div>
              </div>
            )}
            {experience
              ?.filter((x) => !!x.title)
              .map((e) => (
                <div key={e.id} className="iq4-share-wallet-modal__grid-item">
                  <strong>Item</strong>
                  <div>{e?.title}</div>
                  <div className="iq4-share-wallet-status-toggle">
                    <PrivatePublicToggle
                      isIncludeContext
                      record={{
                        isPublic: !!sharingFlags.profile.experience?.find((x) => x.id === e.id)
                          ?.isShareable,
                      }}
                      ariaLabel={`Toggle ${e?.title} Experience to be public or private`}
                      onSelecting={({ selected }) =>
                        setSharingFlags({
                          ...sharingFlags,
                          profile: {
                            ...sharingFlags.profile,
                            experience: [
                              ...sharingFlags.profile.experience.filter((x) => x.id !== e.id),
                              {
                                id: e.id,
                                isShareable: selected,
                              },
                            ],
                          },
                        })
                      }
                    />
                  </div>
                </div>
              ))}
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="skillsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Skills</h2>
              </div>
            </div>
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Item</strong>
              <div>At a Glance</div>
              <div className="iq4-share-wallet-status-toggle">
                <PrivatePublicToggle
                  isIncludeContext
                  record={{ isPublic: !!sharingFlags.profile.skills.atAGlance }}
                  ariaLabel={`Toggle At a Glance to be public or private`}
                  onSelecting={({ selected }) =>
                    setSharingFlags({
                      ...sharingFlags,
                      profile: {
                        ...sharingFlags.profile,
                        skills: {
                          ...sharingFlags.profile.skills,
                          atAGlance: selected,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Item</strong>
              <div>T-Shape</div>
              <div className="iq4-share-wallet-status-toggle">
                <PrivatePublicToggle
                  isIncludeContext
                  record={{ isPublic: !!sharingFlags.profile.skills.tShape }}
                  ariaLabel={`Toggle T-Shape to be public or private`}
                  onSelecting={({ selected }) =>
                    setSharingFlags({
                      ...sharingFlags,
                      profile: {
                        ...sharingFlags.profile,
                        skills: {
                          ...sharingFlags.profile.skills,
                          tShape: selected,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            {!!portfolio?.length && (
              <div className="iq4-share-wallet-modal__content__body__section">
                <div className="iq4-share-wallet__heading-section">
                  <SimpleIcon
                    className="iq4-share-wallet__heading-icon"
                    name="portfolioCircle"
                    role="presentation"
                    hidden
                  />
                  <h2 className="iq4-share-wallet__heading-title">Portfolio</h2>
                </div>
              </div>
            )}
            {portfolio?.map((p) => (
              <div key={p.id} className="iq4-share-wallet-modal__grid-item">
                <strong>Item</strong>
                <div>{p?.title}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{
                      isPublic: !!sharingFlags.profile.portfolio?.find((x) => x.id === p.id)
                        ?.isShareable,
                    }}
                    ariaLabel={`Toggle ${p?.title} to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          portfolio: [
                            ...sharingFlags.profile.portfolio.filter((x) => x.id !== p.id),
                            {
                              id: p.id,
                              isShareable: selected,
                            },
                          ],
                        },
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 'skills':
        return (
          <div id="skills-tabpanel" role="tabpanel" aria-labelledby="skills-tab">
            <div
              className="iq4-share-wallet-modal__grid-item"
              style={{ marginBottom: '2rem', borderBottom: 'none' }}
            >
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="skillsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Skills</h2>
              </div>
              <strong></strong>
              <div className="iq4-share-wallet__toggle-all">
                <strong className="iq4-share-wallet__toggle-all--label">Toggle all:</strong>
                <PrivatePublicToggle
                  isIncludeContext
                  record={{ isPublic: toggleAllSkills }}
                  ariaLabel={`Toggle all skills to be public or private`}
                  onSelecting={({ selected }) => setToggleAllSkills(selected)}
                />
              </div>
            </div>
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Skill Title</strong>
              <strong style={{ textAlign: 'center' }}>Proficiency</strong>
              <strong style={{ textAlign: 'center' }}>Action</strong>
            </div>
            {skills?.map((s) => {
              const selfAssessment = s.assessment;

              return (
                <div key={s.id} className="iq4-share-wallet-modal__grid-item">
                  <div>{s?.value}</div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <RatingStars
                      readOnly={true}
                      value={selfAssessment || 0}
                      isSelf
                      onValueSelect={() => {}}
                    />
                  </div>
                  <div className="iq4-share-wallet-status-toggle">
                    <PrivatePublicToggle
                      isIncludeContext
                      record={{
                        isPublic: !sharingFlags.skills?.find((x) => x.competenceId === s.id)
                          ? true
                          : !!sharingFlags.skills?.find((x) => x.competenceId === s.id)
                              ?.isShareable,
                      }}
                      ariaLabel={`Toggle ${s?.value} to be public or private`}
                      onSelecting={({ selected }) =>
                        setSharingFlags({
                          ...sharingFlags,
                          skills: [
                            ...sharingFlags.skills?.filter((x) => x.competenceId !== s.id),
                            {
                              competenceId: s.id,
                              isShareable: selected,
                            },
                          ],
                        })
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      case 'credentials':
        return (
          <div id="credentials-tabpanel" role="tabpanel" aria-labelledby="credentials-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="credentialsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Credentials</h2>
              </div>
            </div>
            {currentCredentials?.map((c) => (
              <div
                key={c.id}
                className="iq4-share-wallet-modal__grid-item iq4-share-wallet-modal__grid-item-2"
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  {c?.imageUrl && (
                    <img
                      className="iq4-record-image-and-label__image"
                      src={c?.imageUrl}
                      alt="Logo"
                    />
                  )}
                  <div>{c?.label || c?.name}</div>
                  {!!c.isVerified && (
                    <div className="iq4-share-wallet__verified">
                      <Verified />
                    </div>
                  )}
                </div>
                {c.issuerName && <div>{c.issuerName}</div>}
                {!c.issuerName && <div>&nbscp;</div>}
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{
                      isPublic: !!sharingFlags.credentials?.find((x) => x.id === c.id)?.isShareable,
                    }}
                    ariaLabel={`Toggle ${c?.label} to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        credentials: [
                          ...sharingFlags.credentials?.filter((x) => x.id !== c.id),
                          {
                            id: c.id,
                            isShareable: selected,
                          },
                        ],
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 'pathways':
        return (
          <div id="pathways-tabpanel" role="tabpanel" aria-labelledby="pathways-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="pathwaysGraphCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Career Pathways</h2>
              </div>
            </div>
            {selectedPathwayRoles?.map((p) => (
              <div key={p.id} className="iq4-share-wallet-modal__grid-item">
                <strong>Item</strong>
                <div>{p?.label}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    isIncludeContext
                    record={{
                      isPublic: !!sharingFlags.pathways?.find((x) => x.jobId === p.id)?.isShareable,
                    }}
                    ariaLabel={`Toggle ${p?.label} to be public or private`}
                    onSelecting={({ selected }) => {
                      const existingPathway = sharingFlags.pathways?.find((x) => x.jobId === p.id);
                      if (existingPathway) {
                        setSharingFlags({
                          ...sharingFlags,
                          pathways: [
                            ...sharingFlags.pathways.filter((x) => x.jobId !== p.id),
                            {
                              ...existingPathway,
                              isShareable: selected,
                            },
                          ],
                        });
                      } else {
                        setSharingFlags({
                          ...sharingFlags,
                          pathways: [
                            ...sharingFlags.pathways,
                            {
                              jobId: p.id,
                              isShareable: selected,
                            },
                          ],
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        );
    }
  }

  async function shareWallet() {
    if (shareStep === 2) {
      onClose();
      return;
    }

    if (!!enableWalletSharing) {
      await dispatch(shareProfile(sharingFlags));
    }
    setShareStep(2);
  }

  function closeModal() {
    setIsSharingProfile(false);
    onClose();
  }

  function copyLinkClick() {
    navigator.clipboard.writeText(currentAddress);
    setLinkCopied(true);
  }

  function renderNormalContent() {
    return (
      <>
        <div className="iq4-share-wallet-modal__content">
          <div className="iq4-share-wallet-modal__content__header">
            <h1>Share Wallet</h1>
            {shareStep === 1 && (
              <div className="iq4-share-wallet-modal__content__header__description">
                Check and set your permissions to share with your public profile. You can set any
                item public or private.
              </div>
            )}
          </div>
          {shareStep === 1 && (
            <div className="iq4-share-wallet-modal__content__body">
              <div role="tablist" className="iq4-share-wallet-modal__tabs-container">
                <div
                  className={`iq4-share-wallet-modal__tab ${
                    activeTab === 'profile' ? 'iq4-share-wallet-modal__tab--active' : ''
                  }`}
                  onClick={() => setActiveTab('profile')}
                  onKeyPress={() => setActiveTab('profile')}
                  id="profile-tab"
                  role="tab"
                  aria-selected={activeTab === 'profile'}
                  aria-controls="profile-tabpanel"
                  tabIndex={0}
                >
                  Profile
                </div>
                <div
                  className={`iq4-share-wallet-modal__tab ${
                    activeTab === 'skills' ? 'iq4-share-wallet-modal__tab--active' : ''
                  }`}
                  onClick={() => setActiveTab('skills')}
                  onKeyPress={() => setActiveTab('skills')}
                  id="skills-tab"
                  role="tab"
                  aria-selected={activeTab === 'skills'}
                  aria-controls="skills-tabpanel"
                  tabIndex={0}
                >
                  Skills
                </div>
                <div
                  className={`iq4-share-wallet-modal__tab ${
                    activeTab === 'credentials' ? 'iq4-share-wallet-modal__tab--active' : ''
                  }`}
                  onClick={() => setActiveTab('credentials')}
                  onKeyPress={() => setActiveTab('credentials')}
                  id="credentials-tab"
                  role="tab"
                  aria-selected={activeTab === 'credentials'}
                  aria-controls="credentials-tabpanel"
                  tabIndex={0}
                >
                  Credentials
                </div>
                <div
                  className={`iq4-share-wallet-modal__tab ${
                    activeTab === 'pathways' ? 'iq4-share-wallet-modal__tab--active' : ''
                  }`}
                  onClick={() => setActiveTab('pathways')}
                  onKeyPress={() => setActiveTab('pathways')}
                  id="pathways-tab"
                  role="tab"
                  aria-selected={activeTab === 'pathways'}
                  aria-controls="pathways-tabpanel"
                  tabIndex={0}
                >
                  Career Pathways
                </div>
              </div>
              {renderTabContent()}
            </div>
          )}
          {shareStep === 2 && (
            <div className="iq4-share-wallet-modal__content__body">
              <div className="iq4-share-wallet__divider" />
              <div className="iq4-share-wallet__row-grid">
                <div className="iq4-share-wallet__row-grid__item">
                  <strong>Link</strong>
                </div>
                <div className="iq4-share-wallet__row-grid__item">
                  {!!enableWalletSharing && <div>{currentAddress}</div>}
                  {!enableWalletSharing && (
                    <div style={{ fontStyle: 'italic', color: '#C21807', fontWeight: 'bold' }}>
                      Coming soon!
                    </div>
                  )}
                  <div>
                    Note: This is a Public URL and anyone with the link can view your wallet
                  </div>
                  <div>Created: {moment(new Date()).format('MM/DD/YYYY')}</div>
                </div>
                <div className="iq4-share-wallet__row-grid__item">
                  <Button onClick={copyLinkClick} disabled={linkCopied || !enableWalletSharing}>
                    <SimpleIcon name="link" />
                    {!linkCopied && <span>Copy Link</span>}
                    {linkCopied && <span>Link Copied!</span>}
                  </Button>
                </div>
              </div>
              <div className="iq4-share-wallet__divider" />
              <div className="iq4-share-wallet__row-grid">
                <div className="iq4-share-wallet__row-grid__item">
                  <strong>Permissions</strong>
                </div>
                <div className="iq4-share-wallet__row-grid__item">
                  <div>Want to check your sharing permissions again?</div>
                </div>
                <div className="iq4-share-wallet__row-grid__item">
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => setShareStep(1)}
                    onClick={() => {
                      setShareStep(1);
                      setLinkCopied(false);
                    }}
                    className="iq4-share-wallet__open-sharing"
                  >
                    Open Sharing Permissions
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="iq4-share-wallet-modal__footer">
          <Button className="iq4-share-wallet-modal__button" onClick={shareWallet}>
            {!isSharingProfile && shareStep === 1 && <span>Next</span>}
            {isSharingProfile && <CircularProgress size={20} />}
            {shareStep === 2 && <span>Close</span>}
          </Button>
          {shareStep === 1 && (
            <div role="button" tabIndex={0} onClick={closeModal}>
              Cancel
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={closeModal}
      className={`${
        showDisclaimer ? 'iq4-share-wallet-modal-disclaimer' : 'iq4-share-wallet-modal'
      } ${shareStep === 2 ? 'iq4-share-wallet-modal-2' : ''}`}
    >
      <span
        aria-live="polite"
        role="alert"
        className="sr-only"
        aria-label={`This modal is used to configure your Public Wallet Settings. In here, you'll be able to define, on a granular level, the visibility of each type of item within your wallet.`}
      ></span>
      {renderNormalContent()}
    </Modal>
  );
};
