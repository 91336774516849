import React from 'react';
import { SkillsStatsChart, SkillsLegendTile } from 'common/components';
import './SkillsAnalytics.scss';

export const SkillsAnalytics = ({ analytics = {}, onClickSegment, withLegend = true }) => {
  return (
    <div className="iq4-skills-analytics">
      <SkillsStatsChart
        withLegend={withLegend}
        showTopLabels={false}
        className="iq4-skills-analytics__skills-stats-chart"
        skillsMetrics={[
          {
            id: 'MATCHED_SKILLS',
            type: 'matched',
            count: analytics?.overlapSkills?.length,
            label: (
              <>
                <SkillsLegendTile type="matched" />
                <span>Matched</span>
              </>
            ),
            description: 'This is the description for matched skills',
          },
          {
            id: 'MISSING_SKILLS',
            type: 'missing',
            count: analytics?.roleSkills?.length,
            label: (
              <>
                <SkillsLegendTile type="missing" />
                <span>Missing</span>
              </>
            ),
            description: 'This is the description for missing skills',
          },
        ]}
        onClick={(bar) => onClickSegment(bar)}
      />
    </div>
  );
};
