import { LCN_RECORD_STATES, LCN_SHARE_HISTORY_ALL_STATUSES } from 'common/constants/lcnRecords';

export const selectCounts = (state) => {
  const counts = state.records.counts;
  const counsellorConnectionsCount = (state.connections.currentInvites || []).filter(
    (x) => x.status === 1,
  ).length;

  return {
    ...counts,
    total:
      counts.credentailsCountNew === 0 // NOTE: if no new credentials
        ? 1 + counts.connectionsCountNew + counsellorConnectionsCount // NOTE: add 1 to total count (for pathways notification)
        : counts.credentailsCountNew + counts.connectionsCountNew + counsellorConnectionsCount, // NOTE: else, combine credentials + connections count
  };
};

export const selectCredentials = (state) => state.records.credentials;

export const selectCredentialSummaryAPICalled = (state) => state.records.credentialSummaryAPICalled;

export const selectCredentialsByStatusAndType = (state, status, type) => {
  return state.records.credentials.filter((credential) => {
    return credential.type === type && credential.status === status;
  });
};

export const selectOtherCredentialsByStatus = (state, matchedCredentials = [], status) => {
  return state.records.credentials.filter((credential) => {
    return credential.status === status && !matchedCredentials.includes(credential.id);
  });
};

export const selectOtherCredentialsByStatusAndType = (
  state,
  matchedCredentials = [],
  status,
  type,
) => {
  return state.records.credentials.filter((credential) => {
    return (
      !matchedCredentials.includes(credential.id) &&
      credential.type === type &&
      credential.status === status
    );
  });
};

export const selectCredentialsByStatus = (state, status) => {
  return state.records.credentials.filter((credential) => {
    return credential.status === status;
  });
};

export const selectCredentialsByStatusOrRevoked = (state, status) => {
  return state.records.credentials.filter((credential) => {
    return credential.status === status || credential.status === 'VC_REVOKED';
  });
};

export const selectCredentialsByIds = (state, ids = []) => {
  return state.records.credentials.filter((credential) => {
    return ids && ids.includes(credential.id);
  });
};

export const selectCredentialsByIdsAndType = (state, ids, type) => {
  return state.records.credentials.filter((credential) => {
    return (
      (credential.type === type && ids && ids.includes(credential.id)) ||
      (credential.type === type && ids && ids.includes(credential.id.split('VC_')[1]))
    ); // TODO: Remove this split once ids aligned
  });
};

export const selectVerifierOrganizations = (state) => {
  return state.records.verifierOrganizations;
};

export const selectPersonalInformation = (state) => {
  return state.records.personalInformation;
};

export const selectVerifyCredentialsURL = (state) => {
  return state.records.verifyCredentialsURL || '';
};

export const selectConnectionsByStatus = (state, status) => {
  const credentials = state.records.credentials;

  const getCredentialsMatchedByIds = (credentialsMatchedIds, credentials) => {
    return credentials.filter((credential) => {
      return (
        credentialsMatchedIds.includes(credential.id) ||
        credentialsMatchedIds.includes(credential.id.split('VC_')[1])
      ); // TODO: Remove this split once ids aligned
    });
  };

  return state.records.connections
    .filter((connection) => connection.status === status)
    .map((connection) => ({
      ...connection,
      credentialsMatched: connection.credentialsMatched
        ? getCredentialsMatchedByIds(connection.credentialsMatched, credentials)
        : [],
    }));
};

export const selectConnectionsHistory = (state, status) => {
  const credentials = state.records.credentials;

  const getCredentialsMatchedByIds = (credentialsMatchedIds, credentials) =>
    credentials.filter((credential) => credentialsMatchedIds.includes(credential.id));

  return state.records.connections
    .filter((connection) => LCN_SHARE_HISTORY_ALL_STATUSES.includes(connection.status))
    .map((connection) => ({
      ...connection,
      credentialsMatched: connection.credentialsMatched
        ? getCredentialsMatchedByIds(connection.credentialsMatched, credentials)
        : [],
    }));
};

export const selectShareRecordsCount = (state) => {
  const allConnections = state.records.connections;

  return {
    total: allConnections.length,
    requests: allConnections.filter((record) => record.status === LCN_RECORD_STATES.NEW).length,
    shared: allConnections.filter((record) => record.status === LCN_RECORD_STATES.ACTIVE).length,
  };
};

export const selectErrors = (state) => {
  return state.records.errors;
};

export const selectDisplayConnectToProvider = (state) => {
  // user does not have badgr account and have not choosen to skip
  return (
    state?.records?.profile?.badgrAccount === false &&
    state?.records?.profile?.skipBadgrConnection === false
  );
};

export const selectBadgrAccountEnabled = (state) => state?.records?.profile?.badgrAccount;

export const selectConsentForSignaling = (state) =>
  state.records.profile
    ? {
        status: state?.records?.profile?.consentForSignaling,
        message: state?.records?.profile?.consentTextForSignaling,
      }
    : null;

export const selectConsentForPlanning = (state) =>
  state.records.profile
    ? {
        status: state?.records?.profile?.consentForPlanning,
        message: state?.records?.profile?.consentTextForPlanning,
      }
    : null;

export const selectProviderRedirectUrl = (state) => state.records.providerRedirectUrl;

export const selectUI = (state) => state.records.ui;

export const selectDisputeStatusByCredentialId = (state, credentialId) => {
  const credential = state?.records?.credentials?.find(
    (credential) => credential?.id === credentialId,
  );

  return credential ? credential.dispute : false;
};
