import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { SKILLS_OVERLAP_COLOR_SCHEME } from 'common/constants/miscellaneous';
import './SkillsOverlapBarChart.scss';

export const BAR_IDS = {
  UNMATCHED_SKILLS: 'UNMATCHED_SKILLS',
  MATCHED_SKILLS: 'MATCHED_SKILLS',
  MISSING_SKILLS: 'MISSING_SKILLS',
};

/**
 * Home to the Skills Overlap Bar Chart
 * @param {number} mySkillsCount - Skills that the user has that are not associated with the current role
 * @param {number} overlapSkillsCount - Skills that the user has that are associated with the current role
 * @param {number} roleSkillsCount - Skills that the user doesn’t have that are associated with the current role
 * @param {object} [labels] - optional to specify the label names
 * @param {boolean} [withLegend] - Includes the bar chart legend if set
 * @param {string} [active] - Set's the lables active if one of the BAR_IDS are set
 * @param {string} [className] - Custom className
 * @param {number} [onClick] - Click event handler on bars
 * @param {object} [barProps] - override bar chart props
 */
export const SkillsOverlapBarChart = ({
  mySkillsCount,
  overlapSkillsCount,
  roleSkillsCount,
  labels = { left: 'Unmatched skills', middle: 'Matched skills', right: 'Missing skills' },
  withLegend,
  active,
  className,
  barProps = {},
  onClick = () => {},
}) => {
  const renderLegendLabel = (skillsCount, barID, label) => {
    return (
      <div className="iq4-skills-overlap-chart__legend-item">
        <span
          className={`iq4-skills-overlap-chart__legend-item-label ${
            !!active
              ? active === barID
                ? 'iq4-skills-overlap-chart__legend-item-label--active'
                : 'iq4-skills-overlap-chart__legend-item-label--unactive'
              : ''
          }`}
        >
          {label}
        </span>
        <span
          className={`iq4-skills-overlap-chart__legend-item-value ${
            active === barID ? 'iq4-skills-overlap-chart__legend-item-value--active' : ''
          }`}
        >
          {skillsCount}
        </span>
      </div>
    );
  };

  return (
    <div className={`iq4-skills-overlap-chart ${className ? className : ''}`}>
      {withLegend && (
        <div className="iq4-skills-overlap-chart__top-legend-container">
          <div className="iq4-skills-overlap-chart__top-legend-item">
            <div className="iq4-skills-overlap-chart__top-legend-indicator"></div>
            <div className="iq4-skills-overlap-chart__top-legend-label">
              Your skills that are not needed for this role
            </div>
          </div>
          <div className="iq4-skills-overlap-chart__top-legend-item">
            <div className="iq4-skills-overlap-chart__top-legend-indicator">
              <ResponsiveBar
                data={[
                  {
                    [BAR_IDS.OVERLAP_SKILLS]: 1,
                  },
                ]}
                keys={[BAR_IDS.OVERLAP_SKILLS]}
                layout="horizontal"
                padding={0}
                colors={(bar) => SKILLS_OVERLAP_COLOR_SCHEME.MATCHED_BACKGROUND}
                enableLabel={false}
                axisLeft={null}
                axisRight={null}
                axisBottom={null}
                defs={[
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: SKILLS_OVERLAP_COLOR_SCHEME.MATCHED_BACKGROUND,
                    color: SKILLS_OVERLAP_COLOR_SCHEME.MATCHED_LINES,
                    rotation: -45,
                    lineWidth: 5,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: BAR_IDS.MATCHED_SKILLS,
                    },
                    id: 'lines',
                  },
                ]}
                isInteractive={false}
              />
            </div>
            <div className="iq4-skills-overlap-chart__top-legend-label">
              The skills you have that overlap with this role
            </div>
          </div>
          <div className="iq4-skills-overlap-chart__top-legend-item">
            <div className="iq4-skills-overlap-chart__top-legend-indicator"></div>
            <div className="iq4-skills-overlap-chart__top-legend-label">
              Skills you don’t have but need for this role
            </div>
          </div>
        </div>
      )}

      <div className="iq4-skills-overlap-chart__bar-container">
        <ResponsiveBar
          onClick={(bar) => {
            onClick(bar.id);
          }}
          data={[
            {
              [BAR_IDS.UNMATCHED_SKILLS]: mySkillsCount,
              [BAR_IDS.MATCHED_SKILLS]: overlapSkillsCount,
              [BAR_IDS.MISSING_SKILLS]: roleSkillsCount,
            },
          ]}
          keys={[BAR_IDS.UNMATCHED_SKILLS, BAR_IDS.MATCHED_SKILLS, BAR_IDS.MISSING_SKILLS]}
          layout="horizontal"
          padding={0}
          colors={(bar) => {
            switch (bar.id) {
              case BAR_IDS.UNMATCHED_SKILLS:
                return SKILLS_OVERLAP_COLOR_SCHEME.UNMATCHED_SKILL;
              case BAR_IDS.MATCHED_SKILLS:
                return SKILLS_OVERLAP_COLOR_SCHEME.MATCHED_BACKGROUND;
              case BAR_IDS.MISSING_SKILLS:
                return SKILLS_OVERLAP_COLOR_SCHEME.MISSING_SKILLS;
              default:
                return;
            }
          }}
          enableLabel={false}
          axisLeft={null}
          axisRight={null}
          axisBottom={null}
          defs={[
            {
              id: 'lines',
              type: 'patternLines',
              background: SKILLS_OVERLAP_COLOR_SCHEME.MATCHED_BACKGROUND,
              color: SKILLS_OVERLAP_COLOR_SCHEME.MATCHED_LINES,
              rotation: -45,
              lineWidth: 5,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: BAR_IDS.MATCHED_SKILLS,
              },
              id: 'lines',
            },
          ]}
          isInteractive={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          {...barProps}
        />
      </div>
      <div className="iq4-skills-overlap-chart__legend">
        {!!mySkillsCount && (
          <>
            {!!overlapSkillsCount &&
              renderLegendLabel(mySkillsCount, BAR_IDS.UNMATCHED_SKILLS, labels.left)}

            {!!overlapSkillsCount &&
              renderLegendLabel(overlapSkillsCount, BAR_IDS.MATCHED_SKILLS, labels.middle)}

            {!!roleSkillsCount &&
              renderLegendLabel(roleSkillsCount, BAR_IDS.MISSING_SKILLS, labels.right)}
          </>
        )}
      </div>
    </div>
  );
};
