import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import './RoleEduChart.scss';

/**
 * Render the education chart for work roles
 * @param {object[]} levels - The levels attained by people in the work role
 * @param {function} [onClick] - Handle clicking one of the bar segments
 */
export const RoleEduChart = ({ levels, onClick = () => {} }) => {
  const renderBars = (barsData) => {
    return barsData.map((bar) => {
      return (
        <Tooltip
          arrow
          classes={{
            popper: 'iq4-role-edu-chart__tooltip',
          }}
          title={<div className="iq4-role-edu-chart__tooltip-label">{bar.EducationLevel}</div>}
          key={bar.EducationLevel}
        >
          <div
            className="iq4-role-edu-chart__bar"
            style={{ width: `${bar.Value}%` }}
            onClick={() => void onClick(bar)}
          ></div>
        </Tooltip>
      );
    });
  };
  return <div className="iq4-role-edu-chart">{renderBars(levels)}</div>;
};
