import React from 'react';
import { Button } from 'common/components/Button';
import './EmptyState.scss';

export const EmptyState = ({ text, description, action, actionLabel, maxWidth }) => {
  return (
    <div className="iq4-empty-state">
      {text && <p className="iq4-empty-state__text">{text}</p>}
      {description && <p className="iq4-empty-state__description">{description}</p>}
      {action && actionLabel && (
        <Button
          className={`iq4-empty-state__button  ${maxWidth && 'iq4-empty-state__button--maxwidth'}`}
          onClick={action}
        >
          {actionLabel}
        </Button>
      )}
    </div>
  );
};
