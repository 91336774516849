import React from 'react';
import { FormWrapper } from 'common/components/Form';
import { useUserFeedbackFormDefinition } from './useUserFeedbackFormDefinition';

import './UserFeedbackForm.scss';

export const UserFeedbackForm = ({ onError, onSuccess }) => {
  const handleUserFeedbackComplete = (status) => {
    if (!!status.error) {
      onError(status);
      return;
    }

    onSuccess(status);
  };

  return (
    <div className="iq4-user-feedback-form">
      <FormWrapper
        submitLabel="Submit feedback"
        showButtons
        values={{ feedbackType: 'issue' }}
        useFormDefinition={useUserFeedbackFormDefinition}
        onComplete={handleUserFeedbackComplete}
      />
    </div>
  );
};
