import React, { useState } from 'react';
import { ROUTES } from 'routes/routeNames';
import { useSelector } from 'react-redux';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { FormattedMessage } from 'react-intl';
import { ActionCard } from 'common/components/ActionCard';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import PortfolioItem from 'components/Profile/PortfolioItem';
import { TriggerModal } from 'common/components/TriggerModal';
import { EmptyState } from 'common/components/EmptyState';
import { ProfilePortfolioForm } from 'common/components/Forms';
import { OnboardingHeader } from 'common/components/Onboarding';
import GraphicPortfolio from '@assets/svg/empty-states_portfolio.svg';
import { Modal } from 'common/components/Modal';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { selectFeatures } from 'store/features/config/selectors';
import './ProfilePortfolioDetail.scss';
import { useParams } from 'react-router-dom';
import { FormWrapper } from '../../../common/components';
import { portfolioFormDefinitions } from '../../../common/utils';

export const ProfilePortfolioDetail = () => {
  const state = useSelector(selectProfile);
  const breakpoint = useBreakpoints();
  const { profilePortfolioLabel } = useSelector(selectFeatures);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const isPopulated = state.portfolio.length > 0;
  const isMobile = useIsMobile();
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const { id } = useParams();
  const [editedItem, setEditedItem] = useState(null);

  const handleNewModalClose = () => void setIsNewModalOpen(false);
  const handleNewModalOpen = () => void setIsNewModalOpen(true);

  const transformAssociatedRecord = (item) => ({
    ...item,
    ...(item.associatedRecord && {
      associatedRecord: {
        id: item.associatedRecord.id,
        type: item.associatedRecord.type,
      },
    }),
  });

  return (
    <ActionCard
      title={
        <div className="iq4-profile-portfolio-detail__title">
          {profilePortfolioLabel}
          {!isPublicProfile && (
            <TriggerModal
              title={<OnboardingHeader icon="portfolioCircle" title={profilePortfolioLabel} />}
              form={<ProfilePortfolioForm />}
              label={<FormattedMessage id="profile.addAnother.label" />}
              ariaLabel="Select to add another Portfolio item. Opens a modal to add a new Portfolio item."
            />
          )}
        </div>
      }
      icon="portfolioCircle"
      isDivideVisible={false}
      isHeaderVisible={!isMobile}
      backLink={isPublicProfile ? `${ROUTES.PUBLIC_WALLET}/user/${id}` : ROUTES.PROFILE_ROOT}
    >
      {isPopulated && (
        <ul className="iq4-profile-portfolio-detail__list">
          {state.portfolio.map((item, i) => (
            <>
              <li className="iq4-profile-portfolio-summary__list-item" key={i}>
                <PortfolioItem item={item} setEditedItem={setEditedItem} />
              </li>
            </>
          ))}
        </ul>
      )}

      {editedItem && (
        <Modal isOpen={true} title="Edit" handleClose={() => setEditedItem(null)}>
          <FormWrapper
            values={transformAssociatedRecord(editedItem)}
            useFormDefinition={portfolioFormDefinitions[editedItem?.type]}
            onComplete={() => setEditedItem(null)}
          />
        </Modal>
      )}

      {!isPopulated && (
        <div className="iq4-profile-portfolio-summary__empty-state-section">
          <img
            src={GraphicPortfolio}
            alt="Graphic for Portfolio"
            aria-hidden="true"
            className="iq4-profile-portfolio-summary___empty-state-graphic"
          />
          <div className="iq4-profile-portfolio-summary___empty-state-wrapper">
            {!isPublicProfile && (
              <EmptyState
                text="A fully filled out portfolio looks more attractive."
                description="You can add any media here that you want. This is your portfolio. You can highlight featured projects and associate any projects with your education or experience!"
                action={handleNewModalOpen}
                actionLabel="Add a Portfolio Item"
              />
            )}
          </div>
        </div>
      )}

      <Modal
        isOpen={isNewModalOpen}
        handleClose={handleNewModalClose}
        title={profilePortfolioLabel}
      >
        <ProfilePortfolioForm onComplete={handleNewModalClose} />
      </Modal>
    </ActionCard>
  );
};
