import { createSelector } from '@reduxjs/toolkit';
import { ensureHTTPSProtocol } from 'common/utils';
import moment from 'moment';

const selectProfileEducation = (state) => state?.profile?.education;
const selectParsedResumeEducation = (state) =>
  state?.parsedResume?.data?.education?.EducationDetails;

const selectProfileExperience = (state) => state?.profile?.experience;
const selectParsedResumeExperience = (state) =>
  state?.parsedResume?.data?.employmentHistory?.Positions;

const selectParsedResumeSkills = (state) => state?.parsedResume?.data?.skills;

export const selectResumeParserPersonal = createSelector(
  (state) => state?.parsedResume?.data,
  (data) => {
    if (!data) return null;

    const { GivenName: firstName, FamilyName: lastName } =
      data?.contactInformation?.CandidateName || {};

    const email = data?.contactInformation?.EmailAddresses
      ? data?.contactInformation?.EmailAddresses?.[0]
      : '';

    const description =
      data?.professionalSummary ||
      data?.qualificationsSummary ||
      data?.employmentHistory?.ExperienceSummary?.Description;

    const currentPosition = data?.employmentHistory?.Positions?.[0]?.JobTitle?.Normalized;

    const linkedInData = data?.contactInformation?.WebAddresses?.filter(
      (webAddress) => webAddress.Type === 'LinkedIn',
    );
    const linkedIn = linkedInData?.length > 0 ? ensureHTTPSProtocol(linkedInData[0].Address) : '';

    return {
      firstName,
      lastName,
      email,
      description,
      currentPosition,
      linkedIn,
    };
  },
);

const formatLocation = (location) => {
  if (!location) return null;

    if (location?.Municipality)
        return `${location?.Municipality}${
            location?.Municipality && location?.Regions?.[0] ? ', ' + location?.Regions?.[0] : ''
        }${
            location?.Municipality && location?.CountryCode && location?.CountryCode != 'US'
                ? ', ' + location?.CountryCode
                : ''
        }`;
    else if (location?.Regions)
        return location?.Regions?.[0];
    // not returning CountryCode because it is set to US even if that is not correct
    else return null;
};

export const selectResumeParserEducation = createSelector(
  [selectProfileEducation, selectParsedResumeEducation, selectParsedResumeSkills],
  (profileEducation, parsedResumeEducation, parsedResumeSkills) => {
    if (!parsedResumeEducation) return [];

    return parsedResumeEducation
      .map((education) => ({
        title: `${
          education?.Degree?.Name?.Normalized ? education?.Degree?.Name?.Raw : education?.Text
        }${
          education?.Degree?.Name?.Raw &&
          education?.Majors?.length > 0 &&
          !education?.Degree?.Name?.Raw?.endsWith(education?.Majors[0])
            ? ' ' + education?.Majors[0]
            : ''
        }`,
        major: education?.Majors?.length > 0 ? education?.Majors[0] : '',
        school: education?.SchoolName?.Normalized,
        degree: education?.Degree?.Name?.Normalized,
        location: formatLocation(education?.Location),
        ...(education?.EndDate?.FoundMonth && {
            endMonth: (moment(education?.EndDate?.Date, 'YYYY-MM-DD').month() + 1)
                .toString()
                .padStart(2, '0'),
        }),
        ...(education?.EndDate?.FoundYear && {
            endYear: moment(education?.EndDate?.Date, 'YYYY-MM-DD').year().toString(),
        }),
        ...(education?.StartDate?.FoundMonth && {
            startMonth: (moment(education?.StartDate?.Date, 'YYYY-MM-DD').month() + 1)
                .toString()
                .padStart(2, '0'),
        }),
        ...(education?.StartDate?.FoundYear && {
            startYear: moment(education?.StartDate?.Date, 'YYYY-MM-DD').year().toString(),
        }),
        gpa: education?.GPA?.Score,
      competencies: parsedResumeSkills
          .filter((skill) => skill?.foundIn?.find((record) => record.Id === education?.Id))
          .map((competency) => ({ id: competency.competenceId, value: competency.label })),
        type: 'resume',
        verified: false,
        reference: education?.Id,
      }))
      .filter((education) => {
        if (!education.title && !education.school) {
          return false;
        }

        const hasMatchingTitle = profileEducation.find(
          (item) => item.title === education.title || !education.title,
        );
        const hasMatchingSchool = profileEducation.find(
          (item) => item.school === education.school || !education.school,
        );

        return !(hasMatchingTitle && hasMatchingSchool);
      });
  },
);

export const selectResumeParserExperience = createSelector(
  [selectProfileExperience, selectParsedResumeExperience, selectParsedResumeSkills],
  (profileExperience, parsedResumeExperience, parsedResumeSkills) => {
    if (!parsedResumeExperience) return [];

    return parsedResumeExperience
      ?.map((experience) => ({
        title: experience?.JobTitle?.Normalized,
        description: experience?.Description,
        company: experience?.Employer?.Name?.Normalized,
        location: formatLocation(experience?.Employer?.Location),
        isCurrentRole: experience?.IsCurrent,
        startMonth: experience?.StartDate?.FoundMonth && experience?.StartDate?.Date
            ? (moment(experience?.StartDate?.Date, 'YYYY-MM-DD').month() + 1)
                .toString()
                .padStart(2, '0')
            : null,
        startYear: experience?.StartDate?.FoundYear && experience?.StartDate?.Date
            ? moment(experience?.StartDate?.Date, 'YYYY-MM-DD').year().toString()
            : null,
        endMonth: experience?.EndDate?.FoundMonth && experience?.EndDate?.Date
            ? (moment(experience?.EndDate?.Date, 'YYYY-MM-DD').month() + 1)
                .toString()
                .padStart(2, '0')
            : null,
        endYear: experience?.EndDate?.FoundYear && experience?.EndDate?.Date
            ? moment(experience?.EndDate?.Date, 'YYYY-MM-DD').year().toString()
            : null,
        competencies: parsedResumeSkills
          .filter((skill) => skill?.foundIn?.find((record) => record.Id === experience?.Id))
          .map((competency) => ({ id: competency.competenceId, value: competency.label })),
        type: 'resume',
        reference: experience?.Id,
      }))
      .filter((experience) => {
        if (!experience.title && !experience.company) {
          return false;
        }

        const hasMatchingTitle = profileExperience.find(
          (item) => item.title === experience.title || !experience.title,
        );
        const hasMatchingCompany = profileExperience.find(
          (item) => item.company === experience.company || !experience.company,
        );

        return !(hasMatchingTitle && hasMatchingCompany);
      });
  },
);

export const selectResumeParserSkills = createSelector(
  (state) => state?.parsedResume?.data?.skills,
  (skills) => {
    if (!skills) return [];

    return skills?.map((skill) => ({
      id: skill?.competenceId,
      value: skill?.label,
      tier1: skill?.tier1?.label,
      tier2: skill?.tier2?.label,
      selected: skill?.hasOwnProperty('checked') ? skill?.checked : true, // selected by default
      assessment: skill?.assessment,
      addedManually: skill?.addedManually,
    }));
  },
);
