import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ROUTES } from 'routes/routeNames';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { Topbar, Button, CircleNumber } from 'common/components';
import { FormWrapper } from 'common/components/Form';
import { Modal } from 'common/components/Modal';
import { ReactComponent as DiscoveryFrame } from 'common/assets/svg/discovery-frame.svg';
import { ReactComponent as DiscoveryArrow } from 'common/assets/svg/discovery_arrow.svg';
import { ReactComponent as ProfileIllustration } from '@assets/svg/discovery_dashboard_profile_illustration.svg';
import { ReactComponent as SkillsIllustration } from '@assets/svg/discovery_dashboard_skills_illustration.svg';
import { ReactComponent as CredentialsIllustration } from '@assets/svg/discovery_dashboard_credentials_illustration.svg';
import { ReactComponent as ExploreCareer } from '@assets/svg/discovery_dashboard_pathways_illustration.svg';
import placeholderAvatar from '@assets/svg/placeholder-avatar.svg';
import { fetchAllSkillsReq } from 'common/store/features/skills/skillsSlice';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { selectSkillsBreakUp } from 'common/store/features/skills/selectors';
import { selectProfile, selectUserProfile } from 'common/store/features/profile/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { useProfilePersonalFormDefinition } from 'common/hooks/forms';
import { ProfileResumeParse } from '../../components/Profile';
import { fetchUserSelectedWorkRolesReq } from 'common/store/features/pathways/pathwaysSlice';
import './DiscoveryDashboardPage.scss';
import { ProfileAvatar } from '../../components/Profile/ProfileAvatar';

export const DiscoveryDashboardPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [showResumeParser, setShowResumeParser] = useState(false);

  const { applicationTitle, personalProfileSource, modules } = useSelector(selectFeatures);
  const userProfile = useSelector(selectUserProfile);
  const { personal } = useSelector(selectProfile);
  const resolvedAvatarRequest = !!personal.userProfilePhoto;
  const skillsBreakup = useSelector(selectSkillsBreakUp);
  const hasPhoto = !!personal?.userProfilePhoto?.image;
  const avatarPhoto = personal?.userProfilePhoto?.image;
  const avatarAlt = personal?.userProfile?.user
    ? `${personal.userProfile.user.firstName} ${personal.userProfile.user.lastName}`
    : 'User Avatar';

  const userProfilePhotoData = personal?.userProfilePhoto || {
    id: null,
    image: null,
    imageName: null,
    thumbnail: null,
    type: null,
  };

  const onAdd = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    dispatch(fetchAllSkillsReq()); // get skills & add them to store
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
    dispatch(fetchUserSelectedWorkRolesReq());
  }, [dispatch, personalProfileSource]);

  if (!userProfile) {
    return null;
  }

  if (!userProfile?.hasCutRibbon) {
    history.push(ROUTES.ONBOARDING_WELCOME);
  } else if (!userProfile?.hasOnboarded) {
    history.push(ROUTES.ONBOARDING_RESUME);
  }

  return (
    <>
      <Helmet title={`${applicationTitle} - Discovery`} />
      <Topbar Logo={Logo} onUploadResume={() => setShowResumeParser(true)} />
      <main className="iq4-discovery__container" id="main">
        {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}
        <div className="iq4-discovery__avatar">
          <DiscoveryFrame />
          <ProfileAvatar />
        </div>
        <h1 className="iq4-discovery__title">
          <span className="iq4-discovery__title--name">
            Hi {userProfile?.user?.firstName?.toLowerCase()},
          </span>

          <span className="iq4-discovery__title--enhance">
            This is your <strong>discovery dashboard</strong>.{' '}
          </span>

          <span style={{ marginLeft: 8 }}> Where do you want to go from here?</span>
        </h1>
        <div className="iq4-discovery__sections">
          {modules.profile && (
            <div className="iq4-discovery__section">
              <span className="iq4-discovery__section--title">Profile</span>
              <span className="iq4-discovery__section--graphic  iq4-discovery__section--graphic-container">
                <ProfileIllustration />
              </span>
              <div className="iq4-discovery__text-container">
                Build out your <strong>Profile</strong> with skills and credentials you can share
                with employers
              </div>
              <Button
                className="iq4-discovery__button iq4-discovery__button--first"
                onClick={() => void history.push(ROUTES.PROFILE_ROOT)}
                variation="ghost"
              >
                Build My Profile
              </Button>
            </div>
          )}

          <div className="iq4-discovery__section">
            <span className="iq4-discovery__section--title">
              <div className="iq4-discovery__discovery-arrow">
                <DiscoveryArrow />
              </div>
              Credentials
            </span>
            <span className="iq4-discovery__section--graphic  iq4-discovery__section--graphic-container">
              <CredentialsIllustration />
            </span>
            <div className="iq4-discovery__text-container">
              Go to <strong>Credentials</strong> to add and manage all your educational
              achievements.
            </div>
            <div className="iq4-discovery__button-container">
              <Button
                className="iq4-discovery__button iq4-discovery__button--first"
                onClick={() => void history.push(ROUTES.CREDENTIALS)}
                variation="ghost"
              >
                Add a Credential
              </Button>
              <div className="iq4-discovery__button-recommended">RECOMMENDED</div>
            </div>
          </div>

          {modules.skills && (
            <div className="iq4-discovery__section">
              <span className="iq4-discovery__section--title">Skills</span>
              <span className="iq4-discovery__section--graphic  iq4-discovery__section--graphic-container">
                <SkillsIllustration />
              </span>
              <div className="iq4-discovery__text-container">
                Visit <strong>Skills</strong> to review, edit and add more skills to your Profile.
              </div>
              <Button
                className="iq4-discovery__button iq4-discovery__button--first"
                onClick={() => void history.push(ROUTES.MANAGE_SKILLS)}
                variation="ghost"
              >
                Manage my skills
              </Button>
            </div>
          )}

          <div className="iq4-discovery__section">
            <span className="iq4-discovery__section--title">Career Pathways</span>
            <span className="iq4-discovery__section--graphic iq4-discovery__section--graphic-container">
              <ExploreCareer />
            </span>
            <div className="iq4-discovery__text-container">
              Explore your <strong>Career Pathway</strong> job options from your current and
              projected skills and discover what employers you may want to connect with
            </div>
            <Button
              className="iq4-discovery__button"
              onClick={() => void history.push(ROUTES.CAREER_PATHWAYS)}
              variation="ghost"
            >
              Explore Career Pathways
            </Button>
          </div>
        </div>
      </main>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        title="Personal"
        className="iq4-personal-card__modal_edit"
      >
        <FormWrapper
          values={{
            ...userProfile,
            firstName: userProfile?.user?.firstName?.toLowerCase(),
            lastName: userProfile?.user?.lastName?.toLowerCase(),
            email: userProfile?.user?.email,
            image: userProfilePhotoData.image,
          }}
          onComplete={handleClose}
          useFormDefinition={useProfilePersonalFormDefinition}
        />
      </Modal>
    </>
  );
};
