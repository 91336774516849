import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { SimpleIcon, Button, TopSkillsHelp } from 'common/components';
import GraphicSkillsGroup from '@assets/svg/empty-states_skills.svg';
import { ROUTES } from 'routes/routeNames';
import { selectFeatures } from 'store/features/config/selectors';
import './TopSkillsCard.scss';

/**
 * @param {Object[]} topSkills - An array of objects containing skills
 * @param {bool} withoutWrapper - If this is true, the "Top x Skills" text, padding and border is removed
 * @param {string} emptyStateMessage - String to display within empty state
 * @param {bool} showManageYourSkills - If this is true, the "Manage your skills" button will be displayed
 * @param {string} seeMoreLink - Route / URL to use for See More link
 *
 */
export const TopSkillsCard = ({
  topSkills = [],
  withoutWrapper = false,
  emptyStateMessage = '',
  showManageYourSkills = true,
  seeMoreLink = null,
}) => {
  const history = useHistory();
  const { displayUpperTiers } = useSelector(selectFeatures);

  const renderSkillsList = (skills, withNumbers = false) => {
    return (
      <ul className="iq4-top-skills-card__skill-list">
        {skills.map((skill, index) => {
          return (
            <li key={skill.id} className="iq4-top-skills-card__skill-list-item">
              <div className="iq4-top-skills-card__checkmark">
                {withNumbers ? <span>{index + 1}</span> : <SimpleIcon name="tick" />}
              </div>
              <div className="iq4-top-skills-card__skill-name">
                <span className="iq4-top-skills-card__skill-name--tier3">{skill.value}</span>
                {displayUpperTiers && (
                  <span className="iq4-top-skills-card__skill-name--tier1-tier2">
                    {skill.tier1} &gt; {skill.tier2}
                  </span>
                )}
              </div>
              {/* Hiding proficiency stars until API is updated - https://iq4projects.atlassian.net/browse/LERI-52 */}
              {/* <div className="iq4-top-skills-card__assessment">
                <AssessmentStars assessment={skill.assessment} displayOnly />
              </div> */}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={`iq4-top-skills-card ${
        withoutWrapper ? 'iq4-top-skills-card--without-wrapper' : ''
      }`}
    >
      {!withoutWrapper && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h2 className="iq4-top-skills-card__title">TOP {topSkills.length} SKILLS</h2>
          <TopSkillsHelp additionalClass="focus-next" />
        </div>
      )}

      {!!topSkills.length && (
        <p className="iq4-top-skills-card__intro">
          This is your showcase of top skills. You can update this list on the{' '}
          <strong>
            <Link
              aria-label="This link will move you away from your Profile page into the Manage Skills page"
              to={ROUTES.MANAGE_SKILLS}
            >
              Skills
            </Link>
          </strong>{' '}
          page.
        </p>
      )}

      {!topSkills.length && (
        <div className="iq4-top-skills-card__empty-state-section">
          <div className="iq4-top-skills-card__empty-state-wrapper">
            <img
              src={GraphicSkillsGroup}
              alt="Empty State Graphic for Top 10 Skills"
              className="iq4-top-skills-card__empty-state-graphic"
            />
            <div className="iq4-top-skills-card__empty-state-section--text">
              You currently have no skills
            </div>
            <div>
              <Button
                className="iq4-discovery__button iq4-discovery__button--first"
                onClick={() => void history.push(ROUTES.MANAGE_SKILLS)}
                variation="ghost"
              >
                Manage skills
              </Button>
            </div>
          </div>
        </div>
      )}

      {topSkills.length > 0 && (
        <div className="iq4-top-skills-card__list-container">
          {renderSkillsList(topSkills, true)}
        </div>
      )}
      {seeMoreLink && (
        <div className="iq4-top-skills-card__see-more">
          <Link to={seeMoreLink}>
            See more <SimpleIcon name="arrow" />
          </Link>
        </div>
      )}
    </div>
  );
};

TopSkillsCard.propTypes = {
  topSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired])
        .isRequired,
      value: PropTypes.string.isRequired,
      // assessment: PropTypes.number.isRequired,
    }),
  ).isRequired,
  withoutWrapper: PropTypes.bool,
  emptyStateMessage: PropTypes.string,
  showManageYourSkills: PropTypes.bool,
  seeMoreLink: PropTypes.string,
};
