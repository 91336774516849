import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { selectAvatar, selectProfile } from 'common/store/features/profile/selectors';
import { selectOrderedSkills } from 'common/store/features/skills/selectors';
import { selectSelectedPathwaysSpokes } from 'common/store/features/pathways/selectors';
import { selectCredentialsForSharing } from 'common/store/features/credentials/selectors';
import { ActionIcon, Button, Modal, PrivatePublicToggle, SimpleIcon } from 'common/components';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';
import './ShareWalletEmployerModal.scss';
import { setIsSharingProfile } from '../../store/features/profile/profileSlice';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  getEmployerShareProfileSettings,
  shareEmployerProfile,
} from 'common/store/features/connections/connectionsSlice';
import {
  selectEmployerShareProfileSettings,
  selectIsFetchingEmployerShareProfileSettings,
  selectIsSharingEmployerProfile,
} from 'common/store/features/connections/selectors';
import { fetchAllSkillsReq } from '../../store/features/skills/skillsSlice';
import { fetchUserSelectedWorkRolesReq } from '../../store/features/pathways/pathwaysSlice';

export const ShareWalletEmployerModal = ({
  isOpen,
  onClose,
  onShare,
  counsellorId,
  counsellorFullName,
}) => {
  const [activeTab, setActiveTab] = useState('profile');
  const [sharingFlags, setSharingFlags] = useState(null);
  const [hasFetchedSkills, setHasFetchedSkills] = useState(false);
  const [hasFetchedPathways, setHasFetchedPathways] = useState(false);

  const { personal, education, experience, portfolio } = useSelector(selectProfile);
  const skills = useSelector(selectOrderedSkills);
  const selectedPathwayRoles = useSelector(selectSelectedPathwaysSpokes);
  const currentCredentials = useSelector(selectCredentialsForSharing);
  const hasPhoto = !!(personal && personal.userProfilePhoto && personal.userProfilePhoto.thumbnail);
  const userAvatar = useSelector(selectAvatar);
  const isSharingProfile = useSelector(selectIsSharingEmployerProfile);
  const isFetchingEmployerShareProfileSettings = useSelector(
    selectIsFetchingEmployerShareProfileSettings,
  );
  const shareProfileSettings = useSelector((state) =>
    selectEmployerShareProfileSettings(state, counsellorId),
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!shareProfileSettings && personal?.userProfile?.user?.id && counsellorId) {
      dispatch(
        getEmployerShareProfileSettings({ counsellorId, userId: personal?.userProfile?.user?.id }),
      );
    }
  }, [shareProfileSettings, personal, counsellorId]);

  useEffect(() => {
    setSharingFlags(shareProfileSettings);
  }, [shareProfileSettings]);

  function renderTabContent() {
    switch (activeTab) {
      case 'profile':
        return (
          <div id="profile-tabpanel" role="tabpanel" aria-labelledby="profile-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="personCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Personal</h2>
              </div>
            </div>
            {hasPhoto && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>Photo</strong>
                <img className="iq4-share-wallet-modal__avatar" src={userAvatar} alt="Avatar" />

                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{ isPublic: !!sharingFlags.profile.personal.photo }}
                    ariaLabel={`Toggle photo to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            photo: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Name</strong>
              <div>
                {personal?.userProfile?.user?.firstName} {personal?.userProfile?.user?.lastName}
              </div>
              <div className="iq4-share-wallet-status-toggle">
                <PrivatePublicToggle
                  record={{ isPublic: !!sharingFlags.profile.personal.name }}
                  ariaLabel={`Toggle name to be public or private`}
                  onSelecting={({ selected }) =>
                    setSharingFlags({
                      ...sharingFlags,
                      profile: {
                        ...sharingFlags.profile,
                        personal: {
                          ...sharingFlags.profile.personal,
                          name: selected,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            {!!personal?.userProfile?.currentPosition && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>Occupation</strong>
                <div>{personal?.userProfile?.currentPosition}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{ isPublic: !!sharingFlags.profile.personal.role }}
                    ariaLabel={`Toggle Occupation to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            role: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!personal?.userProfile?.description && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>Description</strong>
                <div className="iq4-share-wallet-modal__grid-item-long">
                  {personal?.userProfile?.description}
                </div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{ isPublic: !!sharingFlags.profile.personal.description }}
                    ariaLabel={`Toggle description to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            description: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!personal?.userProfile?.linkedIn && (
              <div className="iq4-share-wallet-modal__grid-item">
                <strong>LinkedIn</strong>
                <div>{personal?.userProfile?.linkedIn}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{ isPublic: !!sharingFlags.profile.personal.linkedIn }}
                    ariaLabel={`Toggle LinkedIn to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          personal: {
                            ...sharingFlags.profile.personal,
                            linkedIn: selected,
                          },
                        },
                      })
                    }
                  />
                </div>
              </div>
            )}
            {!!education?.length && (
              <div className="iq4-share-wallet-modal__content__body__section">
                <div className="iq4-share-wallet__heading-section">
                  <SimpleIcon
                    className="iq4-share-wallet__heading-icon"
                    name="educationCircle"
                    role="presentation"
                    hidden
                  />
                  <h2 className="iq4-share-wallet__heading-title">Education </h2>
                </div>
              </div>
            )}
            {education
              ?.filter((x) => !!x.major)
              .map((course) => (
                <div key={course.id} className="iq4-share-wallet-modal__grid-item">
                  <div>
                    <strong>Course</strong>
                    {!!course.verifiedBy && (
                      <div className="iq4-share-wallet__verified">
                        <Verified />
                      </div>
                    )}
                  </div>
                  <div>{course?.major}</div>
                  <div className="iq4-share-wallet-status-toggle">
                    <PrivatePublicToggle
                      record={{
                        isPublic: !!sharingFlags.profile.education?.find((x) => x.id === course.id)
                          ?.isShareable,
                      }}
                      ariaLabel={`Toggle ${course?.major} course to be public or private`}
                      onSelecting={({ selected }) =>
                        setSharingFlags({
                          ...sharingFlags,
                          profile: {
                            ...sharingFlags.profile,
                            education: [
                              ...sharingFlags.profile.education?.filter((x) => x.id !== course.id),
                              {
                                id: course.id,
                                isShareable: selected,
                              },
                            ],
                          },
                        })
                      }
                    />
                  </div>
                </div>
              ))}
            {!!experience?.length && (
              <div className="iq4-share-wallet-modal__content__body__section">
                <div className="iq4-share-wallet__heading-section">
                  <SimpleIcon
                    className="iq4-share-wallet__heading-icon"
                    name="experienceCircle"
                    role="presentation"
                    hidden
                  />
                  <h2 className="iq4-share-wallet__heading-title">Experience</h2>
                </div>
              </div>
            )}
            {experience
              ?.filter((x) => !!x.title)
              .map((e) => (
                <div key={e.id} className="iq4-share-wallet-modal__grid-item">
                  <strong>Item</strong>
                  <div>{e?.title}</div>
                  <div className="iq4-share-wallet-status-toggle">
                    <PrivatePublicToggle
                      record={{
                        isPublic: !!sharingFlags.profile.experience?.find((x) => x.id === e.id)
                          ?.isShareable,
                      }}
                      ariaLabel={`Toggle ${e?.title} Experience to be public or private`}
                      onSelecting={({ selected }) =>
                        setSharingFlags({
                          ...sharingFlags,
                          profile: {
                            ...sharingFlags.profile,
                            experience: [
                              ...sharingFlags.profile.experience.filter((x) => x.id !== e.id),
                              {
                                id: e.id,
                                isShareable: selected,
                              },
                            ],
                          },
                        })
                      }
                    />
                  </div>
                </div>
              ))}
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="skillsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Skills</h2>
              </div>
            </div>
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Item</strong>
              <div>At a Glance</div>
              <div className="iq4-share-wallet-status-toggle">
                <PrivatePublicToggle
                  record={{ isPublic: !!sharingFlags.profile.skills.atAGlance }}
                  ariaLabel={`Toggle At a Glance to be public or private`}
                  onSelecting={({ selected }) =>
                    setSharingFlags({
                      ...sharingFlags,
                      profile: {
                        ...sharingFlags.profile,
                        skills: {
                          ...sharingFlags.profile.skills,
                          atAGlance: selected,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="iq4-share-wallet-modal__grid-item">
              <strong>Item</strong>
              <div>T-Shape</div>
              <div className="iq4-share-wallet-status-toggle">
                <PrivatePublicToggle
                  record={{ isPublic: !!sharingFlags.profile.skills.tShape }}
                  ariaLabel={`Toggle T-Shape to be public or private`}
                  onSelecting={({ selected }) =>
                    setSharingFlags({
                      ...sharingFlags,
                      profile: {
                        ...sharingFlags.profile,
                        skills: {
                          ...sharingFlags.profile.skills,
                          tShape: selected,
                        },
                      },
                    })
                  }
                />
              </div>
            </div>
            {!!portfolio?.length && (
              <div className="iq4-share-wallet-modal__content__body__section">
                <div className="iq4-share-wallet__heading-section">
                  <SimpleIcon
                    className="iq4-share-wallet__heading-icon"
                    name="portfolioCircle"
                    role="presentation"
                    hidden
                  />
                  <h2 className="iq4-share-wallet__heading-title">Portfolio</h2>
                </div>
              </div>
            )}
            {portfolio?.map((p) => (
              <div key={p.id} className="iq4-share-wallet-modal__grid-item">
                <strong>Item</strong>
                <div>{p?.title}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{
                      isPublic: !!sharingFlags.profile.portfolio?.find((x) => x.id === p.id)
                        ?.isShareable,
                    }}
                    ariaLabel={`Toggle ${p?.title} Portfolio to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        profile: {
                          ...sharingFlags.profile,
                          portfolio: [
                            ...sharingFlags.profile.portfolio.filter((x) => x.id !== p.id),
                            {
                              id: p.id,
                              isShareable: selected,
                            },
                          ],
                        },
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 'skills':
        return (
          <div id="skills-tabpanel" role="tabpanel" aria-labelledby="skills-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="skillsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Skills</h2>
              </div>
            </div>
            {skills?.map((s) => (
              <div key={s.id} className="iq4-share-wallet-modal__grid-item">
                <strong>Item</strong>
                <div>{s?.value}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{
                      isPublic: !sharingFlags.skills?.find((x) => x.competenceId === s.id)
                        ? true
                        : !!sharingFlags.skills?.find((x) => x.competenceId === s.id)?.isShareable,
                    }}
                    ariaLabel={`Toggle ${s?.value} Skill to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        skills: [
                          ...sharingFlags.skills?.filter((x) => x.competenceId !== s.id),
                          {
                            competenceId: s.id,
                            isShareable: selected,
                          },
                        ],
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 'credentials':
        return (
          <div id="credentials-tabpanel" role="tabpanel" aria-labelledby="credentials-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="credentialsCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Credentials</h2>
              </div>
            </div>
            {currentCredentials?.map((c) => (
              <div key={c.id} className="iq4-share-wallet-modal__grid-item">
                <div>
                  <strong>Item</strong>
                  {!!c.isVerified && (
                    <div className="iq4-share-wallet__verified">
                      <Verified />
                    </div>
                  )}
                </div>
                <div>{c?.label}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{
                      isPublic: !!sharingFlags.credentials?.find((x) => x.id === c.id)?.isShareable,
                    }}
                    ariaLabel={`Toggle ${c?.label} Credential to be public or private`}
                    onSelecting={({ selected }) =>
                      setSharingFlags({
                        ...sharingFlags,
                        credentials: [
                          ...sharingFlags.credentials?.filter((x) => x.id !== c.id),
                          {
                            id: c.id,
                            isShareable: selected,
                          },
                        ],
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        );
      case 'pathways':
        return (
          <div id="pathways-tabpanel" role="tabpanel" aria-labelledby="pathways-tab">
            <div className="iq4-share-wallet-modal__content__body__section">
              <div className="iq4-share-wallet__heading-section">
                <SimpleIcon
                  className="iq4-share-wallet__heading-icon"
                  name="pathwaysGraphCircle"
                  role="presentation"
                  hidden
                />
                <h2 className="iq4-share-wallet__heading-title">Career Pathways</h2>
              </div>
            </div>
            {selectedPathwayRoles?.map((p) => (
              <div key={p.id} className="iq4-share-wallet-modal__grid-item">
                <strong>Item</strong>
                <div>{p?.label}</div>
                <div className="iq4-share-wallet-status-toggle">
                  <PrivatePublicToggle
                    record={{
                      isPublic: !!sharingFlags.pathways?.find((x) => x.jobId === p.id)?.isShareable,
                    }}
                    ariaLabel={`Toggle ${p?.label} Career Pathway to be public or private`}
                    onSelecting={({ selected }) => {
                      setSharingFlags({
                        ...sharingFlags,
                        pathways: [
                          ...sharingFlags.pathways?.filter((x) => x.jobId !== p.id),
                          {
                            id: sharingFlags.pathways?.filter((x) => x.jobId === p.id)?.[0]?.id,
                            isShareable: selected,
                            jobId: sharingFlags.pathways?.filter((x) => x.jobId === p.id)?.[0]
                              ?.jobId,
                          },
                        ],
                      });
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        );
    }
  }

  async function shareWallet() {
    await dispatch(
      shareEmployerProfile({ sharingFlags, counsellorId, userId: personal?.userProfile?.user?.id }),
    );
    onShare();
  }

  function closeModal() {
    onClose();
  }

  function setSkillsTabActive() {
    setActiveTab('skills');

    if (!hasFetchedSkills && !skills?.length) {
      dispatch(fetchAllSkillsReq());
      setHasFetchedSkills(true);
    }
  }

  function setPathwaysTabActive() {
    setActiveTab('pathways');

    if (!hasFetchedPathways && !selectedPathwayRoles?.length) {
      dispatch(fetchUserSelectedWorkRolesReq());
      setHasFetchedPathways(true);
    }
  }

  return (
    <Modal isOpen={isOpen} handleClose={closeModal} className="iq4-share-wallet-modal">
      <span
        aria-live="polite"
        role="alert"
        className="sr-only"
        aria-label={`This modal is used to configure your Public Wallet Settings with ${counsellorFullName}. In here, you'll be able to define, on a granular level, the visibility of each type of item within your wallet.`}
      ></span>
      <div className="iq4-share-wallet-modal__content">
        <div className="iq4-share-wallet-modal__content__header">
          <h1>Share Wallet</h1>
          <div className="iq4-share-wallet-modal__content__header__description">
            Check and set your permissions to share with your public profile. You can set any item
            public or private.
          </div>
        </div>
        {sharingFlags && !isFetchingEmployerShareProfileSettings && (
          <div className="iq4-share-wallet-modal__content__body">
            <div className="iq4-share-wallet-modal__tabs-container">
              <div
                className={`iq4-share-wallet-modal__tab ${
                  activeTab === 'profile' ? 'iq4-share-wallet-modal__tab--active' : ''
                }`}
                onClick={() => setActiveTab('profile')}
                onKeyPress={() => setActiveTab('profile')}
                id="skills-tab"
                role="tab"
                aria-selected={activeTab === 'skills'}
                aria-controls="skills-tabpanel"
                tabIndex={0}
              >
                Profile
              </div>
              <div
                className={`iq4-share-wallet-modal__tab ${
                  activeTab === 'skills' ? 'iq4-share-wallet-modal__tab--active' : ''
                }`}
                onClick={setSkillsTabActive}
                onKeyPress={setSkillsTabActive}
                id="skills-tab"
                role="tab"
                aria-selected={activeTab === 'skills'}
                aria-controls="skills-tabpanel"
                tabIndex={0}
              >
                Skills
              </div>
              <div
                className={`iq4-share-wallet-modal__tab ${
                  activeTab === 'credentials' ? 'iq4-share-wallet-modal__tab--active' : ''
                }`}
                onClick={() => setActiveTab('credentials')}
                onKeyPress={() => setActiveTab('credentials')}
                id="skills-tab"
                role="tab"
                aria-selected={activeTab === 'skills'}
                aria-controls="skills-tabpanel"
                tabIndex={0}
              >
                Credentials
              </div>
              <div
                className={`iq4-share-wallet-modal__tab ${
                  activeTab === 'pathways' ? 'iq4-share-wallet-modal__tab--active' : ''
                }`}
                onClick={setPathwaysTabActive}
                onKeyPress={setPathwaysTabActive}
                id="skills-tab"
                role="tab"
                aria-selected={activeTab === 'skills'}
                aria-controls="skills-tabpanel"
                tabIndex={0}
              >
                Career Pathways
              </div>
            </div>
            {renderTabContent()}
          </div>
        )}
      </div>
      {isFetchingEmployerShareProfileSettings && (
        <div className="iq4-share-wallet-modal__loading">
          <CircularProgress className="iq4-30__loading__spinner" size={100} />
        </div>
      )}
      {!isFetchingEmployerShareProfileSettings && (
        <div className="iq4-share-wallet-modal__footer">
          <Button className="iq4-share-wallet-modal__button" onClick={shareWallet}>
            {!isSharingProfile && <span>Save</span>}
            {isSharingProfile && <CircularProgress size={20} />}
          </Button>
          {!isSharingProfile && (
            <div role="button" tabIndex={0} onClick={closeModal}>
              Cancel
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
