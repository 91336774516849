import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { LCN_CREDENTIAL_PROVIDERS } from 'common/constants/lcnRecords';
import { getBase64ImageUrl } from 'common/utils';
import { IconButton, Link } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { RecordImageAndLabel } from 'components/MyRecords/common';
import {
  AssessmentInfoPanel,
  AssessmentStars,
  Button,
  ExternalLinkInterstitial,
  Modal,
  Pill,
  SimpleIcon,
  Tooltip,
} from 'common/components';
import {
  confirmVerifiedCredential,
  submitVerifiedCredentialParseReq,
  fetchBadgrCredentials,
} from 'common/store/features/credentials/credentialsSlice';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';
import { orderBy } from 'lodash';
import { Checkbox } from '../../../common/components/Form/fieldTypes';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  selectIsDisputingCredential,
  selectIsParsingVerifiedCredential,
} from '../../../common/store/features/credentials/selectors';
import { useBreakpoints } from '../../../common/hooks/useBreakpoints';
import { INTERSTITIAL_COPY } from '../../../common/constants/interstitialCopy';
import { CredentialExpandedAlignments } from '../../../common/components/Credentials';

export function VerifiedCredentialRow({ row, setIsDisputing, setRowInDispute }) {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoints();

  const imageUrl = row.imageUrl ? row.imageUrl : row.issuerImageUrl;
  const date = moment(row.issuedOn).format('MM/DD/YYYY');

  const [isExpanded, setIsExpanded] = useState(false);
  const [isParsingCredential, setIsParsingCredential] = useState(false);
  const [activeParsedCredentialId, setActiveParsedCredentialId] = useState(null);
  const [isSubmittingVerifiedCredential, setIsSubmittingVerifiedCredential] = useState(false);
  const [parsingStep, setParsingStep] = useState(1);
  const [activeCredential, setActiveCredential] = useState(null);
  const [activeVerifiedCredential, setActiveVerifiedCredential] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [hasParsedCredential, setHasParsedCredential] = useState(false);
  const [credentialModal, setCredentialModal] = useState(null);
  const [isMobile] = useState(breakpoint === 'xs' || breakpoint === 'sm');
  const [uniqueId, setUniqueId] = useState(null);
  const [isDirectMatch, setIsDirectMatch] = useState(false);
  const [allowAssessmentChange, setAllowAssessmentChange] = useState(true);

  const isParsingVerifiedCredential = useSelector(selectIsParsingVerifiedCredential);
  const isSubmittingDispute = useSelector(selectIsDisputingCredential);

  const buttonRef = useRef();

  let rowJs = null;
  if (row?.text) rowJs = JSON.parse(row?.text);
  const achievementName = rowJs?.credentialSubject?.achievement?.name;
  const achievementDescription = rowJs?.credentialSubject?.achievement?.description;
  const isDisputed = !!row?.isDisputed;

  const modalTitles = ['', '- Details', '- Skills', '- Skills Self Assessment'];

  useEffect(() => {
    if (credentialModal) {
      setCredentialModal({
        ...row,
      });
    }
  }, [row]);

  function setRowDispute() {
    setIsDisputing(true, uniqueId);
    setRowInDispute(row);
  }

  function handleParsingCredentialClose() {
    setIsParsingCredential(false);
    setActiveParsedCredentialId(null);
    setActiveCredential(null);
    setActiveVerifiedCredential(null);
    setIsSubmittingVerifiedCredential(false);
    setParsingStep(1);
  }

  async function submitVerifiedCredential() {
    setIsSubmittingVerifiedCredential(true);
    const result = await dispatch(
      confirmVerifiedCredential({
        credential: activeCredential,
      }),
    );
    setIsSubmittingVerifiedCredential(false);
    setCredentialModal(result?.payload);
    setIsParsingCredential(false);
    handleParsingCredentialClose();
    setHasParsedCredential(true);
    dispatch(fetchBadgrCredentials());
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  }

  function generateUniqueId() {
    return Math.random().toString(36).substr(2, 9);
  }

  useEffect(() => {
    if (!uniqueId) {
      setUniqueId(generateUniqueId());
    }
  }, [uniqueId]);

  useEffect(() => {
      const directMatch = activeCredential?.skills?.some((skill) => skill.sources?.some((s) => s.method == 'RSD'));
      setIsDirectMatch(directMatch);
    }
  , [activeCredential]);

  function VerifiedInput({ value, ariaLabelledBy }) {
    return (
      <div style={{ position: 'relative' }}>
        <SimpleIcon name="verified2" className="iq4-credentials__verified-form-container" />
        <input
          disabled
          type="text"
          className="iq4-credentials__verified-input"
          value={value}
          aria-labelledby={ariaLabelledBy}
        />
      </div>
    );
  }

  const LoadingContainer = () => (
    <div className="iq4-30-onboarding-uploader__loading-container">
      <CircularProgress
        size={100}
        classes={{ root: 'iq4-30-credentials-uploader__circular-progress' }}
      />
    </div>
  );
  function noSkillsExit() {
    submitVerifiedCredential();
    handleParsingCredentialClose();
  }

  function renderCredentialDetails() {
    return (
      <>
        {isParsingVerifiedCredential && !activeVerifiedCredential && (
          <div className="iq4-credential__modal-loading">
            <span className="iq4-credential__modal-loading--label">Parsing the credential...</span>
            <div></div>
            <LoadingContainer />
          </div>
        )}
        {isParsingVerifiedCredential ||
          (!!activeVerifiedCredential && (
            <div className="iq4-credentials__form">
              {imageUrl && (
                <>
                  <div>Badge Image</div>
                  <div className="iq4-credentials__verified-camera-parent">
                    <img className="iq4-credentials__parsed-image" src={imageUrl} alt="thumbnail" />
                    {activeCredential.isVerified && (
                      <div className="iq4-credentials__verified-image-container">
                        <Verified />
                        <span>Verified</span>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div id="credentialTitle">Credential Title</div>
              <VerifiedInput value={activeCredential?.name} ariaLabelledBy="credentialTitle" />
              <div id="issuerName">Issuer Name</div>
              <VerifiedInput value={activeCredential?.issuerName} ariaLabelledBy="issuerName" />
              <div id="issuedOn">Award Date</div>
              <VerifiedInput value={date} ariaLabelledBy="issuedOn" />
              <div id="credentialDescription">Credential Description</div>
              <textarea
                style={{ backgroundColor: '#E8E8E8', color: '#2d283dx', border: 'none' }}
                // disabled
                aria-labelledby="credentialDescription"
                type="text"
                rows={4}
                value={activeCredential?.description}
                className="iq4-credentials__input-textarea"
              />
              <Button
                className="iq4-credentials__save"
                onClick={() =>
                  activeCredential?.skills?.length ? setParsingStep(2) : noSkillsExit()
                }
              >
                {activeCredential?.skills?.length ? 'Next' : 'Save'}
              </Button>
            </div>
          ))}
      </>
    );
  }

  function updateSkillAssessment({ skill, assessment }) {
    let newActiveCredential = { ...activeCredential };
    const c = newActiveCredential.skills.find((x) => x.competenceId === skill.competenceId);
    c.assessment = assessment;
    setActiveCredential(newActiveCredential);
  }

  function renderFoundSkills() {
    return (
      <div>
        <div>We found {activeCredential?.skills?.length || 0} skills from your credential</div>
        <div className="iq4-credentials__parsed-skill-row-container">
          {orderBy(activeCredential?.skills, ['name'], ['asc']).map((skill) => (
            <div className="iq4-credentials__parsed-skill-row">
              <div>{skill.name}</div>
              <Checkbox
                isDisabled={isDirectMatch}
                displayVerified = {isDirectMatch}
                handleChange={(e) => {
                  const newSkills = [...activeCredential?.skills];
                  const newSkill = newSkills.find((x) => x.competenceId === skill.competenceId);
                  newSkill.isChecked = e.target.checked;

                  setActiveCredential({
                    ...activeCredential,
                    skills: newSkills,
                  });
                }}
                isChecked={skill.isChecked}
                key={skill.id}
              />
            </div>
          ))}
        </div>
        <div className="iq4-credentials__parsed-skill-note">
          You can associate more skills with this credential in your Skills tab in the navigation
          bar.
        </div>
        <div className="iq4-credentials__save-container">
          <Button className="iq4-credentials__save" onClick={() => setParsingStep(3)}>
            Next
          </Button>
        </div>
      </div>
    );
  }

  function renderSkillsAssessments() {
    return (
      <div>
        {allowAssessmentChange && (<div>Tell us how good you are at each skill</div>)}
        {!allowAssessmentChange && (<div>Assessed proficiency of each skill</div>)}
        <div className="iq4-credentials__popover-container">
          <Tooltip message={<AssessmentInfoPanel />}>
            View Guide
            <SimpleIcon name="star" />
          </Tooltip>
        </div>
        <div className="iq4-credentials__parsed-skill-row-container-2">
          {orderBy(
            activeCredential?.skills?.filter((x) => x.isChecked),
            ['name'],
            ['asc'],
          ).map((skill) => (
            <div className="iq4-credentials__parsed-skill-row">
              <div>{skill.name}</div>
              <AssessmentStars
                assessment={skill.assessment}
                onChange={(assessment) => updateSkillAssessment({ skill, assessment })}
                displayOnly={!!skill.assessment && !allowAssessmentChange}
              />
            </div>
          ))}
        </div>
        <div className="iq4-credentials__parsed-skill-note">
          You can associate more skills with this credential in your Skills tab in the navigation
          bar.
        </div>
        <div className="iq4-credentials__save-container">
          <Button
            loading={isSubmittingVerifiedCredential}
            className="iq4-credentials__save"
            onClick={submitVerifiedCredential}
          >
            {!isSubmittingVerifiedCredential && <span>Save</span>}
            {isSubmittingVerifiedCredential && (
              <CircularProgress
                size={10}
                classes={{ root: 'iq4-credentials__circular-progress' }}
              />
            )}
          </Button>
        </div>
      </div>
    );
  }

  async function prepareParse(row) {
    setIsParsingCredential(true);
    setActiveParsedCredentialId(row?.credentialId);
    const response = await dispatch(
      submitVerifiedCredentialParseReq({ credential: { ...row, isDisputed: false } }),
    );
    const parsedCredential = {
      ...response?.payload[0],
      isPublic: false,
    };

    if (parsedCredential.skills) {
      parsedCredential.skills.forEach((skill) => {
        skill.isChecked = true;
      });
      const allowAssessmentChange = !parsedCredential?.skills?.some((skill) => skill.assessment);
      setAllowAssessmentChange(allowAssessmentChange);
    }
    setActiveCredential(parsedCredential);
    setActiveVerifiedCredential(row);
  }

  function DesktopView() {
    return (
      <>
        <div
          className={`iq4-credentials__existing-card 
          ${isExpanded ? 'iq4-credentials-auto-height' : ''}
          `}
          key={row?.credentialId}
        >
          <IconButton
            ref={buttonRef}
            classes={{ root: 'iq4-credentials__expand' }}
            aria-label="expand row"
            size="small"
            onClick={() => {
              setIsExpanded(!isExpanded);
              console.log('buttonRef.current', buttonRef.current);
              setTimeout(() => {
                buttonRef.current.focus();
              }, 1);
            }}
          >
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <div
            className={`iq4-verified-credentials__card-container 
            ${isExpanded ? 'iq4-credentials-auto-height' : ''}
            `}
          >
            <div className="iq4-verified-credentials__card-container--row">
              <div className="iq4-record-image-and-label">
                <img className="iq4-record-image-and-label__image" src={imageUrl} alt="Logo" />
                <div className="iq4-record-image-and-label__label">
                  <span className="iq4-credentials__record-container">
                    {row.name}
                    {row.isVerified && (
                      <div className="iq4-credentials__verified-container">
                        <Verified />
                        <span></span>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <div
              className="iq4-verified-credentials__card-container--row"
              style={{ justifyContent: 'center' }}
            >
              <div
                className={`iq4-credentials__credential_status_text--highlight ${
                  isDisputed ? 'iq4-credentials__credential_status_text--disputed' : ''
                }`}
              >
                <Pill
                  label={`${isDisputed ? 'DISPUTED' : 'NEW'}`}
                  type={`${isDisputed ? 'disputed' : 'new'}`}
                />
              </div>
              {isDisputed && !!row?.issuerEmail && (
                <div className="iq4-verified-credentials__issuer-email">{row?.issuerEmail}</div>
              )}
            </div>
            <div className="iq4-verified-credentials__card-container--row">
              <span>{date}</span>
            </div>
            <div className="iq4-verified-credentials__card-container--row">
              <div className="iq4-row-ellipsis">{row?.issuerName}</div>
            </div>
            <div
              className="iq4-verified-credentials__card-container--row"
              style={{ justifyContent: 'center' }}
            >
              {!isSubmittingDispute && !isParsingCredential && (
                <>
                  {!isDisputed && (
                    <Button
                      className={`iq4-credentials__dispute iq4-dispute-${uniqueId}`}
                      onClick={setRowDispute}
                    >
                      Dispute
                    </Button>
                  )}

                  <Button
                    className={`iq4-credentials__confirm iq4-focus-${uniqueId}`}
                    onClick={() => prepareParse(row)}
                  >
                    Accept
                  </Button>
                </>
              )}

              {((isParsingVerifiedCredential && activeParsedCredentialId === row?.credentialId) ||
                isSubmittingDispute) && (
                <CircularProgress size={24} className="iq4-credentials__parse-loading" />
              )}
            </div>
          </div>
          {isExpanded && (
            <div className="iq4-credential-expanded__container">
              <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
              <div className="iq4-credential-expanded__split">
                <div>
                  <div className="iq4-credential-expanded-item" style={{ marginRight: 50 }}>
                    <div className="iq4-credential-expanded-item__label">Description</div>
                    <div className="iq4-credential-expanded-item__content">{row.description}</div>
                  </div>
                  <div className="iq4-credential-expanded-item">
                    <div className="iq4-credential-expanded-item__label">Issuer</div>
                    <div
                      className="iq4-credential-expanded-item__content"
                      style={{ display: 'flex', gap: '10px' }}
                    >
                      {row.issuerImageUrl && (
                        <div>
                          <img
                            className="iq4-record-image-and-label__image"
                            src={row.issuerImageUrl}
                            alt=""
                          />
                        </div>
                      )}
                      {row.issuerUrl && row.issuerName && (
                        <div>
                          <Link href={row.issuerUrl} target="_blank" rel="noopener">
                            {row.issuerName}
                          </Link>
                        </div>
                      )}
                      {!row.issuerUrl && row.issuerName && <div>{row.issuerName}</div>}
                    </div>
                  </div>
                  {achievementName && achievementName != row.name && (
                    <div className="iq4-credential-expanded-item">
                      <div className="iq4-credential-expanded-item__label">Achievement Name</div>
                      <div className="iq4-credential-expanded-item__content">{achievementName}</div>
                    </div>
                  )}
                  {achievementDescription && achievementDescription != row.description && (
                    <div className="iq4-credential-expanded-item">
                      <div className="iq4-credential-expanded-item__label">
                        Achievement Description
                      </div>
                      <div className="iq4-credential-expanded-item__content">
                        {achievementDescription}
                      </div>
                    </div>
                  )}
                  <div className="iq4-credential-expanded-item">
                    <div className="iq4-credential-expanded-item__label">Award Date</div>
                    <div className="iq4-credential-expanded-item__content">
                      {date}
                      {row.expirationDate && (
                        <>
                          {' '}
                          -{' '}
                          {moment(
                            `${moment(row?.expirationDate).format('YYYY')}-${moment(
                              row?.expirationDate,
                            ).format('M')}-${moment(row?.expirationDate).format('DD')}`,
                          ).format('MM/DD/YYYY')}
                        </>
                      )}
                    </div>
                  </div>
                  {!!(row.achievementUrl || row.learnMoreUrl) && (
                    <div className="iq4-credential-expanded-item">
                      <div className="iq4-credential-expanded-item__content">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="iq4-credentials__manual-add"
                          href={row.learnMoreUrl || row.achievementUrl}
                        >
                          View details
                        </a>
                      </div>
                    </div>
                  )}
                </div>
                {imageUrl && (
                  <div className="iq4-credential-expanded__split-img">
                    <img
                      className="iq4-credentials__expanded-image-desktop"
                      src={imageUrl}
                      alt="Logo"
                    />
                  </div>
                )}
              </div>
              <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
              {!!row?.competences?.length && (
                <>
                  <div className="iq4-credential-expanded-item">
                    <div className="iq4-credential-expanded-item__label">Skills</div>
                    <div className="iq4-credential-expanded-item__content">
                      Recipients demonstrated these job skills:
                    </div>
                  </div>
                  <div className="iq4-credential__skills-list">
                    {row?.competences?.map((c) => (
                      <div className="iq4-credential-expanded-item__content">
                        {c?.skillTier3?.label}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div
          className={`iq4-credentials__alert ${showAlert ? 'iq4-credentials__alert--visible' : ''}`}
        >
          <SimpleIcon className="iq4-credentials__alert--close" name="close" />
          Your Credential has been added!
        </div>
      </>
    );
  }

  function MobileView() {
    return (
      <div
        className={`iq4-credentials__verified-card ${
          isExpanded ? 'iq4-credentials-auto-height' : ''
        }`}
        key={row?.credentialId}
      >
        <div
          className={`iq4-credentials__new-container ${
            isExpanded ? 'iq4-credentials-auto-height' : ''
          }`}
        >
          <>
            <div
              className={`iq4-credentials__new-container--row ${
                isExpanded ? 'iq4-credentials__new-container--fixed-row' : ''
              }`}
            >
              <RecordImageAndLabel imageUrl={imageUrl} label={row.name} />
              <IconButton
                classes={{ root: 'iq4-credentials__expand-mobile' }}
                aria-label="expand row"
                size="small"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </div>
          </>
        </div>
        {isExpanded && (
          <div className="iq4-credentials__expanded-mobile-container">
            <div className="iq4-credentials__expanded-mobile-row">
              <div className="iq4-credentials__expanded-mobile-column">
                <div className="iq4-credentials__expanded-mobile-column--header">Award Date</div>
                <div className="iq4-credentials__expanded-mobile-column--content">
                  {moment(row.issuedOn).format('MM/DD/YYYY')}
                </div>
              </div>
              <div className="iq4-credentials__expanded-mobile-column">
                <div className="iq4-credentials__expanded-mobile-column--header">Issuer</div>
                <div className="iq4-credentials__expanded-mobile-column--content">
                  {row.issuerName}
                </div>
              </div>
            </div>
            {!!isDisputed && (
              <Pill
                label="DISPUTED"
                type="disputed"
                className="iq4-credentials__dispute-mobile-pill"
              />
            )}
            <div className="iq4-credentials__expanded-mobile-row">
              <div className="iq4-credentials__expanded-mobile-column">
                <div className="iq4-credentials__expanded-mobile-column--header">Action</div>
                <div className="iq4-credentials__expanded-mobile-column--content">
                  {!isSubmittingDispute && !isParsingCredential && (
                    <>
                      {!isDisputed && (
                        <Button className="iq4-credentials__dispute" onClick={setRowDispute}>
                          Dispute
                        </Button>
                      )}
                      <Button
                        className="iq4-credentials__confirm"
                        onClick={() => prepareParse(row)}
                      >
                        Accept
                      </Button>
                    </>
                  )}

                  {((isParsingVerifiedCredential &&
                    activeParsedCredentialId === row?.credentialId) ||
                    isSubmittingDispute) && (
                    <CircularProgress size={24} className="iq4-credentials__parse-loading" />
                  )}
                </div>
              </div>
            </div>
            <div className="iq4-credentials__expanded-separator" />
            <div className="iq4-credentials__expanded-mobile-row">
              <div className="iq4-credentials__expanded-mobile-column">
                <div className="iq4-credentials__expanded-mobile-column--header">Description</div>
                <div className="iq4-credentials__expanded-mobile-column--content">
                  {row.description}
                </div>
              </div>
            </div>
            <div className="iq4-credentials__expanded-mobile-row">
              <div className="iq4-credentials__expanded-mobile-column">
                <div className="iq4-credentials__expanded-mobile-column--header">Issuer mobile</div>
                <div className="iq4-credentials__expanded-mobile-column--content">
                  {row.issuerName}
                </div>
              </div>
              <div className="iq4-credentials__expanded-mobile-column">
                <div className="iq4-credentials__expanded-mobile-column--header">Award Date</div>
                <div className="iq4-credentials__expanded-mobil e-column--content">
                  {moment(row.issuedOn).format('MM/DD/YYYY')}
                  {row.expirationDate && <> - {moment(row.expirationDate).format('MM/DD/YYYY')}</>}
                </div>
              </div>
            </div>
            <div className="iq4-credentials__expanded-mobile-row">
              <ExternalLinkInterstitial
                linkLabel="View Credential"
                className="iq4-credential__link"
                link={row.learnMoreUrl || row.achievementUrl}
                introText={INTERSTITIAL_COPY.BADGR_INTRO_TEXT}
                mainText={INTERSTITIAL_COPY.BADGR_MAIN_TEXT}
              />
            </div>
            <div className="iq4-credentials__expanded-separator" />
            <img className="iq4-credentials__expanded-image" src={row.imageUrl} alt="Logo" />
            <CredentialExpandedAlignments
              label="Alignments"
              content={row.alignmentList}
              credentialImage={row.imageUrl}
            />
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <Modal
        className="iq4-credentials__add-modal"
        isOpen={isParsingCredential}
        handleClose={handleParsingCredentialClose}
        onDeactivation={() => {
          setTimeout(() => {
            const button = document.querySelector(`.iq4-focus-${uniqueId}`);
            if (button) {
              button.focus();
            }
          }, 1);
        }}
      >
        {parsingStep !== 1 && (
          <SimpleIcon
            name="arrow"
            role="button"
            tabIndex={0}
            onClick={() => setParsingStep(parsingStep - 1)}
            onKeyPress={() => setParsingStep(parsingStep - 1)}
            className="iq4-credentials__add-modal--arrow"
          />
        )}
        <h2 className="iq4-credentials__add-modal--title">
          Add a Credential {modalTitles[parsingStep]}
        </h2>
        {parsingStep === 1 && renderCredentialDetails()}
        {parsingStep === 2 && activeCredential?.skills?.length && renderFoundSkills()}
        {parsingStep === 3 && activeCredential?.skills?.length && renderSkillsAssessments()}
      </Modal>
      {!isMobile && <DesktopView />}
      {isMobile && <MobileView />}
    </>
  );
}
