import { useEffect } from 'react';
import { useReactFlow } from 'reactflow';

export function useGraphResizeToFitView(nodes) {
  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    function handleResize() {
      reactFlowInstance.fitView({ padding: 1, includeHiddenNodes: false });
      // After fitting to view, manually adjust the viewport position if necessary
      setTimeout(() => {
        const viewport = document.querySelector('.iq4-pathway__outer-container');
        if (viewport) {
          const transform = viewport.style.transform;
          const newTransform = transform.replace(/translate\(([^,]*), ([^)]*)\)/, (match, x, y) => {
            // Calculate the amount to subtract based on the size of the graph and the size of the viewport
            const graphHeight =
              Math.max(...nodes.map((node) => node.position.y)) -
              Math.min(...nodes.map((node) => node.position.y));
            const viewportHeight = window.innerHeight;
            const amountToSubtract = (viewportHeight - graphHeight) / 2;
            return `translate(${x}, ${parseFloat(y) - amountToSubtract}px)`;
          });
          viewport.style.transform = newTransform;
        }
      }, 100); // Timeout to ensure this runs after fitView
    }

    window.addEventListener('resize', handleResize);

    // Call it once initially to ensure everything fits from the start.
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [reactFlowInstance]);
}
