import React, { useState } from 'react';
import { Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

export function CredentialSkillSource({
  source
}) {
  return (
      <div
        key={source}
      >
      <div className="iq4-credential-expanded-item__content iq4-credential-skill__source_text" style={{marginBottom:8}}>
        {/* {!source?.isCompetencyStatement && (<SimpleIcon name="tooltipCircle" className="iq4-info--tooltip__tooltip-svg" style={{marginRight:5}} />)} */}
        {source.value}
      </div>
    </div>
  );
}
