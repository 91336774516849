import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SimpleIcon, Button } from 'common/components';
import {
  activateCodeReq,
  toggleIsActivatingCode,
} from 'common/store/features/session/sessionSlice';
import {
  selectHasActivatedCode,
  selectHasCodeActivationError,
  selectIsActivatingCode,
} from 'common/store/features/session/selectors';
import './ActivateCodeForm.scss';

export const ActivateCodeForm = ({ Logo, onSuccessLocation, activateEndpoint }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activationCode, setActivationCode] = useState('');
  const hasActivatedCode = useSelector(selectHasActivatedCode);
  const hasCodeActivationError = useSelector(selectHasCodeActivationError);
  const isActivatingCode = useSelector(selectIsActivatingCode);
  const activationCodeRef = useRef(activationCode);

  useEffect(() => {
    if (hasActivatedCode)
      history.push(onSuccessLocation + "?activationCode=" + activationCode);
  }, [hasActivatedCode]);

  const activate = () => {
    dispatch(toggleIsActivatingCode());
    dispatch(activateCodeReq({ activateEndpoint, activationCode }));
  };

  const handleCodeChange = (e) => {
    activationCodeRef.current = e.target.value;
    setActivationCode(e.target.value);
  };

  function renderActivateCodeForm() {
    return (
      <>
        <h1 className="iq4-activate-code-form__box--title">Activate to Register</h1>
        <div role="form" className="iq4-activate-code-form__box--login-container">
        <label htmlFor="activationCode">Please enter your activation code:</label>
        <div className="iq4-activate-code-form__box--field-container">
            <TextField
              id="activationCode"
              value={activationCode}
              onChange={handleCodeChange}
              InputProps={{
                disableUnderline: true,
              }}
              variant="outlined"
            />
          </div>
          {hasCodeActivationError && (
            <div className="iq4-activate-code-form__box--error-container">
              <SimpleIcon name="error" /> Activation Code Not Recognized
            </div>
          )}
          <Button
            loading={isActivatingCode}
            onClick={activate}
            className={`iq4-activate-code-form__box--button ${
              hasCodeActivationError ? 'iq4-activate-code-form__box--error-button' : ''
            }`}
            variation="ghost"
          >
            Submit
          </Button>
        </div>
      </>
    );
  }

  return (
    <main id="main">
      <div className="iq4-activate-code-form__container">
        <div className="iq4-activate-code-form__box">
          <div className="iq4-activate-code-form__box--logo">
            <Logo />
          </div>
          {renderActivateCodeForm()}
        </div>
      </div>
    </main>
  );
};
