import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextInput, TextArea, AccessibleSelect } from 'common/components/Form/fieldTypes';
import { validations } from 'common/components/Form/validations';
import {
  PROFILE_AVATAR_UPLOAD_URL,
  PROFILE_PERSONAL_URL,
  REGISTER_NEW_USER_URL,
  SCHOOLS_AVAILABLE_FOR_REGISTERING_USERS,
} from 'common/constants/endpoints';
import {
  setProfilePersonal,
  setProfilePhoto,
  setNewUserRegistrations,
} from 'common/store/features/profile/profileSlice';
import { sendFormDataRequest, sendRequest } from 'common/utils';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import { FileUpload } from 'common/components/Form/fieldTypes';
import { ImageUploadPlaceholder } from 'common/components/ImageUploadPlaceholder';
import { selectProfile } from 'common/store/features/profile/selectors';

export const useProfilePersonalFormDefinition = ({ values, onComplete }) => {
  const dispatch = useDispatch();
  const { personal } = useSelector(selectProfile);

  const fieldsDefinition = [
    {
      label: 'Profile Image',
      name: 'profileImage',
      isCustom: true,
      component: ({ ...formProps }) => (
        <>
          <span className="iq4-text-input__label">Profile image</span>
          <FileUpload
            triggerLabel={
              <div className="iq4-profile-personal-form-avatar-container">
                <ImageUploadPlaceholder />
                <span>Upload new image</span>
              </div>
            }
            name="image"
            fileTypes={THUMBNAIL_MIME_TYPES}
            type="image"
            ariaLabel="Upload a new Profile image"
            {...formProps}
          />
        </>
      ),
    },
    {
      label: 'First Name',
      name: 'firstName',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Surname',
      name: 'lastName',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Email',
      name: 'email',
      component: TextInput,
      isDisabled: !!values.email,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Description',
      name: 'description',
      component: TextArea,
      id: 'personalDescription',
      rows: 5,
    },
    {
      label: 'Current Position',
      name: 'currentPosition',
      component: TextInput,
      validators: {
        maxLength: validations.hasMaxLength(200),
      },
    },
    {
      label: 'LinkedIn',
      name: 'linkedIn',
      component: TextInput,
    },
  ];

  const onEdit = async (rawData) => {
    const { image, ...data } = rawData;

    // save profile image
    if (!image || image instanceof File) {
      try {
        await sendFormDataRequest({
          url: PROFILE_AVATAR_UPLOAD_URL,
          verb: image
            ? personal.userProfilePhoto && personal.userProfilePhoto.id
              ? 'PUT'
              : 'POST'
            : 'DELETE',
          data: { image },
        }).then((res) => dispatch(setProfilePhoto(res)));
      } catch (e) {
        console.log('error saving image', e);
      }
    }

    // save form data
    return sendRequest({
      url: `${PROFILE_PERSONAL_URL}/${values.id}`,
      verb: 'PUT',
      data,
    }).then((resp) => {
      return dispatch(setProfilePersonal(resp));
    });
  };

  const onCreate = async (rawData) => {
    const { image, ...data } = rawData;

    const res = await sendRequest({
      url: PROFILE_PERSONAL_URL,
      verb: 'POST',
      data,
    }).then((resp) => {
      return dispatch(setProfilePersonal(resp));
    });

    // save profile image
    if (!image || image instanceof File) {
      try {
        await sendFormDataRequest({
          url: PROFILE_AVATAR_UPLOAD_URL,
          verb: personal.userProfilePhoto && personal.userProfilePhoto.id ? 'PUT' : 'POST',
          data: { image },
        }).then((res) => dispatch(setProfilePhoto(res)));
      } catch (e) {
        console.log('error saving image', e);
      }
    }

    return res;
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};

export const useProfilePersonalFormDefinitionOnboarding = ({ values, onComplete }) => {
  const dispatch = useDispatch();

  const fieldsDefinition = [
    {
      label: 'First Name',
      name: 'firstName',
      component: TextInput,
      isDisabled: false,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Surname',
      name: 'lastName',
      component: TextInput,
      isDisabled: false,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Email',
      name: 'email',
      component: TextInput,
      isDisabled: false,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Description (255 characters)',
      name: 'description',
      component: TextArea,
      validators: {
        maxLength: validations.hasMaxLength(255),
      },
      rows: 5,
    },
    {
      label: 'Current Position',
      name: 'currentPosition',
      component: TextInput,
    },
  ];

  const onEdit = (data) => {
    return sendRequest({
      url: `${PROFILE_PERSONAL_URL}/${values.id}`,
      verb: 'PUT',
      data,
    }).then((resp) => {
      return dispatch(setProfilePersonal(resp));
    });
  };

  const onCreate = (data) => {
    return sendRequest({
      url: PROFILE_PERSONAL_URL,
      verb: 'POST',
      data,
    }).then((resp) => {
      return dispatch(setProfilePersonal(resp));
    });
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};

export const useNewUserRegistrationFormDefinition = ({ values, onComplete }) => {
  const [schoolsAvailableForReg, setSchoolsAvailableForReg] = useState([]);
  // get available schools
  useEffect(() => {
    const getSchools = async () => {
      try {
        const schools = await sendRequest({
          verb: 'GET',
          url: SCHOOLS_AVAILABLE_FOR_REGISTERING_USERS,
        });

        const derivedSchools = schools.map((school) => {
          return {
            key: school.name,
            value: school.name,
            domain: school.domainNameSuffix,
          };
        });

        setSchoolsAvailableForReg(derivedSchools);
      } catch (e) {
        const urlParams = new URLSearchParams(window.location.search);
        const school = urlParams.get('school');

        if (!!school) {
          setSchoolsAvailableForReg([{ key: school, value: school }]);
        }
      }
    };

    getSchools();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const school = urlParams.get('school');

  const fieldsDefinition = [
    {
      label: 'First Name',
      name: 'firstname',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      label: 'Last Name',
      name: 'lastname',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    /* add custom property if school is defined */
    ...(!!school
      ? [
          {
            isCustom: true,
            component: ({ ...formProps }) => (
              <div className="iq4-portfolio-source-select">
                <AccessibleSelect
                  maxWidth
                  label="School"
                  name="school"
                  placeholder="Select your school"
                  options={schoolsAvailableForReg}
                  isDisabled={!!values.school}
                  validators={{
                    required: validations.isRequired(),
                    validate: (currentValue) => {
                      const { email } = formProps.getValues();
                      const defaultSchool =
                        schoolsAvailableForReg?.find((x) => x.key === currentValue) ?? {};
                      const schoolDomain = defaultSchool.domain ?? '';
                      const emailDomain = email?.split('@')?.[1] ?? '';

                      if (Array.isArray(schoolDomain) && !schoolDomain?.includes(emailDomain)) {
                        return `This school requires a @${schoolDomain?.join(
                          ' or ',
                        )} Email Address`;
                      }
                    },
                  }}
                  {...formProps}
                />
              </div>
            ),
          },
        ]
      : []),
    {
      isCustom: true,
      component: ({ ...formProps }) => {
        const { school } = formProps.getValues();
        const showCustomInfo = !!(school || values.school);
        return (
          <TextInput
            {...formProps}
            label="Email"
            name="email"
            {...(showCustomInfo && {
              customInfo: `(Use your registered email address with ${school || values.school})`,
            })}
            validators={{
              required: validations.isRequired(),
              validate: (currentValue) => {
                const { school } = formProps.getValues();
                const defaultSchool =
                  schoolsAvailableForReg?.find((x) => x.key === (values.school || school)) ?? {};
                const schoolDomain = defaultSchool.domain ?? '';
                const emailDomain = currentValue?.split('@')?.[1] ?? '';

                if (!!schoolDomain && !schoolDomain?.includes(emailDomain)) {
                  return `Please use your @${schoolDomain?.join(' or ')} Email Address`;
                }

                formProps.triggerValidation('school');
              },
            }}
          />
        );
      },
    },
    {
      label: 'Password',
      name: 'password',
      component: TextInput,
      type: 'password',
      validators: {
        required: validations.isRequired(),
      },
    },

    {
      label: 'Confirm Password',
      name: 'confirmPassword',
      isCustom: true,
      component: ({ ...formProps }) => {
        return (
          <TextInput
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            validators={{
              validate: (confirmPassword) => {
                const { password } = formProps.getValues();
                return password === confirmPassword || "Password's don't match.";
              },
            }}
            {...formProps}
          />
        );
      },
    },
  ];

  const onCreate = async (rawData) => {
    // derive API payload
    const derivedPayload = derivePayload(rawData);

    let res;
    try {
      // register user
      res = await sendRequest({
        url: REGISTER_NEW_USER_URL,
        verb: 'POST',
        data: derivedPayload,
      });
    } catch (e) {
      // user not authorized to register
      if (e.result?.status === 401) {
        return {
          errorCode: 3, // user not authorized to register
          error: 'Registration is not available at this time. Please try again later.',
          data: rawData,
        };
      }

      // something went wrong saving the user
      return {
        errorCode: 0, // unknown issue registering user
        error: 'There was an error registering your account. Please try again later.',
        data: rawData,
      };
    }

    // check if the user already registered
    const isAlreadyRegistered = res.msg.indexOf('already registered') !== -1;
    // check if user already verified their email.
    const isVerified = res.userRegistration.verified;

    // handle already registered accounts.
    if (isAlreadyRegistered) {
      if (isVerified) {
        return {
          errorCode: 2, // user already registered with this email and is verified.
          error: 'You have already registered your account. Please log in to access your account.',
          data: rawData,
        };
      } else {
        return {
          errorCode: 1, // user already registered with this email but hasn't verified their email
          error:
            'You have already registered your account. Please verify your email address to log in.',
          data: rawData,
        };
      }
    }

    // save data to store
    // dispatch(setNewUserRegistrations(res));

    return {
      success: 'Registration successful. Please verify your email address to log in.',
      data: rawData,
    };

    // derive paylod the api expects
    function derivePayload(rawData) {
      let { confirmPassword: _, ...data } = rawData;

      const urlParams = new URLSearchParams(window.location.search);
      const accountId = urlParams.get('accountId');
      const activationCode = urlParams.get('activationCode');

      // required for payload
      data.verified = false;

      if (!!accountId) {
        data.accountId = accountId;
      }
      if (!!activationCode) {
        data.activationCode = activationCode;
      }

      return data;
    }
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onCreate,
      onComplete,
    },
  };
};
