import React from 'react';

export const getWguContent = () => (
  <div className="iq4-terms-page--container">
    <div className="iq4-terms-page--content">
      <h2>INTRODUCTION.</h2>
      <p>
        The WGU Achievement Wallet is provided and maintained by iQ4, a digital talent cloud
        infrastructure platform company. Your participation and use of the WGU Achievement Wallet
        and other iQ4 technology made available to you as a user of the Achievement Wallet is
        therefore governed by the following iQ4 Privacy Policy. Being part of iQ4 means sharing
        information about yourself with iQ4 and other iQ4 users (&ldquo;Users&rdquo;), communicating
        with them, and working privately on your own. Here are the three main types of information
        you may share while engaging with the Achievement Wallet:
      </p>
      <ol>
        <li>
          1. Information about yourself. This includes your membership profile, any photos, videos,
          or other personally identifiable information you post or provide using other features that
          iQ4 may offer from time to time. You have control over what information you add to your
          profile, and you can update or delete personal information that you previously added at
          any time, but you may not have control over the sharing of information you previously
          communicated or shared with other Users. Where you do not have control over the sharing of
          information, such information sharing control resides solely with iQ4.
        </li>
        <li>
          2. Communication. We may help you communicate and collaborate with other Users. You decide
          how much or how little you wish to communicate, but depending upon your type of account
          you may not have control over the sharing of such communications between or among other
          Users. Where you do not have control over the sharing of information, such information
          sharing control resides solely with iQ4 and/or the applicable iQ4 platform manager.
        </li>
        <li>
          3. Private activity. We may also provide tools that you can use privately on the
          Achievement Wallet website or other iQ4 platform. Information you provide to utilize these
          tools is private and we don&rsquo;t share or distribute this information to other Users or
          third parties except as described in this policy or as required by law. Of course,
          maintaining your trust is our top concern, so we adhere to the following principles to
          protect your privacy:
          <ol>
            <li>
              - We do not rent, sell, or otherwise provide your personally identifiable information
              to third parties without your consent, except as described in this policy or as
              required by law.
            </li>
            <li>
              - We do not share any information you have not chosen to share with other parties
              unless required by the applicable iQ4 platform, compelled by law, as necessary to
              enforce our Terms &amp; Conditions, as otherwise allowed under this Privacy Policy, or
              to protect the rights, property, or personal safety of iQ4, its Users, and the public.
            </li>
            <li>
              - All information you provide will be protected with industry standard protocols and
              technology. We reserve the right to modify this Privacy Policy at any time, so please
              review it frequently. If we make material changes to this policy, we will provide
              notice on the Achievement Wallet website, via email communication to you, and/or on
              other iQ4 platforms so that you may access and review the changes to this document in
              advance of your continued use of the service. If you object to any changes, you may
              cancel your membership in any iQ4 platform in which you are a member. By continuing to
              use the Achievement Wallet website, the Services, and/or other iQ4 platforms after
              notice of changes has been sent to you or published on the Achievement Wallet website,
              you are consenting to the updated Privacy Policy. PLEASE READ THIS POLICY and send us
              questions, concerns, and suggestions in accordance with Section 8 below.
            </li>
          </ol>
        </li>
      </ol>
      <p>What iQ4&rsquo;s Privacy Policy Covers:</p>
      <ol>
        <li>1. Information collected by iQ4.</li>
        <li>2. How iQ4 uses your information.</li>
        <li>3. Your information choices.</li>
        <li>4. Your obligations as a User.</li>
        <li>5. Important information regarding service eligibility.</li>
        <li>6. Security.</li>
        <li>7. Rights of Users located in the European Union pursuant to GDPR</li>
        <li>8. How to contact us.</li>
      </ol>
      <ol>
        <li>
          <h2>1. Information Collected</h2>
          <p>
            iQ4 makes features and functionality available through the Achievement Wallet website
            and other iQ4 technology, including our mobile and other applications, and other
            information provided as part of the Achievement Wallet and iQ4 services (collectively
            the &ldquo;Services&rdquo;). By using the Achievement Wallet, you voluntarily and
            willingly provide iQ4 with certain information and content, including personally and
            organizationally identifiable information, which we collect in order to provide the
            Services. If you have any hesitation about providing information or content to us or
            otherwise used in any manner permitted in this Privacy Policy and the Terms &amp;
            Conditions, you should not use the Services or become a member of an iQ4 platform; and,
            if you are already a member, you should terminate your membership. We collect your
            personal information in the following ways:
          </p>
          <ol>
            <li>
              A. Registration. In order to become a User, you must provide us the following
              information to create an account: name, address, email address, country, school, year
              of graduation, level of education, profession, employer, title, areas of expertise,
              language abilities, hobbies, sports played, credits earned, grade point average,
              gender, responses to applications to become a member of a specific iQ4 platform,
              password, and/or other educational and business and government employee information.
              If a payment is required, you must also provide us with your credit card or billing
              information to process the applicable payment. Without this minimal amount of
              information, you cannot create an individual, commercial, and/or government iQ4
              membership. When establishing passwords, you should choose one that is known only by
              you, and not one that could easily be determined using basic information about you.
              Some of the above-listed information that iQ4 requests from you during the
              registration process is used by iQ4 to provide better, more customized, services and
              experience. You acknowledge that this information is personal to you, and by creating
              an account on iQ4, you allow others, including iQ4, to identify you and to allow iQ4
              to use your information in accordance with this Privacy Policy and the Terms &amp;
              Conditions.
            </li>

            <li>
              B. Customer Service. We collect information when you interact with iQ4&rsquo;s member
              service personnel and/or websites (if applicable) in order to accurately categorize
              and respond to customer inquiries and investigate breaches of our terms.
            </li>

            <li>
              C. Using the iQ4 Websites, Applications, and Services. We receive information when you
              interact with and use the Achievement Wallet website, iQ4 applications (e.g. iQ4 for
              iPhone, Android, etc.), iQ4 technology, the Services, and/or other iQ4 websites. For
              example, we know when you click on areas of interest, install an iQ4 mobile
              application, etc.
            </li>

            <li>
              D. Cookies. Like most websites, we may use cookies and web log files to track site
              usage and trends, to improve the quality of our service, to customize your experience
              on the Achievement Wallet, the Services, or use of other iQ4 technology, as well as to
              deliver iQ4 to Users both on and off the Achievement Wallet website and other iQ4
              websites. A cookie is a tiny data file that resides on your computer, mobile phone, or
              other device, and allows us to recognize you as a User when you return to the
              Achievement Wallet or other iQ4 website using the same computer and web browser. You
              can remove or block cookies using the settings in your browser, but in some cases
              doing so may impact your ability to use the Services. One type of cookie, called a
              &ldquo;session&rdquo; cookie, is used to identify a particular visit to the
              Achievement Wallet or other iQ4 website. Session cookies expire after a short time or
              when you close your web browser. iQ4 may use cookie-equivalent technology for the
              limited purpose of enhancing the security of iQ4&rsquo;s services. In the course of
              optimizing the Services to our Users, we may allow authorized third parties to place
              or recognize a unique cookie on your browser. Any information provided to third
              parties through cookies will not be personally identifiable but may provide general
              segment information (e.g., your status as a student at a university, your career
              field, or information about other groups you are a member of on the iQ4 platform) for
              the enhancement of your user experience. Most browsers are initially set up to accept
              cookies, but you can reset your browser to refuse all cookies or to indicate when a
              cookie is being sent. iQ4 does not store unencrypted personally identifiable
              information in the cookies.
            </li>

            <li>
              E. Log files, IP Addresses, and information about your computer and mobile device. Due
              to the communications standards on the internet, when you visit the Achievement Wallet
              or other iQ4 website we may in the future automatically receive the URL of the site
              from which you came and the site to which you are going when you leave. iQ4 may in the
              future receive the internet protocol (&ldquo;IP&rdquo;) address of your computer (or
              the proxy server you use to access the World Wide Web), your computer operating system
              and type of web browser you are using, email patterns, your mobile device (including
              your UDID) and mobile operating system (if you are accessing iQ4 using a mobile
              device), as well as the name of your ISP or your mobile carrier. iQ4 may also in the
              future receive location data passed to it from third-party services or GPS-enabled
              devices that you have enabled.
            </li>

            <li>
              F. Rights to Access, Correct, and Eliminate Information About You. You have a right to
              access, modify, correct, and eliminate the profile data you supply to iQ4. If you
              update any of your information, we may keep a copy of the information that you
              originally provided to us in our archives for uses documented in this policy. You may
              request deletion of your information at any time by contacting iQ4 in accordance with
              Section 8. We will respond to your request within 30 days. Please note, however, that
              information you have shared with others through the Services or any iQ4 platform may
              have been independently stored or retained by other Users or third parties.
            </li>

            <li>
              G. Data Retention. iQ4 will retain your personal information for so long as your
              account is active or as needed to provide you Services. If you wish to terminate your
              membership, you may do so by contacting iQ4 in accordance with Section 8. We will
              retain and use your information as necessary to comply with our legal obligations,
              resolve disputes, and enforce this Privacy Policy.
            </li>
          </ol>
        </li>
        <li>
          <h2>2. Uses of personal information</h2>
          <ol>
            <li>
              A. Consent to iQ4 Processing Information About You. The information you provide to iQ4
              or on the Achievement Wallet website may reveal, or allow iQ4 to identify, your
              nationality, ethnic origin, gender, age, education history, career history, and other
              aspects of your life. By providing information to us for the purposes of registering
              for an Achievement Wallet profile or adding any additional details to your membership
              profile, you are expressly and voluntarily accepting the terms and conditions of this
              Privacy Policy and iQ4&rsquo;s Terms &amp; Conditions that allow iQ4 to process
              information about you. Supplying information to iQ4 or on your Achievement Wallet
              profile, including any information deemed &ldquo;sensitive&rdquo; by applicable law,
              is entirely voluntary on your part. You have the right to withdraw your consent to
              iQ4&rsquo;s collection and processing of your information at any time, in accordance
              with the terms of this Privacy Policy and the Terms &amp; Conditions, by terminating
              your membership with iQ4, but please note that your withdrawal of consent will not be
              retroactive.
            </li>

            <li>
              B. iQ4 Communications. As described in the Terms &amp; Conditions, we may communicate
              with you through email, notices posted on the Achievement Wallet website, or through
              other means available through the service. We may send you a series of emails that
              help inform new Users about the features of the Services, and we may also send you
              service messages relating to the functioning of the Services. We may also send you
              messages with promotional information directly or on behalf of our partners, unless
              you have opted out of receiving promotional information. Please be aware that you
              cannot opt-out of receiving service messages from iQ4. Also, if we send communications
              to you via the carrier service with which you have a mobile communications
              subscription or otherwise have access, you understand you may be subject to service
              fees associated with your receipt of messages on your mobile device (including text
              messaging charges).
            </li>

            <li>
              C. User Communications. Communications you initiate through iQ4 using communication
              features iQ4 may make available as part of the Services may list your primary email
              address and name in the header of the message. Messages you initiate may also provide
              aggregate information about your network (for instance how many people are in your
              network) and may list the iQ4 Groups you are associated with. Other Communications you
              may be able to initiate through the Achievement Wallet website may list your name as
              the initiator and may also include your personal email address contact information.
              Your contact information will only be shared with another User or third party if you
              choose to communicate with another User or third party.
            </li>

            <li>
              D. Customized Content. We use information you provide to us (or to third parties with
              whom iQ4 offers combined services) to customize your experience on the Achievement
              Wallet website. For example, when you login to your account, we may display customized
              content based upon information we collect about you.
            </li>

            <li>
              E. Sharing Information with Third Parties. iQ4 takes the privacy of our Users very
              seriously, and we do not sell, rent, or otherwise provide your personally identifiable
              information to third parties except to Western Governors University with which you
              agree iQ4 can share this data, and except as otherwise described in this policy.
              Information shared with Western Governors University will not be sold, rented, or
              otherwise provided by Western Governors University to other third parties but will be
              used to help iQ4 administer, maintain, and improve the improve the Services. We will
              also not share other personal information not published to your profile or generated
              through engagement with other iQ4 services without your explicit consent or to carry
              out your instructions unless, disclosure is reasonably necessary in our opinion to:
              (1) comply with legal process, including, but not limited to, civil and criminal
              subpoenas, court orders or other compulsory disclosures; (2) enforce this Privacy
              Policy or the Terms &amp; Conditions; (3) respond to claims of a violation of the
              rights of third parties, whether or not the third party is a User, individual, or
              government agency; (4) respond to customer service inquiries; (5) protect the rights,
              property, or personal safety of iQ4, our Users or the public; (6) respond to requests
              of an employer whose systems are used by you to access the Services; (7) comply with
              the terms of an agreement with, or the direction of, iQ4.
            </li>

            <li>
              F. Achievement Wallet and Services. If you post or import content or information on
              communication features iQ4 may make available to you as part of the Services or on
              other iQ4 platforms, you should be aware that any personally identifiable information
              you choose to provide there may be read, collected, or used by other Users, as well as
              platform developers and other third parties. iQ4 is not responsible for the
              information you choose to submit in these communication features or forums. In most,
              but not all, instances you can delete your posts and imported content or information
              on the iQ4 Platform at any time but, depending upon your type of account, you may not
              have control over the ability to delete such items. Additionally, please note that
              ideas you post and information you share may be seen and used by other Users, and iQ4
              cannot guarantee that other Users have not made copies of or use the ideas and
              information that you share with others on iQ4.
            </li>

            <li>
              G. Testimonials and Advertisements. If you provide any testimonials about the
              Services, we may post those testimonials in connection with our promotion of these
              Services to third parties, but we will only post such testimonials and/or
              advertisements with your consent. Testimonials and advertisements may include
              personally identifiable information you have provided.
            </li>

            <li>
              H. Compliance with Legal Process. It is possible that we may need to disclose personal
              information, profile information, and/or information about your activities as a User
              when required by subpoena or other legal process, or if iQ4 has a good faith belief
              that disclosure is necessary to (a) investigate, prevent or take action regarding
              suspected or actual illegal activities or to assist government enforcement agencies;
              (b) to enforce the Terms &amp; Conditions, to investigate and defend ourselves against
              any third party claims or allegations, or to protect the security or integrity of our
              site; and/or (c) to exercise or protect the rights, property, or personal safety of
              iQ4, other Users, employees, or the public.
            </li>

            <li>
              I. Disclosures to Others as the Result of a Change in Control or Sale of iQ4. We may
              also disclose your personal information and other information you provide to another
              third party as part of a sale of the assets of iQ4 Corporation, a subsidiary or
              division, or as the result of a change in control of the company. Any third party to
              which iQ4 transfers or sells iQ4&rsquo;s assets will have the right to continue to use
              the personal and other information that you provide to us under the terms of this
              Privacy Policy.
            </li>
          </ol>
        </li>
        <li>
          <h2>3. Your Information Choices</h2>
          <ol>
            <li>
              A. Accessing and Changing Your Membership Information. You can review the personal
              information you provided to us by contacting us in accordance with Section 8. You can
              make any desired changes to your profile or other information you publish at any time
              by logging in to the Achievement Wallet website and revising such information. Please
              be aware that even after your request for a change is processed, iQ4 may, for a time,
              retain historical information about you and/or your profile in its backup and/or
              archival copies of its database. iQ4 membership profiles are also defaulted to allow
              Users to be contacted to participate in polls and surveys. Please contact us in
              accordance with Section 8 to change these settings if permitted by the applicable iQ4
              platform in which you are a member.
            </li>

            <li>
              B. Terminating Your Membership. You can terminate your membership through the iQ4
              customer service contact in Section 8. If you terminate your iQ4 membership, we will
              remove your name and other personally identifiable information from our database that
              may be viewable by other Users of the Services and/or other iQ4 platforms. If you
              terminate your membership, we have no obligation to retain your information, and may
              delete any or all of your membership information without liability. However, we may
              retain certain data contributed by you if iQ4 believes it may be necessary to prevent
              fraud or future abuse, or for legitimate business purposes, such as analysis of
              aggregated, non-personally identifiable data, account recovery, or if required by law.
              iQ4 may also retain and use your information if necessary to provide the Services to
              other Users. For example, just as an email you may send to another person through an
              email service provider resides in that person&rsquo;s inbox even after you delete it
              from your sent files or terminate your membership, your contributions to the Services
              or other iQ4 platforms, may remain visible to others after you have terminated your
              membership. Similarly, other information you have shared with others, or that other
              Users have copied, may also remain visile. iQ4 disclaims any liability in relation to
              the deletion or retention (subject to the terms herein) of
            </li>

            <li>
              C. Memorializing Memberships. If we learn that a User is deceased, we may memorialize
              the User&rsquo;s membership. In these cases, we may restrict profile access, remove
              messaging functionality, and terminate such membership if we receive a formal request
              from the User&rsquo;s next of kin or other proper legal request to do so.
            </li>
          </ol>
        </li>
        <li>
          <h2>4. Your Obligations</h2>
          <br />
          <br />
          As a User, you have certain obligations to other Users. Some of these obligations are
          imposed by applicable law and regulations, and others have become commonplace in
          user-friendly communities of like-minded members such as iQ4:
          <ol>
            <li>
              - You must, at all times, abide by this Privacy Policy and the Terms &amp; Conditions.
              This includes respecting all intellectual property rights that may belong to third
              parties (such as trademarks, copyrights, patents, and trade secrets).
            </li>
            <li>
              - You must not download or otherwise disseminate any information that may be deemed to
              be injurious, violent, offensive, obscene, racist, or xenophobic, or which may
              otherwise violate the purpose and spirit of iQ4 and its communities of Users.
            </li>
            <li>
              - You must not provide to iQ4 and/or other User information that you believe might be
              injurious or detrimental to your person or to your professional or social status.
            </li>
            <li>
              - You must keep your username and password confidential and not share it with others.
              Any violation of these guidelines may lead to the restriction, suspension, or
              termination of your account at the sole discretion of iQ4.
            </li>
          </ol>
        </li>
        <li>
          <strong>5. Important information</strong>
          <ol>
            <li>
              A. Scope. This Privacy Policy covers the Achievement Wallet website,and the Services,
              and other iQ4 platforms iQ4 may make available to you. Other iQ4 platforms or iQ4
              websites may have additional or different privacy terms governing your use and the
              information you share by accessing and using the other iQ4 platforms and/or websites.
              This Privacy Policy does not apply to entities that iQ4 does not own or control, such
              as third party applications and websites using the Achievement Wallet website and iQ4
              technology. By using or accessing the Achievement Wallet website and/or Services, you
              agree to our privacy practices outlined in this Privacy Policy.
            </li>
            <li>
              B. Children and Minors. Children under the age of 13 are not eligible to use our
              Services and we do not knowingly collect personal information from children under such
              age. If we learn that we have collected personal information on a child under 13, we
              will delete that data from our systems. iQ4 encourages parents and guardians of minors
              13 years and older to go online with their children and that parents provide their
              consents before the minor sends any information about themselves to anyone over the
              internet.
            </li>
            <li>
              C. Changes to this Privacy Policy. We may update this Privacy Policy at any time, with
              or without advance notice. In the event there are significant changes in the way we
              treat your personally identifiable information, or in the Privacy Policy document
              itself, we will display a notice on the Achievement Wallet website or send you an
              email so that you may review the changed terms prior to continuing to use the
              Services. As always, if you object to any changes to the Privacy Policy and you no
              longer wish to use iQ4, you may terminate your membership with iQ4. Unless stated you
              and your membership. Using the iQ4 Platform after a notice of changes has been sent to
              you or published on our site shall constitute consent to the changed terms or
              practices.
            </li>
            <li>
              D. Conflict with other iQ4 Agreements. This Privacy Policy is intended to control with
              respect to conflicts between it and the Terms &amp; Conditions with respect to the
              subject matter hereof. This Privacy Policy may be further supplemented with additional
              terms by a separate written agreement entered into by and between iQ4 and a User. In
              the event of a conflict between this Privacy Policy and such separate agreement, the
              terms of the separate agreement shall control.
            </li>
          </ol>
        </li>
        <li>
          <strong>6. Security</strong>
          <p>
            In order to help secure your personal information, access to your data on iQ4 is
            password-protected, and sensitive data (such as credit card information) is protected by
            SSL encryption when it is exchanged between your web browser and the Achievement Wallet
            website. To protect any data you store on our servers or servers of iQ4&rsquo;s service
            providers, iQ4 does not audit its system or require its service providers to audit their
            systems for possible vulnerabilities and attacks. We also back-up, and require our
            service providers, to back-up any data and information. However, since the internet is
            not a 100% secure environment, we cannot ensure or warrant the security of any
            information you transmit to iQ4. There is no guarantee that information may not be
            accessed, disclosed, altered, or destroyed by breach of any of our physical, technical,
            or managerial safeguards. It is your responsibility to protect the security of your
            login information. Please note that emails, instant messaging, chat rooms, bulletin
            boards, and similar means of communication with other Users that may be made available
            as part of the Services are not encrypted, and we strongly advise you not to communicate
            any confidential information through these means.
          </p>
        </li>
        <li>
          <h2>7. Rights of Users located in the European Union</h2>
          <br />
          <br />
          Notwithstanding any provision to the contrary in this Privacy Policy, under Chapter 3 of
          the General Data Protection Regulation 2016/679 (&ldquo;GDPR&rdquo;), Users in the
          European Union are guaranteed the following eight (8) rights:
          <ol>
            <li>
              (1) The right to be informed; This means anyone processing your personal data must
              make clear what they are processing, why, and who else the data may be passed to.
            </li>
            <li>
              (2) The right of access; this is your right to see what data is held about you by a
              Data Controller. The Data Controller is iQ4 Corporation.
            </li>
            <li>
              (3) The right to rectification; the right to have your data corrected or amended if
              what is held is incorrect in some way.
            </li>
            <li>
              (4) The right to erasure; under certain circumstances you can ask for your personal
              data to be deleted. This is also called the Right to be Forgotten. This would apply if
              the personal data is no longer required for the purposes it was collected for, or your
              consent for the processing of that data has been withdrawn, or the personal data has
              been unlawfully processed.
            </li>
            <li>
              (5) The right to restrict processing; this gives the User the right to ask for a
              temporary halt to processing of personal data, such as in the case where a dispute or
              legal case has to be concluded, or the data is being corrected.
            </li>
            <li>
              (6) The right to data portability; a User has the right to ask for any data supplied
              directly to the Data Controller by him or her, to be provided in a structured,
              commonly used, and machine-readable format.
            </li>
            <li>
              (7) The right to object; the User has the right to object to further processing of
              their data which is inconsistent with the primary purpose for which it was collected,
              including profiling, automation, and direct marketing.
            </li>
            <li>
              (8) Rights in relation to automated decision making and profiling; Users have the
              right not to be subject to a decision based solely on automated processing.
            </li>
          </ol>
        </li>
        <li>
          <h2>8. Other information:</h2>
          <ol>
            <li>
              A. Information Transfer: We may transfer personal information to other countries, for
              example, for customer service or to process transactions.
            </li>
            <li>
              B: California: If you have a California mailing that you provide in connection with
              your use of the Achievement Wallet, we will not share personal information we collect
              about you except to the extent permitted under California law.
            </li>
            <li>
              C. Vermont: If you have a Vermont mailing address that you provide in connection with
              your use of the Achievement Wallet, we will not share personal information we collect
              about you with non-affiliates unless the law allows or you provide authorization.
            </li>
          </ol>
        </li>
        <li>
          <h2>9. How to contact us</h2>
          <p>
            If you have questions or comments about this Privacy Policy, please email our Data
            Protection Officer at info@iQ4.com or contact us at:
          </p>
          <p>
            iQ4 Corporation
            <br />
            iQ4 Corporation, 300 Tice Boulevard, Suite 315, Woodcliff Lake, New Jersey, 07417 Attn:
            Leon Podolsky CTA
          </p>
        </li>
      </ol>
    </div>
  </div>
);
