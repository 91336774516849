import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ActionIcon } from 'common/components/ActionIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import _uniqueId from 'lodash/uniqueId';
import './MenuButton.scss';

/**
 * (WIP) Dropdown Menu component that is controlled by a button click and renders an array of clickable menu items
 * @param id - Unique id for aria control references (unsure if this is a requirement yet)
 * @param className
 * @param items - Array of Menu Item Objects, label(node) & action(clickAction)
 */

export const MenuButton = (props) => {
  const { className, items, ...menuButtonProps } = props;
  const [id] = useState(_uniqueId('dropdown-meun-'));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuItems = items.map((item, index) => (
    <MenuItem
      role="button"
      key={index}
      onClick={() => {
        handleClose();
        item.action && item.action();
      }}
    >
      {item.label}
    </MenuItem>
  ));

  return (
    <div className={`iq4-menu-button ${className}`} {...menuButtonProps}>
      <ActionIcon
        isDark
        className="iq4-menu-button__action-icon"
        iconName="options"
        buttonLabel="Select to open menu of further options"
        buttonClick={handleClick}
        aria-controls={id}
        aria-haspopup="true"
      />
      <Menu id={id} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {MenuItems}
      </Menu>
    </div>
  );
};

MenuButton.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      action: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

MenuButton.defaultProps = {
  className: '',
};
