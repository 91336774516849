import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  TextInput,
  AutoComplete,
  TextArea,
  Checkbox,
  DateRange,
  FileUpload,
  AccessibleSelect,
} from 'common/components/Form/fieldTypes';
import { CredentialPill } from 'common/components';
import { selectFeatures } from 'store/features/config/selectors';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { validations } from 'common/components/Form/validations';
import { PROFILE_EXPERIENCE_URL } from 'common/constants/endpoints';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import {
  setProfileSection,
  updateProfileSection,
} from 'common/store/features/profile/profileSlice';
import { PROFILE_TYPE, sendFormDataRequest, transformProfileFormsPutData } from 'common/utils';
import './useProfileExperienceFormDefinition.scss';

const requiredValidator = { required: validations.isRequired() };

const employmentTypeOptions = [
  {
    key: 'Full-time',
    value: 'Full-Time',
  },
  {
    key: 'Part-time',
    value: 'Part-time',
  },
  {
    key: 'Self-employed',
    value: 'Self-employed',
  },
  {
    key: 'Freelance',
    value: 'Freelance',
  },
  {
    key: 'Contract',
    value: 'Contract',
  },
  {
    key: 'Internship',
    value: 'Internship',
  },
  {
    key: 'Apprenticeship',
    value: 'Apprenticeship',
  },
];

export const useProfileExperienceFormDefinition = ({ values, onComplete }) => {
  const { selfAttestedEnabled } = useSelector(selectFeatures);
  const dispatch = useDispatch();
  const [competenciesSearchTerm, setCompetenciesSearchTerm] = useState('');
  const competenciesData = useSkillsSearch(competenciesSearchTerm);

  const fieldsDefinition = [
    {
      component: () => <CredentialPill credential={values} />,
    },
    {
      label: 'Role Title',
      name: 'title',
      component: TextInput,
      validators: {
        ...requiredValidator,
      },
    },
    {
      label: 'Company',
      name: 'company',
      component: TextInput,
      validators: {
        ...requiredValidator,
      },
    },
    {
      label: 'Location',
      name: 'location',
      component: TextInput,
    },
    {
      label: 'Industry',
      name: 'companyIndustry',
      component: TextInput,
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <AccessibleSelect
          maxWidth
          label="Employment Type"
          id="employmentType"
          name="employmentType"
          options={employmentTypeOptions}
          {...formProps}
        />
      ),
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => (
        <div>
          <Checkbox
            labelAfter
            name="isCurrentRole"
            label="I currently work here"
            checkboxClass="iq4-onboarding-experience__checkbox"
            {...formProps}
          />
        </div>
      ),
    },
    {
      isCustom: true,
      component: ({ key, getValues, ...formProps }) => {
        const { isCurrentRole } = getValues();
        return (
          <DateRange isPresent={isCurrentRole} key={key} {...formProps} getValues={getValues} />
        );
      },
    },
    ...(selfAttestedEnabled
      ? [
          {
            isCustom: true,
            component: ({ ...formProps }) => (
              <AutoComplete
                name="competencies"
                label="Skills"
                placeholder="Type to search your skills"
                options={competenciesData}
                optionLabel="value"
                onInputChange={(e, value) => setCompetenciesSearchTerm(value)}
                tooltip={
                  <p>
                    Start typing to add skills. <br />
                    <br />
                    Skills examples include: Teamwork, C++, Sales, etc.
                  </p>
                }
                {...formProps}
              />
            ),
          },
        ]
      : []),
    {
      label: 'Description',
      name: 'description',
      component: TextArea,
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <FileUpload
          triggerLabel="Upload a cover image"
          name="image"
          type="thumbnail"
          fileTypes={THUMBNAIL_MIME_TYPES}
          {...formProps}
        />
      ),
    },
  ];

  const onEdit = (data) => {
    return sendFormDataRequest({
      url: `${PROFILE_EXPERIENCE_URL}/${values.id}`,
      verb: 'PUT',
      data: transformProfileFormsPutData(data, values),
    }).then((resp) =>
      dispatch(updateProfileSection({ section: PROFILE_TYPE.EXPERIENCE, value: resp })),
    );
  };

  const onCreate = (data) => {
    return sendFormDataRequest({
      url: PROFILE_EXPERIENCE_URL,
      verb: 'POST',
      data: {
        ...data,
        ...(data.competencies && {
          competencies: data.competencies.map((competency) => ({ id: competency.id })),
        }),
      },
    }).then((resp) => {
      return dispatch(setProfileSection({ section: PROFILE_TYPE.EXPERIENCE, value: resp }));
    });
  };

  return {
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
