import React from 'react';
import { useHover } from 'common/hooks/useHover';

export const PathwayGraphLegend = () => {
  const [purpleRef, isPurpleHovered] = useHover();
  const [darkRef, isDarkHovered] = useHover();

  return (
    <div className="iq4-pathway__legend">
      <div className="iq4-pathway__legend--item">
        <div className="iq4-pathway__legend--square-dark" ref={darkRef} />
        <span>Matched</span>
        {isDarkHovered && (
          <div className="iq4-pathway__legend--tooltip">Role skills which you have</div>
        )}
      </div>
      <div className="iq4-pathway__legend--item">
        <div className="iq4-pathway__legend--square-purple" ref={purpleRef} />
        <span>Missing</span>
        {isPurpleHovered && (
          <div className="iq4-pathway__legend--tooltip">Role skills which you do not have</div>
        )}
      </div>
    </div>
  );
};
