import { combineReducers } from '@reduxjs/toolkit';

import config from './features/config/configSlice';
import profile from '../common/store/features/profile/profileSlice';
import onboarding from '../common/store/features/onboarding/onboardingSlice';
import skills from '../common/store/features/skills/skillsSlice';
import parsedResume from '../common/store/features/parsedResume/parsedResumeSlice';
import pathways from '../common/store/features/pathways/pathwaysSlice';
import records from '../common/store/features/records/recordsSlice';
import ui from '../common/store/features/ui/uiSlice';
import t from '../common/store/features/t/slice';
import session from '../common/store/features/session/sessionSlice';
import errors from '../common/store/features/errors/errorsSlice';
import connections from 'common/store/features/connections/connectionsSlice';
import framework from '../common/store/features/framework/frameworkSlice';
import credentials from '../common/store/features/credentials/credentialsSlice';

const rootReducer = combineReducers({
  config,
  profile,
  onboarding,
  skills,
  parsedResume,
  pathways,
  records,
  ui,
  t,
  session,
  errors,
  connections,
  framework,
  credentials,
});

export default rootReducer;
