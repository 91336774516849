import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import { OnboardingProfilePage } from './OnboardingProfilePage';
import { OnboardingResumePath } from './OnboardingResumePath/OnboardingResumePath';
import { SKILLS_URL } from 'common/constants/endpoints';
import { setSkills } from 'common/store/features/skills/skillsSlice';
import './OnboardingPage.scss';
import { OnboardingWelcomePage } from './OnboardingWelcomePage';

export const OnboardingPage = () => {
  const context = process.env.REACT_APP_CONTEXT;

  const { pathname } = useLocation();
  const { push } = useHistory();
  const dispatch = useDispatch();

  // if user lands on /onboarding redirect to resume path
  if (pathname === `${context}/onboarding` || pathname === `${context}/onboarding/`) {
    push(ROUTES.ONBOARDING_WELCOME);
  }

  // get skills for onboarding
  useEffect(() => {
    fetch(SKILLS_URL, {
      method: 'GET',
    })
      .then((resp) => resp.json())
      .then((data) => data.map((skill) => ({ ...skill, selected: true })))
      .then((skills) => dispatch(setSkills(skills)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Switch>
      <Route exact path={ROUTES.ONBOARDING_WELCOME}>
        <OnboardingWelcomePage />
      </Route>
      {/* resume onboarding path */}
      <Route exact path={[ROUTES.ONBOARDING_RESUME, ROUTES.ONBOARDING_RESUME_COMPLETE]}>
        <OnboardingResumePath />
      </Route>

      {/* create profile manually path */}
      <Route
        exact
        path={[
          ROUTES.ONBOARDING_PERSONAL,
          ROUTES.ONBOARDING_SKILLS,
          ROUTES.ONBOARDING_ASSESSMENT,
          ROUTES.ONBOARDING_ADD_SKILLS,
          ROUTES.ONBOARDING_EDUCATION,
          ROUTES.ONBOARDING_EXPERIENCE,
          ROUTES.ONBOARDING_PORTFOLIO,
        ]}
      >
        <OnboardingProfilePage />
      </Route>
    </Switch>
  );
};
