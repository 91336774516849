import React from 'react';
import { SimpleIcon, ExternalLinkInterstitial } from 'common/components';
import { INTERSTITIAL_COPY } from 'common/constants/interstitialCopy';
import './LearningResourcesCard.scss';

export const LearningResourcesCard = ({ achievement, className }) => {
  const { name, achievementUrl, school, achievementId, learnMoreUrl, id } = achievement;

  return (
    <li className={`iq4-learning-resource-card ${className ? className : ''}`}>
      <div className="iq4-learning-resource-card__row-1">
        <div className="iq4-learning-resource-card__icon">
          <SimpleIcon name="education" />
        </div>
        <div className="iq4-learning-resource-card__course-container">
          <p className="iq4-learning-resource-card__course-title">{name}</p>
          <p className="iq4-learning-resource-card__course-institution">{school?.name}</p>
        </div>
      </div>

      <div className="iq4-learning-resource-card__row-2">
        <div className="iq4-learning-resource-card__location-container">
          {!!(achievementUrl || learnMoreUrl) && (
            <ExternalLinkInterstitial
              className={`iq4-learning-resource-card__link iq4-refocus-${id}`}
              linkLabel="Learn More"
              link={learnMoreUrl || achievementUrl}
              introText={INTERSTITIAL_COPY.BADGR_INTRO_TEXT}
              mainText={INTERSTITIAL_COPY.BADGR_MAIN_TEXT}
              name={name}
              id={id}
            />
          )}
          {!achievementUrl && (
            <div className="iq4-learning-resource-card__location">{achievementId}</div>
          )}
        </div>
      </div>
    </li>
  );
};
