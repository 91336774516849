export const MONTHS = [
  {
    key: '01',
    value: 'January',
  },
  {
    key: '02',
    value: 'February',
  },
  {
    key: '03',
    value: 'March',
  },
  {
    key: '04',
    value: 'April',
  },
  {
    key: '05',
    value: 'May',
  },
  {
    key: '06',
    value: 'June',
  },
  {
    key: '07',
    value: 'July',
  },
  {
    key: '08',
    value: 'August',
  },
  {
    key: '09',
    value: 'September',
  },
  {
    key: '10',
    value: 'October',
  },
  {
    key: '11',
    value: 'November',
  },
  {
    key: '12',
    value: 'December',
  },
];

export const SELECT_MONTHS = [
  {
    id: 1,
    label: 'January',
  },
  {
    id: 2,
    label: 'February',
  },
  {
    id: 3,
    label: 'March',
  },
  {
    id: 4,
    label: 'April',
  },
  {
    id: 5,
    label: 'May',
  },
  {
    id: 6,
    label: 'June',
  },
  {
    id: 7,
    label: 'July',
  },
  {
    id: 8,
    label: 'August',
  },
  {
    id: 9,
    label: 'September',
  },
  {
    id: 10,
    label: 'October',
  },
  {
    id: 11,
    label: 'November',
  },
  {
    id: 12,
    label: 'December',
  },
];

export const generateYearRange = (min, max) => {
  const result = [];
  for (let i = max; i >= min; i--) {
    result.push({
      key: i.toString(),
      value: i.toString(),
    });
  }
  return result;
};
