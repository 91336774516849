const data = {};

/**
 * Set a key to Local Storage
 * @param key
 * @param value
 */
const set = (key, value) => {
  try {
    if (value === undefined) {
      delete data[key];
      window.localStorage.removeItem(key);
    } else {
      data[key] = value;
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get a key from Local Storage (we can pass a fallback value, if needed)
 * @param key
 * @param defaultValue
 * @returns {any}
 */
const get = (key, defaultValue) => {
  const fallbackValue = key in data ? data[key] : defaultValue;
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : fallbackValue;
  } catch (error) {
    return fallbackValue;
  }
};

/**
 * Remove a key from Local Storage
 * @param key
 */
const remove = (key) => {
  try {
    delete data[key];
    window.localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
};

export default {
  get,
  set,
  remove,
};
