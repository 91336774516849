import React from 'react';
import { Collapse, TableCell, TableRow } from '@material-ui/core';
import { Button } from 'common/components';
import './CredentialExpanded.scss';

export const CredentialExpanded = ({
  record,
  open,
  groups = [],
  actionButtonLabel,
  actionButtonAction,
  isCancelAction,
  renderAsTableSemantics = true,
}) => {
  return (
    <div
      className={`iq4-credential-expanded ${
        !renderAsTableSemantics ? 'iq4-credential-expanded--div' : ''
      }`}
    >
      <div
        className={`iq4-credential-expanded__cell ${
          !renderAsTableSemantics ? 'iq4-credential-expanded__cell--div' : ''
        }`}
        style={{ padding: 0 }}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="iq4-credential-expanded__container">
            {record && (
              <>
                <div className="iq4-credential-expanded__container--line" />
                <div className="iq4-credential-expanded">
                  {groups.map((group, index) => (
                    <div
                      className={`iq4-credential-expanded__group ${
                        isCancelAction ? `iq4-credential-expanded__group--last` : ''
                      }`}
                      key={`group-${index}`}
                    >
                      {group.map((item, index) => (
                        <div
                          className="iq4-credential-expanded__group__item"
                          key={`group-item-${index}`}
                        >
                          {item}
                        </div>
                      ))}
                    </div>
                  ))}

                  <div
                    className={`iq4-credential-expanded__group iq4-credential-expanded__group-action 
                  ${isCancelAction ? `iq4-credential-expanded__group-action--cancel` : ''}`}
                  >
                    {actionButtonLabel && actionButtonAction && (
                      <Button
                        className="iq4-credential-expanded__button"
                        onClick={() => actionButtonAction()}
                        variation="ghost"
                      >
                        {actionButtonLabel}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Collapse>
      </div>
    </div>
  );
};
