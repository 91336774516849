import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './Expand.scss';

/**
 * Custom Expand panel component overriding the Material UI ExpansionPanel component
 * @param {*} [title] - The title of the expand
 * @param {String} [className] - Custom className for component
 * @param {boolean} [isOuterCard] - Add's outer card styles, if set
 * @param {*} children - children to render in the expand content section
 * @param {*} expandByDefault - Allow expanding panel by default
 */
export const Expand = ({
  children,
  className,
  title = '',
  isOuterCard = false,
  uniqueId = '',
  label = '',
  expandByDefault = false,
  expandable = true,
  titleHelp = null,
  ...props
}) => {
  const [isExpanded, setIsExpanded] = useState(expandByDefault);
  return (
    <Accordion
      className={`iq4-expand-panel ${isOuterCard ? 'iq4-expand-panel--outer' : ''} ${
        className ? className : ''
      }`}
      expanded={isExpanded}
      onChange={() => (expandable ? setIsExpanded(!isExpanded) : null)}
      {...props}
    >
      {expandable && (
        <AccordionSummary
          className="iq4-expand-panel__title-container"
          classes={{ focused: expandable ? '' : 'iq4-white-bg' }}
          expandIcon={<ExpandMoreIcon />}
          aria-label={label}
          id={uniqueId}
          role={expandable ? 'button' : 'presentation'}
          {...(!expandable && { tabIndex: -1 })}
        >
          <h2 className="iq4-expand-panel__title">{title}</h2>
          {titleHelp && titleHelp()}
        </AccordionSummary>
      )}
      {!expandable && (
        <div
          className="iq4-expand-panel__title-container"
          style={{ position: 'relative' }}
          aria-label={label}
        >
          <h2 className="iq4-expand-panel__title">{title}</h2>
          <div style={{ position: 'absolute', left: '100px', top: '10px' }}>
            {titleHelp && titleHelp()}
          </div>
        </div>
      )}
      <AccordionDetails className="iq4-expand-panel__content">{children}</AccordionDetails>
    </Accordion>
  );
};
