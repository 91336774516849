import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import './ActivateCodePage.scss';
import { ROUTES } from 'routes/routeNames';
import { ACTIVATE_CODE_URL } from 'common/constants/endpoints';
import { ActivateCodeForm } from 'common/components';
import { selectFeatures } from 'store/features/config/selectors';

export const ActivateCodePage = () => {
  const { defaultRoute } = useSelector(selectFeatures);

  return (
    <ActivateCodeForm
      Logo={Logo}
      onSuccessLocation={ROUTES.REGISTER_USERS}
      activateEndpoint={ACTIVATE_CODE_URL}
    />
  );
};
