import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { sessionTimeoutMiddleware } from 'common/store/middlewares';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sessionTimeoutMiddleware),
});

export default store;
