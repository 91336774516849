import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  PATHWAYS_URL,
  PATHWAYS_RECOMMENDED_URL,
  PATHWAYS_FRAMEWORK_URL,
  PATHWAYS_JOB_FAMILY_WORKROLES_URL,
  DASHBOARD_RECOMMENDED_URL,
  DASHBOARD_WORK_ROLE_URL,
  DASHBOARD_PATHWAYS_FRAMEWORK_URL,
  PATHWAYS_COS_JOBS_URL,
  PATHWAYS_USER_SELECTED_WORKROLES_URL,
  ROLE_MISSING_SKILLS_INFO_URL,
} from 'common/constants/endpoints';
import {
  setRoleAsSelected,
  setRoleAsUnselected,
  updateRoleMissingSkills,
} from 'common/store/features/framework/frameworkSlice';
import { JOB_TITLE_EXCLUSION, OVERLY_GENERIC_TERMS, sendRequest } from 'common/utils';
import _uniqBy from 'lodash/uniqBy';
import localStorage from 'common/utils/localStorage';
import { updateRolesFromPathwaySelected } from 'common/store/features/framework/frameworkSlice';

const cosUserId = process.env.REACT_APP_COS_USER_ID;

export const fetchUserSelectedWorkRolesReq = createAsyncThunk(
  'framework/fetchUserSelectedWorkRolesReq',
  async (_, { rejectWithValue, dispatch }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    const localData = localStorage.get('userPathwayNodes');

    /*    if (localData) {
      console.log('getting local storage data:', localData);
      return localData;
    }*/

    dispatch(setIsFetchingSelectedWorkRoles(true));
    return fetch(`${PATHWAYS_USER_SELECTED_WORKROLES_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'GET',
    })
      .then(async (response) => {
        if (response.ok) {
          const selectedRoles = await response.json();
          dispatch(updateRolesFromPathwaySelected({ selectedRoles }));
          return selectedRoles;
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
        dispatch(setIsFetchingSelectedWorkRoles(false));
      });
  },
);

export const fetchDashboardFrameworkByUserReq = createAsyncThunk(
  'pathways/fetchFrameworkReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${DASHBOARD_PATHWAYS_FRAMEWORK_URL}?userId=${id}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchRoleMissingSkillsInfoReq = createAsyncThunk(
  'pathways/fetchRoleMissingSkillsInfoReq',
  async ({ role }, { rejectWithValue, dispatch }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    return fetch(
      `${ROLE_MISSING_SKILLS_INFO_URL}/${role.id}/skills${isPublic ? '?userId=' + userId : ''}`,
      {
        method: 'GET',
      },
    )
      .then(async (response) => {
        if (response.ok) {
          const skills = await response.json();
          dispatch(updateRoleMissingSkills({ role, skills }));
          return skills;
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchFrameworkReq = createAsyncThunk(
  'pathways/fetchFrameworkReq',
  async (_, { rejectWithValue }) => {
    return fetch(PATHWAYS_FRAMEWORK_URL, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchWorkrolesByJobFamilyReq = createAsyncThunk(
  'pathways/fetchWrokrolesByJobFamilyReq',
  async (jobFamilyId, { rejectWithValue }) => {
    return fetch(`${PATHWAYS_JOB_FAMILY_WORKROLES_URL}?jobFamilyId=${jobFamilyId}`, {
      method: 'GET',
    })
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchWorkRoleJobsReq = createAsyncThunk(
  'pathways/fetchWorkRoleJobsReq',
  async ({ role, id }, { getState, dispatch }) => {
    const keywords = role.useSearchTitles ? role.searchTitles : role.onets;
    const selectedLocation = localStorage.get('selectedLocation');

    const jobRecsResult = await Promise.all(
      keywords.map((title) =>
        sendRequest({
          url: `${PATHWAYS_COS_JOBS_URL}/${cosUserId}/${encodeURIComponent(title)}/${
            selectedLocation || 0
          }/25/0/0/0/10/10?source=NLx&showFilters=false`,
          verb: 'GET',
        }),
      ),
    );

    const liveJobs = [].concat.apply(
      [],
      jobRecsResult.map((x) => x['Jobs']),
    );

    // filter out results
    const allUniqueWordsFromSearchTitles = [
      // unique
      ...new Set(
        [].concat
          .apply(
            [],
            keywords.map((x) => x.split(' ')),
          )
          // exclude overly generic terms that might bring other unwanted results
          .filter((x) => !OVERLY_GENERIC_TERMS.includes(x.toString().toLowerCase()))
          // to lowercase for matching purposes
          .map((x) => x.toString().toLowerCase()),
      ),
    ];

    const finalResult = liveJobs
      .filter(
        (job) =>
          !JOB_TITLE_EXCLUSION.some((title) =>
            job.JobTitle.toString().toLowerCase().includes(title),
          ),
      )
      .filter((job) => {
        if (!role.useSearchTitles) {
          return true;
        }

        const jobTitleWords = job.JobTitle.split(' ').map((x) => x.toString().toLowerCase());
        return jobTitleWords.some((item) => allUniqueWordsFromSearchTitles.includes(item));
      })
      // randomise it
      .sort(() => Math.random() - 0.5)
      // select only 10 elements
      .slice(0, 10);

    dispatch(setIsFetchingJobs(false));
    return finalResult;
  },
);

export const fetchRecommendedRolesReq = createAsyncThunk(
  'pathways/fetchRecommendedRolesReq',
  async (_, { rejectWithValue }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    return fetch(`${PATHWAYS_RECOMMENDED_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const addPathwayReq = createAsyncThunk(
  'pathways/addPathwayReq',
  async (role, { dispatch, rejectWithValue }) => {
    const payload = [{ id: role?.id }];
    dispatch(setIsAddingRoleToPathway({ isAdding: true, roleBeingAdded: role?.id }));

    return fetch(`${PATHWAYS_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        dispatch(fetchRecommendedRolesReq());
        if (response.ok) {
          dispatch(setRoleAsSelected(role));
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const removePathwayReq = createAsyncThunk(
  'pathways/removePathwayReq',
  async (role, { dispatch, rejectWithValue }) => {
    const payload = [{ id: role?.id }];
    dispatch(setIsAddingRoleToPathway({ isAdding: true, roleBeingAdded: role?.id }));

    return fetch(`${PATHWAYS_URL}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        dispatch(fetchRecommendedRolesReq());
        if (response.ok) {
          dispatch(setRoleAsUnselected(role));
          return role?.id;
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const pathwaysSlice = createSlice({
  name: 'pathways',
  initialState: {
    recommended: [],
    addedSuggestions: [],
    isFetchingFramework: true,
    isAddingRoleToPathway: false,
    roleBeingAdded: null,
    selectedLocation: '0',
    isFetchingJobs: false,
    isFetchingDemographicData: false,
    jobFamilies: [],
    selectedJobFamily: {},
    userSelectedWorkRoles: [],
    roleIdsFetchedMissingSkills: [],
    selectedWorkRole: null,
    isFetchingSelectedWorkRoles: false,
    hasFetchedSelectedWorkRoles: false,
    hasFetchedRecommendedRoles: false,
    showOnlyInternships: false,
  },
  reducers: {
    removeAddedSuggestion: (state, action) => {
      state.addedSuggestions = state.addedSuggestions.filter((sug) => sug.id !== action.payload.id);
    },
    addAddedSuggestion: (state, action) => {
      state.addedSuggestions = state.addedSuggestions.concat(action.payload);
    },
    setUserSelectedWorkRoles: (state, action) => {
      state.userSelectedWorkRoles = action.payload;
    },
    setShowOnlyInternships: (state, action) => {
      state.showOnlyInternships = action.payload;
    },
    resetPathways: (state, action) => {
      state.recommended = [];
      state.userSelectedWorkRoles = [];
      state.hasFetchedSelectedWorkRoles = false;
      state.hasFetchedRecommendedRoles = false;
      localStorage.remove('userPathwayNodes');
    },
    setIsFetchingSelectedWorkRoles: (state, action) => {
      state.isFetchingSelectedWorkRoles = action.payload;
    },
    setIsAddingRoleToPathway: (state, action) => {
      state.isAddingRoleToPathway = action.payload.isAdding;
      state.roleBeingAdded = action.payload.roleBeingAdded;
    },
  },
  extraReducers: {
    [fetchFrameworkReq.fulfilled]: (state, action) => {
      state.framework = action.payload;
      state.isFetchingFramework = false;
    },
    [fetchWorkrolesByJobFamilyReq.fulfilled]: (state, action) => {
      state.selectedJobFamily = action.payload;
    },
    [addPathwayReq.fulfilled]: (state, action) => {
      const addedPathway = action.payload[0];

      state.userSelectedWorkRoles.push(addedPathway);
      localStorage.set('userPathwayNodes', state.userSelectedWorkRoles);
      state.isAddingRoleToPathway = false;
      state.roleBeingAdded = null;
    },
    [removePathwayReq.fulfilled]: (state, action) => {
      const removedRoleId = action.payload;
      const newUserSelectedRoles = state.userSelectedWorkRoles.filter(
        (workRole) => workRole.id !== removedRoleId,
      );
      state.userSelectedWorkRoles = newUserSelectedRoles;
      localStorage.set('userPathwayNodes', newUserSelectedRoles);
      state.isAddingRoleToPathway = false;
      state.roleBeingAdded = null;
    },
    [fetchRecommendedRolesReq.fulfilled]: (state, action) => {
      state.recommended = action.payload;
      state.hasFetchedRecommendedRoles = true;
    },

    [fetchUserSelectedWorkRolesReq.fulfilled]: (state, action) => {
      const userSelectedRoles = _uniqBy(action.payload, 'id');
      state.userSelectedWorkRoles = userSelectedRoles;
      localStorage.set('userPathwayNodes', userSelectedRoles);
      state.isFetchingSelectedWorkRoles = false;
      state.hasFetchedSelectedWorkRoles = true;
    },
  },
});

// action creators
export const {
  addAddedSuggestion,
  removeAddedSuggestion,
  setIsFetchingJobs,
  setIsFetchingDemographicData,
  setUserSelectedWorkRoles,
  setIsFetchingSelectedWorkRoles,
  setIsAddingRoleToPathway,
  resetPathways,
  setShowOnlyInternships,
} = pathwaysSlice.actions;

export default pathwaysSlice.reducer;
