import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './DataTable.scss';

export const SelectPageSize = ({ handlePageSizeChange, rows, rowsPerPage }) => {
  return (
    <FormControl variant="outlined" className="iq4-datatable__select iq4-select__form-control">
      <Select
        value={rowsPerPage}
        onChange={(e) => handlePageSizeChange(e.target.value)}
        IconComponent={ExpandMoreIcon}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 40 * 4.5 + 8,
            },
          },
        }}
        classes={{
          outlined: 'iq4-datatable-select__input',
          nativeInput: 'iq4-datatable-select__input-height',
        }}
      >
        {rowsPerPage && (
          <MenuItem value={rows.length} disabled>
            Select Page Size
          </MenuItem>
        )}
        {!rowsPerPage && (
          <MenuItem value={0} disabled>
            Select Page Size
          </MenuItem>
        )}
        <MenuItem disableRipple value={10}>
          <span>10 per page</span>
        </MenuItem>
        <MenuItem disableRipple value={20}>
          <span>20 per page</span>
        </MenuItem>
        <MenuItem disableRipple value={50}>
          <span>50 per page</span>
        </MenuItem>
      </Select>
    </FormControl>
  );
};
