import React from 'react';
import { SimpleIcon } from 'common/components';
import './TipBlock.scss';

export const TipBlock = ({ title, description }) => {
  return (
    <div className="iq4-tip-block">
      <div className="iq4-tip-block__title-container">
        <span className="iq4-tip-block__icon">
          <SimpleIcon name="tip" />
        </span>
        <p className="iq4-tip-block__title">{title}</p>
      </div>
      <p className="iq4-tip-block__description">{description}</p>
    </div>
  );
};
