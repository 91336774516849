import { defaultContent } from './default';
import { getWguContent } from './wgu';

export const getSpecificTermsContent = (instance) => {
  switch (instance) {
    case 'wgu':
      return getWguContent();
    default:
      return defaultContent();
  }
};
