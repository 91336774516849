import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'common/components/Form';

export const FormWrapper = ({
  values,
  onComplete,
  onChange,
  useFormDefinition,
  exposeFormContext,
  showButtons,
  layoutConfig,
  submitLabel = 'Save',
  onFieldChange = () => {},
}) => {
  const { formDefinition } = useFormDefinition({
    values,
    onComplete,
    onChange,
  });

  return (
    <Form
      showButtons={showButtons}
      formDefinition={formDefinition}
      values={values}
      submitLabel={submitLabel}
      exposeFormContext={exposeFormContext}
      layoutConfig={layoutConfig}
      onFieldChange={onFieldChange}
    />
  );
};

FormWrapper.propTypes = {
  values: PropTypes.object,
  onComplete: PropTypes.func,
  onChange: PropTypes.func,
  formDefinition: PropTypes.object,
  exposeFormContext: PropTypes.func,
  showButtons: PropTypes.bool,
  layoutConfig: PropTypes.shape({
    submitButtons: PropTypes.shape({
      position: PropTypes.oneOf(['left', 'center', 'right']),
      outline: PropTypes.bool,
    }),
  }),
};

FormWrapper.defaultProps = {
  values: {},
  onComplete: () => {},
  onChange: () => {},
  showButtons: true,
  layoutConfig: {
    submitButtons: {
      position: 'center',
      outline: false,
    },
  },
};
