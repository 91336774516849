import React from 'react';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './ProgressIndicator.scss';

/**
 * ProgressIndicator
 * NOTE: Can either use 'isActive' property inside step objects OR 'completedCount' prop
 * @param steps (Array of step objects)
 * @param completedCount (Optional, indicates number of completed steps)
 * @param stepClass
 * @returns {*}
 * @constructor
 */
export const ProgressIndicator = ({ steps, completedCount, stepClass }) => {
  const currentStepIndex = steps.findIndex((s) => s.isActive);

  return (
    <ul className={`iq4-progress-indicator ${stepClass ? stepClass : ''}`}>
      {steps.map((step, index) => (
        <li
          key={index}
          className={`iq4-progress-indicator__step ${
            index < completedCount || index < currentStepIndex
              ? 'iq4-progress-indicator__step--completed'
              : index === currentStepIndex
              ? 'iq4-progress-indicator__step--active'
              : ''
          }`}
        >
          <span className="iq4-progress-indicator_step-circle">
            <SimpleIcon
              className="iq4-progress-indicator_step-circle-tick"
              name="tick"
              role="presentation"
              aria-hidden={true}
            />
          </span>
        </li>
      ))}
    </ul>
  );
};
