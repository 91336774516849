import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  CREDENTIALS_API_URL,
  CREDENTIAL_SKILLS_API_URL,
  CREDENTIALS_STATUS_API_URL,
  DASHBOARD_QUERY_URL,
  LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL,
  LCN_GET_CREDENTIALS_BY_STATUS_URL,
  PARSE_SKILLS_ENDPOINT,
  PROFILE_PORTFOLIO_URL,
  BADGR_CREDENTIALS_URL,
  BADGR_LOGOUT_URL,
  BADGR_AUTH_URL,
  RESUME_PARSER_URL,
  CREDENTIAL_DISPUTE_API_URL,
} from 'common/constants/endpoints';
import { LCN_RECORD_STATES } from 'common/constants/lcnRecords';
import { setUI } from '../records/recordsSlice';
import {
  asyncSendFormDataRequest,
  PORTFOLIO_TYPE,
  sendFormDataRequest,
  isValidUrl,
} from '../../../utils';

export const fetchCredentialsReq = createAsyncThunk(
  'credentials/fetchCredentialsReq',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setIsFetchingCredentials(true));
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    const res = await fetch(
      `${CREDENTIALS_API_URL}${
        isPublic ? '?userId=' + userId : '?checkStatus=true&returnSkills=false'
      }`,
      {
        method: 'GET',
      },
    );

    return res.json();
  },
);

export const fetchCredentialSkillsReq = createAsyncThunk(
  'credentials/fetchCredentialSkillsReq',
  async ({ credential }, { dispatch, rejectWithValue }) => {
    const res = await fetch(`${CREDENTIALS_API_URL}${'/' + credential.id}`, {
      method: 'GET',
    });
    return res.json();
  },
);

export const fetchCredentialsStatusReq = createAsyncThunk(
  'credentials/fetchCredentialsStatusReq',
  async (_, { dispatch, rejectWithValue }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    const res = await fetch(`${CREDENTIALS_STATUS_API_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'GET',
    });

    return res.json();
  },
);

export const fetchVerifiedCredentials = createAsyncThunk(
  'records/fetchVerifiedCredentials',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setIsFetchingVerifiedCredentials(true));

    return fetch(`${LCN_GET_CREDENTIALS_BY_STATUS_URL}/${LCN_RECORD_STATES.ACTIVE}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setIsFetchingVerifiedCredentials(false));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchBadgrCredentials = createAsyncThunk(
  'records/fetchBadgrCredentials',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setIsFetchingVerifiedCredentials(true));

    let res;
    try {
      res = await fetch(`${BADGR_CREDENTIALS_URL}`, {
        method: 'GET',
      }).then((res) => res.json());
    } catch (e) {
      dispatch(setIsFetchingVerifiedCredentials(false));
      return rejectWithValue();
    }

    const data = res.data;
    dispatch(setIsFetchingVerifiedCredentials(false));

    return { status: res.status, data, anyAdded: res.anyAdded };
  },
);

export const submitCredentialDispute = createAsyncThunk(
  'credentials/submitCredentialDispute',
  async ({ selectedCredential, disputeMessage }, { dispatch, rejectWithValue }) => {
    dispatch(setIsSubmittingDispute(true));
    console.log('disputing', selectedCredential, disputeMessage);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: 200 });
      }, 2000);
    });
  },
);

export const submitBadgrLogoutReq = createAsyncThunk(
  'credentials/submitBadgrLogoutReq',
  async (_, { dispatch, rejectWithValue }) => {
    const res = await fetch(`${BADGR_LOGOUT_URL}`, {
      method: 'POST',
    });
    return res.json();
  },
);

export const submitNewCredential = createAsyncThunk(
  'credentials/submitNewCredential',
  async (
    { credential, credentialImg, selectedSkills, selectedDate },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(setIsAddingNewCredential(true));
    let payload = {
      ...credential,
      startYear: selectedDate?.year,
      startDay: selectedDate?.day,
      startMonth: selectedDate?.month?.toString(),
      competences: [
        ...credential?.skills
          .filter((x) => x.isChecked || x.isChecked === undefined)
          ?.map((x) => ({ id: x.competenceId, assessment: x.assessment })),
      ],
      ...(credentialImg && { image: credentialImg }),
    };

    const res = await asyncSendFormDataRequest({
      url: CREDENTIALS_API_URL,
      verb: 'POST',
      data: {
        ...payload,
      },
    });
    return res.json();
  },
);

export const submitEditCredential = createAsyncThunk(
  'credentials/submitEditCredential',
  async (
    { credential, credentialImg, selectedSkills, selectedDate },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(setIsAddingNewCredential(true));

    let payload = {
      ...credential,
      competences: [
        ...credential?.skills
          .filter((x) => x.isChecked || x.isChecked === undefined)
          ?.map((x) => ({ id: x.competenceId, assessment: x.assessment })),
      ],
      ...(credentialImg && { image: credentialImg }),
    };

    const res = await asyncSendFormDataRequest({
      url: `${CREDENTIALS_API_URL}/${credential?.id}`,
      verb: 'PUT',
      data: {
        ...payload,
      },
    });

    return res.json();
  },
);

export const confirmVerifiedCredential = createAsyncThunk(
  'credentials/confirmVerifiedCredential',
  async ({ credential }, { dispatch, rejectWithValue }) => {
    dispatch(setIsAddingNewCredential(true));

    const payload = {
      ...credential,
      competences: [
        ...credential?.skills
          .filter((x) => x.isChecked)
          ?.map((x) => ({ id: x.competenceId, assessment: x.assessment })),
      ],
      isDisputed: false,
    };

    const res = await asyncSendFormDataRequest({
      url: CREDENTIALS_API_URL,
      verb: 'POST',
      data: {
        ...payload,
      },
    });
    return res.json();
  },
);

export const submitDeleteCredential = createAsyncThunk(
  'credentials/submitDeleteCredential',
  async ({ id }) => {
    const res = await fetch(`${CREDENTIALS_API_URL}/${id}`, {
      method: 'DELETE',
    });

    return res.json();
  },
);

export const submitUpdateToCredential = createAsyncThunk(
  'credentials/submitUpdateToCredential',
  async ({ credential }, { dispatch, state }) => {
    dispatch(updatePublicCredentialStatus({ credential, isPublic: credential.isPublic }));
    const res = await asyncSendFormDataRequest({
      url: `${CREDENTIALS_API_URL}/${credential.id}`,
      verb: 'PUT',
      data: {
        isPublic: credential.isPublic,
      },
    });
    return res.json();
  },
);

export const fetchParsedSkillsReq = createAsyncThunk(
  'credentials/fetchParsedSkillsReq',
  async ({ url }, { dispatch, rejectWithValue }) => {
    dispatch(setIsParsingCredentials(true));
    const payload = {
      badgeParseUrls: [url],
    };

    const res = await fetch(`${PARSE_SKILLS_ENDPOINT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    return res.json();
  },
);

export const fetchParsedSkillsFromJsonReq = createAsyncThunk(
  'credentials/fetchParsedSkillsFromJsonReq',
  async ({ jsonBody }, { dispatch, rejectWithValue }) => {
    dispatch(setIsParsingCredentials(true));
    const payload = [
      {
        ...JSON.parse(jsonBody),
      },
    ];

    const res = await fetch(`${PARSE_SKILLS_ENDPOINT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    return res.json();
  },
);

export const fetchFileParsedSkillsReq = createAsyncThunk(
  'credentials/fetchFileParsedSkillsReq',
  async (attachment, { dispatch, rejectWithValue }) => {
    dispatch(setIsParsingCredentials(true));
    const res = await fetch(PARSE_SKILLS_ENDPOINT, {
      method: 'POST',
      body: attachment,
    });

    return res.json();
  },
);

export const submitVerifiedCredentialParseReq = createAsyncThunk(
  'credentials/submitVerifiedCredentialParseReq',
  async ({ credential }, { dispatch, rejectWithValue }) => {
    dispatch(setIsParsingVerifiedCredential(true));

    const res = await fetch(`${PARSE_SKILLS_ENDPOINT}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([credential]),
    });

    return res.json();
  },
);

export const submitDisputeCredentialReq = createAsyncThunk(
  'credentials/submitDisputeCredentialReq',
  async ({ credential }, { dispatch, rejectWithValue }) => {
    dispatch(setIsDisputingCredential(true));

    const res = await fetch(`${CREDENTIAL_DISPUTE_API_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([{ ...credential, isDisputed: true }]),
    });

    return res.json();
  },
);

export const credentialsSlice = createSlice({
  name: 'credentials',
  initialState: {
    isFetchingCredentials: false,
    hasFetchedCredentials: false,
    isFetchingVerifiedCredentials: false,
    isParsingCredentials: false,
    isDisputingCredential: false,
    hasParsedCredentials: false,
    isParsingVerifiedCredential: false,
    isAddingNewCredential: false,
    hasFetchedVerifiedCredentials: false,
    isSubmittingDispute: false,
    credentialsList: [],
    verifiedCredentialsList: [],
    verifiedCredentialsStatus: '',
    displayedCredentialsNotification: false,
    verifiedCredentialsAnyAdded: false,
  },
  reducers: {
    setIsFetchingCredentials: (state, action) => {
      state.isFetchingCredentials = action.payload;
    },
    setIsFetchingVerifiedCredentials: (state, action) => {
      state.isFetchingVerifiedCredentials = action.payload;
    },
    setIsParsingCredentials: (state, action) => {
      state.isParsingCredentials = action.payload;
    },
    setIsParsingVerifiedCredential: (state, action) => {
      state.isParsingVerifiedCredential = action.payload;
    },
    setIsDisputingCredential: (state, action) => {
      state.isDisputingCredential = action.payload;
    },
    setHasParsedCredentials: (state, action) => {
      state.hasParsedCredentials = action.payload;
    },
    setIsSubmittingDispute: (state, action) => {
      state.isSubmittingDispute = action.payload;
    },
    setIsAddingNewCredential: (state, action) => {
      state.isAddingNewCredential = action.payload;
    },
    updatePublicCredentialStatus: (state, action) => {
      const updatedCredential = state.credentialsList.find(
        (x) => x.id === action.payload?.credential.id,
      );
      updatedCredential.isPublic = action.payload?.isPublic;
    },
    setDisplayedCredentialsNotification: (state, action) => {
      state.displayedCredentialsNotification = action.payload;
    },
  },
  extraReducers: {
    [fetchCredentialsReq.fulfilled]: (state, action) => {
      state.credentialsList = action.payload;
      state.isFetchingCredentials = false;
      state.hasFetchedCredentials = true;
    },
    [fetchCredentialSkillsReq.fulfilled]: (state, action) => {
      let i = state.credentialsList.findIndex((cred) => cred.id === action.payload.id);
      if (i != -1) state.credentialsList[i] = action.payload;
    },
    [submitBadgrLogoutReq.fulfilled]: (state, action) => {
      state.verifiedCredentialsStatus = action.payload.status;
    },
    [fetchCredentialsStatusReq.fulfilled]: (state, action) => {
      let credentialStatuses = action.payload;
      state.credentialsList = state.credentialsList.map((c) => {
        const credentialStatus = credentialStatuses.find((x) => c.id == x.id);
        if (credentialStatus) {
          c.status = credentialStatus.status;
          c.statusReason = credentialStatus.statusReason;
        }
        return c;
      });
    },
    [fetchVerifiedCredentials.fulfilled]: (state, action) => {
      state.verifiedCredentialsList = action.payload?.credentials?.filter(
        (x) =>
          (x?.credentialProvider === 'BADGR' && x?.status === 'VC_ACTIVE') ||
          x?.credentialProvider === 'Parchment',
      );
      state.isFetchingVerifiedCredentials = false;
      state.hasFetchedVerifiedCredentials = true;
    },
    [submitCredentialDispute.fulfilled]: (state) => {
      state.isSubmittingDispute = false;
    },
    [fetchParsedSkillsReq.fulfilled]: (state) => {
      state.isParsingCredentials = false;
      state.hasParsedCredentials = true;
    },
    [fetchParsedSkillsFromJsonReq.fulfilled]: (state) => {
      state.isParsingCredentials = false;
      state.hasParsedCredentials = true;
    },
    [fetchFileParsedSkillsReq.fulfilled]: (state) => {
      state.isParsingCredentials = false;
      state.hasParsedCredentials = true;
    },
    [submitVerifiedCredentialParseReq.fulfilled]: (state, action) => {
      state.isParsingVerifiedCredential = false;
    },
    [submitNewCredential.fulfilled]: (state, action) => {
      state.isAddingNewCredential = false;
      const newCredentials = [...state.credentialsList, action.payload];
      state.credentialsList = newCredentials;
    },
    [confirmVerifiedCredential.fulfilled]: (state, action) => {
      const newCredentials = [...state.credentialsList, action.payload];
      state.credentialsList = newCredentials;
      state.credentialImported = true;
    },
    [submitDeleteCredential.fulfilled]: (state, action) => {
      state.credentialsList = [
        ...state.credentialsList.filter((x) => x.id !== action.meta?.arg?.id),
      ];
    },
    [submitEditCredential.fulfilled]: (state, action) => {
      state.credentialsList = [
        ...state.credentialsList.filter((x) => x.id !== action.payload.id),
        action.payload,
      ];
    },
    [submitDisputeCredentialReq.fulfilled]: (state, action) => {
      state.isDisputingCredential = false;

      // update verifiedCredentialsList item based on achievementId property and save new verifiedCredentialsList
      const updatedVerifiedCredentialsList = state.verifiedCredentialsList.map((credential) => {
        if (credential.achievementId === action.payload?.[0]?.achievementId) {
          return {
            ...credential,
            ...action.payload[0],
          };
        }
        return credential;
      });

      state.verifiedCredentialsList = updatedVerifiedCredentialsList;
    },
    [fetchBadgrCredentials.fulfilled]: (state, action) => {
      state.verifiedCredentialsList = action.payload.data;
      state.verifiedCredentialsStatus = action.payload.status;
      state.isFetchingVerifiedCredentials = false;
      state.hasFetchedVerifiedCredentials = true;
      state.verifiedCredentialsAnyAdded = action.payload?.anyAdded;
    },

    [fetchBadgrCredentials.rejected]: (state, action) => {},
  },
});

export const {
  setIsFetchingCredentials,
  setIsFetchingVerifiedCredentials,
  setIsParsingVerifiedCredential,
  setIsDisputingCredential,
  setIsParsingCredentials,
  setHasParsedCredentials,
  setIsSubmittingDispute,
  setIsAddingNewCredential,
  updatePublicCredentialStatus,
  setDisplayedCredentialsNotification,
} = credentialsSlice.actions;

export default credentialsSlice.reducer;
