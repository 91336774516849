import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/routeNames';
import { ActionCard, EmptyState, LoadingSkeleton } from 'common/components';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAcceptedCredentialsReq } from 'common/store/features/records/recordsSlice';
import { selectCredentialsByStatusAndType } from 'common/store/features/records/selectors';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import GraphicEducation from '@assets/svg/empty-states_education.svg';
import './ProfileLCNEducationSummary.scss';

export const ProfileLCNEducationSummary = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const acceptedDegrees = useSelector((state) =>
    selectCredentialsByStatusAndType(state, 'VC_ACTIVE', 'degree'),
  );

  const isPopulated = acceptedDegrees.length > 0;
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const hasLoaded = !isBootstrapping;

  const onView = () => {
    history.push(ROUTES.MY_RECORDS);
  };

  useEffect(() => {
    dispatch(fetchAcceptedCredentialsReq()); // get accepted LCN credentials
  }, [dispatch]);

  return (
    <>
      <ActionCard
        title="Education"
        icon="educationCircle"
        seeMore={isPopulated ? ROUTES.MY_RECORDS : ''}
        isEmpty={!isPopulated}
      >
        {hasLoaded ? (
          <>
            {!isPopulated && (
              <>
                <img
                  src={GraphicEducation}
                  className="empty-state-graphic"
                  role="presentation"
                  alt="Education Graphic"
                />
                <EmptyState
                  text="Your education is important information to share with an employer."
                  action={onView}
                  actionLabel="View My Records"
                  maxWidth
                />
              </>
            )}
            {isPopulated && (
              <ul className="iq4-profile-education-summary__list">
                {acceptedDegrees.map((item) => (
                  <>
                    <li className="iq4-profile-education-summary__list-item" key={item.id}>
                      <p className="iq4-profile-education-summary__degree">{item.name}</p>
                      <p className="iq4-profile-education-summary__school">{item.issuerName}</p>
                      <hr className="iq4-profile-education-summary__divide" />
                    </li>
                  </>
                ))}
              </ul>
            )}{' '}
          </>
        ) : (
          <div style={{ display: 'block', padding: '10px 0px' }}>
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={24} />
          </div>
        )}
      </ActionCard>
    </>
  );
};
