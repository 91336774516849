import React, { useMemo, useState } from 'react';
import { IconButton } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CredentialSkillSource } from './CredentialSkillSource';

export function CredentialSkill({ skill, displaySkillSources }) {

  if (!skill) {
    return null;
  }

  return (
    <div className="iq4-credential-skill" key={skill?.competenceId}>
      <div
        className="iq4-credential-expanded-item__content"
        style={{ margin: 10, justifySelf: 'left' }}
      >
        {skill?.name}
      </div>
      {displaySkillSources && (
        <>
        <div className="iq4-credential-skill__break" />
        <div>
          {skill?.sources && (
              <div className="iq4-credential-skill__sources-list">
                  {skill?.sources?.map((source) => (
                    <CredentialSkillSource source = {source}/>
                  ))}
              </div>  
          )}
          </div>
          </>
      )}
      
    </div>
  );
}
