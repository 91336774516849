import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import './Uploader.scss';

/**
 * This component uploads a binary file
 * @param handleUpload
 * @param fileTypes
 * @param children
 * @oaran ariaLabel
 * @returns {*}
 * @constructor
 */
export function Uploader({ handleUpload, fileTypes, ariaLabel, children }) {
  const [uploadStatus, setUploadStatus] = useState('');

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setUploadStatus('Uploading file...');
      await handleUpload(acceptedFiles);
      setUploadStatus('File uploaded successfully');
    },
    [handleUpload],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: fileTypes,
  });

  return (
    <>
      <div aria-live="polite" aria-atomic="true" className="sr-only">
        {uploadStatus}
      </div>
      <div
        className="iq4-uploader"
        {...getRootProps()}
        role="button"
        aria-label={ariaLabel || 'Please upload your Resume'}
      >
        <input {...getInputProps()} />
        {children}
      </div>
    </>
  );
}

Uploader.propTypes = {
  handleUpload: PropTypes.func.isRequired,
};
