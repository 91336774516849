import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isSubmittingForm: false,
    isLoadingFilterResults: false,
    skillExpandingId: -1,
    skillExpandedId: -1,
    showJobFamilyExplanation: false,
    showJobRoleExplanation: false,
    displayTour: false,
    displayPathwayDetailTour: false,
    displaySecondPathwayDetailTour: false,
    displayMissingSkillsTour: false,
    isSearchingSkill: false,
    currentTourSteps: [],
    isResumeParserEnabled: false,
    cameFromProfile: false,
  },
  reducers: {
    setIsSubmittingForm: (state, action) => {
      state.isSubmittingForm = action.payload;
    },
    setIsLoadingFilterResults: (state, action) => {
      state.isLoadingFilterResults = action.payload;
    },
    setSkillExpandingId: (state, action) => {
      state.skillExpandingId = action.payload;
    },
    setSkillExpandedId: (state, action) => {
      state.skillExpandedId = action.payload;
    },
    setShowJobFamilyExplanation: (state, action) => {
      state.showJobFamilyExplanation = action.payload;
    },
    setShowJobRoleExplanation: (state, action) => {
      state.showJobRoleExplanation = action.payload;
    },
    setDisplayTour: (state, action) => {
      state.displayTour = action.payload;
    },
    setPathwayDetailTour: (state, action) => {
      state.displayPathwayDetailTour = action.payload;
    },
    setDisplaySecondPathwayDetailTour: (state, action) => {
      state.displaySecondPathwayDetailTour = action.payload;
    },
    setDisplayMissingSkillsTour: (state, action) => {
      state.displayMissingSkillsTour = action.payload;
    },
    setIsSearchingSkill: (state, action) => {
      state.isSearchingSkill = action.payload;
    },
    setCurrentTourSteps: (state, action) => {
      state.currentTourSteps = action.payload;
    },
    setIsResumeParserEnabled: (state, action) => {
      state.isResumeParserEnabled = action.payload;
    },
    setCameFromProfile: (state, action) => {
      state.cameFromProfile = action.payload;
    },
  },
});

// action creators
export const {
  setIsSubmittingForm,
  setIsLoadingFilterResults,
  setSkillExpandingId,
  setSkillExpandedId,
  setNotificationsViewed,
  setShowJobFamilyExplanation,
  setShowJobRoleExplanation,
  setDisplayTour,
  setIsSearchingSkill,
  setCurrentTourSteps,
  setPathwayDetailTour,
  setDisplayMissingSkillsTour,
  setDisplaySecondPathwayDetailTour,
  setIsResumeParserEnabled,
  setCameFromProfile,
} = uiSlice.actions;

export default uiSlice.reducer;
