import React from 'react';
import './CredentialExpandedItem.scss';

export const CredentialExpandedItem = ({ label, content }) => {
  if (!content || content.length === 0) return null;

  return (
    <div className="iq4-credential-expanded-item">
      {label && <div className="iq4-credential-expanded-item__label">{label}</div>}

      {Array.isArray(content) &&
        content.map((item, index) => (
          <div
            className="iq4-credential-expanded-item__content"
            key={`expand-item-content-${index}`}
          >
            {item}
          </div>
        ))}
      {!Array.isArray(content) && (
        <div className="iq4-credential-expanded-item__content">{content}</div>
      )}
    </div>
  );
};
