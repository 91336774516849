import React from 'react';
import { SimpleIcon } from 'common/components';
import './Toast.scss';

export const Toast = ({ title, onClose, children, ariaLabel = 'Close this notification' }) => {
  return (
    <div className="iq4-toast">
      <SimpleIcon
        tabIndex={0}
        aria-label={ariaLabel}
        role="button"
        onKeyPress={onClose}
        onClick={onClose}
        className="iq4-toast--close"
        name="close"
      />
      <div className="iq4-toast--title">{title}</div>
      <div className="iq4-toast--content">{children}</div>
    </div>
  );
};
