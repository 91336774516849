import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components';
import { useFormContext } from 'react-hook-form';
import './Checkbox.scss';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';

export const Checkbox = (props) => {
  const {
    label,
    isChecked,
    handleChange,
    id,
    name,
    validators,
    checkboxClass,
    labelAfter,
    isDisabled = false,
    ariaLabel,
    displayVerified,
  } = props;
  const formContext = useFormContext();
  let register;

  const isWithinForm = formContext !== null;

  if (isWithinForm) {
    register = formContext.register;
  }

  return (
    <label
      className={`iq4-checkbox ${checkboxClass || ''} ${
        isDisabled ? 'iq4-checkbox--disabled' : ''
      }`}
      htmlFor={id || name}
    >
      <input
        ref={isWithinForm ? register({ ...validators }) : null}
        type="checkbox"
        className="iq4-checkbox__input"
        disabled={isDisabled}
        aria-label={ariaLabel || label}
        id={id || name}
        name={name || label}
        checked={isChecked}
        onChange={isWithinForm ? formContext.triggerValidation : handleChange}
      />
      {!labelAfter && label}
      {!displayVerified && (<span className="iq4-checkbox__checkmark">
        <SimpleIcon className="iq4-checkbox__checkmark-icon" name="tick" />
      </span> )}
      {displayVerified && (
        < div className="iq4__skills-verified-icon-container">
          <Verified />
        </div>
      )
      }
      {labelAfter && <span className="iq4-checkbox__label-after">{label}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  handleChange: PropTypes.func,
};

Checkbox.defaultProps = {
  handleChange: null,
};
