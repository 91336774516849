import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setProfileFromSession } from '../profile/profileSlice';
import { CHANGE_PASSWORD_URL, RESET_PASSWORD_URL } from 'common/constants/endpoints';
function checkSessionExpired(state, action) {
  if (action.payload && action.payload.status === 401) {
    state.isSessionExpired = true;
  }
}

function isRejectedAction(action) {
  return action.type.endsWith('rejected');
}

export const fetchIsLoggedInReq = createAsyncThunk(
  'session/fetchIsLoggedInReq',
  async (url, { dispatch }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    const res = await fetch(`${url}${isPublic ? '/?userId=' + userId : ''}`, {
      method: 'GET',
    });

    const response = await res.json();
    dispatch(setProfileFromSession(response));
    return response;
  },
);

export const loginReq = createAsyncThunk(
  'session/loginReq',
  async (params, { rejectWithValue }) => {
    const { loginEndpoint, ...userAndPass } = params;

    const searchParams = Object.keys(userAndPass)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

    return fetch(loginEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: searchParams,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
);

export const changePasswordReq = createAsyncThunk(
  'session/changePasswordReq',
  async (params, { rejectWithValue, dispatch }) => {
    const { currentPassword, password1, password2 } = params;
    dispatch(toggleIsChangingPassword());
    try {
      return fetch(CHANGE_PASSWORD_URL, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentPassword,
          password1,
          password2,
        }),
      }).then(async (response) => {
        dispatch(toggleIsChangingPassword());
        if (response.ok) {
          return response.json();
        } else {
          const resp = await response.json();
          return rejectWithValue(resp);
        }
      });
    } catch (err) {
      dispatch(toggleIsChangingPassword());
      return rejectWithValue(err.response.data);
    }
  },
);

export const resetPasswordReq = createAsyncThunk(
  'session/resetPasswordReq',
  async (username, { rejectWithValue }) => {
    return fetch(`${RESET_PASSWORD_URL}?email=${username}`, {
      method: 'POST',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  },
);

export const validateResetToken = createAsyncThunk(
  'session/validateResetToken',
  async (token, { rejectWithValue }) => {
    return fetch(`${RESET_PASSWORD_URL}?token=${token}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
);

export const submitPasswordResetReq = createAsyncThunk(
  'session/submitPasswordResetReq',
  async ({ token, password1, password2 }, { rejectWithValue, dispatch }) => {
    dispatch(toggleIsResettingPassword());
    return fetch(`${RESET_PASSWORD_URL}/newPassword?token=${token}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password1,
        password2,
      }),
    })
      .then((response) => {
        dispatch(toggleIsResettingPassword());
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        dispatch(toggleIsResettingPassword());
        console.log(error);
      });
  },
);

export const activateCodeReq = createAsyncThunk(
  'session/activateCodeReq',
  async (params, { rejectWithValue }) => {
    const { activateEndpoint, activationCode } = params;

    const searchParams = encodeURIComponent("activationCode") + '=' + encodeURIComponent(activationCode);

    return fetch(activateEndpoint + "?" + searchParams, {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      // },
      // body: searchParams,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
);

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    isBootstrapping: true,
    isLoggingIn: false,
    isLoggedIn: false,
    isSessionExpired: false,
    isResettingPassword: false,
    isChangingPassword: false,
    hasActivatedCode: false,
    hasCodeActivationError: false,
    isActivatingCode: false,
},
  reducers: {
    toggleIsLoggingIn: (state) => {
      state.isLoggingIn = !state.isLoggingIn;
    },
    toggleIsActivatingCode: (state) => {
      state.isActivatingCode = !state.isActivatingCode;
    },
    toggleIsResettingPassword: (state) => {
      state.isResettingPassword = !state.isResettingPassword;
    },
    toggleIsChangingPassword: (state) => {
      state.isChangingPassword = !state.isChangingPassword;
    },
    toggleIsSessionExpired: (state) => {
      state.isSessionExpired = true;
      state.isLoggedIn = false;
    },
    setSessionIsExpired: (state) => {
      state.isSessionExpired = true;
    },
    setIsBootstrapping: (state, action) => {
      state.isBootstrapping = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIsLoggedInReq.fulfilled, (state, action) => {
        if (!Object.keys(action.payload).length || action.payload.status === 401) {
          state.isLoggedIn = false;
        } else {
          state.isLoggedIn = true;
          state.isSessionExpired = false;
          state.user = action.payload;
        }

        state.isBootstrapping = false;
      })
      .addCase(fetchIsLoggedInReq.rejected, (state) => {
        state.isLoggedIn = false;
        state.isBootstrapping = false;
      })
      .addCase(loginReq.fulfilled, (state) => {
        state.isLoggedIn = true;
        state.isLoggingIn = false;
        state.isBootstrapping = false;
        state.hasLoginError = false;
        state.isSessionExpired = false;
      })
      .addCase(loginReq.rejected, (state) => {
        state.hasLoginError = true;
        state.isLoggedIn = false;
        state.isLoggingIn = false;
        state.isBootstrapping = false;
      })
      .addCase(activateCodeReq.fulfilled, (state) => {
        state.hasActivatedCode = true;
        state.hasCodeActivationError = false;
        state.isActivatingCode = false;
      })
      .addCase(activateCodeReq.rejected, (state) => {
        state.hasActivatedCode = false;
        state.hasCodeActivationError = true;
        state.isActivatingCode = false;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        checkSessionExpired(state, action);
      });
  },
});

export const {
  toggleIsLoggingIn,
  toggleIsSessionExpired,
  setSessionIsExpired,
  setIsBootstrapping,
  toggleIsResettingPassword,
  toggleIsChangingPassword,
  toggleIsActivatingCode,
} = sessionSlice.actions;

export default sessionSlice.reducer;
