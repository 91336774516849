import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import {
  ConsentBanner,
  Container,
  Topbar,
  Footer,
  SimpleIcon,
  ProfileHelp,
} from 'common/components';
import {
  fetchWalletProfile,
  updateWalletProfileConsentReq,
} from 'common/store/features/records/recordsSlice';
import { ProfileResumeParse } from 'components/Profile';
import { selectConsentForPlanning } from 'common/store/features/records/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import './ProfilePageLayout.scss';
import { fetchUserSelectedWorkRolesReq } from '../../../common/store/features/pathways/pathwaysSlice';

export const ProfilePageLayout = ({ helmetTitle, children, singleRow }) => {
  const dispatch = useDispatch();

  const [showResumeParser, setShowResumeParser] = useState(false);

  const { consentForPlanningEnabled } = useSelector(selectFeatures);

  const [isConsentDisplayed, setIsConsentDisplayed] = useState(false);

  useEffect(() => {
    consentForPlanningEnabled && dispatch(fetchWalletProfile());
  }, [consentForPlanningEnabled]);

  const consentForPlanning = useSelector(selectConsentForPlanning);

  useEffect(() => {
    setIsConsentDisplayed(consentForPlanningEnabled && consentForPlanning?.status === false);
  }, [consentForPlanningEnabled, consentForPlanning]);

  useEffect(() => {
    dispatch(fetchUserSelectedWorkRolesReq());
  }, []);

  const handleOnAccept = (type) => {
    dispatch(
      updateWalletProfileConsentReq({
        [type]: true,
      }),
    );
  };

  return (
    <>
      <Helmet title={helmetTitle} />
      <Topbar Logo={Logo} onUploadResume={() => setShowResumeParser(true)} />

      {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}

      {isConsentDisplayed && (
        <ConsentBanner
          title="Planning Consent"
          message={consentForPlanning?.message}
          acceptLabel="Accept"
          onAccept={() => handleOnAccept('consentForPlanning')}
        />
      )}
      <main
        className={`iq4-profile-page ${
          isConsentDisplayed ? 'iq4-profile-page__with-consent-banner' : ''
        }`}
        id="main"
      >
        <Container>
          {!singleRow && (
            <Grid container classes={{ root: 'iq4-profile__grid-container' }}>
              <Grid
                item
                md={6}
                classes={{
                  root:
                    'iq4-profile-page-item iq4-profile-page-item--left iq4-profile-page-item--top',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h1 className="iq4-profile-header__text">
                    <span className="iq4-profile-header__label">Profile</span>
                  </h1>
                  <ProfileHelp />
                </div>
              </Grid>
              <Grid
                item
                md={6}
                classes={{
                  root:
                    'iq4-profile-page-item iq4-profile-page-item--right iq4-profile-page-item--top',
                }}
              >
                {children[0]}
              </Grid>
            </Grid>
          )}
          <Grid container>
            {!singleRow && (
              <Grid
                item
                md={3}
                sm={6}
                classes={{ root: 'iq4-profile-page-item iq4-profile-page-item--left' }}
              >
                {children[1]}
              </Grid>
            )}
            <Grid
              item
              md={singleRow ? 12 : 9}
              sm={singleRow ? 12 : 6}
              classes={{
                root: `iq4-profile-page-item iq4-profile-page-item--right ${
                  singleRow ? 'iq4-profile-page-item--mobile' : ''
                }`,
              }}
            >
              {children[2]}
            </Grid>
          </Grid>
        </Container>

        <div className="iq4-profile-page__footer-container">
          <Footer />
        </div>
      </main>
    </>
  );
};
