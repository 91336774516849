import React from 'react';

export const defaultContent = () => (
  <div className="iq4-terms-page--container">
    <div className="iq4-terms-page--content">
      <strong>1. INTRODUCTION.</strong>
      <br />
      <br />
      Being part of iQ4 means sharing information about yourself with iQ4 and others, communicating
      with them, and working privately on your own. By default, your account is set up to share
      information that you and others that register to become an iQ4 user (collectively, “Users” and
      individually a “User”) are interested in sharing. But the amount and type of information you
      decide to share, and with whom you share it, is up to you and/or the manager of a community,
      group and/or project. Here are the three main types of information on iQ4:
      <br />
      <br />
      1. Information about yourself that you share. This is the information that other Users in an
      iQ4 Community, iQ4 Group or iQ4 Project (collectively, the “iQ4 Platform”) sees about you.
      This includes your membership profile, your contributions to discussions, questions and
      collaborations and any photos, videos or other personally identifiable information you post in
      the iQ4 Platform, or other community, group or project features that iQ4 or iQ4 Platform
      managers may offer from time to time. You have control over what you share in a specific iQ4
      Platform, and you can update or delete personal information that you share at any time, but
      depending upon your type of account you may not have control over the sharing of information
      between or among projects, groups or communities, or comments that you may have posted. Where
      you do not have control over the sharing of information, such information sharing control
      resides solely with iQ4 and/or the applicable iQ4 Platform manager.
      <br />
      <br />
      2. Communication. We help you communicate and collaborate with other Users. Some of this is
      one-to-one, some of it is in groups, some of it is in communities, some of it is in projects
      and some of it is public discussion. You decide how much or how little you wish to communicate
      to individuals, communities, groups or projects, but depending upon your type of account you
      may not have control over the sharing of such communications between or among other Users in
      the projects, groups or communities. Where you do not have control over the sharing of
      information, such information sharing control resides solely with iQ4 and/or the applicable
      iQ4 Platform manager.
      <br />
      <br />
      3. Private activity. We also provide tools that you can use privately on the iQ4 Platform (for
      example, searching, stealth, or chat). These actions and information are private and we don’t
      share or distribute them to others on the iQ4 Platform. Of course, maintaining your trust is
      our top concern, so we adhere to the following principles to protect your privacy:
      <br />
      - We do not rent, sell, or otherwise provide your personally identifiable information to third
      parties without your consent, except as described in this policy or as required by law.
      <br />
      - We do not share any information you have not chosen to display on your iQ4 profile to other
      parties, unless required by the applicable iQ4 Platform, compelled by law, or as necessary to
      enforce our User Agreement or protect the rights, property, or personal safety of iQ4, its
      Users, and the public.
      <br />
      - All information that you provide will be protected with industry standard protocols and
      technology. We reserve the right to modify this Privacy Policy at any time, so please review
      it frequently. If we make material changes to this policy, we will notify you here, by means
      of a notice on our home page so that you may access and review the changes to this document in
      advance of your continued use of the service. If you object to any changes, you may cancel
      your membership to any iQ4 Platform in which you are a member. By continuing to use one or
      more iQ4 Platforms after notice of changes has been sent to you or published on the iQ4
      website, you are consenting to the changes. PLEASE READ THIS POLICY and send us questions,
      concerns, and suggestions in accordance with Section 8 below.
      <br />
      <br />
      What iQ4’s Privacy Policy Covers:
      <br />
      <br />
      1. Information collected by iQ4.
      <br />
      2. How iQ4 uses your information.
      <br />
      3. Your information choices.
      <br />
      4. Your obligations as a User.
      <br />
      5. Important information regarding service eligibility.
      <br />
      6. Security.
      <br />
      7. Rights of Users located in the European Union pursuant to GDPR
      <br />
      8. How to contact us.
      <br />
      <br />
      <strong>1. Information Collected</strong>
      <br />
      <br />
      The mission of iQ4 is to provide a platform where people can connect and share thoughts,
      ideas, research, and questions and work collaboratively to achieve a desired result. To
      achieve this mission, iQ4 makes features and functionality available through our website,
      including our mobile and other applications, and other information provided as part of the iQ4
      services (“Services”). By joining iQ4, you voluntarily and willingly provide iQ4 with certain
      information and content, including personally and organizationally identifiable information,
      which we collect in order to provide the Services. If you have any hesitation about providing
      information or content to us and/or having your information or content displayed on the iQ4
      website or otherwise used in any manner permitted in this Privacy Policy and the User
      Agreement, you should not become a member of an iQ4 Platform; and, if you are already a
      member, you should terminate your membership. We collect your personal information in the
      following ways:
      <br />
      <br />
      A. Registration. In order to become a User, you must provide us the following information to
      create an account: name, address, email address, country, school, year of graduation, level of
      education, profession, employer, title, areas of expertise, language abilities, hobbies,
      sports played, credits earned, grade point average, gender, responses to applications to
      become a member of a specific iQ4 Platform, password, and/or other educational and business
      and government employee information. If a payment is required, you must also provide us with
      your credit card or billing information to process the applicable payment. Without this
      minimal amount of information, you cannot create an individual, commercial, and/or government
      iQ4 membership. When establishing passwords, you should choose one that is known only by you,
      and not one that could easily be determined using basic information about you. Some of the
      above-listed information that iQ4 requests from you during the registration process is used by
      iQ4 to provide better, more customized, services and a more valuable iQ4 Community, iQ4 Group,
      and/or iQ4 Project experience. You acknowledge that this information is personal to you, and
      by creating an account on iQ4, you allow others, including iQ4, to identify you and to allow
      iQ4 to use your information in accordance with our User Agreement.
      <br />
      <br />
      B. Customer Service. We collect information when you interact with iQ4’s member service
      personnel and/or websites (if applicable) in order to accurately categorize and respond to
      customer inquiries and investigate breaches of our terms.
      <br />
      <br />
      C. Using the iQ4 Site, Applications, and Services. We receive information when you interact
      with and use the iQ4 website, iQ4 applications (e.g. iQ4 for iPhone, Android, etc.), iQ4
      Platform technology, and/or the Services. For example, we know when you click on areas of
      interest, upload your address book, join iQ4 Groups, participate in polls, install an iQ4
      mobile application, share articles on iQ4, etc.
      <br />
      <br />
      D. Cookies. Like most websites, we use cookies and web log files to track site usage and
      trends, to improve the quality of our service, to customize your experience on iQ4, as well as
      to deliver iQ4 to Users both on and off the iQ4 site. A cookie is a tiny data file that
      resides on your computer, mobile phone, or other device, and allows us to recognize you as a
      User when you return to the iQ4 website using the same computer and web browser. You can
      remove or block cookies using the settings in your browser, but in some cases doing so may
      impact your ability to use iQ4. One type of cookie, called a “session” cookie, is used to
      identify a particular visit to the iQ4 website. Session cookies expire after a short time or
      when you close your web browser. iQ4 may use cookie-equivalent technology for the limited
      purpose of enhancing the security of iQ4’s services. In the course of optimizing the Services
      to our Users, we may allow authorized third parties to place or recognize a unique cookie on
      your browser. Any information provided to third parties through cookies will not be personally
      identifiable but may provide general segment information (e.g., your status as a student at a
      university, your career field, or information about other groups you are a member of on the
      iQ4 Platform) for the enhancement of your user experience. Most browsers are initially set up
      to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a
      cookie is being sent. iQ4 does not store unencrypted personally identifiable information in
      the cookies.
      <br />
      <br />
      E. Log files, IP Addresses, and information about your computer and mobile device. Due to the
      communications standards on the internet, when you visit the iQ4 website we may in the future
      automatically receive the URL of the site from which you came and the site to which you are
      going when you leave iQ4. iQ4 may in the future receive the internet protocol (“IP”) address
      of your computer (or the proxy server you use to access the World Wide Web), your computer
      operating system and type of web browser you are using, email patterns, your mobile device
      (including your UDID) and mobile operating system (if you are accessing iQ4 using a mobile
      device), as well as the name of your ISP or your mobile carrier. iQ4 may also in the future
      receive location data passed to it from third-party services or GPS-enabled devices that you
      have enabled.
      <br />
      <br />
      F. Rights to Access, Correct, and Eliminate Information About You. You have a right to access,
      modify, correct, and eliminate the profile data you supply to iQ4. If you update any of your
      information, we may keep a copy of the information that you originally provided to us in our
      archives for uses documented in this policy. You may request deletion of your information at
      any time by contacting iQ4 in accordance with Section 8. We will respond to your request
      within 30 days. Please note, however, that information you have shared with others, or that
      other Users have copied, through the iQ4 Platform and/or in connection with the Services, may
      have been independently stored or retained by other Users and may also remain visible even if
      you request its deletion.
      <br />
      <br />
      G. Data Retention. iQ4 will retain your personal information for so long as your account is
      active or as needed to provide you Services. If you wish to terminate your membership, you may
      do so by contacting iQ4 in accordance with Section 8. We will retain and use your information
      as necessary to comply with our legal obligations, resolve disputes, and enforce this
      Agreement.
      <br />
      <br />
      H. Data Entry of Upload. You may also choose to manually enter or upload data about you or
      your contacts to the “Contacts” section of your account on iQ4 or any other section of the iQ4
      website. Information entered into Contacts is only viewable by you. By providing email
      addresses or other information of non-Users to iQ4, you represent that you have authority to
      do so. All information that you enter or upload about you or your contacts is covered by the
      User Agreement and this Privacy Policy and will enable us to provide customized services on
      iQ4.
      <br />
      <br />
      <strong>2. Uses of personal information</strong>
      <br />
      <br />
      A. Consent to iQ4 Processing Information About You. The information you provide to iQ4 may
      reveal, or allow others to identify, your nationality, ethnic origin, gender, age, and other
      aspects of your life. By providing information to us for the purposes of registering for an
      iQ4 Platform or adding any additional details to your iQ4 membership profile, you are
      expressly and voluntarily accepting the terms and conditions of this Privacy Policy and iQ4’s
      User Agreement that allow iQ4 to process information about you. Supplying information to iQ4,
      including any information deemed “sensitive” by applicable law, is entirely voluntary on your
      part. You have the right to withdraw your consent to iQ4’s collection and processing of your
      information at any time, in accordance with the terms of this Privacy Policy and the User
      Agreement, by terminating your membership with iQ4, but please note that your withdrawal of
      consent will not be retroactive.
      <br />
      <br />
      B. iQ4 Communications. As described in the User Agreement, we will communicate with you
      through email, notices posted on the iQ4 website, or through other means available through the
      service. We may send you a series of emails that help inform new Users about the features of
      the Services, and we will also send you service messages relating to the functioning of the
      Services. We may also send you messages with promotional information directly or on behalf of
      our partners, unless you have opted out of receiving promotional information. Please be aware
      that you cannot opt-out of receiving service messages from iQ4. Also, if we send
      communications to you via the carrier service with which you have a mobile communications
      subscription or otherwise have access, you understand you will pay any service fees associated
      with your receipt of messages on your mobile device (including text messaging charges).
      <br />
      <br />
      C. User Communications. Many communications you initiate through iQ4 (e.g., an invitation sent
      to a non-User) will list your primary email address and name in the header of the message.
      Messages you initiate may also provide aggregate information about your network (for instance
      how many people are in your network), and may list the iQ4 Groups you are associated with.
      Other communications that you initiate through the iQ4 website, like a request to join an iQ4
      Community, iQ4 Group or iQ4 Project, will list your name as the initiator and may also include
      your personal email address contact information. Your contact information will only be shared
      with another User if both of you have indicated that you would like to establish contact with
      each other.
      <br />
      <br />
      D. Customized Content. We use information you provide to us (or to third parties with whom iQ4
      offers combined services) to customize your experience on our website. For example, when you
      login to your account, we may display customized content based upon information we collect
      about you.
      <br />
      <br />
      E. Sharing Information with Third Parties. iQ4 takes the privacy of our Users very seriously,
      and we do not sell, rent, or otherwise provide your personally identifiable information to
      third parties, except as described in this policy. We will also not share other personal
      information not published to your profile or generated through engagement with other iQ4
      services (e.g., other groups) without your explicit consent or to carry out your instructions
      unless, disclosure is reasonably necessary in our opinion to: (1) comply with legal process,
      including, but not limited to, civil and criminal subpoenas, court orders or other compulsory
      disclosures; (2) enforce this Agreement; (3) respond to claims of a violation of the rights of
      third parties, whether or not the third party is a User, individual, or government agency; (4)
      respond to customer service inquiries; (5) protect the rights, property, or personal safety of
      iQ4, our Users or the public; (6) respond to requests of an employer whose systems are used by
      you to access the Services; (7) comply with the terms of an agreement with, or the direction
      of, an iQ4 Platform manager.
      <br />
      <br />
      F. iQ4 Platform and Services. If you post or import content or information on, or participate
      in, an iQ4 Platform or otherwise utilize the Services, you should be aware that any personally
      identifiable information you choose to provide there can be read, collected, or used by other
      Users, as well as platform developers and other third parties. iQ4 is not responsible for the
      information you choose to submit in these forums. Also, some content in iQ4 Groups may be
      public and searchable on the internet if the iQ4 Platform manager has opened the group for
      public discussions. In most, but not all, instances you can delete your posts and imported
      content or information on the iQ4 Platform at any time but, depending upon your type of
      account, you may not have control over the ability to delete such items. Additionally, please
      note that ideas you post and information you share may be seen and used by other Users, and
      iQ4 cannot guarantee that other Users have not made copies of or use the ideas and information
      that you share with others on iQ4.
      <br />
      <br />
      G. Testimonials and Advertisements. If you provide any testimonials about the iQ4 Services or
      place advertisements, we may post those testimonials and examples of advertisements you place
      in connection with our promotion of these services to third parties, but we will only post
      such testimonials and/or advertisements with your consent. Testimonials and advertisements may
      include your name, logo, and other personal information that you have provided.
      <br />
      <br />
      H. Compliance with Legal Process. It is possible that we may need to disclose personal
      information, profile information, and/or information about your activities as an iQ4 User when
      required by subpoena or other legal process, or if iQ4 has a good faith belief that disclosure
      is necessary to (a) investigate, prevent or take action regarding suspected or actual illegal
      activities or to assist government enforcement agencies; (b) to enforce the User Agreement, to
      investigate and defend ourselves against any third party claims or allegations, or to protect
      the security or integrity of our site; and/or (c) to exercise or protect the rights, property,
      or personal safety of iQ4, our Users, employees, or others.
      <br />
      <br />
      I. Disclosures to Others as the Result of a Change in Control or Sale of iQ4. We may also
      disclose your personal information and other information you provide to another third party as
      part of a sale of the assets of iQ4 Corporation, a subsidiary or division, or as the result of
      a change in control of the company. Any third party to which iQ4 transfers or sells iQ4’s
      assets will have the right to continue to use the personal and other information that you
      provide to us.
      <br />
      <br />
      <strong>3. Your Information Choices</strong>
      <br />
      <br />
      A. Accessing and Changing Your Membership Information. You can review the personal information
      you provided to us by contacting us in accordance with Section 8. You can make any desired
      changes to the information you publish at any time by logging in to the iQ4 website and
      revising such published information. Please be aware that even after your request for a change
      is processed, iQ4 may, for a time, retain residual information about you in its backup and/or
      archival copies of its database. iQ4 membership profiles are also defaulted to allow Users to
      be contacted to participate in polls and surveys. Please contact us in accordance with Section
      8 to change these settings if permitted by the applicable iQ4 Platform in which you are a
      member.
      <br />
      <br />
      B. Terminating Your Membership. You can terminate your membership through the iQ4 customer
      service contact in Section 8. If you terminate your iQ4 membership, we will remove your name
      and other personally identifiable information from our database viewable by all Users of the
      iQ4 Platform. If you terminate your membership, we have no obligation to retain your
      information, and may delete any or all of your membership information without liability.
      However, we may retain certain data contributed by you if iQ4 believes it may be necessary to
      prevent fraud or future abuse, or for legitimate business purposes, such as analysis of
      aggregated, non-personally identifiable data, account recovery, or if required by law. iQ4 may
      also retain and use your information if necessary to provide the Services to other Users. For
      example, just as an email you may send to another person through an email service provider
      resides in that person’s inbox even after you delete it from your sent files or terminate your
      membership, your contributions to iQ4 Platforms, may remain visible to others after you have
      terminated your membership. Similarly, other information you have shared with others, or that
      other Users have copied, may also remain visible. iQ4 disclaims any liability in relation to
      the deletion or retention (subject to the terms herein) of
      <br />
      <br />
      C. Memorializing Memberships. If we learn that a User is deceased, we may memorialize the
      User’s membership. In these cases we may restrict profile access, remove messaging
      functionality, and terminate such membership if we receive a formal request from the User’s
      next of kin or other proper legal request to do so.
      <br />
      <br />
      <strong>4. Your Obligations</strong>
      As a User, you have certain obligations to other Users. Some of these obligations are imposed
      by applicable law and regulations, and others have become commonplace in user-friendly
      communities of like-minded members such as iQ4:
      <br />
      - You must, at all times, abide by the terms and conditions of the then-current Privacy Policy
      and User Agreement. This includes respecting all intellectual property rights that may belong
      to third parties (such as trademarks, copyrights, patents, and trade secrets).
      <br />
      - You must not download or otherwise disseminate any information that may be deemed to be
      injurious, violent, offensive, obscene, racist, or xenophobic, or which may otherwise violate
      the purpose and spirit of iQ4 and its communities of Users.
      <br />
      - You must not provide to iQ4 and/or other Users information that you believe might be
      injurious or detrimental to your person or to your professional or social status.
      <br />
      - You must keep your username and password confidential and not share it with others. Any
      violation of these guidelines may lead to the restriction, suspension, or termination of your
      account at the sole discretion of iQ4.
      <br />
      <br />
      <strong>5. Important information</strong>
      <br />
      <br />
      A. Scope. This Privacy Policy covers the iQ4 web site, the Services, and the iQ4 Platform. It
      does not however, apply to entities that iQ4 does not own or control, such as third party
      applications and websites using the iQ4 Platform. By using or accessing the iQ4 website and/or
      Services, you agree to our privacy practices outlined in this Privacy Policy.
      <br />
      <br />
      B. Children and Minors. Children under the age of 13 are not eligible to use our Services and
      we do not knowingly collect personal information from children under such age. If we learn
      that we have collected personal information on a child under 13, we will delete that data from
      our systems. iQ4 encourages parents and guardians of minors 13 years and older to go online
      with their children and that parents provide their consents before the minor sends any
      information about themselves to anyone over the internet.
      <br />
      <br />
      C. Changes to this Privacy Policy. We may update this Privacy Policy at any time, with or
      without advance notice. In the event there are significant changes in the way we treat your
      personally identifiable information, or in the Privacy Policy document itself, we will display
      a notice on the iQ4 website, iQ4 Platform or send you an email, so that you may review the
      changed terms prior to continuing to use the site. As always, if you object to any of the
      changes to our terms, and you no longer wish to use iQ4, you may terminate your membership
      with iQ4. Unless stated otherwise, our current Privacy Policy applies to all information that
      iQ4 has about you and your membership. Using the iQ4 Platform after a notice of changes has
      been sent to you or published on our site shall constitute consent to the changed terms or
      practices.
      <br />
      <br />
      D. Conflict with other iQ4 Agreements. This Privacy Policy is intended to control with respect
      to conflicts between it and the User Agreement with respect to the subject matter hereof. This
      Privacy Policy may be further supplemented with additional terms by a separate written
      agreement entered into by and between iQ4 and a User. In the event of a conflict between this
      Privacy Policy and such separate agreement, the terms of the separate agreement shall control.
      <br />
      <br />
      <strong>6. Security</strong>
      <br />
      <br />
      In order to help secure your personal information, access to your data on iQ4 is
      password-protected, and sensitive data (such as credit card information) is protected by SSL
      encryption when it is exchanged between your web browser and the iQ4 website. To protect any
      data you store on our servers or servers of iQ4’s service providers, iQ4 does not audit its
      system or require its service providers to audit their systems for possible vulnerabilities
      and attacks. We also back-up, and require our service providers, to back-up any data and
      information. However, since the internet is not a 100% secure environment, we cannot ensure or
      warrant the security of any information you transmit to iQ4. There is no guarantee that
      information may not be accessed, disclosed, altered, or destroyed by breach of any of our
      physical, technical, or managerial safeguards. It is your responsibility to protect the
      security of your login information. Please note that emails, instant messaging, chat rooms,
      bulletin boards, and similar means of communication with other Users of iQ4 are not encrypted,
      and we strongly advise you not to communicate any confidential information through these
      means.
      <br />
      <br />
      <strong>7. Rights of Users located in the European Union</strong>
      Notwithstanding any provision to the contrary in this Privacy Policy, under Chapter 3 of the
      General Data Protection Regulation 2016/679 (“GDPR”), Users in the European Union are
      guaranteed the following eight (8) rights:
      <br />
      (1) The right to be informed; This means anyone processing your personal data must make clear
      what they are processing, why, and who else the data may be passed to.
      <br />
      (2) The right of access; this is your right to see what data is held about you by a Data
      Controller. The Data Controller is iQ4 Corporation.
      <br />
      (3) The right to rectification; the right to have your data corrected or amended if what is
      held is incorrect in some way.
      <br />
      (4) The right to erasure; under certain circumstances you can ask for your personal data to be
      deleted. This is also called the Right to be Forgotten. This would apply if the personal data
      is no longer required for the purposes it was collected for, or your consent for the
      processing of that data has been withdrawn, or the personal data has been unlawfully
      processed.
      <br />
      (5) The right to restrict processing; this gives the User the right to ask for a temporary
      halt to processing of personal data, such as in the case where a dispute or legal case has to
      be concluded, or the data is being corrected.
      <br />
      (6) The right to data portability; a User has the right to ask for any data supplied directly
      to the Data Controller by him or her, to be provided in a structured, commonly used, and
      machine-readable format.
      <br />
      (7) The right to object; the User has the right to object to further processing of their data
      which is inconsistent with the primary purpose for which it was collected, including
      profiling, automation, and direct marketing.
      <br />
      (8) Rights in relation to automated decision making and profiling; Users have the right not to
      be subject to a decision based solely on automated processing.
      <br />
      <br />
      <strong>8. How to contact us</strong>
      <br />
      <br />
      If you have questions or comments about this Privacy Policy, please email our Data Protection
      Officer at info@iQ4.com or contact us at:
      <br />
      <br />
      iQ4 Corporation
      <br />
      c/o Dilworth Paxson LLP
      <br />
      Attn: John A. Squires
      <br />
      99 Park Avenue | Suite 320 | New York, NY 10016
      <br />
    </div>
  </div>
);
