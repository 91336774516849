import { setSessionIsExpired } from 'common/store/features/session/sessionSlice';

/**
 * Session Timeout Middleware - responsible for triggering the Session Timeout Modal
 * @param store
 * @returns {function(*): function(*=): *}
 */
export const sessionTimeoutMiddleware = (store) => (next) => (action) => {
  if (action && action.payload && action.payload.status === 401) {
    store.dispatch(setSessionIsExpired());
  }
  return next(action);
};
