import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Uploader } from 'common/components/Uploader';
import { Button } from 'common/components';
import { ReactComponent as UploaderIcon } from 'common/assets/svg/uploader.svg';
import { setResumeUploaded } from 'common/store/features/onboarding/onboardingSlice';
import { parseResumeReq } from 'common/store/features/parsedResume/parsedResumeSlice';
import { updateHasOnboardedReq } from 'common/store/features/profile/profileSlice';
import { selectProfile } from 'common/store/features/profile/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import Joyride from 'react-joyride';
import './OnboardingResume.scss';
import { selectDisplayTour } from 'common/store/features/ui/selectors';
import { setDisplayTour, setPathwayDetailTour } from '../../../store/features/ui/uiSlice';

const showTutorial = process.env.REACT_APP_SHOW_TUTORIAL === 'true';

export const OnboardingResume = ({
  title,
  displayLinks = false,
  onComplete = () => {},
  disableTourOnLoad = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { personal } = useSelector(selectProfile);
  const { defaultRoute } = useSelector(selectFeatures);
  const displayTour = useSelector(selectDisplayTour);

  useEffect(() => {
    if (!disableTourOnLoad) {
      dispatch(setDisplayTour(true));
    }
  }, [disableTourOnLoad]);

  const steps = [
    {
      target: '.iq4-uploader',
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Upload your resume</h2>
          <p>
            Upload your resume for a speedy profile setup! Your resume holds valuable details about
            your experience and skills, making it a quick and easy way to showcase your strengths to
            potential employers. <br />
            <br />
          </p>
        </div>
      ),
      spotlightClicks: true,
      disableBeacon: true,
      hideSkipButton: true,
    },
    {
      disableBeacon: true,
      disableOverlayClose: true,
      hideCloseButton: true,
      target: '.iq4-30-onboarding-uploader__button',
      content:
        "Don't worry if you don't have a resume ready, you can create your profile manually on the next step.",
    },
  ];

  const handleOnUpload = async (attachment) => {
    let formData = new FormData();
    formData.append('resume', attachment[0]);
    setHasError(false);
    // set the loader active while uploading the resume
    setIsLoading(true);

    // parse resume and get relevant skills
    const uploadResponse = await dispatch(parseResumeReq(formData));

    if (!!uploadResponse.payload) {
      // hide the loader
      setIsLoading(false);
      dispatch(setResumeUploaded(true));
      onComplete();
    } else {
      // hide the loader
      setIsLoading(false);
      setHasError(true);
    }
  };

  const UploadContainer = () => (
    <>
      <UploaderIcon role="presentation" aria-hidden="true" />
      <p className="iq4-30-onboarding-uploader__info-text">
        <span className="iq4-30-onboarding-uploader__info-text--strong">
          Upload your resume <br />
        </span>
      </p>
    </>
  );

  const LoadingContainer = () => (
    <div className="iq4-30-onboarding-uploader__loading-container">
      <CircularProgress
        size={100}
        classes={{ root: 'iq4-30-onboarding-uploader__circular-progress' }}
      />
    </div>
  );

  function handleCreateProfile() {
    history.push(ROUTES.ONBOARDING_PERSONAL);
  }

  function handleWelcomeTourCallback(data) {
    if (data.origin === 'button_close') {
      dispatch(setDisplayTour(false));
    }
  }

  return (
    <div className="iq4-30-onboarding-uploader__container">
      <div className="iq4-30-onboarding-uploader__content">
        <h2 className="iq4-30-onboarding-uploader__title">{title}</h2>
        {displayTour && displayLinks && (
          <Joyride
            disableBeacon={true}
            disableOverlay={true}
            steps={steps}
            callback={handleWelcomeTourCallback}
            continuous={true}
            showProgress={true}
            run={displayTour}
            disableOverlayClose={true}
            styles={{
              buttonBack: {
                backgroundColor: 'transparent',
                color: '#0070F0',
              },
              buttonNext: {
                backgroundColor: '#0070F0',
                color: 'white',
              },
            }}
            locale={{
              last: 'Exit Tour', // Add this line
            }}
          />
        )}

        <Uploader handleUpload={handleOnUpload}>
          <div
            className={`iq4-30-onboarding-uploader ${
              isLoading ? '' : 'iq4-30-onboarding-uploader__hover-container'
            }`}
          >
            {!isLoading && <UploadContainer />}
            {isLoading && <LoadingContainer />}
            {hasError && (
              <p style={{ color: 'red' }}>
                There was an error uploading your resume. Please try again.
              </p>
            )}
          </div>
        </Uploader>
        <p>
          <ol style={{ marginBottom: '2rem', textAlign: 'center' }}>
            Streamline your profile setup by uploading documents in our supported file types:
            <li>1. PDF</li>
            <li>2. DOC/DOCX</li>
            <li>3. TXT</li>
          </ol>
        </p>

        {displayLinks && (
          <>
            <p className="iq4-30-onboarding-uploader__alternative-text">
              No resume ready? No problem, create it manually!
            </p>
            <p className="iq4-30-onboarding-uploader__alternative-button">
              <Button
                aria-label="Manually create your profile, step by step. It will load a new page."
                className="iq4-30-onboarding-uploader__button"
                onClick={handleCreateProfile}
                onKeyPress={handleCreateProfile}
              >
                Create Profile
              </Button>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
