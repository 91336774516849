import React from 'react';
import Rating from '@material-ui/lab/Rating';
import { SKILL_MAX_RATING, RATING_STARS_SIZE } from 'common/constants/miscellaneous';
import './RatingStars.scss';

/**
 * Material UI Rating wrapper
 * @param value
 * @param onValueSelect
 * @param isSelf
 * @param max
 * @param props
 * @returns {*}
 * @constructor
 */
export const RatingStars = ({
  value,
  onValueSelect,
  isSelf = false,
  max = SKILL_MAX_RATING,
  size = RATING_STARS_SIZE.LARGE,
  ...props
}) => {
  return (
    <Rating
      max={max}
      classes={{
        root: 'iq4-rating',
        iconFilled: isSelf
          ? 'iq4-rating-star__filled--self'
          : 'iq4-rating-star__filled--third-party',
        iconEmpty: isSelf ? 'iq4-rating-star__empty--self' : 'iq4-rating-star__empty--third-party',
      }}
      defaultValue={value}
      value={value}
      onChange={onValueSelect}
      size={size}
      {...props}
    />
  );
};
