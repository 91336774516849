import { createSlice } from '@reduxjs/toolkit';

const STATIC_ERROR_MESSAGE = 'An error has occurred. Please try again or come back later.';
export const errorsSlice = createSlice({
  name: 'errors',

  initialState: {
    hasError: false,
    message: null,
    canDismiss: null,
  },

  reducers: {
    setError: (state, { hasError = true, message = STATIC_ERROR_MESSAGE, canDismiss = true }) => {
      return {
        hasError,
        message,
        canDismiss,
      };
    },

    dismissError: () => {
      return setDefaultState();
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('rejected'),
      (state, action) => {
        if (
          action.payload &&
          action.payload.status >= 500 &&
          action?.type?.indexOf('fetchWorkRoleDemographicsByLocationReq') === -1
        ) {
          return setDefaultErrorState(
            action?.payload?.msg ? action?.payload?.msg : STATIC_ERROR_MESSAGE,
          );
        }
      },
    );
  },
});

// action creators
export const { setError, dismissError } = errorsSlice.actions;

export default errorsSlice.reducer;

function setDefaultErrorState(msg = STATIC_ERROR_MESSAGE) {
  return {
    hasError: true,
    message: msg,
    canDismiss: true,
  };
}

function setDefaultState() {
  return {
    hasError: false,
    message: null,
    canDismiss: null,
  };
}
