import React, { useState } from 'react';
import { MultilineDisplayer, SimpleIcon } from '../../common/components';
import moment from 'moment';
import { STUDENT_INVITE_STATUS, STUDENT_INVITE_STATUS_LABEL } from '../../common/utils';
import CircularProgress from '@material-ui/core/CircularProgress';

export function ConnectionRow({ item, onAccept, onDecline, isLoading = false }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={`${isExpanded ? 'iq4-connection-row-expanded' : ''} iq4-connection-row`}>
      <SimpleIcon
        className="iq4-connection-row--expander"
        onClick={() => setIsExpanded(!isExpanded)}
        name={isExpanded ? 'caretUp' : 'caretDown'}
      />
      <div className="iq4-connection-row--container">
        <div className="iq4-connection-row--avatar-container iq4-connections-page--pre-expand--flex-3">
          <span className="iq4-connection-row--avatar-container--avatar">
            <SimpleIcon name="student" />
          </span>
          <span className="iq4-connection-row--avatar-container--label">
            {item.counsellorFullName}
          </span>
        </div>
        <div className="iq4-connections-page--pre-expand--flex-2">
          {moment(new Date(item.dateCreated)).format('MM/DD/YYYY')}
        </div>
        <div className="iq4-connections-page--pre-expand--flex-2">
          <div className="iq4-connection-row--status-new">
            {STUDENT_INVITE_STATUS_LABEL[STUDENT_INVITE_STATUS[item.status]]}
          </div>
        </div>
        <div className="iq4-connections-page--pre-expand--flex-2">{item.school}</div>
        <div className="iq4-connections-page--pre-expand--flex-3-center">
          <div className="iq4-connection-row--actions">
            {!isLoading && (
              <>
                <div
                  className="iq4-connection-row--actions--decline"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => onDecline(item)}
                  onClick={() => onDecline(item)}
                >
                  DECLINE
                </div>
                <div
                  className="iq4-connection-row--actions--accept"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => onAccept(item)}
                  onClick={() => onAccept(item)}
                >
                  SHARE
                </div>
              </>
            )}
            {isLoading && <CircularProgress size="auto" style={{ marginRight: 16 }} />}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="iq4-connection-row--message-container">
          <div className="iq4-connection-row--message-container--title">Invitation Message:</div>
          <div className="iq4-connection-row--message-container--content">
            <MultilineDisplayer text={item.msg} />
          </div>
        </div>
      )}
    </div>
  );
}
