import React from 'react';
import { LearningResourcesCard } from 'common/components';
import './SkillLearningResources.scss';

export const SkillLearningResources = ({ skill }) => {
  const { achievements } = skill;

  return (
    <div className="iq4-skill-learning-resources">
      <div className="iq4-skill-learning-resources--info">
        Below, you will find a diverse array of learning resources aimed at facilitating a higher
        proficiency for this skill:
      </div>
      <ul className="iq4-skills-source__list">
        {achievements &&
          achievements.map(function (achievement, i) {
            return <LearningResourcesCard achievement={achievement} />;
          })}
      </ul>
    </div>
  );
};
