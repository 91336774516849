import React from 'react';
import './PathwaysPanelTitle.scss';

/**
 * Pathways panel title compoenent
 * @param {*} title
 * @param {*} [subTitle]
 * @param {string} [className] - custom className
 */
export const PathwaysPanelTitle = ({ title, subTitle, className, ...props }) => {
  return (
    <div className={`iq4-pathways-panel-title ${className ? className : ''}`} {...props}>
      <div className="iq4-pathways-panel-title__value">{title}</div>
      {subTitle && <div className="iq4-pathways-panel-title__value-subtitle">{subTitle}</div>}
    </div>
  );
};
