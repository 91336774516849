import React from 'react';
import { SimpleIcon } from 'common/components';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '@assets/svg/footer-logo.svg';
import { ReactComponent as LogoDark } from '@assets/svg/logo.svg';
import { ReactComponent as IQ4LogoWhiteLCN } from '@assets/svg/iq4-footer-logo-white-lcn.svg';
import { ReactComponent as NSCSTLogo } from '@assets/svg/nsc-secure-trust.svg';
import { ReactComponent as NSCSTPLogo } from '@assets/svg/nsc-student-data-principles.svg';
import { ReactComponent as NSCSPPLogo } from '@assets/svg/nsc-student-privacy-pledge.svg';

import { selectFeatures } from 'store/features/config/selectors';

import './Footer.scss';

const date = new Date().getFullYear();

const organizationUrl = process.env.REACT_APP_ORGANIZATION_URL;
const organizationName = process.env.REACT_APP_ORGANIZATION_LABEL;

const footerItems = [
  {
    url: process.env.REACT_APP_FOOTER_ITEM_1_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_1_LABEL,
  },
  {
    url: process.env.REACT_APP_FOOTER_ITEM_2_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_2_LABEL,
  },
  {
    url: process.env.REACT_APP_FOOTER_ITEM_3_URL,
    label: process.env.REACT_APP_FOOTER_ITEM_3_LABEL,
  },
];

const DefaultFooter = () => (
  <div className="footer-container">
    <div className="footer">
      <div className="iq4-footer-links no-print">
        <div className="item logo">
          <a href={organizationUrl} target="_blank" rel="noopener noreferrer">
            <Logo className="iq4-nsc-logo" />
          </a>
        </div>
        <div className="item copyright">
          &copy; {`${date} ${organizationName}. All Rights Reserved.`}
        </div>
        <div className="item footer-links">
          {footerItems.map((item, index) => (
            <div key={`footer-item-${index}`}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                {item.label}
                <span className="sr-only">This will open in a new tab.</span>
              </a>
              {index < footerItems.length - 1 && <span className="divider">|</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

const MiniFooter = () => {
  return (
    <footer className="iq4-mini-footer">
      <LogoDark role="presentation" aria-hidden="true" className="iq4-mini-footer__logo" />
      <p className="iq4-mini-footer__copy">&copy; {date} iQ4. All Rights Reserved</p>
    </footer>
  );
};

const MinimalFooter = ({ className, ...props }) => {
  return (
    <footer
      className={`
      iq4-footer ${className ? className : ''}
    `}
      {...props}
    >
      <p className="iq4-footer__text">&copy; 2020 iQ4</p>
    </footer>
  );
};

const LCNFooter = ({ className, hasIQ4Logo = true, ...props }) => {
  return (
    <footer className="iq4-footer-lcn" {...props}>
      <div className="iq4-footer-lcn__section iq4-footer-lcn__section--logos">
        <a
          className="iq4-footer-lcn__logo-link"
          href={organizationUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Logo className="iq4-footer-lcn__logo iq4-footer-lcn__logo--lcn" />
        </a>

        {hasIQ4Logo && (
          <a className="iq4-footer-lcn__logo-link" href="/" rel="noopener noreferrer">
            <IQ4LogoWhiteLCN className="iq4-footer-lcn__logo iq4-footer-lcn__logo--iq4" />
          </a>
        )}
      </div>

      <div className="iq4-footer-lcn__section iq4-footer-lcn__section--links">
        <div className="iq4-footer-lcn__links">
          {footerItems.map((item, index) => (
            <div className="iq4-footer-lcn__link-container" key={`footer-item-${index}`}>
              <a
                className="iq4-footer-lcn__link"
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`See more details (opens in a new tab).`}
              >
                {item.label}
                <SimpleIcon className="iq4-footer-lcn__link-icon" name="openLink" />
                <span className="sr-only">This will open in a new tab.</span>
              </a>
            </div>
          ))}
        </div>

        <p className="iq4-footer-lcn__address">
          2300 Dulles Station Blvd, Suite 220, Herndon, VA 20171
        </p>
      </div>

      <div className="iq4-footer-lcn__section iq4-footer-lcn__section--badges">
        <div className="iq4-footer-lcn__badges">
          <NSCSTLogo />
          <NSCSTPLogo />
          <NSCSPPLogo />
        </div>
        <p className="iq4-footer-lcn__copyright">
          &copy; {`${date} ${organizationName}. All Rights Reserved.`}
        </p>
      </div>
    </footer>
  );
};

const FOOTER_TYPES = {
  DEFAULT: DefaultFooter,
  MINI: MiniFooter,
  MINIMAL: MinimalFooter,
  LCN: LCNFooter,
  LCN_NO_IQ4_LOGO: () => <LCNFooter hasIQ4Logo={false} />,
};
export const Footer = (props) => {
  const { footerType } = useSelector(selectFeatures);

  if (!footerType) return null;

  const FooterComp = FOOTER_TYPES[footerType];

  return <FooterComp {...props} />;
};
