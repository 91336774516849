import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Select, FormControl, MenuItem, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import _orderBy from 'lodash/orderBy';
import './BrowseRolesInput.scss';
import {
  setShowJobFamilyExplanation,
  setShowJobRoleExplanation,
} from '../../../../store/features/ui/uiSlice';
import { selectShowJobFamilyExplanation } from '../../../../store/features/ui/selectors';
import { SimpleIcon } from '../../../SimpleIcon';

export const BrowseRolesInput = ({ roles = [], onSelect, selectedJobFamily }) => {
  const dispatch = useDispatch();
  const showJobFamilyExplanation = useSelector(selectShowJobFamilyExplanation);

  const onSelectRole = (_, opt) => {
    if (!opt) {
      return;
    }
    const role = roles.find((item) => item.id === opt.id);
    onSelect({
      id: role.id,
      label: role.label,
    });
  };

  function showJobFamilyExplanationFunc() {
    dispatch(setShowJobFamilyExplanation(!showJobFamilyExplanation));
    dispatch(setShowJobRoleExplanation(false));
  }

  return (
    <div className="iq4-select__wrapper iq4-categories">
      <div className="iq4-select-label__wrapper">
        <label htmlFor="jobsFamilySearch" className="iq4-categories__label">
          Browse Jobs By job family
        </label>
        <div
          id="jobsFamilySearch"
          className="iq4-categories__helper"
          role="button"
          aria-label="What is a job family?"
          tabIndex={0}
          onClick={showJobFamilyExplanationFunc}
          onKeyPress={showJobFamilyExplanationFunc}
        >
          <SimpleIcon
            name="infoDefault"
            className="iq4-skill-card__proficiency--info"
            role="presentation"
          />
        </div>
      </div>
      <FormControl
        variant="outlined"
        className="iq4-select__form-control iq4-select__form-control-max-width"
      >
        <Autocomplete
          disablePortal
          options={_orderBy(roles, 'label', 'asc')}
          onChange={onSelectRole}
          popupIcon={<KeyboardArrowDownIcon />}
          classes={{
            input: 'iq4-select__input',
            root: 'iq4-search-roles__autocomplete',
          }}
          fullWidth
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              InputProps={{
                'aria-label': 'Search for roles. E.g. "Programmers"',
              }}
              {...params}
              variant="outlined"
              placeholder="Select Job Family. E.g. 'Programmers'"
            />
          )}
        />
      </FormControl>
    </div>
  );
};
