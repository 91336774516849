import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import {
  Topbar,
  Container,
  Footer,
  SimpleIcon,
  Pill,
  Button,
  PrivatePublicToggle,
  Modal,
  ExternalLinkInterstitial,
  InnerTabs,
  Dropdown,
  AssessmentStars,
  Tooltip,
  AssessmentInfoPanel,
  FormWrapper,
} from 'common/components';
import {
  CredentialExpanded,
  CredentialExpandedItem,
  CredentialExpandedAlignments,
} from 'common/components/Credentials';
import { RecordImageAndLabel, MyRecordsLoadingSkeleton } from 'components/MyRecords/common';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CredentialRow } from './components/CredentialRow';
import ReactJson from 'react-json-view';
import {
  fetchCredentialsReq,
  fetchCredentialsStatusReq,
  submitCredentialDispute,
  setIsParsingCredentials,
  setHasParsedCredentials,
  submitNewCredential,
  setIsAddingNewCredential,
  submitDeleteCredential,
  fetchBadgrCredentials,
  fetchFileParsedSkillsReq,
  submitEditCredential,
  submitBadgrLogoutReq,
  fetchParsedSkillsReq,
  fetchParsedSkillsFromJsonReq,
  submitDisputeCredentialReq,
} from 'common/store/features/credentials/credentialsSlice';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';

import {
  selectInnerCurrentCredentials,
  selectHasFetchedCredentials,
  selectHasParsedCredentials,
  selectIsFetchingCredentials,
  selectIsFetchingVerifiedCredentials,
  selectIsParsingCredentials,
  selectIsSubmittingDispute,
  selectNewCredentials,
  selectIsAddingNewCredential,
  selectVerifiedCredentialsStatus,
  selectHasFetchedVerifiedCredentials,
  selectVerifiedCredentials,
  selectVerifiedCredentialsAnyAdded,
} from 'common/store/features/credentials/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { LCN_CREDENTIAL_PROVIDERS } from 'common/constants/lcnRecords';
import { getBase64ImageUrl, isValidUrl } from 'common/utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as Tick } from 'common/assets/svg/tick.svg';
import { ReactComponent as UploaderIcon } from 'common/assets/svg/uploader.svg';
import { ReactComponent as EmptyStateImage } from '@assets/svg/empty-states_skills.svg';
import './CredentialsPage.scss';
import { IconButton, InputLabel } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { INTERSTITIAL_COPY } from 'common/constants/interstitialCopy';
import { selectUserProfile } from 'common/store/features/profile/selectors';
import { ReactComponent as EmptyStateIcon } from 'common/assets/svg/icon_education-generic.svg';
import { Uploader } from 'common/components/Uploader';
import { orderBy } from 'lodash';
import { AccessibleSelect, AutoComplete, Checkbox } from 'common/components/Form/fieldTypes';
import { SELECT_MONTHS, generateYearRange, MONTHS } from 'common/constants/dates';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { VerifiedCredentialRow } from './components/VerifiedCredentialRow';
import { BADGR_AUTH_URL } from 'common/constants/endpoints';
import { setError } from 'common/store/features/errors/errorsSlice';
import { CredentialsBanner } from './components/CredentialsBanner';
import { CredentialDisputeModal } from './components/CredentialDisputeModal';
import { fetchAllSkillsReq } from '../../common/store/features/skills/skillsSlice';
import { fetchUserSelectedWorkRolesReq } from '../../common/store/features/pathways/pathwaysSlice';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { THUMBNAIL_MIME_TYPES } from '../../common/constants/mimeTypes';
import IQ4Tour from '../../common/components/IQ4Tour/IQ4Tour';
import {
  selectCurrentTourSteps,
  selectDisplayTour,
} from '../../common/store/features/ui/selectors';
import { getCredentialTourSteps } from '../../common/components/Topbar/tourSteps';
import { setCurrentTourSteps, setDisplayTour } from '../../common/store/features/ui/uiSlice';
import Joyride, { STATUS } from 'react-joyride';

const yearValues = generateYearRange(1940, new Date().getFullYear());
const showCredentialsBanner = process.env.REACT_APP_SHOW_CREDENTIALS_BANNER;

const exampleCredential = {
  isDisputed: false,
  resources: null,
  learnMoreUrl: null,
  achievementType: 'Assertion',
  credentialProvider: null,
  id: null,
  code: null,
  isLearningOpportunity: null,
  issuerUrl: 'https://api.test.badgr.com/public/issuers/WsDauccISgmGBqgXVMHBcw',
  credentialProviderUrl: null,
  statusReason: null,
  sources: null,
  issuerId: null,
  roles: null,
  isPublic: null,
  text: null,
  startMonth: null,
  criteria: null,
  duration: null,
  isVerified: null,
  issuerName: 'Test Academic Issuer',
  issuedOn: '2023-01-11T15:00:00Z',
  durationUnit: null,
  startDay: null,
  startYear: null,
  parseVersion: null,
  name: 'This is a test credential!',
  school: null,
  imageUrl: 'https://api.badgr.io/public/assertions/WUlx1NAsShiLn-1ZNIjBLA/image',
  skills: null,
  achievementId: 'https://api.test.badgr.com/public/assertions/xcpPYKLnTtq7ZMb8h-z71A',
  competences: null,
  status: 'Active',
  isExternal: false,
  achievementUrl: 'https://api.test.badgr.com/public/assertions/xcpPYKLnTtq7ZMb8h-z71A',
  issuerEmail: 'brent.capriotti@wgu.edu',
  issuerImageUrl: null,
  description:
    'Marketing Analytics Specialization from Western Governors University. The Marketing Analytics Specialization examines e-commerce, marketing analytics, digital marketing analytics, and search engine optimization (SEO). Covered skills include data visualization, marketing planning, media strategy, search engine marketing, strategic thinking, and more.',
  expirationDate: null,
};

export const CredentialsPage = () => {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoints();

  const isFetchingCredentials = useSelector(selectIsFetchingCredentials);
  const isFetchingVerifiedCredentials = useSelector(selectIsFetchingVerifiedCredentials);
  const isParsingCredentials = useSelector(selectIsParsingCredentials);
  const hasFetchedCredentials = useSelector(selectHasFetchedCredentials);
  const hasParsedCredentials = useSelector(selectHasParsedCredentials);
  const hasFetchedVerifiedCredentials = useSelector(selectHasFetchedVerifiedCredentials);
  const credentialsList = useSelector(selectInnerCurrentCredentials);
  const verifiedCredentialsList = useSelector(selectVerifiedCredentials);
  const verifiedCredentialsStatus = useSelector(selectVerifiedCredentialsStatus);
  const verifiedCredentialsAnyAdded = useSelector(selectVerifiedCredentialsAnyAdded);

  const newCredentials = useSelector(selectNewCredentials);
  const currentCredentials = useSelector(selectInnerCurrentCredentials);
  const isAddingNewCredential = useSelector(selectIsAddingNewCredential);

  const { applicationTitle, personalProfileSource } = useSelector(selectFeatures);
  const isSubmittingDispute = useSelector(selectIsSubmittingDispute);
  const userProfile = useSelector(selectUserProfile);
  const currentTourSteps = useSelector(selectCurrentTourSteps);
  const showTour = useSelector(selectDisplayTour);

  const [isMobile, setIsMobile] = useState(checkIsMobile(breakpoint));
  const [isDisputing, setIsDisputing] = useState(false);
  const [isDisputeConfirmed, setIsDisputeConfirmed] = useState(false);
  const [isAcceptingModal, setIsAcceptingModal] = useState(false);
  const [isDeletingModal, setIsDeletingModal] = useState(false);
  const [selectedCredential, setSelectedCredential] = useState(null);
  const [disputeMessage, setDisputeMessage] = useState('');
  const [isAddingCredential, setIsAddingCredential] = useState(false);
  const [isDeletingCredential, setIsDeletingCredential] = useState(false);
  const [isManualFlow, setIsManualFlow] = useState(false);
  const [isParsedFlow, setIsParsedFlow] = useState(false);
  const [parsedCredential, setParsedCredential] = useState(null);
  const [parsingStep, setParsingStep] = useState(0);
  const [parsedImage, setParsedImage] = useState(null);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [alertMessage, setAlertMessage] = useState('Your Credential has been added!');
  const [showAlert, setShowAlert] = useState(false);
  const [credentialInputName, setCredentialInputName] = useState('');
  const [credentialToBeDeleted, setCredentialToBeDeleted] = useState(null);
  const [showResumeParser, setShowResumeParser] = useState(false);
  const [isParsingNSCRow, setIsParsingNSCRow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isShownVerificationMessage, setIsShownVerificationMessage] = useState(false);
  const [isVCExpanded, setIsVCExpanded] = useState(true);
  const [showDisputeSection, setShowDisputeSection] = useState(false);
  const [jsonData, setJsonData] = useState(null);
  const [rowInDispute, setRowInDispute] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [triggerButton, setTriggerButton] = useState(null);
  const [showModalTour, setShowModalTour] = useState(false);
  const [showSecondTour, setShowSecondTour] = useState(false);
  const [isDirectMatch, setIsDirectMatch] = useState(false);
  const [allowAssessmentChange, setAllowAssessmentChange] = useState(true);

  const skillResults = useSkillsSearch(searchTerm);

  const modalTriggerElement = useRef(null);

  const onBadgrLogout = async (data) => {
    const result = await dispatch(submitBadgrLogoutReq());
  };

  const useCompetenciesFormDefinition = ({ onChange }) => {
    const fieldsDefinition = [
      {
        isCustom: true,
        component: ({ ...formProps }) => (
          <AutoComplete
            name="competences"
            label="Associated Skills"
            options={skillResults}
            optionLabel="value"
            onValueSelect={(value) => {
              const newSelectedSkills = value?.map((s) => ({
                competenceId: s.id,
                name: s.value,
                assessment: s.assessment,
              }));

              setParsedCredential({
                ...parsedCredential,
                skills: newSelectedSkills,
              });
              setSelectedSkills(newSelectedSkills);
            }}
            defaultSelectedValues={selectedSkills}
            onInputChange={(e, value) => setSearchTerm(value)}
            tooltip={<p>Add all related skills for this credential</p>}
            isMandatory={true}
            {...formProps}
          />
        ),
      },
    ];

    return {
      formDefinition: {
        fieldsDefinition,
      },
    };
  };

  function checkIsMobile(breakpoint) {
    return breakpoint === 'xs' || breakpoint === 'sm';
  }

  function updateParsedCredentialProp({ prop, value }) {
    setParsedCredential({
      ...parsedCredential,
      [prop]: value,
    });
  }

  useEffect(() => {
    if (!!parsedCredential?.issuedOn) {
      setSelectedYear(moment(parsedCredential?.issuedOn).format('YYYY'));
      setSelectedMonth(moment(parsedCredential?.issuedOn).format('M'));
      setSelectedDay(moment(parsedCredential?.issuedOn).date());
    }
    const directMatch = parsedCredential?.skills?.some((skill) => skill.sources?.some((s) => s.method == 'RSD'));
    setIsDirectMatch(directMatch);
  }, [parsedCredential]);

  useEffect(() => {
    if (!credentialsList?.length && !hasFetchedCredentials && !isFetchingCredentials) {
      dispatch(fetchCredentialsReq());
      dispatch(fetchCredentialsStatusReq());
      dispatch(fetchAllSkillsReq());
      dispatch(fetchUserSelectedWorkRolesReq());
    }
  }, [credentialsList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (verifiedCredentialsAnyAdded) {
      setShowAlert(true);
      setAlertMessage(
        'One or more external credentials have been found. Accept to add to your wallet.',
      );
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
    }
  }, [verifiedCredentialsAnyAdded]);

  useEffect(() => {
    if (
      !verifiedCredentialsList?.length &&
      !hasFetchedVerifiedCredentials &&
      !isFetchingVerifiedCredentials
    ) {
      dispatch(fetchBadgrCredentials());
    }

    dispatch(setCurrentTourSteps(getCredentialTourSteps()));
  }, []);

  useEffect(() => {
    setIsMobile(checkIsMobile(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
  }, [dispatch, personalProfileSource]);

  useEffect(() => {
    if (parsedCredential?.credentialJson) {
      try {
        const parsedJson = JSON.parse(parsedCredential?.credentialJson);
        setJsonData(parsedJson);
      } catch (err) {
        console.error('Invalid JSON pasted');
        setJsonData(null);
      }
    }
  }, [parsedCredential?.credentialJson]);

  if (!userProfile) {
    return null;
  }

  const setActiveCredentialDispute = ({ credential }) => {
    setIsDisputing(true);
    setSelectedCredential(credential);
  };

  const removeActiveCredentialDispute = () => {
    setIsDisputing(false);
    setSelectedCredential(null);
  };

  const selectedCredentialImageURL =
    selectedCredential?.credentialProvider === LCN_CREDENTIAL_PROVIDERS.BADGR
      ? selectedCredential?.imageUrl
      : getBase64ImageUrl(selectedCredential?.base64schoollogo);

  async function submitDispute() {
    const result = await dispatch(submitCredentialDispute({ selectedCredential, disputeMessage }));
    setIsDisputeConfirmed(true);
  }

  function handleJoyrideCallback(data) {
    const { action, index, origin, status, type } = data;
  }

  function NewCredentialRow({ row }) {
    const imageUrl =
      row.credentialProvider === LCN_CREDENTIAL_PROVIDERS.BADGR
        ? row.imageUrl
        : row.base64schoollogo
        ? getBase64ImageUrl(row.base64schoollogo)
        : row.resources?.[0]?.fileS3Url;

    const date = moment(row.awardDate).format('MM/DD/YYYY');
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div
        className={`iq4-credentials__new-card ${isExpanded ? 'iq4-credentials-auto-height' : ''}`}
        key={row?.id}
      >
        <IconButton
          classes={{ root: 'iq4-credentials__expand' }}
          aria-label="expand row"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <div
          className={`iq4-credentials__new-container ${
            isExpanded ? 'iq4-credentials-auto-height' : ''
          }`}
        >
          <>
            <div
              className={`iq4-credentials__new-container--row ${
                isExpanded ? 'iq4-credentials__new-container--fixed-row' : ''
              }`}
            >
              <RecordImageAndLabel imageUrl={imageUrl} label={row.name} />
            </div>
            <div
              className={`iq4-credentials__new-container--row ${
                isExpanded ? 'iq4-credentials__new-container--fixed-row' : ''
              }`}
            >
              <Pill type="new" label="new" />
            </div>
            <div
              className={`iq4-credentials__new-container--row ${
                isExpanded ? 'iq4-credentials__new-container--fixed-row' : ''
              }`}
            >
              <span>{date}</span>
            </div>
            <div
              className={`iq4-credentials__new-container--row ${
                isExpanded ? 'iq4-credentials__new-container--fixed-row' : ''
              }`}
            >
              {row?.issuerName}
            </div>
            <div
              className={`iq4-credentials__new-container--row ${
                isExpanded ? 'iq4-credentials__new-container--fixed-row' : ''
              }`}
            >
              <div className="iq4-credentials__accept-container">
                <div
                  className="iq4-credentials__dispute"
                  role="button"
                  onClick={() => setActiveCredentialDispute({ credential: row })}
                  onKeyPress={() => setActiveCredentialDispute({ credential: row })}
                >
                  Dispute
                </div>
                <Button
                  className="iq4-credentials__accept"
                  onClick={() => setIsAcceptingModal(true)}
                >
                  Accept
                </Button>
              </div>
            </div>
          </>
        </div>
        {isExpanded && (
          <CredentialExpanded
            open
            record={row}
            actionButtonLabel={row.dispute ? 'Cancel Dispute' : 'Dispute'}
            actionButtonAction={() => {}}
            isCancelAction={row.dispute}
            renderAsTableSemantics={false}
            groups={[
              [
                <CredentialExpandedItem label="Description" content={row.description} />,
                <CredentialExpandedItem
                  label="Award Date"
                  content={moment(row.awardDate).format('MM/DD/YYYY')}
                />,
                <CredentialExpandedItem label="Criteria" content={row.criteria} />,
                <CredentialExpandedItem
                  content={
                    !!(row.achievementUrl || row.learnMoreUrl) && (
                      <ExternalLinkInterstitial
                        linkLabel="View Credential"
                        className="iq4-credential__link"
                        link={row.learnMoreUrl || row.achievementUrl}
                        introText={INTERSTITIAL_COPY.BADGR_INTRO_TEXT}
                        mainText={INTERSTITIAL_COPY.BADGR_MAIN_TEXT}
                      />
                    )
                  }
                />,
                <div className="iq4-credential-expanded__container--line-desktop" />,
                <CredentialExpandedItem label="Majors" content={row.majors} />,
                <CredentialExpandedItem label="Major Options" content={row.majorOptions} />,
                <CredentialExpandedItem
                  label="Major Concentrations"
                  content={row.majorConcentrations}
                />,
                <CredentialExpandedItem label="Minors" content={row.minors} />,
                <CredentialExpandedAlignments
                  label="Alignments"
                  content={row.alignmentList}
                  credentialImage={row.imageUrl}
                />,
              ],
            ]}
          />
        )}
      </div>
    );
  }

  function goBackFromDispute() {
    setIsDisputing(false);
    setIsDisputeConfirmed(false);
    dispatch(setIsParsingCredentials(false));
    dispatch(setHasParsedCredentials(false));
  }

  async function handleCredentialUpload(attachment) {
    let formData = new FormData();
    let name = attachment[0].name;
    let fileExt = name.split('.').pop();
    formData.append('data', attachment[0]);
    formData.append('type', fileExt);

    const result = await dispatch(fetchFileParsedSkillsReq(formData));
    if (!!result.payload) {
      const resultObj = result.payload?.[0];
      if (!checkStatus(resultObj)) return;
      if (resultObj?.skills) {
        resultObj.skills.forEach((skill) => {
          skill.isChecked = true;
        });
        const allowAssessmentChange = !resultObj?.skills?.some((skill) => skill.assessment);
        setAllowAssessmentChange(allowAssessmentChange);
      }
      setParsedCredential(resultObj);
    }
  }

  function checkStatus(resultObj) {
    if (resultObj?.status?.startsWith('Unable')) {
      closeAddCredentialModal();
      setAlertMessage(resultObj?.status + ': ' + resultObj?.statusReason);
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 5000);
      return false;
    } else return true;
  }
  async function handleCredentialLinkUpdate() {
    const result = await dispatch(fetchParsedSkillsReq({ url: parsedCredential?.credentialLink }));
    if (!!result.payload) {
      const resultObj = result.payload?.[0];
      if (!checkStatus(resultObj)) return;
      if (resultObj?.skills) {
        resultObj.skills.forEach((skill) => {
          skill.isChecked = true;
        });
        const allowAssessmentChange = !resultObj?.skills?.some((skill) => skill.assessment);
        setAllowAssessmentChange(allowAssessmentChange);
      }

      setParsedCredential(resultObj);
      setIsManualFlow(false);
      setIsParsedFlow(true);
      setParsingStep(1);
    }
  }

  async function handleCredentialJsonUpdate() {
    const result = await dispatch(
      fetchParsedSkillsFromJsonReq({ jsonBody: parsedCredential?.credentialJson }),
    );

    if (!!result.payload) {
      const resultObj = result.payload?.[0];
      if (!checkStatus(resultObj)) return;

      if (resultObj?.skills) {
        resultObj.skills.forEach((skill) => {
          skill.isChecked = true;
        });
        const allowAssessmentChange = !resultObj?.skills?.some((skill) => skill.assessment);
        setAllowAssessmentChange(allowAssessmentChange);
      }

      setParsedCredential(resultObj);
      setIsManualFlow(false);
      setIsParsedFlow(true);
      setParsingStep(1);
    }
  }

  function resetAddCredential() {
    setIsParsedFlow(false);
    setIsManualFlow(false);
    setParsedImage(null);
    dispatch(setIsParsingCredentials(false));
    dispatch(setHasParsedCredentials(false));
    dispatch(setIsAddingNewCredential(false));
    setIsEdit(false);
    setParsingStep(0);
    setParsedCredential(null);
    setSelectedMonth('');
    setSelectedYear('');
    setSelectedYear('');
    setSelectedDay('');
    setJsonData(null);
  }

  function closeAddCredentialModal() {
    setIsAddingCredential(false);
    resetAddCredential();
  }

  function closeDeleteCredentialModal() {
    setIsDeletingModal(false);
    setCredentialToBeDeleted(null);
  }

  function goBackFromParse() {
    if (parsingStep === 3 || parsingStep === 2) {
      setParsingStep(parsingStep - 1);
    } else {
      resetAddCredential();
    }
  }

  const LoadingContainer = () => (
    <div className="iq4-30-onboarding-uploader__loading-container">
      <CircularProgress
        size={100}
        classes={{ root: 'iq4-30-credentials-uploader__circular-progress' }}
      />
    </div>
  );

  const UploadedContainer = () => (
    <div className="iq4-credentials__upload-parent">
      <div className="iq4-credentials__upload-container">
        <div className="iq4-30-credentials-uploader-complete__content">
          <div className="iq4-30-onboarding-resume-complete__percent">100%</div>
        </div>
      </div>
      <Button className="iq4-credentials__save" onClick={() => setParsingStep(1)}>
        Next
      </Button>
    </div>
  );

  const modalTitles = ['', '- Details', '- Skills', '- Skills Self assessment'];

  const handleImageUpload = (data) => {
    if (data && data.length) {
      data[0].preview = URL.createObjectURL(data[0]);
      setParsedImage(data[0]);
    }
  };

  function updateSkillAssessment({ skill, assessment }) {
    let newParsedCredential = { ...parsedCredential };

    let cIndex;
    if (isEdit) {
      cIndex = newParsedCredential.skills.findIndex(
        (x) => (x.competenceId || x.id) === skill.competenceId,
      );

      // Create a new array with all elements of the original array and the updated element
      newParsedCredential.skills = newParsedCredential.skills.map((skill, index) => {
        if (index !== cIndex) {
          return skill;
        }

        // Return a new object with the updated properties for the element you want to modify
        return { ...skill, assessment: assessment };
      });

      setSelectedSkills([
        ...selectedSkills.filter((x) => (x.competenceId || x.id) !== skill.competenceId),
        {
          competenceId: newParsedCredential.skills[cIndex].competenceId,
          name: newParsedCredential.skills[cIndex].value || newParsedCredential.skills[cIndex].name,
          assessment: newParsedCredential.skills[cIndex].assessment,
        },
      ]);
    } else {
      cIndex = newParsedCredential.skills.findIndex((x) => x.competenceId === skill.competenceId);

      // Create a new array with all elements of the original array and the updated element
      newParsedCredential.skills = newParsedCredential.skills.map((skill, index) => {
        if (index !== cIndex) {
          return skill;
        }

        // Return a new object with the updated properties for the element you want to modify
        return { ...skill, assessment: assessment };
      });

      setParsedCredential(newParsedCredential);

      setSelectedSkills([
        ...selectedSkills.filter((x) => (x.competenceId || x.id) !== skill.competenceId),
        {
          competenceId: newParsedCredential.skills[cIndex].competenceId,
          name: newParsedCredential.skills[cIndex].value || newParsedCredential.skills[cIndex].name,
          assessment: newParsedCredential.skills[cIndex].assessment,
        },
      ]);
    }

    setParsedCredential(newParsedCredential);
  }

  async function submitParsedCredential() {
    const requestObj = {
      ...(isEdit ? { id: parsedCredential.id } : {}),
      credential: {
        ...parsedCredential,
        startYear: yearValues[selectedYear]?.value,
        startMonth: +selectedMonth,
        startDay: +selectedDay,
      },
      credentialImg: parsedImage,
      selectedSkills,
      selectedDate: {
        year: yearValues[selectedYear]?.value,
        month: +selectedMonth,
        day: +selectedDay,
      },
    };

    let result;

    if (isEdit) {
      result = await dispatch(submitEditCredential({ ...requestObj }));
    } else {
      result = await dispatch(submitNewCredential({ ...requestObj }));
    }

    if (result.type.indexOf('rejected') >= 0) {
      dispatch(setError());
    } else {
      setAlertMessage(`Your Credential has been ${isEdit ? 'edited' : 'added'}!`);
      setShowAlert(true);
      dispatch(fetchAllSkillsReq());
    }
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    closeAddCredentialModal();
  }

  async function handleDeleteCredential() {
    setIsDeletingCredential(true);
    await dispatch(submitDeleteCredential({ id: credentialToBeDeleted?.id }));
    setShowAlert(true);
    setAlertMessage('Your Credential has been deleted!');
    setIsDeletingCredential(false);
    setCredentialInputName('');
    dispatch(fetchBadgrCredentials());
    dispatch(fetchAllSkillsReq());
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
    closeDeleteCredentialModal();
  }

  function goToManualFlow() {
    setIsManualFlow(true);
    setParsingStep(1);
  }

  function goToLinkFlow() {
    setIsManualFlow(true);
    setParsingStep(7);
  }
  function goToJsonFlow() {
    setIsManualFlow(true);
    setParsingStep(8);
  }

  function goToFinalParsingStep() {
    const selectedSkills = parsedCredential?.skills?.filter((x) => x.isChecked);
    setSelectedSkills(selectedSkills);
    setParsingStep(3);
  }

  function checkFormDisabled() {
    return (
      !parsedCredential?.description ||
      !parsedCredential?.name ||
      !parsedCredential?.issuerName ||
      (!isParsedFlow && !parsedCredential?.skills?.length) ||
      !selectedDay ||
      !selectedMonth ||
      !selectedYear
    );
  }

  function checkLinkFormDisabled() {
    return (
      !parsedCredential?.credentialLink ||
      (!parsedCredential?.credentialLink?.includes('http') &&
        !parsedCredential?.credentialLink?.includes('www.'))
    );
  }
  function checkJsonFormDisabled() {
    return !parsedCredential?.credentialJson;
  }

  function getAllowedDays(month, year) {
    if (!month || !year) return [];
    function checkLeapYear(year) {
      return (0 == year % 4 && 0 != year % 100) || 0 == year % 400;
    }
    const days = [
      null,
      31,
      checkLeapYear(selectedYear) ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31,
    ];

    return Array.from({ length: days[+month] }, (_, i) => ({ key: i + 1, value: i + 1 }));
  }

  function renderAddLinkDetails() {
    return (
      <>
        <div style={{ marginBottom: 26 }}>
          Paste the public link from your credential provider's website.
        </div>
        <div className="iq4-credentials__form">
          <label for="credentialLink">
            URL{' '}
            <span className="iq4-field__required-mark" aria-hidden="true">
              *
            </span>
            <span className="sr-only">Required field</span>
          </label>
          <input
            type="text"
            className="iq4-credentials__input-text"
            placeholder="https://www.credential-provider.com/"
            value={parsedCredential?.credentialLink}
            id="credentialLink"
            onChange={(e) =>
              updateParsedCredentialProp({ prop: 'credentialLink', value: e.target.value })
            }
          />

          <Button
            className="iq4-credentials__save"
            loading={isParsingCredentials}
            disabled={checkLinkFormDisabled()}
            onClick={handleCredentialLinkUpdate}
          >
            Import
          </Button>
        </div>
      </>
    );
  }

  function renderAddJsonDetails() {
    return (
      <>
        <div style={{ marginBottom: 26 }}>Add your JSON file to add a credential</div>
        <div className="iq4-credentials__form">
          <label id="credentialJson">
            Paste JSON here: <span className="iq4-field__required-mark">*</span>
          </label>
          <textarea
            rows={4}
            className="iq4-credentials__input-textarea"
            value={parsedCredential?.credentialJson}
            aria-labelledby="credentialJson"
            onChange={(e) =>
              updateParsedCredentialProp({ prop: 'credentialJson', value: e.target.value })
            }
          />
          {jsonData && <ReactJson src={jsonData} theme="bright:inverted" />}

          <Button
            className="iq4-credentials__save"
            loading={isParsingCredentials}
            disabled={checkJsonFormDisabled()}
            onClick={handleCredentialJsonUpdate}
          >
            Import
          </Button>
        </div>
      </>
    );
  }

  function renderAddSkillsDetails() {
    return (
      <div className="iq4-credentials__form">
        <div>Badge Image</div>
        <div className="iq4-credentials__camera-parent">
          {parsedCredential?.imageUrl && (
            <img
              className="iq4-credentials__parsed-image"
              src={parsedCredential?.imageUrl}
              alt="thumbnail"
            />
          )}
          {!parsedCredential?.imageUrl && parsedImage && (
            <img
              className="iq4-credentials__parsed-image"
              src={parsedImage.preview}
              alt="thumbnail"
            />
          )}
          {!parsedCredential?.imageUrl && (
            <Uploader
              fileTypes={THUMBNAIL_MIME_TYPES}
              handleUpload={handleImageUpload}
              ariaLabel="Please upload your Credential image"
            >
              {!parsedImage && (
                <div className="iq4-credentials__camera-container">
                  <SimpleIcon name="camera" />
                </div>
              )}
              <div className="iq4-credentials__camera-text">Upload Image</div>
            </Uploader>
          )}
        </div>
        <label htmlFor="credentialTitle">
          Credential Title{' '}
          <span className="iq4-field__required-mark" aria-hidden="true">
            *
          </span>
          <span className="sr-only">Required field</span>
        </label>
        <input
          type="text"
          className="iq4-credentials__input-text"
          value={parsedCredential?.name}
          id="credentialTitle"
          onChange={(e) => updateParsedCredentialProp({ prop: 'name', value: e.target.value })}
        />
        <label htmlFor="issuerName">
          Issuer Name{' '}
          <span className="iq4-field__required-mark" aria-hidden="true">
            *
          </span>
          <span className="sr-only">Required field</span>
        </label>
        <input
          type="text"
          className="iq4-credentials__input-text"
          value={parsedCredential?.issuerName}
          id="issuerName"
          onChange={(e) =>
            updateParsedCredentialProp({ prop: 'issuerName', value: e.target.value })
          }
        />
        {isManualFlow && (
          <FormWrapper
            showButtons={false}
            onChange={(values) => {}}
            {...(isEdit && { values: parsedCredential })}
            useFormDefinition={useCompetenciesFormDefinition}
          />
        )}
        <label htmlFor="year-native-helper">
          Award Date{' '}
          <span className="iq4-field__required-mark" aria-hidden="true">
            *
          </span>
          <span className="sr-only">Required field</span>
        </label>
        <div className="iq4-credentials__add__selects">
          <FormControl
            variant="outlined"
            className={`iq4-select__form-control iq4-select__form-control-full`}
          >
            <InputLabel classes={{ root: 'sr-only' }} htmlFor="year-native-helper">
              Year
            </InputLabel>
            <NativeSelect
              value={selectedYear}
              IconComponent={ExpandMoreIcon}
              inputProps={{
                id: 'year-native-helper',
                name: 'year',
                'aria-label': 'Please fill in the year value',
              }}
              onChange={(e) => setSelectedYear(e.target.value)}
              classes={{
                outlined: 'iq4-select__input',
              }}
            >
              <option value="" disabled>
                Year
              </option>
              {yearValues.map((year, index) => (
                <option value={index}>{year.value}</option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl
            variant="outlined"
            className={`iq4-select__form-control iq4-select__form-control-full`}
          >
            <InputLabel classes={{ root: 'sr-only' }} htmlFor="month-native-helper">
              Month
            </InputLabel>
            <NativeSelect
              value={selectedMonth}
              IconComponent={ExpandMoreIcon}
              inputProps={{
                id: 'month-native-helper',
                name: 'month',
                'aria-label': 'Please fill in the month value',
              }}
              onChange={(e) => setSelectedMonth(e.target.value)}
              classes={{
                outlined: 'iq4-select__input',
              }}
            >
              <option value="" disabled>
                Month
              </option>
              {SELECT_MONTHS.map((month, index) => (
                <option value={month.id}>{month.label}</option>
              ))}
            </NativeSelect>
          </FormControl>
          <FormControl
            variant="outlined"
            className={`iq4-select__form-control iq4-select__form-control-full`}
          >
            <InputLabel classes={{ root: 'sr-only' }} htmlFor="day-native-helper">
              Day
            </InputLabel>
            <NativeSelect
              value={selectedDay}
              IconComponent={ExpandMoreIcon}
              inputProps={{
                id: 'day-native-helper',
                name: 'day',
                'aria-label': 'Please fill in the day value',
              }}
              onChange={(e) => setSelectedDay(e.target.value)}
              classes={{
                outlined: 'iq4-select__input',
              }}
            >
              <option value="" disabled>
                Day
              </option>
              {getAllowedDays(selectedMonth, selectedYear).map((day, index) => (
                <option value={day.key}>{day.value}</option>
              ))}
            </NativeSelect>
          </FormControl>
        </div>
        <label htmlFor="credentialDescription">
          Credential Description{' '}
          <span className="iq4-field__required-mark" aria-hidden="true">
            *
          </span>
          <span className="sr-only">Required field</span>
        </label>
        <textarea
          type="text"
          rows={4}
          value={parsedCredential?.description}
          id="credentialDescription"
          onChange={(e) =>
            updateParsedCredentialProp({ prop: 'description', value: e.target.value })
          }
          className="iq4-credentials__input-textarea"
        />

        <Button
          className="iq4-credentials__save"
          disabled={checkFormDisabled()}
          onClick={() => setParsingStep(2)}
        >
          Next
        </Button>
      </div>
    );
  }

  async function handleCredentialDispute(credential) {
    console.log('dispute credential');
    await dispatch(submitDisputeCredentialReq({ credential }));
    setIsDisputing(false);
  }

  function renderSkillsAssessments() {
    return (
      <div>
        <div>Rate your level for each skill</div>
        <div className="iq4-credentials__popover-container">
          <Tooltip message={<AssessmentInfoPanel />}>
            View Guide
            <SimpleIcon name="star" />
          </Tooltip>
        </div>
        <div className="iq4-credentials__parsed-skill-row-container-2">
          {orderBy(selectedSkills, ['name'], ['asc'])?.map((skill) => (
            <div className="iq4-credentials__parsed-skill-row">
              <div>{skill.name}</div>
              <AssessmentStars
                assessment={skill.assessment}
                onChange={(assessment) => updateSkillAssessment({ skill, assessment })}
                displayOnly={!!skill.assessment && !allowAssessmentChange}
              />
            </div>
          ))}
        </div>
        <div className="iq4-credentials__parsed-skill-note">
          You can associate more skills with this credential in your Skills tab in the navigation
          bar.
        </div>
        <div className="iq4-credentials__save-container">
          <Button
            loading={isAddingNewCredential}
            className="iq4-credentials__save"
            onClick={submitParsedCredential}
          >
            {!isAddingNewCredential && <span>Save</span>}
            {isAddingNewCredential && (
              <CircularProgress
                size={10}
                classes={{ root: 'iq4-credentials__circular-progress' }}
              />
            )}
          </Button>
        </div>
      </div>
    );
  }

  function onCredentialRowEdit(row) {
    const finalCompetencies = row.competences?.map((c) => ({
      id: c.id,
      value: c.tier3?.label,
      assessment: c.assessment,
    }));

    setParsedCredential({
      ...row,
      competences: finalCompetencies,
    });

    setSelectedSkills(
      row.competences?.map((c) => ({
        competenceId: c.id,
        name: c.tier3?.label,
        assessment: c.assessment,
      })),
    );
    setSelectedYear(row.startYear);
    setSelectedMonth(row.startMonth);
    setSelectedDay(row.startDay);
    setParsingStep(1);
    setIsManualFlow(true);
    setIsEdit(true);
    setTriggerButton('.iq4-credentials__edit');
    setIsAddingCredential(true);
  }

  function handleTourCallback(data) {
    if ([STATUS.SKIPPED].includes(data.status)) {
      dispatch(setDisplayTour(false));
      return;
    }
    if (data.origin === 'button_close') {
      dispatch(setDisplayTour(false));
      return;
    }

    if (data.action === 'next' && data.index === 1 && data.lifecycle === 'complete') {
      const button = document.querySelector(
        !credentialsList?.length && !!!verifiedCredentialsList?.length && !isMobile
          ? '.iq4-credentials__manual-add-empty'
          : '.iq4-credentials__manual-add',
      );
      if (button) {
        button.click();
        setShowModalTour(true);
      }
    }
  }

  function handleModalTourCallback(data) {
    if (data.origin === 'button_close') {
      dispatch(setDisplayTour(false));
      setShowModalTour(false);
      return;
    }
    if (data.status === 'finished' && data.type === 'tour:end') {
      setShowModalTour(false);
      setIsAddingCredential(false);
      setShowSecondTour(true);
      dispatch(setDisplayTour(false));
    }
  }
  function handleSecondTourCallback(data) {
    if (data.status === 'finished' && data.type === 'tour:end') {
      setShowSecondTour(false);
      dispatch(setDisplayTour(false));
    }
  }

  function renderDesktop() {
    return (
      <>
        <Helmet title={`${applicationTitle} - Credentials`} />
        <Topbar Logo={Logo} onUploadResume={() => setShowResumeParser(true)} />
        <main className={`iq4-credentials__page`} id="main">
          {!!showCredentialsBanner && <CredentialsBanner />}
          <Container className="iq4-credentials__container">
            <div className="iq4-credentials__header">
              <div className="iq4-credentials__title-container">
                <h1 className="iq4-credentials__title">Credentials</h1>
                {(!!currentCredentials.length || !!verifiedCredentialsList.length) && (
                  <Button
                    ref={modalTriggerElement}
                    className="iq4-credentials__manual-add"
                    onClick={() => setIsAddingCredential(true)}
                    loading={isSubmittingDispute}
                  >
                    Add a Credential
                  </Button>
                )}
              </div>
              <div className="iq4-credentials__title--small">
                This is where you can accept and manage all your Credentials.
              </div>
            </div>

            {((!isFetchingCredentials && !!verifiedCredentialsList?.length) ||
              isFetchingCredentials ||
              isFetchingVerifiedCredentials) && (
              <Grid container alignItems="flex-start">
                <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                  <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                    <h2 className="iq4-credentials__sub-header">External Credentials</h2>
                    {!!isFetchingVerifiedCredentials && (
                      <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                        <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                          <MyRecordsLoadingSkeleton />
                        </CardContent>
                      </Card>
                    )}
                    {!isFetchingCredentials && (
                      <>
                        {!!verifiedCredentialsList.length && hasFetchedVerifiedCredentials && (
                          <div>
                            <div style={{ margin: '2rem 0' }}>
                              You can view all details and accept external credentials from this
                              section into your wallet
                            </div>
                            <div className="iq4-credentials__new-container-header">
                              <div className="iq4-credentials__new-container--header">Name</div>
                              <div className="iq4-credentials__new-container--header iq4-credentials__status_header">
                                Status
                              </div>
                              <div className="iq4-credentials__new-container--header">
                                Award Date
                              </div>
                              <div className="iq4-credentials__new-container--header">Issuer</div>
                              <div className="iq4-credentials__new-container--header">Action</div>
                            </div>
                            {showSecondTour && (
                              <div className="iq4-tour-example-credential">
                                <VerifiedCredentialRow
                                  row={exampleCredential}
                                  setRowInDispute={() => {}}
                                  setIsDisputing={() => {}}
                                />
                              </div>
                            )}
                            {verifiedCredentialsList?.map((credential) => (
                              <VerifiedCredentialRow
                                row={credential}
                                setIsDisputing={(_, uId) => {
                                  setIsDisputing(true);
                                  setUniqueId(uId);
                                }}
                                setRowInDispute={setRowInDispute}
                              />
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid container alignItems="flex-start">
              {!!isFetchingCredentials && (
                <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                  <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                    <MyRecordsLoadingSkeleton />
                  </CardContent>
                </Card>
              )}
              {!isFetchingCredentials && (
                <>
                  {!!newCredentials.length && (
                    <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                      <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                        <h2 className="iq4-credentials__sub-header">New</h2>
                        <div className="iq4-credentials__sub-content">
                          Congratulations! You’ve received a new Credential. Select accept if you
                          want to manage it within your profile or you can dispute it if there is a
                          problem.
                        </div>
                        <div className="iq4-credentials__new-container-header">
                          <div className="iq4-credentials__new-container--header">Name</div>
                          <div className="iq4-credentials__new-container--header">Status</div>
                          <div className="iq4-credentials__new-container--header">Award Date</div>
                          <div className="iq4-credentials__new-container--header">Issuer</div>
                          <div className="iq4-credentials__new-container--header">Action</div>
                        </div>
                        {newCredentials?.map((credential) => (
                          <NewCredentialRow row={credential} />
                        ))}
                      </CardContent>
                    </Card>
                  )}
                  {!!credentialsList?.length && hasFetchedCredentials && (
                    <div className="iq4-tour-manual" style={{ width: '100%' }}>
                      <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                        <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                          <h2 className="iq4-credentials__sub-header">Manage</h2>
                          <div className="iq4-credentials__card-container-header">
                            <div className="iq4-credentials__card-container--header">Name</div>
                            {!isMobile && (
                              <>
                                <div className="iq4-credentials__card-container--header iq4-credentials__status_header">
                                  Status
                                </div>
                                <div className="iq4-credentials__card-container--header">
                                  Award Date
                                </div>
                                <div className="iq4-credentials__card-container--header">
                                  Issuer
                                </div>
                                <div className="iq4-credentials__card-container--header">
                                  Action
                                </div>
                              </>
                            )}
                          </div>
                          {credentialsList?.map((credential) => (
                            <CredentialRow
                              row={credential}
                              setCredentialToBeDeleted={setCredentialToBeDeleted}
                              setIsDeletingModal={setIsDeletingModal}
                              setIsEdit={onCredentialRowEdit}
                            />
                          ))}
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </>
              )}
              {isDisputing && !isDisputeConfirmed && (
                <CredentialDisputeModal
                  credential={rowInDispute}
                  onClose={() => setIsDisputing(false)}
                  onDispute={handleCredentialDispute}
                  onDeactivation={() => {
                    setTimeout(() => {
                      const button = document.querySelector(`.iq4-dispute-${uniqueId}`);
                      if (button) {
                        button.focus();
                      }
                    }, 1);
                  }}
                />
              )}
              {isDisputing && isDisputeConfirmed && (
                <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                  <CardContent classes={{ root: 'iq4-credentials__card-content-dispute' }}>
                    <div className="iq4-credentials__dispute-confirmation-container">
                      <h2>Submission Complete</h2>
                      <Tick className="iq4-credentials__dispute--tick" />
                      <div className="iq4-credentials__dispute--thank-you">
                        Thank you, your message has been submitted.
                      </div>
                      <div className="iq4-credentials__dispute--what">What happens next?</div>
                      <div className="iq4-credentials__dispute--hear">
                        You will hear from your credential awarder shortly.
                      </div>
                      <Button
                        className="iq4-credentials__dispute--back"
                        onClick={goBackFromDispute}
                      >
                        Back
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              )}
            </Grid>
          </Container>
          {!isFetchingCredentials &&
            !isFetchingVerifiedCredentials &&
            !credentialsList?.length &&
            !verifiedCredentialsList?.length && (
              <Container className="iq4-credentials__container">
                <Grid container alignItems="flex-start">
                  <Card variant="outlined" classes={{ root: 'iq4-credentials__card-empty' }}>
                    <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                      <div className="iq4-credentials-page__empty-container">
                        <EmptyStateIcon role="presentation" aria-hidden="true" />
                        <div className="iq4-credentials-page__empty-container--label">
                          You have no credentials to manage
                        </div>
                        <Button
                          ref={modalTriggerElement}
                          className="iq4-credentials__manual-add-empty"
                          onClick={() => setIsAddingCredential(true)}
                          loading={isSubmittingDispute}
                        >
                          Add a Credential
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Container>
            )}
          {!isFetchingCredentials &&
            !credentialsList?.length &&
            !!verifiedCredentialsList?.length &&
            !isDisputing && (
              <Container className="iq4-credentials__container">
                <Grid container alignItems="flex-start">
                  <Card variant="outlined" classes={{ root: 'iq4-credentials__card' }}>
                    <CardContent classes={{ root: 'iq4-credentials__card-content' }}>
                      <h2 className="iq4-credentials__sub-header">Manage</h2>
                      <div className="iq4-verified-credentials__card-container-header">
                        <div className="iq4-credentials__card-container--header">Name</div>
                        <div className="iq4-credentials__card-container--header">Award Date</div>
                        <div className="iq4-credentials__card-container--header">Issuer</div>
                        <div className="iq4-verified-credentials__card-container--header">
                          Action
                        </div>
                      </div>
                      <div className="iq4-credentials__existing-card iq4-credentials__empty-manage">
                        <SimpleIcon name="info" /> To manage your external credentials from above,
                        please select "Accept" or add a credential using the button above
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Container>
            )}
          <div className="iq4-credentials__footer-container">
            <Footer />
          </div>
          <div
            className={`iq4-credentials__alert ${
              showAlert ? 'iq4-credentials__alert--visible' : ''
            }`}
          >
            <SimpleIcon className="iq4-credentials__alert--close" name="close" />
            {alertMessage}
          </div>
        </main>
      </>
    );
  }

  const mobileTabContent = [
    {
      title: 'EXTERNAL',
      content: (
        <div>
          {showSecondTour && (
            <div className="iq4-tour-example-credential">
              <VerifiedCredentialRow
                row={exampleCredential}
                setRowInDispute={() => {}}
                setIsDisputing={() => {}}
              />
            </div>
          )}
          {verifiedCredentialsList?.map((credential) => (
            <VerifiedCredentialRow
              row={credential}
              setIsDisputing={(_, uId) => {
                setIsDisputing(true);
                setUniqueId(uId);
              }}
              setRowInDispute={setRowInDispute}
            />
          ))}
        </div>
      ),
    },
    {
      title: 'MANAGE',
      content: (
        <div>
          {credentialsList?.map((credential) => (
            <CredentialRow
              row={credential}
              setCredentialToBeDeleted={setCredentialToBeDeleted}
              setIsDeletingModal={setIsDeletingModal}
              setIsEdit={onCredentialRowEdit}
            />
          ))}
        </div>
      ),
    },
  ];

  const loadingTabContent = [
    {
      title: 'EXTERNAL',
      content: <MyRecordsLoadingSkeleton />,
    },
    {
      title: 'MANAGE',
      content: <div />,
    },
  ];

  function renderMobile() {
    return (
      <>
        <Helmet title={`${applicationTitle} - Credentials`} />
        <Topbar Logo={Logo} />
        <main className={`iq4-credentials__page`} id="main">
          {!!showCredentialsBanner && !isDisputing && <CredentialsBanner />}
          <Container className="iq4-credentials__container">
            <Grid container alignItems="flex-start">
              <div className="iq4-credentials__header">
                <h1 className="iq4-credentials__title">Credentials</h1>
                <div className="iq4-credentials__title--small">
                  This is where you can accept and manage all your Credentials.
                </div>
                <Button
                  className="iq4-credentials__manual-add"
                  onClick={() => setIsAddingCredential(true)}
                  loading={isSubmittingDispute}
                >
                  Add a Credential
                </Button>
              </div>
              <div className="iq4-credentials__mobile-tab-container">
                {!isFetchingCredentials && (
                  <InnerTabs
                    id="credentials-tab"
                    data={mobileTabContent}
                    tabsProps={{ variant: 'fullWidth' }}
                  />
                )}
                {isFetchingCredentials && (
                  <InnerTabs
                    id="credentials-tab"
                    data={loadingTabContent}
                    tabsProps={{ variant: 'fullWidth' }}
                  />
                )}
              </div>
            </Grid>
          </Container>
        </main>
      </>
    );
  }

  function refocusAfterModal() {
    setTimeout(() => {
      const button = document.querySelector(
        triggerButton ? triggerButton : '.iq4-credentials__manual-add',
      );
      if (button) {
        button.focus();
        setTriggerButton(null);
      }
    }, 100);
  }

  return (
    <>
      <Modal
        className={`iq4-credentials__delete-modal`}
        isOpen={isDeletingModal}
        handleClose={() => setIsDeletingModal(false)}
        onDeactivation={() => {
          setTimeout(() => {
            const button = document.querySelector('.iq4-credentials__delete');
            if (button) {
              button.focus();
            }
          }, 1);
        }}
      >
        <h2 className="iq4-credentials__modal--delete-title">You are deleting a Credential</h2>
        {isDeletingCredential && <LoadingContainer />}
        {!isDeletingCredential && (
          <>
            <div className="iq4-credentials__modal--delete-title-2">
              Please type "delete" to delete
            </div>
            <input
              type="text"
              value={credentialInputName}
              aria-label="Please type 'delete' to delete this Credential"
              className="iq4-credentials__input-text"
              onChange={(e) => setCredentialInputName(e.target.value)}
            />
            <div className="iq4-credentials__accept-container">
              <Button
                className="iq4-credentials__delete-btn"
                disabled={
                  credentialInputName?.toString().toLowerCase() !== 'delete' || isDeletingCredential
                }
                onClick={handleDeleteCredential}
              >
                DELETE
              </Button>
            </div>
          </>
        )}
      </Modal>
      <Modal
        className={`iq4-credentials__modal`}
        isOpen={verifiedCredentialsStatus == 'unable to authenticate'}
        handleClose={onBadgrLogout}
      >
        <SimpleIcon name="exclamation" className="iq4-credentials__modal--icon" />
        <h2 className="iq4-credentials__modal--title">Your email doesn't match</h2>

        <div className="iq4-credentials__modal--content">
          Please add your Wallet's email to Badgr to authenticate your Badgr Credentials.
        </div>
        <Button className="iq4-credentials__save" onClick={onBadgrLogout}>
          Back to Credentials
        </Button>
      </Modal>
      <Modal
        className={`iq4-credentials__modal`}
        isOpen={
          isParsedFlow &&
          !isParsingCredentials &&
          hasParsedCredentials &&
          parsingStep === 0 &&
          parsedCredential &&
          parsedCredential?.status == 'unable to authenticate'
        }
        handleClose={resetAddCredential}
      >
        <SimpleIcon name="exclamation" className="iq4-credentials__modal--icon" />
        <h2 className="iq4-credentials__modal--title">Your email doesn't match</h2>

        <div className="iq4-credentials__modal--content">
          Your Wallet's email does not match the email in the file.
        </div>
        <Button className="iq4-credentials__save" onClick={resetAddCredential}>
          Back to Credentials
        </Button>
      </Modal>
      <Modal
        className={`iq4-credentials__modal`}
        isOpen={isAcceptingModal}
        handleClose={() => setIsAcceptingModal(false)}
      >
        <h2 className="iq4-credentials__modal--title">You've Accepted your Credential</h2>
        <div className="iq4-credentials__modal--content">
          Make your credential available on your profile
        </div>
        <PrivatePublicToggle
          displayScope={true}
          isConsentGiven={true}
          onToggle={(record) => () => {}}
          record={{ permission: 'true' }}
          onSelecting={({ signaling }) => () => {}}
        />
        <Button className="iq4-credentials__save" onClick={() => setIsAcceptingModal(false)}>
          Save
        </Button>
      </Modal>

      <Modal
        className={`iq4-credentials__add-modal ${showModalTour ? 'modal-without-background' : ''}`}
        isOpen={isAddingCredential}
        handleClose={closeAddCredentialModal}
        onDeactivation={refocusAfterModal}
        useFocusLock={false}
      >
        {!isParsedFlow && !isManualFlow && (
          <>
            {showModalTour && (
              <Joyride
                disableBeacon={true}
                steps={[
                  {
                    disableOverlay: false,
                    placement: 'right',
                    target: '.iq4-tour-canvas',
                    disableBeacon: true,
                    styles: {
                      options: {
                        zIndex: 100000,
                      },
                    },
                    content: (
                      <div>
                        <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
                          If you have received a digital badge through Canvas Credentials, selecting
                          this option will allow you to import your badges straight into your
                          Wallet. Just sign into your Canvas account and Authorize the Wallet to
                          access it.
                        </p>
                      </div>
                    ),
                  },
                  {
                    disableOverlay: false,
                    placement: 'bottom',
                    target: '.iq4-tour-parse',
                    disableBeacon: true,
                    styles: {
                      options: {
                        zIndex: 10000,
                      },
                    },
                    content: (
                      <div>
                        <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
                          If you have a document such as your transcript, a course syllabus or some
                          other type of certificate, you can upload that here.
                        </p>
                      </div>
                    ),
                  },
                  {
                    disableOverlay: false,
                    placement: 'bottom',
                    target: '.iq4-tour-details',
                    disableBeacon: true,
                    styles: {
                      options: {
                        zIndex: 10000,
                      },
                    },
                    content: (
                      <div>
                        <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
                          While we're trying to connect with other badging platforms, you can still
                          add any credential here. Just remember that adding a credential this way
                          won't make it show up as a verified achievement.
                        </p>
                      </div>
                    ),
                  },
                  {
                    disableOverlay: false,
                    placement: 'top',
                    target: '.iq4-tour-import',
                    disableBeacon: true,
                    styles: {
                      options: {
                        zIndex: 10000,
                      },
                    },
                    content: (
                      <div>
                        <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
                          Use this option to link directly to your digital credential if it is
                          available online.
                        </p>
                      </div>
                    ),
                  },
                  {
                    disableOverlay: false,
                    placement: 'top',
                    target: '.iq4-tour-json',
                    disableBeacon: true,
                    styles: {
                      options: {
                        zIndex: 10000,
                      },
                    },
                    content: (
                      <div>
                        <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
                          Some credential providers let you download the JSON for a credential. You
                          can paste that here to transfer your credential data.
                        </p>
                      </div>
                    ),
                  },
                ]}
                continuous={true}
                showProgress={false}
                run={true}
                spotlightClicks={false}
                callback={handleModalTourCallback}
                disableOverlayClose={true}
                disableScrolling={true}
                locale={{
                  last: 'Next',
                }}
                styles={{
                  buttonBack: {
                    backgroundColor: 'transparent',
                    color: '#0070F0',
                  },
                  buttonNext: {
                    backgroundColor: '#0070F0',
                    color: 'white',
                  },
                }}
              />
            )}
            <h2 className="iq4-credentials__add-modal--title">Add a Credential</h2>
            <div className="iq4-credentials__add-modal--content">
              Choose an option to help you start adding your Credential
            </div>
            <a
              tabIndex={0}
              role="button"
              aria-label="Sign into Canvas Badges. It will redirect to Canvas Badges."
              className="iq4-credentials__add-modal--option iq4-tour-canvas"
              href={BADGR_AUTH_URL}
              onKeyPress={() => window.open(BADGR_AUTH_URL)}
            >
              <div className="iq4-credentials__add-modal--option--container">
                <div className="iq4-credentials__add-modal--option--content">
                  <div className="iq4-credentials__add-modal--opt-title">
                    Sign into Canvas Badges
                  </div>
                  <div className="iq4-credentials__add-modal--opt-description">
                    Sign into Canvas Badges to import your credentials
                  </div>
                </div>
              </div>
            </a>
            <div
              className="iq4-credentials__add-modal--option iq4-credentials__add-modal--option-2 iq4-tour-parse"
              onClick={() => setIsParsedFlow(true)}
              onKeyPress={() => setIsParsedFlow(true)}
              role="button"
              tabIndex={0}
              aria-label="Parse a document, by uploading a pre-filled document"
            >
              <div className="iq4-credentials__add-modal--option--container">
                <div className="iq4-credentials__add-modal--option--content">
                  <div className="iq4-credentials__add-modal--opt-title">Parse a Document</div>
                  <div className="iq4-credentials__add-modal--opt-description">
                    Select a pre-filled out document like a PDF
                  </div>
                </div>
              </div>
            </div>
            <div
              className="iq4-credentials__add-modal--option iq4-credentials__add-modal--option-2 iq4-tour-details"
              onClick={goToManualFlow}
              onKeyPress={goToManualFlow}
              role="button"
              tabIndex={0}
              aria-label="Enter Credential Details Manually"
            >
              <div className="iq4-credentials__add-modal--option--container">
                <div className="iq4-credentials__add-modal--option--content">
                  <div className="iq4-credentials__add-modal--opt-title">
                    Enter Credential Details
                  </div>
                  <div className="iq4-credentials__add-modal--opt-description">
                    Enter your credentials manually through our online form
                  </div>
                </div>
              </div>
            </div>
            <div
              className="iq4-credentials__add-modal--option iq4-credentials__add-modal--option-2 iq4-tour-import"
              onClick={goToLinkFlow}
              onKeyPress={goToLinkFlow}
              role="button"
              tabIndex={0}
              aria-label="Import a link"
            >
              <div className="iq4-credentials__add-modal--option--container">
                <div className="iq4-credentials__add-modal--option--content">
                  <div className="iq4-credentials__add-modal--opt-title">Import a Link</div>
                  <div className="iq4-credentials__add-modal--opt-description">
                    Import a link from your credential provider
                  </div>
                </div>
              </div>
            </div>
            <div
              className="iq4-credentials__add-modal--option iq4-credentials__add-modal--option-2 iq4-tour-json"
              onClick={goToJsonFlow}
              onKeyPress={goToJsonFlow}
              role="button"
              tabIndex={0}
              aria-label="Paste a JSON file"
            >
              <div className="iq4-credentials__add-modal--option--container">
                <div className="iq4-credentials__add-modal--option--content">
                  <div className="iq4-credentials__add-modal--opt-title">Paste a JSON file</div>
                  <div className="iq4-credentials__add-modal--opt-description">
                    Add your JSON file to add a credential
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {isParsedFlow && (
          <>
            <SimpleIcon
              name="arrow"
              role="button"
              tabIndex={0}
              onClick={goBackFromParse}
              onKeyPress={goBackFromParse}
              className="iq4-credentials__add-modal--arrow"
              aria-label="Go back to previous step"
            />
            <h2 className="iq4-credentials__add-modal--title">
              Add a Credential {modalTitles[parsingStep]}
            </h2>
            {!hasParsedCredentials && (
              <>
                <Uploader
                  handleUpload={handleCredentialUpload}
                  ariaLabel="Please upload your credential document"
                >
                  <div className="iq4-credentials__upload-parent">
                    <div className="iq4-credentials__upload-container">
                      {!isParsingCredentials && !hasParsedCredentials && (
                        <>
                          <UploaderIcon className="iq4-credentials__add-modal--upload" />
                          <div className="iq4-credentials__upload-text">
                            Upload your Credential Document
                          </div>
                        </>
                      )}
                      {isParsingCredentials && <LoadingContainer />}
                    </div>
                  </div>
                </Uploader>
              </>
            )}
            {!isParsingCredentials && hasParsedCredentials && parsingStep === 0 && (
              <UploadedContainer />
            )}
            {parsingStep === 1 && renderAddSkillsDetails()}
            {parsingStep === 2 && (
              <div>
                <div>We found {parsedCredential?.skills?.length} skills from your credential</div>
                {!!parsedCredential?.skills?.length && (
                  <>
                    <div className="iq4-credentials__parsed-skill-row-container">
                      {orderBy(parsedCredential?.skills, ['name'], ['asc'])?.map((skill) => (
                        <div className="iq4-credentials__parsed-skill-row">
                          <div>{skill.name}</div>
                          <Checkbox
                            isDisabled={isDirectMatch || (!isManualFlow && !isParsedFlow)}
                            handleChange={(e) => {
                              const newSkills = [...parsedCredential?.skills];
                              const newSkill = newSkills.find(
                                (x) => x.competenceId === skill.competenceId,
                              );
                              newSkill.isChecked = e.target.checked;

                              setParsedCredential({
                                ...parsedCredential,
                                skills: newSkills,
                              });
                            }}
                            isChecked={skill.isChecked}
                            key={skill.id}
                            displayVerified = {isDirectMatch}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="iq4-credentials__parsed-skill-note">
                      You can associate more skills with this credential in your Skills tab in the
                      navigation bar.
                    </div>
                    <div className="iq4-credentials__save-container">
                      <Button
                        disabled={!parsedCredential?.skills?.length}
                        className="iq4-credentials__save"
                        onClick={goToFinalParsingStep}
                      >
                        Next
                      </Button>
                    </div>
                  </>
                )}
                {!parsedCredential?.skills?.length && (
                  <>
                    <div
                      className="iq4-credentials__parsed-skill-row-container"
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <EmptyStateImage />
                    </div>
                    <div className="iq4-credentials__parsed-skill-note">
                      Unfortunately, we couldn't find any skills in your credential. You can still
                      save the Credential and associate skills with it later.
                    </div>
                    <div className="iq4-credentials__save-container">
                      <Button className="iq4-credentials__save" onClick={submitParsedCredential}>
                        Save
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            {parsingStep === 3 && renderSkillsAssessments()}
          </>
        )}
        {isManualFlow && (
          <>
            {!isEdit && (
              <SimpleIcon
                name="arrow"
                role="button"
                tabIndex={0}
                onClick={goBackFromParse}
                onKeyPress={goBackFromParse}
                className="iq4-credentials__add-modal--arrow"
                aria-label="Go back to previous step"
              />
            )}
            <h2 className="iq4-credentials__add-modal--title">
              Add a Credential {modalTitles[parsingStep]}
            </h2>
            {parsingStep === 1 && renderAddSkillsDetails()}
            {parsingStep === 7 && renderAddLinkDetails()}
            {parsingStep === 8 && renderAddJsonDetails()}
            {parsingStep === 2 && renderSkillsAssessments()}
          </>
        )}
      </Modal>
      {showTour && (
        <Joyride
          disableBeacon={true}
          disableOverlay={true}
          showSkipButton={true}
          showCloseButton={false}
          steps={[
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    Need help understanding this section of the platform? This brief tutorial will
                    show you everything you need to know!
                  </p>
                </div>
              ),
              disableOverlay: false,
              placement: 'center',
              target: 'body',
              styles: {
                options: {
                  zIndex: 10000,
                },
              },
            },
            {
              disableOverlay: false,
              target:
                !credentialsList?.length && !!!verifiedCredentialsList?.length && !isMobile
                  ? '.iq4-credentials__manual-add-empty'
                  : '.iq4-credentials__manual-add',
              placement: 'left',
              disableBeacon: true,
              spotlightClicks: true,
              content: (
                <div>
                  <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
                    Click here to add a credential to your Wallet. There are several different ways
                    this can be done!
                  </p>
                </div>
              ),
            },
          ]}
          continuous={true}
          showProgress={false}
          run={true}
          callback={handleTourCallback}
          disableOverlayClose={true}
          scrollToFirstStep={true}
          disableScrolling={true}
          styles={{
            buttonBack: {
              backgroundColor: 'transparent',
              color: '#0070F0',
            },
            buttonNext: {
              backgroundColor: '#0070F0',
              color: 'white',
            },
          }}
          locale={{
            last: 'Open Modal',
            skip: 'Exit Tour',
          }}
        />
      )}
      {showSecondTour && (
        <Joyride
          disableBeacon={true}
          disableOverlay={true}
          steps={[
            {
              disableOverlay: false,
              target: '.iq4-tour-example-credential',
              placement: 'left',
              disableBeacon: true,
              spotlightClicks: true,
              content: (
                <div>
                  <p style={{ fontSize: '0.875rem' }}>
                    If you've linked an account from a badging platform to your Wallet, you can see
                    details about your badges and choose whether to accept each badge into your
                    Wallet.
                  </p>
                </div>
              ),
            },
            {
              disableOverlay: false,
              target: isMobile ? '#tab-credentials-tab-1' : '.iq4-tour-manual',
              placement: 'auto',
              disableBeacon: false,
              spotlightClicks: true,
              content: (
                <div>
                  <p style={{ fontSize: '0.875rem' }}>
                    In this section, you can set each credential as Private or Public for sharing.
                    You can also view details, inspect the badge on the issuing provider's website,
                    or delete a credential if it's no longer relevant.
                  </p>
                </div>
              ),
            },
          ]}
          showProgress={false}
          continuous={true}
          run={true}
          callback={handleSecondTourCallback}
          disableOverlayClose={true}
          scrollToFirstStep={true}
          disableScrolling={true}
          styles={{
            buttonBack: {
              backgroundColor: 'transparent',
              color: '#0070F0',
            },
            buttonNext: {
              backgroundColor: '#0070F0',
              color: 'white',
            },
          }}
          locale={{
            last: 'Finish Tour',
          }}
        />
      )}
      {isMobile ? renderMobile() : renderDesktop()}
    </>
  );
};
