import React, { useState, useEffect, useMemo } from 'react';
import { PathwaysPanelHeader } from '../PathwaysPanelHeader';
import { BAR_IDS } from '../';
import { PathwaysSkillList } from '../PathwaysSkillList';
import './RoleSkillsInfoPanel.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectDisplayMissingSkillsTour } from '../../../../store/features/ui/selectors';
import Joyride from 'react-joyride';
import {
  setDisplayMissingSkillsTour,
  setDisplaySecondPathwayDetailTour,
} from '../../../../store/features/ui/uiSlice';
/**
 * A component that shows the breakdown of a role's skills
 * @param {object[]} userSkills - An array containing the users skills that aren't required by the role
 * @param {object[]} overlapSkills - An array containing the overap of the user's skills and the role skills
 * @param {object[]} roleSkills - An array containing the role skills the user doesn't have yet
 * @param {string} [activeBar] - An id representing which bar is active
 * @param {function} [onBackClick] - Handle back click
 * @param {function} [onActiveBarChange] - Notify parent when active bar has changed
 * @param {function} [onSkillClick] - Handle skills click
 */
export const RoleSkillsInfoPanel = ({
  userSkills,
  overlapSkills,
  roleSkills,
  activeBar,
  onBackClick = () => {},
  onSkillClick = () => {},
  onActiveBarChange = () => {},
  onSkillToggleClick = () => {},
}) => {
  const [currentActiveBar, setCurrentActiveBar] = useState(activeBar);
  const dispatch = useDispatch();
  const currentSkillsInfo = useMemo(() => {
    function getCurrentSkillsListInfo(barID, userSkills, overlapSkills, roleSkills) {
      const skillsMap = {
        [BAR_IDS.UNMATCHED_SKILLS]: { skills: userSkills, title: 'Unmatched Skills' },
        [BAR_IDS.MISSING_SKILLS]: { skills: roleSkills, title: 'Missing Skills' },
        [BAR_IDS.MATCHED_SKILLS]: { skills: overlapSkills, title: 'Matched Skills' },
      };

      return skillsMap[barID];
    }

    return getCurrentSkillsListInfo(currentActiveBar, userSkills, overlapSkills, roleSkills);
  }, [currentActiveBar, userSkills, overlapSkills, roleSkills]);
  const displayMissingSkillsTour = useSelector(selectDisplayMissingSkillsTour);

  // update the current active bar if parent updates it.
  useEffect(() => {
    setCurrentActiveBar(activeBar);
  }, [activeBar]);

  if (!currentSkillsInfo) {
    return null;
  }

  function handleTourCallback(data) {
    if (data.action === 'reset' || data.status === 'init') {
      onBackClick(true);
      dispatch(setDisplayMissingSkillsTour(false));
    }
  }

  return (
    <div className="iq4-role-skills-info-panel">
      <PathwaysPanelHeader
        title={
          <>
            <span className="iq4-pathway-detail__header-title">{currentSkillsInfo.title}</span>{' '}
            <span className="iq4-pathway-detail__header-title-pill">
              {currentSkillsInfo.skills.length}
            </span>
          </>
        }
        onLeftClick={() => onBackClick(false)}
        className="iq4-role-skills-info-panel__header"
      />
      {displayMissingSkillsTour && (
        <Joyride
          callback={handleTourCallback}
          steps={[
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    As you check these missing skills, you might see some skills that you have but
                    haven't added yet. Simply click 'Add Skill' to update your profile.
                  </p>
                </div>
              ),
              disableOverlay: false,
              disableScrolling: true,
              styles: {
                options: {
                  zIndex: 1200,
                },
              },
              target: '.iq4-roles-skills-list__container',
              disableBeacon: true,
            },
            {
              content: (
                <div style={{ padding: '1rem' }}>
                  <p style={{ fontSize: '0.875rem' }}>
                    For some skills, we've found learning opportunities you can check out to address
                    your skills gap.
                  </p>
                </div>
              ),
              disableScrolling: false,
              disableOverlay: false,
              styles: {
                options: {
                  zIndex: 1200,
                },
              },
              target: '.iq4-roles-skills-list__skill-additional-courses',
              disableBeacon: true,
            },
          ]}
          showProgress={false}
          continuous={true}
          run={true}
          disableOverlayClose={true}
          scrollToFirstStep={true}
          disableBeacon={true}
          scrollOffset={350}
          styles={{
            buttonBack: {
              backgroundColor: 'transparent',
              color: '#0070F0',
            },
            buttonNext: {
              backgroundColor: '#0070F0',
              color: 'white',
            },
          }}
          locale={{
            last: 'Next',
          }}
        />
      )}
      {currentActiveBar && (
        <PathwaysSkillList
          className="iq4-role-skills-info-panel__skill-list"
          title={currentActiveBar !== 'MISSING_SKILLS' ? 'Matched Skills' : 'Missing Skills'}
          skills={currentSkillsInfo.skills}
          onClick={onSkillClick}
          addedSkills={currentActiveBar !== 'MISSING_SKILLS'}
          onSkillToggleClick={onSkillToggleClick}
        />
      )}
    </div>
  );
};
