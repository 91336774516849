import React, { useRef, useState, useEffect } from 'react';
import { AssessmentInfoPopover, RatingStars, SimpleIcon } from 'common/components';
import { SELF_ASSESSMENT_ASSESSEE_ID } from 'common/constants/identifiers';
import StarIcon from '@material-ui/icons/Star';
import './SkillProficiency.scss';

/**
 * Proficiency Tab: contains self assessment + additional contributions (if any)
 * @param skill
 * @returns {*}
 * @constructor
 */
export const SkillProficiency = ({ skill, onSelfAssessment, readOnly = false }) => {
  const [showSkillProficiencyInfo, setShowSkillProficiencyInfo] = useState(false);
  const containerRef = useRef(null);
  const assessments = skill.assessments || [];
  const additionalContributions = assessments.filter(
    (s) => s.assesseeId !== SELF_ASSESSMENT_ASSESSEE_ID,
  );
  const selfAssessment = assessments.find((s) => s.assesseeId === SELF_ASSESSMENT_ASSESSEE_ID);

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowSkillProficiencyInfo(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelfAssessment = (e) => {
    if (readOnly) return;

    onSelfAssessment({
      competenceId: skill.competenceId,
      assessment: parseInt(e.target.value),
    });
  };

  const getOverallAverage = (arr) => {
    const sum = arr.reduce((total, currentValue) => total + currentValue, 0);
    return sum / arr.length;
  };

  return (
    <>
      <div className="iq4-skill-card__proficiency--container">
        <div className="iq4-skill-card__proficiency--content">
          <div className="iq4-skill-card__proficiency--label">
            your self assessment{' '}
            <SimpleIcon
              name="infoDefault"
              className="iq4-skill-card__proficiency--info"
              role="button"
              tabIndex={0}
              hidden={false}
              onClick={() => setShowSkillProficiencyInfo(!showSkillProficiencyInfo)}
              onKeyPress={() => setShowSkillProficiencyInfo(!showSkillProficiencyInfo)}
              aria-label="Get more info about 4 star assessments"
            />
            {showSkillProficiencyInfo && (
              <div className="iq4__star-proficiency__container" ref={containerRef}>
                <div className="iq4__star-proficiency__title">Guide to 4 star assessments</div>
                <div className="iq4__star-proficiency__container--row">
                  <StarIcon />
                  <div className="iq4__star-proficiency__content">Novice</div>
                </div>
                <div className="iq4__star-proficiency__container--description">
                  You're just beginning to learn about this skillset. You might already have some
                  exposure to it, but you are still in the initial stages and have a lot more to
                  learn and explore.
                </div>
                <div className="iq4__star-proficiency__container--row">
                  <div>
                    <StarIcon />
                    <StarIcon />
                  </div>
                  <div className="iq4__star-proficiency__content">Intermediate</div>
                </div>
                <div className="iq4__star-proficiency__container--description">
                  You have some experience and the ability to perform basic tasks associated with
                  it. While building upon your knowledge, you are working towards becoming more
                  proficient. You may require a higher level of guidance to apply this skillset
                  successfully due to limited or no practical experience.
                </div>
                <div className="iq4__star-proficiency__container--row">
                  <div>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </div>
                  <div className="iq4__star-proficiency__content">Advanced</div>
                </div>
                <div className="iq4__star-proficiency__container--description">
                  You have a deep understanding of this skillset and can perform complex tasks
                  associated with it effortlessly. You've had substantial practice and/or
                  professional experience in this area, and you're comfortable teaching or guiding
                  others in this skillset.
                </div>
                <div className="iq4__star-proficiency__container--row">
                  <div>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </div>
                  <div className="iq4__star-proficiency__content">Expert</div>
                </div>
                <div className="iq4__star-proficiency__container--description">
                  You have mastered this skillset and have extensive experience and proficiency in
                  it. You can perform exceptionally difficult tasks with ease and precision. You are
                  qualified to provide expert advice, solutions and even innovate within this field.
                  Recognized as a thought leader in this skillset, you are routinely sought out to
                  coach others, demonstrating consistent excellence in its application. You are also
                  capable of teaching, mentoring, and developing others to reach their advanced
                  level of proficiency.
                </div>
              </div>
            )}
          </div>
          <span className="iq4-skill-card__proficiency--rating">
            <SimpleIcon name="personCircle" />
            <span className="iq4-skill-card__proficiency--rating-star">
              <RatingStars
                readOnly={readOnly}
                value={(selfAssessment && selfAssessment.assessment) || 0}
                isSelf
                onValueSelect={handleSelfAssessment}
              />
            </span>
          </span>
        </div>
        {/*        <div className="iq4-skill-card__proficiency--content">
          <span className="iq4-skill-card__proficiency--label">overall skill proficiency</span>
          <span className="iq4-skill-card__proficiency--rating">
            <RatingStars value={getOverallAverage(assessments.map((s) => s.assessment))} readOnly />
          </span>
        </div>*/}
      </div>
      {!!additionalContributions.length && (
        <div className="iq4-skill-card__proficiency--additional">
          <div className="iq4-skill-card__proficiency--label">additional contributions</div>
          <div className="iq4-skill-card__proficiency--item-container">
            {additionalContributions.map((a) => (
              <div className="iq4-skill-card__proficiency--additional-item">
                <div className="iq4-skill-card__proficiency--content">
                  <span className="iq4-skill-card__proficiency--additional-label">
                    {a.assesseeName}
                  </span>
                  <span className="iq4-skill-card__proficiency--rating">
                    <RatingStars value={a.assessment} readOnly />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
