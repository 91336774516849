import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Topbar } from 'common/components';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { ROUTES } from 'routes/routeNames';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/config/selectors';

export const SitemapPage = () => {
  const { applicationTitle } = useSelector(selectFeatures);
  const history = useHistory();
  return (
    <>
      <Helmet title={`${applicationTitle} - Site Map`} />
      <Topbar Logo={Logo} />
      <div className="iq4-terms-page">
        <div className="iq4-max-width-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 className="iq4-terms-page__title">Site Map</h1>
          </div>
          <div className="iq4-terms-page--container">
            <div className="iq4-terms-page--content">
              <ul>
                <li>
                  <Link to={ROUTES.PROFILE_ROOT}>Profile</Link>
                </li>
                <li>
                  <Link to={ROUTES.MANAGE_SKILLS}>Skills</Link>
                </li>
                <li>
                  <Link to={ROUTES.CREDENTIALS}>Credentials</Link>
                </li>
                <li>
                  <Link to={ROUTES.CAREER_PATHWAYS}>Career Pathways</Link>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ROUTES.TERMS_AND_CONDITIONS}
                    aria-label="Go to Terms and Conditions Page. It will open on a new tab."
                  >
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={ROUTES.PRIVACY_POLICY}
                    aria-label="Go to Privacy Policy Page. It will open on a new tab."
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
