import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import { OnboardingFooter } from 'common/components/Onboarding';
import './OnboardingResumeComplete.scss';

export const OnboardingResumeComplete = ({ onComplete = () => {}, onPrevious = () => {} }) => {
  const history = useHistory();

  return (
    <div className="iq4-30-onboarding-resume-complete__container">
      <div className="iq4-30-onboarding-resume-complete__content">
        <h1 className="iq4-30-onboarding-resume-complete__title">Let's get started</h1>
        <div className="iq4-30-onboarding-resume-complete">
          <div className="iq4-30-onboarding-resume-complete__percent">100%</div>
          <p className="iq4-30-onboarding-resume-complete__text">Successful upload!</p>
          <div aria-live="polite" role="region" className="sr-only">
            Your Resume has finished uploading.
          </div>
        </div>
      </div>
      <OnboardingFooter
        direction="We have found credentials, professional experience and skills in your resume. Click 'Next' to confirm them and build your profile."
        isSubmittingForm={false}
        handleNext={() => onComplete()}
        handlePrev={() => onPrevious()}
      />
    </div>
  );
};
