import React, { useEffect, useRef, useState } from 'react';
import { SimpleIcon } from 'common/components';
import './ManageSkillsCardSort.scss';

export const ManageSkillsCardSort = ({ onChangeSort, currentSort, hasProfFilter = true }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sortRef = useRef(null);

  const toggleVisibility = () => setIsVisible(!isVisible);

  function handleClickOutside(event) {
    if (!isVisible) return;
    const isClickOutside = sortRef.current && !sortRef.current.contains(event.target);
    setIsVisible(!isClickOutside);
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const updateSort = (newSort) => {
    Object.keys(currentSort).forEach((v) => (currentSort[v] = false));
    onChangeSort({
      ...currentSort,
      [newSort]: true,
    });
  };

  return (
    <div className="iq4-manage-skills__sort--container" ref={sortRef}>
      <span
        className="iq4-manage-skills__sort--trigger"
        role="button"
        tabIndex={0}
        onClick={toggleVisibility}
        onKeyPress={toggleVisibility}
        aria-label="Sort skills"
      >
        <SimpleIcon name="sort" />
      </span>
      {isVisible && (
        <div className="iq4-manage-skills__sort-section">
          <div className="iq4-manage-skills__sort-section--title">skills</div>
          <div className="iq4-manage-skills__sort-section--options-container">
            {currentSort.hasOwnProperty('aToZ') && (
              <button
                className="iq4-manage-skills__sort-section--options"
                onClick={() => updateSort('aToZ')}
              >
                <span>Alphabetical A-Z</span>
                {currentSort.aToZ && <SimpleIcon name="tick" />}
              </button>
            )}
            {currentSort.hasOwnProperty('zToA') && (
              <button
                className="iq4-manage-skills__sort-section--options"
                onClick={() => updateSort('zToA')}
              >
                <span>Alphabetical Z-A</span>
                {currentSort.zToA && <SimpleIcon name="tick" />}
              </button>
            )}
            {currentSort.hasOwnProperty('newToOld') && (
              <button
                className="iq4-manage-skills__sort-section--options"
                onClick={() => updateSort('newToOld')}
              >
                <span>New to old</span>
                {currentSort.newToOld && <SimpleIcon name="tick" />}
              </button>
            )}
            {currentSort.hasOwnProperty('oldToNew') && (
              <button
                className="iq4-manage-skills__sort-section--options"
                onClick={() => updateSort('oldToNew')}
              >
                <span>Old to new</span>
                {currentSort.oldToNew && <SimpleIcon name="tick" />}
              </button>
            )}
            {hasProfFilter && (
              <>
                {(currentSort.hasOwnProperty('lowToHigh') ||
                  currentSort.hasOwnProperty('highToLow')) && (
                  <div className="iq4-manage-skills__sort-section--title">proficiency</div>
                )}
                {currentSort.hasOwnProperty('lowToHigh') && (
                  <button
                    className="iq4-manage-skills__sort-section--options"
                    onClick={() => updateSort('lowToHigh')}
                  >
                    <span>Low to high</span>
                    {currentSort.lowToHigh && <SimpleIcon name="tick" />}
                  </button>
                )}
                {currentSort.hasOwnProperty('highToLow') && (
                  <button
                    className="iq4-manage-skills__sort-section--options"
                    onClick={() => updateSort('highToLow')}
                  >
                    <span>High to low</span>
                    {currentSort.highToLow && <SimpleIcon name="tick" />}
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
