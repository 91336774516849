import React from 'react';
import { groupBy } from 'lodash';
import './CredentialExpandedAlignments.scss';

export const CredentialExpandedAlignments = ({ credentialImage, content }) => {
  if (!content || content.length === 0) return null;

  return (
    <>
      <div className="iq4-credential-expanded-alignments">
        {Object.entries(
          groupBy(
            content
              .map((item) => (!item.framework ? { ...item, framework: 'Undefined' } : item)) // Replace framework null values with 'Undefined'
              .sort((a, b) => a?.framework?.localeCompare(b?.framework)), // Sort frameworks a-z
            (item) => item.framework,
          ),
        ).map(([key, value], index) => (
          <div
            className="iq4-credential-expanded-alignments__content"
            key={`expand-item-content-${index}`}
          >
            <p className="iq4-credential-expanded-alignments__content__framework">
              Framework - {key}
            </p>
            <ul className="iq4-credential-expanded-alignments__content__list">
              {value
                .sort((a, b) => a?.name?.localeCompare(b?.name)) // Sort alignments a-z
                .map((item) => (
                  <li>{item.name}</li>
                ))}
            </ul>
          </div>
        ))}
      </div>
      <img className="iq4-credentials__expanded-image-desktop" src={credentialImage} alt="Logo" />
    </>
  );
};
