import { useEffect, useState } from 'react';

const BREAKPOINTS = {
  xs: 599,
  sm: 959,
  md: 1279,
  lg: 1919,
};

/**
 * Get the current breakpoints, i.e 'xs', 'sm', ... based on the BREAKPOINTS above
 */
export const useBreakpoints = () => {
  // keeps track of screen size (int representing px value)
  const [screenSize, setScreenSize] = useState(window.outerWidth);
  // keeps track of breakpoint, i.e 'xs', 'sm', ...
  const [breakpoint, setBreakpoint] = useState(getBreakpoints(screenSize));

  // register resize event listener & get screen size whenever the screen changes
  useEffect(() => {
    const listener = () => {
      setScreenSize(window.outerWidth);
    };

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  });

  // update the breakpoint whenever the screensize changes
  useEffect(() => {
    const newBreakpoint = getBreakpoints(screenSize);
    if (breakpoint !== newBreakpoint) {
      setBreakpoint(newBreakpoint);
    }
  }, [breakpoint, screenSize]);

  return breakpoint;

  // derive current breakpoint
  function getBreakpoints(screenSize) {
    if (screenSize <= BREAKPOINTS.xs) {
      return 'xs';
    }

    if (screenSize <= BREAKPOINTS.sm) {
      return 'sm';
    }

    if (screenSize <= BREAKPOINTS.md) {
      return 'md';
    }

    return 'lg';
  }
};
