import React, { useCallback, useEffect, useRef } from 'react';
import { useStore, getStraightPath } from 'reactflow';
import { Position, MarkerType } from 'reactflow';

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode, targetNode) {
  // Assuming nodes are perfect circles and width equals height
  const radius = intersectionNode.width / 2;
  const centerIntersectionNode = {
    x: intersectionNode.positionAbsolute.x + radius,
    y: intersectionNode.positionAbsolute.y + radius,
  };
  const centerTargetNode = {
    x: targetNode.positionAbsolute.x + targetNode.width / 2,
    y: targetNode.positionAbsolute.y + targetNode.height / 2,
  };

  // Calculate the direction from the intersection node to the target node
  const dx = centerTargetNode.x - centerIntersectionNode.x;
  const dy = centerTargetNode.y - centerIntersectionNode.y;

  // Calculate the distance between the centers
  const distance = Math.sqrt(dx * dx + dy * dy);

  // Normalize the direction
  const nx = dx / distance;
  const ny = dy / distance;

  // Scale the normalized direction by the radius to get the intersection point
  const x = centerIntersectionNode.x + nx * radius;
  const y = centerIntersectionNode.y + ny * radius;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node, intersectionPoint) {
  const n = { ...node.positionAbsolute, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function FloatingEdge({ id, source, target, markerEnd, style }) {
  const edgeRef = useRef();
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  useEffect(() => {
    if (edgeRef.current) {
      let parent = edgeRef.current.parentNode;
      while (parent) {
        if (parent.getAttribute('role') === 'button') {
          parent.removeAttribute('role');
          break;
        }
        parent = parent.parentNode;
      }
    }
  }, []);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          for (let node of mutation.addedNodes) {
            if (node.classList.contains('react-flow__edge-floating')) {
              node.setAttribute('tabIndex', '-1');
            }
          }
        }
      }
    });

    const config = { attributes: false, childList: true, subtree: true };
    const parent = document.querySelector('.react-flow__edges');

    if (parent) {
      observer.observe(parent, config);
    }

    return () => observer.disconnect();
  }, []);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty } = getEdgeParams(sourceNode, targetNode);

  const [edgePath] = getStraightPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
  });

  return (
    <path
      id={id}
      ref={edgeRef}
      tabIndex={-1}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
      style={style}
    />
  );
}
