import React, { useState } from 'react';
import { ROUTES } from 'routes/routeNames';
import { ActionCard, LoadingSkeleton, EmptyState, Modal, CredentialPill } from 'common/components';
import { useSelector } from 'react-redux';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { OnboardingHeader } from 'common/components/Onboarding';
import GraphicExperience from '@assets/svg/empty-states_experience.svg';
import { FormWrapper } from 'common/components/Form';
import { useProfileExperienceFormDefinition } from 'common/hooks/forms';
import { orderExperienceRecords } from 'common/utils/orderRecords';
import './ProfileExperienceSummary.scss';
import { useParams } from 'react-router-dom';

export const ProfileExperienceSummary = () => {
  const MAX_NUM_RECORDS = 2;
  const { experience } = useSelector(selectProfile);
  const [isOpen, setIsOpen] = useState(false);
  const isPopulated = experience.length > 0;
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const { id } = useParams();

  const context = process.env.REACT_APP_CONTEXT;

  const hasLoaded = !isBootstrapping;
  const defaultValues = {
    employmentType: 'Full-time',
  };

  const onAdd = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <ActionCard
        title="Experience"
        icon="experienceCircle"
        seeMore={
          isPopulated
            ? isPublicProfile
              ? `${context}/user/${id}/experience`
              : ROUTES.PROFILE_EXPERIENCE
            : ''
        }
        className="iq4-profile-experience-summary"
        isEmpty={!isPopulated}
      >
        {hasLoaded ? (
          <>
            {!isPopulated && (
              <>
                <img
                  src={GraphicExperience}
                  className="empty-state-graphic"
                  alt="Graphic for Experience"
                  aria-hidden="true"
                />
                {!isPublicProfile && (
                  <EmptyState
                    text="Add your work experience here and gain a competitive advantage. Get Started!"
                    action={onAdd}
                    actionLabel="Add Experience"
                    maxWidth
                  />
                )}
              </>
            )}
            {isPopulated && (
              <ul className="iq4-profile-experience-summary__list">
                {orderExperienceRecords(experience)
                  .slice(0, MAX_NUM_RECORDS)
                  .map((item) => (
                    <li className="iq4-profile-experience-summary__list-item" key={item.id}>
                      <CredentialPill credential={item} />
                      <p className="iq4-profile-experience-summary__title">{item.title}</p>
                      <p className="iq4-profile-experience-summary__company">{item.company}</p>
                      <p className="iq4-profile-experience-summary__location">{item.location}</p>
                      <p className="iq4-profile-experience-summary__year">
                        {item.fromYear} {item.fromYear !== item.toYear ? `- ${item.toYear}` : ``}
                      </p>
                      <hr className="iq4-profile-experience-summary__divide" />
                    </li>
                  ))}
              </ul>
            )}
          </>
        ) : (
          <div style={{ padding: '10px 0px' }}>
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
          </div>
        )}
      </ActionCard>
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        title={<OnboardingHeader icon="experienceCircle" title="Experience" />}
      >
        <FormWrapper
          values={defaultValues}
          onComplete={handleClose}
          useFormDefinition={useProfileExperienceFormDefinition}
        />
      </Modal>
    </>
  );
};
