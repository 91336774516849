import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './LinkIcon.scss';

export const LinkIcon = ({
  className,
  to,
  label,
  ariaLabel,
  iconName,
  isAriaHidden,
  size,
  itemRef,
  ...linkIconProps
}) => (
  <Link
    className={`iq4-link-icon ${className}`}
    aria-label={ariaLabel || label}
    to={to}
    {...(itemRef && { ref: itemRef })}
    {...linkIconProps}
  >
    <SimpleIcon
      className="iq4-link-icon__icon"
      name={iconName}
      role="presentation"
      aria-hidden={isAriaHidden}
      size={size}
    />
  </Link>
);

LinkIcon.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  size: PropTypes.string,
};

LinkIcon.defaultProps = {
  className: '',
  isAriaHidden: true,
};
