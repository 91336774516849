import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleIsSessionExpired } from 'common/store/features/session/sessionSlice';
import { Dialog } from '@material-ui/core';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { Button, SimpleIcon } from 'common/components';
import './SessionTimeoutModal.scss';

export const SessionTimeoutModal = ({ isOpen, Logo }) => {
  const breakpoint = useBreakpoints();
  const dispatch = useDispatch();
  const logoutUrl = process.env.REACT_APP_LOGOUT_URL;

  const onButtonClick = () => {
    dispatch(toggleIsSessionExpired());
    window.open(logoutUrl, '_self');
  };

  return (
    <Dialog
      fullScreen={breakpoint === 'xs'}
      disableBackdropClick
      open={isOpen}
      onClose={() => {}}
      classes={{
        root: 'iq4-modal iq4-modal--2',
        paper: 'iq4-modal__session-timeout',
      }}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="iq4-modal-title"
      BackdropProps={{
        classes: {
          root: 'iq4-modal__backdrop',
        },
      }}
    >
      <div className="iq4-modal__children-container">
        <div className="iq4-modal__children iq4-modal__session-timeout-container">
          <div className="iq4-modal__session-timeout--logo">
            <Logo />
          </div>
          <SimpleIcon className="iq4-modal__session-timeout--warning" name="warning2" />
          <span className="iq4-modal__session-timeout--text">Your session has expired</span>
          <span className="iq4-modal__session-timeout--text">Please login again to continue</span>
          <div className="iq4-modal__session-timeout--button-container">
            <Button
              className="iq4-modal__session-timeout--button"
              onClick={() => void onButtonClick()}
              variation="ghost"
            >
              Log In
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
