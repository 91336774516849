import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { SimpleIcon, RatingStars } from 'common/components';
import { MyRecordsModal } from 'components/MyRecords/common';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { Checkbox } from 'common/components/Form/fieldTypes/Checkbox';
import { RATING_STARS_SIZE } from 'common/constants/miscellaneous';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/config/selectors';
import { ClickAwayListener } from '@material-ui/core';
import './ManageSkillsCardFilter.scss';
import { CATEGORY_LABELS } from '../../utils';

/**
 * Main skills filter component
 * @param currentFilter
 * @param onChangeFilter
 * @param {object[]} [customFilters] - provide custom filters in the format: [ { filterGroupTitle: '', filters: [{ label: '', key: '' }] }, ...]
 * @returns {*}
 * @constructor
 */
export const ManageSkillsCardFilter = ({
  currentFilter,
  onChangeFilter,
  customFilters,
  hasProfFilter = true,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const filterRef = useRef(null);
  const isVisibleRef = useRef(isVisible);
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const [showFilterDescription, setShowFilterDescription] = useState(false);
  const { lcnEnabled } = useSelector(selectFeatures);

  const FILTER_DESCRIPTIONS = [
    {
      title: `${CATEGORY_LABELS['it_skills']}`,
      label: `${CATEGORY_LABELS['it_skills']} represent the ability to effectively use a variety of software and tools on the job.`,
    },
    {
      title: `${CATEGORY_LABELS['professional_skills']}`,
      label: `${CATEGORY_LABELS['professional_skills']} are unique abilities and knowledge needed for a particular occupation, that includes a distinct range of skills that are crucial for success on the job.`,
    },
    {
      title: `${CATEGORY_LABELS['soft_skills']}`,
      label: `${CATEGORY_LABELS['soft_skills']} are the personal qualities and behaviors crucial for professional success. These skills can often be transferred and applied across various job roles and industries. `,
    },
    {
      title: 'Verified',
      label:
        'Verified Skills are skills that have been substantiated through the importation of verified credentials, through mechanisms established by the platform for authentication.',
    },
    {
      title: 'Self-Attested',
      label:
        'Self-Attested Skills refer to abilities or knowledge that users claim to possess based on their own assessment or experience. These skills are added without third-party verification',
    },
  ];

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    isVisibleRef.current = isVisible; // update the ref whenever `isVisible` changes
  }, [isVisible]);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  function handleClickOutside(event) {
    if (!isVisibleRef.current) return; // use the ref instead of the state variable
    const isClickOutside = filterRef.current && !filterRef.current.contains(event.target);
    setIsVisible(!isClickOutside);
  }

  useEffect(() => {
    if (isMobile || showFilterDescription) return;
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile, showFilterDescription]);

  const updateFilter = (evt, change) => {
    onChangeFilter({
      ...currentFilter,
      [change]: evt.target.checked,
    });
  };

  const clearAllFilters = () => {
    const clearedFilters = Object.keys(currentFilter).reduce((map, filterKey) => {
      map[filterKey] = false;
      return map;
    }, {});

    onChangeFilter(clearedFilters);
  };

  const renderCustomFilters = (filters) => {
    return filters.map((filterData, i) => {
      if (!lcnEnabled && filterData.filterGroupTitle === 'type') {
        return null;
      }
      return (
        <div key={`filter-custom-filters-${i}`}>
          <div className="iq4-manage-skills__filter-section--title">
            {filterData.filterGroupTitle}
          </div>
          <div className="iq4-manage-skills__filter-section--options-container">
            {filterData.filters.map((filterItem) => {
              return (
                <label
                  className={`iq4-manage-skills__filter-section--options ${
                    currentFilter[filterItem.key]
                      ? 'iq4-manage-skills__filter-section--options-active'
                      : ''
                  }`}
                  key={`filter-section-${filterItem.key}`}
                >
                  <span>{filterItem.label}</span>
                  <Checkbox
                    handleChange={(e) => updateFilter(e, filterItem.key)}
                    isChecked={currentFilter[filterItem.key]}
                    key={currentFilter[filterItem.key]}
                    id={currentFilter[filterItem.key]}
                  />
                </label>
              );
            })}
            {!isMobile && i !== filters.length - 1 && (
              <div className="iq4-manage-skills__filter-section--separator" />
            )}
          </div>
        </div>
      );
    });
  };

  const renderFilterDescriptions = ({ isModal } = {}) => {
    return (
      <div
        className={`iq4-manage-skills__filter-descriptions-container ${
          isModal ? 'iq4-manage-skills__filter-descriptions-container--modal' : ''
        }`}
      >
        <button
          className="iq4-manage-skills__filter-descriptions-back-btn"
          onClick={() => void setShowFilterDescription(false)}
        >
          {!isModal && (
            <>
              <SimpleIcon name="arrow" />
              Back
            </>
          )}
          {isModal && <SimpleIcon name="close" />}
        </button>
        <h2 className="iq4-manage-skills__filter-descriptions-header">Filter Definitions</h2>

        {FILTER_DESCRIPTIONS.map((filterDescription) => {
          return (
            <div
              className="iq4-manage-skills__filter-descriptions-labels"
              key={filterDescription.title}
            >
              <p className="iq4-manage-skills__filter-descriptions-labels-title">
                {filterDescription.title}
              </p>
              <p className="iq4-manage-skills__filter-descriptions-label">
                {filterDescription.label}
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  return (
    <div className="iq4-manage-skills__filter-container">
      <div
        className="iq4-manage-skills__filter-trigger"
        role="button"
        tabIndex={0}
        onClick={toggleVisibility}
        onKeyPress={toggleVisibility}
      >
        <SimpleIcon name="filter" />
        <span className="iq4-manage-skills__filter-trigger--label">Filter</span>
      </div>

      {!isMobile && isVisible && (
        <div className="iq4-manage-skills__filter-section" ref={filterRef}>
          {customFilters ? (
            renderCustomFilters(customFilters)
          ) : (
            <>
              <div className="iq4-manage-skills__filter-section--title">category</div>
              <div className="iq4-manage-skills__filter-section--options-container">
                <div className="iq4-manage-skills__filter-section--options">
                  <Checkbox
                    aria-labelledby={CATEGORY_LABELS['it_skills']}
                    label={CATEGORY_LABELS['it_skills']}
                    handleChange={(e) => updateFilter(e, 'technologyCategory')}
                    isChecked={currentFilter.technologyCategory}
                    key={currentFilter.technologyCategory}
                    id="itSkillsCheckbox"
                    name="itSkillsCheckboxName"
                  />
                </div>
                <div className="iq4-manage-skills__filter-section--options">
                  <Checkbox
                    aria-labelledby={CATEGORY_LABELS['professional_skills']}
                    label={CATEGORY_LABELS['professional_skills']}
                    handleChange={(e) => updateFilter(e, 'professionalSkillsCategory')}
                    isChecked={currentFilter.professionalSkillsCategory}
                    id="professionalSkillsCheckbox"
                    name="professionalSkillsCheckboxName"
                  />
                </div>
                <div className="iq4-manage-skills__filter-section--options">
                  <Checkbox
                    aria-labelledby={CATEGORY_LABELS['soft_skills']}
                    label={CATEGORY_LABELS['soft_skills']}
                    handleChange={(e) => updateFilter(e, 'businessDevelopmentCategory')}
                    isChecked={currentFilter.businessDevelopmentCategory}
                    id="softSkillsCheckbox"
                    name="softSkillsCheckboxName"
                  />
                </div>
                {lcnEnabled && (
                  <>
                    <div className="iq4-manage-skills__filter-section--separator" />
                    <div className="iq4-manage-skills__filter-section--title">outcome type</div>
                    <div className="iq4-manage-skills__filter-section--options">
                      <span>Common</span>
                      <Checkbox
                        handleChange={(e) => updateFilter(e, 'outcomeTypeCommon')}
                        isChecked={currentFilter.outcomeTypeCommon}
                      />
                    </div>
                    <div className="iq4-manage-skills__filter-section--options">
                      <span>Hard</span>
                      <Checkbox
                        handleChange={(e) => updateFilter(e, 'outcomeTypeHard')}
                        isChecked={currentFilter.outcomeTypeHard}
                      />
                    </div>
                  </>
                )}
                {hasProfFilter && (
                  <>
                    <div className="iq4-manage-skills__filter-section--separator" />
                    <div
                      className="iq4-manage-skills__filter-section--title"
                      aria-label="Proficiency"
                    >
                      proficiency
                    </div>
                    <div className="iq4-manage-skills__filter-section--options">
                      <RatingStars value={1} max={1} size={RATING_STARS_SIZE.SMALL} readOnly />
                      <Checkbox
                        handleChange={(e) => updateFilter(e, 'proficiencyOne')}
                        isChecked={currentFilter.proficiencyOne}
                      />
                    </div>
                    <div className="iq4-manage-skills__filter-section--options">
                      <RatingStars value={2} max={2} size={RATING_STARS_SIZE.SMALL} readOnly />
                      <Checkbox
                        handleChange={(e) => updateFilter(e, 'proficiencyTwo')}
                        isChecked={currentFilter.proficiencyTwo}
                      />
                    </div>
                    <div className="iq4-manage-skills__filter-section--options">
                      <RatingStars value={3} max={3} size={RATING_STARS_SIZE.SMALL} readOnly />
                      <Checkbox
                        handleChange={(e) => updateFilter(e, 'proficiencyThree')}
                        isChecked={currentFilter.proficiencyThree}
                      />
                    </div>
                    <div className="iq4-manage-skills__filter-section--options">
                      <RatingStars value={4} max={4} size={RATING_STARS_SIZE.SMALL} readOnly />
                      <Checkbox
                        handleChange={(e) => updateFilter(e, 'proficiencyFour')}
                        isChecked={currentFilter.proficiencyFour}
                      />
                    </div>
                  </>
                )}
              </div>
              <div
                className="iq4-manage-skills__filter-section--title"
                style={{ marginTop: '1rem' }}
              >
                Verification
              </div>
              <div className="iq4-manage-skills__filter-section--options-container">
                <div className="iq4-manage-skills__filter-section--options">
                  <Checkbox
                    label="Verified"
                    handleChange={(e) => updateFilter(e, 'isVerified')}
                    isChecked={currentFilter.isVerified}
                    id="verifiedSkillsCheckbox"
                    name="verifiedSkillsCheckboxName"
                  />
                </div>

                <div className="iq4-manage-skills__filter-section--options">
                  <Checkbox
                    label="Self Attested"
                    handleChange={(e) => updateFilter(e, 'isSelfAttested')}
                    isChecked={currentFilter.isSelfAttested}
                    id="selfAttestedSkillsCheckbox"
                    name="selfAttestedSkillsCheckboxName"
                  />
                </div>
              </div>
              <button
                className="iq4-manage-skills__filter-section-description-btn iq4-manage-skills__filter-section-description-btn--desktop"
                onClick={() => void setShowFilterDescription(true)}
              >
                What do these filters mean?
              </button>

              <MyRecordsModal
                hideDismiss
                className="iq4-manage-skills__filter-section-description-modal"
                handleClose={() => void setShowFilterDescription(false)}
                isOpen={showFilterDescription}
                onDeactivation={() => {
                  setTimeout(() => {
                    const trigger = document.querySelector(
                      '.iq4-manage-skills__filter-section-description-btn',
                    );
                    if (trigger) {
                      trigger.focus();
                    }
                  }, 1);
                }}
              >
                {renderFilterDescriptions({ isModal: true })}
              </MyRecordsModal>
            </>
          )}{' '}
        </div>
      )}

      {isMobile &&
        isVisible &&
        ReactDOM.createPortal(
          <div className="iq4-manage-skills__filter-section--mobile">
            {showFilterDescription ? (
              renderFilterDescriptions()
            ) : (
              <>
                <div className="iq4-manage-skills__filter-section__header">
                  <button
                    className="iq4-manage-skills__filter-section__header-back-btn"
                    onClick={toggleVisibility}
                  >
                    <SimpleIcon name="arrow" />
                    Back
                  </button>
                </div>

                <div className="iq4-manage-skills__filter-section__filter-header">
                  <h2>Filters</h2>
                  <button
                    className="iq4-manage-skills__filter-section__header-clear-btn"
                    onClick={clearAllFilters}
                  >
                    Clear
                  </button>
                </div>

                {customFilters ? (
                  renderCustomFilters(customFilters)
                ) : (
                  <>
                    <div className="iq4-manage-skills__filter-section--title">category</div>
                    <div className="iq4-manage-skills__filter-section--options-container">
                      <label
                        className={`iq4-manage-skills__filter-section--options ${
                          currentFilter.technologyCategory
                            ? 'iq4-manage-skills__filter-section--options-active'
                            : ''
                        }`}
                        aria-label="IT Skills"
                      >
                        <span>{CATEGORY_LABELS['it_skills']}</span>
                        <Checkbox
                          aria-labelledby="IT Skills"
                          handleChange={(e) => updateFilter(e, 'technologyCategory')}
                          isChecked={currentFilter.technologyCategory}
                          key={currentFilter.technologyCategory}
                        />
                      </label>
                      <label
                        className={`iq4-manage-skills__filter-section--options ${
                          currentFilter.professionalSkillsCategory
                            ? 'iq4-manage-skills__filter-section--options-active'
                            : ''
                        }`}
                        aria-label="Professional Skills"
                      >
                        <span>{CATEGORY_LABELS['professional_skills']}</span>
                        <Checkbox
                          aria-labelledby="Professional Skills"
                          handleChange={(e) => updateFilter(e, 'professionalSkillsCategory')}
                          isChecked={currentFilter.professionalSkillsCategory}
                        />
                      </label>
                      <label
                        aria-label="Soft Skills"
                        className={`iq4-manage-skills__filter-section--options ${
                          currentFilter.businessDevelopmentCategory
                            ? 'iq4-manage-skills__filter-section--options-active'
                            : ''
                        }`}
                      >
                        <span>{CATEGORY_LABELS['soft_skills']}</span>
                        <Checkbox
                          aria-labelledby="Soft Skills"
                          handleChange={(e) => updateFilter(e, 'businessDevelopmentCategory')}
                          isChecked={currentFilter.businessDevelopmentCategory}
                        />
                      </label>

                      {hasProfFilter && (
                        <>
                          <div className="iq4-manage-skills__filter-section--title">
                            proficiency
                          </div>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyOne
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={1}
                              max={1}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyOne')}
                              isChecked={currentFilter.proficiencyOne}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyTwo
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={2}
                              max={2}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyTwo')}
                              isChecked={currentFilter.proficiencyTwo}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyThree
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={3}
                              max={3}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyThree')}
                              isChecked={currentFilter.proficiencyThree}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.proficiencyFour
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <RatingStars
                              value={4}
                              max={4}
                              size={RATING_STARS_SIZE.SMALL}
                              readOnly
                            />
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'proficiencyFour')}
                              isChecked={currentFilter.proficiencyFour}
                            />
                          </label>
                        </>
                      )}
                      {lcnEnabled && (
                        <>
                          <div className="iq4-manage-skills__filter-section--title">
                            outcome type
                          </div>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.outcomeTypeCommon
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <span>Common</span>
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'outcomeTypeCommon')}
                              isChecked={currentFilter.outcomeTypeCommon}
                              key={currentFilter.outcomeTypeCommon}
                            />
                          </label>
                          <label
                            className={`iq4-manage-skills__filter-section--options ${
                              currentFilter.outcomeTypeHard
                                ? 'iq4-manage-skills__filter-section--options-active'
                                : ''
                            }`}
                          >
                            <span>Hard</span>
                            <Checkbox
                              handleChange={(e) => updateFilter(e, 'outcomeTypeHard')}
                              isChecked={currentFilter.outcomeTypeHard}
                            />
                          </label>
                        </>
                      )}
                    </div>
                  </>
                )}

                <button
                  className="iq4-manage-skills__filter-section-description-btn"
                  onClick={() => void setShowFilterDescription(true)}
                >
                  What do these filters mean?
                </button>

                <div className="iq4-manage-skills__filter-section__footer">
                  <button onClick={toggleVisibility}>Submit</button>
                </div>
              </>
            )}
          </div>,
          document.getElementsByTagName('BODY')[0],
        )}
    </div>
  );
};
