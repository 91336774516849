import React from 'react';
import { SimpleIcon, ExternalLinkInterstitial } from 'common/components';
import { INTERSTITIAL_COPY } from 'common/constants/interstitialCopy';
import './SkillDescription.scss';

export const SkillDescription = ({ skill }) => {
  const { outcomeDescription, outcomeUrl } = skill;

  return (
    <div className="iq4-skill-description">
      {outcomeDescription && <p>{outcomeDescription}</p>}
      {outcomeUrl && (
        <p>
          <ExternalLinkInterstitial
            className="iq4-skill-description__read-more"
            linkLabel={
              <>
                Read more on Wikipedia
                <SimpleIcon name="openLink" className="iq4-pathway-details__link--icon" />
              </>
            }
            link={outcomeUrl}
            introText={INTERSTITIAL_COPY.WIKIPEDIA_INTRO_TEXT}
            mainText={INTERSTITIAL_COPY.WIKIPEDIA_MAIN_TEXT}
          />
        </p>
      )}
    </div>
  );
};
