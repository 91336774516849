import React from 'react';
import { SimpleIcon } from 'common/components';

/**
 * Component responsible to control the skill card expand state
 * @param isExpanded
 * @param id
 * @returns {*}
 * @constructor
 */
export const SkillCardExpander = ({ isExpanded, skill }) => (
  <button
    className="iq4-skill-card__expand-button"
    aria-label={
      isExpanded ? `Collapse ${skill.tier3Label} skill` : `Expand ${skill.tier3Label} skill`
    }
    aria-expanded={isExpanded}
    aria-controls={`iq4-skill-card__content--${skill?.competenceId}`}
  >
    <SimpleIcon
      className="iq4-skill-card__expand-button-icon"
      name={isExpanded ? 'caretUp' : 'caretDown'}
    />
  </button>
);
