import { createSelector } from '@reduxjs/toolkit';
import { isValidUrl } from 'common/utils';

export const selectCredentialsList = (state) => state.credentials.credentialsList;

export const selectIsFetchingCredentials = (state) => state.credentials.isFetchingCredentials;
export const selectHasFetchedCredentials = (state) => state.credentials.hasFetchedCredentials;

export const selectIsFetchingVerifiedCredentials = (state) =>
  state.credentials.isFetchingVerifiedCredentials;

export const selectIsParsingCredentials = (state) => state.credentials.isParsingCredentials;

export const selectIsParsingVerifiedCredential = (state) =>
  state.credentials.isParsingVerifiedCredential;

export const selectHasFetchedVerifiedCredentials = (state) =>
  state.credentials.hasFetchedVerifiedCredentials;

export const selectHasParsedCredentials = (state) => state.credentials.hasParsedCredentials;

export const selectIsAddingNewCredential = (state) => state.credentials.isAddingNewCredential;

export const selectIsSubmittingDispute = (state) => state.credentials.isSubmittingDispute;

export const selectNewCredentials = (state) => [];

export const selectInnerCurrentCredentials = (state) => state.credentials.credentialsList;

export const selectInnerVerifiedCredentials = (state) => state.credentials.verifiedCredentialsList;
export const selectVerifiedCredentialsStatus = (state) =>
  state.credentials.verifiedCredentialsStatus;
export const selectVerifiedCredentialsAnyAdded = (state) =>
  state.credentials.verifiedCredentialsAnyAdded;

export const selectVerifiedCredentials = createSelector(
  [selectInnerCurrentCredentials, selectInnerVerifiedCredentials],
  (currentCredentials, verifiedCredentials) => {
    const filteredVerifiedCredentials = verifiedCredentials?.filter((x) => {
      return !currentCredentials?.some(
        (y) =>
          y.description === x.description &&
          y.issuerName === x.issuerName &&
          y.label === x.name &&
          y.imageUrl === x.imageUrl,
      );
    });

    return filteredVerifiedCredentials;
  },
);

export const selectCredentialsForSharing = selectInnerCurrentCredentials;

export const selectedDisplayedCredentialsNotification = createSelector(
  (state) => state.credentials.displayedCredentialsNotification,
  (displayed) => displayed,
);

export const selectIsDisputingCredential = (state) => state.credentials.isDisputingCredential;
