import React from 'react';
import PropTypes from 'prop-types';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ActionIcon } from 'common/components/ActionIcon';
import { SimpleIcon } from 'common/components/SimpleIcon';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import './Modal.scss';
import FocusLock from 'react-focus-lock';

/**
 * iq4 Custom Modal
 * @param {boolean} isOpen
 * @param {function} handleClose
 * @param {boolean} [hideDismiss]
 * @param {ReactNode} children
 * @param {*} [title]
 * @param {string} [variation] - modal variation, currently supports only "1" & "2"
 * @param {object[]} [navList] - list of the bottom navigation: [{id: String|number, label: String}, ...]
 * @param {number} [currentNavState] - optional, only required when navList is set. This is the index of the current nav state
 * @param {function} [onNavChange] - optional, only required when navList is set. Notify parent of a nav change
 * @param {string} [className] - custom className
 * @param {boolean} [useFocusLock] - whether to use FocusLock HOC
 */

export const Modal = ({
  isOpen,
  handleClose,
  hideDismiss,
  children,
  title,
  variation = '1',
  navList = [],
  currentNavState,
  onNavChange,
  className,
  onDeactivation = () => {},
  useFocusLock = true,
}) => {
  const breakpoint = useBreakpoints();

  // determine where to append the modal
  const handleModalContainer = () => {
    const mainEl = document.getElementsByTagName('main')[0];

    // fallback to body
    if (!mainEl) return document.body;

    return mainEl;
  };

  const ModalContent = (
    <div className="iq4-modal__children-container">
      {title && (
        <h2 className="iq4-modal__title" id="iq4-modal__title">
          {title}
        </h2>
      )}
      <div className="iq4-modal__children" id="iq4-modal-children-describedby">
        {children}
      </div>
      {!hideDismiss && (
        <ActionIcon
          className="iq4-modal__dismiss-button"
          iconName="close"
          buttonClick={handleClose}
          buttonLabel="Close modal"
        />
      )}
    </div>
  );

  return (
    <MUIDialog
      container={handleModalContainer}
      fullScreen={breakpoint === 'xs'}
      disableBackdropClick
      open={isOpen}
      onClose={handleClose}
      classes={{
        root: `iq4-modal ${variation ? `iq4-modal--${variation}` : ''} ${
          className ? className : ''
        }`,
        paper: `iq4-modal__paper ${navList.length ? 'iq4-modal__paper--with-nav' : ''}`,
      }}
      maxWidth="md"
      fullWidth={true}
      BackdropProps={{
        classes: {
          root: 'iq4-modal__backdrop',
        },
      }}
      aria-labelledby="iq4-modal__title"
      aria-describedby="iq4-modal-children-describedby"
    >
      {useFocusLock ? (
        <FocusLock returnFocus={true} onDeactivation={onDeactivation}>
          {ModalContent}
        </FocusLock>
      ) : (
        ModalContent
      )}

      {!!navList.length && (
        <div className="iq4-modal__bottom_nav-arrows">
          {navList[currentNavState - 1] && (
            <button
              className="iq4-modal__bottom_nav-arrows-left"
              onClick={() =>
                void onNavChange('prev', {
                  currentNavState,
                  navItemClicked: navList[currentNavState - 1],
                })
              }
            >
              <div className="iq4-modal__bottom_nav-arrow">
                <SimpleIcon name="arrow" />
              </div>
              <div className="iq4-modal__bottom_nav-label">
                View <strong>{navList[currentNavState - 1].label}</strong>
              </div>
            </button>
          )}
          {navList[currentNavState + 1] && (
            <button
              className="iq4-modal__bottom_nav-arrows-right"
              onClick={() =>
                void onNavChange('next', {
                  currentNavState,
                  navItemClicked: navList[currentNavState + 1],
                })
              }
            >
              <div className="iq4-modal__bottom_nav-label">
                View <strong>{navList[currentNavState + 1].label}</strong>
              </div>
              <div className="iq4-modal__bottom_nav-arrow">
                <SimpleIcon name="arrow" />
              </div>
            </button>
          )}
        </div>
      )}
    </MUIDialog>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hideDismiss: PropTypes.bool,
  useFocusLock: PropTypes.bool,
};

Modal.defaultProps = {
  hideDismiss: false,
  useFocusLock: true,
};
