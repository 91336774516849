import React, { useRef, forwardRef, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import _orderBy from 'lodash/orderBy';
import 'common/components/Pathways/BrowseRoles/BrowseRolesInput/BrowseRolesInput.scss';

export const SuggestedRolesSelect = forwardRef(({ suggestedRoles = [], onRoleClick }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    openPopper: () => setOpen(true),
    closePopper: () => setOpen(false),
  }));

  const onSelectRole = (_, opt) => {
    if (!opt) {
      return;
    }
    const role = suggestedRoles.find((item) => item.id === opt.id);
    onRoleClick(role);
  };

  return (
    <div className="iq4-select__wrapper iq4-categories">
      <div className="iq4-select-label__wrapper">
        <label htmlFor="jobsFamilySearch" className="iq4-categories__label">
          SUGGESTED ROLES
        </label>
      </div>
      <FormControl
        variant="outlined"
        className="iq4-select__form-control iq4-select__form-control-max-width"
      >
        <Autocomplete
          disablePortal
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={_orderBy(suggestedRoles, 'label', 'asc')}
          onChange={onSelectRole}
          onInputChange={(event, value, reason) => {
            if (reason === 'select-option') {
              event.target.value = '';
            }
          }}
          popupIcon={<KeyboardArrowDownIcon />}
          classes={{
            input: 'iq4-select__input iq4-tour-suggest',
            root: 'iq4-search-roles__autocomplete',
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              InputProps={{
                'aria-label': 'Search for roles. E.g. "Programmers"',
              }}
              {...params}
              variant="outlined"
              inputRef={ref}
              placeholder={`(${suggestedRoles.length}) View All`}
            />
          )}
          getOptionLabel={(option) => option.label}
        />
      </FormControl>
    </div>
  );
});
