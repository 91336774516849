import React from 'react';
import { useSelector } from 'react-redux';
import { Button, SimpleIcon, ExternalLinkInterstitial, CredentialPill } from 'common/components';
import { PathwaysPanelTitle } from '../PathwaysPanelTitle';
import { PathwaysPanelHeader } from '../PathwaysPanelHeader';
import { RoleCourseInnerCard } from '../RoleCourseInnerCard';
import { selectFeatures } from 'store/features/config/selectors';
import { selectIsSkillSelectedById } from 'common/store/features/skills/selectors';
import { INTERSTITIAL_COPY } from 'common/constants/interstitialCopy';
import './RoleIndividualSkillInfoPanel.scss';

/**
 * Info panel on an individual skill
 * @param {object} skill - Skill object
 * @param {object[]} achievements - The available achievements for this skill
 * @param {string} [className] - Custom className
 * @param {function} onBackClick - Handle clicking the header back
 
 * @param {function} onAdd - Handle adding skill to pathway
 * @param {function} [onCourseClick] - Handle clicking course
 */
export const RoleIndividualSkillInfoPanel = ({
  skill,
  achievements,
  className,
  onBackClick,
  onAdd,
  onRemove,
  onCourseClick = () => {},
  readOnly = false,
}) => {
  const { selfAttestedEnabled } = useSelector(selectFeatures);
  const isSkillSelected = useSelector((state) => selectIsSkillSelectedById(state, skill.id));

  const isVerified = skill?.sources?.filter((source) => source.verifiedBy).length > 0;
  const isPublic = window.location.href.indexOf('/user/') >= 0;

  return (
    <div className={`iq4-role-individual-skill-info-panel ${className ? className : ''}`}>
      <PathwaysPanelHeader
        className="iq4-role-individual-skill-info-panel__header"
        title={skill.value}
        onLeftClick={onBackClick}
      />
      {isVerified && (
        <CredentialPill credential={{ ...skill, verifiedBy: 'NSC' }} displayVerifier={true} />
      )}

      {skill.description && (
        <>
          <p className="iq4-role-individual-skill-info-panel__description">{skill.description}</p>
          {skill.outcomeDescriptionUrl && (
            <p className="iq4-role-individual-skill-info-panel__description">
              <ExternalLinkInterstitial
                className="iq4-skill-description__read-more"
                linkLabel={
                  <>
                    Read more on Wikipedia
                    <SimpleIcon name="openLink" className="iq4-pathway-details__link--icon" />
                  </>
                }
                link={skill.outcomeDescriptionUrl}
                introText={INTERSTITIAL_COPY.WIKIPEDIA_INTRO_TEXT}
                mainText={INTERSTITIAL_COPY.WIKIPEDIA_MAIN_TEXT}
              />
            </p>
          )}
        </>
      )}

      {!skill.description && skill.tier3?.description && (
        <>
          <p className="iq4-role-individual-skill-info-panel__description">
            {skill.tier3?.description}
          </p>
        </>
      )}

      {selfAttestedEnabled && isSkillSelected && !readOnly && !isVerified && !isPublic && (
        <>
          <div className="iq4-skill-card__skill-description">{skill?.tier3Description}</div>
          <Button
            variation="clear"
            className="iq4-role-individual-skill-info-panel__skill-remove-btn"
            onClick={() => onRemove(skill)}
          >
            Remove {skill.value} from your Skills Profile
          </Button>
        </>
      )}

      {selfAttestedEnabled && !isSkillSelected && !readOnly && !isPublic && (
        <>
          <Button
            variation="clear"
            className="iq4-role-individual-skill-info-panel__skill-add-btn"
            onClick={() => onAdd(skill)}
          >
            Add {skill.value} to your Skills Profile
          </Button>
        </>
      )}

      {!isSkillSelected && (!achievements || achievements.length === 0) && (
        <p>No learning resources found for this skill</p>
      )}
      {achievements && achievements.length > 0 && (
        <>
          <PathwaysPanelTitle
            className="iq4-role-individual-skill-info-panel__courses-title"
            title="Learning Resources"
            subTitle={`${
              !achievements || achievements.length === 0
                ? `No learning resources found for this skill`
                : `To get educational support for this skill have a look at these available programmes:`
            }`}
          />

          <div className="iq4-role-individual-skill-info-panel__courses-container">
            {achievements &&
              achievements.map((achievement) => (
                <RoleCourseInnerCard
                  className="iq4-role-individual-skill-info-panel__course-card"
                  achievementName={achievement?.name || achievement?.achievementName}
                  achievementId={achievement?.achievementId}
                  learnMoreUrl={achievement?.learnMoreUrl}
                  school={achievement?.school?.name || achievement?.school}
                  onCourseClick={(achievement) => onCourseClick(achievement)}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
