import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { selectSkillSources } from 'common/store/features/skills/selectors';
import { updateSkillSourceReq } from 'common/store/features/skills/skillsSlice';
import { Checkbox } from 'common/components/Form/fieldTypes/Checkbox';
import { Button, SimpleIcon, CredentialPill } from 'common/components';
import { selectIsUISubmittingForm } from 'common/store/features/ui/selectors';
import { setIsSubmittingForm } from 'common/store/features/ui/uiSlice';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';

import './SkillSourceAdd.scss';

export const SkillSourceAdd = ({ skill, onComplete, onAddSourceComplete }) => {
  const dispatch = useDispatch();
  const originalSources = useSelector((state) => selectSkillSources(state, skill));
  const [sources, setSources] = useState(originalSources);
  const isSubmittingForm = useSelector(selectIsUISubmittingForm);

  useEffect(() => {
    const sourcesToFilterBy = skill.sources.filter((x) => x.type !== 'resume');

    if (sourcesToFilterBy?.length) {
      sourcesToFilterBy.forEach((skillSource) => {
        const skillSourceType = skillSource.type;
        const sourceToBeSelected = sources[skillSourceType]?.find(
          (x) => x.id === skillSourceType.id,
        );

        if (sourceToBeSelected) {
          sourceToBeSelected.associated = true;

          setSources({
            ...sources,
            [skillSourceType]: [...sources[skillSourceType]].map((item) => {
              return item.id === sourceToBeSelected?.id
                ? {
                    ...item,
                    associated: true,
                  }
                : item;
            }),
          });
        }
      });
    }
  }, [skill]);

  const handleSelectItem = (sourceType, id) => {
    setSources({
      ...sources,
      [sourceType]: sources[sourceType].map((item) => {
        return item.id === id
          ? {
              ...item,
              associated: !item.associated,
            }
          : item;
      }),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sourceToAdd = [];
    const sourceToDelete = [];
    const sourceTypes = Object.keys(sources);

    sourceTypes.forEach((sourceType) => {
      // loop through each sourceType, i.e. 'education', 'experience', 'portfolio'
      sourceToAdd.push(
        // add items that are newly 'added'
        ...sources[sourceType]
          .filter(
            (item) =>
              item.associated &&
              !originalSources[sourceType].find((originalItem) => originalItem.id === item.id)
                .associated,
          )
          .map((item) => ({
            id: item.id,
            type: sourceType,
          })),
      );

      sourceToDelete.push(
        // add items that are newly 'deleted'
        ...sources[sourceType]
          .filter(
            (item) =>
              !item.associated &&
              originalSources[sourceType].find((originalItem) => originalItem.id === item.id)
                .associated,
          )
          .map((item) => ({
            id: item.id,
            type: sourceType,
          })),
      );
    });

    await dispatch(
      updateSkillSourceReq({
        skill,
        sourceToAdd,
        sourceToDelete,
        originalSources,
      }),
    );

    onComplete();

    onAddSourceComplete && onAddSourceComplete();
    dispatch(setIsSubmittingForm(false));
  };

  const renderEmptyState = () => (
    <div className="iq4-skill-source-add__empty">
      <p>There are no sources to add.</p>
    </div>
  );

  const Sources = () => (
    <form>
      <p>Select the items you would like to add:</p>

      <ul className="iq4-skill-source-add__outer-list">
        {Object.keys(sources).map((sourceType, index) => {
          const items = sources[sourceType];
          const isExperience = sourceType === 'experience';
          const isCredential = sourceType === 'credentials';

          if (sourceType === 'education' && !items?.filter((item) => !item.verifiedBy)?.length) {
            return null;
          }

          return (
            <li key={index} className="iq4-skill-source-add__outer-list-item">
              <div className="iq4-skill-source-add__outer-list-item__label">
                <SimpleIcon
                  className="iq4-skill-source-add__outer-list-item__icon"
                  name={`${sourceType}Circle`}
                  role="presentation"
                />
                {sourceType}
              </div>

              <ul className="iq4-skill-source-add__inner-list">
                {items
                  .filter((item) => !item.verifiedBy)
                  .map((item) => {
                    const labelToDisplay =
                      !item.title && isExperience
                        ? item.company
                        : isCredential
                        ? item.label || item.name
                        : item.title;
                    return (
                      <li className="iq4-skill-source-add__inner-list-item">
                        <div className="iq4-skill-source-add__inner-list-item__label">
                          {labelToDisplay}
                          {!!item.verifiedBy && (
                            <div className="iq4-skill-source-add__inner-list-item__label-verified">
                              <CredentialPill credential={{ ...item, verifiedBy: 'BADGR' }} />
                            </div>
                          )}
                          {item.isVerified && (
                            <div className="iq4-skill-source__verified-icon">
                              <Verified />
                              <span></span>
                            </div>
                          )}
                        </div>
                        <div className="iq4-skill-source-add__inner-list-item__input">
                          <Checkbox
                            ariaLabel={`Select ${labelToDisplay} to add as a source to your skill`}
                            id={item.id}
                            isChecked={item.associated}
                            isDisabled={item.isVerified}
                            handleChange={() => handleSelectItem(sourceType, item.id)}
                          />
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </li>
          );
        })}
      </ul>

      <div className="iq4-skill-source-add__button-container">
        <Button
          className={`iq4-skill-source-add__button-submit ${
            isSubmittingForm ? 'iq4-skill-source-add__button-submit--loading' : ''
          }`}
          type="submit"
          onClick={handleSubmit}
        >
          Save
        </Button>
        {isSubmittingForm && (
          <CircularProgress size={24} className="iq4-skill-source-add__progress__indicator" />
        )}
      </div>
    </form>
  );

  return (
    <>
      <div className="iq4-skill-source-add">
        {!Object.keys(sources).length && renderEmptyState()}
        {!!Object.keys(sources).length && <Sources />}
      </div>
    </>
  );
};
