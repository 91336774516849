import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectRecommendedSkills } from 'common/store/features/skills/selectors';
import {
  fetchRecommendedSkillsReq,
  addRecommendedSkillReq,
} from 'common/store/features/skills/skillsSlice';

import { Expand, RecommendedSkillsList } from 'common/components';
import './RecommendedSkillsPanel.scss';
import { TipBlock } from 'common/components/TipBlock';

export const RecommendedSkillsPanel = ({ addedSkills }) => {
  const recommendedSkill = useSelector(selectRecommendedSkills);
  const dispatch = useDispatch();
  const handleSkillAdd = (newSkill) => {
    dispatch(addRecommendedSkillReq(newSkill));
  };

  // get recommeded skills on mount only
  useEffect(() => {
    dispatch(fetchRecommendedSkillsReq());
  }, [dispatch]);

  return (
    <Expand
      expandByDefault
      isOuterCard
      className="iq4-recommended-skills-panel"
      title={
        <div className="iq4-recommended-skills-panel__title">
          RECOMMENDED SKILLS
          <span className="iq4-recommended-skills-panel__title-skill-count">
            {recommendedSkill.length}
          </span>
        </div>
      }
      uniqueId="recommended-skills"
      label="Recommended Skills"
    >
      <p className="iq4-recommended-skills-panel__p1">
        When it comes to personalizing your skills we have your back!
      </p>
      <p className="iq4-recommended-skills-panel__p2">
        To enhance your profile we recommend you add these new skills into your manage skills card:
      </p>

      <RecommendedSkillsList
        className="iq4-recommended-skills-panel__skills-list"
        onAdd={handleSkillAdd}
        skills={recommendedSkill}
      />
      {addedSkills === 0 && recommendedSkill.length === 0 && (
        <TipBlock
          title="How do I get recommended skills?"
          description="Recommended skills are normally based off other skills in your list. To receive recommended
      skills start adding skills to your list using the search and add feature above. The more
      skills you have, the more we can recommend."
        />
      )}
    </Expand>
  );
};
