import React from 'react';
import './TShapeV2Box.scss';

/**
 * Individual box that goes on the stack on the tshape graph
 * @param {string} type - The type of the box to create 'FILLED'|'LINES'|'FILLED-LINES'
 * @param {string} [className] - Custom className
 */
export const TShapeV2Box = ({ type, className }) => {
  const renderLinesBox = () => {
    return (
      <div className="iq4-tshape-v2-box__lined-box">
        <div className="iq4-tshape-v2-box__lined-box-line"></div>
        <div className="iq4-tshape-v2-box__lined-box-line"></div>
        <div className="iq4-tshape-v2-box__lined-box-line"></div>
        <div className="iq4-tshape-v2-box__lined-box-line"></div>
        <div className="iq4-tshape-v2-box__lined-box-line"></div>
      </div>
    );
  };

  return (
    <div className={`iq4-tshape-v2-box iq4-tshape-v2-box--${type} ${className ? className : ''}`}>
      {(type === 'LINES' || type === 'FILLED_LINES') && renderLinesBox()}
    </div>
  );
};
