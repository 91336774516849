import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Expand, TopSkillsHelp } from 'common/components';
import { selectOrderedTop10Skills } from 'common/store/features/skills/selectors';
import { reorderSkillsReq, replaceSkillReq } from 'common/store/features/skills/skillsSlice';
import { TopSkillsList } from './TopSkillsList';
import './Top10.scss';

/**
 * Top 10 Skills expand panel
 */
export const Top10 = ({ expandable = true, expandByDefault = false }) => {
  const dispatch = useDispatch();
  const skills = useSelector(selectOrderedTop10Skills);

  const handleReorder = (skills) => {
    dispatch(reorderSkillsReq({ skills }));
  };

  const handleReplace = ({ replaceSkill, replaceWithSkill }) => {
    dispatch(replaceSkillReq({ replaceSkill, replaceWithSkill }));
  };

  return (
    <Expand
      title="TOP 10 SKILLS"
      isOuterCard
      className="iq4-top-10-panel"
      uniqueId="top-10"
      label="Top 10 Skills"
      expandable={expandable}
      expandByDefault={expandByDefault}
      titleHelp={() => <TopSkillsHelp />}
    >
      <div className="iq4-top-10-panel__container">
        <p className="iq4-top-10-panel__title">
          You are showcasing{' '}
          <strong>
            {skills.length > 10 ? 10 : skills.length} skill{skills.length === 1 ? '' : 's'}
          </strong>{' '}
          in your Top 10. Add the rest to really showcase your abilities.
        </p>

        <TopSkillsList
          skills={skills}
          onReorder={handleReorder}
          onReplaceSkill={handleReplace}
          className="iq4-top-10-panel__skills-list"
        />
      </div>
    </Expand>
  );
};
