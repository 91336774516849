import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectResumeParsedSkills } from 'common/store/features/skills/selectors';
import { Modal } from 'common/components';
import { ResumeParserWizard } from '../ResumeParserWizard';

import './ResumeParserModal.scss';

export const ResumeParserModal = ({ children, onSave, ...props }) => {
  const parsedResumeSkills = useSelector(selectResumeParsedSkills); // get from selector
  const [currentSection, setCurrentSection] = useState('');

  return (
    <Modal className="iq4-resume-parser-modal" {...props}>
      <div className="iq4-resume-parser-modal__header">
        {currentSection === 'RESUME_UPLOAD' && (
          <>
            <h1>Upload your resume</h1>
          </>
        )}

        {currentSection === 'SKILLS_CHECK' && (
          <>
            <h1>Your skills</h1>
            <p>
              We've found <strong>{parsedResumeSkills.length} new skills</strong> from your resume
            </p>
          </>
        )}

        {currentSection === 'ASSESSMENTS' && (
          <>
            <h1>Skill assessment</h1>
            <p>Self assess your skills to build your T-shape tool.</p>
          </>
        )}
      </div>
      <ResumeParserWizard
        onSave={onSave}
        onSectionChange={(section) => void setCurrentSection(section)}
      />
    </Modal>
  );
};
