import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleIcon, MenuButton, TriggerModal, FormWrapper, PromptModal } from 'common/components';
import { TruncateText } from 'common/components/TruncateText';
import { AudioPlayer } from 'common/components/AudioPlayer';
import CompetenciesList from 'components/Profile/CompetenciesList';
import { ProfileThumbnailUpload } from 'components/Profile/ProfileThumbnailUpload';
import { usePortfolioItemImageFormDefinition } from 'common/hooks/forms';
import { portfolioFormDefinitions, PROFILE_TYPE } from 'common/utils/constants';
import {
  selectProfile,
  selectFeaturedPortfolioItems,
  selectIsPublicProfile,
} from 'common/store/features/profile/selectors';
import {
  removeProfileSection,
  updateProfileSection,
} from 'common/store/features/profile/profileSlice';
import { ensurePortfolioFeaturedItem } from 'common/store/features/profile/profileSlice';
import { sendRequest, sendFormDataRequest } from 'common/utils';
import { PROFILE_PORTFOLIO_URL } from 'common/constants/endpoints';
import './PortfolioItem.scss';
import { fetchAllSkillsReq } from 'common/store/features/skills/skillsSlice';

/**
 * A Detailed Portfolio Item Component (Work in progress full designs for each variation)
 *
 * @param type - Type of portfolio item, e.g. video, link etc
 * @param title - Portfolio item title
 * @param description - Portfolio body copy
 * @param competencies - array of competencies associated with Porfolio item
 */

export const PortfolioItem = ({ item, setEditedItem }) => {
  const {
    type,
    title,
    file,
    url,
    description,
    competencies,
    associatedRecord,
    hasBorder,
    featured,
    resources,
  } = item;
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [isHoverActive, setIsHoverActive] = useState(false);
  const { portfolio } = useSelector(selectProfile);
  const featuredItems = useSelector(selectFeaturedPortfolioItems);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const dispatch = useDispatch();
  const audioEl = createRef();

  const uaf = (item) => (
    <span>
      {item.featured ? 'Unset ' : 'Use As '}
      <span className="iq4-portfolio-item__use-as">Featured</span>
    </span>
  );

  let path;
  switch (type) {
    case 'other':
      path = url;
      break;
    case 'video':
      path = url;
      break;
    case 'image':
    case 'document':
      path = resources?.find((x) => x.type === 'file')?.fileS3Url;
      break;
    default:
      break;
  }

  const transformAssociatedRecord = (item) => ({
    ...item,
    ...(item.associatedRecord && {
      associatedRecord: {
        id: item.associatedRecord.id,
        type: item.associatedRecord.type,
      },
    }),
  });

  const toggleFeatured = (item) => {
    dispatch(ensurePortfolioFeaturedItem({ item, featuredItems, portfolio }));

    return sendFormDataRequest({
      url: `${PROFILE_PORTFOLIO_URL}/${item.id}`,
      verb: 'PUT',
      data: {
        ...item,
        featured: !item.featured,
      },
    }).then((resp) =>
      dispatch(updateProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: resp })),
    );
  };

  const menuItems = [
    {
      label: 'Edit',
      action: () => setEditedItem(item),
    },
    ...(portfolio.length > 1 // NOTE: If there is only one portfolio item, user cannot unset featured
      ? [
          {
            label: uaf(item),
            action: () => toggleFeatured(item),
          },
        ]
      : []),
    {
      label: 'Delete',
      action: () => setIsPromptActive(true),
    },
  ];

  const handleDeleteItem = () => {
    dispatch(ensurePortfolioFeaturedItem({ item, featuredItems, portfolio }));

    return sendRequest({ url: `${PROFILE_PORTFOLIO_URL}/${item.id}`, verb: 'DELETE' })
      .then(() => {
        dispatch(removeProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: item.id }));
        dispatch(fetchAllSkillsReq());
        setIsPromptActive(false);
      })
      .catch((err) => {
        // TODO: John to confirm error display
        console.log(err);
      });
  };

  const portfolioAction = () => {
    if (type === 'audio') {
      // TODO: play audio
      audioEl.current.play();
    } else {
      window.open(path, '_blank');
    }
  };

  function generatePublicCompetencies(competencies) {
    return competencies.map((competency, i) => ({
      id: competency.id,
      value: competency.skillTier3?.label,
    }));
  }

  return (
    <div className={`iq4-portfolio-item ${hasBorder ? 'iq4-portfolio-item--has-border' : ''}`}>
      <PromptModal
        hideDismiss
        isOpen={isPromptActive}
        handleClose={() => setIsPromptActive(false)}
        title="Are you sure you want to delete this?"
        buttonLabel="Delete"
        buttonAction={handleDeleteItem}
      />
      <div className="iq4-portfolio-item__image-wrapper">
        <ProfileThumbnailUpload
          item={item}
          type="Portfolio Item"
          formDefinition={portfolioFormDefinitions[type]}
          imageFormDefinition={usePortfolioItemImageFormDefinition}
          isLarge={true}
        />
        {featured && <span className="iq4-portfolio-item__featured-tag">Featured</span>}
        {path && isHoverActive && type !== 'document' && type !== 'image' && (
          <span className="iq4-portfolio-item__path-tag">{path}</span>
        )}
      </div>
      <div className="iq4-portfolio-item__content-wrapper">
        <div className="iq4-portfolio-item__heading-wrapper">
          {!isPublicProfile && <MenuButton items={menuItems} />}
        </div>
        <div
          role="button"
          className="iq4-portfolio-item__title"
          onClick={isPublicProfile ? null : portfolioAction}
          onMouseEnter={() => setIsHoverActive(true)}
          onMouseLeave={() => setIsHoverActive(false)}
          tabIndex="0"
        >
          <SimpleIcon
            className="iq4-portfolio-item__type-icon"
            name={type !== 'other' ? type : 'link'}
          />
          {title}
          <SimpleIcon className="iq4-portfolio-item__title-click-icon" name="arrow" />
        </div>

        {description && (
          <div className="iq4-portfolio-item__description">
            <TruncateText text={description} min={230} ideal={250} max={270} />
          </div>
        )}

        {type === 'audio' && file && (
          <div className="iq4-portfolio-item__audio">
            <AudioPlayer src={file} ref={audioEl} />
          </div>
        )}

        <CompetenciesList
          competencies={isPublicProfile ? generatePublicCompetencies(competencies) : competencies}
        />
        {associatedRecord && (
          <p>
            Project completed in:{' '}
            <strong>{associatedRecord.title || associatedRecord.major}</strong>
          </p>
        )}
      </div>
    </div>
  );
};

PortfolioItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  associatedRecord: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  }),
};

PortfolioItem.defaultProps = {
  associatedRecord: {},
  description: '',
  url: null,
};

export default PortfolioItem;
