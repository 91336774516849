import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import FormControl from '@material-ui/core/FormControl';
import { useFormContext, Controller } from 'react-hook-form';
import { ActionIcon } from 'common/components/ActionIcon';
import { ValidationMessage } from '../../../ValidationMessage';
import { Tooltip } from 'common/components/Tooltip';
import './AutoComplete.scss';

const Tag = ({ label, onDelete, ...props }) => (
  <div className="iq4-autocomplete__tag" {...props}>
    <span>{label}</span>
    <ActionIcon
      className="iq4-autocomplete__tag-remove"
      iconName="close"
      buttonClick={onDelete}
      buttonLabel={`Remove ${label} tag`}
    />
  </div>
);

/**
 * Inner Select that gets enhanced by RHF's HOC
 * @param id
 * @param name
 * @param label
 * @param placeholder
 * @param onChange
 * @param errors
 * @param formState
 * @param options
 * @param optionLabel
 * @param value
 * @returns {*}
 * @constructor
 */
export const InnerAutoComplete = ({
  id,
  name,
  label,
  placeholder,
  onChange,
  errors,
  formState,
  options,
  optionLabel,
  onInputChange,
  value,
  tooltip,
  tooltipVariation,
  isDisabled,
  isMandatory,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const touched = formState?.touched[name];
  const hasError = errors[name];
  const isSubmitted = formState?.isSubmitted;
  const showError = (touched || isSubmitted) && hasError;

  const isRequiredField = !!isMandatory;

  useEffect(() => {
    if (value !== undefined && value !== '') {
      setSelectedValues(value);
    } else {
      setSelectedValues([]);
    }
  }, [value]);

  const handleChange = (e, values) => {
    setSelectedValues(values);
    onChange(values);
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    setAnchorEl,
  } = useAutocomplete({
    id: id || name,
    multiple: true,
    value,
    options,
    filterSelectedOptions: true,
    getOptionLabel: (option) => option[optionLabel],
    onChange: handleChange,
    onInputChange: onInputChange,
  });

  return (
    <div className="iq4-autocomplete__wrapper" {...getRootProps()} role={null} aria-expanded={null}>
      {label && (
        <label className="iq4-autocomplete__label" id="iq4-autocomplete" {...getInputLabelProps()}>
          {label}
          {isRequiredField && (
            <>
              <span className="iq4-field__required-mark" aria-hidden="true">
                *
              </span>
              <span className="sr-only">Required field</span>
            </>
          )}
          {tooltip && <Tooltip message={tooltip} variation={tooltipVariation} />}
        </label>
      )}

      <FormControl
        variant="outlined"
        className={`iq4-autocomplete__form-control ${
          showError ? 'iq4-autocomplete__form-control--error' : ''
        }`}
      >
        <div
          className={`iq4-autocomplete__input_wrapper ${
            isDisabled
              ? 'iq4-autocomplete__input_wrapper--disabled'
              : !!groupedOptions.length
              ? 'iq4-autocomplete__input_wrapper--active'
              : ''
          }`}
          ref={setAnchorEl}
        >
          {selectedValues.map((option, index) => (
            <Tag label={option[optionLabel]} {...getTagProps({ index })} />
          ))}

          <input
            name={name}
            aria-labelledby="iq4-autocomplete"
            placeholder={selectedValues.length === 0 ? placeholder : ''}
            {...getInputProps()}
            aria-label={`Edit text to search for available options.`}
            aria-expanded={groupedOptions.length > 0 ? true : false}
            aria-owns={`iq4-autocomplete-${name}`}
            aria-controls={`iq4-autocomplete-${name}`}
            aria-autocomplete="list"
            autocomplete="off"
            role="combobox"
            disabled={isDisabled}
            id={id}
          />
        </div>
      </FormControl>

      <ul
        className="iq4-autocomplete__listbox"
        {...getListboxProps()}
        id={`iq4-autocomplete-${name}`}
        role="listbox"
      >
        {groupedOptions.map((option, index) => (
          <li {...getOptionProps({ option, index })}>
            <span>{option[optionLabel]}</span>
          </li>
        ))}
      </ul>

      {hasError && showError && (
        <ValidationMessage
          id={`${name}Error`}
          message={<FormattedMessage id="form.validation.required" />}
        />
      )}
    </div>
  );
};

/**
 * AutoComplete wrapper
 * This needs to use the Controller component from react-hook-form
 * see: https://react-hook-form.com/api#Controller
 * @param name
 * @param validators
 * @param options
 * @param optionLabel
 * @param props
 * @param tooltip
 * @param tooltipVariation
 * @returns {*}
 * @constructor
 */
export const AutoComplete = ({
  name,
  validators,
  options,
  optionLabel,
  onInputChange,
  tooltip,
  tooltipVariation,
  onValueSelect,
  ...props
}) => {
  const { control, getValues } = useFormContext();
  const values = getValues();

  return (
    <Controller
      as={<InnerAutoComplete {...props} />}
      control={control}
      rules={{ ...validators }}
      options={options}
      optionLabel={optionLabel}
      onInputChange={onInputChange}
      onChange={([value]) => {
        onValueSelect && onValueSelect(value);
        return value;
      }}
      name={name}
      value={values[name]}
      tooltip={tooltip}
      tooltipVariation={tooltipVariation}
      validators={validators}
    />
  );
};
