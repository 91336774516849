import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  AtAGlance,
  ActionCard,
  TopSkillsCard,
  LoadingSkeleton,
  SimpleIcon,
  SearchRoles,
  BrowseRolesInput,
  RolesSearchBar,
  Pill,
} from 'common/components';
import './ProfileSkillsSummary.scss';
import { Expand, Drawer, RoleIndividualSkillInfoPanel } from 'common/components';
import { getMySkillsTShapeData } from 'common/utils/utilities';
import {
  selectHasFetchedAllSkills,
  selectMySkills,
  selectOrderedSkills,
  selectOrderedTop10Skills,
  selectSkillsBreakUp,
  selectTopSkills,
} from 'common/store/features/skills/selectors';
import {
  selectSelectedPathwayRoles,
  selectSelectedPathwaysSpokes,
} from 'common/store/features/pathways/selectors';
import {
  setProfileWorkRoles,
  setSelectedJobFamily,
  setSelectedTShapeRole,
} from 'common/store/features/profile/profileSlice';
import {
  fetchRecommendedRolesReq,
  fetchUserSelectedWorkRolesReq,
} from 'common/store/features/pathways/pathwaysSlice';
import { selectJobFamilies } from 'common/store/features/framework/selectors';
import {
  fetchJobFamiliesReq,
  setSelectedJobFamilyById,
} from 'common/store/features/framework/frameworkSlice';
import {
  addSkillFromWorkRoleReq,
  fetchAllSkillsReq,
  fetchTopSkills,
  removeSkillFromWorkRoleReq,
} from 'common/store/features/skills/skillsSlice';
import { fetchTShapeGraphicData, fetchTShapeSkillInfo } from 'common/store/features/t/slice';
import { selectTShapeData, selectTShapeSelectedSkill } from 'common/store/features/t/selectors';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { ROUTES } from 'routes/routeNames';
import { TShapeV2 } from 'common/components/TShapeV2';
import { selectFeatures } from 'store/features/config/selectors';
import { TShapePage } from 'pages';
import { SelectWorkRoles } from 'common/components/SelectWorkRoles';
import { TextField } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useRolesSearch } from 'common/hooks/useRolesSearch';
import {
  selectIsPublicProfile,
  selectProfileWorkRoles,
  selectSelectedJobFamily,
  selectSelectedTShapeRole,
} from 'common/store/features/profile/selectors';
import FocusLock, { AutoFocusInside } from 'react-focus-lock';
import { CATEGORY_LABELS } from '../../../common/utils';

export const ProfileSkillsSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const breakpoint = useBreakpoints();

  const selectedWorkRoles = useSelector(selectSelectedPathwayRoles);
  const tShapeGraphicData = useSelector(selectTShapeData);
  const skills = useSelector(selectOrderedSkills);
  const skillsBreakup = useSelector(selectSkillsBreakUp);
  const [isMobile, setIsMobile] = useState(checkIsMobile(breakpoint));
  const isBootstrapping = useSelector(selectIsBootstrapping);
  const tShapeSelectedSkill = useSelector(selectTShapeSelectedSkill);
  const { displayTop10Skills } = useSelector(selectFeatures);
  const selectedPathwayRoles = useSelector(selectSelectedPathwaysSpokes);
  const jobFamilies = useSelector(selectJobFamilies);
  const mySkills = useSelector(selectMySkills);
  const profileWorkRoles = useSelector(selectProfileWorkRoles);
  const selectedJobFamily = useSelector(selectSelectedJobFamily);
  const selectedTShapeRole = useSelector(selectSelectedTShapeRole);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const topSkills = useSelector(selectTopSkills);
  const hasFetchedAllSkills = useSelector(selectHasFetchedAllSkills);

  const { id } = useParams();

  const [activeTShapeView, setActiveTShapeView] = useState(false);
  const [showTShapeRoleDetails, setShowTShapeRoleDetails] = useState(false);
  const [showTShapeHelp, setShowTShapeHelp] = useState(false);
  const [isRoleSelectionVisible, setIsRoleSelectionVisible] = useState(false);
  const [isJobFamilySelectionVisible, setIsJobFamilySelectionVisible] = useState(false);
  const [isAddJobRoleVisible, setIsAddJobRoleVisible] = useState(false);
  const [isSelectingJobFamilyRole, setIsSelectingJobFamilyRole] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tempJobFamily, setTempJobFamily] = useState(null);

  const roleResults = useRolesSearch(searchTerm);

  const hasLoaded = !isBootstrapping;

  useEffect(() => {
    if (
      profileWorkRoles !== null &&
      !isEqual(profileWorkRoles, selectedPathwayRoles) &&
      selectedPathwayRoles.length
    ) {
      dispatch(setProfileWorkRoles(selectedPathwayRoles));
    }
  }, [selectedPathwayRoles]);

  // get tshape data for the selected role when the role changes
  useEffect(() => {
    if (selectedTShapeRole) {
      dispatch(fetchTShapeGraphicData({ roleId: selectedTShapeRole.id, isPublicProfile, id }));
    }
  }, [selectedTShapeRole]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchRecommendedRolesReq());
    if (!hasFetchedAllSkills) {
      dispatch(fetchAllSkillsReq());
    }
    dispatch(fetchTopSkills());
    if (!jobFamilies.length) {
      dispatch(fetchJobFamiliesReq());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsMobile(checkIsMobile(breakpoint));
  }, [breakpoint]);

  function checkIsMobile(breakpoint) {
    return breakpoint === 'xs' || breakpoint === 'sm';
  }

  const renderMobileSkillsSummary = () => {
    const goToPathwayDetails = () =>
      history.push(
        isPublicProfile
          ? `${ROUTES.PUBLIC_WALLET}/user/${id}/career-pathways?r=${selectedTShapeRole.id}`
          : `${ROUTES.CAREER_PATHWAYS}${
              selectedTShapeRole?.id ? `?r=${selectedTShapeRole.id}` : ''
            }`,
      );

    return (
      <>
        <AtAGlance
          skillsCount={skills.length}
          technicalCount={skillsBreakup['it skills']}
          operationalCount={skillsBreakup['soft skills']}
          professionalCount={skillsBreakup['professional skills']}
          expandByDefault
          expandable={false}
          tabIndex={-1}
        />

        <Expand className="iq4-profile-skills__expand" title="MY SKILLS T" defaultExpanded={true}>
          <SimpleIcon name="infoDefault" className="iq4-t-shape__info" />
          <div className="iq4-profile-skills__tshape-container">
            <p className="iq4-profile-skills__tshape-container-title">
              Explore your T-Shaped technology, business and professional skills as a planning
              framework for growth.
            </p>
            {selectedTShapeRole && (
              <div className="iq4-profile-skills__container-middle iq4-profile-skills__title-parent--item">
                <div>explore role</div>
                <div className="iq4-profile-skills__title-parent--item-label">
                  <div className="iq4-profile-skills__title-parent--change-label">
                    {selectedTShapeRole?.label}
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => setShowTShapeRoleDetails(true)}
                      onKeyPress={() => setShowTShapeRoleDetails(true)}
                      className="iq4-profile-skills__title-parent--change"
                    >
                      Change role
                    </div>
                  </div>
                </div>
                <div className="iq4-profile-skills__title-parent--item-label">
                  <div className="iq4-profile-skills__title-parent--change-label">
                    This is how your skills stack up against the role of {selectedTShapeRole?.label}
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={goToPathwayDetails}
                      onKeyPress={goToPathwayDetails}
                      className="iq4-profile-skills__title-parent--change"
                    >
                      View more details
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!selectedTShapeRole && !isPublicProfile && (
              <div className="iq4-profile-skills__container-middle iq4-profile-skills__title-parent--item">
                <div>add a role</div>
                <div className="iq4-profile-skills__title-parent--item-label">
                  <div
                    className="iq4-profile-skills__title-parent--change-label-color"
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowTShapeRoleDetails(true)}
                    onKeyPress={() => setShowTShapeRoleDetails(true)}
                  >
                    Add a job role and see how you're matched <SimpleIcon name="arrow" />
                  </div>
                </div>
                <div className="iq4-profile-skills__title-parent--small-label">
                  Explore your T-Shaped technology, business and professional skills as a planning
                  framework for growth.
                </div>
              </div>
            )}
            <div>
              {((hasLoaded && !selectedTShapeRole) ||
                (selectedTShapeRole && tShapeGraphicData)) && (
                <TShapeV2
                  renderOnlySelfSkills={!selectedTShapeRole}
                  {...(selectedTShapeRole
                    ? { ...tShapeGraphicData }
                    : { ...getMySkillsTShapeData(mySkills) })}
                  displayOnly
                />
              )}
            </div>
          </div>
          {tShapeGraphicData && (
            <div
              className="iq4-profile-skills__view-larger-t iq4-profile-skills__view-larger-t--mobile"
              onClick={() => void setActiveTShapeView(true)}
            >
              View larger version <SimpleIcon name="arrow" />
            </div>
          )}
        </Expand>

        {displayTop10Skills && (
          <Expand
            className="iq4-profile-skills__expand"
            title={`TOP ${skills.length > 10 ? 10 : skills.length} SKILLS`}
            defaultExpanded={true}
          >
            <TopSkillsCard topSkills={topSkills} withoutWrapper={isMobile} />
          </Expand>
        )}
        {breakpoint === 'sm' && (
          <Link
            className="iq4-profile-skills__see-more"
            to={ROUTES.MANAGE_SKILLS}
            aria-label="See more skills"
          >
            See More <SimpleIcon name="arrow" />
          </Link>
        )}
      </>
    );
  };

  const handleViewMoreRoleDetails = () => {
    history.push(
      isPublicProfile
        ? `${ROUTES.PUBLIC_WALLET}/user/${id}/career-pathways?r=${selectedTShapeRole.id}`
        : `${ROUTES.CAREER_PATHWAYS}${selectedTShapeRole?.id ? `?r=${selectedTShapeRole.id}` : ''}`,
    );
  };

  const handleAddSkill = async (skill) => {
    await dispatch(addSkillFromWorkRoleReq({ skill, roleId: selectedTShapeRole?.id }));
    dispatch(fetchTShapeGraphicData({ roleId: selectedTShapeRole.id }));
  };

  const handleRemoveSkill = async (skill) => {
    await dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: selectedTShapeRole?.id }));
    if (selectedTShapeRole) {
      dispatch(fetchTShapeGraphicData({ roleId: selectedTShapeRole.id }));
    }
  };

  const renderDefault = () => {
    return (
      <>
        {hasLoaded ? (
          <AtAGlance
            skillsCount={skills.length}
            technicalCount={skillsBreakup['it skills']}
            operationalCount={skillsBreakup['soft skills']}
            professionalCount={skillsBreakup['professional skills']}
            expandByDefault
            renderCirclesAsRow
            renderContainerAsRow={false}
            expandable={false}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '15px',
              border: '1px solid #eee',
              borderRadius: '4px',
            }}
          >
            <LoadingSkeleton width="30%" height={225} />
            <LoadingSkeleton width="30%" height={225} />
            <LoadingSkeleton width="30%" height={225} />
          </div>
        )}
        <div
          className={`iq4-profile-skills__container-section iq4-profile-skills__container-section--column ${
            skills.length ? 'iq4-profile-skills__container-section--with-skills' : ''
          }`}
        >
          <h3 className="iq4-profile-skills__container-title iq4-profile-skills__title-absolute">
            my skills t
            <div
              className="iq4-t-shape__info"
              role="button"
              tabIndex={0}
              onClick={() => setShowTShapeHelp(!showTShapeHelp)}
              onKeyPress={() => setShowTShapeHelp(!showTShapeHelp)}
              aria-label="Get more info about T-Shape labels"
            >
              <SimpleIcon name="infoDefault" />
              <div className="iq4-t-shape__info--text">What's this?</div>
            </div>
          </h3>
          <div className="iq4-profile-skills__title-parent">
            {selectedTShapeRole && (
              <div className="iq4-profile-skills__container-middle iq4-profile-skills__title-parent--item">
                <div>explore role</div>
                <div className="iq4-profile-skills__title-parent--item-label">
                  <div className="iq4-profile-skills__title-parent--change-label">
                    {selectedTShapeRole?.label}
                    <div
                      role="button"
                      tabIndex={0}
                      onClick={() => setShowTShapeRoleDetails(true)}
                      onKeyPress={() => setShowTShapeRoleDetails(true)}
                      className="iq4-profile-skills__title-parent--change"
                    >
                      Change role
                    </div>
                  </div>
                </div>
                <div className="iq4-profile-skills__title-parent--small-label">
                  This is how your skills stack up against the role of {selectedTShapeRole?.label}
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={handleViewMoreRoleDetails}
                    onKeyPress={handleViewMoreRoleDetails}
                    className="iq4-profile-skills__title-parent--view-more"
                  >
                    View more details
                  </div>
                </div>
              </div>
            )}
            {!selectedTShapeRole && !isPublicProfile && (
              <div className="iq4-profile-skills__container-middle iq4-profile-skills__title-parent--item">
                <div>add a role</div>
                <div className="iq4-profile-skills__title-parent--item-label">
                  <div
                    className="iq4-profile-skills__title-parent--change-label-color"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setShowTShapeRoleDetails(true);
                    }}
                    onKeyPress={() => setShowTShapeRoleDetails(true)}
                  >
                    Add a job role and see how you're matched <SimpleIcon name="arrow" />
                  </div>
                </div>
                <div className="iq4-profile-skills__title-parent--small-label">
                  Explore your T-Shaped technology, business and professional skills as a planning
                  framework for growth.
                </div>
              </div>
            )}
          </div>
          <div className="iq4-profile-skills__tshape-container">
            {!tShapeGraphicData && !hasLoaded /* need to change this */ && (
              <div style={{ marginTop: '0' }}>
                <LoadingSkeleton variant="rect" style={{ width: '100%', height: '200px' }} />
                <LoadingSkeleton
                  variant="rect"
                  style={{ width: '100%', height: '30px', marginTop: '4px', marginBottom: '0' }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <LoadingSkeleton
                    variant="rect"
                    style={{ flex: '1', height: '356px', marginTop: '4px' }}
                  />
                  <LoadingSkeleton
                    variant="rect"
                    style={{
                      width: '30px',
                      height: '360px',
                      marginLeft: '4px',
                      marginRight: '4px',
                    }}
                  />
                  <LoadingSkeleton
                    variant="rect"
                    style={{ flex: '1', height: '356px', marginTop: '4px' }}
                  />
                </div>
              </div>
            )}
            {((hasLoaded && !selectedTShapeRole) || (selectedTShapeRole && tShapeGraphicData)) && (
              <TShapeV2
                renderOnlySelfSkills={!selectedTShapeRole}
                {...(selectedTShapeRole
                  ? { ...tShapeGraphicData }
                  : { ...getMySkillsTShapeData(mySkills) })}
                sectionLabels={{
                  top: CATEGORY_LABELS['professional_skills'],
                  bottomLeft: CATEGORY_LABELS['it_skills'],
                  bottomRight: CATEGORY_LABELS['soft_skills'],
                }}
                onClick={(skill) => {
                  dispatch(fetchTShapeSkillInfo({ id: skill.id }));
                }}
              />
            )}
          </div>
        </div>
        {hasLoaded ? (
          <>
            {displayTop10Skills && <TopSkillsCard topSkills={topSkills} />}

            {!isPublicProfile && (
              <div className="iq4-action-card__see-more-wrapper">
                <Link
                  className="iq4-profile-skills__see-more"
                  to={ROUTES.MANAGE_SKILLS}
                  aria-label="See more skills"
                >
                  See More <SimpleIcon name="arrow" />
                </Link>
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              padding: '15px',
              border: '1px solid #eee',
              borderRadius: '4px',
              margin: '20px 0px',
            }}
          >
            <LoadingSkeleton width={200} height={40} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
            <LoadingSkeleton width="100%" height={30} />
          </div>
        )}
      </>
    );
  };

  const handlePanelTShapeRoleSearchSelection = (role) => {
    const newSelectedJobFam = jobFamilies.find((x) => x.id === role.jobFamilyId);
    if (newSelectedJobFam) {
      dispatch(setSelectedJobFamily(newSelectedJobFam));
    }

    handlePanelTShapeRoleSelection(role);
  };

  const handlePanelTShapeRoleSelection = (role) => {
    dispatch(setSelectedJobFamilyById(role.jobFamilyId));
    dispatch(setSelectedTShapeRole(role));
    setIsRoleSelectionVisible(false);
    setShowTShapeRoleDetails(false);
    setIsAddJobRoleVisible(false);
    setIsSelectingJobFamilyRole(false);
  };

  const handleClearRoles = () => {
    dispatch(setSelectedTShapeRole(null));
    dispatch(setSelectedJobFamily(null));
    setShowTShapeRoleDetails(false);
    setIsRoleSelectionVisible(false);
    setIsSelectingJobFamilyRole(false);
  };

  const handleSelectAddRoleJobFamily = (jobFamily) => {
    setIsRoleSelectionVisible(true);
    dispatch(setSelectedJobFamily(jobFamily));
  };

  const handleAddJobRoleClose = () => {
    setIsAddJobRoleVisible(false);
    setIsRoleSelectionVisible(false);
  };

  const renderJobFamilySelection = () => {
    const updateJobFamily = (jf) => {
      setTempJobFamily(jf);
      setIsSelectingJobFamilyRole(true);
      setIsJobFamilySelectionVisible(false);
      setIsRoleSelectionVisible(true);
    };

    return (
      <div className="iq4-pathway-detail__selection-container">
        <button
          className="iq4-pathway-detail__selection-container--cancel"
          onClick={() => setIsJobFamilySelectionVisible(false)}
        >
          Cancel
        </button>
        <div className="iq4-pathway-detail__selection-container--title">JOB FAMILY</div>
        <TextField
          value={selectedJobFamily?.label}
          classes={{
            root: 'iq4-pathway-detail__location-input--field-input-root',
          }}
          InputProps={{
            readOnly: true,
            classes: {
              input: 'iq4-pathway-detail__location-input--field-input',
            },
          }}
          variant="outlined"
        />
        <div className="iq4-pathway-detail__selection-container--root">
          {jobFamilies.map((jf) => {
            const isSelectedJobFamily = selectedJobFamily?.id === jf.id;
            return (
              <button
                className={`iq4-pathway-detail__selection-container--item ${
                  isSelectedJobFamily ? 'iq4-pathway-detail__selection-container--selected' : ''
                }`}
                key={jf.id}
                onClick={isSelectedJobFamily ? undefined : () => updateJobFamily(jf)}
              >
                <span className="iq4-pathway-detail__selection-container--label">{jf.label}</span>
                {isSelectedJobFamily && <CheckCircleIcon />}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {!activeTShapeView && (
        <>
          {!!tShapeSelectedSkill && (
            <Drawer
              anchor="right"
              variant="persistent"
              isOpen={true}
              classes={{
                root: 'iq4-tshape-page__drawer',
                paper: 'iq4-tshape-page__drawer-paper',
              }}
            >
              <FocusLock>
                <div style={{ paddingTop: '40px' }}>
                  <button
                    aria-label="Close"
                    className="iq4-tshape-page__drawer__dismiss-button"
                    onClick={() => void dispatch(fetchTShapeSkillInfo({ id: null }))}
                  >
                    <SimpleIcon name="close" role="img" aria-label="Close" />
                  </button>
                  {tShapeSelectedSkill && (
                    <RoleIndividualSkillInfoPanel
                      achievements={tShapeSelectedSkill.achievements}
                      skill={tShapeSelectedSkill}
                      onAdd={(skill) => handleAddSkill(skill)}
                      onRemove={(skill) => handleRemoveSkill(skill)}
                    />
                  )}
                </div>
              </FocusLock>
            </Drawer>
          )}
          {!!showTShapeRoleDetails && (
            <Drawer
              anchor="right"
              variant="persistent"
              isOpen={true}
              classes={{
                root: 'iq4-tshape-page__drawer',
                paper: 'iq4-tshape-page__drawer-paper',
              }}
            >
              <FocusLock
                onDeactivation={() => {
                  setTimeout(() => {
                    const button = document.querySelector(
                      '.iq4-profile-skills__title-parent--change-label-color',
                    );
                    if (button) {
                      button.focus();
                    }
                  }, 1);
                }}
              >
                <div style={{ paddingTop: '40px' }}>
                  <div
                    className="iq4-tshape-page__drawer__dismiss-back"
                    role="button"
                    tabIndex={0}
                    onClick={() => setShowTShapeRoleDetails(false)}
                    onKeyPress={() => setShowTShapeRoleDetails(false)}
                  >
                    <SimpleIcon name="arrow" />
                    <span className="iq4-tshape-page__drawer__dismiss-back--label">Back</span>
                  </div>
                  <button
                    aria-label="Close"
                    className="iq4-tshape-page__drawer__dismiss-button"
                    onClick={() => setShowTShapeRoleDetails(false)}
                  >
                    <SimpleIcon name="close" />
                  </button>
                  <div className="iq4-tshape-page__drawer__role-container">
                    {!isJobFamilySelectionVisible && !isRoleSelectionVisible && (
                      <>
                        <div className="iq4-tshape-page__drawer__role-container--title">
                          {selectedTShapeRole?.label}
                        </div>
                        <div className="iq4-tshape-page__drawer__role-container--divider" />
                        {!isPublicProfile && (
                          <>
                            <div className="iq4-tshape-page__drawer__role-container--role">
                              ROLE
                            </div>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                JOB FAMILY
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                {selectedJobFamily?.label}
                              </div>
                              <div
                                className="iq4-pathway-detail__selection-container--row-item-3"
                                role="button"
                                tabIndex="0"
                                onClick={() => setIsJobFamilySelectionVisible(true)}
                                onKeyPress={() => setIsJobFamilySelectionVisible(true)}
                              >
                                Change
                              </div>
                            </div>
                            {!!selectedJobFamily && (
                              <div className="iq4-pathway-detail__selection-container--row">
                                <div className="iq4-pathway-detail__selection-container--row-item-1">
                                  ROLE
                                </div>
                                <div className="iq4-pathway-detail__selection-container--row-item-2">
                                  {selectedTShapeRole?.label}
                                </div>
                                <div
                                  className="iq4-pathway-detail__selection-container--row-item-3"
                                  role="button"
                                  tabIndex={0}
                                  onClick={() => setIsRoleSelectionVisible(true)}
                                  onKeyPress={() => setIsRoleSelectionVisible(true)}
                                >
                                  Change
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        <>
                          {!!profileWorkRoles?.length && (
                            <div className="iq4-tshape-page__drawer__role-container--role-margin">
                              CAREER PATHWAY
                            </div>
                          )}
                          {profileWorkRoles?.map((role) => (
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                ROLE
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                {role.label}
                              </div>
                              <div
                                className="iq4-pathway-detail__selection-container--row-item-3"
                                role="button"
                                tabIndex={0}
                                onClick={() => handlePanelTShapeRoleSelection(role)}
                                onKeyPress={() => handlePanelTShapeRoleSelection(role)}
                              >
                                Select
                              </div>
                            </div>
                          ))}
                          <div className="iq4-tshape-page__drawer__role-container--role-margin">
                            CLEAR ROLES
                          </div>
                          <div className="iq4-pathway-detail__selection-container--row-padding">
                            <div className="iq4-pathway-detail__selection-container--row-item-2 iq4-pathway-detail__selection-container--row-item-small">
                              View your Skills T-Shape with no role information
                            </div>
                            <div
                              className="iq4-pathway-detail__selection-container--row-item-3"
                              role="button"
                              tabIndex={0}
                              onClick={handleClearRoles}
                              onKeyPress={handleClearRoles}
                            >
                              Clear
                            </div>
                          </div>
                        </>
                      </>
                    )}
                    {isRoleSelectionVisible && (
                      <SelectWorkRoles
                        jobFamily={tempJobFamily}
                        onSelectWorkRole={(role) => handlePanelTShapeRoleSelection(role)}
                        onClose={() => setIsRoleSelectionVisible(false)}
                        selectedWorkRole={!!isSelectingJobFamilyRole ? null : selectedTShapeRole}
                      />
                    )}
                    {isJobFamilySelectionVisible && renderJobFamilySelection()}
                  </div>
                </div>
              </FocusLock>
            </Drawer>
          )}
          {showTShapeHelp && (
            <Drawer
              anchor="right"
              variant="persistent"
              paperProps={{ id: 'tshape-help-drawer' }}
              isOpen={true}
              classes={{
                root: 'iq4-tshape-page__drawer',
                paper: 'iq4-tshape-help-page__drawer-paper',
              }}
            >
              <FocusLock returnFocus={true}>
                <div style={{ paddingTop: '40px' }}>
                  <AutoFocusInside>
                    <button
                      aria-label="Close"
                      className="iq4-tshape-page__drawer__dismiss-button"
                      onClick={() => setShowTShapeHelp(false)}
                    >
                      <SimpleIcon name="close" />
                    </button>
                  </AutoFocusInside>
                  <div className="iq4-tshape-page__drawer__role-container">
                    <>
                      <div className="iq4-tshape-page__drawer__role-container--title-2">
                        The T-Shape
                      </div>
                      <div className="iq4-tshape-page__drawer__role-container--divider" />
                      <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
                      <div>
                        The T-shape helps you visualize how your Professional & Leadership,
                        Technical, and Operational skill proficiencies compare against the target
                        proficiencies for your existing role and other work roles and where you have
                        skill gaps.
                      </div>
                      <div
                        className="iq4-tshape-page__drawer__role-container--role"
                        style={{ marginTop: 40 }}
                      >
                        CATEGORIES
                      </div>
                      <div>The T-Shape is divided into three sections:</div>
                      <p>
                        <b>{CATEGORY_LABELS['professional_skills']}</b> are unique abilities and
                        knowledge needed for a particular occupation, that includes a distinct range
                        of skills that are crucial for success on the job.
                        <br />
                        <div className="iq4__flex-row">
                          Example: DATABASE ADMINISTRATION
                          <Pill
                            label="PROFESSIONAL SKILLS"
                            type="professional skills"
                            className="iq4__margin-left"
                          />
                        </div>
                      </p>
                      <p>
                        <b>{CATEGORY_LABELS['it_skills']}</b> represent the ability to effectively
                        use a variety of software and tools on the job.
                        <br />
                        <div className="iq4__flex-row">
                          Example: SQL Databases
                          <Pill label="IT SKILLS" type="it skills" className="iq4__margin-left" />
                        </div>
                      </p>
                      <p>
                        <b>{CATEGORY_LABELS['soft_skills']}</b> are the personal qualities and
                        behaviors crucial for professional success. These skills can often be
                        transferred and applied across various job roles and industries.
                        <br />
                        <div className="iq4__flex-row">
                          Example: LEADERSHIP
                          <Pill
                            label="SOFT SKILLS"
                            type="soft skills"
                            className="iq4__margin-left"
                          />
                        </div>
                      </p>
                      <div
                        className="iq4-tshape-page__drawer__role-container--role"
                        style={{ marginTop: 40 }}
                      >
                        CHANGING ROLES
                      </div>
                      <div>You can see how your skills match against any role in our library:</div>
                      <p>- Select "Change Role" above the T-Shape</p>
                      <p>- Filter by either "Job Family" or "Role"</p>
                      <p>
                        - Alternatively, you can select a role from the "Career Pathway" section
                        directly
                      </p>
                      <p>- Your Skills are displayed on the T-Shape with a solid color</p>
                      <p>
                        - The Skills required by the selected Role are displayed on the T-Shape with
                        a candy cane pattern, and these are the Skills that you should look to
                        develop
                      </p>
                    </>
                  </div>
                </div>
              </FocusLock>
            </Drawer>
          )}
          {isAddJobRoleVisible && (
            <Drawer
              anchor="right"
              variant="persistent"
              isOpen={true}
              classes={{
                root: 'iq4-tshape-page__drawer',
                paper: 'iq4-tshape-page__drawer-paper',
              }}
            >
              <FocusLock>
                <div style={{ paddingTop: '40px' }}>
                  <div
                    className="iq4-tshape-page__drawer__dismiss-back"
                    role="button"
                    tabIndex={0}
                    onClick={handleAddJobRoleClose}
                    onKeyPress={handleAddJobRoleClose}
                  >
                    <SimpleIcon name="arrow" />
                    <span className="iq4-tshape-page__drawer__dismiss-back--label">Back</span>
                  </div>
                  <button
                    aria-label="Close"
                    className="iq4-tshape-page__drawer__dismiss-button"
                    onClick={() => setIsAddJobRoleVisible(false)}
                  >
                    <SimpleIcon name="close" />
                  </button>
                  <div className="iq4-tshape-page__drawer__role-container">
                    {isAddJobRoleVisible && !isRoleSelectionVisible && (
                      <div>
                        <div className="iq4-tshape-page__drawer__role-container--title">
                          ADD A ROLE
                        </div>
                        <div className="iq4-tshape-page__drawer__role-container--divider" />
                        <RolesSearchBar
                          results={roleResults}
                          onChange={(searchTerm) => {
                            setSearchTerm(searchTerm);
                          }}
                          onSkillClick={(workRole) =>
                            handlePanelTShapeRoleSearchSelection(workRole)
                          }
                        />
                        <div className="iq4-tshape-page__drawer__role-container--second-input">
                          <BrowseRolesInput
                            roles={jobFamilies}
                            onSelect={(jobFamily) => handleSelectAddRoleJobFamily(jobFamily)}
                          />
                        </div>
                      </div>
                    )}
                    {isRoleSelectionVisible && (
                      <SelectWorkRoles
                        displayBackButton={false}
                        jobFamily={selectedJobFamily}
                        onSelectWorkRole={(role) => handlePanelTShapeRoleSelection(role)}
                        onClose={() => setIsAddJobRoleVisible(false)}
                      />
                    )}
                  </div>
                </div>
              </FocusLock>
            </Drawer>
          )}
        </>
      )}

      {activeTShapeView && (
        <div className="iq4-profile-skills-t-view">
          <TShapePage
            onClose={() => void setActiveTShapeView(false)}
            renderOnlySelfSkills={!selectedTShapeRole}
            selectedRole={selectedTShapeRole}
          />
        </div>
      )}

      <ActionCard
        className="iq4-profile-skills"
        title="Skills"
        icon="skillsCircle"
        isDivideVisible={false}
        isEmpty={!skills.length}
      >
        {isMobile && renderMobileSkillsSummary()}
        {!isMobile && renderDefault()}
      </ActionCard>
    </>
  );
};
