import { SKILLS_FILTER_PROP_MAPPING } from 'common/constants/miscellaneous';
import { flatten } from 'lodash';

export const getYearRange = (min, max) => {
  const result = [];
  for (let i = max; i >= min; i--) {
    result.push({
      key: i.toString(),
      value: i.toString(),
    });
  }
  return result;
};

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function getFilenameFromPath(path) {
  const parts = path.split('/');
  const fileURL = parts[parts.length - 1];

  return fileURL && fileURL.split('?')[0];
}

/**
 * Runs a specific filter function, per filter type
 * @param categoryProps
 * @param inputFilter
 * @param inputSkills
 * @returns {Array}
 */
export const runGenericFilter = ({ categoryProps, inputFilter, inputSkills }) => {
  const propsToFilter = categoryProps.filter((prop) => inputFilter[prop]);
  const output = [];

  propsToFilter.forEach((p) => {
    const filtered = inputSkills.filter((skill) => SKILLS_FILTER_PROP_MAPPING[p](skill));

    if (filtered && filtered.length) {
      output.push(filtered);
    }
  });

  return flatten(output);
};

export const getBase64ImageUrl = (base64Data) => {
  return `data:image/jpeg;base64,${base64Data}`;
};

export function deriveTriangularDataFormat(list, rows = 11, columns = 11, isTop = false) {
  let triangularArr = [];

  // spread out the top section to
  const topRows = 10;
  if (isTop) {
    for (let i = 0; i < topRows; i++) {
      triangularArr[i] = [];
      triangularArr[i][0] = list.shift();
      triangularArr[i][1] = list.shift();
    }
  }

  for (let colPos = 0; colPos < columns; colPos++) {
    for (let rowPos = isTop ? topRows : 0; rowPos < rows; rowPos++) {
      if (!triangularArr[rowPos]) triangularArr[rowPos] = [];

      if (!triangularArr[rowPos][colPos]) triangularArr[rowPos][colPos] = list.shift();

      // fill in other sections
      let currentRowIndex = rowPos - 1;
      let currentColumnIndex = colPos + 1;
      while (currentRowIndex >= 0 && currentColumnIndex < columns) {
        if (!triangularArr[currentRowIndex][currentColumnIndex])
          triangularArr[currentRowIndex][currentColumnIndex] = list.shift();

        currentRowIndex--;
        currentColumnIndex++;
      }
    }
  }

  return triangularArr
    .filter((arr) => {
      return !!arr[0];
    })
    .reduce((outerArr, innerArr, index) => {
      outerArr[index] = {
        id: index,
        stack: innerArr.filter((val) => !!val),
      };
      return outerArr;
    }, []);
}

export function getMySkillsTShapeData(mySkills) {
  return {
    top: deriveTriangularDataFormat(
      mySkills.filter((x) => x.competenceGroup === 'Professional Skills').length
        ? [
            ...mySkills
              .filter((x) => x.competenceGroup === 'Professional Skills')
              .map((x) => ({ ...x, type: 'FILLED' })),
          ]
        : [],
      20,
      6,
      true,
    ),
    bottomLeft: deriveTriangularDataFormat(
      mySkills.filter((x) => x.competenceGroup === 'IT Skills').length
        ? [
            ...mySkills
              .filter((x) => x.competenceGroup === 'IT Skills')
              .map((x) => ({ ...x, type: 'FILLED' })),
          ]
        : [],
      11,
      11,
      false,
    ),
    bottomRight: deriveTriangularDataFormat(
      mySkills.filter((x) => x.competenceGroup === 'Soft Skills').length
        ? [
            ...mySkills
              .filter((x) => x.competenceGroup === 'Soft Skills')
              .map((x) => ({ ...x, type: 'FILLED' })),
          ]
        : [],
      11,
      11,
      false,
    ),
  };
}

export function ensureHTTPSProtocol(url) {
  return url.includes('https://') ? url : `https://${url}`;
}

export function removeUnneededChars(str = '') {
  return str.toString().replace(',', '').replace('+', '');
}

export function isValidUrl(urlString) {
  let url;

  try {
    url = new URL(urlString);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}
