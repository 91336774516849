import React from 'react';
import { Container } from '@material-ui/core';
import { SimpleIcon } from '../../../common/components';

export const CredentialsBanner = () => {
  return (
    <div className="iq4-credentials-banner">
      <div className="iq4-max-width-container iq4-credentials__container iq4-credentials-banner--container">
        <div className="iq4-credentials-banner--info">
          <SimpleIcon name="info" />
          <div>Credly Badges</div>
        </div>
        <div className="iq4-credentials-banner--text">
          Download this guide for adding Credly Badges to Badgr aka Canvas Badges
        </div>
        <a
          download
          href="https://iq4-tk-dev.s3.amazonaws.com/credlyBadge/Adding+Credly+Badges+to+Badgr+aka+Canvas+Badges.pdf"
          className="iq4-credentials-banner--download"
          target="_blank"
          aria-label="Download Credly Badges Guide - will open on new tab"
          rel="noopener noreferrer"
        >
          DOWNLOAD
        </a>
      </div>
    </div>
  );
};
