import React from 'react';
import { SkillsList } from 'common/components/SkillsList';
import { Checkbox } from 'common/components/Form/fieldTypes/Checkbox';

import './ResumeParserSkillList.scss';

export const ResumeParserSkillList = ({ skills, onChange, className, ...props }) => {
  return (
    <div className={`iq4-resume-parser-skill-list ${className ? className : ''}`} {...props}>
      <SkillsList skills={skills}>
        {(skill) => {
          return (
            <Checkbox
              label={''}
              isChecked={!!skill.selected}
              handleChange={(e) => {
                onChange(skill, e.target.checked);
              }}
            />
          );
        }}
      </SkillsList>
    </div>
  );
};
