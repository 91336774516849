import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import './EmploymentBreakdown.scss';

export const EmploymentBreakdownPie = ({
  employmentData,
  xProp,
  xDescription,
  yProp,
  yDescription,
}) => {
  const [employmentChartData, setEmploymentChartData] = useState([]);
  const [lowestYValue, setLowestYValue] = useState(null);

  useEffect(() => {
    let lowestY = null;
    const newChartEmploymentData = [
      {
        data: employmentData.map((e) => {
          if (lowestY === null) lowestY = e[yProp];

          if (e[yProp] < lowestY) lowestY = e[yProp];

          return {
            x: e[xProp],
            y: e[yProp],
          };
        }),
      },
    ];

    setLowestYValue(lowestY);
    setEmploymentChartData(newChartEmploymentData);
  }, [employmentData]);

  return (
    <div className="iq4-employment-breakdown-chart__container">
      <div className="iq4-employment-breakdown-chart__left-title">{yDescription}</div>
      <ResponsiveLine
        data={employmentChartData}
        margin={{ top: 15, right: 17, bottom: 25, left: 55 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        useMesh={true}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: null,
          legendOffset: null,
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: null,
          legendOffset: null,
        }}
        theme={{
          fontSize: '14px',
        }}
        isInteractive={false}
        height={240}
        enableArea
        areaBaselineValue={lowestYValue}
        lineWidth={2}
        colors="#B275B2"
        pointSize={12}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
      />
      <div className="iq4-employment-breakdown-chart__right-title">{xDescription}</div>
    </div>
  );
};
