import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useProfilePersonalFormDefinition } from 'common/hooks/forms';
import { SimpleIcon, ActionIcon, Modal, Button } from 'common/components';
import { OnboardingHeader, OnboardingFooter } from 'common/components/Onboarding';
import { FormWrapper } from 'common/components/Form';
import { ProfileAvatar } from 'components/Profile/ProfileAvatar';
import { selectProfile } from 'common/store/features/profile/selectors';
import { selectResumeParserPersonal } from 'common/store/features/parsedResume/selectors';
import { sendRequest } from 'common/utils';
import { setProfilePersonal } from 'common/store/features/profile/profileSlice';
import { PROFILE_PERSONAL_URL } from 'common/constants/endpoints';
import './OnboardingPersonal.scss';
import Joyride from 'react-joyride';
import { selectDisplayTour } from 'common/store/features/ui/selectors';
import IQ4Tour from '../../IQ4Tour/IQ4Tour';
import { setDisplayTour } from '../../../store/features/ui/uiSlice';

export const OnboardingPersonal = ({
  exposeFormContext,
  onComplete,
  onPrevious = () => {},
  disableTourOnLoad = false,
}) => {
  const dispatch = useDispatch();

  const { personal: profilePersonal } = useSelector(selectProfile);
  const resumeParserPersonal = useSelector(selectResumeParserPersonal);

  const [edit, setEdit] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [values, setValues] = useState(null);
  const displayTour = useSelector(selectDisplayTour);

  useEffect(() => {
    if (disableTourOnLoad) {
      dispatch(setDisplayTour(false));
    }
  }, [disableTourOnLoad]);

  const triggerRef = useRef(null);

  const steps = [
    {
      target: '.iq4-onboarding__wizard-steps',
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Onboarding Steps</h2>
          <p style={{ fontSize: '0.875rem' }}>
            There are five steps to take to set up your profile.
          </p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true,
      disableScrolling: true,
      hideCloseButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
    {
      target: '.iq4-onboarding-personal__action-item',
      placement: 'right',
      disableBeacon: true,
      disableScrolling: true,
      spotlightClicks: true,
      hideCloseButton: true,
      content: (
        <div>
          <p style={{ fontSize: '0.875rem' }}>You can edit this entire section by clicking here.</p>
        </div>
      ),
    },
    {
      target: '.iq4-profile-avatar__image-wrapper',
      placement: 'left',
      disableBeacon: true,
      disableScrolling: true,
      spotlightClicks: true,
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Avatar photo</h2>
          <p style={{ fontSize: '0.875rem' }}>Add a photo, if desired.</p>
        </div>
      ),
    },
    {
      target: '.iq4-onboarding-personal__profile--description',
      placement: 'right',
      disableBeacon: true,
      disableScrolling: true,
      spotlightClicks: true,
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Professional Summary</h2>
          <p style={{ fontSize: '0.875rem' }}>
            This is your moment to shine. Accept or edit your professional summary of your
            background and experience.
          </p>
        </div>
      ),
    },
    {
      target: '.iq4-onboarding-footer__button-next',
      placement: 'left',
      spotlightClicks: true,
      disableScrolling: true,
      disableBeacon: true,
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Saving as you go</h2>
          <p style={{ fontSize: '0.875rem' }}>
            You can save and continue at any time, picking up where you left off.
          </p>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setValues({
      id: profilePersonal?.userProfile?.id,
      firstName: profilePersonal?.userProfile?.user?.firstName,
      lastName: profilePersonal?.userProfile?.user?.lastName,
      email: profilePersonal?.userProfile?.user?.email,
      currentPosition:
        profilePersonal?.userProfile?.currentPosition || resumeParserPersonal?.currentPosition,
      description: profilePersonal?.userProfile?.description || resumeParserPersonal?.description,
      linkedIn: profilePersonal?.userProfile?.linkedIn || resumeParserPersonal?.linkedIn,
      hasOnboarded: true,
    });

    const mainElement = document.querySelector('.iq4-onboarding-personal');
    if (mainElement) {
      mainElement.focus();
    }
  }, [profilePersonal, resumeParserPersonal]);

  const handleEditComplete = () => {
    setEdit(false);
  };

  const handleNext = () => {
    setIsSubmittingForm(true);

    const userProfileId = profilePersonal?.userProfile?.id;

    return sendRequest({
      url: userProfileId ? `${PROFILE_PERSONAL_URL}/${userProfileId}` : PROFILE_PERSONAL_URL,
      verb: userProfileId ? 'PUT' : 'POST',
      data: values,
    }).then((resp) => {
      setIsSubmittingForm(false);
      dispatch(setProfilePersonal(resp));
      onComplete();
    });
  };

  return (
    <>
      <div className="iq4-onboarding-personal">
        <ActionIcon
          className="iq4-onboarding-personal__action-item"
          isDark
          iconName="edit"
          buttonClick={() => setEdit(true)}
          buttonLabel="Edit Personal"
        />

        <OnboardingHeader icon="personCircle" title="Personal" isMainHeading={true} />
        {displayTour && <IQ4Tour steps={steps} />}

        <ProfileAvatar label="Choose a picture" onAvatarClick={() => setEdit(true)} />

        <div className="iq4-onboarding-personal__profile">
          {values?.firstName && values?.lastName && (
            <p className="iq4-onboarding-personal__profile--name">
              {values?.firstName} {values?.lastName}
            </p>
          )}

          {values?.currentPosition && (
            <p className="iq4-onboarding-personal__profile--current-position">
              {values?.currentPosition}
            </p>
          )}

          {values?.description && (
            <p className="iq4-onboarding-personal__profile--description">{values?.description}</p>
          )}

          {values?.linkedIn && (
            <p className="iq4-onboarding-personal__profile--linkedin">
              <SimpleIcon name="linkedIn" />
              <a
                href={values?.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Go to LinkedIn (opens in a new tab)`}
              >
                {values?.linkedIn}
              </a>
            </p>
          )}
        </div>

        <Modal
          isOpen={edit}
          handleClose={() => setEdit(false)}
          title={<OnboardingHeader icon="personCircle" title="Personal" />}
          className="iq4-personal-card__modal_edit"
        >
          <FormWrapper
            showButtons
            values={values}
            useFormDefinition={useProfilePersonalFormDefinition}
            onComplete={() => handleEditComplete()}
          />
        </Modal>
      </div>
      <OnboardingFooter
        direction="Happy? Great let's move on to your Education"
        isSubmittingForm={isSubmittingForm}
        handleNext={() => handleNext()}
        handlePrev={() => onPrevious()}
        nextButtonLabel="Save and Continue"
      />
    </>
  );
};
