import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { ValidationMessage } from 'common/components/ValidationMessage';
import './RadioGroup.scss';
import { InnerSelect } from '../AccessibleSelect';

export const InnerRadioGroup = ({
  validators,
  radioItems,
  radioGroupName,
  exposeFormContext,
  triggerExternalValidation,
  onChange,
  value,
  name,
  ...formProps
}) => {
  const { register, formState, errors, getValues, triggerValidation } = useFormContext();
  const touched = formState.touched[radioGroupName];
  const dirty = formState.dirty;
  const hasError = errors[radioGroupName];
  const isSubmitted = formState.isSubmitted;
  const showError = () => {
    if (exposeFormContext && !dirty) return false;

    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  function innerOnChange() {
    console.log('changing radio');
    triggerValidation(name);
  }

  function handleKeyDown(event, item, i) {
    const key = event.key;
    const radioButtons = document.getElementsByClassName('iq4-radio-button__radio');
    const currentIdx = Array.from(radioButtons).indexOf(event.target);

    switch (key) {
      case ' ':
        if (value !== item.value) {
          onChange(item.value);
        }
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        event.preventDefault();
        radioButtons[currentIdx === radioButtons.length - 1 ? 0 : currentIdx + 1].focus();
        onChange(radioItems[currentIdx === radioButtons.length - 1 ? 0 : currentIdx + 1].value);
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        event.preventDefault();
        radioButtons[currentIdx === 0 ? radioButtons.length - 1 : currentIdx - 1].focus();
        onChange(radioItems[currentIdx === 0 ? radioButtons.length - 1 : currentIdx - 1].value);
        break;
      default:
        break;
    }
  }

  return (
    <div className="iq4-radio-group">
      {radioItems.map((item, i) => (
        <label className="iq4-radio-button" key={i}>
          {item.label}
          <input
            id={`${radioGroupName}-${i}`}
            tabIndex="-1"
            ref={register({ ...validators })}
            className="iq4-radio-button__input"
            type="radio"
            name={name}
            value={item.value}
            onChange={innerOnChange}
          />
          <span
            className="iq4-radio-button__radio"
            tabIndex={value === item.value || (!value && i === 0) ? 0 : -1}
            role="radio"
            aria-checked={value === item.value}
            aria-labelledby={`${radioGroupName}-${i}`}
            onKeyDown={(event) => handleKeyDown(event, item, i)}
          ></span>
        </label>
      ))}
      {showError() && (
        <ValidationMessage id={`${radioGroupName}Error`} message={hasError.message} />
      )}
    </div>
  );
};

export const RadioGroup = ({ name, validators, onChange, ...props }) => {
  const { control, getValues } = useFormContext();
  const values = getValues();

  return (
    <Controller
      as={<InnerRadioGroup {...props} />}
      control={control}
      rules={{ ...validators }}
      onChange={([selected]) => {
        onChange && onChange(selected);
        return selected;
      }}
      name={name}
      value={values[name]}
      validators={validators}
    />
  );
};

RadioGroup.propTypes = {
  radioItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  radioGroupName: PropTypes.string.isRequired,
  exposeFormContext: PropTypes.bool,
  triggerExternalValidation: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

RadioGroup.defaultProps = {};
