import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AccessibleSelect } from 'common/components/Form/fieldTypes';
import { MONTHS } from 'common/constants/dates';
import { validations } from 'common/components/Form/validations';
import { getYearRange } from 'common/utils';
import './DateRange.scss';
import { useFormContext } from 'react-hook-form';

const requiredValidator = { required: validations.isRequired() };
const yearValues = getYearRange(1940, new Date().getFullYear());

export const DateRange = ({ isPresent, key, isDisabled = false, ...formProps }) => {
  const formContext = useFormContext();
  const [liveText, setLiveText] = useState('');
  const { getValues } = formContext;
  const fromYearValidator = {
    validate: () => {
      const { startMonth, startYear } = getValues();

      const startDate =
        startMonth && startYear
          ? moment({ year: +startYear, month: +startMonth - 1, day: 1 })
          : null;

      if (!startDate) {
        return false;
      }

      if (startDate && startDate.isAfter(new moment())) {
        return '"Start" date needs to be before the present date';
      }
    },
  };

  const fromToValidator = {
    validate: () => {
      const { startMonth, startYear, endMonth, endYear } = getValues();

      const startDate =
        startMonth && startYear
          ? moment({ year: +startYear, month: +startMonth - 1, day: 1 })
          : null;
      const endDate =
        endMonth && endYear ? moment({ year: +endYear, month: +endMonth - 1, day: 1 }) : null;

      const isValid =
        !startDate || !endDate || startDate.isBefore(endDate) || startDate.isSame(endDate);
      if (!isValid) {
        return 'Needs to be after "start" date';
      }
      return isValid;
    },
  };

  useEffect(() => {
    const { startMonth, startYear, endMonth, endYear, isCurrentStudent } = getValues();
    if (startMonth && startYear) {
      const startMonthName = MONTHS.find((month) => month.key === startMonth).value;
      let liveText = `The selected date range starts from ${startMonthName} ${startYear}`;

      if (endMonth && endYear) {
        const endMonthName = MONTHS.find((month) => month.key === endMonth).value;
        liveText += ` and ends in ${endMonthName} ${endYear}`;
      } else if (!!isCurrentStudent) {
        liveText += ' and is ongoing because the user is currently studying here';
      }

      setLiveText(liveText);
    }
  }, [getValues().startMonth, getValues().startYear, getValues().endMonth, getValues().endYear]);

  const innerIsPresent = isPresent === 'true' || isPresent === true;

  return (
    <span className="iq4-form__date-range" key={key}>
      <div aria-live="polite" className="sr-only">
        {liveText}
      </div>
      <span className="iq4-form__date-range--item">
        <label htmlFor="startMonth">
          Start date:{' '}
          <span className="iq4-field__required-mark" aria-hidden="true">
            *
          </span>
          <span className="sr-only">Required field</span>
        </label>
        <span className="iq4-form__date-range--item-selects">
          <AccessibleSelect
            id="startMonth"
            name="startMonth"
            placeholder="Month"
            ariaLabel="Start Month"
            validators={{ ...requiredValidator }}
            options={MONTHS}
            key={`${key}-startMonth`}
            isDisabled={isDisabled}
            {...formProps}
          />
          <AccessibleSelect
            name="startYear"
            placeholder="Year"
            ariaLabel="Start Year"
            validators={{ ...fromYearValidator, ...requiredValidator }}
            options={yearValues}
            key={`${key}-startYear`}
            isDisabled={isDisabled}
            {...formProps}
          />
        </span>
      </span>
      <span className="iq4-form__date-range-separator">to</span>
      {!innerIsPresent && (
        <span className="iq4-form__date-range--item">
          <span>
            End date: <span className="iq4-field__required-mark">*</span>
          </span>
          <span className="iq4-form__date-range--item-selects">
            <AccessibleSelect
              name="endMonth"
              placeholder="Month"
              validators={{ ...requiredValidator }}
              options={MONTHS}
              key={`${key}-endMonth`}
              isDisabled={isDisabled}
              {...formProps}
            />
            <AccessibleSelect
              name="endYear"
              validators={{ ...fromToValidator, ...requiredValidator }}
              placeholder="Year"
              options={yearValues}
              key={`${key}-endYear`}
              isDisabled={isDisabled}
              {...formProps}
            />
          </span>
        </span>
      )}
      {innerIsPresent && <span className="iq4-form__date-range-present">Present</span>}
    </span>
  );
};
