export const selectNewInvites = (state) =>
  (state.connections.currentInvites || []).filter((x) => x.status === 1);

export const selectHistoryInvites = (state) =>
  (state.connections.currentInvites || []).filter((x) => x.status !== 1);

export const selectConnectionsCount = (state) =>
  state.connections.currentInvites?.filter((x) => x.status === 1).length;

export const selectIsTriggeringDecision = (state) => state.connections.isTriggeringDecision;

export const selectEmployerShareProfileSettings = (state, counsellorId) =>
  state.connections.employerShareProfileSettings[counsellorId];

export const selectIsSharingEmployerProfile = (state) => state.connections.isSharingEmployerProfile;

export const selectIsFetchingEmployerShareProfileSettings = (state) =>
  state.connections.isFetchingEmployerShareProfileSettings;

export const selectNotifyInvites = (state) =>
  state.connections.currentInvites?.filter((x) => !!x.notifyCounsellor);
