import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { ROUTES } from 'routes';
import {
  ProfilePage,
  ProfileExperiencePage,
  ProfileEducationPage,
  ProfilePortfolioPage,
  ProfilePageMobile,
} from 'pages/Profile';
import { PublicProfilePage } from './PublicProfilePage';
import { useSelector } from 'react-redux';
import { selectIsBootstrapping } from 'common/store/features/session/selectors';
import { selectProfile } from 'common/store/features/profile/selectors';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { ManageSkillsPage } from '../ManageSkillsPage';

export const PublicProfileRoutes = () => {
  const breakpoint = useBreakpoints();
  const [isMobile, setIsMobile] = useState(checkIsMobile(breakpoint));
  const { url } = useRouteMatch();

  useEffect(() => {
    setIsMobile(checkIsMobile(breakpoint));
  }, [breakpoint]);

  function checkIsMobile(breakpoint) {
    return breakpoint === 'xs';
  }

  const isBootstrapping = useSelector(selectIsBootstrapping);
  const hasLoaded = !isBootstrapping;

  return (
    <Switch>
      {!isMobile && (
        <Route
          exact
          path={[
            `${ROUTES.PUBLIC_WALLET}/user/:id`,
            `${ROUTES.PUBLIC_WALLET}/user/:id/education`,
            `${ROUTES.PUBLIC_WALLET}/user/:id/experience`,
            `${ROUTES.PUBLIC_WALLET}/user/:id/skills`,
            `${ROUTES.PUBLIC_WALLET}/user/:id/portfolio`,
          ]}
        >
          <PublicProfilePage />
        </Route>
      )}

      {!isMobile && (
        <Route exact path={`${ROUTES.PUBLIC_WALLET}/user/:id/credentials`}>
          <PublicProfilePage />
        </Route>
      )}

      {!isMobile && (
        <Route exact path={`${ROUTES.PUBLIC_WALLET}/user/:id/career-pathways`}>
          <PublicProfilePage />
        </Route>
      )}

      <Route exact path={ROUTES.PROFILE_EXPERIENCE}>
        <ProfileExperiencePage />
      </Route>

      <Route exact path={ROUTES.PROFILE_EDUCATION}>
        <ProfileEducationPage />
      </Route>

      <Route exact path={ROUTES.PROFILE_PORTFOLIO}>
        <ProfilePortfolioPage />
      </Route>

      {hasLoaded && <Redirect exact from={`${ROUTES.PROFILE_ROOT}/*`} to={ROUTES.PROFILE_ROOT} />}
    </Switch>
  );
};
