import {
  usePortfolioDocumentFormDefinition,
  usePortfolioAudioFormDefinition,
  usePortfolioVideoFormDefinition,
  usePortfolioOtherFormDefinition,
  usePortfolioImageFormDefinition,
} from 'common/hooks/forms';

export const PROFILE_TYPE = {
  PERSONAL: 'personal',
  EDUCATION: 'education',
  EXPERIENCE: 'experience',
  PORTFOLIO: 'portfolio',
};

export const PORTFOLIO_TYPE = {
  DOCUMENT: 'document',
  OTHER: 'other',
  VIDEO: 'video',
  AUDIO: 'audio',
  IMAGE: 'image',
};

export const portfolioFormDefinitions = {
  document: usePortfolioDocumentFormDefinition,
  other: usePortfolioOtherFormDefinition,
  video: usePortfolioVideoFormDefinition,
  audio: usePortfolioAudioFormDefinition,
  image: usePortfolioImageFormDefinition,
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const OVERLY_GENERIC_TERMS = ['specialist', 'professional'];

export const JOB_TITLE_EXCLUSION = ['security officer'];

export const PANEL_STATES = {
  DEFAULT: 'DEFAULT',
  WORK_ROLE_SKILLS_OVERVIEW: 'WORK_ROLE_SKILLS_OVERVIEW',
  INDIVIDUAL_SKILL_INFO: 'INDIVIDUAL_SKILL_INFO',
  CORE_TASKS: 'CORE_TASKS',
};

export const STUDENT_INVITE_STATUS = {
  1: 'pending',
  2: 'active',
  3: 'rejected',
  4: 'learnerRevoked',
  5: 'counsellorRevoked',
};

export const STUDENT_INVITE_STATUS_LABEL = {
  pending: 'Pending',
  active: 'Active',
  rejected: 'Rejected',
  learnerRevoked: 'Learner Revoked',
  counsellorRevoked: 'Counsellor Revoked',
};

export const CATEGORY_LABELS = {
  it_skills: process.env.REACT_APP_IT_SKILLS_LABEL || 'IT Skills',
  professional_skills: process.env.REACT_APP_PROFESSIONAL_SKILLS_LABEL || 'Professional Skills',
  soft_skills: process.env.REACT_APP_SOFT_SKILLS_LABEL || 'Soft Skills',
};

export const NODE_POSITIONS = [
  [
    { x: 193, y: -105 },
    { x: 404.18, y: -261 },
    { x: 592, y: -401.17 },
  ],
  [
    { x: 198, y: 130.53 },
    { x: 394.84, y: 260.62 },
    { x: 596.54, y: 390.02 },
  ],
  [
    { x: 21.24, y: 245.37 },
    { x: 21.91, y: 415.35 },
    { x: 21.35, y: 596.97 },
  ],
  [
    { x: -170.09, y: 133.11 },
    { x: -366.64, y: 260.39 },
    { x: -593.2, y: 390.78 },
  ],
  [
    { x: -173, y: -99.33 },
    { x: -388.31, y: -241.55 },
    { x: -613.11, y: -394.51 },
  ],
  [
    { x: 21.86, y: -220.64 },
    { x: 21.73, y: -395.91 },
    { x: 19.79, y: -573.06 },
  ],
];
