import React from 'react';
import FocusLock from 'react-focus-lock';
import { Drawer, SimpleIcon } from 'common/components';

export const JobRoleExplanation = ({ hideJobRoleExplanation }) => (
  <Drawer
    anchor="right"
    variant="persistent"
    isOpen={true}
    classes={{
      root: 'iq4-tshape-page__drawer',
      paper: 'iq4-explanation__drawer-paper',
    }}
  >
    <FocusLock>
      <div style={{ paddingTop: '20px' }}>
        <button
          aria-label="Close"
          className="iq4-tshape-page__drawer__dismiss-button"
          onClick={hideJobRoleExplanation}
        >
          <SimpleIcon name="close" />
        </button>
        <div className="iq4-tshape-page__drawer__role-container iq4-explanation__scroll">
          <h1 className="iq4-explanation--title">Job Roles</h1>
          <div className="iq4-explanation--divider" />
          <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
          <div className="iq4-explanation--content">
            Job roles, also known as job positions or job titles, refer to specific positions within
            an organization that come with a set of responsibilities, tasks, and duties. Each job
            role typically has a unique title and a well-defined set of responsibilities that
            outline what an employee is expected to do as part of their job.
            <br />
            <br /> Here are some key points about job roles: <br />
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Distinct Responsibilities:</strong> Each job role has its own set of tasks and
              responsibilities that contribute to the overall functioning of the organization. These
              responsibilities are often outlined in a job description.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Job Titles:</strong> Job roles are often identified by specific job titles,
              such as “Software Engineer,” “Sales Manager,” “Accountant,” or “Customer Service
              Representative.”
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Qualifications:</strong> Job roles may require specific qualifications,
              skills, and experience levels. For example, some roles may require a bachelor’s
              degree, while others may require certifications or relevant work experience.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Reporting Structure:</strong> Job roles are typically positioned within an
              organizational hierarchy, indicating where they fit in terms of reporting
              relationships and authority levels. For example, a manager may have authority over
              employees in specific roles.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Career Progression:</strong> Job roles are often associated with career paths
              and can serve as stepping stones for employees looking to advance in their careers.
              Many organizations have a hierarchy of roles that allow for upward mobility.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Salary and Compensation:</strong> Compensation for job roles varies based on
              factors like the level of responsibility, the complexity of tasks, and the demand for
              specific skills associated with that role.
            </div>
          </div>{' '}
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Performance Expectations:</strong> Employees in different job roles are
              evaluated based on performance metrics relevant to their responsibilities. These
              metrics help assess how well they are fulfilling their job duties.
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  </Drawer>
);
