import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProfile, selectAvatar } from 'common/store/features/profile/selectors';
import Skeleton from '@material-ui/lab/Skeleton';
import './ProfileAvatar.scss';

export const ProfileAvatar = ({ onAvatarClick, label = '' }) => {
  const { personal } = useSelector(selectProfile);
  const hasPhoto = !!(personal && personal.userProfilePhoto && personal.userProfilePhoto.thumbnail);
  const userAvatar = useSelector(selectAvatar);

  return (
    <div className="iq4-profile-avatar">
      <button className={`iq4-profile-avatar__image-wrapper`} onClick={onAvatarClick}>
        {!userAvatar && <Skeleton variant="circle" style={{ width: '100%', height: '100%' }} />}

        {userAvatar && (
          <>
            {hasPhoto && (
              <img
                className="iq4-profile-avatar__image"
                src={userAvatar}
                alt={` ${personal?.userProfile?.user?.firstName} ${personal?.userProfile?.user?.lastName} Avatar`}
              />
            )}

            {!hasPhoto && (
              <img
                className="iq4-profile-avatar__image-empty"
                src={userAvatar}
                alt="Empty avatar"
              />
            )}
          </>
        )}
        {label && <p className="iq4-profile-avatar__label">{label}</p>}
      </button>
    </div>
  );
};
