import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  PATHWAYS_JOB_FAMILIES_URL,
  PATHWAYS_JOB_FAMILY_WORKROLES_URL,
  PATHWAYS_WORK_ROLE_URL,
  PATHWAYS_COS_JOBS_URL,
  PATHWAYS_COS_DEMOGRAPHICS_URL,
  PUBLIC_PROFILE_URL,
  ROLE_LEARNING_RESOURCES_URL,
} from 'common/constants/endpoints';
import { COS_DEMOGRAPHICS_FIELDS } from 'common/constants/cosDemographicsFields';
import { JOB_TITLE_EXCLUSION, OVERLY_GENERIC_TERMS, sendRequest } from 'common/utils';
import localStorage from 'common/utils/localStorage';
import { selectShowOnlyInternships } from '../pathways/selectors';
import { setSelectedJobFamily } from '../profile/profileSlice';

const cosUserId = process.env.REACT_APP_COS_USER_ID;

export const fetchJobFamiliesReq = createAsyncThunk(
  'framework/fetchJobFamiliesReq',
  async (_, { rejectWithValue, dispatch }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;

    return fetch(
      isPublic ? `${PATHWAYS_JOB_FAMILIES_URL}?userId=${userId}` : PATHWAYS_JOB_FAMILIES_URL,
      {
        method: 'GET',
      },
    )
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchWorkrolesByJobFamily = createAsyncThunk(
  'framework/fetchWorkrolesByJobFamily',
  async (jobFamilyId, { rejectWithValue }) => {
    return fetch(`${PATHWAYS_JOB_FAMILY_WORKROLES_URL}?jobFamilyId=${jobFamilyId}`, {
      method: 'GET',
    })
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const fetchWorkRolePaths = createAsyncThunk(
  'framework/fetchWorkRolePaths',
  async ({ role, isPublicProfile = false, id }, { rejectWithValue, dispatch }) => {
    const url = isPublicProfile
      ? `${PUBLIC_PROFILE_URL}/${id}?filter=workrolePaths&jId=${role?.id}`
      : `${PATHWAYS_WORK_ROLE_URL}/${role?.id}/paths`;

    dispatch(setIsFetchingRolePaths(true));
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        dispatch(setIsFetchingRolePaths(false));
        if (response.ok) {
          return await response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        dispatch(setIsFetchingRolePaths(false));
        return rejectWithValue(error);
      });
  },
);

export const fetchWorkRoleReq = createAsyncThunk(
  'pathways/fetchWorkRoleReq',
  async (
    { roleId, fetchDemographicData, isPublicProfile = false, id },
    { dispatch, rejectWithValue },
  ) => {
    const url = isPublicProfile
      ? `${PUBLIC_PROFILE_URL}/${id}?filter=workrole&jId=${roleId}`
      : `${PATHWAYS_WORK_ROLE_URL}/${roleId}`;
    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (response.ok) {
          const returnValue = await response.json();

          dispatch(fetchWorkRoleJobsReq({ role: returnValue }));
          dispatch(fetchRoleLearningResources({ roleId, id, isPublicProfile }));

          if (fetchDemographicData) {
            dispatch(
              fetchWorkRoleDemographicsByLocationReq({
                role: returnValue,
              }),
            );
          }

          return returnValue;
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);
export const fetchRoleLearningResources = createAsyncThunk(
  'pathways/fetchRoleLearningResources',
  async ({ roleId, id, isPublicProfile = false }, { dispatch, rejectWithValue }) => {
    const url = isPublicProfile
      ? `${ROLE_LEARNING_RESOURCES_URL}/${roleId}`
      : `${ROLE_LEARNING_RESOURCES_URL}/${roleId}`;

    return fetch(url, {
      method: 'GET',
    })
      .then(async (response) => {
        if (response.ok) {
          return await response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const setSelectedJobFamilyById = createAsyncThunk(
  'profile/setSelectedJobFamilyById',
  async (jobFamilyId, { dispatch, getState }) => {
    const { jobFamilies } = getState().framework;
    const jobFamily = jobFamilies.find((jf) => jf.id === jobFamilyId);

    if (jobFamily) {
      dispatch(setSelectedJobFamily(jobFamily));
    }
  },
);

export const fetchWorkRoleJobsReq = createAsyncThunk(
  'framework/fetchWorkRoleJobsReq',
  async ({ role }, { dispatch, getState }) => {
    const [onet] = role.onets;
    const showOnlyInternships = selectShowOnlyInternships(getState());
    const internshipTerms = ['intern', 'internship', 'apprenticeship', 'apprentice'];
    const keywords = showOnlyInternships
      ? internshipTerms.map((t) => `${role?.label} ${t}`)
      : role.useSearchTitles
      ? [role.label]
      : role.onets;
    const selectedLocation = localStorage.get('selectedLocation');

    const jobRecsResult = await Promise.all(
      keywords.map((title) =>
        sendRequest({
          url: `${PATHWAYS_COS_JOBS_URL}/${cosUserId}/${encodeURIComponent(title)}/${
            selectedLocation || 0
          }/25/0/0/0/${showOnlyInternships ? '150' : '50'}/10?source=NLx&showFilters=false`,
          verb: 'GET',
        }),
      ),
    );

    const liveJobs = [].concat.apply(
      [],
      jobRecsResult.map((x) => x['Jobs']),
    );

    // filter out results
    const allUniqueWordsFromSearchTitles = [
      // unique
      ...new Set(
        [].concat
          .apply(
            [],
            keywords.map((x) => x.split(' ')),
          )
          // exclude overly generic terms that might bring other unwanted results
          .filter((x) => !OVERLY_GENERIC_TERMS.includes(x.toString().toLowerCase()))
          // to lowercase for matching purposes
          .map((x) => x.toString().toLowerCase()),
      ),
    ];

    const finalResult = liveJobs
      .filter(
        (job) =>
          !JOB_TITLE_EXCLUSION.some((title) =>
            job.JobTitle.toString().toLowerCase().includes(title),
          ),
      )
      .filter((job) => {
        if (onet !== '0') {
          return true;
        }

        const jobTitleWords = job.JobTitle.split(' ').map((x) => x.toString().toLowerCase());
        return jobTitleWords.some((item) => allUniqueWordsFromSearchTitles.includes(item));
      })
      // randomise it
      .sort(() => Math.random() - 0.5)
      // include only result that the job title contains any term from internshipTerms
      .filter((job) =>
        showOnlyInternships
          ? internshipTerms.some((term) => job.JobTitle.toLowerCase().includes(term))
          : true,
      );

    dispatch(setIsFetchingJobs(false));

    return finalResult;
  },
);

export const fetchWorkRoleDemographicsByLocationReq = createAsyncThunk(
  'pathways/fetchWorkRoleDemographicsByLocationReq',
  async ({ role }, { rejectWithValue, dispatch }) => {
    const selectedLocation = localStorage.get('selectedLocation') || '0';
    const [onet] = role.onets;
    dispatch(setIsFetchingDemographicData(true));

    return fetch(
      `${PATHWAYS_COS_DEMOGRAPHICS_URL}/${cosUserId}/${
        onet ? onet : encodeURIComponent(role.label)
      }/${selectedLocation}?${COS_DEMOGRAPHICS_FIELDS.map((field) => `${field}=true`).join('&')}`,
      {
        method: 'GET',
      },
    )
      .then((response) => {
        if (response.ok) {
          dispatch(setIsFetchingDemographicData(false));
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        dispatch(setIsFetchingDemographicData(false));
        return rejectWithValue(error);
      });
  },
);

export const triggerUpdateSelectedLocation = createAsyncThunk(
  'pathways/triggerUpdateSelectedLocation',
  async ({ role }, { dispatch }) => {
    dispatch(fetchWorkRoleJobsReq({ role }));
    dispatch(
      fetchWorkRoleDemographicsByLocationReq({
        role,
      }),
    );
  },
);

export const frameworkSlice = createSlice({
  name: 'framework',
  initialState: {
    jobFamilies: [],
    isFetchingJobs: false,
    isFetchingDemographicData: false,
    isFetchingWorkRoles: false,
    isFetchingRolePaths: false,
    selectedWorkRoles: [],
    publicRoles: {},
    hasFetchedJobFamilies: false,
  },
  reducers: {
    setIsFetchingJobs: (state, action) => {
      state.isFetchingJobs = action.payload;
    },
    setIsFetchingDemographicData: (state, action) => {
      state.isFetchingDemographicData = action.payload;
    },
    setIsFetchingWorkRoles: (state, action) => {
      state.isFetchingWorkRoles = action.payload;
    },
    setIsFetchingRolePaths: (state, action) => {
      state.isFetchingRolePaths = action.payload;
    },
    clearJobFamilyRoles: (state, action) => {
      const jobFamily = state.jobFamilies.find((x) => x.id === action.payload);
      jobFamily.workRoles = undefined;

      state.jobFamilies = [
        ...state.jobFamilies.filter((x) => x.id !== action.payload),
        { ...jobFamily },
      ];
    },
    setRoleAsSelected: (state, action) => {
      const role = action.payload;
      const jobFamily = state.jobFamilies.find((x) => x.id === role.jobFamilyId);
      state.selectedWorkRoles.push(role.id);

      if (jobFamily) {
        const selectedRole = jobFamily.workRoles?.find((x) => x.id === role.id);
        if (selectedRole) {
          selectedRole.isSelected = true;
        }
      }
    },
    setRoleAsUnselected: (state, action) => {
      const role = action.payload;
      const jobFamily = state.jobFamilies.find((x) => x.id === role.jobFamilyId);

      const id = state.selectedWorkRoles.indexOf(role?.id);
      state.selectedWorkRoles.splice(id, 1);

      if (jobFamily) {
        const selectedRole = jobFamily.workRoles?.find((x) => x.id === role.id);
        if (selectedRole) {
          selectedRole.isSelected = false;
        }
      }
    },
    updateRolesFromPathwaySelected: (state, action) => {
      const { selectedRoles } = action.payload;
      // make copy of state.jobFamilies
      let jobFamilies = [...state.jobFamilies];

      (selectedRoles || []).forEach((workRole) => {
        const jobFamily = jobFamilies.find((x) => x.id === workRole.jobFamilyId);
        if (jobFamily) {
          const selectedRole = jobFamily.workRoles?.find((x) => x.id === workRole.id);
          if (selectedRole) {
            selectedRole.isSelected = true;
          } else {
            if (jobFamily.workRoles) {
              jobFamily.workRoles.push(workRole);
            } else {
              jobFamily.workRoles = [workRole];
            }
          }
        }
      });

      state.jobFamilies = jobFamilies;
    },
    updateRoleMissingSkills: (state, action) => {
      const { role, skills } = action.payload;
      let jobFamilies = [...state.jobFamilies];

      const jobFamily = jobFamilies.find((x) => x.id === role.jobFamilyId);
      if (jobFamily) {
        const selectedRole = jobFamily.workRoles?.find((x) => x.id === role.id);
        if (selectedRole && selectedRole.skills) {
          selectedRole.skills.roleSkills = skills;
        }
      }
    },
  },
  extraReducers: {
    [fetchJobFamiliesReq.fulfilled]: (state, action) => {
      state.jobFamilies = action.payload;
      state.hasFetchedJobFamilies = true;
    },
    [fetchWorkrolesByJobFamily.fulfilled]: (state, action) => {
      const jobFamily = state.jobFamilies.find((x) => x.id === action.meta.arg);
      state.jobFamilies = [
        ...state.jobFamilies.filter((x) => x.id !== action.meta.arg),
        { ...action.payload, hasFetchedAllRoles: true },
      ];

      state.isFetchingWorkRoles = false;
    },
    [fetchWorkRoleReq.fulfilled]: (state, action) => {
      // check if workrole has been selected
      if (state.selectedWorkRoles.indexOf(action.payload?.id) > -1) {
        action.payload.isSelected = true;
      }

      const { isPublicProfile } = action.meta.arg;

      state.jobFamilies = state.jobFamilies.map((jobFamily) => {
        if (jobFamily.id === action.payload.jobFamilyId) {
          const workRoleIndex = jobFamily.workRoles?.findIndex(
            (workRole) => workRole.id === action.payload.id,
          );

          if (workRoleIndex > -1) {
            jobFamily.workRoles[workRoleIndex] = {
              ...jobFamily.workRoles[workRoleIndex],
              ...action.payload,
              paths: !action.payload?.paths ? jobFamily.workRoles[workRoleIndex]?.paths : null,
            };
          } else {
            if (jobFamily.workRoles) {
              jobFamily.workRoles.push(action.payload);
            } else {
              jobFamily.workRoles = [action.payload];
            }
          }
        }
        return jobFamily;
      });
    },
    [fetchWorkRoleJobsReq.fulfilled]: (state, action) => {
      const { role } = action.meta.arg;

      state.jobFamilies = state.jobFamilies.map((jobFamily) => {
        if (jobFamily.id === role.jobFamilyId) {
          const workRoleIndex = jobFamily.workRoles?.findIndex(
            (workRole) => parseInt(workRole.id) === parseInt(role.id),
          );

          if (jobFamily.workRoles?.[workRoleIndex]) {
            jobFamily.workRoles[workRoleIndex] = {
              ...jobFamily.workRoles[workRoleIndex],
              liveRoles: action.payload,
            };
          }
        }
        return jobFamily;
      });
    },
    [fetchRoleLearningResources.fulfilled]: (state, action) => {
      const { roleId } = action.meta.arg;

      state.jobFamilies = state.jobFamilies.map((jobFamily) => {
        if (jobFamily.workRoles) {
          const workRoleIndex = jobFamily.workRoles.findIndex(
            (workRole) => parseInt(workRole.id) === parseInt(roleId),
          );

          if (workRoleIndex !== -1) {
            jobFamily.workRoles[workRoleIndex] = {
              ...jobFamily.workRoles[workRoleIndex],
              learningResources: Array.isArray(action.payload) ? action.payload : [],
            };
          }
        }
        return jobFamily;
      });
    },
    [fetchWorkRolePaths.fulfilled]: (state, action) => {
      const { role } = action.meta.arg;
      state.isFetchingRolePaths = false;

      state.jobFamilies = state.jobFamilies.map((jobFamily) => {
        if (jobFamily.id === role.jobFamilyId) {
          const workRoleIndex = jobFamily.workRoles?.findIndex(
            (workRole) => parseInt(workRole.id) === parseInt(role.id),
          );

          if (jobFamily.workRoles?.[workRoleIndex]) {
            jobFamily.workRoles[workRoleIndex] = {
              ...jobFamily.workRoles[workRoleIndex],
              paths: action.payload,
            };
          }
        }
        return jobFamily;
      });
    },
    [fetchWorkRoleDemographicsByLocationReq.fulfilled]: (state, action) => {
      const { role } = action.meta.arg;

      state.jobFamilies = state.jobFamilies.map((jobFamily) => {
        if (jobFamily.id === role.jobFamilyId) {
          if (!jobFamily.workRoles) {
            jobFamily.workRoles = [];
          }
          const workRoleIndex = jobFamily.workRoles?.findIndex(
            (workRole) => parseInt(workRole.id) === parseInt(role.id),
          );

          jobFamily.workRoles[workRoleIndex === -1 ? 0 : workRoleIndex] = {
            ...jobFamily.workRoles[workRoleIndex],
            demographics: action.payload?.OccupationDetail[0],
          };
        }
        return jobFamily;
      });
    },
  },
});

export const {
  setIsFetchingJobs,
  setIsFetchingDemographicData,
  setIsFetchingWorkRoles,
  clearJobFamilyRoles,
  setRoleAsSelected,
  setRoleAsUnselected,
  updateRolesFromPathwaySelected,
  updateRoleMissingSkills,
  setIsFetchingRolePaths,
  setHasFetchedJobFamilies,
} = frameworkSlice.actions;

export default frameworkSlice.reducer;
