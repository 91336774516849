import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendRequest } from '../../../utils';
import {
  SKILLS_URL,
  MY_SKILLS_URL,
  SKILLS_KSA_URL,
  SKILLS_PERMISSIONS_URL,
  SKILLS_ASSESSMENT_URL,
  SKILL_SOURCE_URL,
  SAVE_SKILLS_RANK,
  SAVE_MY_SKILLS_URL,
  RECOMMENDED_SKILLS_URL,
  SKILL_DETAILS_URL,
  RESUME_PARSER_URL,
  TOP_SKILLS_URL,
} from 'common/constants/endpoints';
import { SELF_ASSESSMENT_ASSESSEE_ID } from 'common/constants/identifiers';
import {
  addProfileSectionCompetency,
  removeProfileSectionCompetency,
} from 'common/store/features/profile/profileSlice';
import { fetchWorkRoleReq } from 'common/store/features/framework/frameworkSlice';
import {
  setIsSubmittingForm,
  setSkillExpandedId,
  setSkillExpandingId,
} from 'common/store/features/ui/uiSlice';
import localStorage from 'common/utils/localStorage';
import {
  resetPathways,
  setRecommendedWorkRoles,
  setUserSelectedWorkRoles,
} from '../pathways/pathwaysSlice';
export const reorderSkillsReq = createAsyncThunk('/skills/reorderSkillsReq', async ({ skills }) => {
  const payload = skills.map((skill, index) => ({
    competenceId: skill.id,
    rank: index + 1,
  }));

  await fetch(SAVE_SKILLS_RANK, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  return skills;
});

export const replaceSkillReq = createAsyncThunk(
  '/skills/replaceSkillReq',
  async ({ replaceSkill, replaceWithSkill }, { dispatch, rejectWithValue }) => {
    return fetch(SAVE_SKILLS_RANK, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        {
          competenceId: replaceSkill.id,
          rank: null, // remove skill from ranking
        },
        {
          competenceId: replaceWithSkill.id,
          rank: replaceSkill.rank,
        },
      ]),
    }).then((response) => {
      if (response.ok) {
        return {
          replaceSkill,
          replaceWithSkill,
        };
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const saveAllSkillsReq = createAsyncThunk(
  'skills/saveAllSkillsReq',
  async (skills, { dispatch }) => {
    const payload = {
      competenceId: skills?.map((x) => x.competenceId),
      sourceId: 6,
    };

    await fetch(MY_SKILLS_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const assessmentsPayload = skills
      .filter((skill) => skill.assessment !== null && skill.assessment !== undefined)
      .map((skill) => ({
        competenceId: skill.competenceId,
        assesseeId: SELF_ASSESSMENT_ASSESSEE_ID,
        assessment: skill.assessment,
      }));

    if (assessmentsPayload.length) {
      await sendRequest({
        url: SKILLS_ASSESSMENT_URL,
        verb: 'POST',
        data: assessmentsPayload,
      });
    }

    dispatch(fetchAllSkillsReq());
  },
);

export const fetchAllSkillsReq = createAsyncThunk(
  'skills/fetchAllSkillsReq',
  async (_, { rejectWithValue }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    return fetch(`${SKILLS_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchTopSkills = createAsyncThunk(
  'skills/fetchTopSkills',
  async (_, { rejectWithValue }) => {
    const isPublic = window.location.href.indexOf('/user/') >= 0;
    const userId = isPublic ? window.location.href.split('/user/')[1]?.split('/')?.[0] : null;
    return fetch(`${TOP_SKILLS_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchSkillDetails = createAsyncThunk(
  'skills/fetchSkillDetails',
  async ({ competenceId, isPublic = false, userId }, { rejectWithValue, dispatch }) => {
    return fetch(`${SKILL_DETAILS_URL}${isPublic ? '?userId=' + userId : ''}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([{ competenceId }]),
    }).then((response) => {
      if (response.ok) {
        dispatch(setSkillExpandedId(competenceId));
        dispatch(setSkillExpandingId(-1));
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchSkillReq = createAsyncThunk(
  'skills/fetchSkillReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${MY_SKILLS_URL}/${id}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const addSkillReq = createAsyncThunk(
  'skills/addSkillsReq',
  async (skill, { dispatch, getState, rejectWithValue }) => {
    // check if user already has skill
    const currentSkills = getState().skills.skills;
    const hasSkill = currentSkills.find((s) => s.competenceId === skill.id);
    // if the user has the skill already just perform newly added skill logic
    if (hasSkill) {
      dispatch(skillsSlice.actions.addNewlyAddedFlag(skill.id));
      return rejectWithValue();
    }

    const newSkill = {
      competenceId: [skill.id],
      sourceId: 5, // sourceId of 5 tells back-end that this skill was manually added
    };

    return fetch(SAVE_MY_SKILLS_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newSkill),
    })
      .then((resp) => resp.json())
      .then((response) => {
        const savedSkill = response[0];
        dispatch(resetPathways());

        // add any other props passed from components on the skill object.
        const { id: _, value: __, ...skillProps } = skill;

        return { ...savedSkill, ...skillProps, newlyAdded: true };
      });
  },
);

export const removeSkillReq = createAsyncThunk(
  'skills/removeSkillReq',
  async (id, { dispatch }) => {
    await sendRequest({
      url: `${SKILLS_URL}?id=${id}`,
      verb: 'DELETE',
    });

    dispatch(resetPathways());
    return id;
  },
);

// ------

export const addSkillFromWorkRoleReq = createAsyncThunk(
  'skills/addSkillFromWorkRoleReq',
  async ({ skill, roleId }, { dispatch, getState, rejectWithValue }) => {
    await dispatch(addSkillReq(skill));
    if (roleId) {
      dispatch(fetchWorkRoleReq({ roleId }));
    }
  },
);

export const removeSkillFromWorkRoleReq = createAsyncThunk(
  'skills/removeSkillFromWorkRoleReq',
  async ({ id, roleId }, { dispatch }) => {
    await dispatch(removeSkillReq(id));

    if (roleId) {
      dispatch(fetchWorkRoleReq({ roleId }));
    }

    return id;
  },
);

export const updateSkillReq = createAsyncThunk(
  'skills/updateSkillAssessmentReq',
  async (skill, { rejectWithValue }) => {
    return fetch(`${MY_SKILLS_URL}/${skill.id}`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(skill),
    }).then((response) => {
      if (response.ok) {
        return skill;
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const updateSkillKSAReq = createAsyncThunk(
  'skills/updateSkillAssessmentReq',
  async ({ ksa }, { rejectWithValue }) => {
    return fetch(SKILLS_KSA_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: ksa.id,
        isSelected: ksa.isSelected,
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const updateSkillPermissionReq = createAsyncThunk(
  'skills/updateSkillPermissionReq',
  async ({ competenceId, isPublic }, { rejectWithValue }) => {
    return fetch(SKILLS_PERMISSIONS_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ competenceId, isPublic }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const updateSkillVisibilityReq = createAsyncThunk(
  'skills/updateSkillVisibilityReq',
  async ({ competenceId, isHidden }, { rejectWithValue }) => {
    return fetch(SKILLS_PERMISSIONS_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ competenceId, isHidden }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const updateSkillSelfAssessmentReq = createAsyncThunk(
  'skills/updateSkillSelfAssessmentReq',
  async ({ competenceId, assessment }, { rejectWithValue }) => {
    return fetch(SKILLS_ASSESSMENT_URL, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([
        {
          competenceId,
          assesseeId: SELF_ASSESSMENT_ASSESSEE_ID,
          assessment,
        },
      ]),
    }).then((response) => {
      if (response.ok) {
        return { competenceId, assessment };
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const removeSkillSourceReq = createAsyncThunk(
  'skills/removeSkillSourceReq',
  async ({ skillId, source }, { rejectWithValue }) => {
    return fetch(`${SKILL_SOURCE_URL}?id=${skillId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sourceToDelete: [
          {
            id: source.id,
            type: source.type,
          },
        ],
      }),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

const filterOriginalSources = (originalSources) => {
  let flattenedSources = [];

  Object.keys(originalSources).forEach((sourceType) => {
    originalSources[sourceType].forEach((source) => {
      flattenedSources.push({ ...source, type: sourceType });
    });
  });

  return flattenedSources
    .filter((source) => source.associated)
    .map((source) => ({
      id: source.id,
      type: source.type,
    }));
};

export const updateSkillSourceReq = createAsyncThunk(
  'skills/updateSkillSourceReq',
  async (
    { skill, sourceToAdd = [], sourceToDelete = [], originalSources },
    { dispatch, rejectWithValue },
  ) => {
    dispatch(setIsSubmittingForm(true));

    return fetch(`${SKILL_SOURCE_URL}?id=${skill.competenceId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sourceToAdd,
        sourceToDelete,
      }),
    }).then((response) => {
      if (response.ok) {
        const skill = response.json();

        const newSources = skill.sources;
        const originalAssociatedSources = filterOriginalSources(originalSources);

        originalAssociatedSources.forEach((originalSource) => {
          // is this in new list
          const isNotInNewSources =
            newSources.findIndex(
              (newSource) =>
                newSource.id === originalSource.id && newSource.type === originalSource.type,
            ) === -1;

          // if not in new list, dispatch action to remove from associated record
          if (isNotInNewSources) {
            dispatch(
              removeProfileSectionCompetency({
                section: originalSource.type,
                id: originalSource.id,
                competency: {
                  id: skill.competenceId,
                  value: skill.tier3Label,
                },
              }),
            );
          }
        });

        newSources.forEach((newSource) => {
          // is this in old list
          const isNotInOldSources =
            originalAssociatedSources.findIndex(
              (originalSource) =>
                originalSource.id === newSource.id && originalSource.type === newSource.type,
            ) === -1;

          // if not in old list, dispatch action to add to associated record
          if (isNotInOldSources && newSource.type !== 'manual') {
            dispatch(
              addProfileSectionCompetency({
                section: newSource.type,
                id: newSource.id,
                competency: {
                  id: skill.competenceId,
                  value: skill.tier3Label,
                },
              }),
            );
          }
        });

        dispatch(setIsSubmittingForm(false));

        return skill;
      } else {
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchRecommendedSkillsReq = createAsyncThunk(
  'skills/fetchRecommendedSkillsReq',
  async (_, { rejectWithValue }) => {
    return fetch(`${RECOMMENDED_SKILLS_URL}?limit=3`, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return rejectWithValue(response);
        }
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  },
);

export const addRecommendedSkillReq = createAsyncThunk(
  'skills/addRecommendedSkillReq',
  async (skill, { getState, dispatch }) => {
    // add the skill to the user's passport
    await dispatch(addSkillReq(skill));

    const recommendedSkills = getState().skills.recommendedSkills;
    if (recommendedSkills.length === 1) {
      // fetch more recommended skills
      dispatch(fetchRecommendedSkillsReq());
    }

    return skill;
  },
);

export const finishOnboardingReq = createAsyncThunk(
  '/skills/finishOnboardingReq',
  async (onComplete, { getState }) => {
    const skills = getState().skills.skills;
    const unselectedSkills = skills.filter(
      (skill) => skill.hasOwnProperty('selected') && !skill.selected,
    );

    // remove unselected skills from the user's passport
    await Promise.all(
      unselectedSkills.map((skill) =>
        sendRequest({
          url: `${SKILLS_URL}?id=${skill.competenceId}`,
          verb: 'DELETE',
        }),
      ),
    );

    onComplete();

    return unselectedSkills.map((skill) => skill.competenceId);
  },
);

export const skillsSlice = createSlice({
  name: 'skills',
  initialState: {
    mySkills: [],
    skills: [],
    topSkills: [],
    recommendedSkills: [],
    resumeParser: [],
    hasFetchedAllSkills: false,
  },
  reducers: {
    setMySkills: (state, action) => {
      state.mySkills = action.payload;
    },
    setSkills: (state, action) => {
      state.skills = action.payload;
    },
    updateSkill: (state, action) => {
      const itemIndex = state.skills.findIndex(
        (x) => x.competenceId === action.payload.competenceId,
      );
      state.skills[itemIndex] = action.payload;
    },
    addSkillSource: (state, action) => {
      const skillId = action.payload.skillId;
      const source = action.payload.source;

      const itemIndex = state.skills.findIndex((x) => x.competenceId === skillId);
      const originalSources = state.skills[itemIndex].sources || [];

      const sourceExists =
        originalSources.filter((item) => item.id === source.id && item.type === source.type)
          .length > 0;

      state.skills[itemIndex].sources = sourceExists
        ? state.skills[itemIndex].sources
        : [...originalSources, source];
    },
    removeSkillSource: (state, action) => {
      const skillId = action.payload.skillId;
      const source = action.payload.source;
      const skillIndex = state.skills.findIndex((x) => x.competenceId === skillId);
      let sources = state.skills[skillIndex].sources || [];
      const sourceIndex = sources.findIndex(
        (item) => item.id === source.id && item.type === source.type,
      );

      if (sourceIndex > -1) {
        sources.splice(sourceIndex, 1);
      }

      state.skills[skillIndex].sources = sources;
    },
    removeNewlyAddedFlag: (state, action) => {
      const skill = state.skills.find((s) => s.competenceId === action.payload);
      delete skill.newlyAdded;
    },
    addNewlyAddedFlag: (state, action) => {
      state.skills[
        state.skills.findIndex((s) => s.competenceId === action.payload)
      ].newlyAdded = true;
    },
    toggleSkillSelectedOnboarding: (state, action) => {
      const { selected, id } = action.payload;
      const skillIndex = state.skills.findIndex((skill) => skill.competenceId === id);
      state.skills[skillIndex].selected = selected;
    },

    updateSelectedResumeParserSkills: (state, action) => {
      // TODO: Remove this when update in ResumeParserWizard flow
      const index = state.resumeParser.findIndex(
        (skill) => skill.competenceId === action.payload.skill.id,
      );

      if (index > -1) {
        state.resumeParser[index].checked = action.payload.checked;
      }
    },

    updateAssessmentResumeParserSkill: (state, action) => {
      const index = state.resumeParser.findIndex(
        (skill) => skill.competenceId === action.payload.skill.id,
      );

      state.resumeParser[index].assessment = action.payload.assessment;
    },

    manuallyAddResumeParserSkill: (state, action) => {
      state.resumeParser.push(action.payload);
    },

    setResumeParserSkills: (state, action) => {
      state.resumeParser = action.payload;
    },
  },

  extraReducers: {
    [fetchAllSkillsReq.fulfilled]: (state, action) => {
      state.skills = action.payload;
      state.hasFetchedAllSkills = true;
    },

    [fetchSkillDetails.fulfilled]: (state, action) => {
      let updatedSkill = action.payload[0];

      const skillIndex = state.skills.findIndex(
        (skill) => skill.competenceId === updatedSkill.competenceId,
      );

      state.skills[skillIndex] = {
        ...state.skills[skillIndex],
        ...updatedSkill,
      };

      console.log('updated skill', state.skills[skillIndex]);
    },

    [addSkillReq.fulfilled]: (state, action) => {
      state.skills.push(action.payload);
    },

    [updateSkillReq.fulfilled]: (state, action) => {
      const index = state.mySkills.findIndex((s) => s.id === action.payload.id);
      state.mySkills[index] = action.payload;
    },

    [updateSkillKSAReq.fulfilled]: (state, action) => {
      const relevantSkillIndex = state.skills.findIndex(
        (s) => s.competenceId === action.payload.competenceId,
      );
      const updatedSkill = state.skills[relevantSkillIndex];

      state.skills[relevantSkillIndex] = {
        ...updatedSkill,
        ...action.payload,
      };
    },

    [updateSkillSelfAssessmentReq.fulfilled]: (state, action) => {
      const index = state.skills.findIndex((s) => s.competenceId === action.payload.competenceId);

      if (!state.skills[index].assessments) {
        state.skills[index].assessments = [];
      }

      const selfAssessmentIndex = state.skills[index].assessments.findIndex(
        (assessment) => assessment.assesseeId === SELF_ASSESSMENT_ASSESSEE_ID,
      );

      // toggle isNewSkill flag
      state.skills[index] = {
        ...state.skills[index],
        isNewSkill: false,
      };

      state.skills[index].proficiency = action.payload.assessment;
      state.skills[index].assessment = action.payload.assessment;

      // not self assessed yet
      if (selfAssessmentIndex === -1) {
        state.skills[index].assessments.push({
          assesseeId: SELF_ASSESSMENT_ASSESSEE_ID,
          assessment: action.payload.assessment,
        });
        return;
      }

      state.skills[index].assessments[selfAssessmentIndex] = {
        ...state.skills[index].assessments[selfAssessmentIndex],
        assessment: action.payload.assessment,
      };
    },

    [updateSkillPermissionReq.fulfilled]: (state, action) => {
      state.skills[state.skills.findIndex((s) => s.competenceId === action.payload.competenceId)] =
        action.payload;
    },

    [updateSkillVisibilityReq.fulfilled]: (state, action) => {
      state.skills[
        state.skills.findIndex((s) => s.competenceId === action.payload.competenceId)
      ].isHidden = action.payload?.isHidden;
    },

    [removeSkillSourceReq.fulfilled]: (state, action) => {
      const currentSkill = state.skills.find((x) => x.competenceId === action.payload.competenceId);

      if (currentSkill) {
        currentSkill.sources = action.payload?.sources;
        state.skills[
          state.skills.findIndex((s) => s.competenceId === action.payload.competenceId)
        ] = currentSkill;
      }
    },

    [updateSkillSourceReq.fulfilled]: (state, action) => {
      state.skills[state.skills.findIndex((s) => s.competenceId === action.payload.competenceId)] =
        action.payload;
    },

    [saveAllSkillsReq.fulfilled]: (state, action) => {
      state.mySkills = action.payload;
    },

    [removeSkillReq.fulfilled]: (state, action) => {
      const idToRemove = action.payload;
      const index = state.skills.findIndex((s) => s.competenceId === idToRemove);

      state.skills = [...state.skills.slice(0, index), ...state.skills.slice(index + 1)];
    },

    [reorderSkillsReq.fulfilled]: (state, action) => {
      const competenceIdToRank = action.payload.reduce((map, skill, index) => {
        map[skill.id] = index + 1;
        return map;
      }, {});

      const skillsWithUpdatedRank = state.skills.map((skill) => {
        return {
          ...skill,
          rank: competenceIdToRank[skill.competenceId]
            ? competenceIdToRank[skill.competenceId]
            : null,
        };
      });

      state.skills = skillsWithUpdatedRank;
    },

    [replaceSkillReq.fulfilled]: (state, action) => {
      const sourceIndex = state.skills.findIndex(
        (skill) => skill.competenceId === action.payload.replaceSkill.id,
      );
      const destinationIndex = state.skills.findIndex(
        (skill) => skill.competenceId === action.payload.replaceWithSkill.id,
      );

      state.skills[sourceIndex].rank = null;
      state.skills[destinationIndex].rank = action.payload.replaceSkill.rank;
    },

    [fetchRecommendedSkillsReq.fulfilled]: (state, action) => {
      state.recommendedSkills = action.payload;
    },

    [addRecommendedSkillReq.fulfilled]: (state, action) => {
      const addedSkillIndex = state.recommendedSkills.findIndex(
        (skill) => skill.competenceId === action.payload.id,
      );
      state.recommendedSkills = [
        ...state.recommendedSkills.slice(0, addedSkillIndex),
        ...state.recommendedSkills.slice(addedSkillIndex + 1),
      ];
    },

    [finishOnboardingReq.fulfilled]: (state, action) => {
      const removedSkillIds = action.payload;
      state.skills = state.skills.filter(
        (skill) => removedSkillIds.indexOf(skill.competenceId) === -1,
      );
    },
    [fetchTopSkills.fulfilled]: (state, action) => {
      state.topSkills = action.payload;
    },
  },
});

// action creators
export const {
  setMySkills,
  setSkills,
  updateSkill,
  addSkillSource,
  removeSkillSource,
  removeSkill,
  setSkillKSAs,
  setSkillSelfAssessment,
  removeNewlyAddedFlag,
  toggleSkillSelectedOnboarding,
  updateSelectedResumeParserSkills,
  updateAssessmentResumeParserSkill,
  setResumeParserSkills,
  manuallyAddResumeParserSkill,
} = skillsSlice.actions;

export default skillsSlice.reducer;
