import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Expand,
  SimpleIcon,
  LoadingSkeleton,
  TShapeV2,
  Button,
  EmploymentBreakdownPie,
  ExternalLinkInterstitial,
} from 'common/components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Checkbox, TextField } from '@material-ui/core';
import { PATHWAYS_COS_VIDEO_CONTENT_URL } from 'common/constants/endpoints';
import {
  CoreTasks,
  SkillsAnalytics,
  OpenPositions,
  PathwayTopSkillsCourse,
  RoleSkillsInfoPanel,
  RoleIndividualSkillInfoPanel,
  PathwaysSimilarRolesPanel,
  RoleEduChart,
} from '../Panels';
import {
  addPathwayReq,
  removePathwayReq,
  removeAddedSuggestion,
  addAddedSuggestion,
  fetchRoleMissingSkillsInfoReq,
  setShowOnlyInternships,
  fetchRecommendedRolesReq,
} from 'common/store/features/pathways/pathwaysSlice';
import {
  addSkillFromWorkRoleReq,
  removeSkillFromWorkRoleReq,
} from 'common/store/features/skills/skillsSlice';
import {
  triggerUpdateSelectedLocation,
  setIsFetchingDemographicData,
  setIsFetchingJobs,
  fetchWorkRolePaths,
  fetchWorkRoleJobsReq,
} from 'common/store/features/framework/frameworkSlice';
import {
  selectIsAddingRoleToPathway,
  selectIsFetchingSelectedWorkRoles,
  selectRoleIdsFetchedMissingSkills,
  selectSelectedJobFamily,
  selectSelectedPathwayRoles,
  selectShowOnlyInternships,
} from 'common/store/features/pathways/selectors';

import {
  selectIsFetchingJobs,
  selectIsFetchingDemographicData,
  selectIsFetchingWorkRoles,
  selectIsFetchingRolePaths,
} from 'common/store/features/framework/selectors';

import { selectJobFamilies } from 'common/store/features/framework/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import { usLocations } from 'common/constants/usLocations';
import { useLocalStorage } from 'common/hooks/useLocalStorage';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { PANEL_STATES } from 'common/utils/constants';
import { SelectWorkRoles } from 'common/components/SelectWorkRoles';
import { ROUTES } from 'routes';
import { removeUnneededChars } from 'common/utils';
import Skeleton from '@material-ui/lab/Skeleton';
import './PathwayDetail.scss';
import Avatar from '@material-ui/core/Avatar';
import { selectIsPublicProfile, selectProfile } from '../../../store/features/profile/selectors';
import { MiddleStepModal } from '../../PathwaysGraph/MiddleStepModal';
import {
  setDisplayMissingSkillsTour,
  setDisplaySecondPathwayDetailTour,
  setDisplayTour,
  setPathwayDetailTour,
  setShowJobFamilyExplanation,
  setShowJobRoleExplanation,
} from '../../../store/features/ui/uiSlice';
import {
  selectDisplayPathwayDetailTour,
  selectDisplaySecondPathwayDetailTour,
  selectDisplayTour,
  selectShowJobFamilyExplanation,
  selectShowJobRoleExplanation,
} from '../../../store/features/ui/selectors';
import { fetchTShapeSkillInfo, setSelectedSkillInfo } from 'common/store/features/t/slice';
import { selectTShapeSelectedSkill } from 'common/store/features/t/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import { INTERSTITIAL_COPY } from '../../../constants/interstitialCopy';
import Joyride from 'react-joyride';

export const PathwayDetail = ({
  role,
  onViewRole,
  readOnly = false,
  filterByInstitution,
  onTShapeClick,
  onBackClick,
  onPanelStateChange = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { label } = role;

  const isFetchingJobs = useSelector(selectIsFetchingJobs);
  const isFetchingDemographicData = useSelector(selectIsFetchingDemographicData);
  const isFetchingWorkRoles = useSelector(selectIsFetchingWorkRoles);
  const jobFamilies = useSelector(selectJobFamilies);
  const selectedJobFamily = useSelector(selectSelectedJobFamily);
  const { personal } = useSelector(selectProfile);
  const isPublicProfile = useSelector(selectIsPublicProfile);
  const isFetchingSelectedWorkRoles = useSelector(selectIsFetchingSelectedWorkRoles);
  const selectedWorkRoles = useSelector(selectSelectedPathwayRoles);
  const selectedSkillsInfo = useSelector(selectTShapeSelectedSkill);
  const isAddingRoleToPathway = useSelector(selectIsAddingRoleToPathway);
  const isFetchingRolePaths = useSelector(selectIsFetchingRolePaths);
  const showTour = useSelector(selectDisplayPathwayDetailTour);
  const showSecondTour = useSelector(selectDisplaySecondPathwayDetailTour);
  const showOnlyInternships = useSelector(selectShowOnlyInternships);

  // Demographics
  const [educationAttainmentLevels, setEducationAttainmentLevels] = useState(null);
  const [projections, setProjections] = useState(null);
  const [wages, setWages] = useState(null);

  const [currentPanelState, setCurrentPanelState] = useState(PANEL_STATES.DEFAULT);
  const [backPanelState, setBackPanelState] = useState(PANEL_STATES.DEFAULT);
  const [activeSkill, setActiveSkill] = useState(null);
  const [panelsConfig, setPanelsConfig] = useState(null);
  const [activeSkillsBarSegment, setActiveSkillsBarSegment] = useState(null);
  const [isStickyMainVisible, setIsStickyMainVisible] = useState(true);
  const [isJobFamilySelectionVisible, setIsJobFamilySelectionVisible] = useState(false);
  const [isRoleSelectionVisible, setIsRoleSelectionVisible] = useState(false);
  const [isLocationSelectionVisible, setIsLocationSelectionVisible] = useState(false);
  const [chosenJobFamily, setChosenJobFamily] = useState(null);
  const [isSelectingJobFamilyRole, setIsSelectingJobFamilyRole] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadedScreen, setLoadedScreen] = useState(false);
  const [prevRole, setPrevRole] = useState(JSON.stringify(role));

  const stickyRef = useRef(null);
  const backRef = useRef(null);
  const cancelRef = useRef(null);

  const [selectedLocation, setSelectedLocation] = useLocalStorage('selectedLocation', '0');
  const isMobile = useIsMobile();
  const { id } = useParams();

  const showJobFamilyExplanation = useSelector(selectShowJobFamilyExplanation);
  const showJobRoleExplanation = useSelector(selectShowJobRoleExplanation);
  const roleIdsFetchedMissingSkills = useSelector(selectRoleIdsFetchedMissingSkills);

  const locationLabel = usLocations.find((x) => x.id === selectedLocation)?.label;

  const updateStickyVisibility = (entries) => {
    const [entry] = entries;
    setIsStickyMainVisible(entry.isIntersecting);
  };

  const intersectingOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.01,
  };

  const avatarPhoto = personal?.userProfilePhoto?.thumbnail;
  const flattenedPaths = role.paths?.flat();
  const sortedPaths = flattenedPaths?.sort((a, b) => (a.type > b.type ? 1 : -1));
  const step = sortedPaths?.[0];

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        onBackClick();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if (prevRole !== role?.id) {
      dispatch(fetchWorkRolePaths({ role, isPublicProfile, id }));
      setPrevRole(role?.id);
    }
  }, [role, id, isPublicProfile, dispatch]);

  useEffect(() => {
    const observer = new IntersectionObserver(updateStickyVisibility, intersectingOptions);
    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }

    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current);
      }
    };
  }, [stickyRef, intersectingOptions]);

  useEffect(() => {
    const button = document.querySelector('.iq4-pathway-detail__header-title--small-font');
    if (button) {
      button.focus();
    }
  }, [role]);

  useEffect(() => {
    if (!isFetchingSelectedWorkRoles) {
      setTimeout(() => setLoadedScreen(true), 20);
    }
  }, [isFetchingSelectedWorkRoles]);

  const { pathways } = useSelector(selectFeatures);

  const pathwayTopSkillsCourse = {};

  useEffect(() => {
    if (onPanelStateChange) {
      onPanelStateChange(currentPanelState);
    }
  }, [currentPanelState]);

  useEffect(() => {
    setEducationAttainmentLevels(role?.demographics?.EducationTraining?.EducationType);
    setProjections(role?.demographics?.Projections);
    setWages(role?.demographics?.Wages);
  }, [role?.demographics]);

  useEffect(() => {
    if (!isFetchingJobs) {
      dispatch(fetchWorkRoleJobsReq({ role }));
    }
  }, [showOnlyInternships, isFetchingJobs]);

  const renderLoadingPanel = () => (
    <div style={{ display: 'block', padding: '10px 10px' }}>
      <LoadingSkeleton width="100%" height={30} />
      <LoadingSkeleton width="100%" height={30} />
      <LoadingSkeleton width="100%" height={24} />
    </div>
  );

  const getWagesLabel = () =>
    role?.demographics?.Wages?.StateWagesList?.length
      ? role?.demographics?.Wages?.StateWagesList[0]
      : role?.demographics?.Wages?.NationalWagesList[0];

  const VideoRenderer = React.memo(() => {
    if (isFetchingDemographicData || isFetchingWorkRoles) {
      return (
        <div className="iq4-pathway-details__video-container">
          <Skeleton variant="rect" style={{ width: '100%', height: '100%' }} />
        </div>
      );
    }

    const hasVideo = !!role?.demographics?.Video?.[0];

    const onetCode =
      role?.demographics?.Video && role?.demographics?.Video.length
        ? role?.demographics?.Video[0].VideoCode
        : '';

    const videoURL = `${PATHWAYS_COS_VIDEO_CONTENT_URL}/${onetCode}.mp4`;

    if (onetCode && hasVideo) {
      return (
        <div className="iq4-pathway-details__video-container">
          <video
            width="100%"
            height="100%"
            controls
            preload="metadata"
            controlsList="nodownload"
            crossOrigin="anonymous"
          >
            <source src={videoURL} type="video/mp4" />
            <track
              label="English"
              kind="subtitles"
              srcLang="en"
              src={`https://cdn.careeronestop.org/CaptionFiles/${onetCode}.vtt`}
              default
            />
          </video>
        </div>
      );
    }
    return <>No video available for this role.</>;
  });

  const renderJobFamilyLabel = (jobFamilyId) => {
    return jobFamilies.find((jobFamily) => jobFamily.id === jobFamilyId)?.label;
  };

  const handleSelectWorkRole = (workRole) => {
    setIsRoleSelectionVisible(false);
    setChosenJobFamily(null);
    history.push(`${ROUTES.CAREER_PATHWAYS}${workRole?.id ? `?r=${workRole.id}` : ''}`);
  };

  const handleClickSegment = (segment) => {
    setActiveSkillsBarSegment(segment.id);
    if (segment.id === 'MISSING_SKILLS' && !roleIdsFetchedMissingSkills.includes(role.id)) {
      dispatch(fetchRoleMissingSkillsInfoReq({ role }));
    }
    setCurrentPanelState(PANEL_STATES.WORK_ROLE_SKILLS_OVERVIEW);
  };

  const updateSelectedLocation = (value) => {
    setSelectedLocation(value);
    dispatch(setIsFetchingDemographicData(true));
    dispatch(setIsFetchingJobs(true));
    dispatch(triggerUpdateSelectedLocation({ role }));
  };

  useEffect(() => {
    const showPathway = !!role.paths?.length;
    const flattenedPaths = role.paths?.flat();
    const sortedPaths = flattenedPaths?.sort((a, b) => (a.type > b.type ? 1 : -1));

    const finalPanelConfig = {
      ...(showPathway &&
        !isFetchingRolePaths && {
          recommendedPathway: {
            title: 'RECOMMENDED PATHWAY',
            isDefaultExpanded: true,
            content: (
              <div className="iq4-pathway-detail__suggested pathway">
                <div className="iq4-pathway__suggested-avatar">
                  {avatarPhoto && <img src={avatarPhoto} alt="Avatar" />}
                  {!avatarPhoto && (
                    <Avatar className="iq4-pathway__empty-avatar" alt="Avatar" src={null} />
                  )}
                  <div className="iq4-pathway__suggested-mid--text">
                    <div className="iq4-pathway__suggested-mid--label">
                      {personal?.userProfile.user?.firstName} {personal?.userProfile.user?.lastName}
                      , {personal?.userProfile?.currentPosition}
                    </div>
                  </div>
                  <div className="iq4-pathway__suggested-line-1" />
                </div>

                <div className="iq4-pathway__suggested-mid">
                  <SimpleIcon
                    name={
                      sortedPaths?.[0]?.type === 'role'
                        ? 'experienceCirclePathways'
                        : 'educationCircle'
                    }
                    className=""
                  />
                  <div className="iq4-pathway__suggested-mid--text">
                    <div className="iq4-pathway__suggested-mid--label">
                      {sortedPaths?.[0]?.name}
                    </div>
                    <div
                      className="iq4-pathway__suggested-mid--view"
                      role="button"
                      tabIndex={0}
                      onClick={() => setShowModal(true)}
                      onKeyPress={() => setShowModal(true)}
                    >
                      View more
                    </div>
                  </div>
                </div>
                <div className="iq4-pathway__suggested-line-2" />
                <div className="iq4-pathway__suggested-mid">
                  <SimpleIcon name="experienceCirclePathways" />
                  <div className="iq4-pathway__suggested-mid--text">
                    <div className="iq4-pathway__suggested-mid--label">{role.label}</div>
                    {!readOnly && role.isSelected && (
                      <div
                        role="button"
                        tabIndex={0}
                        onClick={() => removeRoleFromPathway(role)}
                        onKeyPress={() => removeRoleFromPathway(role)}
                        className="iq4-pathway__suggested-mid--view"
                      >
                        Delete from Pathway
                      </div>
                    )}
                    {!readOnly &&
                      !role.isSelected &&
                      !isFetchingSelectedWorkRoles &&
                      !isPublicProfile &&
                      selectedWorkRoles?.length < 6 && (
                        <div
                          role="button"
                          tabIndex={0}
                          onClick={() => addRoleToPathway(role)}
                          onKeyPress={() => addRoleToPathway(role)}
                          className="iq4-pathway__suggested-mid--view iq4-tour-add"
                        >
                          Add to Pathway
                        </div>
                      )}
                  </div>
                </div>
              </div>
            ),
          },
        }),
      ...(isFetchingRolePaths && {
        recommendedPathway: {
          title: 'RECOMMENDED PATHWAY',
          isDefaultExpanded: true,
          content: (
            <div className="iq4-pathway-detail__suggested pathway">
              <div className="iq4-pathway__suggested-avatar">
                {avatarPhoto && <img src={avatarPhoto} alt="Avatar" />}
                {!avatarPhoto && (
                  <Avatar className="iq4-pathway__empty-avatar" alt="Avatar" src={null} />
                )}
                <div className="iq4-pathway__suggested-mid--text">
                  <div className="iq4-pathway__suggested-mid--label">
                    {personal?.userProfile.user?.firstName} {personal?.userProfile.user?.lastName},{' '}
                    {personal?.userProfile?.currentPosition}
                  </div>
                </div>
                <div className="iq4-pathway__suggested-line-1" />
              </div>
              <LoadingSkeleton variant="circle" width={50} height={50} style={{ marginTop: 22 }} />
            </div>
          ),
        },
      }),
      ...(role.description &&
        role.description !== role.label && {
          description: {
            title: 'DESCRIPTION',
            isDefaultExpanded: true,
            content: (
              <>
                <p>{role.description}</p>
                {role?.demographics && (
                  <p>
                    <VideoRenderer />
                  </p>
                )}
              </>
            ),
          },
        }),
      ...(role.description === role.label && {
        description: {
          title: 'DESCRIPTION',
          isDefaultExpanded: true,
          content: (
            <p>
              <VideoRenderer />
            </p>
          ),
        },
      }),
      ...(role?.jobTasks &&
        !!role?.jobTasks?.length && {
          coreTasks: {
            title: 'CORE TASKS',
            isDefaultExpanded: true,
            content: (
              <>
                {!role?.jobTasks && renderLoadingPanel()}
                {!!role?.jobTasks?.length && (
                  <>
                    <div className="iq4-pathway-detail__core-tasks__header">
                      These are some core tasks you can expect to be able to perform with this role
                    </div>
                    <ul className="iq4-core-tasks">
                      {role?.jobTasks.map((task) => (
                        <li className="iq4-core-tasks__item">{task.task}</li>
                      ))}
                    </ul>
                  </>
                )}
              </>
            ),
          },
        }),
      ...(pathways.similarRoles && {
        similarRoles: {
          title: 'SIMILAR ROLES',
          isDefaultExpanded: true,
          content: (
            <div className="iq4-tour-similar">
              {!role.similarRoles && renderLoadingPanel()}
              {role.similarRoles && role.similarRoles.length === 0 && (
                <p className="iq4-pathway-detail__empty_state">
                  No Similar Roles found for this workrole.
                </p>
              )}
              {role.similarRoles && role.similarRoles.length > 0 && (
                <PathwaysSimilarRolesPanel
                  roles={role.similarRoles}
                  onClick={(role) => onViewRole(role)}
                />
              )}
            </div>
          ),
        },
      }),
      skillsOverlap: {
        title: 'SKILLS OVERLAP',
        isDefaultExpanded: true,
        content: (
          <div className="iq4-tour-skills-overlap">
            {!role.skills && renderLoadingPanel()}
            {role.skills && (
              <>
                <p className="iq4-pathway-detail__expand__intro">
                  Select the bar to expand skills information and view potential courses for roles
                  where you need skills you don’t have.
                </p>
                <SkillsAnalytics
                  analytics={role.skills}
                  onClickSegment={(segment) => handleClickSegment(segment)}
                />
              </>
            )}
          </div>
        ),
      },
      learningResource: {
        title: 'RECOMMENDED LEARNING RESOURCE',
        isDefaultExpanded: false,
        content: (
          <>
            {!role.skills && renderLoadingPanel()}
            {role.skills && !role.learningResources && (
              <p className="iq4-pathway-detail__empty_state">
                No recommended learning resources found.
              </p>
            )}
            {role.learningResources &&
              role.learningResources?.map((resource) => (
                <div className={`iq4-learning-resource-card`}>
                  <div className="iq4-learning-resource-card__row-1">
                    <div className="iq4-learning-resource-card__icon">
                      <SimpleIcon name="education" />
                    </div>
                    <div className="iq4-learning-resource-card__course-container">
                      <p className="iq4-learning-resource-card__course-title">{resource.name}</p>
                      <p className="iq4-learning-resource-card__course-institution">
                        {resource.school}
                      </p>
                    </div>
                  </div>

                  <div className="iq4-learning-resource-card__row-2">
                    <div className="iq4-learning-resource-card__location-container">
                      {!!resource.url && (
                        <ExternalLinkInterstitial
                          className="iq4-learning-resource-card__link"
                          linkLabel="Learn More"
                          link={resource.url}
                          labelName={`${resource.name} at ${resource.school}`}
                          introText={INTERSTITIAL_COPY.BADGR_INTRO_TEXT}
                          mainText={INTERSTITIAL_COPY.BADGR_MAIN_TEXT}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </>
        ),
      },
      tShape: {
        title: 'MY SKILLS T',
        isDefaultExpanded: true,
        content: (
          <>
            {!role.tShape && renderLoadingPanel()}
            {role.tShape && (
              <>
                <p className="iq4-pathway-detail__expand__intro">
                  Explore your T-Shaped technology, business and professional skills as a planning
                  framework for growth.
                </p>
                <div className="iq4-pathway-detail__tshape-container">
                  <TShapeV2 {...role.tShape} displayOnly />
                  <Button
                    variation="ghost"
                    className="iq4-pathway-detail__tshape-link"
                    onClick={onTShapeClick}
                  >
                    View larger version
                  </Button>
                </div>
              </>
            )}
          </>
        ),
      },
      ...(role?.demographics?.EducationTraining && {
        educationAttainmentLevels: {
          title: 'EDUCATION ATTAINMENT LEVELS',
          isDefaultExpanded: true,
          content: (
            <div className="iq4-tour-education">
              {(!role?.demographics?.EducationTraining?.EducationType ||
                isFetchingDemographicData) &&
                renderLoadingPanel()}
              {!isFetchingDemographicData &&
                role?.demographics?.EducationTraining?.EducationType &&
                role?.demographics?.EducationTraining?.EducationType.length === 0 && (
                  <p className="iq4-pathway-detail__empty_state">
                    No Education Attainment Levels found for this workrole.
                  </p>
                )}
              {!isFetchingDemographicData &&
                role?.demographics?.EducationTraining?.EducationType &&
                role?.demographics?.EducationTraining?.EducationType.length > 0 && (
                  <>
                    <p className="iq4-pathway-detail__edu-levels-text">
                      This bar is a guide to show what others working in {locationLabel} as{' '}
                      {role.label} have attained.
                    </p>
                    <RoleEduChart levels={role?.demographics?.EducationTraining?.EducationType} />
                    <div className="iq4-pathway-detail__edu-levels-legend">
                      {role?.demographics?.EducationTraining?.EducationType.filter(
                        (eduLevel) => eduLevel.Value,
                      ).map((eduLevel) => {
                        return (
                          <div
                            key={eduLevel.EducationLevel}
                            className="iq4-pathway-detail__edu-levels-legend-item"
                          >
                            <span /> {eduLevel.EducationLevel}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
            </div>
          ),
        },
      }),
      ...(role?.demographics?.Projections && {
        employmentBreakdownChart: {
          title: 'EMPLOYMENT PROJECTION',
          isDefaultExpanded: true,
          content: (
            <div className="iq4-tour-projection">
              {(!role?.demographics?.Projections || isFetchingDemographicData) &&
                renderLoadingPanel()}
              {!isFetchingDemographicData && role?.demographics?.Projections && (
                <>
                  <p className="iq4-pathway-detail__edu-levels-text">
                    This is a projection of employment openings in {locationLabel} as {role.label}.
                  </p>
                  <EmploymentBreakdownPie
                    employmentData={[
                      {
                        number: parseInt(
                          role?.demographics?.Projections?.Projections?.[0]?.EstimatedEmployment?.replace(
                            ',',
                            '',
                          ),
                        ),
                        year: role?.demographics?.Projections?.EstimatedYear,
                      },
                      {
                        number: parseInt(
                          role?.demographics?.Projections?.Projections?.[0]?.ProjectedEmployment?.replace(
                            ',',
                            '',
                          ),
                        ),
                        year: role?.demographics?.Projections?.ProjectedYear,
                      },
                    ]}
                    xProp="year"
                    xDescription="Year"
                    yProp="number"
                    yDescription="Prospective roles"
                  />
                </>
              )}
            </div>
          ),
        },
      }),
      ...(role?.demographics && {
        annualEarnings: {
          title: 'ANNUAL EARNINGS',
          isDefaultExpanded: true,
          content: (
            <div className="iq4-tour-earnings">
              {(!getWagesLabel() || isFetchingDemographicData) && renderLoadingPanel()}
              {!isFetchingDemographicData && getWagesLabel() && (
                <>
                  <p className="iq4-pathway-detail__edu-levels-text">
                    This graph shows annual earnings by {role.label} in {locationLabel}.
                  </p>
                  <EmploymentBreakdownPie
                    employmentData={[
                      {
                        percentile: 10,
                        earnings: removeUnneededChars(getWagesLabel()['Pct10']),
                      },
                      {
                        percentile: 25,
                        earnings: removeUnneededChars(getWagesLabel()['Pct25']),
                      },
                      {
                        percentile: 'Median',
                        earnings: removeUnneededChars(getWagesLabel()['Median']),
                      },
                      {
                        percentile: 75,
                        earnings: removeUnneededChars(getWagesLabel()['Pct75']),
                      },
                      {
                        percentile: 90,
                        earnings: removeUnneededChars(getWagesLabel()['Pct90']),
                      },
                    ]}
                    xProp="percentile"
                    xDescription="Percentile"
                    yProp="earnings"
                    yDescription="Earnings (USD per annum)"
                  />
                </>
              )}
            </div>
          ),
        },
      }),
      liveRoles: {
        title: 'LIVE ROLES',
        isDefaultExpanded: true,
        content: (
          <div className="iq4-tour-live-roles">
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
              <Checkbox
                aria-label="Only show Internships"
                checked={showOnlyInternships}
                onChange={() => dispatch(setShowOnlyInternships(!showOnlyInternships))}
              />
              <div>Show Internships only</div>
            </div>
            {(!role.liveRoles || isFetchingJobs) && renderLoadingPanel()}
            {!role.liveRoles && !isFetchingJobs && (
              <p className="iq4-pathway-detail__empty_state">
                No Live Roles found for this workrole.
              </p>
            )}
            {!isFetchingJobs && role.liveRoles && role.liveRoles.length > 0 && (
              <OpenPositions role={role} />
            )}
          </div>
        ),
      },
    };

    setPanelsConfig(finalPanelConfig);
  }, [role, activeSkillsBarSegment, onViewRole, isAddingRoleToPathway]); // eslint-disable-line react-hooks/exhaustive-deps

  const addRoleToPathway = (role) => {
    dispatch(addPathwayReq(role));
    dispatch(addAddedSuggestion(role));
  };

  const removeRoleFromPathway = (role) => {
    dispatch(removePathwayReq(role));
    dispatch(removeAddedSuggestion(role));
  };

  const handleAddSkill = async (skill) => {
    await dispatch(addSkillFromWorkRoleReq({ skill, roleId: role.id }));
    dispatch(fetchRecommendedRolesReq());
    setTimeout(() => dispatch(fetchRoleMissingSkillsInfoReq({ role })), 200);
  };

  const handleRemoveSkill = async (skill) => {
    await dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: role.id }));
    dispatch(fetchRecommendedRolesReq());
    setTimeout(() => dispatch(fetchRoleMissingSkillsInfoReq({ role })), 200);
  };

  const prepareLocationVisible = () => {
    setIsLocationSelectionVisible(true);
    setTimeout(() => scrollToSelectionTop(), 1);
  };

  const prepareRoleSelectionVisible = () => {
    setIsRoleSelectionVisible(true);
  };

  function showJobFamilyExplanationFunc() {
    dispatch(setShowJobFamilyExplanation(!showJobFamilyExplanation));
    dispatch(setShowJobRoleExplanation(false));
  }

  function showJobRoleExplanationFunc() {
    dispatch(setShowJobRoleExplanation(!showJobRoleExplanation));
    dispatch(setShowJobFamilyExplanation(false));
  }

  const getStickyContent = () => {
    return (
      <>
        <div className="iq4-pathway-detail__selection-container--row">
          <div className="iq4-pathway-detail__selection-container--row-item-1">
            JOB FAMILY{' '}
            <SimpleIcon
              name="infoDefault"
              className="iq4-skill-card__proficiency--info"
              role="button"
              tabIndex={0}
              hidden={false}
              onClick={() => showJobFamilyExplanationFunc(true)}
              onKeyPress={() => showJobFamilyExplanationFunc(true)}
              aria-label="Get more info about what a Job Family is"
            />
          </div>
          <div className="iq4-pathway-detail__selection-container--row-item-2">
            {renderJobFamilyLabel(role.jobFamilyId)}
          </div>
          <div
            className="iq4-pathway-detail__selection-container--row-item-3"
            role="button"
            tabIndex={0}
            onClick={() => setIsJobFamilySelectionVisible(true)}
            onKeyPress={() => setIsJobFamilySelectionVisible(true)}
            aria-label={`Change job family. Current job family is ${renderJobFamilyLabel(
              role.jobFamilyId,
            )}`}
          >
            Change
          </div>
        </div>
        <div className="iq4-pathway-detail__selection-container--row">
          <div className="iq4-pathway-detail__selection-container--row-item-1">
            ROLE{' '}
            <SimpleIcon
              name="infoDefault"
              className="iq4-skill-card__proficiency--info"
              role="button"
              tabIndex={0}
              hidden={false}
              onClick={showJobRoleExplanationFunc}
              onKeyPress={showJobRoleExplanationFunc}
              aria-label="Get more info about what a Role is"
            />
          </div>
          <div className="iq4-pathway-detail__selection-container--row-item-2">{role.label}</div>
          <div
            className="iq4-pathway-detail__selection-container--row-item-3"
            role="button"
            tabIndex={0}
            onClick={prepareRoleSelectionVisible}
            onKeyPress={prepareRoleSelectionVisible}
            aria-label={`Change role. Current role is ${role.label}`}
          >
            Change
          </div>
        </div>
        <div className="iq4-pathway-detail__selection-container--row iq4-tour-location">
          <div className="iq4-pathway-detail__selection-container--row-item-1">LOCATION</div>
          <div className="iq4-pathway-detail__selection-container--row-item-2">{locationLabel}</div>
          <div
            role="button"
            tabIndex={0}
            onClick={prepareLocationVisible}
            onKeyPress={prepareLocationVisible}
            aria-label={`Change location. Current location is ${locationLabel}`}
            className="iq4-pathway-detail__selection-container--row-item-3"
          >
            Change
          </div>
        </div>
      </>
    );
  };

  const scrollToTop = () => {
    backRef &&
      backRef.current &&
      backRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const scrollToSelectionTop = () => {
    cancelRef && cancelRef.current && cancelRef.current.scrollIntoView();
  };

  const StickyContainer = () => (
    <div className="iq4-pathway-detail__selection-container-sticky">
      <>
        <div
          className="iq4-pathway-detail__selection-container-sticky--back"
          role="button"
          tabIndex={0}
          onClick={scrollToTop}
          onKeyPress={scrollToTop}
        >
          Back to top
        </div>
        {getStickyContent()}
      </>
    </div>
  );

  const renderJobFamilySelection = () => {
    const updateJobFamily = (jf) => {
      setChosenJobFamily(jf);
      setIsJobFamilySelectionVisible(false);
      setIsRoleSelectionVisible(true);
      setIsSelectingJobFamilyRole(true);
    };

    const selectedJobFamilyLabel = jobFamilies.find((x) => x.id === role.jobFamilyId).label;

    return (
      <div className="iq4-pathway-detail__selection-container">
        <button
          className="iq4-pathway-detail__selection-container--cancel"
          onClick={() => setIsJobFamilySelectionVisible(false)}
        >
          Cancel
        </button>
        <div className="iq4-pathway-detail__selection-container--title">
          JOB FAMILY{' '}
          <div
            className="iq4-categories__helper"
            role="button"
            tabIndex={0}
            onClick={showJobFamilyExplanationFunc}
            onKeyPress={showJobFamilyExplanationFunc}
          >
            What is a Job Family?
          </div>
        </div>
        <TextField
          value={selectedJobFamilyLabel}
          classes={{
            root: 'iq4-pathway-detail__location-input--field-input-root',
          }}
          InputProps={{
            readOnly: true,
            'aria-label': 'Select job family. E.g. "Programmers"',
            classes: {
              input: 'iq4-pathway-detail__location-input--field-input',
            },
          }}
          variant="outlined"
        />
        <div className="iq4-pathway-detail__selection-container--root">
          {jobFamilies.map((jf) => {
            const isSelectedJobFamily =
              selectedJobFamily.id === jf.id || role.jobFamilyId === jf.id;

            return (
              <button
                className={`iq4-pathway-detail__selection-container--item ${
                  isSelectedJobFamily ? 'iq4-pathway-detail__selection-container--selected' : ''
                }`}
                key={jf.id}
                onClick={isSelectedJobFamily ? undefined : () => updateJobFamily(jf)}
                aria-label={`Select ${jf.label}`}
              >
                <span className="iq4-pathway-detail__selection-container--label">{jf.label}</span>
                {isSelectedJobFamily && <CheckCircleIcon />}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  const renderLocationSelection = () => {
    const updateSelection = (value) => {
      updateSelectedLocation(value);
      setIsLocationSelectionVisible(false);
    };

    return (
      <div className="iq4-pathway-detail__selection-container">
        <button
          ref={cancelRef}
          className="iq4-pathway-detail__selection-container--cancel"
          onClick={() => setIsLocationSelectionVisible(false)}
        >
          Cancel
        </button>
        <div className="iq4-pathway-detail__selection-container--title">LOCATION</div>
        <TextField
          value={locationLabel}
          classes={{
            root: 'iq4-pathway-detail__location-input--field-input-root',
          }}
          InputProps={{
            readOnly: true,
            classes: {
              input: 'iq4-pathway-detail__location-input--field-input',
            },
          }}
          variant="outlined"
        />
        <div className="iq4-pathway-detail__selection-container--root">
          {usLocations.map((location) => {
            const isSelectedLocation = selectedLocation === location.id;
            return (
              <button
                className={`iq4-pathway-detail__selection-container--item ${
                  isSelectedLocation ? 'iq4-pathway-detail__selection-container--selected' : ''
                }`}
                key={location.id}
                onClick={isSelectedLocation ? undefined : () => updateSelection(location.id)}
              >
                <span className="iq4-pathway-detail__selection-container--label">
                  {location.label}
                </span>
                {isSelectedLocation && <CheckCircleIcon />}
              </button>
            );
          })}
        </div>
      </div>
    );
  };

  function handlePathwayDetailTourCallback(data) {
    if (data.origin === 'button_close') {
      dispatch(setPathwayDetailTour(false));
      return;
    }
    if (data.status === 'finished' && data.type === 'tour:end') {
      dispatch(setPathwayDetailTour(false));

      const button = document.querySelector('.iq4-tour-missing-chart');
      if (button) {
        button.click();
        dispatch(setDisplayMissingSkillsTour(true));
      }
    }
  }

  function handleSecondTourCallback(data) {
    if (data.origin === 'button_close') {
      dispatch(setDisplaySecondPathwayDetailTour(false));
      return;
    }

    if (data.status === 'finished' && data.type === 'tour:end') {
      dispatch(setDisplaySecondPathwayDetailTour(false));
    }
  }

  const isMakingAnySelection =
    isJobFamilySelectionVisible || isRoleSelectionVisible || isLocationSelectionVisible;

  const renderDefaultState = () => {
    if (isJobFamilySelectionVisible) {
      return renderJobFamilySelection();
    }

    if (isRoleSelectionVisible) {
      const handleCloseRoleSelection = () => {
        setIsRoleSelectionVisible(false);
        setChosenJobFamily(null);
        setIsSelectingJobFamilyRole(false);
      };

      return (
        <SelectWorkRoles
          jobFamily={chosenJobFamily ? chosenJobFamily : selectedJobFamily}
          onSelectWorkRole={handleSelectWorkRole}
          onClose={handleCloseRoleSelection}
          selectedWorkRole={isSelectingJobFamilyRole ? undefined : role}
        />
      );
    }

    if (isLocationSelectionVisible) {
      return renderLocationSelection();
    }

    const desktopSteps = [
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              Curious to learn more about this role? Here, you can find out the usual level of
              education achieved by people in this role.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-tour-education',
        disableBeacon: true,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              You can check the number of job openings expected in your chosen geographic area over
              the next ten years.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-tour-projection',
        disableBeacon: true,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              Lastly, you can see your potential earnings by checking the Annual Earnings for the
              geographic area you chose.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-tour-earnings',
        disableBeacon: true,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              If you're actively seeking a new job, you can always come here to explore current
              openings that might interest you. If you find one you like, click 'Learn More' to
              visit the company's website.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-open-positions-inner-card',
        disableBeacon: true,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              Have a specific title in mind? You can type in a key word or two here to search for
              relevant roles that we may not have suggested to you.
            </p>
          </div>
        ),
        disableOverlay: false,
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-roles-search-bar__input',
        disableBeacon: true,
        disableScrolling: false,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              Changed your mind about a role? You can remove it from your career pathways by
              clicking on this icon.
            </p>
          </div>
        ),
        disableOverlay: false,
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.delete-role',
        disableBeacon: true,
        disableScrolling: false,
      },
    ];

    const mobileSteps = [
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              Curious to learn more about this role? Here, you can find out the usual level of
              education achieved by people in this role.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-tour-education',
        disableBeacon: true,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              You can check the number of job openings expected in your chosen geographic area over
              the next ten years.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-tour-projection',
        disableBeacon: true,
      },
      {
        content: (
          <div style={{ padding: '1rem' }}>
            <p style={{ fontSize: '0.875rem' }}>
              Lastly, you can see your potential earnings by checking the Annual Earnings for the
              geographic area you chose.
            </p>
          </div>
        ),
        disableOverlay: false,
        disableScrolling: false,
        placement: isMobile ? 'top' : 'right',
        styles: {
          options: {
            zIndex: 10200,
          },
        },
        target: '.iq4-tour-earnings',
        disableBeacon: true,
      },
    ];

    return (
      <>
        <div aria-live="polite" role="alert" className="sr-only">
          You are now inside the {role.label} role details.
        </div>
        <div className="iq4-pathway-detail__header">
          <button
            ref={backRef}
            className="iq4-pathway-detail__header-title iq4-pathway-detail__header-title--small-font"
            onClick={onBackClick}
          >
            <SimpleIcon name="arrow" /> Back to Career Pathways
          </button>

          <h2 className="iq4-pathway-detail__header-name">{role.label}</h2>
          {!role.isSelected &&
            !readOnly &&
            !isFetchingSelectedWorkRoles &&
            !isPublicProfile &&
            selectedWorkRoles?.length < 6 &&
            !isAddingRoleToPathway && (
              <Button
                variation="ghost"
                onClick={() => addRoleToPathway(role)}
                className="iq4-pathway-detail__header__add-to-pathways"
              >
                <SimpleIcon
                  className="iq4-pathway-detail__header__add-to-pathways__icon"
                  name="add"
                  role="presentation"
                />
                Add to Pathway
              </Button>
            )}
          {isAddingRoleToPathway && (
            <CircularProgress
              size={32}
              className="iq4-30__loading__spinner"
              style={{ marginTop: '1rem', marginLeft: '2rem' }}
            />
          )}

          {role.isSelected && !readOnly && !isPublicProfile && !isAddingRoleToPathway && (
            <Button
              variation="ghost"
              onClick={() => removeRoleFromPathway(role)}
              className="iq4-pathway-detail__header__add-to-pathways"
            >
              <SimpleIcon
                className="iq4-pathway-detail__header__add-to-pathways__icon"
                name="bin"
                role="presentation"
              />
              Delete this role
            </Button>
          )}

          {!showTour && !showSecondTour && (
            <div ref={stickyRef} className="iq4-pathway-detail__sticky-placeholder" />
          )}
        </div>
        <div
          className={`${
            !isStickyMainVisible && !isMobile && !isPublicProfile && !showTour && !showSecondTour
              ? 'iq4-pathway-detail__selection-container-sticky'
              : 'iq4-pathway-detail__selection-container'
          } ${showTour ? 'iq4-tour' : ''}`}
          style={{ zIndex: 9999, maxHeight: '100vh', overflowY: 'auto' }}
        >
          {!isPublicProfile && (
            <>
              {!isStickyMainVisible && !isMobile && (
                <div
                  className="iq4-pathway-detail__selection-container-sticky--back"
                  role="button"
                  tabIndex={0}
                  onClick={scrollToTop}
                  onKeyPress={scrollToTop}
                >
                  Back to top
                </div>
              )}
              {isStickyMainVisible && (
                <div className="iq4-pathway-detail__selection-container-sticky--reference" />
              )}
              {getStickyContent()}
            </>
          )}
        </div>
        {panelsConfig &&
          Object.entries(panelsConfig).map(([id, index]) => {
            const panel = panelsConfig[id];
            return (
              <>
                {panel.title === 'LIVE ROLES' && (
                  <div
                    style={{
                      padding: '1rem 8px',
                      border: '1px solid #e4e4e4',
                      height: '8rem',
                      fontSize: '0.9375rem',
                    }}
                  >
                    The data presented above is compiled from various leading U.S. labor market
                    information sources. <br />
                    This information is continuously updated in an effort to reflect the most
                    current market trends.
                  </div>
                )}
                <Expand
                  classes={{
                    root: 'iq4-pathway-detail__expand',
                  }}
                  key={`${panel.title}-${index}`}
                  title={panel.title}
                  expandByDefault={panel.isDefaultExpanded}
                >
                  {panel.content}
                </Expand>
              </>
            );
          })}
        {showTour && loadedScreen && (
          <Joyride
            disableBeacon={true}
            callback={handlePathwayDetailTourCallback}
            steps={[
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      If you want to add the role you're exploring, simply click here. It will
                      update with more information as one of your pathways. The blue and black
                      colors around a job role show your matched or missing skills.
                    </p>
                  </div>
                ),
                disableOverlay: false,
                styles: {
                  options: {
                    zIndex: 10200,
                  },
                },
                target: '.iq4-pathway-detail__header__add-to-pathways',
                disableBeacon: true,
              },
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      While you're browsing this section, active job postings for this role will be
                      shown to you. You can update your location preference here to focus your
                      exploration on a single state.
                    </p>
                  </div>
                ),
                disableScrolling: false,
                disableOverlay: false,
                styles: {
                  options: {
                    zIndex: 10200,
                  },
                },
                target: '.iq4-tour-location',
                disableBeacon: true,
              },
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      If this role doesn't feel like the right fit for you, you can view and explore
                      similar roles that might be a better match.
                    </p>
                  </div>
                ),
                disableScrolling: false,
                disableOverlay: false,
                placement: isMobile ? 'auto' : 'right',
                styles: {
                  options: {
                    zIndex: 10200,
                  },
                },
                target: '.iq4-tour-similar',
                disableBeacon: true,
              },
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      Clicking on the arrows next to 'Matched' or 'Missing' will show you the skills
                      you have that match this role or the ones you do not have. Let's explore your
                      missing skills for the role you're investigating.
                    </p>
                  </div>
                ),
                disableOverlay: false,
                spotlightClicks: true,
                placement: isMobile ? 'auto' : 'right',
                styles: {
                  options: {
                    zIndex: 10200,
                  },
                },
                target: '.iq4-tour-skills-overlap',
                disableBeacon: true,
              },
            ]}
            showProgress={false}
            continuous={true}
            run={true}
            disableOverlayClose={true}
            scrollToFirstStep={true}
            scrollOffset={isMobile ? 80 : 350}
            styles={{
              buttonBack: {
                backgroundColor: 'transparent',
                color: '#0070F0',
              },
              buttonNext: {
                backgroundColor: '#0070F0',
                color: 'white',
              },
            }}
            locale={{
              last: 'Next',
            }}
          />
        )}
        {showSecondTour && (
          <Joyride
            disableBeacon={true}
            callback={handleSecondTourCallback}
            steps={isMobile ? mobileSteps : desktopSteps}
            showProgress={false}
            continuous={true}
            run={true}
            disableOverlayClose={true}
            showPrevButton={false}
            scrollToFirstStep={true}
            scrollOffset={isMobile ? 40 : 350}
            styles={{
              buttonBack: {
                backgroundColor: 'transparent',
                color: '#0070F0',
              },
              buttonNext: {
                backgroundColor: '#0070F0',
                color: 'white',
              },
            }}
            locale={{
              last: 'Finish Tour',
            }}
          />
        )}
      </>
    );
  };

  const renderWorkRoleSkillsState = () => {
    const renderOutcomeType = (outcomeType) => {
      return outcomeType?.includes('Emsi Hard Skill')
        ? 'hard'
        : outcomeType?.includes('Emsi Common Skill')
        ? 'common'
        : null;
    };

    const PANEL_PROPS = {
      userSkills: !role.skills
        ? []
        : role.skills.userSkills
            .map((skill, i) => ({
              id: skill.competenceId,
              value: skill.label,
              description: skill.description,
              outcomeDescriptionUrl: skill.outcomeDescriptionUrl,
              category: skill.competenceGroup,
              type: renderOutcomeType(skill?.outcomeType),
              achievements: filterByInstitution
                ? skill.achievements
                  ? skill.achievements.filter(
                      (achievement) => achievement?.school?.name === filterByInstitution?.name,
                    )
                  : []
                : skill.achievements,
              tier1: skill.tier1,
              tier2: skill.tier2,
              tier3: skill.tier3,
              sources: skill.sources,
            }))
            .sort((a, b) => {
              var valueA = a.value.toUpperCase();
              var valueB = b.value.toUpperCase();
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            }),
      overlapSkills: !role.skills
        ? []
        : role.skills.overlapSkills
            .map((skill, i) => ({
              id: skill.competenceId,
              value: skill.label,
              description: skill.description,
              outcomeDescriptionUrl: skill.outcomeDescriptionUrl,
              category: skill.competenceGroup,
              type: renderOutcomeType(skill?.outcomeType),
              achievements: filterByInstitution
                ? skill.achievements
                  ? skill.achievements.filter(
                      (achievement) => achievement?.school?.name === filterByInstitution?.name,
                    )
                  : []
                : skill.achievements,
              tier1: skill.tier1,
              tier2: skill.tier2,
              tier3: skill.tier3,
              sources: skill.sources,
            }))
            .sort((a, b) => {
              var valueA = a.value.toUpperCase();
              var valueB = b.value.toUpperCase();
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            }),
      roleSkills: !role.skills
        ? []
        : role.skills.roleSkills
            .map((skill, i) => ({
              id: skill.id,
              value: skill.label,
              description: skill.description,
              outcomeDescriptionUrl: skill.outcomeDescriptionUrl,
              category: skill.competenceGroup.toLowerCase(),
              type: renderOutcomeType(skill?.outcomeType),
              achievements: filterByInstitution
                ? skill.achievements
                  ? skill.achievements.filter(
                      (achievement) => achievement?.school?.name === filterByInstitution?.name,
                    )
                  : []
                : skill.achievements,
              tier1: skill.tier1,
              tier2: skill.tier2,
              tier3: skill.tier3,
              sources: skill.sources,
            }))
            .sort((a, b) => {
              var valueA = a.value.toUpperCase();
              var valueB = b.value.toUpperCase();
              return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            }),
    };

    return (
      <div className="iq4-pathway-detail__work-role-skills">
        <RoleSkillsInfoPanel
          {...PANEL_PROPS}
          roleTitle={label}
          activeBar={activeSkillsBarSegment}
          onBackClick={(runSecondTour = false) => {
            setBackPanelState(PANEL_STATES.DEFAULT);
            setBackPanelState(PANEL_STATES.DEFAULT);
            setCurrentPanelState(backPanelState);

            if (runSecondTour) {
              setTimeout(() => dispatch(setDisplaySecondPathwayDetailTour(true)), 100);
            }
          }}
          onActiveBarChange={setActiveSkillsBarSegment}
          onSkillClick={(skill) => {
            setActiveSkill(skill);
            dispatch(fetchTShapeSkillInfo({ id: skill.id }));
            setBackPanelState(PANEL_STATES.WORK_ROLE_SKILLS_OVERVIEW);
            setCurrentPanelState(PANEL_STATES.INDIVIDUAL_SKILL_INFO);
          }}
          onSkillToggleClick={async (skill) => {
            const isSkillAlreadyAdded = activeSkillsBarSegment !== 'MISSING_SKILLS';
            if (isSkillAlreadyAdded) {
              await dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: role.id }));
            } else {
              await dispatch(addSkillFromWorkRoleReq({ skill, roleId: role.id }));
            }

            dispatch(fetchRecommendedRolesReq());
            setTimeout(() => dispatch(fetchRoleMissingSkillsInfoReq({ role })), 200);
          }}
        />
      </div>
    );
  };

  const renderIndividualSkillState = () => (
    <div className="iq4-pathway-detail__individual-skill-info">
      <RoleIndividualSkillInfoPanel
        achievements={activeSkill?.achievements}
        skill={activeSkill}
        onBackClick={() => {
          setBackPanelState(PANEL_STATES.DEFAULT);
          setCurrentPanelState(backPanelState);
          setActiveSkill(null);
          dispatch(setSelectedSkillInfo(null));
        }}
        onAdd={(skill) => handleAddSkill(skill)}
        onRemove={(skill) => handleRemoveSkill(skill)}
        readOnly={readOnly}
      />
    </div>
  );

  const renderCoreTasksState = () => (
    <div className="iq4-pathway-detail__core-tasks-info">
      <CoreTasks
        tasks={role['jobTasks']}
        onBackClick={() => {
          setBackPanelState(PANEL_STATES.DEFAULT);
          setCurrentPanelState(backPanelState);
        }}
      />
    </div>
  );

  return (
    <>
      {currentPanelState === PANEL_STATES.DEFAULT &&
        !isMakingAnySelection &&
        !isStickyMainVisible &&
        isMobile && <StickyContainer />}
      <div className="iq4-pathway-detail">
        {currentPanelState === PANEL_STATES.DEFAULT && renderDefaultState()}
        {currentPanelState === PANEL_STATES.WORK_ROLE_SKILLS_OVERVIEW &&
          renderWorkRoleSkillsState()}
        {currentPanelState === PANEL_STATES.INDIVIDUAL_SKILL_INFO && renderIndividualSkillState()}
        {currentPanelState === PANEL_STATES.CORE_TASKS && renderCoreTasksState()}
      </div>
      <MiddleStepModal step={step} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};
