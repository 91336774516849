import React from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { updateSkillKSAReq } from 'common/store/features/skills/skillsSlice';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';
import './SkillDetails.scss';

export const SkillDetails = ({ skill, readOnly = false }) => {
  const dispatch = useDispatch();
  const hasKsas = !!(skill.ksas && skill.ksas.length);
  const isVerified = !!skill?.isVerified;

  const handleToggleKSA = (ksa) => {
    dispatch(
      updateSkillKSAReq({
        ksa: {
          ...ksa,
          isSelected: !ksa.isSelected,
        },
      }),
    );
  };

  const verifiedKsas = skill.ksas?.filter((ksa) => ksa.isVerified);
  const unverifiedKsas = skill.ksas?.filter((ksa) => !ksa.isVerified);

  return (
    <ul className="iq4-manage-skills__skill-details">
      {!hasKsas && (
        <span
          className="iq4-manage-skills__skill-details--empty"
          style={{ marginBottom: '1.5rem' }}
        >
          There are no skill details for this skill
        </span>
      )}
      {!!verifiedKsas.length && (
        <span className="iq4-__skills-details-title">
          The following list of descriptors associated with the above skill are verified:
        </span>
      )}
      {!!verifiedKsas.length &&
        verifiedKsas.map((ksa) => {
          const isSelected = ksa.isSelected;
          return (
            <li className="iq4-manage-skills__skill-details--content">
              <Checkbox
                disabled={readOnly || ksa.isVerified}
                disableRipple
                checked={isSelected}
                {...(ksa.isVerified && {
                  checkedIcon: (
                    <div className="iq4__skills-verified-icon-container-check">
                      <Verified />
                    </div>
                  ),
                })}
                onChange={() => handleToggleKSA(ksa)}
                classes={{
                  root: isSelected
                    ? 'iq4-manage-skills__skill-details--checkbox-selected'
                    : 'iq4-manage-skills__skill-details--checkbox-unselected',
                  input: 'iq4-manage-skills__skill-details--checkbox-input',
                }}
                inputProps={{
                  'aria-label': `Checkbox for KSA - ${ksa.label}`,
                }}
              />

              <span
                className={isSelected ? '' : 'iq4-manage-skills__skill-details--unselected-label'}
              >
                {ksa.label}
              </span>
            </li>
          );
        })}
      {!!unverifiedKsas.length && (
        <span
          className="iq4-__skills-details-title"
          style={{ marginTop: !verifiedKsas.length ? 0 : '2rem' }}
        >
          You can select from the following list of descriptors:
        </span>
      )}
      {unverifiedKsas.length > 0 &&
        unverifiedKsas.map((ksa) => {
          const isSelected = ksa.isSelected;
          return (
            <li className="iq4-manage-skills__skill-details--content" key={ksa.id}>
              <Checkbox
                disabled={readOnly || ksa.isVerified}
                disableRipple
                checked={isSelected}
                {...(ksa.isVerified && {
                  checkedIcon: (
                    <div className="iq4__skills-verified-icon-container-check">
                      <Verified />
                    </div>
                  ),
                })}
                onChange={() => handleToggleKSA(ksa)}
                classes={{
                  root: isSelected
                    ? 'iq4-manage-skills__skill-details--checkbox-selected'
                    : 'iq4-manage-skills__skill-details--checkbox-unselected',
                  input: 'iq4-manage-skills__skill-details--checkbox-input',
                }}
                inputProps={{
                  'aria-label': `Checkbox for KSA - ${ksa.label}`,
                }}
              />

              <span
                className={isSelected ? '' : 'iq4-manage-skills__skill-details--unselected-label'}
              >
                {ksa.label}
              </span>
            </li>
          );
        })}
    </ul>
  );
};
