import React from 'react';
import { FormWrapper } from 'common/components/Form';
import './ImageUpload.scss';

export const ImageUpload = ({ values, useFormDefinition, onComplete }) => {
  const onFormComplete = () => {
    onComplete && onComplete();
  };

  return (
    <div className="iq4-image-upload">
      <FormWrapper
        values={values}
        onComplete={onFormComplete}
        useFormDefinition={useFormDefinition}
        showButton={true}
        exposeFormContext={() => {}}
      />
    </div>
  );
};
