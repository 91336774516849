import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useFormContext, Controller } from 'react-hook-form';
import { ValidationMessage } from '../../../ValidationMessage';
import { FormattedMessage } from 'react-intl';
import { isEqual } from 'lodash';
import './AccessibleSelect.scss';

/**
 * Inner Select that gets enhanced by RHF's HOC
 * @param id
 * @param name
 * @param label
 * @param placeholder
 * @param onChange
 * @param errors
 * @param formState
 * @param maxWidth
 * @param options
 * @param ariaLabel
 * @returns {*}
 * @constructor
 */
export const InnerSelect = ({
  id,
  name,
  label,
  placeholder,
  onChange,
  errors,
  formState,
  maxWidth,
  options,
  value,
  exposeFormContext,
  triggerExternalValidation,
  isDisabled = false,
  validators,
  ariaLabel,
}) => {
  const [selectedValue, setSelectedValue] = useState(-1);
  const touched = formState.touched[name];
  const dirty = formState.dirty;
  const hasError = errors[name];
  const isSubmitted = formState.isSubmitted;

  const { triggerValidation } = useFormContext();

  const showError = () => {
    if (exposeFormContext && !dirty) return false;
    return (touched || isSubmitted || triggerExternalValidation) && hasError;
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedValue(options[value]);
    onChange(options[value].key);
    triggerValidation(name);
  };

  useEffect(() => {
    // specifying undefined because we might want to set to 0
    if (value !== undefined) {
      const index = options.findIndex((option) => isEqual(option.key, value));
      setSelectedValue(index);
    } else {
      setSelectedValue(-1);
    }
  }, [value, options]);

  const isRequiredField = !!validators?.['required'];

  return (
    <div className="iq4-select__wrapper">
      {label && (
        <label className="iq4-select__label" htmlFor={id || name}>
          {label}
          {isRequiredField && (
            <>
              <span className="iq4-field__required-mark">*</span>
              <span className="sr-only">Required field</span>
            </>
          )}
        </label>
      )}
      <FormControl
        variant="outlined"
        className={`iq4-select__form-control ${
          maxWidth ? 'iq4-select__form-control-max-width' : ''
        } ${showError() ? 'iq4-select__form-control--error' : ''}`}
      >
        <NativeSelect
          value={selectedValue}
          onChange={handleChange}
          IconComponent={ExpandMoreIcon}
          inputProps={{
            id: id || name,
            name,
            'aria-label': ariaLabel || `Please fill in the ${placeholder || label || name} value`,
          }}
          disabled={isDisabled}
          classes={{
            outlined: 'iq4-select__input',
          }}
        >
          {placeholder && (
            <option value={-1} disabled>
              {placeholder}
            </option>
          )}
          {options && options.map((option, index) => <option value={index}>{option.value}</option>)}
        </NativeSelect>
      </FormControl>
      {hasError &&
        showError() &&
        (hasError.type === 'validate' ? (
          <>
            <span
              className="sr-only"
              aria-live="polite"
              role="alert"
              aria-label={`${name} - ${hasError.message}`}
            ></span>
            <ValidationMessage id={`${name}Error`} message={`${name} - ${hasError.message}`} />
          </>
        ) : (
          <>
            <span
              className="sr-only"
              aria-live="polite"
              role="alert"
              aria-label={`Please fill in the ${placeholder || label || name} value`}
            ></span>
            <ValidationMessage
              id={`${name}Error`}
              message={<FormattedMessage id="form.validation.required" />}
            />
          </>
        ))}
    </div>
  );
};

/**
 * Select wrapper
 * This needs to use the Controller component from react-hook-form
 * see: https://react-hook-form.com/api#Controller
 * @param name
 * @param props
 * @returns {*}
 * @constructor
 */
export const AccessibleSelect = ({ name, validators, onChange, ...props }) => {
  const { control, getValues } = useFormContext();
  const values = getValues();

  return (
    <Controller
      as={<InnerSelect {...props} />}
      control={control}
      rules={{ ...validators }}
      onChange={([selected]) => {
        onChange && onChange(selected);
        return selected;
      }}
      name={name}
      value={values[name]}
      validators={validators}
    />
  );
};
