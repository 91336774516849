import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { SimpleIcon } from '../SimpleIcon';
import './Dropdown.scss';

/**
 * Dropdown component
 * @param {object[]} options - the dropdown options: [{id, label}]
 * @param {object} value - the selected value
 * @param {function} onChange - handle change of dropdown
 * @param {string} [className] - custom className
 * @param {string} [menuClass] - custom className for dropdown menu
 * @param {string} [placeholder] - placeholder for the dropdown
 * @param {boolean} [disabled] - disabled flag
 */
export const Dropdown = ({
  options,
  value,
  className,
  placeholder,
  onChange,
  menuClass,
  disabled,
}) => {
  return (
    <Select
      classes={{
        root: 'iq4-dropdown__select-root',
      }}
      value={value}
      onChange={onChange}
      disabled={!!disabled}
      input={
        <InputBase
          classes={{
            root: `iq4-dropdown ${className ? className : ''}`,
            focused: 'iq4-dropdown--focused',
          }}
          fullWidth
          placeholder={placeholder}
        />
      }
      IconComponent={() => <SimpleIcon name="caretDown" />}
      MenuProps={{
        classes: {
          paper: 'iq4-dropdown__menu-container',
          list: `iq4-dropdown__menu-list ${menuClass ? menuClass : ''}`,
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            maxHeight: 60 * 5 + 20,
            width: 240,
          },
        },
      }}
      displayEmpty
    >
      <MenuItem
        value=""
        disabled
        classes={{ root: 'iq4-dropdown__option iq4-dropdown__option--placeholder' }}
      >
        {placeholder}
      </MenuItem>

      {options.map((option) => (
        <MenuItem
          disabled={option.disabled}
          key={option.id}
          value={option.id}
          classes={{ root: 'iq4-dropdown__option' }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
