import React from 'react';
import { Pill } from 'common/components';
import './CredentialPill.scss';

export const CredentialPill = ({ credential }) => {
  const { verifiedBy } = credential;

  return (
    <Pill
      className="iq4-credential-pill"
      label={verifiedBy ? `Verified` : 'Self Attested'}
      icon={verifiedBy ? 'tick' : null}
      type={verifiedBy ? 'verified' : 'selfAttested'}
    />
  );
};
