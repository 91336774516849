const context = process.env.REACT_APP_CONTEXT;
const taxonomy = process.env.REACT_APP_IQ4_TAXONOMY;
const BADGR_OAUTH_URL = process.env.REACT_APP_BADGR_OAUTH_URL;

export const PROFILE_URL = `${context}/api/v2/user/profile`;
export const PROFILE_PERSONAL_URL = `${context}/api/v2/user/profile/personal`;
export const PROFILE_EDUCATION_URL = `${context}/api/v2/user/profile/education`;
export const PROFILE_EXPERIENCE_URL = `${context}/api/v2/user/profile/experience`;
export const PROFILE_PORTFOLIO_URL = `${context}/api/v2/user/profile/portfolio`;
export const PROFILE_AVATAR_UPLOAD_URL = `${context}/api/v2/user/profile/personal/photo`;
export const PROFILE_ASSOCIATE_PORTFOLIO_URL = `${context}/api/v2/user/profile/associate/portfolio`;
export const PROFILE_AGREE_TERMS_URL = `${context}/api/v2/user/profile/agreeTerms`;
export const PROFILE_SHARE_URL = `${context}/api/v2/user/profile/share/setting`;
export const PUBLIC_PROFILE_URL = `${context}/api/v2/user/profile/info`;
export const SKILLS_SEARCH_URL = `${context}/api/v2/skill/search`;
export const MY_SKILLS_URL = `${context}/api/v2/user/profile/skills`; // dev server api
export const SAVE_SKILLS_RANK = `${context}/api/v2/user/profile/skills/rank`;
export const SAVE_MY_SKILLS_URL = `${context}/api/v2/user/profile/skills`;
export const SKILLS_URL = `${context}/api/v2/user/profile/skills`;

export const TOP_SKILLS_URL = `${context}/api/user/passport/top/skills`;
export const SKILL_DETAILS_URL = `${context}/api/v2/user/profile/skills/details`;
export const SKILLS_KSA_URL = `${context}/api/v2/user/profile/skills/manage`;
export const SKILLS_ASSESSMENT_URL = `${context}/api/v2/user/profile/skills/assessment`;
export const SKILL_SOURCE_URL = `${context}/api/v2/user/profile/skills/source`;
export const SKILLS_PERMISSIONS_URL = `${context}/api/v2/user/profile/skills/privacy`;
export const RECOMMENDED_SKILLS_URL = `${context}/api/v2/skill/recommended`;
export const RESUME_PARSER_URL = `${context}/api/v2/parse?sovrentax=V2plus&useclassify=true&classifyresults=true&threshold=.3&type=resume`;

export const PATHWAYS_URL = `${context}/api/v2/pathways/user`;
export const PATHWAYS_FRAMEWORK_URL = `${context}/api/v2/pathways/framework`;
export const PATHWAYS_WORK_ROLE_URL = `${context}/api/v2/pathways/workrole`;
export const PATHWAYS_JOB_FAMILIES_URL = `${context}/api/v2/pathways/framework/jobFamilies`;
export const PATHWAYS_JOB_FAMILY_WORKROLES_URL = `${context}/api/v2/pathways/framework/workRole`;
export const PATHWAYS_USER_SELECTED_WORKROLES_URL = `${context}/api/v2/pathways/framework/workRole/selected`;
export const PATHWAYS_SEARCH_URL = `${context}/api/v2/pathways/framework/workRole/search`;
export const PATHWAYS_RECOMMENDED_URL = `${context}/api/v2/pathways/recommended`;
export const PATHWAYS_EMSI_CAREER_URL = `${context}/servlet/emsi/careers/us`;

export const PATHWAYS_COS_JOBS_URL = `${context}/servlet/cos/v1/jobsearch`;
export const PATHWAYS_COS_DEMOGRAPHICS_URL = `${context}/servlet/cos/v1/occupation`;
export const PATHWAYS_COS_VIDEO_CONTENT_URL =
  'https://cdn.careeronestop.org/OccVids/OccupationVideos';

export const NSC_STUDENT_PROFILE_URL = `${context}/servlet/nsc/api/student-account/student-profile/me`;

// Holder
// Get Counts e.g. https://api.dev.myhub.org/myhub/holder/wallet/credential/counts [GET]
export const LCN_GET_COUNTS_URL = `${context}/servlet/nsc/myhub/holder/wallet/credential/counts`;

// Holder - Connections
// Get Connection Requests by status e.g. https://api.dev.myhub.org/myhub/holder/wallet/connections/status/Accept [GET]
export const LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL = `${context}/servlet/nsc/myhub/holder/wallet/connections/status`;

// Get Connection Detail by ID e.g. https://api.dev.myhub.org/myhub/holder/wallet/connections/43543vdv5dfsdfsf [GET]
export const LCN_GET_CONNECTION_DETAIL_BY_ID_URL = `${context}/servlet/nsc/myhub/holder/wallet/connections`;

// Create a New Connection Request e.g. https://api.dev.myhub.org/myhub/holder/wallet/connections [POST]. See https://docs.google.com/document/d/1ANiMDk-dVm8ZT3BgQOUhWd7IOeor80e_HlDPIuuZZpQ/edit?ts=5f1e98f2
export const LCN_CREATE_NEW_CONNECTION_REQUEST_URL = `${context}/servlet/nsc/myhub/holder/wallet/connections`;

// Approve/Reject Connection Request e.g. https://api.dev.myhub.org/myhub/holder/wallet/connections [PATCH]
export const LCN_APPROVE_REJECT_CONNECTION_REQUEST_URL = `${context}/servlet/nsc/myhub/holder/wallet/connections`;

// Holder - Permissions
// Update Permissions e.g. https://api.dev.myhub.org/myhub/holder/wallet/credential/permissions [POST]
export const LCN_UPDATE_PERMISSIONS_URL = `${context}/servlet/nsc/myhub/holder/wallet/credential/permissions`;

// Holder - WALLET
// Get Credential by ID e.g. https://api.dev.myhub.org/myhub/holder/wallet/credential/fsdfsaf325325325 [GET]
export const LCN_GET_CREDENTIAL_BY_ID_URL = `${context}/servlet/nsc/myhub/holder/wallet/credential`;

// Get Credentials by Status // e.g. 1) Get My Accepted Credentials - My Record https://api.dev.myhub.org/myhub/holder/wallet/credential/status/accept [GET], 2) Get New Credentials https://api.dev.myhub.org/myhub/holder/wallet/credential/status/new [GET]
export const LCN_GET_CREDENTIALS_BY_STATUS_URL = `${context}/servlet/nsc/myhub/holder/wallet/credential/status`;

// Update Credential Status // e.g. https://api.dev.myhub.org/myhub/holder/wallet/credential/status [POST]
export const LCN_UPDATE_CREDENTIAL_STATUS_URL = `${context}/servlet/nsc/myhub/holder/wallet/credential/status`;

// Get Verifier Organizations e.g. https://api.dev.myhub.org/myhub/verifiers [GET]
export const LCN_GET_VERIFIER_ORGANIZATIONS_URL = `${context}/servlet/nsc/myhub/verifiers`;

// Get Verify Credentials URL e.g. https://api.dev.myhub.org/myhub/verify/credential [GET]
export const LCN_GET_VERIFY_CREDENTIALS_URL = `${context}/servlet/nsc/myhub/verify/credential`;

// New Endpoints
export const LCN_WALLET_PROFILE = `${context}/servlet/nsc/myhub/wallet/profile`;
export const LCN_BADGR_AUTHENTICATE = `${context}/servlet/nsc/myhub/badgr/authenticate`;
export const LCN_UPDATE_CREDENTIAL_DISPUTE_URL = `${context}/servlet/nsc/myhub/holder/wallet/credential/dispute`;
export const LCN_CONNECT_TO_PROVIDER = `${context}/servlet/badgr/myhub/badgr/badges`;
export const LCN_CREDENTIAL_SUMMARY = `${context}/servlet/nsc/myhub/holder/wallet/credential/summary`;

export const DASHBOARD_PROFILE_URL = `${context}/servlet/nsc/sso/profile/me`;
export const DASHBOARD_FRAMEWORK_URL = `${context}/api/v2/dashboard/framework`;
export const DASHBOARD_RECOMMENDED_URL = `${context}/api/v2/pathways/recommended`;
export const DASHBOARD_WORK_ROLE_URL = `${context}/api/v2/pathways/workrole`;
export const DASHBOARD_PATHWAYS_FRAMEWORK_URL = `${context}/api/v2/pathways/framework`;

export const DASHBOARD_USER_URL = `${context}/api/v2/dashboard/user`;
export const DASHBOARD_USERS_URL = `${context}/api/v2/dashboard/all/users`;
export const DASHBOARD_ACHIEVEMENTS_URL = `${context}/api/v2/dashboard/achievements`;
export const DASHBOARD_ACHIEVEMENT_URL = `${context}/api/v2/dashboard/achievement`;
export const DASHBOARD_SKILLS_URL = `${context}/api/v2/dashboard/skills`;
export const DASHBOARD_SKILL_URL = `${context}/api/v2/dashboard/skill`;
export const DASHBOARD_QUERY_URL = `${context}/api/v2/dashboard/query`;
export const DASHBOARD_TOP_CARDS_URL = `${context}/api/v2/dashboard/topCard`;

export const DASHBOARD_SKILLS_COUNTS_URL = `${context}/api/v2/dashboard/skills/count`;
export const DASHBOARD_SKILL_DETAILS_URL = `${context}/api/v2/dashboard/skills/details`;
export const PATHWAYS_ALL_WORK_ROLES_URL = `${context}/api/job`;
export const AUTHENTICATION_URL = `${context}/login/authenticate`;
export const ACTIVATE_CODE_URL = `${context}/api/activation/code/validate`;
export const GET_T_SHAPE_URL = `${context}/api/user/tshape/status/t`;
export const GET_SKILL_INFO_URL = `${context}/api/v2/user/profile/skills`;
export const STUDENT_LOOKUP_URL = `${context}/servlet/nsc/api/myhub/counselor/studentLookup`;
export const INVITE_STUDENT_URL = `${context}/api/invite/student`;
export const INVITE_STUDENT_STATUS_URL = `${context}/api/invite/student/status/update`;
export const PARSE_SKILLS_ENDPOINT = `${context}/api/v2/parse/badge`;
export const CREDENTIALS_API_URL = `${context}/api/v2/user/profile/credentials`;
export const CREDENTIAL_SKILLS_API_URL = `${context}/api/v2/user/profile/credential/skills`;
export const CREDENTIAL_DISPUTE_API_URL = `${context}/api/v2/user/credential/dispute`;
export const CREDENTIALS_STATUS_API_URL = `${context}/api/v2/user/profile/credentials/status`;
export const BADGR_AUTH_URL = BADGR_OAUTH_URL;
export const BADGR_CREDENTIALS_URL = `${context}/servlet/badgr/v2/backpack/assertions`;
export const BADGR_LOGOUT_URL = `${context}/servlet/badgr/v2/logout`;
export const REGISTER_NEW_USER_URL = `${context}/api/user/register/`;
export const SEND_VERIFY_EMAIL_NEW_USER_URL = `${context}/api/user/register/invite?type=verify`;
export const SCHOOLS_AVAILABLE_FOR_REGISTERING_USERS = `${context}/api/v2/dashboard/institutions`;
export const EMPLOYER_WALLET_SHARE_SETTINGS_URL = `${context}/api/v2/user/profile/share/setting`;
export const USER_FEEDBACK_URL = `${context}/api/feedback/user`;

export const RESET_PASSWORD_URL = `${context}/api/reset-password`;
export const CHANGE_PASSWORD_URL = `${context}/api/v2/user/profile/updatePassword`;

export const ROLE_LEARNING_RESOURCES_URL = `${context}/api/job/learningResource`;

export const ROLE_MISSING_SKILLS_INFO_URL = `${context}/api/v2/user/profile/missing/role`;
