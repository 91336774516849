import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer,
  SimpleIcon,
  RoleIndividualSkillInfoPanel,
  LoadingSkeleton,
  Pill,
} from 'common/components';
import { fetchTShapeSkillInfo, fetchTShapeGraphicData } from 'common/store/features/t/slice';
import { selectTShapeData, selectTShapeSelectedSkill } from 'common/store/features/t/selectors';
import { TShapeV2 } from 'common/components';
import { selectProfile } from 'common/store/features/profile/selectors';
import { CATEGORY_LABELS, getMySkillsTShapeData } from 'common/utils';
import { selectMySkills } from 'common/store/features/skills/selectors';
import {
  addSkillFromWorkRoleReq,
  removeSkillFromWorkRoleReq,
} from 'common/store/features/skills/skillsSlice';
import './TShapePage.scss';
import FocusLock from 'react-focus-lock';

export const TShapePage = ({
  onClose,
  title = 'My Skills T',
  renderOnlySelfSkills = false,
  selectedRole,
}) => {
  const dispatch = useDispatch();
  const tShapeGraphicData = useSelector(selectTShapeData);
  const tShapeSelectedSkill = useSelector(selectTShapeSelectedSkill);
  const { personal } = useSelector(selectProfile);
  const mySkills = useSelector(selectMySkills);
  const [selectedTShapeRole, setSelectedTShapeRole] = useState('');
  const [showTShapeHelp, setShowTShapeHelp] = useState(false);

  // get tshape data for the selected role when the role changes
  useEffect(() => {
    if (selectedTShapeRole && !selectedRole) {
      dispatch(fetchTShapeGraphicData({ roleId: selectedTShapeRole.id }));
    }
  }, [selectedTShapeRole]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddSkill = async (skill) => {
    await dispatch(addSkillFromWorkRoleReq({ skill, roleId: selectedRole?.id }));
    dispatch(fetchTShapeGraphicData({ roleId: selectedRole?.id }));
  };

  const handleRemoveSkill = async (skill) => {
    await dispatch(removeSkillFromWorkRoleReq({ id: skill.id, roleId: selectedRole?.id }));
    dispatch(fetchTShapeGraphicData({ roleId: selectedRole?.id }));
  };

  return (
    <div className="iq4-tshape-page">
      <Drawer
        variant="persistent"
        isOpen={tShapeSelectedSkill}
        classes={{
          root: 'iq4-tshape-page__drawer',
          paper: 'iq4-tshape-page__drawer-paper',
        }}
      >
        <FocusLock>
          <div style={{ paddingTop: '40px' }}>
            <button
              aria-label="Close drawer"
              className="iq4-tshape-page__drawer__dismiss-button"
              onClick={() => void dispatch(fetchTShapeSkillInfo({ id: null }))}
            >
              <SimpleIcon name="close" />
            </button>
            {tShapeSelectedSkill && (
              <RoleIndividualSkillInfoPanel
                achievements={tShapeSelectedSkill.achievements.filter(
                  (achievement) => achievement?.school === personal?.school?.name,
                )}
                skill={tShapeSelectedSkill}
                onBackClick={null}
                onAdd={handleAddSkill}
                onRemove={handleRemoveSkill}
                onCourseClick={() => {}}
              />
            )}
          </div>
        </FocusLock>
      </Drawer>

      <div className="iq4-tshape-page__container">
        <div
          className={`iq4-tshape-page__t-container ${
            tShapeSelectedSkill ? 'iq4-tshape-page__t-container--open-drawer' : ''
          }`}
        >
          <div className="iq4-tshape-page__heading-back-btn-container">
            <button className="iq4-tshape-page__heading-back-btn" onClick={onClose}>
              Close <SimpleIcon name="close" />
            </button>
          </div>
          {(selectedTShapeRole || selectedRole) && !renderOnlySelfSkills && (
            <div className="iq4-profile-skills__container-middle iq4-profile-skills__title-parent--item">
              <div>explore role</div>
              <div className="iq4-profile-skills__title-parent--item-label">
                <div className="iq4-profile-skills__title-parent--change-label">
                  {selectedRole?.label || selectedTShapeRole?.label}
                </div>
              </div>
            </div>
          )}
          <div className="iq4-t-shape__title-wrapper">
            <h2 className="iq4-tshape-page__t-tile">{title}</h2>
            <div
              className="iq4-t-shape__info"
              role="button"
              tabIndex={0}
              onClick={() => setShowTShapeHelp(!showTShapeHelp)}
              onKeyPress={() => setShowTShapeHelp(!showTShapeHelp)}
            >
              <SimpleIcon name="infoDefault" aria-label="Get more info about T-Shape labels" />
              <div className="iq4-t-shape__info--text">What's this?</div>
            </div>
          </div>
          {!tShapeGraphicData && !renderOnlySelfSkills && (
            <div style={{ marginTop: '60px' }}>
              <LoadingSkeleton variant="rect" style={{ width: '100%', height: '200px' }} />
              <LoadingSkeleton
                variant="rect"
                style={{ width: '100%', height: '30px', marginTop: '4px', marginBottom: '0' }}
              />
              <div
                style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}
              >
                <LoadingSkeleton
                  variant="rect"
                  style={{ flex: '1', height: '356px', marginTop: '4px' }}
                />
                <LoadingSkeleton
                  variant="rect"
                  style={{
                    width: '30px',
                    height: '360px',
                    marginLeft: '4px',
                    marginRight: '4px',
                  }}
                />
                <LoadingSkeleton
                  variant="rect"
                  style={{ flex: '1', height: '356px', marginTop: '4px' }}
                />
              </div>
            </div>
          )}

          {((tShapeGraphicData && !renderOnlySelfSkills) || renderOnlySelfSkills) && (
            <TShapeV2
              renderOnlySelfSkills={renderOnlySelfSkills}
              {...(!renderOnlySelfSkills
                ? { ...tShapeGraphicData }
                : { ...getMySkillsTShapeData(mySkills) })}
              sectionLabels={{
                top: CATEGORY_LABELS['professional_skills'],
                bottomLeft: CATEGORY_LABELS['it_skills'],
                bottomRight: CATEGORY_LABELS['soft_skills'],
              }}
              onClick={(skill) => {
                dispatch(fetchTShapeSkillInfo({ id: skill.id }));
              }}
              showHelp={true}
            />
          )}
          <Drawer
            anchor="right"
            variant="persistent"
            isOpen={!!showTShapeHelp}
            classes={{
              root: 'iq4-tshape-page__drawer',
              paper: 'iq4-tshape-help-page__drawer-paper',
            }}
          >
            <FocusLock>
              <div style={{ paddingTop: '40px' }}>
                <button
                  aria-label="Close"
                  className="iq4-tshape-page__drawer__dismiss-button"
                  onClick={() => setShowTShapeHelp(false)}
                >
                  <SimpleIcon name="close" />
                </button>
                <div className="iq4-tshape-page__drawer__role-container">
                  <>
                    <div className="iq4-tshape-page__drawer__role-container--title-2">
                      The T-Shape
                    </div>
                    <div className="iq4-tshape-page__drawer__role-container--divider" />
                    <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
                    <div>
                      The T-shape helps you visualize how your Professional & Leadership, Technical,
                      and Operational skill proficiencies compare against the target proficiencies
                      for your existing role and other work roles and where you have skill gaps.
                    </div>
                    <div
                      className="iq4-tshape-page__drawer__role-container--role"
                      style={{ marginTop: 40 }}
                    >
                      CATEGORIES
                    </div>
                    <div>The T-Shape is divided into three sections:</div>
                    <p>
                      <b>Professional Skills</b> are unique abilities and knowledge needed for a
                      particular occupation, that includes a distinct range of skills that are
                      crucial for success on the job.
                      <br />
                      <div className="iq4__flex-row">
                        Example: DATABASE ADMINISTRATION
                        <Pill
                          label="PROFESSIONAL SKILLS"
                          type="professional skills"
                          className="iq4__margin-left"
                        />
                      </div>
                    </p>
                    <p>
                      <b>IT Skills</b> represent the ability to effectively use a variety of
                      software and tools on the job.
                      <br />
                      <div className="iq4__flex-row">
                        Example: SQL Databases
                        <Pill label="IT SKILLS" type="it skills" className="iq4__margin-left" />
                      </div>
                    </p>
                    <p>
                      <b>Soft Skills</b> are the personal qualities and behaviors crucial for
                      professional success. These skills can often be transferred and applied across
                      various job roles and industries.
                      <br />
                      <div className="iq4__flex-row">
                        Example: LEADERSHIP
                        <Pill label="SOFT SKILLS" type="soft skills" className="iq4__margin-left" />
                      </div>
                    </p>
                    <div
                      className="iq4-tshape-page__drawer__role-container--role"
                      style={{ marginTop: 40 }}
                    >
                      CHANGING ROLES
                    </div>
                    <div>You can see how your skills match against any role in our library:</div>
                    <p>- Select "Change Role" above the T-Shape</p>
                    <p>- Filter by either "Job Family" or "Role"</p>
                    <p>
                      - Alternatively, you can select a role from the "Career Pathway" section
                      directly
                    </p>
                    <p>- Your Skills are displayed on the T-Shape with a solid color</p>
                    <p>
                      - The Skills required by the selected Role are displayed on the T-Shape with a
                      candy cane pattern, and these are the Skills that you should look to develop
                    </p>
                  </>
                </div>
              </div>
            </FocusLock>
          </Drawer>
        </div>
      </div>
    </div>
  );
};
