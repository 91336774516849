import React from 'react';
import { RecommendedWorkRolesList } from '../RecommendedWorkRolesList';
import './PathwaysSimilarRolesPanel.scss';

/**
 * Similar roles list component
 * @param {object[]} roles - Roles array
 * @param {function} onClick - Handle clicking the role
 * @param {string} [className] - Custom className
 */
export const PathwaysSimilarRolesPanel = ({ roles, onClick, className }) => (
  <div className={`iq4-pathways-similar-roles-panel ${className ? className : ''}`}>
    <p className="iq4-pathways-similar-roles-panel__title">
      These are similar roles based on skills and experience data we’ve picked up from your resume.
    </p>

    <div className="iq4-pathways-similar-roles-panel__list">
      <RecommendedWorkRolesList
        roles={roles}
        onClick={(role) => onClick(role)}
        className="iq4-pathways-similar-roles-panel__list-item"
      />
    </div>
  </div>
);
