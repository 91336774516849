import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { ProgressIndicator } from 'common/components/ProgressIndicator';
import { Topbar, Button, LinkIcon } from 'common/components';
import { ROUTES } from 'routes';
import {
  OnboardingPersonal,
  OnboardingEducation,
  OnboardingExperience,
} from 'common/components/Onboarding';
import { OnboardingAssessmentPage, OnboardingPortfolioPage } from 'pages/Onboarding';
import { selectProfile } from 'common/store/features/profile/selectors';
import { fetchProfileReq, updateHasOnboardedReq } from 'common/store/features/profile/profileSlice';
import { selectFeatures } from 'store/features/config/selectors';
import { Footer } from 'common/components';

import './OnboardingProfilePage.scss';
import { setDisplayTour } from '../../common/store/features/ui/uiSlice';
import { selectCameFromProfile } from '../../common/store/features/ui/selectors';

const showTutorial = process.env.REACT_APP_SHOW_TUTORIAL === 'true';

export const OnboardingProfilePage = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const history = useHistory();
  const state = useSelector(selectProfile);
  const [steps, setSteps] = useState([]);
  const [formContext, setFormContext] = useState(null);
  const { personalProfileSource, defaultRoute } = useSelector(selectFeatures);
  const [isProfileSetup, setIsProfileSetup] = useState(false);
  const { personal } = useSelector(selectProfile);
  const cameFromProfile = useSelector(selectCameFromProfile);

  const linkRef = useRef(null);

  // setup profile data
  useEffect(() => {
    const setupProfile = async () => {
      const res = await dispatch(fetchProfileReq(personalProfileSource));

      if (res.payload.personal.userProfile && res.payload.personal.userProfile.user) {
        setIsProfileSetup(true);
      }
    };

    setupProfile();
  }, []);

  useEffect(() => {
    setSteps([
      {
        isActive: path === ROUTES.ONBOARDING_PERSONAL,
        next: ROUTES.ONBOARDING_EDUCATION,
        prev: cameFromProfile ? ROUTES.PROFILE_ROOT : ROUTES.ONBOARDING_ROOT,
        name: 'Profile',
      },
      {
        isActive: path === ROUTES.ONBOARDING_EDUCATION,
        next: ROUTES.ONBOARDING_EXPERIENCE,
        prev: ROUTES.ONBOARDING_PERSONAL,
        name: 'Education',
      },
      {
        isActive: path === ROUTES.ONBOARDING_EXPERIENCE,
        next: ROUTES.ONBOARDING_ASSESSMENT,
        prev: ROUTES.ONBOARDING_EDUCATION,
        name: 'Experience',
      },
      {
        isActive: path === ROUTES.ONBOARDING_ASSESSMENT,
        next: ROUTES.ONBOARDING_PORTFOLIO,
        prev: ROUTES.ONBOARDING_EXPERIENCE,
        name: 'Assessment',
      },
      {
        isActive: path === ROUTES.ONBOARDING_PORTFOLIO,
        next: ROUTES.DISCOVERY_DASHBOARD,
        prev: ROUTES.ONBOARDING_ASSESSMENT,
        name: 'Portfolio',
      },
    ]);

    linkRef.current.focus();
  }, [path]);

  useEffect(() => {
    console.log('trying to focus', linkRef.current);
    linkRef.current.focus();
  }, [currentStep?.name]);

  const currentStep = steps.find((s) => s.isActive) || {};

  const handleOnboardingNext = () => {
    history.push(currentStep.next);
  };

  const handleOnboardingPrevious = () => {
    history.push(currentStep.prev);
  };

  const handleShowHelp = async () => {
    dispatch(setDisplayTour(true));
  };

  return (
    <>
      <Topbar
        logoOnly
        Logo={Logo}
        className="iq4-onboarding-manual__topbar iq4-onboarding-resume-path__topbar"
      />
      <div className="iq4-onboarding-manual-container">
        <main>
          <div aria-live="polite" role="alert" className="sr-only">
            {`You are inside the Onboarding Wizard. Current section is: ${currentStep.name}`}
          </div>
          <div className="iq4-onboarding__header">
            <LinkIcon
              itemRef={linkRef}
              to={currentStep.prev || ROUTES.ONBOARDING_ROOT}
              iconName="onboardingArrow"
              ariaLabel="Go back"
              label="back"
              size="22px"
              className="iq4-onboarding__icon"
            />
            <div className="iq4-onboarding__wizard-steps">
              <ProgressIndicator steps={steps} />
            </div>
            {showTutorial && (
              <Button className="iq4-onboarding__skip" onClick={() => handleShowHelp()}>
                Show Help
              </Button>
            )}
          </div>
          <div className="iq4-onboarding__step-container">
            <Switch>
              <Route exact path={ROUTES.ONBOARDING_PERSONAL}>
                <OnboardingPersonal
                  exposeFormContext={setFormContext}
                  onPrevious={() => handleOnboardingPrevious()}
                  onComplete={() => handleOnboardingNext()}
                />
              </Route>
              <Route exact path={ROUTES.ONBOARDING_EDUCATION}>
                <OnboardingEducation
                  exposeFormContext={setFormContext}
                  onPrevious={() => handleOnboardingPrevious()}
                  onComplete={() => handleOnboardingNext()}
                />
              </Route>
              <Route exact path={ROUTES.ONBOARDING_EXPERIENCE}>
                <OnboardingExperience
                  exposeFormContext={setFormContext}
                  onPrevious={() => handleOnboardingPrevious()}
                  onComplete={() => handleOnboardingNext()}
                />
              </Route>
              <Route exact path={ROUTES.ONBOARDING_ASSESSMENT}>
                <OnboardingAssessmentPage
                  onPrevious={() => handleOnboardingPrevious()}
                  onComplete={() => handleOnboardingNext()}
                />
              </Route>
              <Route exact path={ROUTES.ONBOARDING_PORTFOLIO}>
                <OnboardingPortfolioPage
                  exposeFormContext={setFormContext}
                  onPrevious={() => handleOnboardingPrevious()}
                  onComplete={() => history.push(ROUTES.DISCOVERY_DASHBOARD)}
                />
              </Route>
            </Switch>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};
