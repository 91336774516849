import React from 'react';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/config/selectors';

import { ReactComponent as Logo } from '@assets/svg/logo.svg';

import './NotAuthorizedRegistrationPage.scss';

export const NotAuthorizedRegistrationPage = () => {
  const { notAuthorizedRegistration } = useSelector(selectFeatures);

  return (
    <div className="iq4-register-not-authorized-page">
      <div className="iq4-register-not-authorized-page__card">
        <div className="iq4-register-not-authorized-page__logo-container">
          <Logo role="presentation" aria-hidden="true" />
        </div>

        <h1 className="iq4-register-not-authorized-page__title">
          {notAuthorizedRegistration?.title || 'Not Authorized'}
        </h1>

        <p>
          {!notAuthorizedRegistration?.messages &&
            'You are not authorized to register for IQ4 at the present time.'}
        </p>

        {notAuthorizedRegistration?.messages?.map((message, index) => (
          <p className="iq4-register-not-authorized-page__text" key={index}>
            {Array.isArray(message)
              ? message.map((line, i) => (
                  <>
                    {line}
                    <br />
                  </>
                ))
              : message}
          </p>
        ))}
      </div>
    </div>
  );
};
