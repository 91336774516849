import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { LCN_CREDENTIAL_PROVIDERS } from 'common/constants/lcnRecords';
import { getBase64ImageUrl } from 'common/utils';
import { IconButton, Link, Checkbox, FormControlLabel } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, PrivatePublicToggle } from 'common/components';
import { ReactComponent as Verified } from 'common/components/SimpleIcon/svg/verified.svg';
import {
  submitUpdateToCredential,
  fetchCredentialSkillsReq,
} from 'common/store/features/credentials/credentialsSlice';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { CredentialSkill } from './CredentialSkill';

export function CredentialRow({
  row,
  setCredentialToBeDeleted,
  setIsDeletingModal,
  setIsEdit,
  onDelete = () => {},
  isReadOnly = false,
}) {
  const dispatch = useDispatch();
  const breakpoint = useBreakpoints();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile] = useState(breakpoint === 'xs' || breakpoint === 'sm');
  const [displayCount, setDisplayCount] = useState(10);

  let imageUrl = row.imageUrl
    ? row.imageUrl
    : row.base64schoollogo
    ? getBase64ImageUrl(row.base64schoollogo)
    : row.resources?.[0]?.fileS3Url;
  if (!imageUrl) imageUrl = row.issuerImageUrl;

  let date = '';
  if (row?.issuedOn) {
    date = moment(row?.issuedOn).format('MM/DD/YYYY');
  } else if (row.startYear) {
    date = moment(
      `${row.startYear}-${row.startMonth
        .toString()
        .padStart(2, '0')}-${row.startDay.toString().padStart(2, '0')}`,
    ).format('MM/DD/YYYY');
  }

  const isPublic = window.location.href.indexOf('/user/') >= 0;

  let sortedCompetencies = [...row?.competences];
  if (sortedCompetencies?.length)
    sortedCompetencies = sortedCompetencies.sort((a, b) => {
      return a.tier3?.label?.localeCompare(b.tier3?.label);
    });

  let rowJs = null;
  if (row?.text) rowJs = JSON.parse(row?.text);
  const achievementName = rowJs?.credentialSubject?.achievement?.name;
  const achievementDescription = rowJs?.credentialSubject?.achievement?.description;
  const displaySkillSources = row?.skills?.some((skill) =>
    skill.sources?.some((s) => s.method == 'RSD'),
  );

  function prepareCredentialToBeDeleted(cred) {
    setCredentialToBeDeleted(cred);
    setIsDeletingModal(true);
    onDelete(document.querySelector('.iq4-credentials__delete'));
  }

  function toggleExpanded(cred) {
    setIsExpanded(!isExpanded);
    if (!isExpanded && !cred.competences?.length) {
      dispatch(fetchCredentialSkillsReq({ credential: cred }));
    }
  }

  function statusName(status) {
    if (!status) return '';
    if (status.startsWith('VC_')) status = status.slice(3);
    status = status.toLowerCase();
    status = status.charAt(0).toUpperCase() + status.slice(1);
    return status;
  }

  const hasURL = !!row?.achievementUrl?.includes('http');

  return (
    <div
      className={`iq4-credentials__existing-card ${
        isExpanded ? 'iq4-credentials-auto-height' : ''
      }`}
      key={row?.id}
    >
      <IconButton
        classes={{ root: 'iq4-credentials__expand' }}
        aria-label="expand row"
        size="small"
        onClick={() => toggleExpanded(row)}
      >
        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <div
        className={`iq4-credentials__card-container ${
          isExpanded ? 'iq4-credentials-auto-height' : ''
        }`}
      >
        <div className="iq4-credentials__card-container--row iq4-credentials__card-container--with-verified">
          <div className="iq4-record-image-and-label">
            {imageUrl && (
              <img className="iq4-record-image-and-label__image" src={imageUrl} alt="Logo" />
            )}
            <div className={`${imageUrl ? 'iq4-record-image-and-label__label' : ''}`}>
              <span className="iq4-credentials__record-container">
                {row.name}
                {row.isVerified && (
                  <div
                    className={`${
                      isMobile
                        ? 'iq4-credentials__verified-container-mobile'
                        : 'iq4-credentials__verified-container'
                    }`}
                  >
                    <Verified />
                    <span></span>
                  </div>
                )}
              </span>
            </div>
          </div>
        </div>
        {!isMobile && (
          <>
            <div className="iq4-credentials__card-container--row iq4-credentials__credential_status_text--highlight">
              {statusName(row?.status)}
            </div>
            <div className="iq4-credentials__card-container--row">
              <span>{date}</span>
            </div>
            <div className="iq4-credentials__card-container--row">{row?.issuerName}</div>

            <div className="iq4-credentials__card-container--row">
              <PrivatePublicToggle
                displayScope={true}
                isConsentGiven={true}
                record={row}
                onSelecting={({ selected }) => {
                  const updatedRow = { ...row };
                  updatedRow.isPublic = selected;
                  dispatch(submitUpdateToCredential({ credential: updatedRow }));
                }}
              />
            </div>
          </>
        )}
      </div>
      {isExpanded && (
        <div className="iq4-credential-expanded__container">
          <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
          <div className="iq4-credential-expanded__split">
            <div>
              <div className="iq4-credential-expanded-item" style={{ marginRight: 50 }}>
                <div className="iq4-credential-expanded-item__label">Description</div>
                <div className="iq4-credential-expanded-item__content">{row.description}</div>
              </div>
              <div className="iq4-credential-expanded-item">
                <div className="iq4-credential-expanded-item__label">Issuer</div>
                <div
                  className="iq4-credential-expanded-item__content"
                  style={{ display: 'flex', gap: '10px' }}
                >
                  {row.issuerImageUrl && (
                    <div>
                      <img
                        className="iq4-record-image-and-label__image"
                        src={row.issuerImageUrl}
                        alt=""
                      />
                    </div>
                  )}
                  {row.issuerUrl && row.issuerName && (
                    <div>
                      <Link href={row.issuerUrl} target="_blank" rel="noopener">
                        {row.issuerName}
                      </Link>
                    </div>
                  )}
                  {!row.issuerUrl && row.issuerName && <div>{row.issuerName}</div>}
                </div>
              </div>
              {achievementName && achievementName != row.name && (
                <div className="iq4-credential-expanded-item">
                  <div className="iq4-credential-expanded-item__label">Achievement Name</div>
                  <div className="iq4-credential-expanded-item__content">{achievementName}</div>
                </div>
              )}
              {achievementDescription && achievementDescription != row.description && (
                <div className="iq4-credential-expanded-item">
                  <div className="iq4-credential-expanded-item__label">Achievement Description</div>
                  <div className="iq4-credential-expanded-item__content">
                    {achievementDescription}
                  </div>
                </div>
              )}
              <div className="iq4-credential-expanded-item">
                <div className="iq4-credential-expanded-item__label">Award Date</div>
                <div className="iq4-credential-expanded-item__content">{date}</div>
              </div>
            </div>
            {imageUrl && (
              <div className="iq4-credential-expanded__split-img">
                <img
                  className="iq4-credentials__expanded-image-desktop"
                  src={imageUrl}
                  alt="Logo"
                />
              </div>
            )}
          </div>
          <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
          {!!row?.competences?.length && (
            <>
              <div className="iq4-credential-expanded-item">
                <div className="iq4-credential-expanded-item__label">Skill view</div>
                <div className="iq4-credential-expanded-item__content">
                  Here you can view the skills found and added to your wallet from this credential
                </div>
              </div>
              <div className="iq4-credential__skills-list">
                <div className="iq4-credential-skill">
                  <div className="iq4-credential-skill__header">Skill</div>
                  {displaySkillSources && (
                    <>
                      <div className="iq4-credential-skill__break"></div>
                      <div className="iq4-credential-skill__header" style={{ marginLeft: 7 }}>
                        Skill Statement
                      </div>
                    </>
                  )}
                </div>
                {sortedCompetencies?.slice(0, displayCount)?.map((c) => {
                  // check if row has skills
                  const skills = row?.skills?.find((s) => s.competenceId == c.id);
                  return (
                    <CredentialSkill
                      skill={
                        skills ? skills : { competenceId: c?.tier3?.label, name: c?.tier3?.label }
                      }
                      displaySkillSources={displaySkillSources}
                    />
                  );
                })}
              </div>
              {sortedCompetencies.length > displayCount && (
                <Button
                  variation="ghost"
                  className="iq4-credential-skill__load-more"
                  onClick={() => setDisplayCount(sortedCompetencies.length)}
                >
                  Load More
                </Button>
              )}
            </>
          )}
          {!row.isVerified && !isPublic && (
            <Button className="iq4-credentials__edit" onClick={() => setIsEdit(row)}>
              Edit
            </Button>
          )}
          {!!row.isVerified && hasURL && (
            <div className="iq4-credential-expanded-item__content">
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="iq4-credentials__manual-add"
                style={{ cursor: 'pointer' }}
                href={row.achievementUrl}
                aria-label={`View more details (opens in a new tab)`}
              >
                View details
              </a>
            </div>
          )}
          <div className="iq4-credential-expanded__container--line iq4-credential-expanded__container--line-no-margin" />
          {!isReadOnly && (
            <div className="iq4-credential-expanded__split" style={{ marginTop: 45 }}>
              <div className="iq4-credential-expanded-item__content">
                Do you want to delete this credential?
              </div>
              <Button
                className="iq4-credentials__delete"
                onClick={() => prepareCredentialToBeDeleted(row)}
                loading={false}
              >
                Delete this credential
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
