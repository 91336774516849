import { useState, useEffect } from 'react';

// custom hook to check if user clicked outside element.
export const useClickOutside = (ref) => {
  const [clickedOutside, setClickedOutside] = useState(false);

  useEffect(() => {
    // check if clicked outside ref
    const handler = (e) => {
      const isOutside = !(ref.current && ref.current.contains(e.target));
      setClickedOutside(isOutside);
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [ref]);

  return [clickedOutside, setClickedOutside];
};
