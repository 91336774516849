import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextInput,
  TextArea,
  AutoComplete,
  FileUpload,
  AccessibleSelect,
} from 'common/components/Form/fieldTypes';
import { selectFeatures } from 'store/features/config/selectors';
import { useSkillsSearch } from 'common/hooks/useSkillsSearch';
import { validations } from 'common/components/Form/validations';
import { ImageUploadPlaceholder } from 'common/components/ImageUploadPlaceholder';
import { selectPortfolioSources, selectProfile } from 'common/store/features/profile/selectors';
import { PROFILE_PORTFOLIO_URL } from 'common/constants/endpoints';
import { PORTFOLIO_DOCUMENT_MIME_TYPES, THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import {
  setProfileSection,
  updateProfileSection,
} from 'common/store/features/profile/profileSlice';
import {
  PORTFOLIO_TYPE,
  PROFILE_TYPE,
  sendFormDataRequest,
  transformProfileFormsPutData,
} from 'common/utils';

export const usePortfolioImageFormDefinition = ({ values, onComplete }) => {
  const { selfAttestedEnabled } = useSelector(selectFeatures);
  const { portfolio } = useSelector(selectProfile);
  const sourceOptions = useSelector(selectPortfolioSources);
  const dispatch = useDispatch();
  const [competenciesSearchTerm, setCompetenciesSearchTerm] = useState('');
  const competenciesData = useSkillsSearch(competenciesSearchTerm);

  const fieldsDefinition = [
    {
      label: 'Title',
      name: 'title',
      component: TextInput,
      validators: {
        required: validations.isRequired(),
      },
    },
    {
      isCustom: true,
      component: ({ ...formProps }) => {
        return (
          <FileUpload
            label="Upload your image"
            uploadedLabel="Your image"
            triggerLabel={<ImageUploadPlaceholder isLarge={true} label="Select Image" />}
            name="file"
            fileTypes={THUMBNAIL_MIME_TYPES}
            type="image"
            iconName="image"
            validators={{
              required: validations.isRequired(),
            }}
            {...formProps}
          />
        );
      },
    },
    {
      label: 'Description (500 characters)',
      name: 'description',
      component: TextArea,
      validators: {
        maxLength: validations.hasMaxLength(500),
      },
    },
    ...(selfAttestedEnabled
      ? [
          {
            isCustom: true,
            component: ({ ...formProps }) => (
              <AutoComplete
                name="competencies"
                label="Skills"
                placeholder="Type to search your skills"
                options={competenciesData}
                optionLabel="value"
                onInputChange={(e, value) => setCompetenciesSearchTerm(value)}
                tooltip={
                  <p>
                    Start typing to add skills. <br />
                    <br />
                    Skills examples include: Teamwork, C++, Sales, etc.
                  </p>
                }
                {...formProps}
              />
            ),
          },
        ]
      : []),
    ...(!!sourceOptions.length
      ? [
          {
            isCustom: true,
            component: ({ ...formProps }) => (
              <div className="iq4-portfolio-source-select">
                <AccessibleSelect
                  maxWidth
                  label="Where did you complete this project"
                  name="associatedRecord"
                  placeholder="This is a dropdown list of Education and Experience"
                  options={sourceOptions}
                  {...formProps}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const onEdit = (data) => {
    // keep the key `file` for sending an image, but have the type as 'image'
    let { image: file, ...derivedData } = data;
    derivedData.file = file;

    return sendFormDataRequest({
      url: `${PROFILE_PORTFOLIO_URL}/${values.id}`,
      verb: 'PUT',
      data: {
        ...transformProfileFormsPutData(data, values),
        type: PORTFOLIO_TYPE.IMAGE,
      },
    }).then((resp) =>
      dispatch(updateProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: resp })),
    );
  };

  const onCreate = (data) => {
    // keep the key `file` for sending an image, but have the type as 'image'
    // let { image: file, ...derivedData } = data;
    // derivedData.file = file;

    return sendFormDataRequest({
      url: PROFILE_PORTFOLIO_URL,
      verb: 'POST',
      data: {
        ...data,
        type: PORTFOLIO_TYPE.IMAGE,
        featured: portfolio.length === 0,
      },
    }).then((resp) =>
      dispatch(setProfileSection({ section: PROFILE_TYPE.PORTFOLIO, value: resp })),
    );
  };

  return {
    fieldsDefinition,
    onEdit,
    onCreate,
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onCreate,
      onComplete,
    },
  };
};
