import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'common/components/Modal';
import { Button } from 'common/components/Button';
import FocusLock from 'react-focus-lock';
import './TriggerModal.scss';

export const TriggerModal = ({
  title,
  form,
  label,
  buttonVariation,
  className,
  isTextTriggered,
  isReadOnly = false,
  showCloseButton = false,
  ariaLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const textTriggerRef = useRef(null);
  const [focusOnClose, setFocusOnClose] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (!isOpen && focusOnClose && textTriggerRef.current) {
      textTriggerRef.current.focus();
      setFocusOnClose(false);
    }
  }, [focusOnClose, textTriggerRef.current, isOpen]);

  const onTrigger = () => {
    setIsOpen(true);
    setFocusOnClose(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const TextTrigger = ({ children }) => (
    <span
      ref={textTriggerRef}
      role="button"
      onClick={isReadOnly ? null : onTrigger}
      onKeyDown={isReadOnly ? null : onTrigger}
      className="iq4-trigger-modal__text"
      aria-label={ariaLabel || 'Open modal'}
    >
      {children}
    </span>
  );

  const renderClose = () => (
    <div className={`iq4-form__button-container iq4-form__button_container--btns-center`}>
      <div className="iq4-form__button-submit-container">
        <Button
          variation="ghost"
          type="submit"
          className="iq4-form__button-submit"
          onClick={() => setIsOpen(false)}
        >
          Close
        </Button>
      </div>
    </div>
  );

  return (
    <div className={`iq4-trigger-modal ${className}`}>
      {isTextTriggered && <TextTrigger>{label}</TextTrigger>}
      {!isTextTriggered && (
        <button
          ref={buttonRef}
          className="iq4-button iq4-button-ghost"
          onClick={isReadOnly ? null : onTrigger}
          aria-label={ariaLabel || 'Open modal'}
        >
          {label}
        </button>
      )}
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        title={title}
        className={`${className}__modal`}
        onDeactivation={() => {
          if (buttonRef.current) {
            setTimeout(() => buttonRef.current.focus(), 100);
          }
        }}
      >
        {React.cloneElement(form, { onComplete: handleClose })}
        {showCloseButton && renderClose()}
      </Modal>
    </div>
  );
};

TriggerModal.propTypes = {
  title: PropTypes.string,
  form: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  buttonVariation: PropTypes.string,
  className: PropTypes.string,
  isTextTriggered: PropTypes.bool,
};

TriggerModal.defaultProps = {
  title: '',
  label: '',
  buttonVariation: 'ghost',
  className: '',
  isTextTriggered: false,
};
