import React, { useState } from 'react';
import { SimpleIcon } from 'common/components/SimpleIcon';

import './AssessmentStars.scss';

/**
 * Assessment stars component used for assessing skills
 * @param {number} assessment - The assessment value
 * @param {boolean} displayOnly - makes the component non-interactive
 * @param {function} onChange - Callback to notify parent of assessment change
 */
export const AssessmentStars = ({ assessment, displayOnly = false, onChange }) => {
  const [mouseOver, setMouseOver] = useState(0);

  function renderStar(starValue) {
    return (
      <button
        tabIndex={displayOnly ? '-1' : '0'}
        key={starValue}
        className={`
            iq4-assessment-stars__star-btn
            ${assessment >= starValue && mouseOver === 0 ? 'iq4-assessment-stars--active-star' : ''}
            ${mouseOver >= starValue ? 'iq4-assessment-stars--mouseover' : ''}
            `}
        onMouseOver={() => handleMouseOver(starValue)}
        onMouseLeave={() => handleMouseLeave()}
        onFocus={() => handleMouseOver(starValue)}
        onBlur={() => handleMouseLeave()}
        onClick={() => void handleChange(starValue)}
        disabled={displayOnly}
        aria-label={`${
          assessment >= starValue && mouseOver === 0
            ? 'Active assessment star'
            : 'Inactive assessment star'
        }`}
      >
        <SimpleIcon name="star" />
      </button>
    );
  }

  // star events
  const handleChange = (val) => {
    if (displayOnly) return;
    onChange(val);
  };
  const handleMouseOver = (val) => {
    if (displayOnly) return;
    setMouseOver(val);
  };
  const handleMouseLeave = () => {
    if (displayOnly) return;
    setMouseOver(0);
  };

  return (
    <div className="iq4-assessment-stars">
      {displayOnly && <span className="sr-only">{assessment}</span>}
      {[1, 2, 3, 4].map((val) => renderStar(val))}
    </div>
  );
};
