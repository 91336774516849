import React from 'react';
import { PathwaysPanelHeader } from '../PathwaysPanelHeader';
import './CoreTasks.scss';

export const CoreTasks = ({ tasks, onBackClick }) => (
  <div className="iq4-core-tasks__info-panel">
    <PathwaysPanelHeader
      className="iq4-core-tasks__info-panel__header"
      title="Core Tasks"
      onLeftClick={onBackClick}
    />

    <ul className="iq4-core-tasks">
      {tasks && tasks.map((task) => <li className="iq4-core-tasks__item">{task.task}</li>)}
    </ul>
  </div>
);
