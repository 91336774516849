import React from 'react';
import {
  AssessmentStars,
  SkillsList,
  SimpleIcon,
  Tooltip,
  AssessmentInfoPanel,
} from 'common/components';

import './ResumeParserAssessmentList.scss';

export const ResumeParserAssessmentList = ({ skills, onChange, className, ...props }) => {
  return (
    <div className={`iq4-resume-parser-assessment-list ${className ? className : ''}`} {...props}>
      <div className="iq4-resume-parser-assessment-list__popover-icon">
        <Tooltip message={<AssessmentInfoPanel />}>
          View Guide
          <SimpleIcon name="star" />
        </Tooltip>
      </div>

      <SkillsList skills={skills}>
        {(skill) => {
          return (
            <AssessmentStars
              assessment={skill.assessment}
              onChange={(assessment) => void onChange(skill, assessment)}
            />
          );
        }}
      </SkillsList>
    </div>
  );
};
