import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'routes/routeNames';
import { OnboardingHeader, OnboardingFooter } from 'common/components/Onboarding';
import {
  fetchTopSkills,
  saveAllSkillsReq,
  setResumeParserSkills,
} from 'common/store/features/skills/skillsSlice';
import { updateAssessedResumeParserSkills } from 'common/store/features/parsedResume/parsedResumeSlice';
import { selectResumeParserSkills } from 'common/store/features/parsedResume/selectors';
import { Button, ResumeParserAssessmentList } from 'common/components';

import './OnboardingAssessmentPage.scss';
import IQ4Tour from '../../../common/components/IQ4Tour/IQ4Tour';
import { setDisplayTour } from '../../../common/store/features/ui/uiSlice';

const showTutorial = process.env.REACT_APP_SHOW_TUTORIAL === 'true';

export const OnboardingAssessmentPage = ({
  onComplete = () => {},
  onPrevious = () => {},
  disableTourOnLoad,
}) => {
  const dispatch = useDispatch();
  const resumeParsedSkills = useSelector(selectResumeParserSkills);
  const [selectedSkills, setSelectedSkills] = useState(() => getSelectedSkills(resumeParsedSkills));
  const [assessedCount, setAssessedCount] = useState(() => getAssessedCount(resumeParsedSkills));
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    if (disableTourOnLoad) {
      dispatch(setDisplayTour(false));
    }
  }, [disableTourOnLoad]);

  let steps = [];

  if (selectedSkills?.length > 0) {
    steps = [
      ...steps,
      {
        target: '.iq4-skill-list',
        content: (
          <div>
            <h2 style={{ fontSize: '1rem' }}>Skills Proficiency</h2>
            <p style={{ fontSize: '0.875rem' }}>
              Rate your proficiency in each skill by selecting the number of stars that best
              reflects your expertise. The Guide is available to explain what each number of stars
              indicates about your proficiency in each skill.
            </p>
          </div>
        ),
        placement: 'right',
        spotlightClicks: true,
        disableBeacon: true,
        hideCloseButton: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
      },
    ];
  }

  // update selected skills with updated assessment
  useEffect(() => {
    const newSelectedSkills = getSelectedSkills(resumeParsedSkills);
    setSelectedSkills(newSelectedSkills);
  }, [resumeParsedSkills]);

  // keep track of number of skills assessed
  useEffect(() => {
    const newCount = getAssessedCount(resumeParsedSkills);
    setAssessedCount(newCount);
  }, [resumeParsedSkills]);

  function getSelectedSkills(skills) {
    return skills.filter((s) => s.selected);
  }

  function setAssessment(skill, assessment) {
    dispatch(updateAssessedResumeParserSkills({ skill: { id: skill.id }, assessment }));
  }

  function getAssessedCount(skills) {
    return skills.reduce((sum, s) => {
      return s.selected && s.assessment ? ++sum : sum;
    }, 0);
  }

  // save skills and navigate to
  const finishOnboarding = async () => {
    setIsSubmittingForm(true);

    const payload = selectedSkills.map((skill) => ({
      competenceId: skill.id,
      assessment: skill.assessment,
    }));

    await dispatch(saveAllSkillsReq(payload));
    dispatch(fetchTopSkills());

    onComplete();
  };

  return (
    <>
      <div className="iq4-onboarding-skills-list">
        <OnboardingHeader icon="skillsCircle" title="Skills - Self assessment" isMainHeading />
        {showTutorial && <IQ4Tour steps={steps} />}

        {selectedSkills?.length > 0 && (
          <div className="iq4-onboarding__card">
            <p>Rate your level for each skill</p>

            <ResumeParserAssessmentList skills={selectedSkills} onChange={setAssessment} />
          </div>
        )}

        {!selectedSkills.length && (
          <div className="iq4-onboarding__card">
            <p className="iq4-onboarding__card__empty">
              We haven't identified any skills at this point. Don't worry! You'll have the
              opportunity to add skills in a future section.
            </p>
          </div>
        )}
      </div>
      <OnboardingFooter
        direction="Next, you can optionally add portfolio items"
        isSubmittingForm={isSubmittingForm}
        handleNext={() => finishOnboarding()}
        handlePrev={() => onPrevious()}
        nextButtonLabel="Save and Continue"
      />
    </>
  );
};
