import React from 'react';

import './AssessmentInfoPanel.scss';
import { SimpleIcon } from 'common/components/SimpleIcon';

// prettier-ignore
const STAR_LABELS = ['I\'m a beginner', 'I use it', "It's something I do daily", "I'm an expert"];

/**
 * An info panel speficifying the assessment criteria
 * @param {string} className - Custom class name
 */
export const AssessmentInfoPanel = ({ className, ...props }) => {
  function renderAssessmentRow() {
    return STAR_LABELS.map((label, i) => {
      return (
        <div key={i} className="iq4-assessment-info-panel__assessment-row">
          {renderStars(i + 1)}
          <p>{label}</p>
        </div>
      );
    });
  }

  function renderStars(numOfStars) {
    const stars = [];
    for (let i = 0; i < numOfStars; i++) {
      const star = <SimpleIcon key={i} name="star" />;
      stars.push(star);
    }

    return <div>{stars}</div>;
  }

  return (
    <div className={`iq4-assessment-info-panel ${className ? className : ''}`} {...props}>
      <p className="iq4-assessment-info-panel__titile">
        Use this 4 star assessment guide to rate how competent you are
      </p>
      {renderAssessmentRow()}
    </div>
  );
};
