import { createSlice } from '@reduxjs/toolkit';

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    resumeUploaded: false,
    MINIMUM_SKILL_COUNT: 5,
  },
  reducers: {
    setResumeUploaded: (state, action) => {
      state.resumeUploaded = action.payload;
    },
  },
});

// action creators
export const { setResumeUploaded } = onboardingSlice.actions;

export default onboardingSlice.reducer;
