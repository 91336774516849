import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import './LoginPage.scss';
import { ROUTES } from 'routes/routeNames';
import { AUTHENTICATION_URL } from 'common/constants/endpoints';
import { LoginForm } from 'common/components';
import { selectFeatures } from 'store/features/config/selectors';

export const LoginPage = () => {
  const { defaultRoute } = useSelector(selectFeatures);

  return (
    <LoginForm
      Logo={Logo}
      onLoginSuccessLocation={ROUTES[defaultRoute]}
      loginEndpoint={AUTHENTICATION_URL}
    />
  );
};
