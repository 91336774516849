import { useState, useEffect } from 'react';
import { useBreakpoints } from 'common/hooks/useBreakpoints';

export const useIsMobile = (checkFnc) => {
  const breakpoint = useBreakpoints();
  const [currentZoom, setCurrentZoom] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkZoom = () => {
      const zoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
      setCurrentZoom(zoom);
    };

    window.addEventListener('resize', checkZoom);
    checkZoom();

    return () => {
      window.removeEventListener('resize', checkZoom);
    };
  }, []);

  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint, currentZoom]);

  function getIsMobile(breakpoint) {
    return checkFnc
      ? checkFnc(breakpoint) || currentZoom > 250
      : breakpoint === 'xs' || currentZoom > 250;
  }

  return isMobile;
};
