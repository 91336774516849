import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, FormWrapper } from 'common/components';
import { ProfilePortfolioForm } from 'common/components/Forms';
import { selectProfile } from 'common/store/features/profile/selectors';
import { useProfileExperienceFormDefinition } from 'common/hooks/forms/useProfileExperienceFormDefinition';
import { useProfileEducationFormDefinition } from 'common/hooks/forms/useProfileEducationFormDefinition';

/**
 * Get resource forms functionality and expose to children
 * @param {String} type - The type of resource you need, "education", "experience" , "portfolio"
 * @param {*} children
 */
export const WithResourceForm = ({ type, children }) => {
  const resourceData = useSelector(selectProfile);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => void setIsModalOpen(true);
  const handleModalClose = () => void setIsModalOpen(false);

  const formDefinitions = {
    experience: useProfileExperienceFormDefinition,
    education: useProfileEducationFormDefinition,
  };

  return (
    <>
      {children({
        openModal: handleModalOpen,
        closeModal: handleModalClose,
        resourceData: resourceData[type],
      })}

      <Modal isOpen={isModalOpen} handleClose={handleModalClose} title={type}>
        {type !== 'portfolio' && (
          <FormWrapper
            values={resourceData[type]}
            onComplete={handleModalClose}
            useFormDefinition={formDefinitions[type]}
          />
        )}

        {type === 'portfolio' && <ProfilePortfolioForm onComplete={handleModalClose} />}
      </Modal>
    </>
  );
};
