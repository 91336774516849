import React from 'react';
import PropTypes from 'prop-types';
import { SimpleIcon } from 'common/components/SimpleIcon';
import CompetenciesList from 'components/Profile/CompetenciesList';
import { usePortfolioItemImageFormDefinition } from 'common/hooks/forms';
import { ProfileThumbnailUpload } from '../ProfileThumbnailUpload';
import { portfolioFormDefinitions } from '../../../common/utils';
import './PortfolioItemSummary.scss';
import { useSelector } from 'react-redux';
import { selectIsPublicProfile } from '../../../common/store/features/profile/selectors';

export const PortfolioItemSummary = ({ item }) => {
  const { type, title, competencies, thumbnail } = item;
  const isPublicProfile = useSelector(selectIsPublicProfile);

  function generatePublicCompetencies(competencies) {
    return competencies.map((competency, i) => ({
      id: competency.id,
      value: competency.skillTier3?.label,
    }));
  }

  return (
    <div className="iq4-portfolio-item-summary">
      <div className="iq4-portfolio-item-summary__image-wrapper">
        {thumbnail && (
          <img className="iq4-portfolio-item-summary__image " src={thumbnail} alt="portolio item" />
        )}
        {!thumbnail && (
          <ProfileThumbnailUpload
            item={item}
            type="Portfolio Item"
            formDefinition={portfolioFormDefinitions[type]}
            imageFormDefinition={usePortfolioItemImageFormDefinition}
            isLarge={true}
          />
        )}
      </div>
      <div className="iq4-portfolio-item-summary__content-wrapper">
        <div className="iq4-portfolio-item-summary__heading-wrapper">
          <SimpleIcon
            className="iq4-portfolio-item-summary__type-icon"
            name={type !== 'other' ? type : 'link'}
          />
          <p className="iq4-portfolio-item-summary__title">{title}</p>
        </div>
        <CompetenciesList
          competencies={isPublicProfile ? generatePublicCompetencies(competencies) : competencies}
        />
      </div>
      <div></div>
    </div>
  );
};

PortfolioItemSummary.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  competencies: PropTypes.array.isRequired,
};

PortfolioItemSummary.defaultProps = {};

export default PortfolioItemSummary;
