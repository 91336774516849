import { useState, useEffect } from 'react';
import { SKILLS_SEARCH_URL } from 'common/constants/endpoints';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/config/selectors';
import { setIsSearchingSkill } from 'common/store/features/ui/uiSlice';

const MIN_CHAR_COUNT = 1;
export const useSkillsSearch = (query) => {
  const [skillsResults, setSkillsResults] = useState([]);
  const { displayUpperTiers } = useSelector(selectFeatures);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!query || (query && query.length < MIN_CHAR_COUNT)) return;
    dispatch(setIsSearchingSkill(true));

    const params = {
      q: query,
      limit: 10,
    };

    // Encode the parameters
    const queryString = new URLSearchParams(params).toString();

    fetch(`${SKILLS_SEARCH_URL}?${queryString}`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        const filteredResults = data.map((skill) => ({
          id: skill.competence,
          value: `${skill.skillTier3} (${skill.skillTier2})`,
          ...(displayUpperTiers && { value: `${skill.skillTier3} (${skill.skillTier2})` }),
          ...(!displayUpperTiers && { value: skill.skillTier3 }),
          tier1: skill.skillTier1,
          tier2: skill.skillTier2,
          tier3: skill.skilltier3,
          alreadyAdded: false, // current api can't handle this
        }));

        dispatch(setIsSearchingSkill(false));
        setSkillsResults(filteredResults);
      });
  }, [query]);

  return skillsResults;
};
