import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SimpleIcon, Button } from 'common/components';
import {
  loginReq,
  resetPasswordReq,
  toggleIsLoggingIn,
} from 'common/store/features/session/sessionSlice';
import {
  selectIsLoggedIn,
  selectIsLoggingIn,
  selectHasLoginError,
} from 'common/store/features/session/selectors';
import './LoginForm.scss';
import { fetchProfileAgreeTerms } from '../../store/features/profile/profileSlice';

export const LoginForm = ({ Logo, onLoginSuccessLocation, loginEndpoint }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [hasSentForgotPassword, setHasSentForgotPassword] = useState(false);
  const [hasResetError, setHasResetError] = useState(false);

  const usernameRef = useRef(username);
  const passwordRef = useRef(password);

  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isLoggingIn = useSelector(selectIsLoggingIn);
  const hasLoginError = useSelector(selectHasLoginError);

  useEffect(() => {
    if (isLoggedIn) {
      history.push(onLoginSuccessLocation);
      dispatch(fetchProfileAgreeTerms());
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEmailChange = (e) => {
    usernameRef.current = e.target.value;
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    passwordRef.current = e.target.value;
    setPassword(e.target.value);
  };

  const login = () => {
    dispatch(toggleIsLoggingIn());
    dispatch(loginReq({ loginEndpoint, username, password }));
  };

  const resetPassword = async () => {
    const result = await dispatch(resetPasswordReq(username));
    if (result.type === 'session/resetPasswordReq/rejected') {
      setHasResetError(true);
    } else {
      setHasResetError(false);
      setHasSentForgotPassword(true);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (!isForgotPassword && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        event.preventDefault();

        dispatch(toggleIsLoggingIn());
        dispatch(
          loginReq({ loginEndpoint, username: usernameRef.current, password: passwordRef.current }),
        );
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [isForgotPassword]);

  function renderLoginForm() {
    return (
      <>
        <h1 className="iq4-login-form__box--title">Sign in</h1>
        <div role="form" className="iq4-login-form__box--login-container">
          <div className="iq4-login-form__box--field-container">
            <label htmlFor="username">Email:</label>
            <TextField
              id="username"
              classes={{
                root: hasLoginError
                  ? 'iq4-login-form__box--field-input-root-error'
                  : 'iq4-login-form__box--field-input-root',
              }}
              value={username}
              onChange={handleEmailChange}
              InputProps={{
                disableUnderline: true,
                classes: {
                  input: hasLoginError
                    ? 'iq4-login-form__box--field-input-error'
                    : 'iq4-login-form__box--field-input',
                  notchedOutline: hasLoginError ? 'iq4-login-form__box--no-border' : '',
                },
              }}
              variant="outlined"
            />
          </div>
          <div className="iq4-login-form__box--field-container">
            <label htmlFor="password" className="iq4-login-form__box--field-label">
              Password:
            </label>
            <TextField
              id="password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              clas
              classes={{
                root: hasLoginError
                  ? 'iq4-login-form__box--field-input-root-error'
                  : 'iq4-login-form__box--field-input-root',
              }}
              InputProps={{
                classes: {
                  input: hasLoginError
                    ? 'iq4-login-form__box--field-input-error'
                    : 'iq4-login-form__box--field-input',
                  notchedOutline: hasLoginError ? 'iq4-login-form__box--no-border' : '',
                },
              }}
              variant="outlined"
            />
          </div>
          {hasLoginError && (
            <div className="iq4-login-form__box--error-container">
              <SimpleIcon name="error" /> Incorrect Username or Password
            </div>
          )}
          <Button
            loading={isLoggingIn}
            onClick={login}
            className={`iq4-login-form__box--button ${
              hasLoginError ? 'iq4-login-form__box--error-button' : ''
            }`}
            variation="ghost"
          >
            Log In
          </Button>
          <div
            className="iq4-login-form__forgot-text"
            tabIndex={0}
            role="button"
            onClick={() => setIsForgotPassword(true)}
            onKeyPress={() => setIsForgotPassword(true)}
          >
            Forgot Password?
          </div>
        </div>
      </>
    );
  }

  function renderForgotPassword() {
    return (
      <>
        <h1 className="iq4-login-form__box--title-2">Forgot Password</h1>
        {!hasSentForgotPassword && (
          <>
            <div className="iq4-login-form__forgot-info-text">
              To reset your password, please enter the email address associated with this account
              below. We will send you further instructions to reset your password.
              {hasResetError && (
                <div className="iq4-login-form__box--error-container" style={{ width: '100%' }}>
                  <SimpleIcon name="error" /> There was an error resetting your password. Please try
                  again.
                </div>
              )}
            </div>
            <div role="form" className="iq4-login-form__box--login-container">
              <div className="iq4-login-form__box--field-container">
                <label htmlFor="username">Email:</label>
                <TextField
                  id="username"
                  classes={{
                    root: hasLoginError
                      ? 'iq4-login-form__box--field-input-root-error'
                      : 'iq4-login-form__box--field-input-root',
                  }}
                  value={username}
                  onChange={handleEmailChange}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: hasLoginError
                        ? 'iq4-login-form__box--field-input-error'
                        : 'iq4-login-form__box--field-input',
                      notchedOutline: hasLoginError ? 'iq4-login-form__box--no-border' : '',
                    },
                  }}
                  variant="outlined"
                />
              </div>
              {hasLoginError && (
                <div className="iq4-login-form__box--error-container">
                  <SimpleIcon name="error" /> Incorrect Username or Password
                </div>
              )}
              <Button
                loading={isLoggingIn}
                onClick={resetPassword}
                className={`iq4-login-form__box--button ${
                  hasLoginError ? 'iq4-login-form__box--error-button' : ''
                }`}
                variation="ghost"
              >
                Submit
              </Button>
            </div>
          </>
        )}
        {hasSentForgotPassword && (
          <>
            <div className="iq4-login-form__forgot-info-text">Password Reset Email Sent</div>
            <div className="iq4-login-form__forgot-info-text">
              We have sent an email to the address associated with your account. Please check your
              inbox (and spam/junk folder) for further instructions on how to reset your password.
            </div>
          </>
        )}
      </>
    );
  }

  return (
    <main id="main">
      <div className="iq4-login-form__container">
        <div className="iq4-login-form__box">
          <div className="iq4-login-form__box--logo">
            <Logo />
          </div>
          {!isForgotPassword && renderLoginForm()}
          {isForgotPassword && renderForgotPassword()}
        </div>
      </div>
    </main>
  );
};
