import React from 'react';
import { useDispatch } from 'react-redux';
import { FileUpload } from 'common/components/Form/fieldTypes';
import { ImageUploadPlaceholder } from 'common/components/ImageUploadPlaceholder';
import { PROFILE_EDUCATION_URL } from 'common/constants/endpoints';
import { THUMBNAIL_MIME_TYPES } from 'common/constants/mimeTypes';
import { updateProfileSection } from 'common/store/features/profile/profileSlice';
import { PROFILE_TYPE, sendFormDataRequest } from 'common/utils';

export const useProfileEducationImageFormDefinition = ({ values, onComplete }) => {
  const dispatch = useDispatch();

  const fieldsDefinition = [
    {
      isCustom: true,
      component: ({ ...formProps }) => (
        <FileUpload
          triggerLabel={<ImageUploadPlaceholder isLarge={true} label="Select Image" />}
          name="image"
          fileTypes={THUMBNAIL_MIME_TYPES}
          type="image"
          {...formProps}
        />
      ),
    },
  ];

  const onEdit = (data) => {
    return sendFormDataRequest({
      url: `${PROFILE_EDUCATION_URL}/${values.id}`,
      verb: 'PUT',
      data,
    }).then((resp) =>
      dispatch(updateProfileSection({ section: PROFILE_TYPE.EDUCATION, value: resp })),
    );
  };

  return {
    fieldsDefinition,
    onEdit,
    formDefinition: {
      fieldsDefinition,
      onEdit,
      onComplete,
    },
  };
};
