import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AssessmentStars, SimpleIcon, SkillsSearchBar } from 'common/components';
import { useClickOutside } from 'common/hooks/useClickOutside';
import './TopSkillsList.scss';

const DEFAULT_TOP_SKILLS_COUNT = 10;
/**
 * Top Skills components that lets you manage your top skills
 * @param {object[]} skills - All your skills, order based on the position in the array
 * @param {function} onReorder - callback to notify parent when skill list updated, passing the new update skill list to parent
 * @param {function} onReplaceSkill - callback that get's called when a user is trying to replace a skill
 * @param {number} [topSkillsCount] - The number of top skills to display
 * @param {string} [className] - Extend className
 */
export const TopSkillsList = ({
  skills,
  onReorder,
  onReplaceSkill,
  topSkillsCount = DEFAULT_TOP_SKILLS_COUNT,
  className,
  ...props
}) => {
  const [topSkills, setTopSkills] = useState(getTopSkills(skills, topSkillsCount));
  const [replaceSkillID, setReplaceSkillID] = useState(null);
  const [skillsNotInTop, setSkillsNotInTop] = useState(getSkillsNotInTop(skills, topSkillsCount));
  const [skillSearchTerm, setSkillSearchTerm] = useState('');
  const [searchedForSkills, setSearchedForSkills] = useState(
    getSearchedForSkills(skillsNotInTop, skillSearchTerm),
  );

  const prevDestination = useRef(null);
  const searchInputRef = useRef(null);
  const [clickedOutside, setClickedOutside] = useClickOutside(searchInputRef);

  // update top skills if new skills are passed down
  useEffect(() => {
    setTopSkills(getTopSkills(skills, topSkillsCount));
  }, [skills, topSkillsCount]);

  // keep skills not in top up to date.
  useEffect(() => {
    setSkillsNotInTop(getSkillsNotInTop(skills, topSkillsCount));
  }, [skills, topSkillsCount]);

  // keep searched for skills up to date
  useEffect(() => {
    setSearchedForSkills(getSearchedForSkills(skillsNotInTop, skillSearchTerm));
  }, [skillsNotInTop, skillSearchTerm]);

  // reset search state when hiding search bar
  useEffect(() => {
    if (replaceSkillID === null) {
      setSkillSearchTerm('');
    }
  }, [replaceSkillID]);

  // close the skill's search input if user clicks outside
  useEffect(() => {
    if (replaceSkillID !== null && clickedOutside) setReplaceSkillID(null);
  }, [replaceSkillID, clickedOutside]);

  const onDragStart = () => {
    // if replace search bar is open, close it.
    if (replaceSkillID !== null) setReplaceSkillID(null);
  };

  const onDragUpdate = (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const newOrderedSkills = reOrderInternally(
      prevDestination.current !== null ? prevDestination.current : source.index,
      destination.index,
      topSkills,
    );

    setTopSkills(newOrderedSkills);

    prevDestination.current = destination.index;
  };

  const onDragEnd = () => {
    onReorder(topSkills);

    prevDestination.current = null;
  };

  const handleSearchChange = (newSearchTerm) => {
    setSkillSearchTerm(newSearchTerm);
  };

  const handleSearchSkillClick = (newSkill) => {
    const rank = topSkills.findIndex((skill) => skill.id === replaceSkillID) + 1;
    onReplaceSkill({
      replaceSkill: { id: replaceSkillID, rank },
      replaceWithSkill: { id: newSkill.id, rank: null },
    });
  };

  const handleSkillItemClick = (e, skill) => {
    if (replaceSkillID === null) {
      setClickedOutside(false);
      e.stopPropagation();
    }

    setReplaceSkillID(skill.id);
  };

  const renderMissingSkills = () => {
    const missingSkillsCount = topSkillsCount - skills.length;

    if (missingSkillsCount <= 0) return;

    return Array.from(Array(missingSkillsCount).keys()).map((_, index) => (
      <div
        className={`iq4-top-skills-list__item iq4-top-skills-list__item--missing ${
          (index + 1 + skills.length) % 2 === 0
            ? 'iq4-top-skills-list__item--grey'
            : 'iq4-top-skills-list__item--white'
        }`}
        key={index}
      >
        <div className="iq4-top-skills-list__item-content">
          <div className="iq4-top-skills-list__item-number iq4-top-skills-list__item-number--missing">
            {index + 1 + skills.length}
          </div>

          <div className="iq4-top-skills-list__item-skill iq4-top-skills-list__item-skill--missing">
            Missing Skill
          </div>
        </div>
      </div>
    ));
  };

  function getTopSkills(skills = [], count) {
    return skills.slice(0, count);
  }

  function getSkillsNotInTop(skills, count) {
    return skills.slice(count).map((s) => ({
      ...s,
      tier1: s.tier1Label,
      tier2: s.tier2Label,
    }));
  }

  function getSearchedForSkills(skillsNotInTop, searchTerm) {
    if (!searchTerm) return skillsNotInTop;

    return skillsNotInTop.filter((skill) => {
      return skill.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    });
  }

  function reOrderInternally(sourceIndex, destinationIndex, topSkills) {
    const withoutElement = [
      ...topSkills.slice(0, sourceIndex),
      ...topSkills.slice(sourceIndex + 1),
    ];

    const reorderedSkills = [
      ...withoutElement.slice(0, destinationIndex),
      topSkills[sourceIndex],
      ...withoutElement.slice(destinationIndex),
    ];

    return reorderedSkills;
  }

  return (
    <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="iq4TopSkillsList" direction="vertical">
        {(providedDroppable) => (
          <ol
            className={`iq4-top-skills-list ${className ? className : ''}`}
            ref={providedDroppable.innerRef}
            {...providedDroppable.droppableProps}
            {...props}
          >
            {topSkills.map((skill, index) => (
              <Draggable draggableId={skill.id.toString()} index={index} key={skill.id}>
                {(providedDraggable, { isDragging }) => (
                  <li
                    className={`iq4-top-skills-list__item ${
                      skill.id === replaceSkillID ? 'iq4-top-skills-list__item--with-input' : ''
                    } ${isDragging ? 'iq4-top-skills-list__item--dragging' : ''} ${
                      (index + 1) % 2 === 0
                        ? 'iq4-top-skills-list__item--grey'
                        : 'iq4-top-skills-list__item--white'
                    }`}
                    ref={providedDraggable.innerRef}
                    onClick={(e) => void handleSkillItemClick(e, skill)}
                    {...providedDraggable.draggableProps}
                    {...providedDraggable.dragHandleProps}
                  >
                    {skill.id !== replaceSkillID && (
                      <>
                        <SimpleIcon
                          className={`iq4-top-skills-list__item-drag-icon ${
                            isDragging ? 'iq4-top-skills-list__item-drag-icon--dragging' : ''
                          }`}
                          name="dragSort"
                        />

                        <div className="iq4-top-skills-list__item-content">
                          <div className="iq4-top-skills-list__item-number">{index + 1}</div>
                          <div
                            className={`iq4-top-skills-list__item-skill ${
                              isDragging ? 'iq4-top-skills-list__item-skill--dragging' : ''
                            }`}
                          >
                            {skill.value}
                          </div>
                        </div>

                        {/* Hiding proficiency stars until API is updated - https://iq4projects.atlassian.net/browse/LERI-52 */}
                        {/* <div className="iq4-top-skills-list__assessment">
                          <AssessmentStars assessment={skill.assessment} displayOnly />
                        </div> */}
                      </>
                    )}

                    {skill.id === replaceSkillID && (
                      <SkillsSearchBar
                        noSearchIcon
                        noResultsMessage={
                          skillsNotInTop.length ? (
                            <>
                              No skills found for <strong>{skillSearchTerm}</strong>
                            </>
                          ) : (
                            <>
                              Add more skills to your passport to be able to replace{' '}
                              <strong>{skill.value}</strong>
                            </>
                          )
                        }
                        className="iq4-top-skills-list__search-bar"
                        minSearchLength={0}
                        debounceDelay={0}
                        placeholder={`Replace your skill at position ${index + 1}`}
                        results={searchedForSkills}
                        onClick={(e) => void e.stopPropagation()}
                        onChange={handleSearchChange}
                        onSkillClick={handleSearchSkillClick}
                      />
                    )}
                  </li>
                )}
              </Draggable>
            ))}
            {providedDroppable.placeholder}

            {renderMissingSkills()}
          </ol>
        )}
      </Droppable>
    </DragDropContext>
  );
};
