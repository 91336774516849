import React, { useState } from 'react';
import FocusLock from 'react-focus-lock';
import { SimpleIcon, Drawer } from 'common/components';

export function ProfileHelp() {
  const [showTopSkillsHelp, setShowTopSkillsHelp] = useState(false);

  function toggleHelp(e) {
    e.preventDefault();
    e.stopPropagation();
    setShowTopSkillsHelp(!showTopSkillsHelp);
  }

  return (
    <>
      <div
        className="iq4-t-shape__info"
        role="button"
        tabIndex={0}
        onClick={toggleHelp}
        onKeyPress={toggleHelp}
        aria-label="Get more info about T-Shape labels"
      >
        <SimpleIcon name="infoDefault" />
        <div className="iq4-t-shape__info--text">What's this?</div>
      </div>
      {showTopSkillsHelp && (
        <Drawer
          anchor="right"
          variant="persistent"
          isOpen={true}
          classes={{
            root: 'iq4-tshape-page__drawer',
            paper: 'iq4-tshape-help-page__drawer-paper',
          }}
        >
          <FocusLock returnFocus={true}>
            <div style={{ paddingTop: '40px' }}>
              <button
                aria-label="Close"
                className="iq4-tshape-page__drawer__dismiss-button"
                onClick={() => setShowTopSkillsHelp(false)}
              >
                <SimpleIcon name="close" />
              </button>
              <div className="iq4-tshape-page__drawer__role-container">
                <div className="iq4-tshape-page__drawer__role-container--title-2">
                  ABOUT PROFILE
                </div>
                <div className="iq4-tshape-page__drawer__role-container--divider" />
                <div className="iq4-tshape-page__drawer__role-container--role">INTRODUCTION</div>
                <p style={{ fontSize: '0.875rem' }}>
                  Your profile page on this skills-based platform serves as your digital
                  representation, allowing you to showcase your qualifications, credentials and
                  experiences to potential employers. The cards for Personal, Skills, Portfolio,
                  Education, and Experience enable you to organize and highlight key aspects of your
                  professional identity, organised by a number of cards on the screen:
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: 40 }}
                >
                  <div className="iq4-personal-card__heading-link">
                    <SimpleIcon
                      className="iq4-personal-card__heading-icon"
                      name="personCircle"
                      role="presentation"
                      hidden
                    />
                    <h2 className="iq4-personal-card__heading-title">Personal</h2>
                  </div>
                </div>
                <p style={{ fontSize: '0.875rem', padding: '0 1rem' }}>
                  This card contains essential information about you, such as your name, contact
                  details, and a brief bio.
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: 40 }}
                >
                  <div className="iq4-personal-card__heading-link">
                    <SimpleIcon
                      className="iq4-personal-card__heading-icon"
                      name="skillsCircle"
                      role="presentation"
                      hidden
                    />
                    <h2 className="iq4-personal-card__heading-title">Skills</h2>
                  </div>
                </div>
                <p style={{ fontSize: '0.875rem', padding: '0 1rem' }}>
                  Here, you can list and detail the skills you possess, whether they’re technical,
                  soft skills, or specific competencies relevant to your field. This section
                  showcases your expertise and strengths. <br />
                  <br />
                  <strong>At a glance</strong> offers a snapshot of your total skills view and
                  explores your skills through categories, providing a quick overview of key
                  competencies.
                  <br />
                  <br />
                  <strong> The T-shape</strong> helps you visualize how your Professional &
                  Leadership, Technical, and Operational skill proficiencies compare against the
                  target proficiencies for your existing role and other cyber work roles and where
                  you have skill gaps. <br />
                  <br />
                  <strong>Top 10 Skills</strong> Our platform identifies and ranks your key
                  strengths based on various criteria. Discover your standout abilities at a glance
                  to enhance your profile and stay competitive in today’s dynamic landscape.
                  <br />A ’See more’ link will bring you to your Skills page.
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: 40 }}
                >
                  <div className="iq4-personal-card__heading-link">
                    <SimpleIcon
                      className="iq4-personal-card__heading-icon"
                      name="educationCircle"
                      role="presentation"
                      hidden
                    />
                    <h2 className="iq4-personal-card__heading-title">Education</h2>
                  </div>
                </div>
                <p style={{ fontSize: '0.875rem', padding: '0 1rem' }}>
                  This card outlines your educational background, including (and allowing you to
                  include) degrees, certifications, courses, and any academic achievements. It helps
                  establish your level of expertise and qualification in your chosen field. A ’See
                  more’ link will bring you to all your Education details.
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: 40 }}
                >
                  <div className="iq4-personal-card__heading-link">
                    <SimpleIcon
                      className="iq4-personal-card__heading-icon"
                      name="experienceCircle"
                      role="presentation"
                      hidden
                    />
                    <h2 className="iq4-personal-card__heading-title">Experience</h2>
                  </div>
                </div>
                <p style={{ fontSize: '0.875rem', padding: '0 1rem' }}>
                  In this section, you can document your professional experience, including past
                  jobs, internships, volunteer work, or any other relevant roles you’ve held. It
                  demonstrates your practical knowledge and track record in your industry. A ’See
                  more’ link will bring you to all your Education details. Portfolio &
                  Certifications The portfolio card is where you can display samples of your work,
                  projects you’ve completed, or any other relevant accomplishments. It provides
                  concrete evidence of your skills and abilities.
                </p>
                <div
                  className="iq4-tshape-page__drawer__role-container--role"
                  style={{ marginTop: 40 }}
                >
                  <div className="iq4-personal-card__heading-link">
                    <SimpleIcon
                      className="iq4-personal-card__heading-icon"
                      name="portfolioCircle"
                      role="presentation"
                      hidden
                    />
                    <h2 className="iq4-personal-card__heading-title">Portfolio</h2>
                  </div>
                </div>
                <p style={{ fontSize: '0.875rem', padding: '0 1rem' }}>
                  The portfolio card is where you can upload and display samples of your work,
                  projects you’ve completed, or any other relevant certification documents or
                  accomplishments. It provides a visible way of showcasing concrete evidence of your
                  skills, knowledge and abilities. A ’See more’ link will bring you to all your
                  Portfolio & Certifications items.
                </p>
                <p style={{ fontSize: '0.875rem', marginTop: 40 }}>
                  Overall, your profile page acts as a comprehensive snapshot of who you are
                  professionally, allowing others to assess your suitability for opportunities,
                  collaborations, or employment based on your skills, accomplishments, and
                  background. Keeping it updated and well-organized is essential for making a strong
                  impression and attracting relevant opportunities.
                </p>
              </div>
            </div>
          </FocusLock>
        </Drawer>
      )}
    </>
  );
}
