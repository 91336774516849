import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import {
  CredentialsPage,
  OnboardingPage,
  ProfileRoutes,
  ManageSkillsPage,
  CareerPathwaysPage,
  ConnectionsPage,
  DiscoveryDashboardPage,
  LoginPage,
  TermsAndConditionsPage,
  PrivacyPolicyPage,
  RegisterUserPage,
  NotAuthorizedRegistrationPage,
  SitemapPage,
  ResetPasswordPage,
  SettingsPage,
  ActivateCodePage,
} from 'pages';
import { selectFeatures } from 'store/features/config/selectors';
import { ROUTES } from './routeNames';
import { ProtectedRoute } from './ProtectedRoute';
import { SessionTimeoutModal, AcceptTerms } from 'common/components';
import { selectIsSessionExpired } from 'common/store/features/session/selectors';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { PublicProfileRoutes } from 'pages/PublicWallet/PublicProfileRoutes';

const context = process.env.REACT_APP_CONTEXT;

const Router = ({ defaultRoute }) => {
  const isSessionExpired = useSelector(selectIsSessionExpired);

  return (
    <BrowserRouter>
      <SessionTimeoutModal isOpen={isSessionExpired} Logo={Logo} />
      <Switch>
        <Redirect exact from={ROUTES.MY_RECORDS} to={ROUTES.MY_RECORDS_MANAGE_ROOT} />
        <ProtectedRoute component={OnboardingPage} path={ROUTES.ONBOARDING_ROOT} />
        <ProtectedRoute component={ProfileRoutes} path={ROUTES.PROFILE_ROOT} />
        <ProtectedRoute component={ManageSkillsPage} exact path={ROUTES.MANAGE_SKILLS} />
        <ProtectedRoute component={CredentialsPage} path={ROUTES.CREDENTIALS} />
        <ProtectedRoute component={CareerPathwaysPage} exact path={ROUTES.CAREER_PATHWAYS} />
        <ProtectedRoute component={ConnectionsPage} exact path={ROUTES.CONNECTIONS} />
        <ProtectedRoute component={SitemapPage} exact path={ROUTES.SITEMAP} />
        <ProtectedRoute component={SitemapPage} exact path={ROUTES.SITEMAP} />
        <ProtectedRoute component={SettingsPage} exact path={ROUTES.SETTINGS} />
        <ProtectedRoute
          component={TermsAndConditionsPage}
          exact
          path={ROUTES.TERMS_AND_CONDITIONS}
        />
        <ProtectedRoute component={PrivacyPolicyPage} exact path={ROUTES.PRIVACY_POLICY} />
        <ProtectedRoute
          component={DiscoveryDashboardPage}
          exact
          path={ROUTES.DISCOVERY_DASHBOARD}
        />
        <Route component={LoginPage} exact path={ROUTES.HOME} />
        <Route component={RegisterUserPage} exact path={ROUTES.REGISTER_USERS} />
        <Route component={PublicProfileRoutes} path={`${ROUTES.PUBLIC_WALLET}/user/:id`} />
        <Route component={ResetPasswordPage} path={`${ROUTES.RESET_PASSWORD}`} />
        <Route component={ActivateCodePage} exact path={`${ROUTES.ACTIVATE_CODE}`} />

        <Route
          component={NotAuthorizedRegistrationPage}
          path={ROUTES.NOT_AUTHORIZED_REGISTRATION}
        />

        <Redirect exact from={`${context}`} to={ROUTES[defaultRoute]} />
        <Redirect from="*" to="/404" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
