import React from 'react';
import { LoadingSkeleton } from 'common/components';
import './MyRecordsLoadingSkeleton.scss';

export const MyRecordsLoadingSkeleton = () => (
  <>
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
    <LoadingSkeleton variant="rect" width="100%" height={80} style={{ marginTop: '10px' }} />
  </>
);
