import React, { useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { selectDisplayTour } from 'common/store/features/ui/selectors';
import { setDisplayTour } from 'common/store/features/ui/uiSlice';
import './IQ4Tour.scss';
import useSetTourOverlayHeight from 'common/hooks/useSetTourOverlayHeight';

const IQ4Tour = ({ steps, exposeCallback = () => {} }) => {
  const dispatch = useDispatch();
  const displayTour = useSelector(selectDisplayTour);
  useSetTourOverlayHeight();

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(setDisplayTour(false));
    }
    exposeCallback(data);
  };

  useEffect(() => {
    dispatch(setDisplayTour(true));
  }, []);

  return (
    <Joyride
      disableBeacon={true}
      steps={steps}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      run={displayTour}
      callback={handleJoyrideCallback}
      styles={{
        buttonBack: {
          backgroundColor: 'transparent',
          color: '#0070F0',
        },
        buttonSkip: {
          backgroundColor: 'transparent',
          color: '#0070F0',
        },
        buttonNext: {
          backgroundColor: '#0070F0',
          color: 'white',
        },
      }}
      locale={{
        last: 'Exit Tour',
        skip: 'Exit Tour',
      }}
    />
  );
};

export default IQ4Tour;
