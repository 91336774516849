import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { debounce } from 'lodash';
import { TShapePage } from '../TShapePage';
import {
  Topbar,
  ConsentBanner,
  Container,
  Drawer,
  RecommendedRolesPanel,
  RecommendedRolesEmptyPanel,
  FixedTooltip,
  Footer,
  InnerTabs,
  CountIndicator,
  SimpleIcon,
  Button,
  PathwaysGraphV2,
  SuggestedRolesSelect,
} from 'common/components';
import {
  selectDisplayPathwayDetailTour,
  selectDisplaySecondPathwayDetailTour,
  selectDisplayTour,
  selectShowJobFamilyExplanation,
  selectShowJobRoleExplanation,
} from 'common/store/features/ui/selectors';
import { BrowseRolesInput, PathwayDetail, RolesSearchBar } from 'common/components/Pathways';
import { useBreakpoints } from 'common/hooks/useBreakpoints';
import { PathwaysGraph, PathwayDonut } from 'common/components/PathwaysGraph';
import { ProfileResumeParse } from 'components/Profile';
import {
  selectRecommendedWorkRoles,
  selectSelectedPathwaysSpokes,
} from 'common/store/features/pathways/selectors';
import {
  selectHasFetchedJobFamilies,
  selectIsFetchingDemographicData,
  selectJobFamilies,
  selectWorkRole,
} from 'common/store/features/framework/selectors';
import { selectIsPublicProfile, selectProfile } from 'common/store/features/profile/selectors';
import { fetchTShapeGraphicData } from 'common/store/features/t/slice';
import { selectTShapeData } from 'common/store/features/t/selectors';
import {
  fetchWorkrolesByJobFamilyReq,
  fetchRecommendedRolesReq,
  fetchUserSelectedWorkRolesReq,
  addPathwayReq,
  removePathwayReq,
  removeAddedSuggestion,
} from 'common/store/features/pathways/pathwaysSlice';
import {
  fetchJobFamiliesReq,
  fetchWorkRoleReq,
  setIsFetchingDemographicData,
} from 'common/store/features/framework/frameworkSlice';
import { selectConsentForPlanning } from 'common/store/features/records/selectors';
import { ROUTES } from 'routes/routeNames';
import {
  fetchWalletProfile,
  updateWalletProfileConsentReq,
} from 'common/store/features/records/recordsSlice';
import { fetchAllSkillsReq } from 'common/store/features/skills/skillsSlice';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { selectFeatures } from 'store/features/config/selectors';
import PathwayGrid from 'common/assets/svg/pathway-grid.svg';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { useScrollToTop } from 'common/hooks/useScrollToTop';
import { useRolesSearch } from 'common/hooks/useRolesSearch';
import { selectSkills } from '../../common/store/features/skills/selectors';
import { SelectWorkRoles } from 'common/components/SelectWorkRoles';
import './CareerPathwaysPage.scss';
import FocusLock from 'react-focus-lock';
import {
  setDisplayTour,
  setPathwayDetailTour,
  setShowJobFamilyExplanation,
  setShowJobRoleExplanation,
} from '../../common/store/features/ui/uiSlice';
import { JobFamilyExplanation } from './components/JobFamilyExplanation';
import { JobRoleExplanation } from './components/JobRoleExplanation';
import { ReactFlowProvider } from 'reactflow';
import { LegacyPathwayDonut } from '../../common/components/PathwaysGraph/LegacyPathwayDonut';
import { fetchCredentialsReq } from '../../common/store/features/credentials/credentialsSlice';
import Joyride from 'react-joyride';

export const CareerPathwaysPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedWorkRoleId, setSelectedWorkRoleId] = useState(null);
  const [showTooltips, setShowTooltips] = useState(false);
  const [showRecommendedRoles, setShowRecommendedRoles] = useState(true);
  const [showRecommendedRolesEmpty, setShowRecommendedRolesEmpty] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSpecialtyArea, setSelectedSpecialtyArea] = useState(null);
  const [selectedJobFamily, setSelectedJobFamily] = useState(null);
  const [showResumeParser, setShowResumeParser] = useState(false);
  const [modalTriggerElement, setModalTriggerElement] = useState(null);
  const [modalTriggerSource, setModalTriggerSource] = useState('');
  const [isLegendVisible, setIsLegendVisible] = useState(true);
  const [elementToFocus, setElementToFocus] = useState(null);

  const roleResults = useRolesSearch(searchTerm);

  const [isConsentDisplayed, setIsConsentDisplayed] = useState(false);
  const [activeTShapeView, setActiveTShapeView] = useState(false);
  const footerContainerRef = useRef(null);
  const mainGraphRef = useRef(null);
  const suggestedRolesSelectRef = useRef();

  const isPublicProfile = useSelector(selectIsPublicProfile);
  const showJobFamilyExplanation = useSelector(selectShowJobFamilyExplanation);
  const showJobRoleExplanation = useSelector(selectShowJobRoleExplanation);
  const isFetchingDemographicData = useSelector(selectIsFetchingDemographicData);
  const showTour = useSelector(selectDisplayTour);
  const showPathwayDetailTour = useSelector(selectDisplayPathwayDetailTour);
  const showSecondTour = useSelector(selectDisplaySecondPathwayDetailTour);

  const { id } = useParams();

  const hasRoleId = !!new URLSearchParams(window.location.search)?.get('r');

  const {
    applicationTitle,
    personalProfileSource,
    displayBrowseRoles,
    displaySearchRoles,
    consentForPlanningEnabled,
    enableDemographicData,
  } = useSelector(selectFeatures);
  const consentForPlanning = useSelector(selectConsentForPlanning);

  const recommendedWorkRoles = useSelector(selectRecommendedWorkRoles);
  const searchRoles = [];
  const jobFamilies = useSelector(selectJobFamilies);

  const selectedPathwaysSpokes = useSelector(selectSelectedPathwaysSpokes);
  const { personal } = useSelector(selectProfile);
  const tShapeGraphicData = useSelector(selectTShapeData);
  const { skills } = useSelector(selectSkills);
  const hasFetchedJobFamilies = useSelector(selectHasFetchedJobFamilies);
  const location = useLocation();

  const selectedWorkRoleDetails = useSelector(
    selectWorkRole({ id: new URLSearchParams(location.search).get('r'), isPublicProfile }),
  );

  const [pathwaysBackgroundOffset, setPathwaysBackgroundOffset] = useState({ x: null, y: null });

  const breakpoint = useBreakpoints();
  //const [isMobile, setIsMobile] = useState(getIsMobile(breakpoint));
  const isMobile = false;

  const [currentPanelState, setCurrentPanelState] = useState(null);
  const scrollableNodeRef = useRef(null);

  useScrollToTop(scrollableNodeRef, [currentPanelState]);

  useEffect(() => {
    const listener = () => {
      const headerNode = document.querySelector('.iq4-topbar__container');
      const consentNode = document.querySelector('.iq4-consent-banner');
      const drawerContainerNode = document.querySelector('.iq4-career-pathways__drawer-paper');

      if (headerNode && drawerContainerNode) {
        drawerContainerNode.style.setProperty(
          'top',
          `${headerNode.offsetHeight + (consentNode ? consentNode.offsetHeight : 0)}px`,
          'important',
        );

        drawerContainerNode.style.setProperty(
          'height',
          `calc(100% - ${
            headerNode.offsetHeight + (consentNode ? consentNode.offsetHeight : 0)
          }px)`,
          'important',
        );
      }
    };

    window.addEventListener('resize', listener);

    // init
    listener();

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [breakpoint, activeTShapeView, isConsentDisplayed]);

  useEffect(() => {
    dispatch(fetchUserSelectedWorkRolesReq());
    dispatch(fetchCredentialsReq());
  }, []);

  useEffect(() => {
    const checkVisibility = () => {
      if (mainGraphRef.current) {
        const rect = mainGraphRef.current.getBoundingClientRect();
        setIsLegendVisible(rect.top >= 0 && rect.bottom <= window.innerHeight);
      }
    };

    window.addEventListener('scroll', checkVisibility);

    return () => window.removeEventListener('scroll', checkVisibility);
  }, []);

  useEffect(() => {
    setIsConsentDisplayed(consentForPlanningEnabled && consentForPlanning?.status === false);
  }, [consentForPlanningEnabled, consentForPlanning]);

  /*  useEffect(() => {
    setIsMobile(getIsMobile(breakpoint));
  }, [breakpoint]);*/

  useEffect(() => {
    consentForPlanningEnabled && dispatch(fetchWalletProfile());
  }, [consentForPlanningEnabled]);

  // load work role data after framework data has loaded.
  useEffect(() => {
    const roleId = new URLSearchParams(location.search).get('r');

    if (roleId && hasFetchedJobFamilies) {
      if (enableDemographicData && !selectedWorkRoleDetails) {
        dispatch(setIsFetchingDemographicData(true));
      }

      if (
        !selectedWorkRoleDetails ||
        (selectedWorkRoleDetails && !selectedWorkRoleDetails.demographics && enableDemographicData)
      ) {
        dispatch(
          fetchWorkRoleReq({
            roleId,
            fetchDemographicData: enableDemographicData,
            isPublicProfile,
            id,
          }),
        );
      }

      setIsDrawerOpen(true);
    } else if (elementToFocus) {
      let childNode = elementToFocus.querySelector('[aria-label*="Click to See"]');
      if (!childNode) {
        childNode = elementToFocus.querySelector('[aria-label*="See more information about"]');
      }

      if (childNode) {
        setTimeout(() => childNode.focus(), 1);
        setElementToFocus(null);
      } else {
        setElementToFocus(null);
      }
    }
  }, [location.search, hasFetchedJobFamilies]);

  useEffect(() => {
    if (isPublicProfile) {
      const roleId = new URLSearchParams(location.search).get('r');

      if (roleId) {
        if (enableDemographicData && !selectedWorkRoleDetails) {
          dispatch(setIsFetchingDemographicData(true));
        }

        if (
          !selectedWorkRoleDetails ||
          (selectedWorkRoleDetails &&
            !selectedWorkRoleDetails.demographics &&
            enableDemographicData)
        ) {
          dispatch(
            fetchWorkRoleReq({
              roleId,
              fetchDemographicData: enableDemographicData,
              isPublicProfile,
              id,
            }),
          );
        }

        setIsDrawerOpen(true);
      }
    }
  }, [isPublicProfile]);

  // set the tshape data based on the currently selected role
  useEffect(() => {
    if (selectedWorkRoleDetails) {
      dispatch(fetchTShapeGraphicData({ roleId: selectedWorkRoleDetails.id, isPublicProfile, id }));
    }
  }, [selectedWorkRoleDetails?.id]);

  useEffect(() => {
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
  }, [personalProfileSource]);

  useEffect(() => {
    dispatch(fetchRecommendedRolesReq());
    dispatch(fetchAllSkillsReq());

    if (!hasFetchedJobFamilies) {
      dispatch(fetchJobFamiliesReq());
    }
  }, []);

  useEffect(() => {
    if (isDrawerOpen && selectedWorkRoleDetails && !isFetchingDemographicData) {
      const drawerContentNode = document.querySelector('.iq4-career-pathways__drawer-content');
      const containerNode = document.querySelector('.iq4-career-pathways__container');
      if (drawerContentNode && containerNode) {
        containerNode.style.setProperty(
          'height',
          `calc(${drawerContentNode.offsetHeight}px + 100px`,
        );
      } else {
        containerNode.style.setProperty('height', `calc(100vh - 201px)`);
      }
    }
  }, [isDrawerOpen, selectedWorkRoleDetails, isFetchingDemographicData]);

  const reset = () => {
    setSelectedCategory(null);
    setSelectedSpecialtyArea(null);
    setSelectedWorkRoleId(null);
    setSelectedJobFamily(null);
    toggleWorkRolePanel();
  };

  function refocusAfterModal() {
    if (modalTriggerElement) {
      const focusElement =
        modalTriggerSource !== 'recommended'
          ? modalTriggerElement.querySelector('.iq4-pathway__circle-out-container--icon-btn')
          : modalTriggerElement;
      if (focusElement) {
        focusElement.focus();
      }
      setModalTriggerElement(null);
      setModalTriggerSource('');
    }
  }

  const handleClose = () => {
    reset();
    setIsDrawerOpen(false);
    refocusAfterModal();
  };

  const handleSelectWorkRole = (workRole) => {
    toggleWorkRolePanel(workRole.id);
  };

  const handleViewRolesDetails = (role, source, index) => {
    if (source && source === 'graph') {
      const triggerElement = document.querySelector(
        `.iq4-pathway__circle-out-container--small-${index + 1}`,
      );

      setModalTriggerElement(triggerElement);
    }

    if (role) {
      toggleWorkRolePanel(role.id);
    }
  };

  const handleSelectJobFamily = (value) => {
    dispatch(fetchWorkrolesByJobFamilyReq(value.id));
    setSelectedJobFamily(value);
    setIsDrawerOpen(true);
  };

  const handlePathwaysGraphRemove = (role) => {
    dispatch(removePathwayReq(role));
    dispatch(removeAddedSuggestion(role));
  };

  const handleCancelWorkRole = () => {
    toggleWorkRolePanel();
  };

  const handleRecommendedWorkRoleDetails = (workRole, index) => {
    toggleWorkRolePanel(workRole.id);

    setModalTriggerElement(document.querySelector(`.iq4-recommended-work-roles-${index}`));
    setModalTriggerSource('recommended');
  };

  const handleAddRoleToPathway = (role) => {
    dispatch(addPathwayReq(role));
  };

  useEffect(() => {
    const handler = (e) => {
      setShowTooltips(false);
    };

    window.addEventListener('click', handler);

    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(function () {
      const pathwaysRootNode = document.getElementsByClassName('iq4-pathway__manual-hexagon')[0];
      if (!pathwaysRootNode) return;
      const pathwaysRootNodePositionData = pathwaysRootNode.getBoundingClientRect();
      const { x, y } = pathwaysRootNodePositionData;
      const svgPatternWidth = 110;
      const svgPatternHeight = 64;
      const svgPatternOffsetHeight = 21;
      const xBackgroundOffset = x % svgPatternWidth;
      const yBackgroundOffset = (y % svgPatternHeight) - svgPatternOffsetHeight;

      setPathwaysBackgroundOffset({ x: xBackgroundOffset, y: yBackgroundOffset });
    }, 24);

    window.addEventListener('resize', handleResize);

    if (pathwaysBackgroundOffset.x === null) {
      handleResize();
    }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function toggleWorkRolePanel(roleId) {
    if (!isPublicProfile) {
      history.push(`${ROUTES.CAREER_PATHWAYS}${roleId ? `?r=${roleId}` : ''}`);
    } else {
      history.push(
        `${ROUTES.PUBLIC_WALLET}/user/${id}/career-pathways${roleId ? `?r=${roleId}` : ''}`,
      );
    }
  }

  const handleOnAccept = (type) => {
    dispatch(
      updateWalletProfileConsentReq({
        [type]: true,
      }),
    );
  };

  function getIsMobile(breakpoint) {
    return breakpoint === 'xs' || breakpoint === 'sm';
  }

  const showFooter = () => {
    return !isDrawerOpen && !isPublicProfile && !getIsMobile(breakpoint);
  };

  function getCareerPathwaysContent() {
    return (
      <>
        {!selectedPathwaysSpokes.length && (
          <p>
            You have not yet added any Career Pathways. Search for a role above or view 'Suggested
            Roles'
          </p>
        )}
        {selectedPathwaysSpokes.map((spoke, index) => {
          return (
            <div className="iq4-career-pathways__page__mobile-donut">
              <LegacyPathwayDonut
                key={index}
                readOnly={false}
                spoke={spoke}
                onDelete={() => {
                  dispatch(removeAddedSuggestion(spoke));
                  handlePathwaysGraphRemove(spoke);
                }}
                onSelect={() => {
                  handleViewRolesDetails(spoke);
                }}
              />
            </div>
          );
        })}
      </>
    );
  }

  function displayMobileLayout() {
    if (recommendedWorkRoles.length) {
      return (
        <InnerTabs
          id="iq4-career-pathways__page__mobile-tabs"
          className="iq4-career-pathways__page__mobile-tabs"
          tabsProps={{ variant: 'fullWidth' }}
          data={[
            {
              title: 'Career Pathways',
              content: getCareerPathwaysContent(),
            },
            {
              title: (
                <div className="iq4-career-pathways__page__mobile-tabs__header-title">
                  Suggested
                  <CountIndicator count={recommendedWorkRoles.length} variation="secondary" />
                </div>
              ),
              content: (
                <RecommendedRolesPanel
                  className="iq4-career-pathways__page-recommended-roles-panel"
                  roles={recommendedWorkRoles}
                  onClose={() => setShowRecommendedRoles(false)}
                  onClick={(role, index) => handleRecommendedWorkRoleDetails(role, index)}
                />
              ),
            },
          ]}
        />
      );
    }

    return (
      <div className="iq4-career-pathways__page__mobile-tabs">{getCareerPathwaysContent()}</div>
    );
  }

  const recommendedWorkRolesToUse =
    selectedPathwaysSpokes && selectedPathwaysSpokes.length
      ? recommendedWorkRoles.filter(
          (wr) => !selectedPathwaysSpokes.map((x) => x.id).includes(wr.id),
        )
      : recommendedWorkRoles;

  const shouldDisplayRecommendedRolesPanel = () => {
    if (!skills?.length) {
      return false;
    }

    return !!recommendedWorkRoles.length;
  };

  const closeSelectWorkRoles = () => {
    setIsDrawerOpen(false);
    setSelectedJobFamily(null);
  };

  function hideJobFamilyExplanation() {
    dispatch(setShowJobFamilyExplanation(false));
  }

  function hideJobRoleExplanation() {
    dispatch(setShowJobRoleExplanation(false));
  }

  function handleTourCallback(data) {
    if (data.action === 'close') {
      dispatch(setDisplayTour(false));
      dispatch(setPathwayDetailTour(false));

      if (suggestedRolesSelectRef.current) {
        suggestedRolesSelectRef.current.closePopper();
      }

      return false;
    }

    if (data.index === 1 && data.lifecycle === 'ready') {
      if (suggestedRolesSelectRef.current) {
        suggestedRolesSelectRef.current.openPopper();
      }
    }

    if (data.index === 2 && data.lifecycle === 'ready') {
      if (suggestedRolesSelectRef.current) {
        suggestedRolesSelectRef.current.closePopper();
      }
    }

    if (data.status === 'finished' && data.type === 'tour:end') {
      const suggestedRole = document.querySelector('.suggested-role');
      const roleNode = document.querySelector('.role-node-button');
      if (suggestedRole) {
        suggestedRole.click();
      } else if (roleNode) {
        roleNode.click();
      }

      if (suggestedRolesSelectRef.current) {
        suggestedRolesSelectRef.current.closePopper();
      }

      dispatch(setPathwayDetailTour(true));
    }
  }

  console.log('showTour', showTour);
  console.log('selectedPathwaysSpokes', selectedPathwaysSpokes);

  return (
    <>
      <Helmet title={`${applicationTitle} - Career Pathways`} />
      <Topbar
        isPublicProfile={isPublicProfile}
        Logo={Logo}
        onUploadResume={() => setShowResumeParser(true)}
      />
      {showResumeParser && <ProfileResumeParse onClose={() => setShowResumeParser(false)} />}
      {isConsentDisplayed && (
        <ConsentBanner
          title="Planning Consent"
          message={consentForPlanning?.message}
          acceptLabel="Accept"
          onAccept={() => handleOnAccept('consentForPlanning')}
        />
      )}

      <main
        className={`iq4-career-pathways__page ${
          isConsentDisplayed ? `iq4-career-pathways__page__with-consent-banner` : ''
        }`}
        id="main"
      >
        {activeTShapeView && (
          <TShapePage
            selectedRole={selectedWorkRoleDetails}
            renderOnlySelfSkills={!selectedWorkRoleDetails}
            onClose={() => void setActiveTShapeView(false)}
          />
        )}
        {showTour && (
          <Joyride
            disableBeacon={true}
            callback={handleTourCallback}
            steps={[
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      Need help understanding this section of the platform? This brief tutorial will
                      show you everything you need to know!
                    </p>
                  </div>
                ),
                disableOverlay: false,
                placement: 'center',
                target: 'body',
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
              },
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      These job roles are suggested based on the skills profile you've created. This
                      is your chance to explore different career options and see how your skills
                      match those roles.
                    </p>
                  </div>
                ),
                disableOverlay: false,
                styles: {
                  options: {
                    zIndex: 2000,
                  },
                },
                placement: 'left',
                target: '.iq4-tour-suggest',
                disableBeacon: true,
              },
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      Clicking on this will let you explore the role in detail to decide if you want
                      to add it as a possible career pathway. You can add up to six possible career
                      pathways.
                    </p>
                  </div>
                ),
                disableOverlay: false,
                styles: {
                  options: {
                    zIndex: 2000,
                  },
                },
                target: '.suggested-role',
                disableBeacon: true,
              },
              {
                content: (
                  <div style={{ padding: '1rem' }}>
                    <p style={{ fontSize: '0.875rem' }}>
                      If you want to add the role you're exploring, simply click here. It will
                      update with more information as one of your pathways. The blue and black
                      colors around a job role show your matched or missing skills.
                    </p>
                  </div>
                ),
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.iq4-pathway__suggestion-label-container--actions',
              },
            ]}
            showProgress={false}
            continuous={true}
            run={true}
            disableOverlayClose={true}
            scrollToFirstStep={true}
            disableScrolling={true}
            styles={{
              buttonBack: {
                backgroundColor: 'transparent',
                color: '#0070F0',
              },
              buttonNext: {
                backgroundColor: '#0070F0',
                color: 'white',
              },
            }}
            locale={{
              last: 'Next',
            }}
          />
        )}
        {!activeTShapeView && (
          <>
            {' '}
            <Container
              className={`iq4-career-pathways__container ${
                isConsentDisplayed ? `iq4-career-pathways__container--with-consent` : ''
              } ${isPublicProfile ? 'iq4-career-pathways__container--public-profile' : ''} ${
                (showPathwayDetailTour || showSecondTour) && isMobile ? 'iq4-hide' : ''
              }`}
            >
              {!!showJobFamilyExplanation && (
                <JobFamilyExplanation hideJobFamilyExplanation={hideJobFamilyExplanation} />
              )}
              {!!showJobRoleExplanation && (
                <JobRoleExplanation hideJobRoleExplanation={hideJobRoleExplanation} />
              )}
              <Grid container alignItems="flex-start">
                {!isPublicProfile && (
                  <Grid
                    item
                    classes={{
                      root: `iq4-career-pathways__selects-container`,
                    }}
                  >
                    <h1 className="iq4-career-pathways__title">Career Pathways</h1>

                    <RolesSearchBar
                      results={roleResults}
                      onChange={(searchTerm) => {
                        setSearchTerm(searchTerm);
                      }}
                      onSkillClick={(role) => {
                        handleViewRolesDetails(role);
                      }}
                    />

                    {displayBrowseRoles && (
                      <BrowseRolesInput
                        roles={jobFamilies}
                        selectedJobFamily={selectedJobFamily}
                        onSelect={handleSelectJobFamily}
                      />
                    )}
                    <SuggestedRolesSelect
                      ref={suggestedRolesSelectRef}
                      suggestedRoles={recommendedWorkRoles}
                      onRoleClick={(role) => {
                        handleViewRolesDetails(role);
                      }}
                    />
                  </Grid>
                )}
                <Grid item classes={{ root: 'iq4-career-pathways__main' }} ref={mainGraphRef}>
                  {!isMobile && selectedPathwaysSpokes && personal && recommendedWorkRolesToUse && (
                    <div className="iq4-career-pathways__graph">
                      <ReactFlowProvider>
                        <PathwaysGraphV2
                          spokes={selectedPathwaysSpokes}
                          suggestions={recommendedWorkRolesToUse}
                          isLegendVisible={isLegendVisible}
                          onSelectPath={(role, index, node) => {
                            handleViewRolesDetails(role, 'graph', index);
                            setElementToFocus(node);
                          }}
                          onAddPath={(role) => handleAddRoleToPathway(role)}
                          onRemovePath={(role) => handlePathwaysGraphRemove(role)}
                        />
                      </ReactFlowProvider>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Container>
            <Drawer
              variant="persistent"
              isOpen={isDrawerOpen}
              classes={{
                root: 'iq4-career-pathways__drawer',
                paper: 'iq4-career-pathways__drawer-paper',
              }}
            >
              <div
                className={`iq4-career-pathways__drawer-content ${
                  isPublicProfile ? 'iq4-career-pathways__drawer-content-public' : ''
                }`}
              >
                {!selectedWorkRoleDetails && selectedJobFamily && (
                  <SelectWorkRoles
                    jobFamily={selectedJobFamily}
                    onSelectWorkRole={handleSelectWorkRole}
                    onClose={closeSelectWorkRoles}
                  />
                )}
                {!selectedWorkRoleDetails && selectedPathwaysSpokes && hasRoleId && (
                  <div className="iq4-career-pathways__drawer__pathway-detail">
                    <div className="iq4-pathway-detail">
                      <div className="iq4-pathway-detail__header">
                        <button className="iq4-pathway-detail__header-title iq4-pathway-detail__header-title--small-font">
                          <SimpleIcon name="arrow" /> Back to Career Pathways
                        </button>

                        <h1 className="iq4-pathway-detail__header-name">
                          <Skeleton
                            variant="rect"
                            style={{ width: '220px', height: '20px', marginBottom: '4px' }}
                          />
                        </h1>
                        {!isPublicProfile && (
                          <Button
                            variation="ghost"
                            className="iq4-pathway-detail__header__add-to-pathways"
                          >
                            <SimpleIcon
                              className="iq4-pathway-detail__header__add-to-pathways__icon"
                              name="add"
                              role="presentation"
                            />
                            Loading...
                          </Button>
                        )}
                      </div>
                      <div
                        className="iq4-pathway-detail__selection-container"
                        style={{ marginTop: '30px' }}
                      >
                        {!isPublicProfile && (
                          <>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                JOB FAMILY
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                <Skeleton
                                  variant="rect"
                                  style={{ width: '200px', height: '10px' }}
                                />
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-3">
                                Change
                              </div>
                            </div>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                ROLE
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                <Skeleton
                                  variant="rect"
                                  style={{ width: '140px', height: '10px' }}
                                />
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-3">
                                Change
                              </div>
                            </div>
                            <div className="iq4-pathway-detail__selection-container--row">
                              <div className="iq4-pathway-detail__selection-container--row-item-1">
                                LOCATION
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-2">
                                <Skeleton
                                  variant="rect"
                                  style={{ width: '220px', height: '10px' }}
                                />
                              </div>
                              <div className="iq4-pathway-detail__selection-container--row-item-3">
                                Change
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {selectedWorkRoleDetails && (
                  <div
                    ref={scrollableNodeRef}
                    className="iq4-career-pathways__drawer__pathway-detail"
                  >
                    <PathwayDetail
                      onPanelStateChange={(newPanelState) => {
                        setCurrentPanelState(newPanelState);
                      }}
                      workRoles={searchRoles}
                      selectedWorkroles={selectedPathwaysSpokes}
                      onTShapeClick={() => setActiveTShapeView(true)}
                      onWorkRoleChange={(e) => {
                        const roleId = e.target.label;
                        handleViewRolesDetails({ id: roleId });
                      }}
                      onBackClick={handleClose}
                      category={selectedCategory}
                      specialtyArea={selectedSpecialtyArea}
                      role={{
                        ...selectedWorkRoleDetails,
                        salary: true,
                        education: true,
                        tShape: tShapeGraphicData,
                      }}
                      onCancel={handleCancelWorkRole}
                      onViewRole={(selected) => handleViewRolesDetails(selected)}
                      filterByInstitution={personal?.school}
                      showTour={showTour}
                    />
                  </div>
                )}
              </div>
            </Drawer>
          </>
        )}
      </main>
    </>
  );
};
