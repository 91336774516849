import React from 'react';
import { connect } from 'react-redux';
import { Notifier } from '@airbrake/browser';

/**
 * Generic Error Boundary component (needs to be a class component for now - for the `componentDidCatch` event)
 */
export class InnerErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const { projectId, projectKey } = this.props;

    if (window.location.hostname !== 'localhost') {
      this.airbrake = new Notifier({
        projectId,
        projectKey,
      });
    }
  }

  componentDidCatch(error, info) {
    const { user } = this.props;
    const userToSend = user && user.personal ? user.personal : undefined;

    this.airbrake &&
      this.airbrake.notify({
        error: error,
        params: { info: info, user: userToSend },
      });
  }

  render() {
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  user: state.session.user,
});

export const ErrorBoundary = connect(mapStateToProps)(InnerErrorBoundary);
