import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  LCN_GET_COUNTS_URL,
  LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL,
  LCN_GET_CONNECTION_DETAIL_BY_ID_URL,
  LCN_CREATE_NEW_CONNECTION_REQUEST_URL,
  LCN_APPROVE_REJECT_CONNECTION_REQUEST_URL,
  LCN_UPDATE_PERMISSIONS_URL,
  LCN_GET_CREDENTIAL_BY_ID_URL,
  LCN_GET_CREDENTIALS_BY_STATUS_URL,
  LCN_UPDATE_CREDENTIAL_STATUS_URL,
  LCN_GET_VERIFIER_ORGANIZATIONS_URL,
  NSC_STUDENT_PROFILE_URL,
  LCN_GET_VERIFY_CREDENTIALS_URL,
  LCN_WALLET_PROFILE,
  LCN_UPDATE_CREDENTIAL_DISPUTE_URL,
  LCN_CONNECT_TO_PROVIDER,
  LCN_CREDENTIAL_SUMMARY,
} from 'common/constants/endpoints';

import { LCN_RECORD_STATES, LCN_RECORD_STATUSES } from 'common/constants/lcnRecords';

export const refreshBadges = createAsyncThunk(
  'records/refreshBadges',
  async (undefined, { rejectWithValue }) => {
    const providerCallback = process.env.REACT_APP_MY_RECORDS_PROVIDER_CALLBACK_URL;

    return fetch(`${LCN_CONNECT_TO_PROVIDER}?callbackUrl=${providerCallback}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const connectToProvider = createAsyncThunk(
  'records/connectToProvider',
  async (providerId, { rejectWithValue }) => {
    const providerCallback = process.env.REACT_APP_MY_RECORDS_PROVIDER_CALLBACK_URL;

    return fetch(
      `${LCN_CONNECT_TO_PROVIDER}?callbackUrl=${providerCallback}&provider=${providerId}`,
      {
        method: 'GET',
      },
    ).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchCountsReq = createAsyncThunk(
  'records/fetchCountsReq',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'countLoading', status: true }));
    return fetch(LCN_GET_COUNTS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'countLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchAcceptedCredentialsReq = createAsyncThunk(
  'records/fetchAcceptedCredentialsReq',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'credentialsAcceptedLoading', status: true }));
    return new Promise((resolve) => {
      setTimeout(() => {
        // UNCOMMENT IF: we want to test a successful message

        dispatch(setUI({ type: 'credentialsAcceptedLoading', status: false }));
        resolve({
          credentials: [
            {
              id: 'BADGR_pXMhfpyxTCCX2vdPq5EGhg',
              name: 'WGU Bachelor of Science, Health Information Management',
              description:
                'Bachelor of Science, Health Information Management credential from Western Governors University.',
              issueDate: '2021-08-24',
              expirationDate: null,
              credentialURL: 'https://api.test.badgr.com/public/assertions/pXMhfpyxTCCX2vdPq5EGhg',
              achievementURL: 'https://api.test.badgr.com/public/badges/UDZgNkMmRs6SZN7OKerPbA',
              revoked: false,
              imageUrl: 'https://api.test.badgr.com/public/badges/UDZgNkMmRs6SZN7OKerPbA/image',
              alignmentList: [
                {
                  name: 'Baseline Results Evaluation',
                  framework: 'OSMT',
                },
                {
                  name: 'Medical Billing Record Evaluation',
                  framework: 'OSMT',
                },
                {
                  name: 'Team Monitoring',
                  framework: 'OSMT',
                },
                {
                  name: 'Ethical Issue Description',
                  framework: 'OSMT',
                },
                {
                  name: 'Learning Experience Refinement',
                  framework: 'OSMT',
                },
                {
                  name: 'Analyze a Business for Strategies for Navigating Ethical Issues',
                  framework: 'OSMT',
                },
                {
                  name: 'Contextual Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Mitigation Approaches',
                  framework: 'OSMT',
                },
                {
                  name: 'Policy and Procedure Assessment',
                  framework: 'OSMT',
                },
                {
                  name: 'Workflow Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Care Delivery Model Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Change Management Strategy Creation',
                  framework: 'OSMT',
                },
                {
                  name: 'Change Management Technique Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Change Management Technique Recognition',
                  framework: 'OSMT',
                },
                {
                  name: 'Determine Effective Change Management Techniques',
                  framework: 'OSMT',
                },
                {
                  name: 'Goal Adjustment',
                  framework: 'OSMT',
                },
                {
                  name: 'Clinical Data Interpretation',
                  framework: 'OSMT',
                },
                {
                  name: 'Medical Condition Diagnosis',
                  framework: 'OSMT',
                },
                {
                  name: 'Regulatory Entity Compliance',
                  framework: 'OSMT',
                },
                {
                  name: 'Address Common Goals in Education',
                  framework: 'OSMT',
                },
                {
                  name: 'Technology Adaptation',
                  framework: 'OSMT',
                },
                {
                  name: 'Trust and Rapport Creation',
                  framework: 'OSMT',
                },
                {
                  name: 'Benchmarking Research',
                  framework: 'OSMT',
                },
                {
                  name: 'Quality Assurance Standard Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Acknowledgement',
                  framework: 'OSMT',
                },
                {
                  name: 'Learning Environment Fostering',
                  framework: 'OSMT',
                },
                {
                  name: 'Evaluate Curriculum',
                  framework: 'OSMT',
                },
                {
                  name: 'Resource and Service Trends Data Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Data Integrity Evaluation',
                  framework: 'OSMT',
                },
                {
                  name: 'Data Quality Optimization',
                  framework: 'OSMT',
                },
                {
                  name: 'Data Organization Definition',
                  framework: 'OSMT',
                },
                {
                  name: 'Alternative Solution Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Senior Manager and Decision Maker Collaboration',
                  framework: 'OSMT',
                },
                {
                  name: 'Strategic Path Decisions with Senior Managers and Decision Makers',
                  framework: 'OSMT',
                },
                {
                  name: 'Medical Terminology Descriptions',
                  framework: 'OSMT',
                },
                {
                  name: 'Test Result Interpretation',
                  framework: 'OSMT',
                },
                {
                  name: 'Hemodialysis and Peritoneal Differentiation',
                  framework: 'OSMT',
                },
                {
                  name: 'Disaster Recovery Standards of Practice Design',
                  framework: 'OSMT',
                },
                {
                  name: 'Communication Pathway Creation',
                  framework: 'OSMT',
                },
                {
                  name: 'Compliant Electronic Medical Records (EMRs) Maintenance',
                  framework: 'OSMT',
                },
                {
                  name: 'Risk Mitigation Strategy Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Ethical Dilemmas',
                  framework: 'OSMT',
                },
                {
                  name: 'Evidence and Research Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Awareness of Harmful Practices',
                  framework: 'OSMT',
                },
                {
                  name: 'Distinguish Ethical Behavior',
                  framework: 'OSMT',
                },
                {
                  name: 'Ethical Decision Making',
                  framework: 'OSMT',
                },
                {
                  name: 'Decision Valuation',
                  framework: 'OSMT',
                },
                {
                  name: 'Responsible Practice',
                  framework: 'OSMT',
                },
                {
                  name: 'Unethical Behavior',
                  framework: 'OSMT',
                },
                {
                  name: 'Financial Statements Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Forecast, Report, and Analysis Preparation',
                  framework: 'OSMT',
                },
                {
                  name: 'Cost Reduction and Increased Revenue Opportunities Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Financial Statement Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Risk Assessment Conducting',
                  framework: 'OSMT',
                },
                {
                  name: 'Healthcare Data Processes Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Patient Assessments Writing',
                  framework: 'OSMT',
                },
                {
                  name: 'Care Outcomes Technologies Evaluation',
                  framework: 'OSMT',
                },
                {
                  name: 'Care Outcomes Technologies List',
                  framework: 'OSMT',
                },
                {
                  name: 'Evaluate Communication Technology',
                  framework: 'OSMT',
                },
                {
                  name: 'Information Management Tools Selection',
                  framework: 'OSMT',
                },
                {
                  name: 'List Communication Technologies',
                  framework: 'OSMT',
                },
                {
                  name: 'Data and Information Security Regulations Explanation',
                  framework: 'OSMT',
                },
                {
                  name: 'Human Resource Planning Role Evaluation',
                  framework: 'OSMT',
                },
                {
                  name: 'Information Security Strategy Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Data Compromise Indicators Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Information Systems Operations and Data Processing Coordination',
                  framework: 'OSMT',
                },
                {
                  name: 'Secure Data Transmission Methods Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Resources Instruction Provision',
                  framework: 'OSMT',
                },
                {
                  name: 'Fact Patterns Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Improvement Change Management',
                  framework: 'OSMT',
                },
                {
                  name: 'International Business Issues Recognition',
                  framework: 'OSMT',
                },
                {
                  name: 'Ethical Leadership Activities Conducting',
                  framework: 'OSMT',
                },
                {
                  name: 'Expectations and Duties Agreement',
                  framework: 'OSMT',
                },
                {
                  name: 'Team Lead Collaboration',
                  framework: 'OSMT',
                },
                {
                  name: 'Group Development Strategy Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Individual Learning Style Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Analyze Legal Constraints',
                  framework: 'OSMT',
                },
                {
                  name: 'Legislation Compliance Ensuring',
                  framework: 'OSMT',
                },
                {
                  name: 'Process Strategic Plan Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Classroom Leadership Skills Implementation',
                  framework: 'OSMT',
                },
                {
                  name: 'Cross-Cultural Psychology',
                  framework: 'OSMT',
                },
                {
                  name: 'Human Potential',
                  framework: 'OSMT',
                },
                {
                  name: 'Operations Performance Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Supervision Strategies Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Medicare and Medicaid Compliance Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Medicare and Medicaid Reimbursement Compliance Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Medicare/Medicaid Compliance Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Medicare/Medicaid Compliance Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Billing and Procedural Codes Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Billing Documentation Submission',
                  framework: 'OSMT',
                },
                {
                  name: 'Claim Denial Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Coding Classifications Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Coding Classifications Differentiation',
                  framework: 'OSMT',
                },
                {
                  name: 'Coding Classifications Education',
                  framework: 'OSMT',
                },
                {
                  name: 'Coding Classifications Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Coding Technology Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Describe Non-Financial Benefits of Coding',
                  framework: 'OSMT',
                },
                {
                  name: 'Diagnosis and Procedure Codes Assignment',
                  framework: 'OSMT',
                },
                {
                  name: 'Electronic Medical Records (EMRs) Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Electronic Medical Records (EMRs) Preparation',
                  framework: 'OSMT',
                },
                {
                  name: 'Ensure Accurate Healthcare Billing',
                  framework: 'OSMT',
                },
                {
                  name: 'Healthcare Services Coding Explanation',
                  framework: 'OSMT',
                },
                {
                  name: 'Medical Code Organization',
                  framework: 'OSMT',
                },
                {
                  name: 'Perform Procedural Coding',
                  framework: 'OSMT',
                },
                {
                  name: 'Provide Medical Diagnoses and Procedure Codes',
                  framework: 'OSMT',
                },
                {
                  name: 'Service Code Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Ethical Impact',
                  framework: 'OSMT',
                },
                {
                  name: 'Clinical Interactions Documentation Maintenance',
                  framework: 'OSMT',
                },
                {
                  name: 'Provide Access to Health Information',
                  framework: 'OSMT',
                },
                {
                  name: 'Record of Patient Care Composition',
                  framework: 'OSMT',
                },
                {
                  name: 'Assign Current Procedural Terminology',
                  framework: 'OSMT',
                },
                {
                  name: 'Assign International Current Procedural Terminology Codes',
                  framework: 'OSMT',
                },
                {
                  name: 'Interpretation for International Classification of Disease Codes',
                  framework: 'OSMT',
                },
                {
                  name: 'Term Differentiation',
                  framework: 'OSMT',
                },
                {
                  name: 'Integration Opportunities',
                  framework: 'OSMT',
                },
                {
                  name: 'Organized or Systematic Framework Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Resources Use Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Fiscal Operations Efficiency Appraisal',
                  framework: 'OSMT',
                },
                {
                  name: 'School District Policies Articulation',
                  framework: 'OSMT',
                },
                {
                  name: 'Values',
                  framework: 'OSMT',
                },
                {
                  name: 'Organizational Policy Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Capitalize on Strengths',
                  framework: 'OSMT',
                },
                {
                  name: 'Organizational Structure Implementation',
                  framework: 'OSMT',
                },
                {
                  name: 'Behavior Modification Recommendation',
                  framework: 'OSMT',
                },
                {
                  name: 'Performance Appraisal Review',
                  framework: 'OSMT',
                },
                {
                  name: 'Company Performance Reports Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Compliance Changes Communication',
                  framework: 'OSMT',
                },
                {
                  name: 'Immune Response Physiology Description',
                  framework: 'OSMT',
                },
                {
                  name: 'Financial Portfolio Performance Summaries Creation',
                  framework: 'OSMT',
                },
                {
                  name: 'Define Solution Goals',
                  framework: 'OSMT',
                },
                {
                  name: 'Professional Development Opportunities Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Appropriate Professional Traits Demonstration',
                  framework: 'OSMT',
                },
                {
                  name: 'Attitude Alignment',
                  framework: 'OSMT',
                },
                {
                  name: 'Ensure On-Time Product Delivery',
                  framework: 'OSMT',
                },
                {
                  name: 'Project Management Approaches Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Project Planning Duties Assignment',
                  framework: 'OSMT',
                },
                {
                  name: 'Applicable Quality Standards Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Quality Management Standards Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Quality Standards Communication Documentation Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Quality Standards Compliance Process Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Compliance Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'List Healthcare Compliance Resources and Agencies',
                  framework: 'OSMT',
                },
                {
                  name: 'Compliance Practices Institution',
                  framework: 'OSMT',
                },
                {
                  name: 'Regulatory Requirements Education',
                  framework: 'OSMT',
                },
                {
                  name: 'Primary and Secondary Research Conduction',
                  framework: 'OSMT',
                },
                {
                  name: 'Financial Resources Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Resources Gathering',
                  framework: 'OSMT',
                },
                {
                  name: 'Risk Analysis Plan Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Risk Areas Assessment',
                  framework: 'OSMT',
                },
                {
                  name: 'Risk Assessment Practices Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Business Risks Identification',
                  framework: 'OSMT',
                },
                {
                  name: 'Risk Management Policies Advisement',
                  framework: 'OSMT',
                },
                {
                  name: 'Recruitment Strategy Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Medical Instrument Sterilization',
                  framework: 'OSMT',
                },
                {
                  name: 'Business Challenges Analysis',
                  framework: 'OSMT',
                },
                {
                  name: 'Business Process Modeling Facilitation',
                  framework: 'OSMT',
                },
                {
                  name: 'Deployed Solutions Maintenance Plan Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Tax Compliance Detection Program Development',
                  framework: 'OSMT',
                },
                {
                  name: 'Collaboration Principles Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Develop Timelines and Milestones',
                  framework: 'OSMT',
                },
                {
                  name: 'Group Leadership Principles',
                  framework: 'OSMT',
                },
                {
                  name: 'Group Leadership Principles Creation',
                  framework: 'OSMT',
                },
                {
                  name: 'Systems Implementation Team Member Participation',
                  framework: 'OSMT',
                },
                {
                  name: 'Teamwork Principles Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Insurance Regulations Compliance Strategies Application',
                  framework: 'OSMT',
                },
                {
                  name: 'Third-Party Payers and Government Payers Differentiation',
                  framework: 'OSMT',
                },
                {
                  name: 'Total Quality Management Control Processes Recommendations',
                  framework: 'OSMT',
                },
                {
                  name: 'Patient Treatment Plan Creation',
                  framework: 'OSMT',
                },
                {
                  name: 'Identify Basic Anatomical Terms',
                  framework: 'OSMT',
                },
                {
                  name: 'Explain Anatomical Relationships',
                  framework: 'OSMT',
                },
                {
                  name: 'Identify Physiology',
                  framework: 'OSMT',
                },
                {
                  name: 'Identify Anatomical Structures',
                  framework: 'OSMT',
                },
                {
                  name: 'Health Information/Medical Records Administration/Administrator',
                  framework: 'NCES-The Classification of Instructional Programs (CIP)',
                },
                {
                  name: 'Medical and Health Services Managers',
                  framework: 'O*NET-Standard Occupational Classification (SOC)',
                },
                {
                  name: 'Medical Records Specialists',
                  framework: 'O*NET-Standard Occupational Classification (SOC)',
                },
              ],
              issuerURI: 'https://api.test.badgr.com/public/issuers/WsDauccISgmGBqgXVMHBcw',
              issuerName: 'Western Governors University',
              status: 'VC_ACTIVE',
              dispute: false,
              permission: true,
              type: 'Degree',
              signaling: true,
              base64schoollogo: null,
              credentialProvider: 'BADGR',
              majors: null,
              minors: null,
              majorOptions: null,
              majorConcentrations: null,
              credentialType: 'Degree',
              awardDate: '2021-08-24',
            },
            {
              id: 'did:key:123',
              name: 'Our Wallet Passed JFF Plugfest #1 2022',
              description: 'This wallet can display this Open Badge 3.0',
              issueDate: '2022-05-01',
              expirationDate: null,
              credentialURL: 'https://api.test.badgr.com/public/assertions/npNbvplUSgi1XDTMUjODsA',
              achievementURL: 'https://api.test.badgr.com/public/badges/pgDMtKVNTuqLXSJ3OYrcdw',
              revoked: false,
              imageUrl:
                'https://kayaelle.github.io/vc-ed/plugfest-1-2022/images/JFF_LogoLockup.png',
              alignmentList: [],
              issuerURI: 'https://www.jff.org/',
              issuerName: 'Jobs for the Future (JFF)',
              criteria:
                'The first cohort of the JFF Plugfest 1 in May/June of 2021 collaborated to push interoperability of VCs in education forward.',
              status: 'VC_ACTIVE',
              dispute: false,
              permission: false,
              type: 'Badge',
              signaling: false,
              base64schoollogo: null,
              credentialProvider: 'BADGR',
              majors: null,
              minors: null,
              majorOptions: null,
              majorConcentrations: null,
              credentialType: 'Badge',
              awardDate: '2021-06-14',
            },
          ],
        });
      }, 1000);
    });
    /*    return fetch(`${LCN_GET_CREDENTIALS_BY_STATUS_URL}/${LCN_RECORD_STATES.ACTIVE}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'credentialsAcceptedLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });*/
  },
);

export const fetchNewCredentialsReq = createAsyncThunk(
  'records/fetchNewCredentialsReq',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'credentialsNewLoading', status: true }));

    return fetch(`${LCN_GET_CREDENTIALS_BY_STATUS_URL}/${LCN_RECORD_STATES.NEW}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'credentialsNewLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchCredentialsDetailsByIdReq = createAsyncThunk(
  'records/fetchCredentialsDetailsByIdReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${LCN_GET_CREDENTIAL_BY_ID_URL}/${id}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchVerifierOrganizationsReq = createAsyncThunk(
  'records/fetchVerifierOrganizationsReq',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'verifierOrganizationsLoading', status: true }));
    return fetch(LCN_GET_VERIFIER_ORGANIZATIONS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'verifierOrganizationsLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchConnectionRequestsReq = createAsyncThunk(
  'records/fetchConnectionRequestsReq',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'connectionsRequestsLoading', status: true }));
    return fetch(`${LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL}/${LCN_RECORD_STATES.NEW}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'connectionsRequestsLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchConnectionDetailsByIdReq = createAsyncThunk(
  'records/fetchConnectionDetailsByIdReq',
  async (id, { rejectWithValue }) => {
    return fetch(`${LCN_GET_CONNECTION_DETAIL_BY_ID_URL}/${id}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchSharedConnectionsReq = createAsyncThunk(
  'records/fetchSharedConnectionsReq',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'connectionsSharedLoading', status: true }));
    return fetch(`${LCN_GET_CONNECTION_REQUEST_BY_STATUS_URL}/${LCN_RECORD_STATES.HISTORY}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'connectionsSharedLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const approveRejectConnectionReq = createAsyncThunk(
  'records/approveRejectConnectionReq',
  async (payload, { dispatch, rejectWithValue }) => {
    return fetch(LCN_APPROVE_REJECT_CONNECTION_REQUEST_URL, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchConnectionRequestsReq());
        dispatch(fetchSharedConnectionsReq());
        dispatch(fetchCountsReq());
        return payload;
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const updatePermissionsReq = createAsyncThunk(
  'records/updatePermissionsReq',
  async (payload, { rejectWithValue }) => {
    return fetch(LCN_UPDATE_PERMISSIONS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        return payload;
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const updateCredentialStatusReq = createAsyncThunk(
  'records/updateCredentialStatusReq',
  async (data, { dispatch, rejectWithValue }) => {
    const payload = data.map((item) => ({
      id: item.id,
      status: LCN_RECORD_STATES.ACTIVE,
    }));

    return fetch(LCN_UPDATE_CREDENTIAL_STATUS_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCountsReq());
        dispatch(triggerCredentialSummary());
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const updateCredentialDisputeReq = createAsyncThunk(
  'records/updateCredentialDisputeReq',
  async ({ credentialId, dispute, disputeReason }, { dispatch, rejectWithValue }) => {
    const payload = {
      dispute,
      ...(disputeReason && { disputeReason }),
    };

    return fetch(`${LCN_UPDATE_CREDENTIAL_DISPUTE_URL}/${credentialId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCountsReq());
        !dispute && dispatch(triggerCredentialSummary());
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const createNewConnectionReq = createAsyncThunk(
  'records/createNewConnectionReq',
  async (payload, { dispatch, rejectWithValue }) => {
    return fetch(LCN_CREATE_NEW_CONNECTION_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchSharedConnectionsReq());
        return payload; // TODO: Confirm when when API final (Des - IAW)
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const triggerCredentialSummary = createAsyncThunk(
  'records/triggerCredentialSummary',
  async (undefined, { rejectWithValue, dispatch }) => {
    // eslint-disable-line no-shadow-restricted-names
    const fromDate = moment().subtract(100, 'year').utc().format();
    return fetch(`${LCN_CREDENTIAL_SUMMARY}/${fromDate}`, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setCredentialSummaryAPICalled(true));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchPersonalInformation = createAsyncThunk(
  'records/fetchPersonalInformation',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'personalInformationLoading', status: true }));
    return fetch(NSC_STUDENT_PROFILE_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'personalInformationLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchVerifyCredentialsURL = createAsyncThunk(
  'records/fetchVerifyCredentialsURL',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'verifyCredentialsURLLoading', status: true }));
    return fetch(LCN_GET_VERIFY_CREDENTIALS_URL, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'verifyCredentialsURLLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const fetchWalletProfile = createAsyncThunk(
  'records/fetchWalletProfile',
  async (undefined, { dispatch, rejectWithValue }) => {
    // eslint-disable-line no-shadow-restricted-names
    dispatch(setUI({ type: 'consentLoading', status: true }));
    return new Promise((resolve) => {
      setTimeout(() => {
        // UNCOMMENT IF: we want to test a successful message

        dispatch(setUI({ type: 'consentLoading', status: false }));
        resolve({
          id: 'student_profile_id',
          badgrAccount: false,
          consentForPlanning: false,
          consentForSignaling: false,
          consentTextForSignaling:
            'Provide consent for sharing your data (mock signaling text). Provide consent for sharing your data (mock signaling text). Provide consent for sharing your data (mock signaling text)',
          consentTextForPlanning:
            'Provide consent for sharing your data (mock planning text). Provide consent for sharing your data (mock planning text). Provide consent for sharing your data (mock planning text).',
          skipBadgrConnection: true,
        });
      }, 1000);
    });
    /*    return fetch(LCN_WALLET_PROFILE, {
      method: 'GET',
    }).then((response) => {
      if (response.ok) {
        dispatch(setUI({ type: 'consentLoading', status: false }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });*/
  },
);

export const updateWalletProfileConsentReq = createAsyncThunk(
  'records/updateWalletProfileConsentReq',
  async (data, { dispatch, rejectWithValue }) => {
    return fetch(LCN_WALLET_PROFILE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        dispatch(fetchCountsReq());
        data?.consentForPlanning && dispatch(triggerCredentialSummary());
        data?.consentForSignaling &&
          dispatch(setUI({ type: 'displayConsentForSignalingAcceptedMessage', status: true }));
        return response.json();
      } else {
        console.log('ERROR FROM LCN API:', response);
        return rejectWithValue(response);
      }
    });
  },
);

export const recordsSlice = createSlice({
  name: 'records',
  initialState: {
    counts: {
      connectionsCountNew: 0,
      credentailsCountNew: 0,
    },
    credentials: [],
    connections: [],
    verifierOrganizations: [],
    personalInformation: {},
    verifyCredentialsURL: null,
    errors: [],
    profile: null,
    providerRedirectUrl: null,
    ui: {
      consentLoading: true,
      countLoading: true,
      credentialsNewLoading: true,
      credentialsAcceptedLoading: true,
      connectionsRequestsLoading: true,
      connectionsSharedLoading: true,
      personalInformationLoading: true,
      verifyCredentialsURLLoading: true,
      verifierOrganizationsLoading: true,
      displayConsentForSignalingAcceptedMessage: false,
    },
    credentialSummaryAPICalled: true,
  },
  reducers: {
    setUI: (state, action) => {
      state.ui[action.payload.type] = action.payload.status;
    },
    resetErrors: (state, action) => {
      state.errors = [];
    },
    setCredentialSummaryAPICalled: (state, action) => {
      state.credentialSummaryAPICalled = action.payload;
    },
  },
  extraReducers: {
    [connectToProvider.fulfilled]: (state, action) => {
      if (action.payload.responseType === 'require_authorization') {
        state.providerRedirectUrl = action.payload.redirectUrl;
      }
    },

    [fetchCountsReq.fulfilled]: (state, action) => {
      state.counts = action.payload;
    },

    [fetchAcceptedCredentialsReq.fulfilled]: (state, action) => {
      action.payload.credentials.forEach((credential) => {
        const index = state.credentials.findIndex((item) => item.id === credential.id);
        if (index > -1) {
          state.credentials[index] = credential;
        } else {
          state.credentials.push(credential);
        }
      });
    },

    [fetchNewCredentialsReq.fulfilled]: (state, action) => {
      action.payload.credentials.forEach((credential) => {
        const index = state.credentials.findIndex((item) => item.id === credential.id);
        if (index > -1) {
          state.credentials[index] = credential;
        } else {
          state.credentials.push(credential);
        }
      });
    },

    [fetchCredentialsDetailsByIdReq.fulfilled]: (state, action) => {
      const index = state.credentials.findIndex(
        (credential) => credential.id === action.payload.id,
      );
      const credential = state.credentials[index];
      const credentialType =
        credential.type === 'microcredential' ? 'microCredential' : credential.type; // TODO: Remove this when fixed on API

      state.credentials[index] = {
        ...credential,
        [credential.type]: action.payload[credentialType],
      };
    },

    [fetchVerifierOrganizationsReq.fulfilled]: (state, action) => {
      state.verifierOrganizations = action.payload;
    },

    [fetchConnectionRequestsReq.fulfilled]: (state, action) => {
      action.payload.listOfconnections &&
        action.payload.listOfconnections.forEach((connection) => {
          const index = state.connections.findIndex(
            (item) => item.connectionId === connection.connectionId,
          );
          if (index > -1) {
            state.connections[index] = {
              ...connection,
              status: LCN_RECORD_STATES.NEW,
            };
          } else {
            state.connections.push({
              ...connection,
              status: LCN_RECORD_STATES.NEW,
            });
          }
        });
    },

    [fetchSharedConnectionsReq.fulfilled]: (state, action) => {
      action.payload.listOfconnections &&
        action.payload.listOfconnections.forEach((connection) => {
          const index = state.connections.findIndex(
            (item) => item.connectionId === connection.connectionId,
          );
          if (index > -1) {
            state.connections[index] = connection;
          } else {
            state.connections.push(connection);
          }
        });
    },

    [createNewConnectionReq.fulfilled]: (state, action) => {
      state.connections.push(action.payload);
    },

    [fetchConnectionDetailsByIdReq.fulfilled]: (state, action) => {
      const index = state.connections.findIndex(
        (connection) => connection.connectionId === action.payload.connectionId,
      );
      state.connections[index] = action.payload;
    },

    [updatePermissionsReq.fulfilled]: (state, action) => {
      action.payload.forEach((credential) => {
        const { credentialId, permissioned } = credential;
        const index = state.credentials.findIndex((item) => item.id === credentialId);
        const original = state.credentials[index];

        state.credentials[index] = {
          ...original,
          permission: permissioned,
        };
      });
    },

    [updateCredentialStatusReq.fulfilled]: (state, action) => {
      action.payload.forEach((credential) => {
        const { status, id } = credential;
        const index = state.credentials.findIndex((item) => item.id === id);
        const original = state.credentials[index];

        state.credentials[index] = {
          ...original,
          status: status,
        };
      });
    },

    [fetchPersonalInformation.fulfilled]: (state, action) => {
      state.personalInformation = action.payload;
    },

    [fetchVerifyCredentialsURL.fulfilled]: (state, action) => {
      state.verifyCredentialsURL = action.payload.verifyCredentialsURL;
    },
    [fetchWalletProfile.fulfilled]: (state, action) => {
      state.profile = action.payload;
    },

    [updateWalletProfileConsentReq.fulfilled]: (state, action) => {
      state.profile = action.payload;
    },

    [updateCredentialDisputeReq.fulfilled]: (state, action) => {
      const index = state.credentials.findIndex((item) => item.id === action.payload.id);
      state.credentials[index] = {
        ...action.payload,
        status: !action.payload.dispute ? 'VC_ACTIVE' : action.payload.status,
      };
    },
  },
});

// action creators
// eslint-disable-next-line no-empty-pattern

export const { setUI, resetErrors, setCredentialSummaryAPICalled } = recordsSlice.actions;

export default recordsSlice.reducer;
