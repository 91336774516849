import { useState } from 'react';
import localStorage from 'common/utils/localStorage';

/**
 * React Hook to get / set values from local storage
 * @param key
 * @param initialValue
 * @returns {unknown[]}
 */
export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.get(key);
      return item || initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      localStorage.set(key, value);
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue];
}
