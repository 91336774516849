import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OnboardingHeader, OnboardingFooter, OnboardingList } from 'common/components/Onboarding';
import { Modal, TriggerModal, CredentialPill } from 'common/components';
import { useProfileEducationFormDefinition } from 'common/hooks/forms';
import { FormWrapper } from '../../Form';
import { DEFAULT_COUNTRY } from 'common/constants/countries';
import { PROFILE_EDUCATION_URL } from 'common/constants/endpoints';
import { selectProfile } from 'common/store/features/profile/selectors';
import { selectResumeParserEducation } from 'common/store/features/parsedResume/selectors';
import { removeEducation } from 'common/store/features/parsedResume/parsedResumeSlice';
import { PROFILE_TYPE, sendFormDataRequest } from 'common/utils';
import {
  setProfileSection,
  removeProfileSection,
} from 'common/store/features/profile/profileSlice';
import { sendRequest } from 'common/utils';
import { orderEducationRecords } from 'common/utils/orderRecords';
import './OnboardingEducation.scss';
import { selectDisplayTour } from 'common/store/features/ui/selectors';
import Joyride from 'react-joyride';
import useSetTourOverlayHeight from 'common/hooks/useSetTourOverlayHeight';
import IQ4Tour from '../../IQ4Tour/IQ4Tour';
import { setDisplayTour } from '../../../store/features/ui/uiSlice';

const showTutorial = process.env.REACT_APP_SHOW_TUTORIAL === 'true';

export const OnboardingEducation = ({
  exposeFormContext,
  onComplete,
  onPrevious,
  disableTourOnLoad = false,
}) => {
  const dispatch = useDispatch();
  useSetTourOverlayHeight();
  const [triggerItem, setTriggerItem] = useState(null);

  const { education: profileEducation } = useSelector(selectProfile);
  const resumeParserEducation = useSelector(selectResumeParserEducation);

  const defaultValues = {
    country: DEFAULT_COUNTRY,
    isCurrentStudent: true,
  };

  useEffect(() => {
    if (disableTourOnLoad) {
      dispatch(setDisplayTour(false));
    }
  }, [disableTourOnLoad]);

  const [values, setValues] = useState(defaultValues);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  let steps = [
    {
      target: '.iq4-onboarding__add-another',
      content: (
        <div>
          <h2 style={{ fontSize: '1rem' }}>Add Another</h2>
          <p style={{ fontSize: '0.875rem' }}>
            Add additional education experience if it's not on your uploaded resume.
          </p>
        </div>
      ),
      placement: 'right',
      disableBeacon: true,
      hideCloseButton: true,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
    },
  ];

  if (profileEducation?.length > 0) {
    steps = [
      {
        target: '.iq4-onboarding-list',
        content: (
          <div>
            <h2 style={{ fontSize: '1rem' }}>Education Text:</h2>
            <p style={{ fontSize: '0.875rem' }}>
              We captured all your educational experiences here and you can edit or delete as
              needed.
            </p>
          </div>
        ),
        placement: 'right',
        disableBeacon: true,
        hideCloseButton: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
      },
      ...steps,
    ];
  }

  if (resumeParserEducation.length > 0) {
    steps = [
      {
        target: '.iq4-new-resume-items',
        content: (
          <div>
            <h2 style={{ fontSize: '1rem' }}>New Resume Items</h2>
            <p style={{ fontSize: '0.875rem' }}>
              These are the new education items found in your resume. You can edit or delete them if
              you need to.
            </p>
          </div>
        ),
        placement: 'right',
        disableBeacon: true,
        hideCloseButton: true,
        styles: {
          options: {
            zIndex: 10000,
          },
        },
      },
      ...steps,
    ];
  }

  const onEdit = (data, triggerItem) => {
    setIsEdit(true);
    setValues(data);
    setTriggerItem(triggerItem);
  };

  const handleDelete = (item, setDeleteItem) => {
    if (item?.type === 'resume') {
      dispatch(removeEducation({ reference: item.reference }));
      setDeleteItem(null);
      return;
    }

    sendRequest({ url: `${PROFILE_EDUCATION_URL}/${item?.id}`, verb: 'DELETE' })
      .then(() => {
        dispatch(removeProfileSection({ section: PROFILE_TYPE.EDUCATION, value: item?.id }));
        setDeleteItem(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleNext = () => {
    setIsSubmittingForm(true);

    if (resumeParserEducation.length === 0) {
      onComplete();
    }

    return resumeParserEducation.forEach((values) => {
      return sendFormDataRequest({
        url: PROFILE_EDUCATION_URL,
        verb: 'POST',
        data: values,
      }).then((resp) => {
        dispatch(setProfileSection({ section: PROFILE_TYPE.EDUCATION, value: resp }));
        dispatch(removeEducation({ reference: values.reference }));
        onComplete();
      });
    });
  };

  const onFormComplete = (values) => {
    // if from resume, remove from parsedResumeSlice
    if (values?.type === 'resume' && values?.reference) {
      dispatch(removeEducation({ reference: values.reference }));
    }

    setIsEdit(false);
    setValues(defaultValues);
  };

  const renderListItemFields = (item, renderDates) => (
    <div className="iq4-onboarding-list__item__fields">
      <span className="iq4-onboarding-list__item__pill">
        <CredentialPill credential={item} displayVerifier={true} />
      </span>
      <span className="iq4-onboarding-list__item__field--strong">{item.title}</span>
      <span className="iq4-onboarding-list__item__field">{item.school}</span>
      <span className="iq4-onboarding-list__item__field">{renderDates(item)}</span>
    </div>
  );

  const renderAddRecord = () => {
    const hasRecords = resumeParserEducation?.length > 0 || profileEducation?.length > 0;

    return (
      <div className="iq4-onboarding__add-another">
        <TriggerModal
          title={<OnboardingHeader icon="educationCircle" title="Education" />}
          form={
            <FormWrapper
              useFormDefinition={useProfileEducationFormDefinition}
              values={defaultValues}
            />
          }
          label={hasRecords ? 'I want to add another' : 'Add an educational record'}
          ariaLabel="Add more educational records"
        />
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="iq4-onboarding-education">
          <OnboardingHeader icon="educationCircle" title="Education" isMainHeading={true} />
          {showTutorial && <IQ4Tour steps={steps} />}

          {resumeParserEducation?.length > 0 && (
            <div className="iq4-onboarding__card iq4-new-resume-items">
              <p>The following records were found in your resume. Please confirm.</p>

              <OnboardingList
                records={orderEducationRecords(resumeParserEducation)}
                onEdit={onEdit}
                onDelete={handleDelete}
                renderFields={renderListItemFields}
              />
            </div>
          )}

          {profileEducation?.length > 0 && (
            <div className="iq4-onboarding__card">
              <p>The following records are saved in your profile.</p>

              <OnboardingList
                records={orderEducationRecords(profileEducation)}
                onEdit={onEdit}
                onDelete={handleDelete}
                renderFields={renderListItemFields}
                listType="Education"
              />
            </div>
          )}

          {!profileEducation.length && !resumeParserEducation.length && (
            <div className="iq4-onboarding__card">
              <p className="iq4-onboarding__card__empty">No records found</p>
            </div>
          )}

          <div className="iq4-onboarding__card">{renderAddRecord()}</div>
        </div>
        <Modal
          isOpen={isEdit}
          handleClose={() => setIsEdit(false)}
          onDeactivation={() => {
            if (triggerItem) {
              setTimeout(() => {
                const button = document.querySelector(triggerItem);
                if (button) {
                  button.focus();
                  setTriggerItem(null);
                }
              }, 100);
            }
          }}
          title={<OnboardingHeader icon="educationCircle" title="Education" />}
        >
          <FormWrapper
            values={values}
            onComplete={() => onFormComplete(values)}
            useFormDefinition={useProfileEducationFormDefinition}
            layoutConfig={{ submitButtons: { position: 'center' } }}
            showButtons={!values.verifiedBy}
            showCloseButton={values.verifiedBy}
          />
        </Modal>
      </div>

      <OnboardingFooter
        direction="Happy? Great let's move on to your Experience"
        isSubmittingForm={isSubmittingForm}
        handleNext={() => handleNext()}
        handlePrev={() => onPrevious()}
        nextButtonLabel="Save and Continue"
      />
    </>
  );
};
