import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import ReactFlow, {
  Background,
  Controls,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  useReactFlow,
  applyNodeChanges,
} from 'reactflow';
import { AvatarNode, RoleNode } from './Nodes';
import 'reactflow/dist/style.css';
import { FloatingEdge } from './Nodes';
import { PathwayGraphLegend } from '../PathwaysGraph/PathwaysGraphLegend';
import { useGraphResizeToFitView } from 'common/hooks/useGraphResizeToFitView';
import { IntermediateRoleNode } from './Nodes/IntermediateRoleNode';
import {
  selectHasFetchedRecommendedWorkRoles,
  selectHasFetchedSelectedWorkRoles,
} from 'common/store/features/pathways/selectors';
import CircularProgress from '@material-ui/core/CircularProgress';
import './PathwaysGraphV2.scss';
import { NODE_POSITIONS } from '../../utils';
import { useIsMobile } from '../../hooks/useIsMobile';

const defaultEdgeOptions = {
  style: { strokeWidth: 1, stroke: 'black' },
  type: 'floating',
};

const edgeTypes = {
  floating: FloatingEdge,
};

const initialNodes = [{ id: 'center', type: 'avatarNode', position: { x: 0, y: 0 } }];

const initialEdges = [];
const enableIntermediateSteps = process.env.REACT_APP_ENABLE_INTERMEDIATE_STEPS === 'true';

/**
 *
 * @param spokes - the spokes to display
 * @param suggestions - the suggestions to display
 * @param onSelectPath - callback when a path is selected
 * @param onAddPath - callback when a path is added
 * @param onRemovePath - callback when a path is removed
 * @param isLegendVisible - whether to show the legend
 * @returns {JSX.Element|null}
 * @constructor
 */
export const PathwaysGraphV2 = ({
  spokes,
  suggestions,
  onSelectPath,
  onAddPath,
  onRemovePath,
  isLegendVisible,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const graphControlsRef = useRef(null);
  const [hasRun, setHasRun] = useState(false);
  const [liveRegionText, setLiveRegionText] = useState('');
  const [nodeLengthLastCalculated, setNodeLengthLastCalculated] = useState(0);

  const hasFetchedSelectedWorkRoles = useSelector(selectHasFetchedSelectedWorkRoles);
  const hasFetchedRecommendedRoles = useSelector(selectHasFetchedRecommendedWorkRoles);
  const isMobile = useIsMobile();

  const reactFlowInstance = useReactFlow();
  useGraphResizeToFitView(nodes);

  const nodeTypes = useMemo(
    () => ({
      avatarNode: AvatarNode,
      roleNode: RoleNode,
      intermediateRole: IntermediateRoleNode,
    }),
    [],
  );

  useEffect(() => {
    graphControlsRef.current = document.createElement('div');
    document.body.appendChild(graphControlsRef.current);

    // Cleanup function to remove the appended div when the component unmounts
    return () => {
      if (graphControlsRef.current) {
        document.body.removeChild(graphControlsRef.current);
      }
    };
  }, []);

  function removeTabIndexes() {
    setTimeout(() => {
      const floatingEdges = document.querySelectorAll('.react-flow__edge-floating');
      const roleNodes = document.querySelectorAll('.react-flow__node-roleNode');
      const intemediateRoles = document.querySelectorAll('.react-flow__node-intermediateRole');

      if (floatingEdges?.length) {
        floatingEdges.forEach((edge) => {
          edge.setAttribute('tabindex', '-1');
          edge.removeAttribute('aria-label');
          edge.removeAttribute('aria-describedby');
        });
      }

      if (roleNodes?.length) {
        roleNodes.forEach((node) => {
          node.setAttribute('tabindex', '-1');
          node.removeAttribute('aria-label');
          node.removeAttribute('aria-describedby');
        });
      }

      if (intemediateRoles?.length) {
        intemediateRoles.forEach((node) => {
          node.setAttribute('tabindex', '-1');
          node.setAttribute('role', 'presentation');
          node.removeAttribute('aria-label');
          node.removeAttribute('aria-describedby');
        });
      }
    }, 0);
  }

  let nodeRadius = 100;

  const onElementClick = useCallback(
    (event, element) => {
      if (element.type === 'roleNode') {
        // Check if the clicked node already has children
        const hasChildren = edges.some((edge) => edge.source === element.id);
        if (hasChildren) {
          return;
        }

        let angle = 0;
        let radius = 0;
        let positionFound = false;
        let newNodePositions = [
          { x: 0, y: 0 },
          { x: 0, y: 0 },
        ];

        for (let i = 0; i < newNodePositions.length; i++) {
          positionFound = false;
          while (!positionFound) {
            // Calculate a position in a spiral pattern around the clicked node.
            newNodePositions[i] = {
              x: element.position.x + radius * Math.cos(angle),
              y: element.position.y + radius * Math.sin(angle),
            };

            // Check if the new position overlaps with any existing nodes.
            positionFound = !nodes.some((node) => {
              const dx = node.position.x - newNodePositions[i].x;
              const dy = node.position.y - newNodePositions[i].y;
              const distance = Math.sqrt(dx * dx + dy * dy);
              return distance < nodeRadius * 2;
            });

            // Increase the angle and radius for the next iteration.
            angle += Math.PI / 5; // 30 degrees
            if (angle >= Math.PI * 2) {
              angle -= Math.PI * 2;
              radius += nodeRadius;
            }
          }
        }

        const newNodes = newNodePositions.map((position, index) => ({
          id: `newNode-${element.id}-${index}`,
          type: 'roleNode',
          position,
          data: element.data,
        }));

        setNodes((prevNodes) => [...prevNodes, ...newNodes]);

        // Create new edges
        const newEdges = newNodes.map((newNode) => ({
          id: `edge-${element.id}-${newNode.id}`,
          source: element.id,
          target: newNode.id,
        }));

        setEdges((prevEdges) => [...prevEdges, ...newEdges]);

        setTimeout(() => {
          reactFlowInstance.fitView();
        }, 0);
      }
    },
    [nodes, edges],
  );

  const innerOnNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
      // Log the changes for debugging purposes
      changes.forEach((change) => {
        if (change.type === 'position') {
          // Node was dragged, log its new position
          console.log(`Node ${change.id} new position:`, change.position);
        }
      });
    },
    [setNodes],
  );

  function getNodePosition(section, nodeIndex) {
    return NODE_POSITIONS[section][nodeIndex];
  }

  useEffect(() => {
    if (
      (hasRun && nodeLengthLastCalculated === spokes.length) ||
      !hasFetchedSelectedWorkRoles ||
      !hasFetchedRecommendedRoles
    ) {
      return;
    }

    if (!!spokes?.length || suggestions?.length) {
      const coordinates = [
        { x: 193, y: -105 },
        { x: 198, y: 130.53 },
        { x: 21.24, y: 245.37 },
        { x: -170.09, y: 133.11 },
        { x: -173, y: -99.33 },
        { x: 21.86, y: -220.64 },
      ];
      const selectedRolesNodes = [];

      spokes.forEach((spoke, spokeIndex) => {
        let usedIndex = 0;
        const hasIntermediateSteps = spoke?.paths?.length > 0;

        if (hasIntermediateSteps && enableIntermediateSteps) {
          const flattenedPaths = spoke.paths?.flat();
          const sortedPaths = flattenedPaths?.sort((a, b) => (a.type > b.type ? 1 : -1));

          (sortedPaths.slice(0, 2) || []).forEach((path, index) => {
            selectedRolesNodes.push({
              id: `intermediate-${spokeIndex}-${index + 1}`,
              type: 'intermediateRole',
              position: {
                x: getNodePosition(spokeIndex, usedIndex)?.x,
                y: getNodePosition(spokeIndex, usedIndex)?.y,
              },
              source: index === 0 ? 'center' : String(`intermediate-${spokeIndex}-${usedIndex}`),
              data: {
                spoke: path,
                positionLabel: spokeIndex < 3 ? 'right' : 'left',
                onSelectPath: () => {},
                onRemovePath: () => {},
                nodeType: 'intermediate',
              },
            });

            usedIndex++;
          });
        }

        selectedRolesNodes.push({
          id: String(spokeIndex + 1),
          type: 'roleNode',
          position: {
            x: getNodePosition(spokeIndex, usedIndex)?.x,
            y: getNodePosition(spokeIndex, usedIndex)?.y,
          },
          source:
            hasIntermediateSteps && enableIntermediateSteps
              ? `intermediate-${spokeIndex}-${Math.min(spoke.paths.length, 2)}`
              : 'center',
          data: {
            spoke,
            positionLabel: spokeIndex < 3 ? 'right' : 'left',
            onSelectPath: (_, node) => {
              onSelectPath(spokes[spokeIndex], spokeIndex, node);
            },
            onRemovePath: () => onRemovePath(spokes[spokeIndex]),
            nodeType: 'selected',
          },
        });
      });

      const suggestionsToAdd = 6 - spokes.length;
      if (suggestionsToAdd > 0) {
        const suggestionsNodes = suggestions.slice(0, suggestionsToAdd).map((suggestion, index) => {
          const graphIndex = index + spokes.length;
          return {
            id: String(graphIndex + 1),
            type: 'roleNode',
            position: {
              x: coordinates[graphIndex].x,
              y: coordinates[graphIndex].y,
            },
            source: 'center',
            data: {
              spoke: suggestion,
              positionLabel: graphIndex < 3 ? 'right' : 'left',
              onSelectPath: (_, node) => {
                onSelectPath(suggestion, index, node);
              },
              nodeType: 'suggested',
              onAddPath: () => onAddPath(suggestion),
            },
          };
        });
        selectedRolesNodes.push(...suggestionsNodes);
      }

      const isNodeSuggested = (nodeId) => {
        const node = selectedRolesNodes.find((node) => node.id === nodeId);
        return node?.data?.nodeType === 'suggested';
      };

      const newEdges = selectedRolesNodes.map((node, index) => {
        return {
          id: `e-${node.id}-${index + 1}`,
          source: node.source,
          target: node.id,
          animated: false,
        };
      });

      setNodes((nodes) => [...initialNodes, ...selectedRolesNodes]);
      setEdges((edges) => [...newEdges]);
      setHasRun(true);
      setNodeLengthLastCalculated(spokes.length);

      const selectedRoleNames = spokes.map((spoke) => spoke.label).join(', ');
      const suggestedRoleNames = suggestions.map((suggestion) => suggestion.label).join(', ');
      setLiveRegionText(`Selected roles: ${selectedRoleNames}.`);
    }
  }, [spokes, suggestions]);

  const onConnect = useCallback((params) => setEdges((els) => addEdge(params, els)), []);

  if (!nodes?.length || !edges?.length) {
    return (
      <div className="iq4-pathway__outer-container">
        <CircularProgress classes={{ root: 'iq4__pathways_loading' }} size={100} />
      </div>
    );
  }

  return (
    <div className="iq4-pathway__outer-container">
      {isLegendVisible && <PathwayGraphLegend />}
      <div aria-live="polite" aria-atomic="true" className="sr-only">
        {liveRegionText}
      </div>

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={innerOnNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultEdgeOptions={defaultEdgeOptions}
        /*onNodeClick={onElementClick}*/
        fitView={true}
        maxZoom={1}
        onInit={removeTabIndexes}
      >
        <Background variant={BackgroundVariant.Dots} />
        {graphControlsRef.current &&
          ReactDOM.createPortal(
            <>
              <Controls
                showInteractive={false}
                position={isMobile ? 'bottom-left' : 'bottom-right'}
              />
            </>,
            graphControlsRef.current,
          )}
      </ReactFlow>
    </div>
  );
};
