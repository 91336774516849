import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';
import { Link } from 'react-router-dom';
import { selectFeatures } from 'store/features/config/selectors';
import { selectProfile } from 'common/store/features/profile/selectors';
import { ReactComponent as BadgrLogo } from '@assets/svg/logo-provider-badgr.svg';
import { ReactComponent as WGULogo } from '@assets/svg/logo-provider-wgu.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, SimpleIcon, WithResourceForm } from 'common/components';
import {
  ProfilePersonal,
  ProfileSkillsSummary,
  ProfileEducationSummary,
  ProfileLCNEducationSummary,
} from 'components/Profile';
import { ProfileExperienceDetail } from 'components/Profile/ProfileExperienceDetail';
import { ProfilePortfolioDetail } from 'components/Profile/ProfilePortfolioDetail';

import { ROUTES } from 'routes/routeNames';

import './MobileProfileExpand.scss';

const EDU_PROVIDERS = [
  {
    id: 'SOCIAL',
    logo: <BadgrLogo />,
  },
  {
    id: 'WGU',
    logo: <WGULogo />,
  },
];

/**
 * Internally used by the MobileTabs component to also give the functionality to add a resource (education, experience, portfolio)
 * @param {String} type - The type of resource to render - "education", "experience", "portfolio"
 */
const DefaultTab = ({ type, lcnEnabled = false }) => {
  const tabs = {
    experience: <ProfileExperienceDetail />,
    education: lcnEnabled ? <ProfileLCNEducationSummary /> : <ProfileEducationSummary />,
    portfolio: <ProfilePortfolioDetail />,
  };

  return (
    <WithResourceForm type={type}>
      {({ openModal, resourceData }) => (
        <>
          {tabs[type]}

          {!!resourceData.length && (
            <Button className="iq4-mobile-profile-expand__create-btn" onClick={openModal}>
              Add {type}
            </Button>
          )}
        </>
      )}
    </WithResourceForm>
  );
};

/**
 * Mobile tabs component
 * @param {Object[]} [tabs] - Tabs array similar to the default tabs array above
 */
export const MobileProfileExpand = ({ className, ...props }) => {
  const { lcnEnabled, profilePortfolioLabel } = useSelector(selectFeatures);
  const profileState = useSelector(selectProfile);
  const isPortfolioPopulated = profileState.portfolio.length > 0;

  const orderedExperience = _orderBy(
    profileState.experience,
    function (experience) {
      return moment(`${experience.startYear}-${experience.startMonth}-01`);
    },
    ['desc'],
  );

  const renderAccordion = () => {
    return DEFAULT_TABS.map((tab) => {
      return (
        <Accordion classes={{ root: 'iq4-mobile-profile-expand__accordion' }}>
          <AccordionSummary
            classes={{ root: 'iq4-mobile-profile-expand__accordion-summary' }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="iq4-mobile-profile-expand__accordion-icon-container">
              <SimpleIcon
                className="iq4-mobile-profile-expand__accordion-icon"
                name={tab.icon}
                role="presentation"
              />
            </div>
            {tab.title}
          </AccordionSummary>
          <AccordionDetails classes={{ root: 'iq4-mobile-profile-expand__accordion-details' }}>
            {tab.component}
            {((tab.type === 'portfolio' && isPortfolioPopulated) ||
              (tab.type === 'experience' && !!orderedExperience.length) ||
              (tab.type === 'education' && profileState.education?.length) ||
              tab.type === 'skills') && (
              <div className="iq4-action-card__see-more-wrapper">
                <Link className="iq4-action-card__see-more" to={tab.to}>
                  See More <SimpleIcon name="arrow" />
                </Link>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const renderExperienceList = (list) => {
    return list.map((exp) => {
      return (
        <div className="iq4-mobile-profile-expand__exp-card" key={exp.id}>
          <p className="iq4-mobile-profile-expand__exp-card-p iq4-mobile-profile-expand__exp-card-p--bold">
            {exp.title}
          </p>
          <p className="iq4-mobile-profile-expand__exp-card-p iq4-mobile-profile-expand__exp-card-p--bold">
            {exp.company}
          </p>
          <p className="iq4-mobile-profile-expand__exp-card-p iq4-mobile-profile-expand__exp-card-p">
            {exp.companyIndustry}
          </p>
          <p className="iq4-mobile-profile-expand__exp-card-p iq4-mobile-profile-expand__exp-card-p">
            {exp.startYear}
          </p>
        </div>
      );
    });
  };

  // used as default by the MobileTabs component when user doesn't specify the tabs prop
  const DEFAULT_TABS = [
    {
      type: 'education',
      to: ROUTES.PROFILE_EDUCATION,
      icon: 'education',
      title: 'Education',
      component: <DefaultTab type="education" lcnEnabled={lcnEnabled} />,
    },
    {
      type: 'experience',
      to: ROUTES.PROFILE_EXPERIENCE,
      icon: 'experience',
      title: 'Experience',
      component: orderedExperience.length ? (
        renderExperienceList(orderedExperience)
      ) : (
        <DefaultTab type="experience" />
      ),
    },
    {
      type: 'skills',
      to: ROUTES.MANAGE_SKILLS,
      icon: 'skills',
      title: 'Skills',
      component: <ProfileSkillsSummary />,
    },
    {
      type: 'portfolio',
      to: ROUTES.PROFILE_PORTFOLIO,
      icon: 'portfolio',
      title: profilePortfolioLabel,
      component: <DefaultTab type="portfolio" />,
    },
  ];

  return (
    <section className={`iq4-mobile-profile-expand ${className ? className : ''}`} {...props}>
      <ProfilePersonal />
      <div>{renderAccordion()}</div>
    </section>
  );
};
