import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Topbar } from 'common/components';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { selectHasAcceptedTerms } from 'common/store/features/profile/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import './PrivacyPolicyPage.scss';
import { getSpecificPrivacyPolicyContent } from './content/getSpecificPrivacyPolicyContent';
import { defaultContent } from './content/default';

export const PrivacyPolicyPage = () => {
  const { applicationTitle, showSpecificTermsPages } = useSelector(selectFeatures);
  const hasAcceptedTerms = useSelector(selectHasAcceptedTerms);

  return (
    <>
      <Helmet title={`${applicationTitle} - Terms & Conditions`} />
      <Topbar Logo={Logo} logoOnly={true} />
      <div className="iq4-terms-page">
        <div className="iq4-max-width-container">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h1 className="iq4-terms-page__title">Privacy Policy</h1>
            {hasAcceptedTerms && (
              <div className="iq4-terms-modal__accepted iq4-terms-page--accepted">ACCEPTED</div>
            )}
          </div>
          {showSpecificTermsPages
            ? getSpecificPrivacyPolicyContent(showSpecificTermsPages)
            : defaultContent()}
        </div>
      </div>
    </>
  );
};
