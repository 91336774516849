import React from 'react';
import PropTypes from 'prop-types';
import { CompetencyPill } from 'common/components';
import './CompetenciesList.scss';

/**
 * A simple component that renders and styles a list competencies
 * @param competencies - array of competency objects
 */

const CompetenciesList = ({ competencies, isVerified = false, ...listProps }) => (
  <ul className="iq4__competencies-list__list" {...listProps}>
    {competencies &&
      competencies.map((competency) => (
        <li className="iq4__competencies-list__competency" key={competency.id}>
          <CompetencyPill competency={{ ...competency, verified: isVerified }} />
        </li>
      ))}
  </ul>
);

CompetenciesList.propTypes = {
  competencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ).isRequired,
};

CompetenciesList.defaultProps = {};

export default CompetenciesList;
