import React from 'react';

export function getCredentialTourSteps() {
  return [
    {
      content: (
        <div style={{ padding: '1rem' }}>
          <p style={{ fontSize: '0.875rem' }}>
            Need help understanding this section of the platform? This brief tutorial will show you
            everything you need to know!
          </p>
        </div>
      ),
      disableOverlay: false,
      placement: 'center',
      target: 'body',
    },
    {
      disableOverlay: false,
      target: '.iq4-credentials__manual-add',
      placement: 'left',
      disableBeacon: true,
      spotlightClicks: true,
      content: (
        <div>
          <p style={{ fontSize: '0.875rem', padding: '1rem' }}>
            Click here to add a credential to your Wallet. There are several different ways this can
            be done!
          </p>
        </div>
      ),
    },
  ];
}
