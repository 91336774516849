import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LinkIcon } from 'common/components/LinkIcon';
import { SimpleIcon } from 'common/components/SimpleIcon';
import './ActionCard.scss';

/**
 * Default Card that accepts components for manipulating contents of the card that fills container
 * @param title - Card Title in Card Heading
 * @param icon - The name of the icon for use in the Card Heading
 * @param ActionIcon - The options/interaction element of the card
 * @param seeMore - Path for See More footer link
 * @param backLink - Path for back link
 * @param isDivideVisible - show/hide the horizontal rule in the header
 * @param customFooter - allows for an additional footer
 * @param children - card body
 * @constructer
 */

export const ActionCard = (props) => {
  const {
    className,
    title,
    icon,
    ActionIcon,
    seeMore,
    backLink,
    isDivideVisible,
    isHeaderVisible = true,
    customFooter,
    children,
    isEmpty,
    seeMoreAriaLabel,
  } = props;
  return (
    <div
      className={`iq4-action-card ${isEmpty ? 'iq4-action-card--empty' : ''} ${
        className ? className : ''
      }`}
    >
      {isHeaderVisible && (
        <div className="iq4-action-card__heading">
          <div className="iq4-action-card__heading-link">
            {backLink && <LinkIcon to={backLink} iconName="arrow" label="Back" />}
            <SimpleIcon
              className="iq4-action-card__heading-icon"
              name={icon}
              role="presentation"
              hidden
            />
            <h2 className="iq4-action-card__heading-title">{title}</h2>
          </div>
          {ActionIcon}
        </div>
      )}
      {isDivideVisible && <hr className="iq4-action-card__divide" />}
      <div className="iq4-action-card__body">
        {children}
        {seeMore && (
          <div className="iq4-action-card__see-more-wrapper">
            <Link
              className="iq4-action-card__see-more"
              to={seeMore}
              aria-label={`See more ${title}. This will move you away from the current page.`}
            >
              See More <SimpleIcon name="arrow" />
            </Link>
          </div>
        )}
        {customFooter && (
          <div className="iq4-action-card__custom-footer-wrapper">{customFooter}</div>
        )}
      </div>
    </div>
  );
};

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  actionIcon: PropTypes.node,
  customFooter: PropTypes.node,
  seeMore: PropTypes.string,
  backLink: PropTypes.string,
  isDivideVisible: PropTypes.bool,
  children: PropTypes.node,
  isEmpty: PropTypes.bool,
};

ActionCard.defaultProps = {
  actionIcon: null,
  customFooter: null,
  seeMore: '',
  backLink: '',
  isDivideVisible: true,
  children: null,
  isEmpty: false,
};
