import React from 'react';
import FocusLock from 'react-focus-lock';
import { Drawer, Pill, SimpleIcon } from 'common/components';
import { CATEGORY_LABELS } from '../../utils';

export const SkillsExplanation = ({ hideSkillsExplanation }) => (
  <Drawer
    anchor="right"
    variant="persistent"
    isOpen={true}
    classes={{
      root: 'iq4-tshape-page__drawer',
      paper: 'iq4-explanation__drawer-paper',
    }}
  >
    <FocusLock returnFocus={true}>
      <div style={{ paddingTop: '20px' }}>
        <button
          aria-label="Close"
          className="iq4-tshape-page__drawer__dismiss-button"
          onClick={hideSkillsExplanation}
        >
          <SimpleIcon name="close" />
        </button>
        <div className="iq4-tshape-page__drawer__role-container iq4-explanation__scroll">
          <h1 className="iq4-explanation--title">About adding a skill</h1>
          <div className="iq4-explanation--divider" />
          <div className="iq4-tshape-page__drawer__role-container--role">ABOUT</div>
          <div className="iq4-explanation--content" style={{ borderBottom: 'none' }}>
            Skills can encompass a wide range of competencies, including IT, soft, or professional
            abilities that are relevant to a person’s professional or personal development. These
            skills may be acquired through education, training, or practical experience and should
            be showcased or added to the platform to help highlight their qualifications, seek
            relevant opportunities, and get recommended roles from our skills engine.
          </div>
          <div className="iq4-tshape-page__drawer__role-container--role">ADD A SKILL</div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            Here is how you can add a skill:
            <br />
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Access the Search Bar:</strong> At the top of the Skills page you will see a
              search bar on the skills platform.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Enter the Skill name:</strong> In the search bar, type the name of the skill
              you want to add to your profile. For example, if you want to add “JavaScript” as a
              skill, type “Jav” and a number of options will appear.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Select the Skill:</strong> From the dropdown search results, identify the
              specific skill you want to add to your profile.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div>
              <strong>Add the Skill: </strong> Once you’ve found the skill you want to add, there is
              usually an option to add it to your profile. This option is typically represented as a
              button or link next to the skill’s name. Click on this option.
            </div>
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            <div style={{ color: '#2B71E8' }}>
              <strong>
                Tip
                <br />
              </strong>
              Your newly added skill is confirmed and you can edit and specify your skill
              proficiency and other details.
              <br />
              <br />
            </div>
          </div>
          <div className="iq4-tshape-page__drawer__role-container--role">
            ADD A RECOMMENDED SKILL
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            You can also add a recommended skill from the left hand column of cards. The skills
            engine will populate this on an ongoing basis.
            <br />
            <br />
          </div>
          <div className="iq4-tshape-page__drawer__role-container--role">
            WHAT ARE SKILL CATEGORIES?
          </div>
          <div className="iq4-explanation--content iq4-explanation--no-border">
            Skills are divided into three categories:
            <br />
          </div>
          <p style={{ fontSize: '0.875rem' }}>
            <b>{CATEGORY_LABELS['professional_skills']}</b> are unique abilities and knowledge
            needed for a particular occupation, that includes a distinct range of skills that are
            crucial for success on the job.
            <br />
            <div className="iq4__flex-row">
              Example: ARCHITECTURE
              <Pill
                label="PROFESSIONAL SKILLS"
                type="professional skills"
                className="iq4__margin-left"
              />
            </div>
          </p>
          <p style={{ fontSize: '0.875rem' }}>
            <b>{CATEGORY_LABELS['it_skills']}</b> represent the ability to effectively use a variety
            of software and tools on the job.
            <br />
            <div className="iq4__flex-row">
              Example: SQL Databases
              <Pill label="IT SKILLS" type="it skills" className="iq4__margin-left" />
            </div>
          </p>
          <p style={{ fontSize: '0.875rem' }}>
            <b>{CATEGORY_LABELS['soft_skills']}</b> are the personal qualities and behaviors crucial
            for professional success. These skills can often be transferred and applied across
            various job roles and industries.
            <br />
            <div className="iq4__flex-row">
              Example: LEADERSHIP
              <Pill label="SOFT SKILLS" type="soft skills" className="iq4__margin-left" />
            </div>
          </p>
        </div>
      </div>
    </FocusLock>
  </Drawer>
);
