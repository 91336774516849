import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'store/features/config/selectors';
import './SkillsList.scss';

/**
 * Render Skill list component
 * @param {function} children - render callback
 * @param {Object[]} skills - Skills to render in the list
 */
export const SkillsList = ({ children, skills = [], ...props }) => {
  const { displayUpperTiers } = useSelector(selectFeatures);
  return (
    <ul {...props} className={`iq4-skill-list ${props.className ? props.className : ''}`}>
      {skills.map((s) => {
        return (
          <li
            key={s.id}
            className={`iq4-skill-list__item ${s.selected ? 'iq4-skill-list--selected' : null}`}
          >
            <div className="iq4-skill-list__item-label-container">
              <p className="iq4-skill-list__item-label">{s.value}</p>
              {displayUpperTiers && (
                <p className="iq4-skill-list__item-label iq4-skill-list__item__tiers">
                  {s.tier1} > {s.tier2}
                </p>
              )}
            </div>
            <div className="iq4-skill-list__item-children">{children(s)}</div>
          </li>
        );
      })}
    </ul>
  );
};

SkillsList.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.object).isRequired,
};
