import React, { useState } from 'react';
import moment from 'moment';
import { STUDENT_INVITE_STATUS, STUDENT_INVITE_STATUS_LABEL } from 'common/utils';
import { MultilineDisplayer, SimpleIcon } from 'common/components';

export function MobileRow({ item, onAccept, onDecline, onRevoke }) {
  const [isExpanded, setIsExpanded] = useState(false);

  async function triggerRevoke() {
    await onRevoke(item);
    setIsExpanded(!isExpanded);
  }

  return (
    <div className={`${isExpanded ? 'iq4-connection-row-expanded' : ''} iq4-connection-row`}>
      <div className="iq4-connection-row--container">
        <div className="iq4-connection-row--avatar-container iq4-connection-row--avatar-container-mobile iq4-connections-page--pre-expand--flex-3-mobile">
          <div className="iq4-connection-row--name-mobile">{item.counsellorFullName}</div>
        </div>
        <div className="iq4-connections-page--pre-expand--flex-1">
          <div
            className={
              item.status === 'new'
                ? 'iq4-connection-row--status-new-mobile'
                : 'iq4-connection-row--status-mobile'
            }
          >
            {STUDENT_INVITE_STATUS_LABEL[STUDENT_INVITE_STATUS[item.status]]}
          </div>
          <SimpleIcon
            onClick={() => setIsExpanded(!isExpanded)}
            name={isExpanded ? 'caretUp' : 'caretDown'}
          />
        </div>
      </div>
      {isExpanded && (
        <div className="iq4-connection-row--message-container">
          <div>{moment(new Date(item.dateCreated)).format('MM/DD/YYYY')}</div>
          <div className="iq4-connection-row--message-container--title">Invitation Message:</div>
          <div className="iq4-connection-row--message-container--content">
            <MultilineDisplayer text={item.msg} />
          </div>
          {item.status === 1 && (
            <>
              <div className="iq4-connection-row--message-container--title">Action</div>
              <div className="iq4-connection-row--actions">
                <div
                  className="iq4-connection-row--actions--accept"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => onAccept(item)}
                  onClick={() => onAccept(item)}
                >
                  Share
                </div>
                <div
                  className="iq4-connection-row--actions--decline"
                  tabIndex={0}
                  role="button"
                  onKeyPress={() => onDecline(item)}
                  onClick={() => onDecline(item)}
                >
                  Decline
                </div>
              </div>
            </>
          )}
          {item.status === 2 && (
            <div
              className="iq4-connection-row--actions--revoke iq4-connection-row--actions--revoke-mobile"
              tabIndex={0}
              role="button"
              onKeyPress={triggerRevoke}
              onClick={triggerRevoke}
            >
              Revoke
            </div>
          )}
        </div>
      )}
    </div>
  );
}
