import React from 'react';
import './RecordImageAndLabel.scss';

export const RecordImageAndLabel = ({ imageUrl, label }) => {
  return (
    <div className="iq4-record-image-and-label">
      {imageUrl && <img className="iq4-record-image-and-label__image" src={imageUrl} alt="Logo" />}
      <span className="iq4-record-image-and-label__label">{label}</span>
    </div>
  );
};
