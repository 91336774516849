import React, { useState, useEffect, useRef } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from 'routes';
import { LinkIcon } from 'common/components/LinkIcon';
import { SimpleIcon, Topbar } from 'common/components';
import {
  ResumeInfoPanel,
  OnboardingResume,
  OnboardingResumeComplete,
} from 'common/components/Onboarding';
import { OnboardingAssessmentPage } from '../OnboardingAssessmentPage/OnboardingAssessmentPage';
import { setResumeUploaded } from 'common/store/features/onboarding/onboardingSlice';
import { selectOnboarding } from 'common/store/features/onboarding/selectors';
import { selectResumeParsedSkills } from 'common/store/features/skills/selectors';
import { Container } from 'common/components';
import { selectUserProfile } from 'common/store/features/profile/selectors';
import { selectFeatures } from 'store/features/config/selectors';
import { fetchProfileReq } from 'common/store/features/profile/profileSlice';
import { useIsMobile } from 'common/hooks/useIsMobile';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import './OnboardingResumePath.scss';

export const OnboardingResumePath = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const isMobile = useIsMobile();
  const state = useSelector(selectOnboarding);
  const resumeParsedSkills = useSelector(selectResumeParsedSkills);
  const [steps, setSteps] = useState([]);
  const dispatch = useDispatch();
  const linkIconRef = useRef(null);

  const { personalProfileSource } = useSelector(selectFeatures);

  useEffect(() => {
    personalProfileSource && dispatch(fetchProfileReq(personalProfileSource));
  }, [dispatch, personalProfileSource]);

  const userProfile = useSelector(selectUserProfile);

  const contentRef = useRef();
  // if user gets to assessment page without having any skills redirect them to resume upload
  useEffect(() => {
    const selectedSkills = resumeParsedSkills.length;

    if (path === ROUTES.ONBOARDING_ASSESSMENT && selectedSkills < state.MINIMUM_SKILL_COUNT) {
      history.push(ROUTES.ONBOARDING_RESUME);
    }
  }, [path, state.MINIMUM_SKILL_COUNT, resumeParsedSkills, history]);

  // keep track of route configs
  useEffect(() => {
    setSteps([
      {
        sideComponent: <ResumeInfoPanel userProfile={userProfile} />,
        isActive: path === ROUTES.ONBOARDING_RESUME,
        prev: null,
      },
      {
        sideComponent: isMobile ? null : <ResumeInfoPanel userProfile={userProfile} />,
        isActive: path === ROUTES.ONBOARDING_RESUME_COMPLETE,
        prev: ROUTES.ONBOARDING_RESUME,
      },
      {
        isActive: path === ROUTES.ONBOARDING_SKILLS,
        next: ROUTES.ONBOARDING_ASSESSMENT,
        prev: ROUTES.ONBOARDING_RESUME,
      },
      {
        isActive: path === ROUTES.ONBOARDING_ASSESSMENT,
        prev: ROUTES.ONBOARDING_SKILLS,
      },
    ]);
  }, [path, state, userProfile]);

  // navigate to the complete view when the resume has been uploaded
  useEffect(() => {
    if (state.resumeUploaded && path === ROUTES.ONBOARDING_RESUME) {
      history.push(ROUTES.ONBOARDING_RESUME_COMPLETE);

      // reset it back to false after going to skills just in case the user comes back to re-upload their resume.
      dispatch(setResumeUploaded(false));
    }
  }, [state.resumeUploaded, history, dispatch, path]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }

    if (linkIconRef.current) {
      linkIconRef.current.focus();
    }
  }, [path]);

  const currentStep = steps.find((s) => s.isActive) || {};

  return (
    <div className="iq4-onboarding-resume-path-container">
      <Topbar logoOnly Logo={Logo} className="iq4-onboarding-resume-path__topbar" />
      <Container>
        <main className="iq4-onboarding-resume-path">
          {/* side component  */}
          {currentStep && currentStep.sideComponent}

          <div ref={contentRef} className="iq4-onboarding-resume-path__content-container">
            <div>
              {currentStep.prev && (
                <div className="iq4-onboarding-resume-path__header">
                  <h1>
                    <Link
                      className="iq4-link-icon iq4-onboarding__icon"
                      aria-label="Go back to previous step"
                      to={currentStep.prev}
                      ref={linkIconRef}
                    >
                      <SimpleIcon
                        className="iq4-link-icon__icon"
                        name="onboardingArrow"
                        role="presentation"
                        aria-hidden={false}
                        size="22px"
                      />
                    </Link>

                    {currentStep.name}
                  </h1>
                </div>
              )}
              <Switch>
                <Route exact path={[ROUTES.ONBOARDING_RESUME]}>
                  <OnboardingResume title="Let's get started" displayLinks={true} />
                </Route>
                <Route exact path={[ROUTES.ONBOARDING_RESUME_COMPLETE]}>
                  <OnboardingResumeComplete
                    onComplete={() => history.push(ROUTES.ONBOARDING_PERSONAL)}
                    onPrevious={() => history.push(ROUTES.ONBOARDING_RESUME)}
                  />
                </Route>
                <Route exact path={ROUTES.ONBOARDING_ASSESSMENT}>
                  <OnboardingAssessmentPage />
                </Route>
              </Switch>
            </div>
          </div>
        </main>
      </Container>
    </div>
  );
};
