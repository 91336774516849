import { useState, useEffect } from 'react';
import { PATHWAYS_SEARCH_URL } from 'common/constants/endpoints';

const MIN_CHAR_COUNT = 3;
export const useRolesSearch = (query) => {
  const [rolesResults, setRolesResults] = useState([]);

  useEffect(() => {
    if (!query || (query && query.length < MIN_CHAR_COUNT)) return;

    fetch(`${PATHWAYS_SEARCH_URL}?q=${query}&limit=10`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        setRolesResults(data);
      });
  }, [query]);

  return rolesResults;
};
