import React from 'react';
import PropTypes from 'prop-types';
import './OnboardingInfoPanel.scss';

const OnboardingInfoPanel = (props) => {
  const { title, description, imageUrl, children } = props;
  return (
    <div className="onboarding-info-panel">
      <div className="onboarding-info-panel-content">
        {title && <h1 className="onboarding-info-panel-content__title">{title}</h1>}
        {description && <p className="onboarding-info-panel-content__description">{description}</p>}
        {imageUrl && <img className="onboarding-info-panel-content__image" src={imageUrl} alt="" />}
        <div className="onboarding-info-panel-content__children">{children}</div>
      </div>
    </div>
  );
};

OnboardingInfoPanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
};

OnboardingInfoPanel.propTypes = {
  title: '',
  description: '',
  imageUrl: '',
};

export default OnboardingInfoPanel;
