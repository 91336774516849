import React from 'react';
import './RecommendedWorkRolesList.scss';

/**
 * Recommended work roles list component
 * @param {object[]} roles - an array of roles to list
 * @param {function} onClick - Hndle clicking the details btn for roles
 */
export const RecommendedWorkRolesList = ({ roles, onClick }) => {
  return (
    roles &&
    roles.map((role, index) => {
      return (
        <div key={role.id} className="iq4-recommended-work-roles-list">
          <p className="iq4-recommended-work-roles-list__title">{role.label}</p>
          <span
            role="button"
            tabIndex={0}
            onClick={() => void onClick(role, index)}
            onKeyPress={() => void onClick(role, index)}
            className={`iq4-recommended-work-roles-list__detail-btn iq4-recommended-work-roles-${index}`}
          >
            Details
          </span>
        </div>
      );
    })
  );
};
