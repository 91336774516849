import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'common/components/Button';
import { useIsMobile } from 'common/hooks/useIsMobile';
import './Notifications.scss';

export const Notifications = ({ notifications, onClose }) => {
  const history = useHistory();
  const isMobile = useIsMobile((b) => b === 'xs' || b === 'sm');

  const handleAction = (notification) => {
    const actionRoute = notification?.actionRoute?.split('/');
    const pathName = history.location?.pathname.split('/');
    if (
      actionRoute.length &&
      pathName.length &&
      actionRoute[actionRoute.length - 1] !== pathName[pathName.length - 1]
    ) {
      history.push(notification.actionRoute);
    }
    onClose();
  };

  if (isMobile) {
    return (
      <div className="iq4-notifications-mobile">
        <div className="iq4-notifications__heading-mobile">Notifications</div>
        <ul className="iq4-notifications__list-mobile">
          {notifications &&
            notifications.map((notification) => (
              <li className="iq4-notifications__list-item-mobile">
                <span className="iq4-notifications__list-text">{notification.message}</span>
                <Button
                  className="iq4-notifications__list-button-mobile"
                  variation="ghost"
                  onClick={() => handleAction(notification)}
                >
                  {notification.actionLabel}
                </Button>
              </li>
            ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="iq4-notifications">
      <div className="iq4-notifications__heading">Notifications</div>
      <ul className="iq4-notifications__list">
        {notifications &&
          notifications.map((notification) => (
            <li className="iq4-notifications__list-item">
              <span>{notification.message}</span>
              <Button
                className="iq4-notifications__list-button"
                variation="ghost"
                onClick={() => handleAction(notification)}
              >
                {notification.actionLabel}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};
