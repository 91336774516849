import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { ReactComponent as Ribbon } from '@assets/svg/ribbon.svg';
import { ReactComponent as RibbonLeft } from '@assets/svg/ribbon-cut-left.svg';
import { ReactComponent as RibbonRight } from '@assets/svg/ribbon-cut-right.svg';
import { ReactComponent as Circles } from 'common/assets/svg/circles-background.svg';
import { ReactComponent as CirclesMagic } from 'common/assets/svg/circles-magic.svg';
import { Button } from 'common/components';
import Confetti from './Confetti';
import './OnboardingPage.scss';
import { updateHasCutRibbonReq } from 'common/store/features/profile/profileSlice';
import { ROUTES } from '../../routes';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProfile } from 'common/store/features/profile/selectors';
import { useIsMobile } from '../../common/hooks/useIsMobile';

const appName = process.env.REACT_APP_NAME;
const welcomeVideoUrl = process.env.REACT_APP_WELCOME_VIDEO_URL;

export const OnboardingWelcomePage = () => {
  const containerRef = useRef(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [hasCutRibbon, setHasCutRibbon] = useState(false);
  const [showCutRibbons, setShowCutRibbons] = useState(true);
  const [hoverScissors, setHoverScissors] = useState(false);

  const { personal } = useSelector(selectProfile);
  const isMobile = useIsMobile((b) => b === 'xs' || b === 'sm');

  const history = useHistory();
  const dispatch = useDispatch();

  function Scissors() {
    return (
      <div
        className="scissors-container"
        style={{ left: `${cursorPos.x}px`, top: `${cursorPos.y}px` }}
      >
        <figure className={`scissors ${hoverScissors ? 'hover' : ''}`}>
          <div className="half">
            <span className="handle"></span>
            <span className="blade"></span>
          </div>
          <div className="half">
            <span className="blade"></span>
            <span className="handle"></span>
          </div>
        </figure>
      </div>
    );
  }

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setCursorPos({ x: rect.left + 300, y: rect.top + 800 });
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setCursorPos({ x: rect.left + rect.width / 2 - 200, y: rect.top + rect.height / 2 });
    }

    const updateCursorPos = (e) => {
      setCursorPos({ x: e.clientX - 200, y: e.clientY - 120 });
    };

    if (!hasCutRibbon) {
      window.addEventListener('mousemove', updateCursorPos);
      window.addEventListener('touchmove', updateCursorPos);
    }

    return () => {
      window.removeEventListener('mousemove', updateCursorPos);
      window.removeEventListener('touchmove', updateCursorPos);
    };
  }, [hasCutRibbon]);

  /*  useEffect(() => {
    console.log(cursorPos);
  }, [cursorPos]);*/

  useEffect(() => {
    if (!hasCutRibbon) {
      const start = { x: 1095, y: 787 };
      const end = {
        x: containerRef.current.getBoundingClientRect().left + 300,
        y: containerRef.current.getBoundingClientRect().top + 500,
      };
      const duration = isMobile ? 1000 : 2000; // duration in milliseconds
      const startTime = performance.now();

      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);

        const newX = start.x + (end.x - start.x) * progress;
        const newY = start.y + (end.y - start.y) * progress;

        setCursorPos({ x: newX, y: newY });

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          handleRibbonClick();
        }
      };

      requestAnimationFrame(animate);
    }
  }, [hasCutRibbon]);

  useEffect(() => {
    setCursorPos({
      x: containerRef.current.getBoundingClientRect().left + 300,
      y: containerRef.current.getBoundingClientRect().top + 800,
    });
  }, []);

  const handleRibbonClick = (event) => {
    setTimeout(() => setHasCutRibbon(true), 500);
    setTimeout(() => setShowCutRibbons(false), 1700);
  };

  function skipWelcomeVideo() {
    dispatch(updateHasCutRibbonReq({ id: personal?.userProfile?.id, hasCutRibbon: true }));
    history.push(ROUTES.ONBOARDING_RESUME);
  }

  return (
    <div
      className={`iq4-welcome-page-container ${hasCutRibbon ? '' : 'iq4-welcome-page-no-cursor'}`}
      ref={containerRef}
      onClick={handleRibbonClick}
    >
      <div className="iq4-welcome-page-content">
        <div className="iq4-welcome-page__logo-container">
          <Logo />
        </div>
        {!hasCutRibbon && (
          <div className="iq4-welcome-ribbon-container">
            <h1 className="iq4-welcome-page-title">Welcome to {appName}!</h1>
            <div className="iq4-welcome-page-text">
              Let’s celebrate your journey to mastering new skills together!
            </div>
            <Ribbon />
            {!isMobile && <Scissors />}
          </div>
        )}
        {hasCutRibbon && (
          <>
            {welcomeVideoUrl && (
              <div className="iq4-welcome-page-text">
                What is the {appName}? Please watch this short video for an important overview on
                how to use this exciting new tool
              </div>
            )}
            {!welcomeVideoUrl && (
              <div className="iq4-welcome-page-text">
                Explore your {appName}, and get ready for an exciting journey ahead.
              </div>
            )}
            <Confetti />
            {showCutRibbons && (
              <>
                <RibbonLeft
                  className="wiggleAndFall"
                  style={{
                    position: 'absolute',
                    right: '50%',
                    bottom: '40%',
                  }}
                />
                <RibbonRight
                  className="wiggleAndFall"
                  style={{ position: 'absolute', bottom: '15%' }}
                />
              </>
            )}
            {welcomeVideoUrl && (
              <div className="iq4-welcome-page__video-parent">
                <div className="iq4-welcome-page__video-container">
                  <iframe
                    src={welcomeVideoUrl}
                    title="Tutorial"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
            <div className="circles-magic-container">
              <CirclesMagic className="circles-magic" />
            </div>
            <Button style={{ marginTop: 40 }} onClick={skipWelcomeVideo}>
              Continue
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
